import type {RouteRecordRaw} from 'vue-router';
import type {CommonRouteMeta} from '@/types';

const BaseDashboard = () => import('@/views/dashboard/BaseDashboard.vue');

const defaultMeta: CommonRouteMeta = {
    icon: 'mdi-handshake',
    noCache: false,
};

const commission: RouteRecordRaw[] = [{
    path: '/commission',
    component: BaseDashboard,
    meta: {
        ...defaultMeta,
        title: 'commission',
        showInMenu: false,
        middleware: ['auth'],
        layout: 'Dashboard',
    } as CommonRouteMeta,
    children: [
        {
            path: '',
            components: {
                default: () => import('@/views/dashboard/commission/Commission.vue'),
            },
            name: 'commission',
            meta: {...defaultMeta, title: ''},
        },
        {
            path: 'partners',
            components: {
                default: () => import('@/views/dashboard/settings/CommissionPartners.vue'),
            },
            name: 'commission.partners',
            meta: {...defaultMeta, title: 'Partners'},
        },
        {
            path: 'partners/:partner_uuid',
            components: {
                default: () => import('@/views/dashboard/commission/SingleCommissionPartner.vue'),
            },
            name: 'commission.partner.single',
            meta: {...defaultMeta, title: 'Partners'},
        },
    ],
}];

export default commission;
