import type {RouteRecordRaw} from 'vue-router';
import {CommonRouteMeta} from '@/types';

const EsignRecipient = () => import('@/views/eSigner/RecipientV2.vue');
const BaseESigner = () => import('@/components/eSigner/index.vue');

export const eSignerRoutes: RouteRecordRaw[] = [
    {
        path: '/signing',
        component: BaseESigner,
        meta: {
            layout: 'Esign',
        } as CommonRouteMeta,
        children: [
            {
                path: 'v2/:envelope_id/:recipient_id',
                component: EsignRecipient,
                name: 'esign.recipient.v2',
                meta: {title: 'eSign Recipient', icon: 'domain', noCache: true, showNavbar: true, layout: 'Esign',},
            },
            {
                path: 'v2/:envelope_id/:recipient_id/sample',
                component: () => import('@/views/eSigner/RecipientV2.vue'),
                name: 'esign.recipient.v2.sample',
                meta: {title: 'eSign Recipient', icon: 'domain', noCache: true, showNavbar: true},
            },
        ],
    },
];

export const eSignerCompleteRoutes: RouteRecordRaw = {
    path: '/signing',
    component: BaseESigner,
    children: [
        {
            path: ':envelope_id/:recipient_id/complete',
            component: () => import('@/views/eSigner/RecipientComplete.vue'),
            name: 'esign.recipient.complete',
            meta: {title: 'eSign Recipient Complete', icon: 'domain', noCache: true},
        },
    ],
    meta: {hidden: true, noCache: false} as CommonRouteMeta
};
