import { get, isUndefined } from 'lodash';
import elFactory from './elFactory.service';
import UtilService from './util.service';

const CardService = {

  cardCount: 0,

  prepListingCards() {
    const productCards = Array.from(document.getElementsByClassName('fleetwire-listing-card'))

    productCards.forEach((listing, index) => {
      CardService.cardCount++;
      const lid = UtilService.getListingId(listing);


      const listingCardActions = elFactory('div', { class: 'fw-card__actions' }, 'Open');
      const listingCardCartBtn = elFactory('a', { class: 'fw-button fw-branded fw-card__actions' },
        elFactory('i', { class: 'fw-icon-cart fw-link fw-branded-font-color' })
      );

      listingCardActions.onclick = function () {
        CardService.clickedButton();
      };
      listingCardCartBtn.onclick = function () {


        CardService.clickedButton(lid);
      };

      const dataset = $fw(listing).data();
      const markup = CardService.buildProductCard(lid, dataset);

      $fw(markup).click(function () {
        window.open(
          UtilService.companyProductUrl(lid),
          '_blank'
        );
      });

      $fw(productCards[index]).replaceWith(markup)
    })
    UtilService.setWidth();

    CardService.buildQuickView();
  },

  buildQuickView() {
    const closeBtn = elFactory('a', { class: 'fw-close' }, 'X');

    closeBtn.onclick = function () {
      CardService.close_quick_view();
    };


    const markup = elFactory('div', { id: 'fleetwire-quickview', class: 'fleetwire-quickview' },
      elFactory('div', { class: 'booqable-component fleetwire-component', style: 'display: none;' },
        elFactory('div', { id: 'booqable-quickview-wrapper', class: 'fleetwire-quickview-wrapper closed' },
          elFactory('div', { id: 'booqable-quickview-inner' },
            closeBtn,
            elFactory('div', { class: 'booqable-product-detail-wrapper' },
              elFactory('div', { class: 'ProductImage-czxphg hgNThE' },
                elFactory('div', { class: 'MainImageContainer-dsPfmb kPjdza', 'data-tid': '' },
                  elFactory('div', { class: 'MainImage-btvEcL gypPqW' },
                    elFactory('div', {
                        class: 'BFocalImage-dejJaX gWABCj',
                        style: 'position: relative; overflow: hidden;'
                      },
                      elFactory('img', {
                        class: 'Image-fDMyDA kftJhF',
                        alt: 'bmw',
                        src: 'https://fleetwire.io/images/vehicle-placeholder.jpg',
                        style: 'min-height: 100%; min-width: 100%; position: absolute; inset: 0px; max-width: 100%;',
                        'data-focus-y': '0',
                        'data-focus-x': '0',
                        loading: 'lazy',
                      }),
                    ),
                  ),
                ),
              ),
              elFactory('div', { class: 'fw-content' },
                elFactory('h3', { class: 'fw-product-name' },
                  'BMW'
                ),
                elFactory('div', {
                    class: 'fleetwire-product-button-wrapper',
                    id: 'booqable-product-button-18ae2009-9c79-411c-b6cd-20e40ed2e8a0'
                  },
                  elFactory('div', { class: 'fw-actions' },
                    elFactory('span', { class: 'fw-price-details' },
                      elFactory('span', { class: 'fw-duration' }, 'from'),
                      elFactory('span', { class: 'fw-price' }, '$95.00')
                    ),
                    elFactory('span', { 'aria-expanded': false },
                      elFactory('a', {
                        target: '_blank',
                        class: 'AvailabilityLink-hXmWhx kKkbX Link-coULHM letVP A-jrLLFi iMXKpJ',
                        color: 'dark'
                      }, 'Check availability')
                    ),
                    elFactory('div', { class: 'fw-control', style: 'display: none;' },
                      elFactory('div', { class: 'InputField-fbCPUI dfiEEZ' },
                        elFactory('div', { class: 'LabelContainer-imROAO kLzFrI' }),
                        elFactory('span', { class: 'InputContainer-kcTEuW ldFkuZ' },
                          elFactory('input', {
                            name: 'quantity',
                            value: 1,
                            type: 'number',
                            class: 'fw-quantity Input-fXckBj kNmAJV Input-eXLwAq iwGLPw'
                          }),
                        ),
                      ),
                      elFactory('div', { class: 'Spacer-dQMXRY endFZm' }),
                      elFactory('span', { class: 'ButtonContainer-cCzDqJ dASMYX Span-hCfPsm jdJhRb', color: 'primary' },
                        elFactory('button', {
                            type: 'button',
                            class: 'fw-button false Button-jiBmQ bruVYM Button-hYXUXp dvaIoi'
                          },
                          elFactory('span', { class: 'Label-ghyrbC cjnCZD Span-hCfPsm ehhEci' }, 'Add to cart'),
                        ),
                      ),
                    ),
                  ),
                ),
                elFactory('div', { class: 'MagnifyContainer-iQrpjS hBbDLP' }),
              ),
            ),
          ),
        )
      )
    );


    document.body.appendChild(markup);


    window.onclick = function (event) {
      if (event.target.id === 'booqable-quickview-wrapper') {
        event.target.classList.remove('open');
        event.target.classList.add('closed');
      }
    }
  },

  clickedButton(lid) {
    const listing = window.listingData.find(listing => listing.l_id === lid);


    const quickView = document.getElementById('fleetwire-quickview');
    const fwOverlayState = quickView.querySelector('#booqable-quickview-wrapper');


    fwOverlayState.classList.remove('closed');
    fwOverlayState.classList.add('open');

    // var quickView = document.getElementsByClassName('booqable-quickview');


    const listingName = quickView.getElementsByClassName('fw-product-name');
    const listingPrice = quickView.getElementsByClassName('fw-price');
    const listingImage = quickView.getElementsByClassName('Image-fDMyDA');
    const listingAvailLink = quickView.getElementsByClassName('AvailabilityLink-hXmWhx');
    const amount = get(listing, 'pricing.amount', 0);


    listingName[0].innerHTML = listing.name;
    listingPrice[0].innerHTML = `$${UtilService.getListingPrice(amount)}`;
    listingImage[0].src = UtilService.productFirstImage(listing);
    listingAvailLink[0].href = UtilService.companyProductUrl(lid);

    if (quickView.length > 0) {
      const quickViewToggle = quickView[0].getElementsByClassName('fleetwire-quickview-wrapper')
      quickViewToggle[0].classList.remove('closed');
      quickViewToggle[0].classList.add('open');
    }
  },


  close_quick_view() {
    const element = document.getElementsByClassName('fleetwire-quickview');

    const elementToggle = element[0].getElementsByClassName('fleetwire-quickview-wrapper');

    elementToggle[0].classList.remove('open');
    elementToggle[0].classList.add('closed');
  },

  /**
   * Builds listing card element
   *
   * @private
   * @param {string} lid
   * @param dataset
   */
  buildProductCard(lid, dataset = {}) {
    let datasetString = '';
    if (dataset) {
      dataset = CardService.allowedCardDataAttributes(dataset)
      datasetString = Object.keys(dataset).map(key => `data-${key}="${dataset[key]}"`).join(' ');
    }
    return $fw(`<div class="fleetwire-listing-card card-${lid}" ${datasetString}>
        <div class="fleetwire-product ${lid}-card-main">
            <div class="fleetwire-component">
                <div class="fleetwire-product-wrapper">
                    <div class="fleetwire-product-inner">
                        <div style="height: 200px;">
                            <div class="fw-card-image ${lid}-card" style="position: relative; overflow: hidden;">
                                <div class="fw-image__image fw-image__image--cover"></div>
                            </div>
                        </div>
                        <div class="fw-details">
                            <div class="fw-product-title-wrapper fw-has-prices">
                                <div class="fw-product-name"></div>
                            </div>
                            <span class="fw-price-details">
                                <span class="fw-duration"></span>
                                <span class="fw-price fw-price-currency"></span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>`);
  },

  /**
   * Filters out unwanted data attributes
   *
   * @private
   * @param {Object} dataset
   */
  allowedCardDataAttributes(dataset) {
    const allowed = ['currency', 'customtitle', 'showprice'];

    Object.keys(dataset).forEach(value => {
      if (!allowed.includes(value)) {
        delete dataset[value];
      }
    });
    return dataset;
  },


  /**
   * Adds listing card element to the DOM
   *
   * @private
   * @param {Object} [listings]
   */
  addListingCards(listings) {
    if (CardService.cardCount === 0) return;

    listings.forEach((listing) => {
      const listingCards = CardService.getListingCards(listing)

      listingCards.forEach((card) => {
        if (CardService.listingInactive(listing)) {
          $fw(card).remove();
          console.error(`listing (${listing.name}) archived, deleted or not active so it was removed`);
          return;
        }

        CardService.setCardAttributes(card, listing);

        card.classList.remove('card-skeleton', 'card-skeleton-loading');
      })
    });

    UtilService.setWidth();
  },

  listingInactive(listing) {
    return !!(listing.archived_at || listing.deleted_at || !listing.active);
  },


  /**
   * Get all the card elements for a listing
   *
   * @private
   * @param {Object} listing
   */
  getListingCards(listing) {
    const lid = get(listing, 'l_id');
    return Array.from(document.getElementsByClassName(`card-${lid}`));
  },


  /**
   * Set the card element attributes
   *
   * @private
   * @param {Object} cardElement
   * @param {Object} listing
   */
  setCardAttributes(cardElement, listing) {
    const attr = get(cardElement, 'dataset');


    CardService.setPrice(cardElement, listing);


    const title = get(listing, 'name', '');

    const linkText = isUndefined(attr['customTitle']) ? title : attr['customtitle'];


    const imageElement = cardElement.getElementsByClassName('fw-image__image');
    const cardDescription = cardElement.getElementsByClassName('fw-product-name');


    // Set card image
    const img = UtilService.productFirstImage(listing);
    const style = `background-image: url("${img}"); background-position: center center;`;
    imageElement[0].setAttribute('style', style);


    // Set card title
    cardDescription[0].innerHTML = linkText


  },

  /**
   * Set the card element price attributes
   *
   * @private
   * @param {Object} cardElement
   * @param {Object} listing
   * @param {number} updateAmount
   */
  setPrice(cardElement, listing = null, updateAmount = 0) {
    const attr = get(cardElement, 'dataset');

    const showPrice = get(attr, 'showprice')

    if (showPrice) {
      let currency = get(attr, 'currency');

      /**
       * If no currency is set, use the default company currency; fallback to USD
       */
      if (!currency) {
        currency = get(window.fwCompany, 'currency')
        currency = get(currency, 'symbol', '$') ?? '$';
      }


      const cardPrice = cardElement.getElementsByClassName('fw-price');
      const priceDetails = cardElement.getElementsByClassName('fw-price-details');


      const amount = get(listing, 'pricing.amount', get(listing, 'price', updateAmount));
      const price = UtilService.getListingPrice(amount);
      cardPrice[0].innerHTML = `${currency}${price}`
      priceDetails[0].setAttribute('style', 'display: block;');
    }
  },

}

export default CardService;