import {EventBus} from '@/plugins/eventBus';
import {Form} from '@/plugins/FWForms/index';
import axios from 'axios';
import * as types from '@/store/mutation-types';
import {StorageService} from '@/services/storage.service';
import {createUrlQueryString} from '@/utils/urls';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {Module} from 'vuex';
import {RootState} from '@/types/RootState';
import {push} from 'notivue';

type PaymentMethod = {
    billing_details: {
        address: {
            city: string | null,
            country: string | null,
            line1: string | null,
            line2: string | null,
            postal_code: string | null,
            state: string | null,
        },
        email: string | null,
        name: string | null,
        phone: string | null,
    },
    brand: string,
    card: {
        brand: string,
        checks: {
            address_line1_check: string,
            address_postal_code_check: string,
            cvc_check: string,
        },
        country: string,
        exp_month: number,
        exp_year: number,
        fingerprint: string,
        funding: string,
        generated_from: string,
        last4: string,
        networks: {
            available: Array<string>,
            preferred: string,
        },
        three_d_secure_usage: {
            supported: boolean,
        },
        wallet: string,
    },
    exp_month: number,
    exp_year: number,
    funding: string,
    id: string,
    is_default: boolean,
    last4: string,
    name: string,
}

export interface StripePaymentIntent {
    amount: number,
    amount_details: any,
    automatic_payment_methods: any,
    canceled_at: any,
    cancellation_reason: any,
    capture_method: string,
    client_secret: string,
    confirmation_method: string,
    created: number,
    currency: string,
    description: string,
    id: string,
    last_payment_error: any,
    livemode: boolean,
    next_action: any,
    object: string,
    payment_method: string,
    payment_method_configuration_details: any,
    payment_method_types: Array<string>,
    processing: any,
    receipt_email: any,
    setup_future_usage: string,
    shipping: any,
    source: any,
    status: string,
}

export interface PaymentsState {
    loading: Record<string, boolean>,
    payments: Array<any>,
    payment: any,
    newPayment: {
        method: string,
        amount: number,
        date: string,
        security_deposit: number,
        keep_deposit_amount: number,
        keep_deposit_reason: string,
        description: string,
        transaction_type: string,
        payment_type: string,
        newCard: boolean,
        nameOnCard: string,
        paymentMethod: any,
        credit_card_id: any,
        capture_method: string,
    },
    stripeKey: string,
    payment_methods: PaymentMethod[],
    StripeIntent: any,
    paymentForm: Form<any>,
    currentWorkingPaymentItem: any,
    customPaymentMethods: string[],
}

// state
const state: PaymentsState = {
    loading: {
        cardBtnLoading: false,
        addPaymentMethod: false,
        cancelPaymentIntent: false,
        refund: false,
        refundPayment: false,
        cancelPayment: false,
        deletePayment: false,
        createPayment: false,
        capturePayment: false,
        deleteCustomPayment: false,
        sendPaymentViaSms: false,
        fetchPayment: false,
        fetchPaymentMethods: false,
        updatePaymentMethods: false,
    },
    payments: [],
    payment: {},
    newPayment: {
        method: '',
        amount: 0,
        date: new Date().toISOString().substring(0, 10),
        security_deposit: 0,
        keep_deposit_amount: 0,
        keep_deposit_reason: '',
        description: '',
        transaction_type: 'charge',
        payment_type: 'cash',
        newCard: false,
        nameOnCard: '',
        paymentMethod: null,
        credit_card_id: null,
        capture_method: 'automatic',
    },
    stripeKey: import.meta.env.VUE_APP_STRIPE_KEY,
    payment_methods: [] as PaymentMethod[],
    StripeIntent: {},
    paymentForm: new Form(),
    currentWorkingPaymentItem: {},
    customPaymentMethods: [],
};


const PaymentsModule: Module<PaymentsState, RootState> = {
    namespaced: true,
    state,
    mutations: {
        [types.SET_PAYMENTS_LOADING](state: PaymentsState, loading: { subject: string, loading: boolean }) {
            state.loading[loading.subject] = loading.loading;
        },
        [types.SET_PAYMENT](state: PaymentsState, payment) {
            state.payment = payment;
        },
        [types.SET_PAYMENTS](state, payments) {
            state.payments = payments;
        },
        [types.SET_PAYMENT_METHODS](state, payment_methods: PaymentMethod[]) {
            state.payment_methods = payment_methods;
        },
        [types.SET_CURRENT_WORKING_PAYMENT_ITEM](state, item) {
            state.currentWorkingPaymentItem = item;
        },
        [types.SET_CUSTOM_PAYMENT_METHODS](state, methods) {
            state.customPaymentMethods = methods;
        },
        [types.SET_STRIPE_INTENT](state, data) {
            state.StripeIntent = data;
        },
        [types.RESET_NEW_PAYMENT](state) {
            state.newPayment = {
                method: '',
                amount: 0,
                date: new Date().toISOString().substring(0, 10),
                security_deposit: 0,
                keep_deposit_amount: 0,
                keep_deposit_reason: '',
                description: '',
                transaction_type: 'charge',
                payment_type: 'cash',
                newCard: false,
                nameOnCard: '',
                paymentMethod: null,
                credit_card_id: null,
                capture_method: 'automatic',
            }
        },
    },
    actions: {
        async fetchPayment({commit}, payment_id: string) {
            try {
                const responseData = await performApiRequest(commit, 'SET_PAYMENTS_LOADING', 'fetchPayment', () => axios.get(`/v1/payments/${payment_id}`));
                commit('SET_PAYMENT', responseData.payment);
                return responseData.payment;
            } catch (error) {
                console.error(error)
            }
        },

        fetchPayments({commit}, attributes) {
            const query = createUrlQueryString(attributes, true);

            axios.get(`/v1/payments${query}`)
                .then((response) => {
                    commit('SET_PAYMENTS', response.data.payments);
                    EventBus.$emit('fetch-payments-success');
                }).catch(() => {
            }).finally(() => {
            });
        },

        async fetchPaymentMethods({commit}) {
            try {
                const responseData = await performApiRequest(commit, 'SET_PAYMENTS_LOADING', 'fetchPaymentMethods', () => axios.get('/v1/stripe/payment-methods'));
                commit('SET_PAYMENT_METHODS', responseData.cards);
            } catch (error) {
                console.error(error)
            }
        },
        async updateDefaultBillingDetails({commit}, data) {
            try {
                await performApiRequest(commit, 'SET_PAYMENTS_LOADING', 'updatePaymentMethods', () => axios.put('/v1/stripe/payment-methods/default', data));
                push.success({
                    message: 'Payment method updated successfully',
                });
            } catch (error) {
                push.error({
                    message: e?.response?.data?.message || 'There was an error',
                });
            }
        },

        createSetupIntent({commit}) {
            axios.post('/v1/stripe/create-setup-intent')
                .then((response) => {
                    commit('SET_STRIPE_INTENT', response.data.intent);
                }).catch(() => {
            }).finally(() => {
            });
        },

        async addPaymentMethod({commit}, paymentIntent: StripePaymentIntent) {
            try {
                const responseData = await performApiRequest(commit, 'SET_PAYMENTS_LOADING', 'addPaymentMethod', () => axios.post('/v1/stripe/add-payment-method', paymentIntent));
                commit(types.SET_PAYMENT_METHODS, responseData?.cards || []);
                commit('company/SET_COMPANY', responseData?.company, {root: true});
                EventBus.$emit('add-payment-method-success');
            } catch (error) {
                console.error(error)
            }
        },

        /**
         * Capture a payment authorization
         */
        capturePayment({commit}, capture) {
            commit('SET_PAYMENTS_LOADING', {subject: 'capturePayment', loading: true});

            axios.post(`/v1/payments/${capture.uuid}/capture`, capture)
                .then((response) => {
                    commit('SET_PAYMENT', response.data.payment);
                    EventBus.$emit('capture-payment-success');

                    push.success({
                        message: 'Payment was captured successfully',
                    });
                }).catch((e) => {
                push.error({
                    message: e?.response?.data?.message || 'There was an error',
                });
            }).finally(() => {
                commit('SET_PAYMENTS_LOADING', {subject: 'capturePayment', loading: false});
            });
        },

        refundPayment2({commit, rootGetters}, refund) {
            commit('SET_PAYMENTS_LOADING', {subject: 'refund', loading: true});
            axios.post(`/v1/company/${rootGetters['company/company'].uuid}/payments/${refund.p_id}/refund`, {
                amount: refund.amount,
            })
                .then((response) => {
                    commit('SET_PAYMENT', response.data.payment);
                    push.success({
                        message: 'Payment refund was successful',
                    });
                }).catch((e) => {
                console.dir(e);
                push.error({
                    message: e?.response?.data?.message || 'There was an error',
                });
            }).finally(() => {
                commit('SET_PAYMENTS_LOADING', {subject: 'refund', loading: false});
            });
        },

        /**
         * Create a payment
         */
        createPayment({state, commit}, payment) {
            commit('SET_PAYMENTS_LOADING', {subject: 'createPayment', loading: true});


            axios.post('/v1/payments', {
                payment,
            }).then((response) => {
                EventBus.$emit('create-payment-success', response.data.payment);
                commit(types.SET_PAYMENT, response.data.payment);

                push.success({
                    message: 'Success',
                });
            }).catch((e) => {
                push.error({
                    message: e?.response?.data?.message || 'There was an error',
                });
                state.paymentForm.errors.set(e?.response?.data?.message || 'There was an error');
            }).finally(() => {
                commit('SET_PAYMENTS_LOADING', {subject: 'createPayment', loading: false});
            });
        },

        /**
         * Create a manual payment
         */
        createManualPayment({commit}, payment) {
            commit('SET_PAYMENTS_LOADING', {subject: 'createPayment', loading: true});

            axios.post('/v1/payments', {payment})
                .then((response) => {
                    EventBus.$emit('create-payment-success', response.data.payment);
                    commit(types.SET_PAYMENT, response.data.payment);

                    push.success({
                        message: 'Success',
                    });
                }).catch((e) => {
                console.dir(e);
                push.error({
                    message: e?.response?.data?.message || 'There was an error',
                });
            }).finally(() => {
                commit('SET_PAYMENTS_LOADING', {subject: 'createPayment', loading: false});
            });
        },

        cancelPayment({commit}, uuid) {
            commit('SET_PAYMENTS_LOADING', {subject: 'cancelPayment', loading: true});
            axios.post(`/v1/payments/${uuid}/cancel-request`)
                .then((response) => {
                    EventBus.$emit('cancel-payment-intent-success', response.data.payment);
                    commit('SET_PAYMENT', response.data.payment);
                }).catch((e) => {
                push.error({
                    message: e?.response?.data?.message || 'There was an error',
                });
            }).finally(() => {
                commit('SET_PAYMENTS_LOADING', {subject: 'cancelPayment', loading: false});
            });
        },

        /**
         * Refund a payment
         */
        refundPayment({state, commit}, payment) {
            commit('SET_PAYMENTS_LOADING', {subject: 'refundPayment', loading: true});
            state.paymentForm.errors.clear();

            axios.post(`/v1/payments/${payment.uuid}/refund`, payment)
                .then((response) => {
                    EventBus.$emit('refund-payment-success');
                    commit('SET_PAYMENT', response.data.payment);
                    push.success({
                        message: 'Refund Payment Success',
                    });
                }).catch((e) => {
                EventBus.$emit('refund-payment-failure', e?.response?.data?.message || 'There was an error');
                state.paymentForm.errors.set(e?.response?.data?.message || 'There was an error');
            }).finally(() => {
                commit('SET_PAYMENTS_LOADING', {subject: 'refundPayment', loading: false});
            });
        },

        /**
         * Keep deposit
         */
        keepDeposit({state, commit}, payment) {
            commit('SET_PAYMENTS_LOADING', {subject: 'refundPayment', loading: true});
            state.paymentForm.errors.clear();

            axios.post(`/v1/company/${StorageService.getCompanyUuid()}/payments/hold-deposit`, payment)
                .then((response) => {
                    EventBus.$emit('refund-payment-success');
                    commit('SET_PAYMENT', response.data.payment);
                    push.success({
                        message: 'Refund Payment Success',
                    });
                }).catch((e) => {
                state.paymentForm.errors.set(e?.response?.data?.message || 'There was an error');
            }).finally(() => {
                commit('SET_PAYMENTS_LOADING', {subject: 'refundPayment', loading: false});
            });
        },

        deletePayment({commit}, uuid) {
            commit('SET_PAYMENTS_LOADING', {subject: 'deletePayment', loading: true});

            axios.delete(`/v1/payments/${uuid}`)
                .then(() => {
                    EventBus.$emit('delete-payment-success');
                }).catch((e) => {
                push.error({
                    message: e?.response?.data?.message || 'There was an error',
                });
            }).finally(() => {
                commit('SET_PAYMENTS_LOADING', {subject: 'deletePayment', loading: false});
            });
        },

        async deleteCustomPaymentMethod({commit}, name) {
            const responseData = await performApiRequest(commit, 'SET_PAYMENTS_LOADING', 'deleteCustomPayment', () => axios.delete(`/v1/custom-payment-methods/${name}`));
            commit(types.SET_CUSTOM_PAYMENT_METHODS, responseData.meta);
        },


        /**
         * Send a sms with a payment request link
         */
        sendPaymentRequestLinkViaSms({commit}, payment) {
            commit('SET_PAYMENTS_LOADING', {subject: 'sendPaymentViaSms', loading: true});

            axios.post(`/v1/payments/${payment.uuid}/send-via-sms`)
                .then(() => {
                    EventBus.$emit('send-payment-request-link-via-sms-success');
                }).catch((e) => {
                EventBus.$emit('send-payment-request-link-via-sms-failed', e?.response?.data?.message || 'There was an error');
            }).finally(() => {
                commit('SET_PAYMENTS_LOADING', {subject: 'sendPaymentViaSms', loading: false});
            });
        },
    },
    getters: {
        loading: (state: PaymentsState) => state.loading,
        payment: (state: PaymentsState) => state.payment,
        payments: (state: PaymentsState) => state.payments,
        newPayment: (state: PaymentsState) => state.newPayment,
        stripeKey: (state: PaymentsState) => state.stripeKey,
        StripeIntent: (state: PaymentsState) => state.StripeIntent,
        paymentMethods: (state: PaymentsState) => state.payment_methods,
        paymentForm: (state: PaymentsState) => state.paymentForm,
        currentWorkingPaymentItem: (state: PaymentsState) => state.currentWorkingPaymentItem,
        customPaymentMethods: (state: PaymentsState) => state.customPaymentMethods,
    }
};

export default PaymentsModule;
