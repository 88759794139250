<template>
  <div>
    <v-card :disabled="fleetLoading.createBranch" :loading="fleetLoading.createBranch">
      <v-card-title>
        New branch
      </v-card-title>
      <v-card-text class="mt-3">
        <v-form v-model="branchForm">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <v-text-field
              v-model="currentWorkingBranch.name"
              class="w-full"
              label="Name"
              name="name"
              required
              variant="outlined"
            />
            <v-text-field
              v-model="currentWorkingBranch.email"
              class="w-full"
              label="Email"
              required
              variant="outlined"
            />
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            <v-select
              v-model="currentWorkingBranch.document"
              :items="documents"
              class="w-full"
              item-title="name"
              item-value="uuid"
              label="Rental Agreement"
              return-object
              variant="outlined"
            >
              <template v-slot:item="{ props, item }">
                <v-list-item :subtitle="item.raw.uuid" v-bind="props"></v-list-item>
              </template>
            </v-select>
            <v-autocomplete
              v-model="currentWorkingBranch.timezone"
              :items="timezones"
              :label="$t('timezone')"
              :persistent-hint="true"
              class="w-full"
              hint="This timezone will be used for listings when a branch is assigned to it."
              variant="outlined"
            />
          </div>

          <div class="mt-8 mb-10 pt-4 border-t">
            <h4 class="text-lg font-semibold mb-4">Location Settings</h4>
            <MapBoxAddressAutofill
              class="w-full"
              @locationData="addLocationData"
            />
          </div>
        </v-form>
        <div v-if="formErrors" class="space-y-2">
          <v-alert v-for="(error, index) in formErrors" :key="index" type="error" variant="outlined">
            {{ error[0] }}
          </v-alert>
        </div>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn variant="text" @click="router.push({ name: 'settings.fleet.branches' }).catch(() => {});">Cancel</v-btn>
        <v-btn :loading="fleetLoading.createBranch" @click="createFleetBranch(currentWorkingBranch)">Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script lang="ts" setup>
import {computed, onBeforeMount, ref} from 'vue';
import {guessTimeZone, timezones} from '@/utils/dates';
import {useStore} from 'vuex';
import MapBoxAddressAutofill from '@/components/widgets/MapBoxAddressAutofill.vue';
import {useRouter} from 'vue-router';
import {TenantBranch, TenantBranchLocation} from '@/types';
import {TemplateData} from '@/store/modules/document';
import {useTemplate} from '@/composables/useTemplate';
import {useBranch} from '@/composables/useBranch';

const router = useRouter();
const {getters} = useStore();

const {fetchTemplates, documentTemplates} = useTemplate();
const {createBranch} = useBranch();

const fleetLoading = computed(() => getters['fleet/loading']);
const documents = computed(() => {
  const templates = documentTemplates.value?.filter((t: TemplateData) => t?.documents);

  return templates.map((t: TemplateData) => ({
    name: t?.documents?.name,
    uuid: t?.documents?.uuid,
  }));
});

const currentWorkingBranch = ref<TenantBranch>({
  active: true,
  short_code: '',
  set: false,
  name: '',
  uuid: '',
  email: '',
  pickup_location: '',
  return_location: '',
  pickup_location_id: '',
  return_location_id: '',
  timezone: '',
  document: null,
  location_id: '',
  location: null,
  order: null,
  document_set: null,
});
const branchForm = ref(null);
const formErrors = ref(null);


const createFleetBranch = async (branch: TenantBranch) => {
  await createBranch(branch).then(() => {
    router.push({name: 'settings.fleet.branches'}).catch(() => {
    });
  }).catch((error) => {
    console.dir(error);
    formErrors.value = error.response.data.errors;
  });
};

const addLocationData = (locationData: TenantBranchLocation) => {
  currentWorkingBranch.value.location = locationData;
};

onBeforeMount(() => {
  fetchTemplates();

  currentWorkingBranch.value.timezone = guessTimeZone;
});
</script>
