import {computed, ComputedRef} from 'vue';
import {useStore} from 'vuex';
import {CompanyInterface, CompanyMeta, DefaultMeta, StoreLoaders} from '@/types';
import getDefaultMeta from '@/utils/meta';

export function useCompany() {
    const store = useStore();


    const companyToken = computed(() => store.getters['auth/token']);
    const company = computed<CompanyInterface>(() => store.getters['company/company']);
    const companySettings: ComputedRef<any> = computed(() => store.getters['company/settings']);
    const defaultMeta: ComputedRef<DefaultMeta> = computed(() => store.getters['company/defaultMeta']);
    const companyMeta = computed<CompanyMeta>(() => store.getters['company/meta']);
    const companyLoading: ComputedRef<StoreLoaders> = computed(() => store.getters['company/companyLoading']);
    const companyForm = computed(() => store.getters['company/companyForm']);
    const originalCompany = computed(() => store.getters['company/originalCompany']);
    const companyLoaded = computed(() => store.getters['company/loaded']);
    const registerFormRules = computed(() => store.getters['company/registerFormRules']);
    const operatingRules = computed(() => store.getters['company/operatingRules']);

    const companyMetaForm = computed(() => store.getters['company/companyMetaForm']);

    const companyTrialOver = computed(() => store.getters['company/trialOver']);


    const saveCompanyTimezone = (payload: { timezone: string, updateListings: boolean }) => {
        return store.dispatch('company/saveCompanyTimezone', payload);
    }

    const companyAdditionalScripts = computed<string | null>(() => getDefaultMeta('settings.custom.scripts') as string | null);
    const additionalStyles = computed<string | null>(() => getDefaultMeta('settings.custom.styles') as string | null);
    const companyFacebookPixelId = computed<string | null>(() => getDefaultMeta('settings.analytics.facebook_id') as string | null);
    const companyGoogleAnalyticsId = computed<string | null>(() => getDefaultMeta('settings.analytics.google_id') as string | null);

    function addDefaultMeta(data: { meta: ({ value: any; key: string })[] }) {
        try {
            return store.dispatch('company/addDefaultMeta', data);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    function fetchDefaultMeta() {
        return store.dispatch('company/getDefaultMeta');
    }

    function saveCompanyInfo() {
        return store.dispatch('company/saveCompanyInfo');
    }


    const saveCompanyMeta = async () => {
        return await store.dispatch('company/saveCompanyMeta');
    }

    function getActivityLog(payload: { page?: number, perPage?: number }) {
        return store.dispatch('company/getActivityLog', payload);
    }

    const showSetupChecklist = computed(() => {
        return (companySettings.value?.['settings.setup_checklist.show_setup_checklist'] ?? false)
            && !(companySettings.value?.['settings.setup_checklist.hide'] ?? false);
    })

    const localizationDistanceUnits = computed(() => {
        return getDefaultMeta('localization_distance_units') ?? 'miles';
    });

    const localizationDistanceUnitsObject = computed(() => {
        if (localizationDistanceUnits.value === 'kilometers') {
            return {
                label: 'Kilometer',
                label_plural: 'Kilometers',
                label_abr: 'km',
            };
        }
        return {
            label: 'Mile',
            label_plural: 'Miles',
            label_abr: 'mi',
        };
    })

    const setupCustomStyles = () => {
        if (!additionalStyles.value) {
            return; // Exit early if there are no additional styles
        }

        // Check if the style element already exists in the DOM
        const existingStyleElement = document.getElementById('FWAdditionalStyles') as HTMLStyleElement | null;


        if (!existingStyleElement) {
            const companyAdditionalStyle = document.createElement('style');
            companyAdditionalStyle.setAttribute('id', 'FWAdditionalStyles');
            companyAdditionalStyle.setAttribute('type', 'text/css');
            const customStyles = additionalStyles.value;

            try {
                companyAdditionalStyle.appendChild(document.createTextNode(customStyles));
                document.body.appendChild(companyAdditionalStyle);
            } catch (e) {
                try {
                    companyAdditionalStyle.textContent = customStyles;
                    document.body.appendChild(companyAdditionalStyle);
                } catch (e) {
                    console.error(e);
                }
            }
        }
    }

    const setupCustomScripts = () => {
        if (companyAdditionalScripts.value) {
            if (!document.getElementById('FWAdditionalScripts')) {
                const companyAdditionalScript = document.createElement('script');
                companyAdditionalScript.setAttribute('id', 'FWAdditionalScripts');
                const customScripts = stripScripts(companyAdditionalScripts.value);

                try {
                    companyAdditionalScript.appendChild(document.createTextNode(customScripts));
                    document.body.appendChild(companyAdditionalScript);
                } catch (e) {
                    try {
                        companyAdditionalScript.text = customScripts;
                        document.body.appendChild(companyAdditionalScript);
                    } catch (e) {
                        console.error(e);
                    }
                }
            }
        }
    }

    const stripScripts = (s: string) => {
        if (s) {
            /** Remove noscript */
            const noscriptRegex = /<noscript\b[^<]*(?:(?!<\/noscript>)<[^<]*)*<\/noscript>/gi;
            s = s.replace(noscriptRegex, '');

            const div = document.createElement('div');
            div.innerHTML = s;

            const scripts = div.getElementsByTagName('script');
            let stripScript = '<!-- Fleetwire Custom Script --> \n\n';

            for (let i = scripts.length - 1; i >= 0; i--) {
                if (scripts[i].innerText) {
                    const scriptContent = scripts[i].innerText;
                    stripScript += `${scriptContent}; \n\n`;
                }
                scripts[i].parentNode?.removeChild(scripts[i]);
            }

            stripScript += div.textContent?.trim() || '';

            return stripScript;
        }
        return '';
    }


    return {
        company,
        companyToken,
        companyTrialOver,
        companyFacebookPixelId,
        companyGoogleAnalyticsId,
        originalCompany,
        companySettings,
        companyLoading,
        defaultMeta,
        companyMeta,
        showSetupChecklist,
        companyForm,
        localizationDistanceUnits,
        localizationDistanceUnitsObject,
        registerFormRules,
        companyLoaded,
        companyMetaForm,
        operatingRules,
        setupCustomStyles,
        setupCustomScripts,
        getActivityLog,
        saveCompanyInfo,
        saveCompanyMeta,
        addDefaultMeta,
        fetchDefaultMeta,
        saveCompanyTimezone,
    }
}