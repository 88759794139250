import {useStore} from 'vuex';
import {computed} from 'vue';
import {UpdateOrderPayload} from '@/store/modules/order';
import {OrderDocumentType, OrderLine} from '@/types';


export function useOrders() {
    const store = useStore();


    const orderLoading = computed<Record<string, boolean>>(() => store.getters['order/loading']);
    const theNewOrder = computed(() => store.getters['order/order']);
    const orderDialogs = computed(() => store.getters['order/orderDialogs']);
    const orderDocument = computed<OrderDocumentType>(() => store.getters['order/document']);
    const currentWorkingPricingWizardOrderLine = computed(() => store.getters['order/currentWorkingPricingWizardOrderLine']);


    const orderAvailability = computed(() => store.getters['order/availability']);

    const updateOrderLine = async (data) => {
        return await store.dispatch('order/updateLine', data);
    }

    const deleteOrderDocument = async (documentUuid: string) => {
        return await store.dispatch('order/deleteOrderDocument', documentUuid);
    }

    const setOrderDocument = async (data) => {
        return store.commit('order/SET_ORDER_DOCUMENT', data);
    }

    const checkOrderAvailability = async (orderUuid: string) => {
        return await store.dispatch('order/availability', orderUuid);
    }

    const getOrderItemModel = async (data) => {
        return await store.dispatch('order/getOrderItemModel', data);
    }

    const getOrderDocuments = async (orderUuid: string) => {
        return await store.dispatch('order/getOrderDocuments', orderUuid);
    }

    const getOrderDocument = async (documentUuid: string) => {
        return await store.dispatch('order/getOrderDocument', documentUuid);
    }

    const updateOrderDocument = async (data) => {
        return await store.dispatch('order/updateOrderDocument', data);
    }

    const getNewOrder = async () => {
        return await store.dispatch('order/newOrder');
    }

    const addOrderLine = async (data: { orderUuid: string, line: OrderLine }) => {
        return await store.dispatch('order/addLine', data);
    }

    /**
     * Fetches a specific order by ID.
     */
    const getOrder = (id: string) => store.dispatch('order/getOrder', id);

    const updateNewOrder = async (data: UpdateOrderPayload) => {
        return await store.dispatch('order/update', data);
    }

    const setOrder = async (data) => {
        console.dir(data);
        return store.commit('order/SET_ORDER', data);
    }

    /**
     * Removes an order line.
     */
    const removeOrderLine = (lineId: string) => {
        return store.dispatch('order/removeLine', lineId);
    };

    /**
     * Retrieves order lines safely.
     */
    const orderLines = computed<OrderLine[]>(() => theNewOrder.value?.lines || []);

    const openPricingWizardDialog = (open: boolean = false, lineItem: OrderLine) => {
        console.dir('openPricingWizardDialog');
        console.dir(lineItem);
        console.dir('^^^^^');
        return store.commit('order/SET_OPEN_PRICING_WIZARD_DIALOG', {open, lineItem});
    }

    return {
        orderLoading,
        theNewOrder,
        orderLines,
        orderDialogs,
        currentWorkingPricingWizardOrderLine,
        orderAvailability,
        orderDocument,
        setOrderDocument,
        checkOrderAvailability,
        getNewOrder,
        addOrderLine,
        openPricingWizardDialog,
        updateOrderLine,
        getOrder,
        setOrder,
        updateNewOrder,
        removeOrderLine,
        getOrderDocuments,
        getOrderDocument,
        updateOrderDocument,
        getOrderItemModel,
        deleteOrderDocument,
    }
}