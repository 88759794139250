import axios from 'axios';
import {EventBus} from '@/plugins/eventBus';
import {get} from 'lodash';
import {Form} from '@/plugins/FWForms/index';
import * as types from '../mutation-types';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {createUrlQueryString} from '@/utils/urls';
import {ESignerFieldData, RenterInterface, StoreLoaders} from '@/types';
import {TemplateData} from '@/store/modules/document';


export interface EnvelopeRecipientsInterface {
    completed_at: string;
    created_at: string;
    data: ESignerFieldData[];
    envelope_id_stamped: string;
    opened_at: string;
    recipient: string;
    renter: RenterInterface;
    sent_at: string;
    signature: string;
    signature_style: string;
    status: string;
    template_id: string;
    updated_at: string;
    uuid: string;
}

export interface EnvelopeInterface {
    attachments: EnvelopeAttachmentInterface[];
    completed: boolean;
    completed_at: string;
    created_at: string;
    data: ESignerFieldData[];
    file_path: string;
    opened: boolean;
    opened_at: string;
    order_document: string;
    pdf_path: string;
    pdf_url: string;
    recipient_uuid: string;
    recipients: EnvelopeRecipientsInterface[];
    renter: RenterInterface;
    signature: any;
    status: string;
    updated_at: string;
    white_label: boolean;
    uuid: string;
    template?: TemplateData;
    logs?: {
        created_at: string;
        action: string;
        ip: string;
        actor: string;
    }[];
}

export interface SaveProgressPayload {
    uuid: string,
    recipientId: string,
    allSigned: boolean,
    ip: string,
    data: ESignerFieldData[],
    sample?: boolean
}

// AcceptEnvelopePayload extends SaveProgressPayload
export interface AcceptEnvelopePayload extends SaveProgressPayload {
    recipientKey: string
}

export interface SaveSignaturePayload {
    uuid: string,
    recipientId: string,
    signature: string,
    sample?: boolean,
    mode: 'draw' | 'typed',
    recipientKey: string
}

export interface RemoveAttachmentPayload {
    attachmentId: string;
    uuid: string;
    recipientId: string;
    sample?: boolean;
}

export interface EnvelopeAttachmentInterface {
    attachment_id: string;
    document_images: string[];
    envelope_id: number;
    name: string;
    order: number;
    created_at?: string;
    updated_at?: string;
    url?: string;
    uuid?: string;
}

export interface EnvelopePagination {
    count: number;
    current_page: number;
    next_page: string;
    per_page: number;
    total: number;
    total_pages: string;
    from: number;
    to: number;
    last_page: number;
    total_envelopes: number;
}

interface EnvelopeResponse {
    data: EnvelopeInterface[];
    meta: EnvelopePagination;
    links: object;
}

export interface EnvelopePage {
    page: number;
    pageData: ESignerFieldData[];
    path: string; // URL to the page image
}

interface EsignSessionData {
    ip: string;
    windowWidth?: number;
    contentMainWidth: number;
    contentMainHeight: number;
    remainingFieldsCount: number;
    envelopeId: string;
    recipientId: string;
    recipientKey: string;
    totalFields: number;
}

export interface EnvelopeState {
    envelopesPagination: {
        current_page: number;
        from: number;
        last_page: number;
        per_page: number;
        to: number;
        total: number;
        total_envelopes: number;
    },
    init: boolean;
    loading: StoreLoaders;
    document: any[];
    template: any;
    envelopes: EnvelopeInterface[];
    envelope: EnvelopeInterface;
    envelopeIsReady: boolean;
    allEnvelopeData: ESignerFieldData[];
    envelopeForm: Form<object>;
    envelopeCustomer: any;
    envelopeDialogs: Record<string, boolean>;
    envelopesQuery?: {
        page?: number,
        itemsPerPage?: number,
        sortBy?: string[],
        sortDesc?: boolean[],
        filter?: {
            tag_list?: string[],
            archived?: boolean,
            search?: string,
            status?: string[],
            date?: {
                gte?: string,
                lte?: string,
            }
        },
        include?: string,
    },
    esignSessionData: EsignSessionData,
    esignEnvelopeDataCopy: EnvelopePage[],
}

const state: EnvelopeState = {
    init: false,
    loading: {
        envelope: false,
        envelopes: false,
        saving: false,
        accepting: false,
        voiding: false,
        createEnvelope: false,
        saveSignature: false,
        deleteAttachment: false,
        resetEnvelopeData: false,
        savingProgress: false,
        resendEnvelope: false,
        reminder: false,
    },
    document: [],
    template: {},
    envelopes: [],
    envelope: null,
    envelopeIsReady: false,
    allEnvelopeData: [],
    envelopeForm: new Form(),
    envelopeCustomer: {},
    envelopeDialogs: {
        newEnvelope: false,
    },

    envelopesQuery: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['created_at'],
        sortDesc: [true],
        filter: {
            tag_list: [],
            archived: false,
            search: '',
            status: [],
            date: {
                gte: '',
                lte: '',
            }
        },
        include: '',
    },
    envelopesPagination: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
        total_envelopes: 0,
    },
    esignSessionData: {
        ip: '',
        totalFields: 0,
        windowWidth: window.innerWidth,
        contentMainWidth: 0,
        contentMainHeight: 0,
        remainingFieldsCount: 0,
        envelopeId: null,
        recipientId: null,
        recipientKey: null,
    },
    esignEnvelopeDataCopy: [],
};

const getters = {
    init: (state: EnvelopeState) => state.init,
    envelopeDialogs: (state: EnvelopeState) => state.envelopeDialogs,
    esignSessionData: (state: EnvelopeState) => state.esignSessionData,
    esignEnvelopeDataCopy: (state: EnvelopeState) => state.esignEnvelopeDataCopy,
    loading: (state: EnvelopeState) => state.loading,
    document: (state: EnvelopeState) => state.document,
    template: (state: EnvelopeState) => state.template,
    envelopes: (state: EnvelopeState) => state.envelopes,
    envelope: (state: EnvelopeState) => state.envelope,
    envelopeIsReady: (state: EnvelopeState) => state.envelopeIsReady,
    allEnvelopeData: (state: EnvelopeState) => state.allEnvelopeData,
    envelopeForm: (state: EnvelopeState) => state.envelopeForm,
    envelopeCustomer: (state: EnvelopeState) => state.envelopeCustomer,
    envelopesQuery: (state: EnvelopeState) => state.envelopesQuery,
    envelopesPagination: (state: EnvelopeState) => state.envelopesPagination,
};

const mutations = {
    [types.SET_ENVELOPE_LOADING](state: EnvelopeState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_ESIGN_SESSION_DATA](state: EnvelopeState, data: { key: string, value: any } | Record<string, any>) {
        if (typeof data.key === 'string' && data.hasOwnProperty('value')) {
            // Single key-value pair update
            if (!state.esignSessionData.hasOwnProperty(data.key)) {
                console.error(`Key "${data.key}" does not exist in esignSessionData.`);
                return;
            }
            state.esignSessionData[data.key] = data.value;
        } else if (typeof data === 'object') {
            // Multiple key-value pair updates
            Object.entries(data).forEach(([key, value]) => {
                if (!state.esignSessionData.hasOwnProperty(key)) {
                    console.warn(`Key "${key}" does not exist in esignSessionData.`);
                    return;
                }
                state.esignSessionData[key] = value;
            });
        } else {
            console.error('Invalid data format for SET_ESIGN_SESSION_DATA mutation.');
        }
    },

    [types.SET_ALL_ENVELOPES](state: EnvelopeState, envelopes: EnvelopeResponse) {
        console.dir('SET_ALL_ENVELOPES');
        console.dir(envelopes);
        state.envelopes = envelopes.data;

        const meta = envelopes?.meta;
        if (meta) {
            state.envelopesPagination = {
                current_page: meta.current_page,
                from: meta.from,
                last_page: meta.last_page,
                per_page: meta.per_page,
                to: meta.to,
                total: meta.total,
                total_envelopes: meta.total_envelopes,
            }
        }
    },
    [types.SET_ENVELOPE](state: EnvelopeState, envelope: EnvelopeInterface) {
        state.envelope = envelope;
        state.envelopeIsReady = true;
    },
    [types.SET_ENVELOPE_SIGNATURE](state: EnvelopeState, signature) {
        state.envelope.signature = signature;
    },
    [types.SET_ENVELOPE_CUSTOMER](state: EnvelopeState, customer) {
        state.envelopeCustomer = customer;
    },
    [types.UPDATE_ENVELOPE_FIELD](state: EnvelopeState, data: ESignerFieldData) {
        console.dir('UPDATE_ENVELOPE_FIELD');
        console.dir(data);

        const pageIndex = data.page - 1;
        const pageData = state.esignEnvelopeDataCopy[pageIndex].pageData;
        const fieldIndex = pageData.findIndex((f: ESignerFieldData) => f.id === data.id);

        if (fieldIndex !== -1) {
            state.esignEnvelopeDataCopy[pageIndex].pageData[fieldIndex] = {...pageData[fieldIndex], ...data};
        }
    },
    [types.SET_ALL_FIELDS](state: EnvelopeState, payload: { data: ESignerFieldData[], pageImages: string[] }) {
        console.dir('SET_ALL_FIELDS');
        console.dir(payload);
        state.allEnvelopeData = payload.data;


        const fields = payload.data.sort((a, b) => a.page - b.page);

        state.esignEnvelopeDataCopy = payload.pageImages.map((p, index) => {
            const pageNumber = index + 1;
            let pageData = fields.filter((f) => f.page === pageNumber) || [];

            pageData = pageData.map((d) => {
                const data = d;
                const updated = d?.updated;
                const title = d?.title;

                if (d.type === 'field') {
                    const filled = get(d, 'filled', false);
                    return {
                        ...data,
                        filled,
                        text: filled ? data.text : '',
                        placeholder: data.text,
                        toolTip: data.text,
                        title: title || data.text,
                    };
                }
                if (d.type === 'text') {
                    return {
                        ...data,
                        filled: d?.filled || true,
                        placeholder: data.text,
                        text: data.readOnly || updated ? data.text : '',
                        title: title || data.text,
                    };
                }
                if (d.type === 'attachment') {
                    return {
                        ...data,
                        attached: d?.attached || false,
                        filled: d?.filled || false,
                        url: d?.url || null,
                    };
                }
                if (d.type === 'checkbox') {
                    return {
                        ...data,
                        toolTip: data.fieldName,
                    };
                }

                return {
                    ...d,
                    signed: false,
                    filled: false,
                    src: '',
                    toolTip: 'Sign Here',
                };
            });

            return {
                page: pageNumber,
                path: p,
                pageData,
            }
        });
    },
    [types.SET_ENVELOPE_DIALOG](state: EnvelopeState, data: { key: string, value: boolean }) {
        state.envelopeDialogs[data.key] = data.value;
    },
    [types.SET_ENVELOPE_QUERY](state: EnvelopeState, data) {
        console.dir('SET_ENVELOPE_QUERY');
        console.dir(data);

        state.envelopesQuery[data.key] = data.value;
    },
};

const actions = {

    /**
     * Get an envelope
     * @throws {Error}
     */
    async getEnvelopeV2({commit}, {uuid, recipientId, recipientKey, sample}: {
        uuid: string;
        recipientId: string;
        recipientKey?: string;
        sample?: boolean;
    }) {
        const queryParams = new URLSearchParams();
        if (sample) queryParams.append('sample', 'true');
        if (recipientKey) queryParams.append('key', recipientKey);


        // Determine request path
        const queryString = queryParams.toString() ? `?${queryParams.toString()}` : '';
        const requestPath = sample ? `/sample/${queryString}` : `${queryString}`;

        const responseData = await performApiRequest(commit, 'SET_ENVELOPE_LOADING', 'getEnvelope', () => axios.get(`/v1/envelope-v2/${uuid}/${recipientId}${requestPath}`));
        const envelope = responseData?.envelope ?? [];
        const envelopeCustomer = responseData?.envelopeCustomer ?? {};
        const customerData = envelopeCustomer?.data ?? envelope?.data ?? [];
        console.log(`customerData is array: ${Array.isArray(customerData)}`);

        commit(types.SET_ALL_FIELDS, {
            data: customerData,
            pageImages: envelope?.template?.documents?.document_images ?? []
        });
        commit(types.SET_ENVELOPE, envelope);
        commit(types.SET_ENVELOPE_CUSTOMER, envelopeCustomer);
    },

    /**
     * Reset an envelope
     */
    async getEnvelopes({commit}) {
        console.dir('getEnvelopes');
        try {
            const options = state.envelopesQuery;

            const {sortBy = [], sortDesc = [], filter = {}} = options;

            const queryObject = {
                'sort': sortBy.length > 0 ? (sortDesc?.[0] ? `-${sortBy[0]}` : sortBy[0]) : null,
                'filter[tag_list]': filter.tag_list || null,
                'filter[archived]': filter.archived ?? null,
                'filter[q]': filter.search || null,
                'filter[status]': filter.status || null,
                'page': options.page || 1,
                'itemsPerPage': options.itemsPerPage || 30,
                'include': options.include,
                'filter[date][gte]': filter?.date?.gte || null,
                'filter[date][lte]': filter?.date?.lte || null,
            };

            // Convert the object into a query string
            const query = createUrlQueryString(queryObject, true);
            console.log('✅ API Query String:', query);

            const responseData = await performApiRequest(commit, 'SET_ENVELOPE_LOADING', 'envelopes', () => axios.get(`/v1/envelope${query}`));
            commit(types.SET_ALL_ENVELOPES, responseData);
        } catch (error) {
        }
    },

    async createEnvelope({commit}, data) {
        try {
            const responseData = await performApiRequest(commit, 'SET_ENVELOPE_LOADING', 'createEnvelope', () => axios.post('/v1/envelope', data));
            commit(types.SET_ENVELOPE, responseData.envelope);
            EventBus.$emit('create-envelope-success');
        } catch (error) {
            EventBus.$emit('SystemMessage', {
                type: 'error',
                message: error?.response?.data?.message || 'There was an error.',
            });
        }
    },


    /**
     * Reset an envelope
     */
    resetEnvelopeData({commit}, uuid: string) {
        commit('SET_ENVELOPE_LOADING', {subject: 'resetEnvelopeData', loading: true});

        axios.post(`/v1/envelope/${uuid}/reset`)
            .then((response) => {
                commit(types.SET_ENVELOPE, get(response, 'data.envelope', []));
            }).catch((e) => {
            EventBus.$emit('SystemMessage', {
                type: 'error',
                message: get(e, 'response.data.message', 'There was an error.'),
            });
        }).finally(() => {
            commit('SET_ENVELOPE_LOADING', {subject: 'resetEnvelopeData', loading: false});
        });
    },

    /**
     * Resend an envelope
     */
    resendEnvelope({commit}, {uuid, recipients}) {
        commit('SET_ENVELOPE_LOADING', {subject: 'resendEnvelope', loading: true});

        axios.post(`/v1/envelope/${uuid}/resend`, {recipients})
            .then(() => {
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Resend successful',
                });
            }).catch((e) => {
            EventBus.$emit('SystemMessage', {
                type: 'error',
                message: get(e, 'response.data.message', 'There was an error.'),
            });
        }).finally(() => {
            commit('SET_ENVELOPE_LOADING', {subject: 'resendEnvelope', loading: false});
        });
    },


    /**
     * Save an envelope customer signature
     */
    async saveSignature({state, commit}, data: SaveSignaturePayload) {

        let queryString = '';
        if (get(data, 'sample')) {
            queryString = '/sample';
        }

        try {
            const responseData = await performApiRequest(commit, 'SET_ENVELOPE_LOADING', 'saveSignature', () => axios.post(`/v1/envelope/${data.uuid}/${data.recipientId}/signature${queryString}`, data));
            EventBus.$emit('saved-signature-success', responseData);
            commit(types.SET_ENVELOPE_SIGNATURE, responseData.envelope.signature || '');
            commit(types.SET_ENVELOPE_CUSTOMER, responseData.customer);

            state.envelopeForm.finishProcessing();
        } catch (error) {
        }
    },

    async removeAttachment({commit}, data: RemoveAttachmentPayload) {
        let queryString = '';
        if (get(data, 'sample')) {
            queryString = '/sample';
        }
        return await performApiRequest(commit, 'SET_ENVELOPE_LOADING', 'deleteAttachment', () => axios.delete(`/v1/envelope/${data.uuid}/${data.recipientId}/attachment/${data.attachmentId}${queryString}`));
    },

    /**
     * Saves the progress of the e-sign document
     */
    async saveProgress({commit}, data: SaveProgressPayload) {
        return await performApiRequest(commit, 'SET_ENVELOPE_LOADING', 'savingProgress', () => axios.put(`/v1/envelope/${data.uuid}/${data.recipientId}`, data));
    },

    /**
     * Prints the progress of the e-sign document
     * Handy for signing on paper
     */
    printProgress({commit}, data) {
        commit('SET_ENVELOPE_LOADING', {subject: 'savingProgress', loading: true});
        axios.post(`/v1/envelope/${data.uuid}/${data.recipientId}/print`, data)
            .then((response) => {
                EventBus.$emit('esign-progress-printed-success', response);
            }).catch(() => {
        }).finally(() => {
            commit('SET_ENVELOPE_LOADING', {subject: 'savingProgress', loading: false});
        });
    },


    /**
     * Accept agreement and sign
     * @throws {Error}
     */
    async acceptAndSignV2({commit}, data: AcceptEnvelopePayload) {
        const responseData = await performApiRequest(commit, 'SET_ENVELOPE_LOADING', 'accepting', () => axios.post(`/v1/envelope-v2/${data.uuid}/${data.recipientId}/accept`, data));
        commit(types.SET_ENVELOPE, responseData.envelope);
    },

    async voidEnvelope({state, commit}, envelope: EnvelopeInterface) {
        try {
            await performApiRequest(commit, 'SET_ENVELOPE_LOADING', 'voiding', () => axios.post(`/v1/envelope/${envelope.uuid}/void`));
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Envelope voided successfully!',
            });
        } catch (error) {
            state.envelopeForm.errors.set(error?.response?.data?.errors || []);
        }
    },


    async downloadProgress({state, commit}, envelope: EnvelopeInterface) {
        try {
            const responseData = await performApiRequest(commit, 'SET_ENVELOPE_LOADING', 'downloading-progress', () => axios.post(`/v1/envelope/${envelope.uuid}/download-progress`));
            window.open(responseData.url, '_blank');
        } catch (error) {
            state.envelopeForm.errors.set(error?.response?.data?.errors || []);
        }
    },

    sendReminder({state, commit}, data: { envelope: EnvelopeInterface, email: string }) {
        commit('SET_ENVELOPE_LOADING', {subject: 'reminder', loading: true});

        axios.post(`/v1/envelope/${data.envelope.uuid}/reminder`, {email: data.email})
            .then((response) => {
                EventBus.$emit('envelope-reminder-success', get(response, 'data.envelope', []));
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Envelope reminder sent successfully!',
                });
            }).catch((e) => {
            state.envelopeForm.errors.set(get(e, 'response.data.errors', []));
        }).finally(() => {
            commit('SET_ENVELOPE_LOADING', {subject: 'reminder', loading: false});
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
