import {useStore} from 'vuex';
import {computed} from 'vue';
import {TemplateData} from '@/store/modules/document';
import {ESignerFieldData, ESignTool} from '@/types';

export function useTemplate() {
    const store = useStore();

    // 🔹 Computed Properties
    const documentTemplates = computed<TemplateData[]>(() => store.getters['document/templates']);
    const documentsLoading = computed(() => store.getters['document/loading']);
    const selectedFieldV2 = computed<ESignerFieldData>(() => store.getters['template/selectedFieldV2']);
    const template = computed<TemplateData | null>(() => store.getters['template/template']);
    const allTools = computed<ESignTool[]>(() => store.getters['template/allTools']);
    const currentWorkingTool = computed(() => store.getters['template/currentWorkingTool']);
    const documentFieldData = computed(() => store.getters['template/documentFieldData']);
    const toolsActive = computed(() => store.getters['template/toolsActive']);
    const templateDocument = computed(() => template.value?.documents);
    const documentImages = computed(() => store.getters['template/documentImages']);
    const selectedFieldIndexV2 = computed(() => store.getters['template/selectedFieldIndexV2']);
    const signerItems = computed(() => store.getters['template/signerItems']);

    const documentFileName = computed(() => {
        return template.value?.documents.name ?? '';
    });

    const templateStageData = computed<ESignerFieldData[]>(() => {
        return templateDocument.value?.data ?? [];
    })

    const templateDocumentImages = computed(() => {
        return templateDocument.value?.document_images ?? [];
    });
    const selectedFieldV3Coordinates = computed(() => {
        console.dir('selectedFieldV3Coordinates');
        // Check if selectedFieldV2 is defined
        if (!selectedFieldV2.value) {
            return {x: 0, y: 0};
        }

        const x = selectedFieldV2.value.x || 0;
        const y = selectedFieldV2.value.y || 0;

        return {x, y};
    });

    const currentSigner = computed({
        get: () => store.getters['template/currentSigner'],
        set: (signer) => store.commit('template/SET_FIELD_CURRENT_SIGNER', signer)
    });

    const fetchTemplates = async () => {
        return await store.dispatch('document/fetchTemplates');
    }

    const setDocumentImages = (images) => store.commit('template/SET_DOCUMENT_IMAGES', images);
    const setTool = (toolUsed: string) => store.commit('template/SET_CURRENT_WORKING_TOOL', toolUsed)
    const updateStageData = (payload: {
        data: ESignerFieldData,
        index: number
    }) => store.commit('template/UPDATE_DOCUMENT_TEMPLATE_DATA', payload);
    const removeAllStageData = () => store.commit('template/REMOVE_ALL_FIELDS');
    const addToStageData = (payload: ESignerFieldData) => store.commit('template/ADD_TO_DOCUMENT_TEMPLATE_DATA', payload);
    const setSingleTemplate = (template: TemplateData) => store.commit('template/SET_ONE_TEMPLATE', template);
    const deleteSelectedElementsV2 = () => store.dispatch('template/deleteSelectedElementsV2');
    const updateSelectedField = (field: ESignerFieldData) => store.commit('template/SET_SELECTED_FIELD_V2', field);
    const setToolsActive = (isActive: boolean) => store.commit('template/SET_TOOLS_ACTIVE', isActive);

    const toggleRightSidebar = () => {
        store.commit('template/SET_SELECTED_FIELD_V2', null);
        store.commit('template/SET_SELECTED_FIELD_INDEX_V2', 0);
    }


    const makeFieldId = (length = 8): string => {
        const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';
        return Array.from({length}, () =>
            characters.charAt(Math.floor(Math.random() * characters.length))
        ).join('');
    };
    //ESignerFieldData
    const templateStageDataGroupedByPage = computed<{ [key: number]: ESignerFieldData[] }>(() => {
        const data: ESignerFieldData[] = templateStageData.value;
        const grouped: { [key: number]: ESignerFieldData[] } = {};

        data.forEach((item) => {
            const page = item.page;
            if (!grouped[page]) {
                grouped[page] = [];
            }
            grouped[page].push(item);
        });
        return grouped;
    });

    const removeField = (field: ESignerFieldData) => {
        store.commit('template/REMOVE_FIELD', field);
    }
    const scrollToField = (field: ESignerFieldData) => {
        const documentViewer = document.querySelector('#esign-editor-main-content');
        if (documentViewer) {
            documentViewer.scrollTo({
                top: field.relativeY,
                left: 0,
                behavior: 'smooth',
            });
        }
    }


    return {
        templateStageData,
        allTools,
        template,
        documentsLoading,
        documentTemplates,
        selectedFieldV2,
        documentFieldData,
        currentWorkingTool,
        selectedFieldV3Coordinates,
        templateDocumentImages,
        documentImages,
        toolsActive,
        currentSigner,
        documentFileName,
        selectedFieldIndexV2,
        signerItems,
        templateStageDataGroupedByPage,
        removeField,
        scrollToField,
        setSingleTemplate,
        addToStageData,
        setDocumentImages,
        updateStageData,
        updateSelectedField,
        toggleRightSidebar,
        deleteSelectedElementsV2,
        setTool,
        fetchTemplates,
        makeFieldId,
        removeAllStageData,
        setToolsActive
    }
}