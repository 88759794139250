import {asyncRoutes, constantRoutes} from '@/router/routes';
import * as types from '../mutation-types';
import type {RouteMeta, RouteRecordRaw} from 'vue-router';


/**
 * Check if it matches the current user right by 'meta.role'
 */
function canAccess(roles: string[], permissions: string[], route: RouteRecordRaw): boolean {
    const meta: RouteMeta | undefined = route.meta;

    if (!meta) return true; // If no meta, allow access

    let hasRole = true;
    let hasPermission = true;

    if (meta.roles || meta.permissions) {
        hasRole = meta.roles ? roles.some(role => meta.roles!.includes(role)) : false;
        hasPermission = meta.permissions ? permissions.some(permission => meta.permissions!.includes(permission)) : false;
    }

    return hasRole || hasPermission;
}

/**
 * Find all routes of this role
 * @param routes asyncRoutes
 * @param roles
 * @param permissions
 */
function filterAsyncRoutes(
    routes: RouteRecordRaw[],
    roles: string[],
    permissions: string[]
): RouteRecordRaw[] {
    const res: RouteRecordRaw[] = [];

    console.log('%c 🎯 Filtering Async Routes...', 'color: blue; font-weight: bold;');


    routes.forEach((route) => {
        const tmp: RouteRecordRaw = {...route};

        console.log('%c 🔍 Evaluating Route:', 'color: purple; font-weight: bold;', tmp);

        if (canAccess(roles, permissions, tmp)) {
            console.log('%c ✅ Access Granted', 'color: green; font-weight: bold;', tmp.path);

            if (tmp.children) {
                tmp.children = filterAsyncRoutes(tmp.children, roles, permissions);
            }

            res.push(tmp);
        } else {
            console.log('%c 🚫 Access Denied', 'color: red; font-weight: bold;', tmp.path);
        }
    });

    return res;
}

export interface PermissionState {
    routes: any[];
    addRoutes: any[];
}

const state: PermissionState = {
    routes: [],
    addRoutes: [],
};

const getters = {
    permission_routes: (state: PermissionState) => state.routes,
};

const mutations = {
    [types.SET_ROUTES](state: PermissionState, routes: RouteRecordRaw[]) {
        console.dir('SET_ROUTES');
        console.dir(routes);
        state.addRoutes = routes;
        state.routes = constantRoutes.concat(routes);
    },
};

const actions = {
    generateRoutes({commit}, {roles, permissions}) {
        console.log('%c 🚀 Generating Routes...', 'color: red; font-weight: bold;');

        return new Promise((resolve) => {
            try {
                let accessedRoutes: RouteRecordRaw[];

                if (roles.includes('admin')) {
                    console.log('%c 🎩 Admin detected - Granting full access...', 'color: green; font-weight: bold;');
                    accessedRoutes = asyncRoutes;
                } else {
                    console.log('%c 🔍 Filtering routes based on roles & permissions...', 'color: blue; font-weight: bold;');
                    accessedRoutes = filterAsyncRoutes(asyncRoutes, roles, permissions);
                }

                console.log('%c ✅ Routes successfully generated!', 'color: lime; font-weight: bold;');
                commit(types.SET_ROUTES, accessedRoutes);

                resolve(accessedRoutes);
            } catch (error) {
                console.error('%c ❌ Error in generateRoutes:', 'color: red; font-weight: bold;', error);
                resolve([]); // Resolve with an empty array to prevent breaking functionality
            }
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
