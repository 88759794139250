import {useStore} from 'vuex';
import {computed} from 'vue';
import {EmailDomainSettings} from '@/store/modules/email-domain-settings';

export function useEmailDomainSettings() {
    const store = useStore();

    const emailDomainSettingsLoading = computed(() => store.getters['emailDomainSettings/loading']);
    const emailDomainSettings = computed(() => store.getters['emailDomainSettings/emailDomainSettings']);


    const fetchEmailDomain = async () => {
        return await store.dispatch('emailDomainSettings/fetchEmailDomain');
    }

    const createEmailDomain = async (payload: any) => {
        return await store.dispatch('emailDomainSettings/createEmailDomain', payload);
    }

    const deleteEmailDomain = async (domainId: EmailDomainSettings) => {
        return await store.dispatch('emailDomainSettings/deleteEmailDomain', domainId);
    }

    const emailDomainCheckDKIM = async (domainId: EmailDomainSettings) => {
        return await store.dispatch('emailDomainSettings/emailDomainCheckDKIM', domainId);
    }

    const emailDomainCheckReturnPathStatus = async (domainId: EmailDomainSettings) => {
        return await store.dispatch('emailDomainSettings/emailDomainCheckReturnPathStatus', domainId);
    }

    const setAsDefaultSendingDomain = async (domainId: EmailDomainSettings) => {
        return await store.dispatch('emailDomainSettings/setAsDefaultSendingDomain', domainId);
    }

    const sendTestEmail = async (payload: string) => {
        return await store.dispatch('emailDomainSettings/sendTestEmail', payload);
    }

    const updateEmailDomainUsername = async (payload: EmailDomainSettings) => {
        return await store.dispatch('emailDomainSettings/updateEmailDomainUsername', payload);
    }

    return {
        emailDomainSettingsLoading,
        emailDomainSettings,
        fetchEmailDomain,
        createEmailDomain,
        deleteEmailDomain,
        emailDomainCheckDKIM,
        emailDomainCheckReturnPathStatus,
        setAsDefaultSendingDomain,
        sendTestEmail,
        updateEmailDomainUsername
    }
}