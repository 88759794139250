import {computed} from 'vue';
import {useStore} from 'vuex';
import {
    AcceptEnvelopePayload,
    EnvelopeInterface,
    EnvelopePage,
    RemoveAttachmentPayload,
    SaveProgressPayload,
    SaveSignaturePayload,
} from '@/store/modules/envelope';
import {DocumentType, TemplateData} from '@/store/modules/document';
import {ESignerFieldData, StoreLoaders} from '@/types';

export type NewEnvelope = {
    b_id: string,
    template_uuid: string,
    send_method: {
        email: boolean,
        sms: boolean,
    },
}

export interface envelopeDataByPageInterface {
    page: number;
    path: string;
    pageData: ESignerFieldData[];
}

export function useEnvelope() {
    const store = useStore();

    const envelope = computed<EnvelopeInterface>(() => store.getters['envelope/envelope']);
    const envelopes = computed(() => store.getters['envelope/envelopes']);

    const envelopeLoading = computed<StoreLoaders>(() => store.getters['envelope/loading']);
    const envelopeDialogs = computed(() => store.getters['envelope/envelopeDialogs']);
    const envelopeForm = computed(() => store.getters['envelope/envelopeForm']);
    const envelopesQuery = computed(() => store.getters['envelope/envelopesQuery']);
    const envelopesPagination = computed(() => store.getters['envelope/envelopesPagination']);
    const totalRemainingFields = computed<number>(() => store.getters['envelope/totalRemainingFields']);
    const envelopeIsReady = computed<boolean>(() => store.getters['envelope/envelopeIsReady']);

    const allEnvelopeData = computed<ESignerFieldData[]>(() => store.getters['envelope/allEnvelopeData']);
    const esignSessionData = computed(() => store.getters['envelope/esignSessionData']);
    const esignEnvelopeDataCopy = computed<EnvelopePage[]>(() => store.getters['envelope/esignEnvelopeDataCopy']);

    const envelopeCustomer = computed(() => store.getters['envelope/envelopeCustomer']);
    const envelopeCustomerData = computed(() => envelopeCustomer.value?.data || {});

    const envelopeTemplate = computed<TemplateData>(() => envelope.value?.template);
    const envelopeTemplateDocuments = computed<DocumentType>(() => envelopeTemplate.value?.documents);
    const envelopeDocumentImages = computed(() => envelopeTemplateDocuments.value?.document_images || []);

    const envelopeDocumentDimensions = computed(() => envelopeTemplateDocuments.value?.dimensions || {
        width: 612,
        height: 792
    });
    const envelopeDocumentDimensionRatio = computed(() => {
        const h = envelopeDocumentDimensions.value.height;
        const w = envelopeDocumentDimensions.value.width;

        return Math.round((w / h) * 100000) / 100000; // Round to 5 decimal places
    });

    const envelopeCompleted = computed(() => envelope.value?.completed_at);
    const envelopeCustomerCompleted = computed(() => envelopeCustomer.value?.completed_at);

    const completedEnvelopeUrl = computed<string>(() => envelope.value?.file_path || envelope.value?.pdf_url || '');

    const envelopeStatus = computed(() => envelope.value?.status || 'pending');

    const envelopeData = computed<envelopeDataByPageInterface[]>(() => {
        const fields = Array.isArray(allEnvelopeData.value) ? [...allEnvelopeData.value].sort((a, b) => a.page - b.page) : [];

        console.dir('envelopeData');
        console.dir(fields);
        const pages = envelopeDocumentImages.value || [];

        return pages.map((p, index) => {
            const pageNumber = index + 1;
            let pageData = fields.filter((f) => f.page === pageNumber) || [];

            pageData = pageData.map((d) => {
                const data = d;
                const updated = data.updated;
                const title = data.title;

                if (data.type === 'field') {
                    const filled = data.filled || false;
                    return {
                        ...data,
                        filled,
                        text: filled ? data.text : '',
                        placeholder: data.text,
                        toolTip: data.text,
                        title: title || data.text,
                    };
                }
                if (data.type === 'text') {
                    return {
                        ...data,
                        filled: data.filled || true,
                        placeholder: data.text,
                        text: data.readOnly || updated ? data.text : '',
                        title: title || data.text,
                    };
                }
                if (data.type === 'attachment') {
                    return {
                        ...data,
                        attached: data.attached || false,
                        filled: data.filled || false,
                        url: data.url || null,
                    };
                }
                if (data.type === 'checkbox') {
                    return {
                        ...data,
                        toolTip: data.fieldName,
                    };
                }

                return {
                    ...d,
                    signed: false,
                    filled: false,
                    src: '',
                    toolTip: 'Sign Here',
                };

            });
            return {
                page: pageNumber,
                path: p,
                pageData,
            };
        });
    });

    const envelopeRequiredFields = computed(() => {
        return envelopeData.value
            .flatMap(e => e.pageData) // Native `flatMap`
            .reduce((count, d) => count + (d.required ? 1 : 0), 0);
    });

    const envelopeSignatures = computed(() => {
        const data = esignEnvelopeDataCopy.value.flatMap((e) => e.pageData);
        let signatureCount = 0;
        data.forEach((d) => {
            if (d.type === 'signature') signatureCount++;
        });
        return signatureCount;
    });

    const remainingRequiredFields = computed(() => {
        const data = esignEnvelopeDataCopy.value.flatMap((e) => e.pageData);
        console.dir('remainingRequiredFields');
        console.dir(data.length);
        console.dir(data);

        return data.filter((field) => {
            if (!field.required) return false;

            switch (field.type) {
                case 'signature':
                    return !field.signed;
                case 'field':
                case 'text':
                    return !field.filled || (field.text?.trim().length === 0);
                case 'checkbox':
                    return !field.value;
                case 'attachment':
                    return !field.filled;
                default:
                    return false;
            }
        });
    });


    function sendEnvelopeReminder(data: { envelope: EnvelopeInterface, email: string }) {
        store.dispatch('envelope/sendReminder', data);
    }

    const saveSignature = async (data: SaveSignaturePayload) => {
        return await store.dispatch('envelope/saveSignature', data);
    }

    const acceptAndSignEnvelope = async (data: AcceptEnvelopePayload) => {
        return await store.dispatch('envelope/acceptAndSignV2', data);
    }

    function setEnvelopeDialog(data: { key: string, value: boolean }) {
        store.commit('envelope/SET_ENVELOPE_DIALOG', data);
    }

    function updateEnvelopeField(data: ESignerFieldData) {
        store.commit('envelope/UPDATE_ENVELOPE_FIELD', data);
    }

    const setEsignSessionData = async (data: { key: string, value: any } | Record<string, any>) => {
        return store.commit('envelope/SET_ESIGN_SESSION_DATA', data);
    }


    const removeAttachment = async (data: RemoveAttachmentPayload) => {
        return await store.dispatch('envelope/removeAttachment', data);
    }

    const saveProgress = async (data: SaveProgressPayload) => {
        return await store.dispatch('envelope/saveProgress', data);
    }


    const getEnvelopeV2 = async (payload: {
        uuid: string,
        recipientId: string,
        recipientKey?: string,
        sample?: boolean
    }) => {
        return await store.dispatch('envelope/getEnvelopeV2', payload);
    }

    const createEnvelope = async (data: NewEnvelope) => {
        return await store.dispatch('envelope/createEnvelope', data);
    }

    const voidEnvelope = async (envelope: EnvelopeInterface) => {
        return await store.dispatch('envelope/voidEnvelope', envelope);
    }

    const getEnvelopes = async () => {
        return await store.dispatch('envelope/getEnvelopes');
    };

    const printProgress = async (envelope: EnvelopeInterface) => {
        return await store.dispatch('envelope/printProgress', envelope);
    }

    const downloadProgress = async (envelope: EnvelopeInterface) => {
        return await store.dispatch('envelope/downloadProgress', envelope);
    }

    const resendEnvelope = async (payload: { uuid: string, recipients: string[] }) => {
        return await store.dispatch('envelope/resendEnvelope', payload);
    }

    const resetEnvelopeData = async (uuid: string) => {
        return await store.dispatch('envelope/resetEnvelopeData', uuid);
    }

    const updateCustomerQuery = (query: { key: string, value: object | string | number }) => {
        store.commit('envelope/SET_ENVELOPE_QUERY', query);
    }

    return {
        envelope,
        envelopeLoading,
        envelopeDialogs,
        envelopes,
        envelopeForm,
        envelopesQuery,
        envelopesPagination,
        totalRemainingFields,
        envelopeIsReady,
        allEnvelopeData,
        envelopeDocumentImages,
        envelopeData,
        envelopeDocumentDimensionRatio,
        envelopeDocumentDimensions,
        envelopeStatus,
        completedEnvelopeUrl,
        envelopeRequiredFields,
        envelopeCustomer,
        envelopeCustomerData,
        esignSessionData,
        esignEnvelopeDataCopy,
        remainingRequiredFields,
        envelopeCompleted,
        envelopeCustomerCompleted,
        envelopeSignatures,
        updateEnvelopeField,
        acceptAndSignEnvelope,
        setEsignSessionData,
        getEnvelopeV2,
        updateCustomerQuery,
        setEnvelopeDialog,
        createEnvelope,
        saveSignature,
        sendEnvelopeReminder,
        getEnvelopes,
        voidEnvelope,
        printProgress,
        downloadProgress,
        resendEnvelope,
        resetEnvelopeData,
        removeAttachment,
        saveProgress,
    }
}