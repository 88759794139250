<template>
  <div class="right-bar2 overflow-x-hidden">
    <div class="bg-gray-700 flex items-center justify-between w-full px-3 py-3">
      <div class="flex-grow"></div>
      <v-btn class="right-bar-toggle" size="x-small" theme="dark" @click="toggleRightSidebar();">
        <v-icon color="white" size="small">mdi-close</v-icon>
      </v-btn>
    </div>

    <div class="rightbar-content h-100">
      <div>

        <!-- Edit Field -->
        <div>
          <div>
            <v-card class="edit-field-card" flat>
              <v-card-title>
                <div v-if="selectedFieldType === 'text'">
                  <v-icon class="mr-2">mdi-format-text</v-icon>
                  Text
                </div>
                <div v-if="selectedFieldType === 'checkbox'">
                  <v-icon class="mr-2">mdi-checkbox-marked-outline</v-icon>
                  Checkbox
                </div>
                <div v-if="selectedFieldType === 'signature'">
                  <v-icon class="mr-2">mdi-signature</v-icon>
                  Signature
                </div>
                <div v-if="selectedFieldType === 'attachment'">
                  <v-icon class="mr-2">mdi-attachment</v-icon>
                  Attachment
                </div>
                <div v-if="selectedFieldTitle === 'Name'">
                  <v-icon class="mr-2">mdi-format-text</v-icon>
                  Name
                </div>
                <div v-if="selectedFieldType === 'field'">
                  <span v-if="selectedFieldV2.customField"><v-icon class="mr-2">mdi-creation</v-icon>Custom Field</span>
                  <span v-else><v-icon class="mr-2">mdi-creation</v-icon> Field</span>
                </div>
              </v-card-title>
              <v-divider/>
              <v-card-text>
                <div v-if="selectedFieldV2?.primary_customer" class="p-1">
                  <v-icon :color="'#5be8ff'" class="mr-1" size="small">mdi-account-circle</v-icon>
                  Primary Signer
                </div>
                <div v-if="selectedFieldV2?.secondary_customer" class="p-1">
                  <v-icon :color="'purple'" class="mr-1" size="small">mdi-account-circle</v-icon>
                  Secondary Signer
                </div>
                <p v-if="toolDescription(selectedFieldV2)" class="p-1 overflow-auto" style="max-height: 100px;">
                  <span class="h6 font-bold">Description:</span>
                  {{ toolDescription(selectedFieldV2) }}
                </p>
                <p v-if="selectedFieldV2?.typeId === 'field-booking-deposit'" class="p-2">
                  <v-icon size="x-small">mdi-help-circle-outline</v-icon>
                  Depending on if they pay with a debit or credit this field will fill in the respective amount you
                  chose for the listing.
                </p>
                <div class="px-2">
                  <div class="flex align-center" style="height: 60px;">
                    <div class="flex-grow">
                      Required Field
                      <v-icon v-tippy class="mb-1" content="Requires users to enter data into the field before submitting." size="x-small">
                        mdi-help-circle-outline
                      </v-icon>
                    </div>
                    <div>
                      <v-switch
                        v-model="selectedElementAttributesRequiredField"
                        density="compact"
                        hide-details
                      />
                    </div>
                  </div>
                  <div v-if="allowsReadOnly" class="flex align-center" style="height: 60px;">
                    <div class="flex-grow">
                      Read Only
                      <v-icon v-tippy class="mb-1" content="This field will be read only and can't be changed" size="x-small">
                        mdi-help-circle-outline
                      </v-icon>
                    </div>
                    <div>
                      <v-switch
                        v-model="selectedElementAttributesReadOnlyField"
                        density="compact"
                        hide-details
                      />
                    </div>
                  </div>

                  <div v-if="selectedFieldType === 'checkbox'">
                    <div class="flex align-center" style="height: 60px;">
                      <div class="flex-grow">
                        Select By Default
                        <v-icon v-tippy class="mb-1" content="This field will be checked by default" size="x-small">
                          mdi-help-circle-outline
                        </v-icon>
                      </div>
                      <div class="h-100">
                        <v-switch
                          v-model="selectedFieldV2.value"
                          density="compact"
                          hide-details
                        />
                      </div>
                    </div>
                    <v-text-field
                      v-model="selectedFieldV2.fieldName"
                      density="compact"
                      label="Field Name *"
                      required
                      variant="outlined"
                    />
                  </div>
                </div>

                <div v-if="selectedFieldV2" class="mt-5">
                  <v-expansion-panels multiple variant="accordion">
                    <v-expansion-panel v-if="selectedFieldType === 'text'">
                      <v-expansion-panel-title>Add Text</v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <v-text-field
                          v-model="selectedFieldV2.text"
                          label="Text"
                          variant="outlined"
                        ></v-text-field>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-title>Position</v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <v-text-field
                          v-model="selectedFieldV2.x"
                          label="X"
                          type="number"
                          variant="outlined"
                        ></v-text-field>
                        <v-text-field
                          v-model="selectedFieldV2.y"
                          label="Y"
                          type="number"
                          variant="outlined"
                        ></v-text-field>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="selectedFieldType !== 'checkbox'">
                      <v-expansion-panel-title>Formatting</v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <v-text-field
                          v-model="selectedFieldV2.fontSize"
                          label="Font Size"
                          type="number"
                          variant="outlined"
                        ></v-text-field>

                        <v-btn-toggle v-model="selectedFieldV2.textAlign">
                          <v-btn size="small" value="left">
                            <v-icon size="small">mdi-format-align-left</v-icon>
                          </v-btn>
                          <v-btn size="small" value="center">
                            <v-icon size="small">mdi-format-align-center</v-icon>
                          </v-btn>
                          <v-btn size="small" value="right">
                            <v-icon size="small">mdi-format-align-right</v-icon>
                          </v-btn>
                        </v-btn-toggle>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-title>ToolTip</v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <v-text-field
                          v-model="selectedFieldV2.toolTip"
                          label="Tool Tip"
                          variant="outlined"
                        ></v-text-field>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <!--                   <v-expansion-panel>-->
                    <!--                     <v-expansion-panel-title>Advanced</v-expansion-panel-title>-->
                    <!--                     <v-expansion-panel-text>-->
                    <!--                       <div style="height: 60px;" class="flex align-center">-->
                    <!--                         <div class="flex-grow">-->
                    <!--                           Conditional-->
                    <!--                           <v-icon x-small class="mb-1" v-tippy content="Hide this field until other fields are filled out">mdi-help-circle-outline</v-icon>-->
                    <!--                         </div>-->
                    <!--                         <div class="h-100">-->
                    <!--                           <v-switch-->
                    <!--                               dense-->
                    <!--                               v-model="selectedFieldV2?.conditional"-->
                    <!--                               inset-->
                    <!--                               hide-details-->
                    <!--                               @click="setupConditionalField()"-->
                    <!--                           ></v-switch>-->
                    <!--                         </div>-->
                    <!--                       </div>-->
                    <!--                       <v-btn-->
                    <!--                           v-if="selectedFieldV2?.conditional"-->
                    <!--                           x-small-->
                    <!--                           block-->
                    <!--                           @click="conditionalRulesDialog = true"-->
                    <!--                       >-->
                    <!--                         Edit Conditions-->
                    <!--                       </v-btn>-->
                    <!--                     </v-expansion-panel-text>-->
                    <!--                   </v-expansion-panel>-->
                  </v-expansion-panels>
                </div>
                <div v-if="false" class="mt-2 p-1">
                  <v-select
                    v-model="customer"
                    :items="['primary', 'secondary']"
                    density="compact"
                    label="Customer"
                    variant="outlined"
                  >
                    <template v-slot:item="{ item }">
                      <v-icon v-if="item.value === 'primary'" color="#5be8ff">mdi-circle-small</v-icon>
                      <v-icon v-if="item.value === 'secondary'" color="#975bff">mdi-circle-small</v-icon>
                      {{ upperFirst(item.value) }}
                    </template>
                  </v-select>
                </div>
              </v-card-text>

              <v-card-actions>
                <v-spacer/>
                <delete-button
                  :isDisabled="fieldIsDependant(selectedFieldV2)"
                  @delete-confirmed="$emit('update:esignFieldDeleted');"
                />
              </v-card-actions>
            </v-card>

            <v-card-text>
              <div v-if="selectedFieldV2?.x" class="flex flex-col text-muted text-xs">
                <div class="flex">
                  <span class="flex-grow">X POS:</span> {{ selectedFieldV2?.x }}
                </div>
                <div class="flex">
                  <span class="flex-grow">Y POS:</span> {{ selectedFieldV2?.y }}
                </div>
                <div class="flex">
                  <span class="flex-grow">Width:</span> {{ selectedFieldV2?.w }}
                </div>
                <div class="flex">
                  <span class="flex-grow">Field ID:</span> {{ selectedFieldV2?.id }}
                </div>
              </div>
            </v-card-text>
            <pre v-if="debugDrawerOpen" class="text-xs">
              {{ selectedFieldV2 }}
            </pre>
          </div>
        </div>

      </div>
    </div>
    <v-dialog v-model="conditionalRulesDialog" width="420">
      <v-card>
        <v-card-title>
          Rules for {{ selectedFieldTitle }}
        </v-card-title>

        <v-card-text>
          <div>Reveal this field when...</div>
          <div>
            <v-select
              v-model="conditionalRules.when"
              :disabled="conditionalRules.rules.length <= 1"
              :items="possibleWhens"
              density="compact"
              item-title="text"
              item-value="value"
              variant="outlined"
            >
            </v-select>
          </div>
          <div>
            <div v-for="(rule, index) in conditionalRules.rules" :key="index" class="mb-1">
              <div class="flex  p-1 border rounded">
                <div class="flex-grow">
                  <v-select
                    v-model="rule.field"
                    :items="availableFields"
                    class="mt-1"
                    density="compact"
                    hide-details
                    item-title="text"
                    item-value="value"
                    label="Field"
                    variant="outlined"
                  >
                    <template v-slot:selection="{ item }">
                      <v-icon :color="item.color" class="mr-1" size="small">mdi-account-circle</v-icon>
                      <div class="flex">
                        <div class="flex-grow">
                          {{ item.text }}
                          <span v-if="item.fieldName" class="small">({{ item.fieldName }})</span>
                        </div>
                        <div v-if="item.primary">Primary</div>
                        <div v-if="item.secondary">Secondary</div>
                      </div>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-icon :color="item.color" class="mr-1" size="small">mdi-account-circle</v-icon>
                      <div class="flex">
                        <div class="flex-grow">
                          {{ item.text }}
                          <span v-if="item.fieldName" class="small">({{ item.fieldName }})</span>
                        </div>
                        <div v-if="item.primary">Primary</div>
                        <div v-if="item.secondary">Secondary</div>
                      </div>
                    </template>
                  </v-select>
                  <v-select
                    v-model="rule.value"
                    :items="getActionItems(rule)"
                    class="mt-2"
                    density="compact"
                    hide-details
                    item-title="text"
                    item-value="value"
                    label="Action"
                    variant="outlined"
                  >
                  </v-select>
                </div>
                <div class="px-1">
                  <v-btn
                    v-if="index !== 0"
                    rounded
                    size="x-small"
                    variant="flat"
                    @click="conditionalRules.rules.splice(index, 1)"
                  >
                    <v-icon size="x-small">mdi-delete</v-icon>
                  </v-btn>

                  <delete-button
                    @delete-confirmed="conditionalRules.rules.splice(index, 1)"
                  />
                </div>
              </div>
              <div
                v-if="conditionalRules.rules.length > 1 && conditionalRules.rules.length !== index + 1"
                class="flex justify-content-center"
              >
                <span v-if="conditionalRules.when === 'any'">Or</span>
                <span v-if="conditionalRules.when === 'all'">And</span>
                <span v-if="conditionalRules.when === 'none'">Not And</span>
              </div>
            </div>
            <div class="mt-2">
              <v-btn block size="small" variant="text" @click="addConditionToRuleset()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn @click="setConditionalRules()">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import {computed, defineAsyncComponent, ref, watch} from 'vue';
import {upperFirst} from '@/utils/text';
import {useTemplate} from '@/composables/useTemplate';
import {useAppSettings} from '@/composables/useAppSettings';
import {ESignerFieldData} from '@/types';

const DeleteButton = defineAsyncComponent(() => import('@/components/buttons/DeleteButton.vue'));

export default {
  name: 'TheTemplateRightSidebar',
  components: {DeleteButton},

  emits: ['update:esignFieldDeleted'],

  setup() {
    const {
      allTools,
      selectedFieldV2,
      templateStageData,
      toggleRightSidebar,
      setTool,
      deleteSelectedElementsV2
    } = useTemplate();
    const {debugDrawerOpen} = useAppSettings();
    const currentTool = ref('');
    const customer = ref('');
    const conditionalRulesDialog = ref(false);
    const conditionalRules = ref({
      when: 'any',
      rules: [
        {
          field: '',
          operator: '',
          value: '',
        },
      ],
    });
    const possibleWhens = ref([
      {
        text: 'Any of the following',
        value: 'any',
      },
      {
        text: 'All of the following',
        value: 'all',
      },
      {
        text: 'None of the following',
        value: 'none',
      },
    ]);

    const possibleRules = ref({
      field: [
        {
          value: 'filled',
          text: 'is filed in',
        },
        {
          value: 'not_filled',
          text: 'is not filed in',
        },
      ],
      checkbox: [
        {
          value: 'filled',
          text: 'is checked',
        },
        {
          value: 'not_filled',
          text: 'is not check',
        },
      ],
    });

    const conditionalRulesRules = computed(() => {
      return conditionalRules.value.rules;
    });

    watch(currentTool, (tool) => {
      if (tool) {
        const toolUsed = allTools.value.find((t) => t.value === currentTool.value);
        console.dir('toolUsed');
        console.dir(toolUsed);
        if (toolUsed) {
          setTool(toolUsed);
        }
      }
    });

    watch(selectedFieldV2, (field) => {
      if (field) {
        if (field.conditional_rules) {
          conditionalRules.value = field.conditional_rules;
        } else {
          conditionalRules.value = {
            when: 'any',
            rules: [
              {
                field: '',
                operator: '',
                value: '',
              },
            ],
          };
        }
      }
    });


    watch(conditionalRulesRules, (rules) => {
      if (rules.length <= 1) {
        conditionalRules.value.when = 'any';
      }
    });

    const selectedFieldType = computed(() => selectedFieldV2?.value?.type);
    const selectedFieldTitle = computed(() => selectedFieldV2?.value?.title);


    const allFieldsWithRules = computed(() => {
      return templateStageData.value.filter((field: ESignerFieldData) => field?.conditional_rules?.rules?.length > 0);
    });

    const dependentFields = computed(() => {
      const fieldIds = [];
      allFieldsWithRules.value.forEach((field) => {
        field.conditional_rules.rules.forEach((rule) => {
          if (rule.value && !fieldIds.includes(rule.value)) {
            fieldIds.push(rule.value);
          }
        });
      });
      return fieldIds;
    });

    const setupConditionalField = () => {
      if (selectedFieldV2.value?.conditional) {
        conditionalRulesDialog.value = true;
      } else {
        delete selectedFieldV2.value?.conditional_rules;
      }
    };

    const getActionItems = (rule) => {
      const field = templateStageData.value.find((field) => field.id === rule.field);
      if (!field) return [];

      return possibleRules.value[field.type] || [];
    };

    const setConditionalRules = () => {
      conditionalRulesDialog.value = false;
      selectedFieldV2.value.conditional_rules = conditionalRules.value;
    };

    const addConditionToRuleset = () => {
      console.dir('addConditionToRuleset');
      conditionalRules.value.rules.push({
        field: '',
        operator: '',
        value: '',
      });
    };

    const fieldIsDependant = (field) => {
      return dependentFields.value && dependentFields.value.includes(field?.id);
    };

    const toolDescription = (tool) => {
      const findTool = allTools.value.find((t) => t.id === tool?.typeId);
      return findTool?.description;
    };

    const allFieldsOrdered = computed(() => {
      const fields = templateStageData.value.map((field) => ({
        text: field.title,
        value: field.id,
        type: field.type,
        fieldName: field.fieldName,
        primary: !!field.primary_customer,
        secondary: !!field.secondary_customer,
      }));

      return fields.sort((a, b) => {
        if (a.primary && !b.primary) {
          return -1;
        }
        if (!a.primary && b.primary) {
          return 1;
        }
        if (a.secondary && !b.secondary) {
          return -1;
        }
        if (!a.secondary && b.secondary) {
          return 1;
        }
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
    });

    const availableFields = computed(() => {
      return allFieldsOrdered.value?.filter((field) => {
        if (selectedFieldV2.value) {
          return field.value !== selectedFieldV2.value.id;
        }
        return true;
      });
    });

    const allowsReadOnly = computed(() => {
      return selectedFieldV2.value?.typeId === 'field-free-text'
        || selectedFieldV2.value?.typeId === 'field-primary-free-text'
        || selectedFieldV2.value?.typeId === 'field-secondary-free-text'
        || selectedFieldV2.value?.customField;
    });

    const selectedElementAttributesRequiredField = computed({
      get() {
        return selectedFieldV2.value?.required || false;
      },
      set(value) {
        console.dir(selectedFieldV2.value);
        selectedFieldV2.value.required = value;
      },
    });

    const selectedElementAttributesReadOnlyField = computed({
      get() {
        return selectedFieldV2.value?.readOnly || false;
      },
      set(value) {
        console.dir('set value');
        selectedFieldV2.value.readOnly = value;
      },
    });


    return {
      upperFirst,
      deleteSelectedElementsV2,
      setupConditionalField,
      getActionItems,
      setConditionalRules,
      addConditionToRuleset,
      fieldIsDependant,
      toolDescription,
      toggleRightSidebar,
      allowsReadOnly,
      selectedElementAttributesRequiredField,
      selectedElementAttributesReadOnlyField,
      allFieldsOrdered,
      dependentFields,
      availableFields,
      selectedFieldTitle,
      selectedFieldType,
      allFieldsWithRules,
      templateStageData,
      selectedFieldV2,
      debugDrawerOpen,
      allTools,
      currentTool,
      customer,
      conditionalRulesDialog,
      conditionalRules,
      possibleWhens,
      possibleRules,
    };
  },

};
</script>

<style lang="scss">
.edit-field-card.theme--light.v-card {
  background-color: #efefef;
  border: none;
}

.edit-field-card .v-card__text {
  padding: 0 !important;
}

.right-bar2 {
  background-color: #efefef;
  -webkit-box-shadow: 0 0 24px 0 rgba(50, 58, 70, .1), 0 1px 0 0 rgba(50, 58, 70, .08);
  box-shadow: 0 0 24px 0 rgba(50, 58, 70, .1), 0 1px 0 0 rgba(50, 58, 70, .08);
  display: block;
  position: fixed;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
  width: 220px;
  z-index: 9999;
  float: right !important;
  right: -220px;
  top: 0;
  bottom: 0;
  padding-bottom: 80px;
  overflow: auto;
}

.esign-right-bar-enabled .right-bar2 {
  right: 0;
}

.right-bar2 .rightbar-title {
  background-color: #313a46;
  padding: 27px 25px;
  color: #fff !important;
}

.right-bar2 .rightbar-title h5 {
  color: #fff !important;
}

.right-bar2 .right-bar-toggle {
  //background-color: #444e5a;
  //height: 24px;
  //width: 24px;
  //line-height: 22px;
  //color: #fff;
  //text-align: center;
  //border-radius: 50%;
  //margin-top: -4px;
}
</style>
