import axios from 'axios';
import * as types from '@/store/mutation-types';
import {createUrlQueryString} from '@/utils/urls';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {StoreLoaders} from '@/types';
import {push} from 'notivue';

type Axel = {
    active: boolean,
    balance: number,
    client_id: string | null,
    client_secret: string | null,
    created_at: string,
    low_balance_threshold: number,
    refill_amount: number,
    updated_at: string,
    uuid: string,
}

export type AxelIgnition = {
    created_at: string,
    ignition_uri: string,
    policies: any | null,
    policy_account_id: any | null,
    results: any | null,
    status: any | null,
    updated_at: string | null
    uuid: string,
}

export type AxleState = {
    loading: StoreLoaders,
    axleAccount: Axel | null,
    ignitions: AxelIgnition[],
    incomingIgnition: object | null,
}


// state
const state: AxleState = {
    loading: {
        getLinks: false,
        getCheckoutLink: false,
        fetchStartLink: false,
        refreshIgnition: false,
        startAxleAccount: false,
        axel_get: false,
    },
    axleAccount: null,
    ignitions: [],
    incomingIgnition: null,
};

const getters = {
    loading: (state: AxleState) => state.loading,
    axleAccount: (state: AxleState) => state.axleAccount,
    ignitions: (state: AxleState) => state.ignitions,
    incomingIgnition: (state: AxleState) => state.incomingIgnition,
};

const mutations = {
    [types.SET_AXLE_LOADING](state: AxleState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },

    [types.SET_AXLE](state: AxleState, axle: Axel | null) {
        state.axleAccount = axle;
    },
    [types.SET_AXLE_IGNITIONS](state: AxleState, ignitions: AxelIgnition[]) {
        state.ignitions = ignitions;
    },
    [types.SET_INCOMING_AXLE_IGNITION](state: AxleState, payload: object) {
        state.incomingIgnition = payload;
    },
    [types.MERGE_AXLE_IGNITION](state: AxleState, ignition: AxelIgnition) {
        const index = state.ignitions.findIndex((i) => i.uuid === ignition.uuid);

        if (index !== -1) {
            state.ignitions[index] = ignition;
        }
    },
};

const actions = {

    /**
     * Get Axle account
     */
    async getAxle({commit}) {
        const responseData = await performApiRequest(commit, 'SET_AXLE_LOADING', 'axel_get', () => axios.get('/v1/axle'));
        commit(types.SET_AXLE, responseData.data);
    },


    /**
     * Fetch a checkout link
     */
    async getCheckoutLink({commit}, data: { credits: number }) {
        return await performApiRequest(commit, 'SET_AXLE_LOADING', 'getCheckoutLink', () => axios.post('/v1/axle/checkout-link', data));
    },

    async startAxleAccount({commit}) {
        return await performApiRequest(commit, 'SET_AXLE_LOADING', 'startAxleAccount', () => axios.post('/v1/axle/create-account'));
    },

    async testAutoRecharge({commit}) {
        return await performApiRequest(commit, 'SET_AXLE_LOADING', 'startAxleAccount', () => axios.post('/v1/axle/test-auto-recharge'));
    },

    /**
     * Fetch a start ignition link
     */
    async fetchStartIgnitionLink({commit}, data: { r_id?: string, b_id?: string }) {
        try {
            const responseData = await performApiRequest(commit, 'SET_AXLE_LOADING', 'fetchStartLink', () => axios.post('/v1/axle/start-ignition', data));
            commit(types.SET_AXLE_IGNITIONS, responseData.ignitions);
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'An error occurred while fetching the ignition link',
            });
        }
    },

    /**
     * Fetch a start ignition link (public)
     */
    async fetchStartIgnitionLinkPublic({commit}, data: { b_id: string }) {
        try {
            const responseData = await performApiRequest(commit, 'SET_AXLE_LOADING', 'fetchStartLink', () => axios.post('/v1/axle/start-ignition/start', data));
            return responseData.url;
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },


    /**
     * Get ignition links
     */
    async getIgnitionLinks({commit}, data = []) {
        try {
            const query = createUrlQueryString(data);
            const responseData = await performApiRequest(commit, 'SET_AXLE_LOADING', 'getLinks', () => axios.get(`/v1/axle/ignitions${query}`));
            commit(types.SET_AXLE_IGNITIONS, responseData.ignitions);
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },

    /**
     * Refresh ignition link
     */
    async refreshIgnition({commit}, data: AxelIgnition) {
        try {
            const responseData = await performApiRequest(commit, 'SET_AXLE_LOADING', 'refreshIgnition', () => axios.post(`/v1/axle/ignitions/${data.uuid}/refresh`));
            commit(types.MERGE_AXLE_IGNITION, responseData.ignition);
            return responseData;
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
