<template>
  <div>
    <div v-if="!company.init" class="container">
      <div class="grid grid-cols-3">
        <div>
          <div class="max-w-sm animate-pulse p-5" role="status">
            <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
          </div>
        </div>
        <div class="col-span-2">
          <v-card variant="flat">
            <v-card-text>
              <div class="max-w-sm animate-pulse p-5" role="status">
                <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                <span class="sr-only">Loading...</span>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <div class="max-w-sm animate-pulse p-5" role="status">
                <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                <span class="sr-only">Loading...</span>
              </div>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </div>
    <div v-else-if="!employee.found">
      Employee not found
    </div>
    <div v-else>
      <div class="grid grid-cols-1 md:grid-cols-3 mb-5">
        <div>
          <div class="setting-section-description">
            <h3 class="text-lg font-medium text-gray-900 dark:text-gray-100">Account</h3>
          </div>
        </div>
        <div class="col-span-1">
          <v-card max-width="100%" variant="flat">
            <v-card-text>
              <div class="flex flex-col sm:flex-row">
                <div class="flex flex-grow h-100 content-center">
                  <v-avatar
                    class="shadow-sm"
                    color="primary"
                    size="40"
                  >
                    <span class="text-white font-medium">{{ employeeInitials(employee) }}</span>
                  </v-avatar>
                  <div class="ml-4">
                    <div class="flex items-center gap-2">
                      <span class="text-gray-900 dark:text-gray-100 font-medium">
                        {{ employee.name }}
                      </span>
                      <v-chip
                        v-if="employee.email === user.email"
                        class="text-xs"
                        color="primary"
                        size="small"
                        variant="outlined"
                      >
                        Account Owner
                      </v-chip>
                    </div>
                    <div class="text-sm text-gray-500 dark:text-gray-400 mt-1">
                      {{ employee.email }}
                    </div>
                    <div class="text-sm text-gray-500 dark:text-gray-400">
                      {{ employee.phone }}
                    </div>
                  </div>
                </div>
              </div>

              <v-form v-if="editAccountForm" class="mt-10">
                <div class="">
                  <div class="">
                    <v-text-field
                      v-model="employeeClone.email"
                      class="mb-4"
                      label="Email"
                      variant="outlined"
                    />
                  </div>
                </div>
                <div class="">
                  <div class="mb-4">
                    <v-text-field
                      v-model="employeeClone.first_name"
                      label="First Name"
                      variant="outlined"
                    />
                  </div>
                  <div class="mb-4">
                    <v-text-field
                      v-model="employeeClone.last_name"
                      label="Last Name"
                      variant="outlined"
                    />
                  </div>
                </div>
                <div class="">
                  <div class="">
                    <v-text-field
                      v-model="employeeClone.phone"
                      label="Phone"
                      variant="outlined"
                    />
                  </div>
                  <div class="">
                  </div>
                </div>
              </v-form>
            </v-card-text>
            <v-card-actions class="flex flex-col sm:flex-row justify-end gap-2">
              <v-btn v-if="editAccountForm" size="small" variant="text" @click="editAccountForm = false;">
                Cancel
              </v-btn>
              <v-btn v-if="editAccountForm" @click="saveCompanyEmployeeInit()">
                Save
              </v-btn>
              <v-btn v-if="!editAccountForm" size="small" variant="text" @click="editAccountForm = true;">
                edit
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>

      <v-divider class="my-6 md:my-10" opacity="50"/>

      <div class="grid grid-cols-1 md:grid-cols-3 my-5">
        <div>
          <div class="setting-section-description">
            <h3 class="text-lg font-medium text-gray-900 dark:text-gray-100">Account Status</h3>
            <div class="feature-description">
              Manage team member access by activating or deactivating accounts as needed.<br>

              When you deactivate a team member, their seat becomes available in your plan's total seat allocation.
            </div>
          </div>
        </div>
        <div class="col-span-1">
          <v-card max-width="100%" variant="flat">
            <v-card-title>
              <span v-if="!employee.email_verified_at">Invite Pending</span>
              <span v-else>Confirmed</span>
            </v-card-title>

            <v-card-text>
              This team member occupies one seat in your plan's allocation. They will gain access to your Fleetwire
              account once they accept the invitation sent to <strong>{{ employee.email }}</strong>
            </v-card-text>

            <v-card-actions class="flex flex-col sm:flex-row justify-between gap-2">
              <v-btn
                v-if="!employee.email_verified_at"
                color="grey"
                variant="text"
                @click="sendEmployeeInvite()"
              >
                Resend invite
              </v-btn>
              <v-btn :disabled="currentEmployeeIsMe" color="error" size="small" variant="text" @click="deleteEmployee()">
                {{ $t('deactivate') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>

      <v-divider class="my-6 md:my-10" opacity="50"/>

      <div class="grid grid-cols-1 md:grid-cols-3 my-5">
        <div>
          <div class="setting-section-description">
            <h3 class="text-lg font-medium text-gray-900 dark:text-gray-100">Permissions</h3>
          </div>
        </div>
        <div class="col-span-1">
          <v-card :disabled="form.busy" max-width="100%" variant="flat">
            <v-card-text>
              <v-checkbox
                v-model="selectAllPermissions"
                :indeterminate="employeePermissions.length !== 0 && employeePermissions.length < possiblePermissions.length"
                class="mb-4"
                color="accent-4"
                label="Select All"
              />

              <div v-for="(item, category) in filteredPermissions" :key="`title-${item.name}`" class="mb-6">
                <v-card-title class="text-base">{{ formatTitles(category) }}</v-card-title>
                <template v-for="(perm, index) in item" :key="`item${category}-${index}`">
                  <v-checkbox
                    v-model="employeePermissions"
                    :label="perm.name"
                    :value="perm.name"
                    class="mb-1"
                    color="accent-4"
                    density="compact"
                    hide-details
                  ></v-checkbox>
                </template>
              </div>
            </v-card-text>

            <v-card-actions class="flex justify-end">
              <v-btn :loading="form.busy" color="primary" @click="saveEmployee">
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>

      <v-divider class="my-6 md:my-10" opacity="50"/>

      <div class="grid grid-cols-1 md:grid-cols-3 my-5">
        <div>
          <div class="setting-section-description">
            <h3 class="text-lg font-medium text-gray-900 dark:text-gray-100">Roles</h3>
          </div>
        </div>
        <div class="col-span-1">
          <v-card :disabled="form.busy" max-width="100%" variant="flat">
            <v-card-text>
              <div v-for="(item) in availableRoles" :key="`title-${item.title}`" class="mb-2">
                <v-checkbox
                  v-model="employeeRoles"
                  :disabled="currentEmployeeIsMe"
                  :label="item.title"
                  :value="item.name"
                  color="accent-4"
                  density="compact"
                  hide-details
                ></v-checkbox>
              </div>
            </v-card-text>

            <v-card-actions class="flex justify-end">
              <v-btn :loading="form.busy" color="primary" @click="saveEmployee">
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </div>

    <v-dialog v-model="deleteConfirmDialog" max-width="400">
      <v-card>
        <v-alert v-if="currentEmployeeIsMe" class="card-alert-full" type="warning">
          You can not delete yourself
        </v-alert>
        <v-card-title class="text-h5">Are you sure?</v-card-title>
        <v-card-text>
          You are about to delete this employee.
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="grey" size="small" variant="text" @click="deleteConfirmDialog = false">
            {{ $t('nevermind') }}
          </v-btn>
          <v-btn :disabled="currentEmployeeIsMe" :loading="form.busy" color="red text-white" @click="deleteEmployeeConfirmed()">
            {{ $t('confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import {nextTick} from 'vue';
import {mapActions, mapGetters} from 'vuex';
import {clone, each, get, map, remove} from 'lodash';
import {EventBus} from '@/plugins/eventBus';
import Form from 'vform';
import {useCompany} from '@/composables/useCompany';
import {push} from 'notivue'

const PERMISSION_ORDER = ['view', 'add', 'edit', 'cancel', 'delete', 'manage'];


export default {
  name: 'SettingsEmployeeSingle',

  setup() {
    const {company} = useCompany();
    return {
      company,
    };
  },

  data() {
    return {
      availableRoles: [
        {
          name: 'company_admin',
          title: 'Company Admin',
          description: 'The company admin',
        },
      ],
      deleteConfirmDialog: false,
      selectAllPermissions: false,
      form: new Form(),
      editAccountForm: false,
      employeeClone: {
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
      },
      permissionDescriptions: {
        orders_adjust_taxes: {
          title: 'Adjust Taxes',
          description: 'Allows the employee to adjust taxes on orders',
        },
      },
      admin_approvals: {
        customers_edit: false,
        customers_delete: true,
      },
    };
  },

  watch: {
    employee: {
      immediate: true,
      handler(employee) {
        if (employee.uuid) {
          this.employeeClone = clone(employee);
        }
      },
    },
    selectAllPermissions: {
      immediate: true,
      handler(newData) {
        if (newData) {
          this.employeePermissions = map(this.possiblePermissions, (p) => p.name);
        } else {
          this.employeePermissions = [];
        }
      },
    },
  },

  mounted() {
    if (this.possiblePermissions.length === this.employeePermissions.length) {
      this.selectAllPermissions = true;
    }
  },

  created() {
    this.fetchCompanyEmployee();
    EventBus.$on('employee-updated-success', () => {
      this.editAccountForm = false;
      this.fetchCompanyEmployee();
    });
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      possiblePermissions: 'app/possiblePermissions',
      employee: 'company/employee',
    }),

    filteredPermissions(): Record<string, any> {
      const permissions = {
        customers: [],
        users: [],
        listings: [],
        orders: [],
        settings: [],
        turo: [],
        misc: [],
      };

      const allPermissions = [...this.possiblePermissions];

      each(permissions, (p, i) => {
        permissions[i] = remove(allPermissions, (p) => p.name.includes(i));
      });
      permissions.misc = allPermissions;

      each(permissions, (p, i) => {
        // order each permission group by the order in PERMISSION_ORDER
        // e.g. customers_view should be first in the list because PERMISSION_ORDER[0] === 'view'
        // any permissions that are not in PERMISSION_ORDER will be sorted alphabetically after
        permissions[i] = p.sort((a, b) => {
          const aIndex = PERMISSION_ORDER.indexOf(a.name.split('_')[1]);
          const bIndex = PERMISSION_ORDER.indexOf(b.name.split('_')[1]);

          if (aIndex === -1 && bIndex === -1) {
            return a.name.localeCompare(b.name);
          }
          if (aIndex === -1) {
            return 1;
          }
          if (bIndex === -1) {
            return -1;
          }

          return aIndex - bIndex;
        });
      });

      return permissions;
    },

    currentEmployeeIsMe() {
      return this.employee.uuid === this.user.uuid;
    },

    employeePermissions: {
      get() {
        const employee = this.$store.getters['company/employee'];
        return get(employee, 'permissions', []);
      },
      set(value) {
        this.$store.commit('company/SET_EMPLOYEE_PERMISSIONS', value);
      },
    },
    employeeRoles: {
      get() {
        const employee = this.$store.getters['company/employee'];
        return get(employee, 'roles', []);
      },
      set(value) {
        this.$store.commit('company/SET_EMPLOYEE_ROLES', value);
      },
    },
  },

  methods: {
    ...mapActions({
      saveCompanyEmployee: 'company/saveEmployee',
    }),
    checkPermission(perm) {
      nextTick(() => {
        const turnOn = this.employeePermissions.includes(perm.name);

        if (perm.name.includes('_view') && !turnOn) {
          let otherPerms = this.possiblePermissions.filter((p) => p.name.includes(perm.name.split('_')[0]) && !p.name.includes('_view'));
          otherPerms = map(otherPerms, (p) => p.name);
          // remove all the other permissions from the employeePermissions array
          this.employeePermissions = this.employeePermissions.filter((p) => !otherPerms.includes(p));
        }

        const topic = perm.name.split('_')[0];
        console.dir(topic);

        if (turnOn) {
          const viewPerm = this.employeePermissions.includes(`${topic}_view`);
          if (!viewPerm) {
            this.employeePermissions.push(`${topic}_view`);
          }
        }
      });
    },
    saveCompanyEmployeeInit() {
      const employeeData = {};

      if (this.employeeClone.first_name !== this.employee.first_name) {
        employeeData.first_name = this.employeeClone.first_name;
      }
      if (this.employeeClone.last_name !== this.employee.last_name) {
        employeeData.last_name = this.employeeClone.last_name;
      }
      if (this.employeeClone.phone !== this.employee.phone) {
        employeeData.phone = this.employeeClone.phone;
      }
      if (this.employeeClone.email !== this.employee.email) {
        employeeData.email = this.employeeClone.email;
      }

      if (Object.keys(employeeData).length === 0 && employeeData.constructor === Object) {
        return;
      }

      this.saveCompanyEmployee({
        employee_uuid: this.$route.params.e_id,
        data: employeeData,
      });
    },
    formatTitles(word: string) {
      if (!word) return '';
      const title = word.split('_');
      return title.map((word) => word[0].toUpperCase() + word.substring(1)).join(' ');
    },
    async fetchCompanyEmployee() {
      try {
        const response = await this.form.get(`/v1/employees/${this.$route.params.e_id}`);

        this.$store.commit('company/SET_COMPANY_EMPLOYEE', get(response, 'data.employee'));
        this.form = new Form(get(response, 'data.employee'));
      } catch (er) {
        console.dir(er);
      }
    },

    async saveEmployee() {
      try {
        this.form = new Form(this.employee);
        const response = await this.form.put(`/v1/employees/${this.employee.uuid}`);
        this.$store.commit('company/SET_COMPANY_EMPLOYEE', response.data.employee);
        push.success({
          message: 'Company employee saved',
        });

      } catch (er) {
        console.dir(er);
      }
    },

    async deleteCompanyEmployee() {
      try {
        this.form = new Form();
        const response = await this.form.delete(`/v1/employees/${this.employee.uuid}`);

        if (get(response, 'data.company')) {
          this.$store.commit('company/SET_COMPANY', get(response, 'data.company'));
        }
        push.success({
          message: 'Company employee deleted',
        });
        this.$router.push({name: 'settings.account'}).catch(() => {
        });
      } catch (er) {
        console.dir(er);
      }
    },

    sendEmployeeInvite() {
      console.dir('sendEmployeeInvite');
    },

    deleteEmployee() {
      this.deleteConfirmDialog = true;
    },

    deleteEmployeeConfirmed() {
      this.deleteConfirmDialog = false;
      this.deleteCompanyEmployee(this.employee);
    },

    employeeInitials(employee) {
      let acronym = '';
      if (employee.first_name && employee.last_name) {
        acronym = employee.first_name.slice(0, 1) + employee.last_name.slice(0, 1);
      } else {
        let matches = employee.name.match(/\b(\w)/g);

        if (matches && matches.length > 2) {
          matches = matches.slice(0, 2);
          acronym = matches.join('');
        }
      }
      return acronym;
    },
  },

  beforeUnmount() {
    EventBus.$off('employee-updated-success');
    this.$store.commit('company/RESET_EMPLOYEE');
  },
};
</script>
