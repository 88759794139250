import type {RouteRecordRaw} from 'vue-router';
import DocumentsDashboardLayoutV2 from '@/views/documentsV2/DocumentsDashboardV2.vue';

import AddFields from '@/views/documentsV2/NewDocumentFieldsV2.vue';

import Home from '@/views/documents/Home.vue';
import NewDocument from "@/views/documents/NewDocument.vue";
import SingleDocument from "@/views/documents/SingleDocument.vue";
import navRightActions from '@/components/dashboard/navActions/OrderDocumentOptions.vue';
import {CommonRouteMeta} from "@/types";

const BaseDashboard = () => import('@/views/dashboard/BaseDashboard.vue');

const documentRoutes: RouteRecordRaw[] = [
    {
        path: '/documents',
        component: BaseDashboard,
        meta: {
            title: 'documents',
            icon: 'mdi-file-document-outline',
            noCache: false,
            showInMenu: true,
            middleware: ['auth'],
            layout: 'Dashboard',
        } as CommonRouteMeta,
        children: [
            {
                path: '',
                component: Home,
                name: 'documents.index',
                meta: {title: 'Documents', icon: 'mdi-file-document-outline', noCache: false},
            },
            {
                path: ':uuid',
                components: {
                    default: SingleDocument,
                    navRightActions: navRightActions
                },
                name: 'documents.show',
                meta: {title: '', icon: 'mdi-file-document-outline', noCache: false},
            },
            {
                path: 'prepare/:uuid',
                component: NewDocument,
                name: 'documents.prepare',
                meta: {title: 'Document Prep', icon: 'mdi-file-document-outline', noCache: false},
            },
            {
                path: 'new',
                component: NewDocument,
                name: 'documents.new',
                meta: {title: 'Document New', icon: 'mdi-file-document-outline', noCache: false},
            },
        ],
    },
    {
        path: '/documents-v2',
        component: DocumentsDashboardLayoutV2,
        meta: {hidden: true, middleware: ['auth'], layout: 'Dashboard', noCache: false} as CommonRouteMeta,
        children: [
            {
                path: '',
                component: Home,
                name: 'documentsv2.index',
                meta: {title: '', icon: 'mdi-file-document-outline', noCache: false},
            },
            {
                path: 'prepare/:uuid/add-fields',
                component: AddFields,
                name: 'documentsv2.fields',
                meta: {title: 'Document Add Fields', icon: 'mdi-file-document-outline', noCache: false},
            },
        ],
    },
];

export default documentRoutes;
