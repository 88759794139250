export default async function guest({store}) {
    console.log('%c -- MIDDLEWARE -- GUEST ✅', 'color: white; background: green; padding: 2px; font-weight: bold;');

    try {
        // Retrieve authentication token from Vuex store
        const token = store.getters['auth/token'];
        const isAuthenticated = store.getters['auth/check'];

        console.log('%c 🔑 Auth Token:', 'color: cyan; font-weight: bold;', token);
        console.log('%c 👤 Is Authenticated?', 'color: cyan; font-weight: bold;', isAuthenticated);

        // Check if user is authenticated
        if (token) {
            console.warn('%c 🚪 Guest Middleware: User is logged in - Redirecting...', 'color: orange; font-weight: bold;');
            return false;
        }

        console.log('%c ✅ Guest Middleware: User is NOT logged in', 'color: lime; font-weight: bold;');
        return true;
    } catch (error) {
        console.error('%c ❌ Guest Middleware Error:', 'color: red; font-weight: bold;', error);
        return false; // Fallback to assuming user is logged in to prevent unintended access
    }
}