import {ActionTree, GetterTree} from 'vuex';
import {EventBus} from '@/plugins/eventBus';
import axios from 'axios';
import * as types from '@/store/mutation-types';

export interface SquareState {
    loading: Record<string, boolean>;
    currentWorkingInvoice: any;
}

export interface SquareActions {
    getSquareOauthUrl(context: any): Promise<void>;

    saveSquareCode(context: any, data: { code: string; state: string }): Promise<void>;

    getSquareProvider(context: any): Promise<void>;

    testPayment(context: any, squareId: string): Promise<void>;
}

// state
const state: SquareState = {
    loading: {
        analytics: false,
        fetchCart: false,
    },
    currentWorkingInvoice: '',
};

const getters: GetterTree<SquareState, any> = {
    loading: (state) => state.loading,
};

const mutations = {
    [types.SET_SQUARE_LOADING](state: SquareState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
};

const actions: ActionTree<SquareState, any> & SquareActions = {

    async getSquareOauthUrl({commit}) {
        commit(types.SET_SQUARE_LOADING, {subject: 'analytics', loading: true});

        try {
            const response = await axios.get('/v1/square/url');
            EventBus.$emit('square-oauth-url-create-success', response.data);
        } catch (e) {
            EventBus.$emit('square-oauth-url-create-error', e.response);
        } finally {
            commit(types.SET_SQUARE_LOADING, {subject: 'analytics', loading: false});
        }
    },

    async saveSquareCode({commit}, data: { code: string; state: string }) {
        commit(types.SET_SQUARE_LOADING, {subject: 'analytics', loading: true});

        try {
            const response = await axios.post('/v1/square/redirect', data);
            EventBus.$emit('square-oauth-url-create-success', response.data);
        } catch (e) {
            EventBus.$emit('square-oauth-url-create-error', e.response);
        } finally {
            commit(types.SET_SQUARE_LOADING, {subject: 'analytics', loading: false});
        }
    },

    async getSquareProvider() {
        try {
            const response = await axios.get('/v1/square/');
            EventBus.$emit('square-oauth-success', response.data);
        } catch (e) {
            console.error('Error fetching Square provider:', e);
        }
    },

    async testPayment(_, squareId: string) {
        try {
            const response = await axios.post(`/v1/square/testPayment?squareId=${squareId}`);
            EventBus.$emit('delete-square-oauth-success', response.data);
        } catch (e) {
            console.error('Error testing Square payment:', e);
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
