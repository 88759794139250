import {computed, ComputedRef} from 'vue';
import {useStore} from 'vuex';
import {AppFeatures} from '@/types';

interface FeatureWaitlist {
    feature: string;
}

export function useAppFeatures() {
    const store = useStore();

    const appFeatures: ComputedRef<AppFeatures> = computed(() => store.getters['app/appFeatures']);
    const featureWaitlist = computed(() => store.getters['app/featureWaitlist']);


    const canFilterByPaymentStatus = computed(() => appFeatures.value?.['canFilterByPaymentStatus'] || false);
    const canUseSquareIntegration = computed(() => appFeatures.value?.['square-payment-profile'] || false);
    const canUseBraintreeIntegration = computed(() => appFeatures.value?.['braintree-payment-profile'] || false);
    const hasAxleIntegrationFeature = computed(() => appFeatures.value?.['axle-integration'] || false);
    const hasPricingTemplateFeature = computed(() => appFeatures.value?.['pricing-template'] || false);
    const hasStoreThemesFeature = computed(() => appFeatures.value?.['store-themes'] || false);
    const hasRenterBlockFeature = computed(() => appFeatures.value?.['renter-block'] || false);

    function hasWaitlistRequest(feature: string): boolean {
        return featureWaitlist.value?.some((f: FeatureWaitlist) => f.feature === feature) ?? false;
    }

    function addFeatureWaitlist(featureWaitlist: FeatureWaitlist): void {
        store.dispatch('app/addFeatureWaitlist', featureWaitlist);
    }

    function getFeatureWaitlist(): void {
        store.dispatch('app/getFeatureWaitlist');
    }

    return {
        appFeatures,
        addFeatureWaitlist,
        hasWaitlistRequest,
        getFeatureWaitlist,

        // computed
        canFilterByPaymentStatus,
        canUseSquareIntegration,
        canUseBraintreeIntegration,
        hasAxleIntegrationFeature,
        hasPricingTemplateFeature,
        hasStoreThemesFeature,
        hasRenterBlockFeature,
    }
}