<template>
  <!-- Terms & Discounts -->
  <v-card ref="termsSection" class="mt-5" style="border: 1px solid rgb(224, 220, 229);" variant="flat">
    <v-card-title :class="{'cursor-pointer' : subscriptionStep === 0}" @click="handleStepClick">
      <div class="flex">
        <span class="text-sm md:text-base">2. Term & Discounts</span>
        <v-spacer/>
        <v-btn
          v-if="subscriptionStep !== 1"
          icon="mdi-chevron-down"
          variant="text"
        />
      </div>
    </v-card-title>

    <div v-show="subscriptionStep === 1">
      <v-item-group
        v-model="updatedSubscription.interval"
        mandatory
      >
        <div v-for="(_details, term) in planPricing" :key="term">
          <v-item v-slot="{ isSelected, toggle }" :value="term">
            <v-card
              :color="isSelected ? 'grey-200' : ''"
              class="d-flex align-center border-b rounded-b-none"
              variant="flat"
              @click="toggle"
            >
              <v-scroll-y-transition>
                <div class="p-3 w-full">
                  <div class="subscription__card d-flex justify-space-between align-center">
                    <div class="subscription__card--first d-flex align-center">
                      <div class="mr-2">
                        <v-btn
                          :icon="isSelected ? 'mdi-circle' : 'mdi-circle-outline'"
                          variant="text"
                        />
                      </div>

                      <div class="subscription__card--details">
                        <div class="subscription__card--title">
                          <div class="font-bold">
                            <div v-if="term === 'monthly'">Monthly</div>
                            <div v-else>Annual</div>
                          </div>
                        </div>

                        <div class="subscription__card--description text-caption text-md-body-2">
                          <div v-if="term === 'monthly'">Month-to-month contract</div>
                          <div v-else>Save up to 29% by committing to a 12-month plan</div>
                        </div>
                      </div>
                    </div>

                    <div class="subscription__card--second text-right">
                      <div class="subscription__card--price-title text-caption text-md-body-2">Per Month</div>
                      <div class="subscription__card--price-price d-flex align-end justify-end">
                        <span style="padding-top: 4px;">$</span>
                        <span style="font-size: 24px;align-self: end;">
                          {{ planPricing[term][planSelectionName] }}
                        </span>
                        <span style="font-size: 14px;align-self: end;padding-bottom: 2px;">/mo</span>
                      </div>
                    </div>
                  </div>
                </div>
              </v-scroll-y-transition>
            </v-card>
          </v-item>
        </div>
      </v-item-group>
    </div>
  </v-card>
</template>

<script lang="ts" setup>
import {useSubscriptionManager} from '@/composables/useSubscriptionManager';
import {ref} from 'vue';
import type {VCard} from 'vuetify/components';

const termsSection = ref<InstanceType<typeof VCard> | null>(null);

const {
  subscriptionStep,
  planPricing,
  updatedSubscription,
  planSelectionName
} = useSubscriptionManager();

const handleStepClick = () => {
  if (subscriptionStep.value === 0) {
    subscriptionStep.value = 1;
    // Wait for the next tick to ensure the content is rendered
    setTimeout(() => {
      termsSection.value?.$el?.scrollIntoView({behavior: 'smooth', block: 'start'});
    }, 100);
  } else {
    subscriptionStep.value = 1;
  }
};
</script>
