import { Commit } from 'vuex';
import { AxiosResponse } from 'axios';

/**
 * Sets the loading state for a given subject.
 * @param commit - Vuex commit function
 * @param commitName - The name of the commit
 * @param subject - The subject of the loading state
 * @param value - The loading state value
 */
export const setLoading = (commit: Commit, commitName: string, subject: string, value: boolean) => {
    commit(commitName, { subject, loading: value });
};


/**
 * Performs an API request with loading state management.
 * @template T - The type of the response data
 * @param commit - Vuex commit function
 * @param loadingCommitName - The name of the loading commit
 * @param subject - The subject of the loading state
 * @param apiCall - The API call function
 * @returns The response data
 * @throws The error thrown by the API call
 */
export const performApiRequest = async <T>(
    commit: Commit,
    loadingCommitName: string,
    subject: string,
    apiCall: () => Promise<AxiosResponse<T>>
): Promise<T> => {
    try {
        setLoading(commit, loadingCommitName, subject, true);
        const response = await apiCall();
        return response.data;
    } catch (error) {
        console.error(`Error during API call for ${subject}: ${error}`);
        throw error;
    } finally {
        setLoading(commit, loadingCommitName, subject, false);
    }
};