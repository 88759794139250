<template>
  <v-app class="z-0 relative">
    <component :is="layoutComponent">
      <router-view/>
    </component>

    <Notivue v-slot="item">
      <Notification :item="item"/>
    </Notivue>
  </v-app>
</template>

<script lang="ts" setup>
import {computed, defineAsyncComponent, onBeforeMount} from 'vue';
import {useRoute} from 'vue-router';
import {store} from '@/store';
import 'notivue/animations.css';
import 'notivue/notification.css';
import {Notification, Notivue} from 'notivue'

const TheDefaultLayout = defineAsyncComponent(() => import('@/layouts/TheDefaultLayout.vue'));

// Define the allowed layout names explicitly
type LayoutKey = 'Dashboard' | 'Esign' | 'CompanyStore' | 'Default';

const layoutMap = {
  Dashboard: defineAsyncComponent({
    loader: () => import('@/layouts/TheDashboardLayout.vue'),
    delay: 200, // Avoid flicker on fast networks
    timeout: 5000, // Fail if loading takes too long
  }),
  Esign: defineAsyncComponent(() => import('@/layouts/TheEsignLayout.vue')),
  CompanyStore: defineAsyncComponent(() => import('@/layouts/TheCompanyStoreLayout.vue')),
  Default: defineAsyncComponent(() => import('@/layouts/TheDefaultLayout.vue')),
};

const route = useRoute();

/**
 * Dynamically determine the layout component based on the route's metadata.
 * Defaults to a standard layout or fallback if no layout is specified.
 */
const layoutComponent = computed(() => {
  const layoutName = route.meta?.layout as LayoutKey | undefined;
  const resolvedLayout = layoutName && layoutMap[layoutName] ? layoutMap[layoutName] : TheDefaultLayout;

  console.log(`🖥️ Using Layout: ${layoutName || 'Default'}`);
  return resolvedLayout;
});

onBeforeMount(() => {
  // Initialize the app state
  store.commit('app/INITIALIZE_STATE');
});
</script>