import axios from 'axios';
import {filter, findIndex, flatMap, get, map, slice} from 'lodash';
import {EventBus} from '@/plugins/eventBus';
import * as types from '../mutation-types';

export interface TuroPricingState {
    init: boolean,
    loading: Record<string, boolean>,
    allVehicles: any[],
    listedVehicles: any[],
    snoozedVehicles: any[],
    vehiclePricing: any[],
    outlookDays: number,

}

const state: TuroPricingState = {
    init: false,

    loading: {
        turo_me: false,
        updateVehiclesDailyPrice: false,
        fetchVehicleDailyPrice: false,
    },
    outlookDays: 28,
    allVehicles: [],
    listedVehicles: [],
    snoozedVehicles: [],
    vehiclePricing: [],
};

const getters = {
    turoInit: (state: TuroPricingState) => state.init,
    loading: (state: TuroPricingState) => state.loading,
    allVehicles: (state: TuroPricingState) => state.allVehicles,
    listedVehicles: (state: TuroPricingState) => state.listedVehicles,
    vehiclePricing: (state: TuroPricingState) => state.vehiclePricing,
    outlookDays: (state: TuroPricingState) => state.outlookDays,
};

const mutations = {
    [types.SET_INIT](state: TuroPricingState, init) {
        state.init = init;
    },
    [types.SET_TURO_PRICING_LOADING](state: TuroPricingState, loading) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_TURO_PRICING_VEHICLES](state: TuroPricingState, vehicles) {
        state.allVehicles = vehicles;

        const listed = filter(vehicles, (v) => get(v, 'status.status.value') === 'LISTED');

        const prices = [];
        for (let i = 0; i < state.outlookDays; i++) {
            prices.push({
                date: null,
                price: null,
                selected: false,
                vid: null,
            });
        }
        state.listedVehicles = map(listed, (l) => ({
            id: l.id,
            image: get(l, 'image.originalImageUrl'),
            name: l.name,
            status: get(l, 'status.status.value'),
            vin: get(l, 'vin'),
            year: get(l, 'year'),
            make: get(l, 'make'),
            model: get(l, 'model'),
            prices,
            pricesSet: false,
            rowSelected: false,
        }));

        state.snoozedVehicles = filter(vehicles, (v) => get(v, 'status.status.value') === 'SNOOZED');
    },
    [types.SET_TURO_VEHICLE_DAILY_PRICING](state: TuroPricingState, data) {
        const vehicleId = get(data, 'data.vehicleId');
        let pricing = get(data, 'pricing');

        const vehicleIndex = findIndex(state.listedVehicles, (v) => v.id.toString() === vehicleId.toString());


        pricing = slice(pricing, 0, state.outlookDays);

        // noinspection JSUnusedLocalSymbols
        pricing = flatMap(pricing, (p, index) => ({
            price: p.price,
            selected: false,
            vid: vehicleId,
            date: p.date,
        }));

        if (vehicleIndex >= 0) {
            state.listedVehicles[vehicleIndex].prices = pricing;
        }

        state.vehiclePricing.push({id: vehicleId, prices: pricing});
    },
};

const actions = {

    fetchTuroMe({commit}, turo_uuid) {
        commit('SET_TURO_PRICING_LOADING', {subject: 'turo_me', loading: true});

        axios.get(`/v1/connections/turo/${turo_uuid}/me`)
            .then((response) => {
                commit('SET_TURO_PRICING_VEHICLES', get(response, 'data.me.vehicles'));

                EventBus.$emit('fetch-turo-me-success', response.data.me.vehicles);
            }).catch((e) => {
            if (e.response.status === 400 || e.response.status === 403) {
                EventBus.$emit('fetch-turo-connection-failed-400', e.response.data.message);
            }

            if (e.response.status === 428) {
                EventBus.$emit('fetch-turo-connection-failed-428', e.response.data.message);
            }
        }).finally(() => {
            commit('SET_TURO_PRICING_LOADING', {subject: 'turo_me', loading: false});
        });
    },

    fetchVehicleDailyPrice({commit, rootGetters}, data) {
        commit('SET_TURO_PRICING_LOADING', {subject: 'fetchVehicleDailyPrice', loading: true});

        const query = `?start=${data.start}&end=${data.end}&vehicleId=${data.vehicleId}`;
        axios.get(`/v1/company/${rootGetters['company/company'].uuid}/connections/turo/${data.turo_uuid}/vehicle/${data.vehicleId}/daily_price${query}`)
            .then((response) => {
                commit('SET_TURO_VEHICLE_DAILY_PRICING', get(response, 'data'));
                EventBus.$emit('fetch-turo-vehicle-daily-pricing-success', get(response, 'data'));
            }).catch((e) => {
            console.dir(e);
            if (e.response.status === 429) {
                EventBus.$emit('turo-too-many-requests-429', data);
            }
        }).finally(() => {
            commit('SET_TURO_PRICING_LOADING', {subject: 'fetchVehicleDailyPrice', loading: false});
        });
    },

    updateVehiclesDailyPrice({commit, rootGetters}, data) {
        commit('SET_TURO_PRICING_LOADING', {subject: 'updateVehiclesDailyPrice', loading: true});

        axios.post(`/v1/company/${rootGetters['company/company'].uuid}/connections/turo/${data.turo_uuid}/daily_price`, data)
            .then((response) => {
                EventBus.$emit('update-turo-vehicle-daily-pricing-success', get(response, 'data'));
            }).catch((e) => {
            console.dir(e);
            if (e.response.status === 400 || e.response.status === 403) {
                EventBus.$emit('create-turo-connection-failed-400', e.response.data.message);
            }
        }).finally(() => {
            commit('SET_TURO_PRICING_LOADING', {subject: 'updateVehiclesDailyPrice', loading: false});
        });
    },

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
