<template>
  <!-- Plan Selection -->
  <v-card style="border: 1px solid rgb(224, 220, 229);" variant="flat">
    <v-card-title :class="{'cursor-pointer' : subscriptionStep !== 0}" @click="subscriptionStep = 0">
      <div class="flex items-center">
        <span class="text-sm md:text-base">1. Choose your plan</span>
        <v-spacer/>
        <v-btn
          v-if="subscriptionStep > 0"
          icon="mdi-chevron-down"
          size="small"
          variant="text"
        />
      </div>
    </v-card-title>

    <div v-if="subscriptionStep === 0">
      <v-item-group v-model="planSelection" mandatory>
        <div v-for="(plan, i) in plansOrdered" :key="i">
          <v-item v-slot="{ isSelected, toggle }">
            <v-card
              :class="isSelected ? 'plan-selected' : ''"
              :color="isSelected ? 'grey-200' : ''"
              class="d-flex flex-column md:flex-row align-start md:align-center border-b rounded-b-none"
              dark
              variant="flat"
              @click="toggle"
            >
              <v-scroll-y-transition>
                <div class="p-3 w-full">
                  <div class="subscription__card flex flex-col md:flex-row w-full">
                    <div class="subscription__card--first flex-1">
                      <div class="flex items-start">
                        <div>
                          <v-btn :icon="isSelected ? 'mdi-circle' : 'mdi-circle-outline'" size="small" variant="text"/>
                        </div>

                        <div class="subscription__card--details ml-2">
                          <div class="subscription__card--title">
                            <div class="font-bold uppercase text-sm md:text-base">
                              {{ plan.name }}
                            </div>

                            <div v-if="plan.name === subscriptionPlan" class="text-xs md:text-sm">
                              <span class="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300" type="info">Current plan</span>
                            </div>
                            <div v-if="plan.name === 'premium'" class="text-xs md:text-sm">
                              <span class="bg-gray-200 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300" type="info">You trialed this plan</span>
                            </div>
                          </div>

                          <div v-if="plan.name === 'premium'" class="subscription__card--description text-xs md:text-sm">
                            Everything in the Standard plan + more vehicles, connections and advanced features.
                          </div>

                          <div v-if="plan.name === 'standard'" class="subscription__card--description text-xs md:text-sm">
                            Everything in the Basic plan + more vehicles, esign capability, coupons, and more.
                          </div>

                          <div v-if="plan.name === 'basic'" class="subscription__card--description text-xs md:text-sm">
                            Great for just getting started.
                          </div>

                          <div class="subscription__card--bullets mt-2">
                            <template v-for="bullet in plan.description.bullets" :key="bullet">
                              <div v-if="bullet !== 'And More*'" class="subscription__card--bullet flex items-start">
                                <div class="badge badge-rounded-circle badge-success-soft mt-1 mr-2">
                                  <v-icon color="success" size="x-small">mdi-check</v-icon>
                                </div>
                                <p class="text-xs md:text-sm">
                                  {{ bullet }}
                                </p>
                              </div>
                            </template>

                            <div class="subscription__card--bullet flex items-start">
                              <div class="badge badge-rounded-circle badge-success-soft mt-1 mr-2">
                                <v-icon color="success" size="x-small">mdi-check</v-icon>
                              </div>
                              <p class="text-xs md:text-sm">
                                <a
                                  href="https://fleetwire.io/pricing/?utm_source=subscription-checkout&utm_medium=subscription-page&utm_campaign=subscription-review"
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  @click.stop
                                >
                                  And more...
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="subscription__card--second mt-3 md:mt-0 md:ml-4">
                      <div class="subscription__card--price-title text-xs md:text-sm">Starts At</div>
                      <div class="subscription__card--price-price flex items-baseline">
                        <span class="text-sm md:text-base">$</span>
                        <span class="text-lg md:text-2xl">
                          {{ planPricing['yearly'][plan.name] }}
                        </span>
                        <span class="text-xs md:text-sm">/mo</span>
                      </div>
                    </div>
                  </div>
                </div>
              </v-scroll-y-transition>
            </v-card>
          </v-item>
        </div>
      </v-item-group>
    </div>
  </v-card>
</template>

<script lang="ts" setup>
import {useSubscription} from '@/composables/useSubscription';
import {useSubscriptionManager} from '@/composables/useSubscriptionManager';

const {subscriptionPlan} = useSubscription();
const {subscriptionStep, planSelection, plansOrdered, planPricing} = useSubscriptionManager();
</script>
