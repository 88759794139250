import axios from 'axios';
import { get } from 'lodash';
import * as types from '../mutation-types';

export interface TuroResearchState {
    init: boolean;
    loading: Record<string, boolean>;
    locations: any[];
    makes: any[];
    researchList: any[];
}

const state: TuroResearchState = {
  init: false,

  loading: {
    locations: false,
    makes: false,
    research: false,
  },
  locations: [],
  makes: [],
  researchList: [],
};

const getters = {
  turoInit: (state) => state.init,
  loading: (state) => state.loading,
  locations: (state) => state.locations,
  makes: (state) => state.makes,
  researchList: (state) => state.researchList,
};

const mutations = {
  [types.SET_INIT](state, init) {
    state.init = init;
  },
  [types.SET_TURO_RESEARCH_LOADING](state, loading) {
    state.loading[loading.subject] = loading.loading;
  },
  [types.SET_TURO_ALL_VEHICLE_MAKES](state, makes) {
    state.makes = makes;
  },
  [types.SET_TURO_ALL_DELIVERY_LOCATIONS](state, locations) {
    state.locations = locations;
  },
  [types.SET_TURO_RESEARCH_SEARCH_LIST](state, list) {
    state.researchList = list;
  },
};

const actions = {

  /**
   * Fetch delivery locations
   *
   * @param commit
   * @param rootGetters
   */
  fetchDeliveryLocations({ commit, rootGetters }) {
    commit('SET_TURO_RESEARCH_LOADING', { subject: 'locations', loading: true });

    axios.get(`/v1/company/${rootGetters['company/company'].uuid}/connections/turo/delivery-locations`)
    .then((response) => {
      commit('SET_TURO_ALL_DELIVERY_LOCATIONS', get(response, 'data.locations'));
    }).catch((e) => {
      console.dir(e);
    }).finally(() => {
      commit('SET_TURO_RESEARCH_LOADING', { subject: 'locations', loading: false });
    });
  },


  /**
   * Fetch makes
   *
   * @param commit
   * @param rootGetters
   */
  fetchMakes({ commit, rootGetters }) {
    commit('SET_TURO_RESEARCH_LOADING', { subject: 'makes', loading: true });

    axios.get(`/v1/company/${rootGetters['company/company'].uuid}/connections/turo/fetch-makes`)
    .then((response) => {
      commit('SET_TURO_ALL_VEHICLE_MAKES', get(response, 'data.makes'));
    }).catch((e) => {
      console.dir(e);
    }).finally(() => {
      commit('SET_TURO_RESEARCH_LOADING', { subject: 'makes', loading: false });
    });
  },


  /**
   * Start Turo research
   *
   * @param state
   * @param commit
   * @param rootGetters
   * @param {Object} data
   */
  startResearch({ state, commit, rootGetters }, data) {
    commit('SET_TURO_RESEARCH_LOADING', { subject: 'research', loading: true });

    axios.post(`/v1/company/${rootGetters['company/company'].uuid}/connections/turo/research`, data)
    .then((response) => {
      commit('SET_TURO_RESEARCH_SEARCH_LIST', get(response, 'data.list.list'));
    }).catch((e) => {
      console.dir(e);
    }).finally(() => {
      commit('SET_TURO_RESEARCH_LOADING', { subject: 'research', loading: false });
    });
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
