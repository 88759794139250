import {Form} from '@/plugins/FWForms/index';
import {findIndex, get, remove, set} from 'lodash';
import axios from 'axios';
import {EventBus} from '@/plugins/eventBus';
import dayjs, {Dayjs} from 'dayjs';
import duration from 'dayjs/plugin/duration';
import {StorageService} from '@/services/storage.service';
import * as types from '../mutation-types';
import {createOrderUrlQueryString} from '@/utils/urls';
import {BookingInterface, ListingInterface, OrderLine, StoreLoaders} from '@/types';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {GetterTree} from 'vuex';
import {RootState} from '@/types/RootState';
import {push} from 'notivue'

dayjs.extend(duration);

export type CancelBookingPayload = {
    b_id: string;
    refund: boolean;
    unblockTuro: boolean;
    sendCancellationEmail: boolean;
}


type BookingQueryOptions = {
    page?: number,
    sortDesc?: string[] | boolean,
    itemsPerPage?: number,
    includeAbandoned?: boolean,
    onlyAbandoned?: boolean,
    withOrderProperties?: boolean,
    withRenterProperties?: boolean,
    searchTerm?: string,
    searchTopic?: string,
    exporting?: boolean,
    providers?: string[],
    statuses?: string[],
    filter?: any,
    sortBy?: Array<{ key: string, order: string }>
}


function prepareBookingData(options: BookingQueryOptions = {}) {
    const {
        page = 1,
        sortDesc = [],
        itemsPerPage = 20,
        includeAbandoned = false,
        onlyAbandoned = false,
        withOrderProperties,
        withRenterProperties,
        searchTerm,
        searchTopic,
        exporting,
        providers = [],
        statuses = [],
        filter,
        sortBy
    } = options;

    const data: {
        page: number;
        sortDesc: string | boolean;
        itemsPerPage: number;
        includeAbandoned?: boolean;
        onlyAbandoned?: boolean;
        withOrderProperties?: boolean;
        withRenterProperties?: boolean;
        searchTerm?: string;
        searchTopic?: string;
        exporting?: boolean;
        providers: string[];
        statuses?: string;
        filter?: any;
        sortBy?: string;
        filterQuery?: string;
    } = {
        page,
        sortDesc: Array.isArray(sortDesc) && sortDesc.length === 1 ? sortDesc[0] : !!sortDesc,
        itemsPerPage,
        includeAbandoned,
        onlyAbandoned,
        withOrderProperties,
        withRenterProperties,
        searchTerm,
        searchTopic,
        exporting,
        providers
    };

    if (statuses.length > 0) {
        data.statuses = statuses.join(',');
    }

    if (providers.length > 0) {
        data.statuses = providers.join(',');
    }

    if (!includeAbandoned) {
        delete data.includeAbandoned;
    }

    if (!onlyAbandoned) {
        delete data.onlyAbandoned;
    }

    if (filter) {
        data.filter = filter;
        data.filterQuery = Object.entries(filter)
            .filter(([_, value]) => value)
            .map(([key, value]) => `filter[${key}]=${value}`)
            .join('&');
    }

    if (sortBy) {
        data.sortBy = sortBy?.[0]?.key;
        data.sortDesc = sortBy?.[0]?.order === 'desc';
    }

    return data;
}

export interface BookingGetters extends GetterTree<BookingState, RootState> {
    init(state: BookingState): boolean;

    notFound(state: BookingState): boolean;

    statusFilterItems(state: BookingState): string[];

    startsAtFilterItems(state: BookingState): string | null;

    statusFilterTemp(state: BookingState): string[];

    paymentStatusFilterItems(state: BookingState): string[];

    activityFilter(state: BookingState): string;

    sourcesFilter(state: BookingState): string[];

    booking(state: BookingState): BookingInterface;

    priorityBookings(state: BookingState): BookingInterface[];

    bookings(state: BookingState): BookingInterface[];

    meta(state: BookingState): any;

    fleetResources(state: BookingState): any;

    abandonedBookings(state: BookingState): BookingInterface[];

    totalBookings(state: BookingState): number;

    customerBooking(state: BookingState): any;

    bookingLoading(state: BookingState): Record<string, boolean>;

    calendarAttributes(state: BookingState): any[];

    bookingCustomer(state: BookingState): any;

    bookingVehicle(state: BookingState): any;

    completeBooking(state: BookingState): {
        b_id: string,
    };

    filteredMake(state: BookingState): object[];

    turoBookings(state: BookingState): any[];

    rememberedCustomer(state: BookingState): any;

    res2(state: BookingState): any;

    bookingForm(state: BookingState): Form<any>;

    bookingPayments(state: BookingState): any[];

    automationEvents(state: BookingState): any[];
}


type BookingVehicleSwapData = {
    b_id: string,
    listing: ListingInterface,
    transferListingData: boolean,
    transferUnavailabilityOnCarSharing: boolean,
}

type CalendarAttribute = {
    key: string,
    id?: number,
    highlight: {
        color: string,
        fillMode: string,
    },
    dates: string | Dayjs | Date | string[],
    popover: {
        label: string,
    },
    bar?: string,
    trip_start?: string,
    trip_end?: string,
    trip_start_buffer?: string,
    trip_end_buffer?: string,
}


export interface BookingMeta {
    current_page: number;
    from: number | null;
    last_page: number;
    links: Array<{ url: string | null, label: string, active: boolean }>;
    path: string;
    per_page: number;
    stats: {
        count: {
            [key: string]: number,
        },
        payment_status: {
            [key: string]: number,
        },
        sources: {
            [key: string]: number,
        },
        statuses: {
            [key: string]: number,
        },
        tag_list: string[],
        total: number,
    }
    to: number | null;
    total: number;
    total_bookings: number;
}

export type BookingState = {
    dialogs: Record<string, boolean>,
    loading: StoreLoaders,
    init: boolean,
    notFound: boolean,
    listing: ListingInterface,
    statusFilterItems: string[],
    startsAtFilterItems: string | null,
    statusFilterTemp: string[],
    paymentStatusFilterItems: string[],
    activityFilter: string,
    sourcesFilter: string[],
    booking: BookingInterface,
    priorityBookings: BookingInterface[],
    bookings: BookingInterface[],
    meta: BookingMeta,
    fleetResources: any,
    abandoned_bookings: BookingInterface[],
    totalBookings: number,
    customerBooking: any,
    calendarAttributes: CalendarAttribute[],
    unavailableDates: any[],
    completeBooking: {
        b_id: string,
    },
    filteredMake: object[],
    turoBookings: any[],
    rememberedCustomer: any,
    res2: any,
    bookingForm: Form<any>,
    bookingPayments: any[],
    automationEvents: any[],
}


const state: BookingState = {
    init: false,
    notFound: false,

    dialogs: {
        createTripExtensionDialog: false,
    },

    loading: {
        booking: false,
        booking_logs: false,
        bookings: false,
        meta: false,
        priority_bookings: false,
        fleet_resources: false,
        manualCheckout: false,
        check_vehicles_availability: false,
        updateBooking: false,
        extendBooking: false,
        deleteExtension: false,
        cancelBooking: false,
        bookingPayments: false,
        bookingStatus: false,
        addOrderItem: false,
        startBookingVehicleSwap: false,
        startBookingVehicleSwapStep2: false,
        attachCommissionPartner: false,
        getAutomationEvents: false,
        updateBookingCustomerFields: false,
        manuallyRemoveBooking: false,
        deleteTripPhotos: false,
    },

    bookingForm: new Form(),

    listing: null,

    customerBooking: {},

    totalBookings: 0,
    priorityBookings: [],
    bookings: [],
    abandoned_bookings: [],

    booking: {
        init: false,
        complete: 0,
        created_at: '',
        days: 0,
        delivery_price: 0,
        discount: 0,
        extras_price: 0,
        manually_deleted_at: null,
        booking_meta: null,
        payment_status: '',
        price_per_day: 0,
        r_id: '',
        renter: null,
        required_options_price: 0,
        source: '',
        status: '',
        taxes: 0,
        timezone: '',
        total: 0,
        trip_end: '',
        trip_end_tz: '',
        trip_start: '',
        trip_start_tz: '',
        turo_trip: null,
        updated_at: '',
        method: '',
        miles_extra: 0,
        miles_included: 0,

        b_id: null,
        l_id: null,
        cartId: null,

        host: null,

        order_items: [],

        customer: {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            dob: '',
            age: '',
            signaturePng: '',
            signFullName: '',
            signInitials: '',
            agreeAdoptSignature: false,
            agreeToS: false,
        },

        settings: {
            zip_code: '',
            age_requirement: 0,
            mile_allowance: {
                miles_included: 0,
                miles_extra: 0,
                miles_extra_selected: false,
            },

            young_driver: 0,
            description: '',
        },

        vehicle: {
            available: false,
            price: 0,
            title: '',
            color: '',
            year: '',
            image: '',
            images: [],
            duration: '',
            miles_included: '',
            lat: '',
            long: '',
        },

        listing: null,

        pricing: {
            original_price: 0,
            price: 0,
            price_type: '',
            price_period: '',

            discounts: {
                success: false,
                loading: false,
                discounted: false,
                code: '',
                discount: '',
                discountValue: 0,
                newPrice: 0,
                type: '',
                reason: '',
            },

            delivery: 0,
            extras: 0,
            requiredOption: 0,
            total_per_day: 0,
            subTotal: 0,
            grandTotal: 0,
            paymentDisplay: '',
            security_deposit: 0,
        },

        trip_required_options: [],

        trip_displays: {
            tripMilesUnlimited: false,
            tripMilesIncluded: '',
            tripMilesOverageDisplay: '',
            tripMilesHelpModalDisplay: '',

            young_driver: {
                helpModalDisplaySet: false,
                helpModalDisplayH1: '',
                helpModalDisplayP1: '',
            },
        },

        mile_allowance: {
            miles_included: '',
            miles_extra: '',
            miles_extra_selected: false,
        },

        duration: {
            months: 0,
            weeks: 0,
            days: 1,
            hours: 0,
            minutes: 0,
            units: 1,
        },

        location: {
            city: '',
            state: '',
            zip_code: '',
            neighborhood: '',
            airport_code: '',
            price: 0,
            type: '',
            selecting: false,
            count: JSON.parse(localStorage.getItem('count')),
        },

        locations: [],

        delivery: {},
    },

    meta: null,
    bookingPayments: [],

    completeBooking: {
        b_id: '',
    },

    filteredMake: [],

    unavailableDates: [],
    calendarAttributes: [
        {
            key: 'today',
            highlight: {
                color: 'orange',
                fillMode: 'light',
            },
            dates: new Date(),
            popover: {
                label: 'Today',
            },
        },
    ],

    turoBookings: [],

    rememberedCustomer: {},
    res2: null,
    automationEvents: [],
    fleetResources: [],
    statusFilterItems: [] as string[],
    startsAtFilterItems: null,
    statusFilterTemp: [],
    paymentStatusFilterItems: [],
    sourcesFilter: [] as string[],
    activityFilter: '',
};

const getters: BookingGetters = {
    init: (state: BookingState) => state.init,
    notFound: (state: BookingState) => state.notFound,
    statusFilterItems: (state: BookingState) => state.statusFilterItems,
    startsAtFilterItems: (state: BookingState) => state.startsAtFilterItems,
    statusFilterTemp: (state: BookingState) => state.statusFilterTemp,
    paymentStatusFilterItems: (state: BookingState) => state.paymentStatusFilterItems,
    activityFilter: (state: BookingState) => state.activityFilter,
    sourcesFilter: (state: BookingState) => state.sourcesFilter,
    booking: (state: BookingState) => state.booking,
    priorityBookings: (state: BookingState) => state.priorityBookings,
    bookings: (state: BookingState) => state.bookings,
    meta: (state: BookingState) => state.meta,
    fleetResources: (state: BookingState) => state.fleetResources,
    abandonedBookings: (state: BookingState) => state.abandoned_bookings,
    totalBookings: (state: BookingState) => state.totalBookings,
    customerBooking: (state: BookingState) => state.customerBooking,
    bookingLoading: (state: BookingState) => state.loading,
    calendarAttributes: (state: BookingState) => state.calendarAttributes,
    unavailableDates: (state: BookingState) => state.unavailableDates,
    bookingCustomer: (state: BookingState) => state.booking.customer,
    bookingVehicle: (state: BookingState) => state.booking.vehicle,
    completeBooking: (state: BookingState) => state.completeBooking,
    filteredMake: (state: BookingState) => state.filteredMake,
    turoBookings: (state: BookingState) => state.turoBookings,
    rememberedCustomer: (state: BookingState) => state.rememberedCustomer,
    res2: (state: BookingState) => state.res2,
    bookingForm: (state: BookingState) => state.bookingForm,
    bookingPayments: (state: BookingState) => state.bookingPayments,
    automationEvents: (state: BookingState) => state.automationEvents,
    bookingDialogs: (state: BookingState) => state.dialogs,
};

const mutations = {
    [types.SET_BOOKING_LOADING](state: BookingState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },

    [types.SET_BOOKING_DIALOG](state: BookingState, dialog: { subject: string, value: boolean }) {
        state.dialogs[dialog.subject] = dialog.value;
    },
    [types.CLEAR_BOOKING_FORM_ERRORS](state: BookingState, form_field) {
        if (form_field === null || form_field === undefined) {
            state.bookingForm.errors.clear();
        } else {
            state.bookingForm.errors.clear(form_field);
        }
    },
    [types.SET_BOOKING_FORM_ERROR](state: BookingState, error) {
        state.bookingForm.errors.set(error.field, error.message);
    },
    [types.SET_LISTING_ID](state: BookingState, l_id: string) {
        state.booking.l_id = l_id;
    },
    [types.SET_STATUS_FILTER_ITEMS](state: BookingState, statuses) {
        state.startsAtFilterItems = null;
        state.statusFilterItems = statuses;
    },
    [types.SET_STATUS_FILTER_ITEMS_UPCOMING](state: BookingState) {
        state.statusFilterItems = ['reserved'];
        state.startsAtFilterItems = dayjs().format('YYYY-MM-DD HH:mm:ss');
    },
    [types.SET_STATUS_FILTER_TEMP_ITEMS](state: BookingState, filter) {
        state.statusFilterTemp = filter;
    },
    [types.SET_ACTIVITY_FILTER_ITEMS](state: BookingState, activity) {
        state.activityFilter = activity;
    },
    [types.SET_SOURCE_FILTER_ITEMS](state: BookingState, sources) {
        state.sourcesFilter = sources;
    },
    [types.SET_PAYMENT_STATUS_FILTER_ITEMS](state: BookingState, statuses) {
        state.paymentStatusFilterItems = statuses;
    },
    [types.SET_BOOKING_CART_ID](state: BookingState, cartId: string) {
        state.booking.cartId = cartId;
    },
    [types.SET_CURRENT_WORKING_BOOKING](state: BookingState, booking: BookingInterface) {
        state.booking = booking;
        state.init = true;
    },
    [types.SET_CURRENT_WORKING_BOOKING_STATUS](state: BookingState, status: string) {
        set(state.booking, 'status', status);
    },
    [types.SET_CURRENT_WORKING_BOOKING_ATTRIBUTE](state: BookingState, data) {
        set(state.booking, data.attribute, get(data.booking, data.attribute));
    },
    [types.SET_BOOKING_NOT_FOUND](state: BookingState, value) {
        state.notFound = value;
    },
    [types.SET_BOOKINGS](state: BookingState, bookings: BookingInterface[]) {
        state.bookings = bookings;
        state.init = true;
    },
    [types.SET_BOOKINGS_META](state: BookingState, meta) {
        state.meta = meta;
    },
    [types.SET_ABANDONED_BOOKINGS](state: BookingState, bookings: BookingInterface[]) {
        state.abandoned_bookings = bookings;
    },
    [types.SET_PRIORITY_BOOKINGS](state: BookingState, bookings: BookingInterface[]) {
        state.priorityBookings = bookings;
    },
    [types.SET_FLEET_RESOURCES](state: BookingState, bookings: BookingInterface[]) {
        state.fleetResources = bookings;
    },
    [types.SET_BOOKING_AUTOMATION_EVENTS](state: BookingState, events) {
        state.automationEvents = events;
    },
    [types.SET_TOTAL_BOOKINGS](state: BookingState, count) {
        state.totalBookings = count;
    },
    [types.SET_BOOKING_PAYMENTS](state: BookingState, payments) {
        state.bookingPayments = payments;
    },
    [types.MERGE_BOOKING_LINE_ITEM](state: BookingState, line) {
        state.booking.order_items.push(line);
    },
    [types.UPDATE_BOOKING_LINE_ITEM](state: BookingState, line) {
        const lineIndex = findIndex(state.booking.order_items, l => l.uuid === line.uuid);
        if (lineIndex >= 0) {
            state.booking.order_items.splice(lineIndex, 1);
            state.booking.order_items.push(line);
        }
    },
    [types.REMOVE_BOOKING_LINE_ITEM](state: BookingState, line) {
        state.booking.order_items = remove(state.booking.order_items, l => l.uuid !== line);
    },
    [types.SET_FILTERED_MAKES](state: BookingState, make) {
        state.filteredMake = make;
    },
    [types.SET_CALENDAR_ATTRIBUTES](state: BookingState, attributes: CalendarAttribute[]) {
        state.calendarAttributes = attributes;
    },
    [types.PUSH_CALENDAR_ATTRIBUTES](state: BookingState, attribute: CalendarAttribute) {
        state.calendarAttributes.push(attribute);
    },
    [types.RESET_UNAVAILABLE_DATES](state: BookingState) {
        state.calendarAttributes = [
            {
                key: 'today',
                highlight: {
                    color: 'orange',
                    fillMode: 'light',
                },
                dates: new Date(),
                popover: {
                    label: 'Today',
                },
            },
        ];
    },
    [types.SET_UNAVAILABLE_DATES](state: BookingState, dates: {
        trip_start: string,
        trip_end: string,
        trip_start_buffer: string,
        trip_end_buffer: string
    }[]) {
        state.unavailableDates = dates;

        state.calendarAttributes = [{
            key: 'today',
            highlight: {
                color: 'orange',
                fillMode: 'light',
            },
            dates: new Date(),
            popover: {
                label: 'Today',
            },
        },];

        const createCalendarAttribute = (barColor: string, date: string | Dayjs, label: string): CalendarAttribute => ({
            key: 'unavailable-' + date,
            highlight: null,
            bar: barColor,
            dates: [dayjs(date).format()],
            popover: {label},
        });

        dates.forEach(date => {
            const days = dayjs.duration(dayjs(date.trip_end).diff(dayjs(date.trip_start))).asDays();
            const startTimeBuffer = dayjs(date.trip_start_buffer).format('h:mm a');
            const endTimeBuffer = dayjs(date.trip_end_buffer).format('h:mm a');

            if (days === 1) {
                state.calendarAttributes.push(
                    createCalendarAttribute('green', date.trip_start, `Becomes unavailable at ${startTimeBuffer}`),
                    createCalendarAttribute('red', date.trip_start, `Unavailable after ${startTimeBuffer}`),
                    createCalendarAttribute('red', date.trip_end, `Unavailable until ${startTimeBuffer}`),
                    createCalendarAttribute('green', date.trip_end, `Becomes available after ${endTimeBuffer}`),
                );
            } else {
                const middleDur = [];
                for (let i = 1; i < days; i++) {
                    middleDur.push(createCalendarAttribute('red', dayjs(date.trip_start).add(i, 'days'), 'Unavailable'));
                }

                state.calendarAttributes.push(
                    createCalendarAttribute('green', date.trip_start, `Becomes unavailable at ${startTimeBuffer}`),
                    createCalendarAttribute('red', date.trip_start, `Unavailable after ${startTimeBuffer}`),
                    ...middleDur,
                    createCalendarAttribute('red', date.trip_end, `Unavailable until after ${endTimeBuffer}`),
                    createCalendarAttribute('green', date.trip_end, `Becomes available after ${endTimeBuffer}`),
                );
            }
        });
    },

    [types.SET_BOOKING_LISTING](state: BookingState, data) {
        state.listing = data;
        state.listing.init = true;
        const newPricing = data.pricing;
        state.booking.pricing = {...state.booking.pricing, ...newPricing};

        state.booking.pricing.security_deposit = get(data, 'pricing.sec_dep');
    },

    [types.SET_STORED_TURO_BOOKINGS](state: BookingState, bookings) {
        state.turoBookings = bookings;
    },
    [types.SET_CUSTOMER_BOOKING](state: BookingState, booking) {
        state.customerBooking = booking;
    },
    [types.SET_REMEMBER_CUSTOMER](state: BookingState, customer) {
        const data = {
            email: get(customer, 'email'),
            stripe_id: get(customer, 'stripe_id'),
            last4: null,
        };

        if (get(customer, 'last4')) {
            data.last4 = get(customer, 'last4');
        }
        state.rememberedCustomer = customer;
        localStorage.setItem('customer', JSON.stringify(data));
    },
    [types.RESET_BOOKING](state: BookingState) {
        state.booking = {
            fuel_level_end: 0,
            fuel_level_start: 0,
            listing: undefined,
            odometer_end: 0,
            odometer_start: 0,
            b_id: '',
            booking_meta: undefined,
            complete: 0,
            created_at: '',
            days: 0,
            delivery_price: 0,
            discount: 0,
            extras_price: 0,
            manually_deleted_at: '',
            method: '',
            miles_extra: 0,
            miles_included: 0,
            payment_status: '',
            price_per_day: 0,
            r_id: '',
            renter: undefined,
            required_options_price: 0,
            source: '',
            status: '',
            taxes: 0,
            timezone: '',
            total: 0,
            trip_end: '',
            trip_end_tz: '',
            trip_start: '',
            trip_start_tz: '',
            turo_trip: null,
            updated_at: '',
            init: false,
            l_id: null,
            cartId: null,
            host: null,
            order_items: [],
            customer: {
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                dob: '',
                age: '',
                signaturePng: '',
                signFullName: '',
                signInitials: '',
                agreeAdoptSignature: false,
                agreeToS: false,
            },
            settings: {
                zip_code: '',
                age_requirement: 0,
                mile_allowance: {
                    miles_included: 0,
                    miles_extra: 0,
                    miles_extra_selected: false,
                },

                young_driver: 0,
                description: '',
            },

            vehicle: {
                available: false,
                price: 0,
                title: '',
                color: '',
                year: '',
                image: '',
                images: [],
                duration: '',
                miles_included: '',
                lat: '',
                long: '',
            },

            pricing: {
                original_price: 0,
                price: 0,
                price_type: '',
                price_period: '',

                discounts: {
                    success: false,
                    loading: false,
                    discounted: false,
                    code: '',
                    discount: '',
                    discountValue: 0,
                    newPrice: 0,
                    type: '',
                    reason: '',
                },

                delivery: 0,
                extras: 0,
                requiredOption: 0,
                total_per_day: 0,
                subTotal: 0,
                grandTotal: 0,
                paymentDisplay: '',
                security_deposit: 1,
            },

            trip_required_options: [],

            trip_displays: {
                tripMilesUnlimited: false,
                tripMilesIncluded: '',
                tripMilesOverageDisplay: '',
                tripMilesHelpModalDisplay: '',

                young_driver: {
                    helpModalDisplaySet: false,
                    helpModalDisplayH1: '',
                    helpModalDisplayP1: '',
                },
            },

            mile_allowance: {
                miles_included: '',
                miles_extra: '',
                miles_extra_selected: false,
            },

            duration: {
                months: 0,
                weeks: 0,
                days: 1,
                hours: 0,
                minutes: 0,
                units: 1,
            },

            location: {
                city: '',
                state: '',
                zip_code: '',
                neighborhood: '',
                airport_code: '',
                price: 0,
                type: '',
                selecting: false,
                count: JSON.parse(localStorage.getItem('count')),
            },

            locations: [],

            delivery: {}
        };
    },
};

const actions = {

    async fetchSingleBooking({commit}, b_id: string) {
        commit(types.SET_BOOKING_NOT_FOUND, false);

        try {
            const responseData = await performApiRequest(commit, 'SET_BOOKING_LOADING', 'booking', () => axios.get(`/v1/bookings/${b_id}`));


            const renter = responseData?.booking?.renter || [];
            const order = responseData?.booking?.order || [];

            commit('SET_CURRENT_WORKING_BOOKING', responseData.booking);
            commit('renters/SET_RENTER', renter, {root: true});
            commit('order/SET_ORDER', order, {root: true});
            EventBus.$emit('fetch-single-booking-success', responseData.booking);
        } catch (error) {
            if (error.response.status === 404) {
                commit(types.SET_BOOKING_NOT_FOUND, true);
            }
        }
    },

    getBookingActivityLogs({commit}, b_id: string) {
        commit('SET_BOOKING_LOADING', {subject: 'booking_logs', loading: true});

        axios.get(`/v1/bookings/${b_id}/activity-logs`)
            .then(response => {
                EventBus.$emit('get-booking--activity-logs-success', get(response, 'data.activity'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'booking_logs', loading: false});
        });
    },

    async fetchPriorityBookings({commit}) {
        const responseData = await performApiRequest(commit, 'SET_BOOKING_LOADING', 'priority_bookings', () => axios.get('/v1/bookings/priority'));
        commit(types.SET_PRIORITY_BOOKINGS, responseData.bookings);
    },

    /**
     * Fetch fleet resources
     */
    fetchFleetResources({commit}, options) {
        commit('SET_BOOKING_LOADING', {subject: 'fleet_resources', loading: true});

        let qString = '?';
        if (get(options, 'startDate')) {
            qString += `startDate=${get(options, 'startDate')}`;
        }
        if (get(options, 'endDate')) {
            qString += `&endDate=${get(options, 'endDate')}`;
        }

        axios.get(`/v1/fleet-resources${qString}`)
            .then(response => {
                commit('SET_FLEET_RESOURCES', get(response, 'data.bookings'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'fleet_resources', loading: false});
        });
    },

    fetchCustodianFleetResources({commit}, options) {
        commit('SET_BOOKING_LOADING', {subject: 'fleet_resources', loading: true});

        let qString = '?';
        if (get(options, 'startDate')) {
            qString += `startDate=${get(options, 'startDate')}`;
        }
        if (get(options, 'endDate')) {
            qString += `&endDate=${get(options, 'endDate')}`;
        }

        axios.get(`/v1/custodians/${options.company_uuid}/fleet-resources${qString}`)
            .then(response => {
                commit('SET_FLEET_RESOURCES', get(response, 'data.bookings'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'fleet_resources', loading: false});
        });
    },

    /**
     * Fetch bookings
     */
    async fetchBookings({commit}, options: BookingQueryOptions = {}) {
        try {
            const data = prepareBookingData(options);

            const query = createOrderUrlQueryString(data, true);

            const responseData = await performApiRequest(commit, 'SET_BOOKING_LOADING', 'bookings', () => axios.get(`/v1/bookings${query}`));

            if (options?.onlyAbandoned) {
                commit(types.SET_ABANDONED_BOOKINGS, responseData.data);
            } else {
                commit(types.SET_BOOKINGS, responseData.data);
                commit(types.SET_BOOKINGS_META, responseData.meta);
                commit(types.SET_TOTAL_BOOKINGS, responseData.meta.total);
            }
        } catch (error) {
            commit('SET_BOOKING_FORM_ERROR', error.response?.data?.message || 'There was an error');
            console.error(error)
        }
    },

    async fetchCompanyBooking({commit}, b_id: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_BOOKING_LOADING', 'booking', () => axios.get(`/v1/company/${StorageService.getCompanyUuid()}/booking/${b_id}`));

            commit(types.SET_CUSTOMER_BOOKING, responseData?.booking || []);
            EventBus.$emit('booking-fetch-axle-success', responseData?.axle);
            const envelopes = responseData?.booking?.envelope || [];
            let lastEnvelope = {};
            if (envelopes.length > 0) {
                lastEnvelope = envelopes[envelopes.length - 1];
            }
            commit('envelope/SET_ENVELOPE', lastEnvelope, {root: true});
            EventBus.$emit('booking-fetch-success');
        } catch (error) {
            if (error.response.status === 404) {
                commit(types.SET_BOOKING_NOT_FOUND, true);
            }
        }
    },

    /**
     * Create a booking meta
     */
    async createBookingMeta({commit}, data: { booking: BookingInterface, key: string, value: any }) {
        return await performApiRequest(commit, 'SET_BOOKING_LOADING', 'meta', () => axios.post(`/v1/bookings/${data.booking.b_id}/meta`, {
            key: data.key,
            value: data.value,
        }));
    },

    fetchStoredTuroBookings({commit}) {
        axios.get('/v1/turo-bookings')
            .then(response => {
                commit('SET_STORED_TURO_BOOKINGS', get(response, 'data.bookings', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'check_vehicles_availability', loading: false});
        });
    },

    updateListingId({commit}, l_id) {
        commit(types.SET_LISTING_ID, l_id);
    },

    async checkOneListingsBookings({commit}, l_id: string) {
        const responseData = await performApiRequest(commit, 'SET_BOOKING_LOADING', 'check_vehicles_availability', () => axios.get(`/v1/company/${StorageService.getCompanyUuid()}/listings/${l_id}/availability`));
        commit('SET_UNAVAILABLE_DATES', responseData.unavailable);
    },

    /**
     * Update a bookings
     *
     * @param commit
     * @param state
     * @param {String} id
     */
    retrieve: {
        root: true,
        handler({commit, state}, id) {
            axios.post(`/v1/company/${StorageService.getCompanyUuid()}/stripe/retrieve`, {
                id,
            }).then(response => {
                const checkoutSession = get(response, 'data.checkoutSession');
                state.res2 = get(response, 'data.$res2');

                /**
                 * @typedef {Object} checkoutSession.customer
                 * @typedef {Object} checkoutSession.setup_intent
                 */
                commit('SET_REMEMBER_CUSTOMER', {
                    email: checkoutSession.customer.email,
                    stripe_id: checkoutSession.customer.id,
                    last4: checkoutSession.setup_intent.payment_method.card.last4,
                });
            }).catch(() => {
            });
        },
    },

    /**
     * Update a bookings
     */
    async updateBooking({commit, state}, data) {
        try {
            commit('CLEAR_BOOKING_FORM_ERRORS');
            const responseData = await performApiRequest(commit, 'SET_BOOKING_LOADING', 'updateBooking', () => axios.put(`/v1/bookings/${data.b_id}`, data));

            commit('SET_CURRENT_WORKING_BOOKING', responseData.booking);
            commit('renters/SET_RENTER', responseData?.booking?.renter || [], {root: true});
            push.success({
                message: 'Booking updated successfully',
            });
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
            state.bookingForm.errors.set(error?.response?.data?.errors || []);
        }
    },

    /**
     * Update a booking custom field
     *
     * @param commit
     * @param {Object} data
     */
    updateBookingCustomFields({commit}, data) {
        commit('SET_BOOKING_LOADING', {subject: 'updateBookingCustomerFields', loading: true});

        axios.put(`/v1/bookings/${data.b_id}/custom-fields`, {properties: data.properties})
            .then(() => {
                EventBus.$emit('update-custom-field-success');
            })
            .catch(error => {
                push.error({
                    message: error?.response?.data?.message || 'There was an error',
                });
            }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'updateBookingCustomerFields', loading: false});
        });
    },

    /**
     * Extend a bookings
     */
    async extendBooking({commit}, data) {
        const responseData = await performApiRequest(commit, 'SET_BOOKING_LOADING', 'extendBooking', () => axios.post(`/v1/bookings/${data.b_id}/extend`, data));
        commit(types.SET_CURRENT_WORKING_BOOKING, responseData.booking);
        return responseData;
    },

    /**
     * Delete a booking extension
     */
    async deleteExtension({commit}, extension_uuid: string) {
        const responseData = await performApiRequest(commit, 'SET_BOOKING_LOADING', 'deleteExtension', () => axios.delete(`/v1/extensions/${extension_uuid}`));

        commit('SET_CURRENT_WORKING_BOOKING_ATTRIBUTE', {
            booking: responseData.booking,
            attribute: 'extensions'
        });
        EventBus.$emit('extension-deleted-success', responseData.booking);
    },

    attachCommissionPartner({commit}, data) {
        commit('SET_BOOKING_LOADING', {subject: 'attachCommissionPartner', loading: true});
        commit('CLEAR_BOOKING_FORM_ERRORS');

        axios.put(`/v1/bookings/${data.b_id}/attach-commission-partner/${data.partner_uuid}`, data).then(response => {
            commit('SET_CURRENT_WORKING_BOOKING', get(response, 'data.booking'));
            EventBus.$emit('attache-partner-commission-success');
        }).catch(error => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'attachCommissionPartner', loading: false});
        });
    },

    removeCommissionPartnerFromBooking({commit}, data) {
        commit('SET_BOOKING_LOADING', {subject: 'attachCommissionPartner', loading: true});
        commit('CLEAR_BOOKING_FORM_ERRORS');

        axios.delete(`/v1/bookings/${data.b_id}/commission/${data.partner_uuid}`, data)
            .then(response => {
                commit('SET_CURRENT_WORKING_BOOKING', get(response, 'data.booking'));
                EventBus.$emit('attache-partner-commission-success');
            }).catch(error => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'attachCommissionPartner', loading: false});
        });
    },

    cancelBooking({commit, state}, data: CancelBookingPayload) {
        commit('SET_BOOKING_LOADING', {subject: 'cancelBooking', loading: true});
        commit('CLEAR_BOOKING_FORM_ERRORS');

        axios.post(`/v1/bookings/${data.b_id}/cancel`, data)
            .then(response => {
                if (get(response, 'data.success')) {
                    push.success({
                        message: 'Booking canceled successfully',
                    });
                    if (get(response, 'data.booking')) {
                        commit('SET_CURRENT_WORKING_BOOKING', get(response, 'data.booking'));
                    }
                } else {
                    state.bookingForm.errors.set(['There was an error']);
                }
            }).catch(error => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'cancelBooking', loading: false});
        });
    },


    requestCancelBooking({commit}, data) {
        commit('SET_BOOKING_LOADING', {subject: 'cancelBooking', loading: true});

        axios.post('/v1/request-cancellation', data)
            .then(response => {
                EventBus.$emit('booking-guest-canceled-success', get(response, 'data.booking'));
            }).catch(error => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'cancelBooking', loading: false});
        });
    },

    addOrderItem({commit}, line) {
        commit('SET_BOOKING_LOADING', {subject: 'addOrderItem', loading: true});
        commit('CLEAR_BOOKING_FORM_ERRORS');

        axios.post(`/v1/company/${StorageService.getCompanyUuid()}/bookings/${line.b_id}/lines/`, {line}).then(response => {
            if (get(response, 'data.success')) {
                commit('MERGE_BOOKING_LINE_ITEM', get(response, 'data.line'), {});
                EventBus.$emit('add-order-item-success');
            }
        }).catch(error => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'addOrderItem', loading: false});
        });
    },
    editOrderItem({commit}, line: OrderLine) {
        commit('SET_BOOKING_LOADING', {subject: 'editOrderItems', loading: true});

        axios.put(`/v1/company/${StorageService.getCompanyUuid()}/lines/${line.uuid}`, line).then(response => {
            if (get(response, 'data.success')) {
                commit('UPDATE_BOOKING_LINE_ITEM', get(response, 'data.line'), {});
            }
        }).catch(error => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'editOrderItems', loading: false});
        });
    },
    deleteOrderLineItem({commit}, line: string) {
        commit('SET_BOOKING_LOADING', {subject: 'bookingPayments', loading: true});
        commit('CLEAR_BOOKING_FORM_ERRORS');

        axios.delete(`/v1/company/${StorageService.getCompanyUuid()}/lines/${line}`).then(response => {
            if (get(response, 'data.success')) {
                EventBus.$emit('delete-order-item-success');
                commit('REMOVE_BOOKING_LINE_ITEM', line);
            }
        }).catch(error => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'bookingPayments', loading: false});
        });
    },

    fetchBookingPayments({commit}, b_id: string) {
        commit('SET_BOOKING_LOADING', {subject: 'bookingPayments', loading: true});
        commit('CLEAR_BOOKING_FORM_ERRORS');

        axios.get('/v1/payments', {params: {b_id}})
            .then(response => {
                commit('SET_BOOKING_PAYMENTS', get(response, 'data.payments'), []);
            }).catch(error => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'bookingPayments', loading: false});
        });
    },

    changeBookingStatus({commit}, data) {
        commit('SET_BOOKING_LOADING', {subject: 'bookingStatus', loading: true});
        commit('CLEAR_BOOKING_FORM_ERRORS');

        axios.put(`/v1/bookings/${data.b_id}/status`, data)
            .then(response => {
                commit('SET_CURRENT_WORKING_BOOKING_STATUS', get(response, 'data.booking.status'));
                EventBus.$emit('change-booking-status-success', get(response, 'data.booking'));
                push.success({
                    message: 'Booking status updated successfully',
                });
            }).catch(error => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'bookingStatus', loading: false});
        });
    },

    /**
     * Update a bookings' mile allowance
     *
     * @param commit
     * @param state
     * @param {Object} data
     */
    updateBookingMileAllowance({commit, state}, data) {
        commit('SET_BOOKING_LOADING', {subject: 'updateBooking', loading: true});
        commit('CLEAR_BOOKING_FORM_ERRORS');

        axios.put(`/v1/bookings/${data.b_id}/mile-allowance`, data).then(response => {
            commit('SET_CURRENT_WORKING_BOOKING', get(response, 'data.booking'), []);
            EventBus.$emit('update-booking-mile-allowance-success', get(response, 'data.booking'));
            push.success({
                message: 'Booking distance allowance updated successfully',
            });
        }).catch(error => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
            state.bookingForm.errors.set(error?.response?.data?.errors || []);
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'updateBooking', loading: false});
        });
    },

    /**
     * Delete a trip photo from a reservation
     *
     * @param commit
     * @param {String} uuid
     */
    deleteTripPhoto({commit}, uuid: string) {
        commit('SET_BOOKING_LOADING', {subject: 'updateBooking', loading: true});

        axios.delete(`/v1/trip-photo/${uuid}`)
            .then(() => {
                EventBus.$emit('delete-trip-photo-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'updateBooking', loading: false});
        });
    },

    deleteTripPhotos({commit}, uuids: string[]) {
        commit('SET_BOOKING_LOADING', {subject: 'deleteTripPhotos', loading: true});

        axios.post('/v1/trip-photos-delete', uuids)
            .then(() => {
                EventBus.$emit('delete-trip-photos-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'deleteTripPhotos', loading: false});
        });
    },

    /**
     * Update a trip photo from a reservation
     *
     * @param commit
     * @param {Object} file - The File
     * @param {string} file.uuid - The UUID of the file
     */
    updateTripPhoto({commit}, file) {
        commit('SET_BOOKING_LOADING', {subject: 'updateTripPhoto', loading: true});

        axios.put(`/v1/trip-photo/${file.uuid}`, file)
            .then(response => {
                EventBus.$emit('update-trip-photo-success', get(response, 'data.photo'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'updateTripPhoto', loading: false});
        });
    },

    async startBookingVehicleSwap({commit}, data: BookingVehicleSwapData) {
        commit('CLEAR_BOOKING_FORM_ERRORS');

        try {
            const responseData = await performApiRequest(commit, 'SET_BOOKING_LOADING', 'startBookingVehicleSwap', () => axios.post(`/v1/bookings/${data.b_id}/vehicle-swap`, data));
            EventBus.$emit('vehicle-swap-success-step-1');
            commit('SET_CURRENT_WORKING_BOOKING', responseData.booking);
        } catch (error) {
            commit('SET_BOOKING_FORM_ERROR', error.response?.data?.message || 'There was an error');
            console.error(error)
        }
    },

    startBookingVehicleSwapStep2({commit, state}, data) {
        commit('SET_BOOKING_LOADING', {subject: 'startBookingVehicleSwapStep2', loading: true});
        commit('CLEAR_BOOKING_FORM_ERRORS');

        axios.post(`/v1/bookings/${data.booking.b_id}/vehicle-swap-step-2`, data).then(response => {
            EventBus.$emit('vehicle-swap-success-step-2');
            commit(types.SET_CURRENT_WORKING_BOOKING, response?.data?.booking);
        }).catch(e => {
            state.bookingForm.errors.set(get(e, 'response.data.errors', []));
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'startBookingVehicleSwapStep2', loading: false});
        });
    },

    resendConfirmationEmail({commit}, b_id: string) {
        commit('SET_BOOKING_LOADING', {subject: 'updateTripPhoto', loading: true});

        axios.post(`/v1/bookings/${b_id}/resend-confirmation-email`)
            .then(() => {
                push.success({
                    message: 'Booking confirmation email resent.',
                });
            }).catch(e => {
            let message = get(e, 'response.data.message', 'There was an error');

            if (get(e, 'response.headers.retry-after')) {
                message = `${message}. Retry in ${get(e, 'response.headers.retry-after')} seconds`;
            }

            push.error({
                message: message,
            });
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'updateTripPhoto', loading: false});
        });
    },

    /**
     * Toggle a Turo connections active state.
     *
     * @param commit
     * @param {String} b_id
     */
    fetchBookingAutomationEvents({commit}, b_id: string) {
        commit('SET_BOOKING_LOADING', {subject: 'getAutomationEvents', loading: true});

        axios.get(`/v1/bookings/${b_id}/automation-events`)
            .then(response => {
                commit('SET_BOOKING_AUTOMATION_EVENTS', get(response, 'data.events', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'getAutomationEvents', loading: false});
        });
    },

    /**
     * Mark a booking as manually removed
     *
     * @param commit
     * @param {String} b_id
     */
    removeProviderBookingFromFleetwire({commit}, b_id: string) {
        commit('SET_BOOKING_LOADING', {subject: 'manuallyRemoveBooking', loading: true});

        axios.delete(`/v1/bookings/${b_id}/manual-delete`)
            .then(() => {
                EventBus.$emit('mark-booking-as-removed-manually-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_BOOKING_LOADING', {subject: 'manuallyRemoveBooking', loading: false});
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
