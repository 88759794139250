<template>
  <div>
    <v-card :disabled="branchLoading.updateBranch || branchLoading.fetchBranch" :loading="branchLoading.fetchBranch">
      <v-card-title>
        Action Item: {{ actionItem?.title }}
      </v-card-title>
      <v-card-text class="mt-3">
        <v-form>
          <div class="grid grid-cols-2">
            <div class="mb-5">
              <v-text-field
                v-model="currentWorkingActionItemClone.title"
                :error-messages="fleetForm.errors.get('title')"
                label="Name"
                variant="outlined"
                @keydown="$store.commit('fleet/CLEAR_FLEET_FORM_ERRORS', 'title')"
              />
            </div>
          </div>

          <div class="grid grid-cols-2">
            <div class="mb-5">
              <v-select
                v-model="currentWorkingActionItemClone.when"
                :items="actionItemEras"
                item-title="name"
                item-value="value"
                label="Era"
                variant="outlined"
              />
            </div>
          </div>

          <div class="grid grid-cols-2">
            <div class="mb-5">
              <v-autocomplete
                v-model="currentWorkingActionItemClone.vehicle_classes"
                :items="vehicleClasses"
                item-title="name"
                item-value="uuid"
                label="Vehicle Classes"
                multiple
                return-object
                variant="outlined"
              />
            </div>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <DeleteButton @deleteConfirmed="deleteActionItem(currentWorkingActionItemClone)"/>
        <v-spacer/>
        <v-btn size="small" variant="text" @click="$router.push({ name: 'settings.action_items' }).catch(() => {});">
          Back
        </v-btn>
        <v-btn
          :disabled="branchLoading.updateBranch || currentWorkingActionItemClone.title === '' || currentWorkingActionItemClone.when === ''"
          :loading="branchLoading.updateBranch"
          @click="updateActionItemInit()"
        >Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>


<script lang="ts">
import {defineAsyncComponent} from 'vue';
import {mapActions, mapGetters} from 'vuex';

const DeleteButton = defineAsyncComponent(() => import('@/components/buttons/DeleteButton.vue'));

const defaultActionItem = {
  uuid: '',
  title: '',
  when: 'pre-trip',
  order: 0,
  vehicle_classes: [],
};

export default {
  name: 'ActionItemSingle.vue',

  components: {
    DeleteButton,
  },

  data() {
    return {
      actionItemEras: [
        {name: 'Pre-Trip', value: 'pre-trip'},
        {name: 'Post-Trip', value: 'post-trip'},
      ],
      currentWorkingActionItemClone: {...defaultActionItem},
    };
  },

  created() {
    this.getActionItem(this.$route.params.uuid);
    this.getVehicleClasses();
  },

  watch: {
    actionItem(item) {
      this.currentWorkingActionItemClone = JSON.parse(JSON.stringify(item));
    },
    actionItemDeleted() {
      this.$router.push({name: 'settings.action_items'}).catch(() => {
      });
    },
  },

  computed: {
    ...mapGetters({
      actionItem: 'fleet/actionItem',
      branchLoading: 'fleet/loading',
      vehicleClasses: 'vehicles/vehicleClasses',
      actionItemDeleted: 'fleet/actionItemDeleted',
      fleetForm: 'fleet/fleetForm',
    }),
  },

  methods: {
    ...mapActions({
      getActionItem: 'fleet/getActionItem',
      deleteActionItem: 'fleet/deleteActionItem',
      updateActionItem: 'fleet/updateActionItem',
      getVehicleClasses: 'vehicles/getVehicleClasses',
    }),
    updateActionItemInit() {
      this.updateActionItem({
        uuid: this.$route.params.uuid,
        title: this.currentWorkingActionItemClone.title,
        when: this.currentWorkingActionItemClone.when,
        order: this.currentWorkingActionItemClone.order,
        vehicle_classes: this.currentWorkingActionItemClone.vehicle_classes,
      });
    },
  },

  beforeUnmount() {
    this.currentWorkingActionItemClone = {...defaultActionItem};
  },
};
</script>

