<template>
  <div>
    <v-tabs v-model="documentsTab">
      <v-tab>Contracts</v-tab>
      <v-tab>Invoices</v-tab>
      <v-tab v-if="userHasRole('beta-tester-1')">Quotes</v-tab>
    </v-tabs>

    <v-window v-model="documentsTab">
      <v-window-item>
        <div class="mx-5 mt-5">
          <v-expansion-panels style="border-top: none;">
            <v-expansion-panel>
              <v-expansion-panel-title>
                <template v-slot:default="{ expanded }">
                  <div class="grid grid-cols-4 gap-2">
                    <div class="col-4">
                      Templates
                    </div>
                    <div class="col-8">
                      <v-fade-transition hide-on-leave>
                        <div v-if="!expanded">
                          ({{ validTemplates.length }} {{ $t('templates') }})
                        </div>
                      </v-fade-transition>
                    </div>
                  </div>
                </template>

              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-card :loading="documentsLoading.get" variant="flat">
                  <the-help-link url="https://help.fleetwire.io/en/article/what-is-automatic-e-sign-1o5wfxk/"/>
                  <v-alert v-if="!includedInSubscription('esign')" border="bottom" class="card-alert-full" density="compact" type="warning">
                    You plan does not include eSign rental agreements.
                    <v-btn :to="{name: 'settings.account'}" class="float-right" size="x-small">Upgrade</v-btn>
                  </v-alert>

                  <v-card-title>
                    <div class="flex">
                      Document Templates
                      <v-btn size="small" variant="text" @click="learnMoreDialog = true;">
                        <v-icon size="small">mdi-help-circle</v-icon>
                      </v-btn>
                      <v-spacer/>
                      <v-btn :disabled="!planIncludesESign" class="mt-2" size="small" @click="createTemplateInit()">
                        <v-icon class="mr-1" size="small">mdi-plus</v-icon>
                        {{ $t('template') }}
                      </v-btn>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <TemplatesTableList :templates="templates"/>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>


        <ContractsTable/>
      </v-window-item>
      <v-window-item>
        <v-card>
          <div class="grid grid-cols-3 gap-2 mx-4 my-10">
            <div>
              <v-card>
                <v-card-title>
                  Number of Invoices
                </v-card-title>
                <v-card-text>
                  {{ orderDocumentsInvoicesFiltered.length }}
                </v-card-text>
              </v-card>
            </div>
            <div>
              <v-card>
                <v-card-title>
                  Paid Amount
                </v-card-title>
                <v-card-text>
                  {{ formatMoneyDisplayV2(invoicePaidAmount) }}
                </v-card-text>
              </v-card>
            </div>
            <div>
              <v-card>
                <v-card-title>
                  Turnover
                </v-card-title>
                <v-card-text>
                  {{ formatMoneyDisplayV2(invoiceTotalAmount) }}
                </v-card-text>
              </v-card>
            </div>
          </div>
          <div v-if="orderDocumentsInvoicesFiltered.length === 0" class="text-center border rounded p-4">
            No Invoices found
          </div>
          <v-data-table
            v-else
            :headers="orderDocumentsHeaders"
            :items="orderDocumentsInvoicesFiltered"
            :items-per-page="50"
            :mobile-breakpoint="0"
          >
            <template v-slot:item.title="{ item }">
              <div class="h-100 content-center">
                <router-link :to="{name: 'documents.show', params: { uuid: item.uuid}}">
                  {{ item.title }}
                </router-link>
              </div>
            </template>
            <template v-slot:item.order="{ item }">
              <div class="h-100 content-center">
                <router-link v-if="item?.order?.b_id " :to="{name: 'orders.single', params: { b_id: item?.order?.b_id }}">
                  {{ item?.order?.b_id }}
                </router-link>
              </div>
            </template>
            <template v-slot:item.customer="{ value }">
              <div class="h-100 content-center">
                <router-link v-if="value?.r_id" :to="{name: 'customers.single', params: { r_id: value.r_id }}">
                  {{ value.r_id }}
                </router-link>
              </div>
            </template>
            <template v-slot:item.paid_in_cents="{ item }">
              <div class="h-100 content-center">
                {{ formatMoneyDisplayV2(item.paid_in_cents) }}
              </div>
            </template>
            <template v-slot:item.grand_total_with_tax_in_cents="{ item }">
              <div class="h-100 content-center">
                {{ formatMoneyDisplayV2(item.grand_total_with_tax_in_cents) }}
              </div>
            </template>
            <template v-slot:item.status="{ value }">
              <div class="h-100 content-center">
                {{ value }}
              </div>
            </template>
            <template v-slot:item.date="{ value }">
              <div class="h-100 content-center">
                {{ value }}
              </div>
            </template>
            <template v-slot:item.confirmed="{ value }">
              <div class="h-100 content-center">
                {{ value }}
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-window-item>
      <v-window-item>
        <v-card>
          <div v-if="orderDocuments.length === 0" class="text-center border rounded p-4">
            No contracts found
          </div>
          <v-data-table
            v-else
            :headers="orderDocumentsHeaders"
            :items="orderDocumentsQuotesFiltered"
            :items-per-page="50"
            :mobile-breakpoint="0"
          >
            <template v-slot:item.title="{ item }">
              <router-link :to="{name: 'documents.show', params: { uuid: item.uuid}}">
                {{ item.title }}
              </router-link>
            </template>
            <template v-slot:item.customer="{ item }">
              <router-link :to="{name: 'customers.single', params: { r_id: renterId(item.customer)}}">
                {{ renterName(item.customer) }}
              </router-link>
            </template>
            <template v-slot:item.grand_total_in_cents="{ item }">
              ${{ formatMoney(item.grand_total_in_cents) }}
            </template>
            <template v-slot:item.grand_total_with_tax_in_cents="{ item }">
              ${{ formatMoney(item.grand_total_with_tax_in_cents) }}
            </template>
          </v-data-table>
        </v-card>
      </v-window-item>
    </v-window>

    <v-dialog v-model="learnMoreDialog" max-width="600">
      <v-card>
        <v-card-title class="text-h5">What are document templates?</v-card-title>
        <v-card-text>
          <div>
            Here you can upload a document and insert variable placeholders that will automatically fill in once a
            customer makes a reservation. These are called document templates. You can set up placeholders such as name,
            email, booking dates, vehicle info, signatures, attachments, etc. Once customers book a vehicle, Fleetwire
            can email them a link to their e-sign envelope. We will email you a complete copy for your records upon
            completion.
          </div>
        </v-card-text>
        <v-card-actions>
          <TheHelpLink url="https://help.fleetwire.io/en/article/esign-1o5wfxk/?bust=1643742268392"/>
          <v-spacer/>
          <v-btn @click="learnMoreDialog = false;">
            {{ $t('okay') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script lang="ts">
import {defineAsyncComponent, ref, watch} from 'vue';
import {mapActions, mapGetters, useStore} from 'vuex';
import {EventBus} from '@/plugins/eventBus';
import {Form} from '@/plugins/FWForms/index';
import subscriptionMixin from '@/mixins/subscription';
import {useDateFormatter} from '@/composables/useDate';
import {useMoneyFormatter} from '@/composables/useMoneyFormatter';
import {useAppSettings} from '@/composables/useAppSettings';
import {useEnvelope} from '@/composables/useEnvelope';
import ContractsTable from '@/views/documents/ContractsTable.vue';
import {RenterInterface} from '@/types';
import {useSubscription} from '@/composables/useSubscription';
import {useTemplate} from '@/composables/useTemplate';

const TemplatesTableList = defineAsyncComponent(() => import('@/components/dashboard/documents/TemplatesTableList.vue'));


export default {
  name: 'TheDocumentsDashboardHome',

  setup() {
    const store = useStore();
    const {formatMoneyDisplayV2, formatMoney} = useMoneyFormatter();
    const {formatRentalDate} = useDateFormatter();
    const {sendEnvelopeReminder, voidEnvelope, getEnvelopes} = useEnvelope();
    const {includedInSubscription} = useSubscription();
    const {fetchTemplates} = useTemplate();

    const {userHasRole} = useAppSettings();


    const documentsTab = ref(0);
    const learnMoreDialog = ref(false);
    const envelopInfoDialog = ref(false);
    const voidEnvelopeDialog = ref(false);
    const dataTableOptionStatus = ref('all');


    const orderDocumentsHeaders = [
      {title: 'Title', sortable: false, key: 'title'},
      {title: 'Booking', key: 'order'},
      {title: 'Customer', key: 'customer'},
      {title: 'Status', key: 'status'},
      {title: 'Date', key: 'date'},
      {title: 'Total w/tax', key: 'grand_total_with_tax_in_cents'},
      {title: 'Paid', key: 'paid_in_cents'},
      {title: 'Confirmed', key: 'confirmed'},
    ];


    const reminderData = ref({
      recipients: [],
    });

    watch(documentsTab, (val) => {
      console.dir(val);
      if (val === 1) {
        store.dispatch('order/getAllOrderDocuments');
      }
    });


    const envelopesFilter = ref({
      complete: 'all',
    });

    const nameChangeErrors = ref([]);


    const reminderDialog = ref(false);

    const currentWorkingEnvelope = ref({});

    const form = ref(new Form());


    const envelopeVoided = (envelope) => {
      return envelope.status === 'voided';
    };

    return {
      learnMoreDialog,
      envelopInfoDialog,
      voidEnvelopeDialog,
      orderDocumentsHeaders,
      dataTableOptionStatus,
      documentsTab,
      envelopesFilter,
      nameChangeErrors,
      reminderData,
      reminderDialog,
      currentWorkingEnvelope,
      form,
      fetchTemplates,
      includedInSubscription,
      envelopeVoided,
      userHasRole,
      sendEnvelopeReminder,
      voidEnvelope,
      getEnvelopes,
      formatMoney,
      formatMoneyDisplayV2,
      formatRentalDate,
    };
  },

  components: {
    ContractsTable,
    TemplatesTableList,
  },

  mixins: [
    subscriptionMixin,
  ],

  watch: {
    $route() {
      if (this.device === 'mobile' && this.secondaryNavViews.listingImportNav) {
        this.toggleSecondaryImportNav();
      }
    },
  },

  created() {
    if (this.planIncludesESign) {
      this.fetchTemplates();
    }
    EventBus.$on('create-template-success', (template) => {
      if (template.uuid) {
        this.$router.push({name: 'documents.prepare', params: {uuid: template.uuid}}).catch(err => {
        });
      }
    });
    EventBus.$on('envelope-reminder-success', () => {
      this.reminderDialog = false;
      this.reminderData = {
        recipients: [],
      };
    });
  },

  computed: {
    ...mapGetters({
      device: 'app/device',
      secondaryNavViews: 'app/secondaryNavViews',
      templates: 'document/templates',
      documents: 'document/documents',
      documentsLoading: 'document/loading',
      envelopes: 'envelope/envelopes',
      orderDocuments: 'order/documents',
    }),
    validTemplates() {
      // Return templates that have documents (truthy value)
      return this.templates.filter(template => template.documents);
    },
    templatesWithoutDocuments() {
      // Return templates where documents exist (including null)
      return this.templates.filter(template => template.documents === null);
    },
    orderDocumentsQuotesFiltered() {
      return this.orderDocuments.filter((d) => d.type === 'Document::Quote');
    },
    orderDocumentsInvoicesFiltered() {
      return this.orderDocuments.filter((d) => d.type === 'Document::Invoice');
    },
    invoicePaidAmount() {
      // Filtering paid and overpaid invoices
      const paidInvoices = this.orderDocumentsInvoicesFiltered.filter(invoice => {
        const invoiceStatus = invoice.status?.toLowerCase();
        return invoiceStatus === 'paid' || invoiceStatus === 'overpaid';
      });

      // Summing the paid amount in cents from the filtered invoices
      return paidInvoices.reduce((total, invoice) => total + (invoice.paid_in_cents ?? 0), 0);
    },
    invoiceTotalAmount() {
      // Summing the grand total with tax in cents from the filtered invoices
      return this.orderDocumentsInvoicesFiltered.reduce((total, invoice) => total + (invoice.grand_total_with_tax_in_cents ?? 0), 0);
    }
  },

  methods: {
    ...mapActions({
      toggleSecondaryImportNav: 'app/toggleSecondaryImportNav',
      createTemplate: 'document/createTemplate',
    }),
    bookingId(item) {
      return item?.order?.b_id;
    },
    renterFullName(renter: RenterInterface) {
      const name = renter?.full_name;
      if (name) {
        return name;
      }
      return `${renter?.first_name} ${renter?.last_name}`.trim();
    },
    recipients(envelope) {
      // Check if there are no recipients in the envelope
      if (!envelope.recipients?.length) {
        return [{
          name: this.renterFullName(envelope.renter),
          r_id: envelope.renter?.r_id,
        }];
      }

      // Process each recipient to extract their details
      return envelope.recipients.map(recipient => ({
        name: this.renterFullName(recipient.renter),
        r_id: recipient.renter?.r_id,
      }));
    },

    renterName(item: RenterInterface) {
      return item.full_name;
    },
    renterId(renter: RenterInterface) {
      return renter.r_id;
    },
    createTemplateInit() {
      const templatesPending = this.templatesWithoutDocuments;

      if (templatesPending.length === 0) {
        this.createTemplate();
        return;
      }

      const firstTemplate = templatesPending[0];

      this.$router.push({name: 'documents.prepare', params: {uuid: firstTemplate.uuid}}).catch({});
    },
  },

  beforeUnmount() {
    this.$store.commit('envelope/SET_ALL_ENVELOPES', {
      data: [],
    });
    this.$store.commit('order/SET_ORDER_DOCUMENTS', []);
  },
};
</script>

<style>
.denser .v-btn:not(.v-btn--round).v-size--default {
  height: 21px !important;
}

.denser .v-btn.v-size--default {
  font-size: 10px !important;
}

.denser .v-btn-toggle .v-btn.v-btn.v-size--default {
  min-width: 40px !important;
}
</style>
