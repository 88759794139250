import {computed, ref} from 'vue';
import {Coupon} from '@/types';

export function useCouponForm() {
    const couponClone = ref<Coupon>({
        uuid: '',
        code: '',
        type: 'percent',
        amount: 20,
        limit: -1,
        count: 10,
        reason: '',
        notes: '',
        start_at: '',
        end_at: '',
        schedule: true,
        active: true,
        listings: [],
        user_limit: -1
    });

    const currentCouponListings = ref(true);
    const currentCouponUsersOn = ref(false);
    const currentCouponUsers = ref(1);
    const couponLimit = ref(false);
    const couponFormValid = ref(false);

    const currentCouponAmount = computed({
        get() {
            const amount = couponClone.value.amount;
            return couponClone.value.type === 'amount' ? amount * 0.01 : amount;
        },
        set(value) {
            couponClone.value.amount = couponClone.value.type === 'amount' ? value * 100 : value;
        }
    });

    const couponRules = computed(() => ({
        reason: couponClone.value.reason ? [
            couponClone.value.reason.length <= 40 || 'Max 40 characters'
        ] : [],
        code: [
            couponClone.value.code.length > 0 || 'Coupon code is required',
            couponClone.value.code.length <= 200 || 'Max 200 characters'
        ],
        amount: couponClone.value.type === 'percent'
            ? [(couponClone.value.amount > 0 && couponClone.value.amount <= 100) || 'A percent based discount must be between 0 and 100 percent.']
            : [(couponClone.value.amount > 0 && couponClone.value.amount < 1000000000) || 'An amount based discount must be between 0 and 10,000,000.']
    }));

    const resetForm = () => {
        couponClone.value = {
            uuid: '',
            code: '',
            type: 'percent',
            amount: 20,
            limit: -1,
            count: 10,
            reason: '',
            notes: '',
            start_at: '',
            end_at: '',
            schedule: true,
            active: true,
            listings: [],
            user_limit: -1
        };
        currentCouponListings.value = true;
        currentCouponUsersOn.value = false;
        currentCouponUsers.value = 1;
        couponLimit.value = false;
        couponFormValid.value = false;
    };

    const updateListings = (listings: any[]) => {
        if (currentCouponListings.value) {
            couponClone.value.listings = [];
        } else {
            couponClone.value.listings = listings;
        }
    };

    const updateUserLimit = () => {
        if (currentCouponUsersOn.value) {
            couponClone.value.user_limit = +currentCouponUsers.value === -1 ? 1 : +currentCouponUsers.value;
            currentCouponUsers.value = couponClone.value.user_limit;
        } else {
            couponClone.value.user_limit = -1;
        }
    };

    return {
        couponClone,
        currentCouponListings,
        currentCouponUsersOn,
        currentCouponUsers,
        couponLimit,
        couponFormValid,
        currentCouponAmount,
        couponRules,
        resetForm,
        updateListings,
        updateUserLimit
    };
} 