<template>
  <div>
    <v-card variant="outlined">
      <v-card-title>
        Price Rules
        <TheHelpLink url="https://help.fleetwire.io/en/article/advanced-pricing-cxlndp/?bust=1666982473968"></TheHelpLink>
        <v-spacer/>
        <v-btn size="small" @click="EventBus.$emit('open-pricing-rule-dialog');">
          <v-icon class="mr-1" size="small">mdi-plus</v-icon>
          Rule
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        Price rules are used to automatically calculate the price of certain days based on your rules.
      </v-card-subtitle>
      <v-card-text class="mt-3">
        <v-data-table
          :headers="headers"
          :items="pricingRules"
          hide-default-footer
        >
          <template v-slot:item.id="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.active="{ item }">
            <span v-if="item.active">Active</span>
          </template>
          <template v-slot:item.name="{ item }">
            <router-link :to="{name: 'settings.pricing.advanced.edit', params: {'uuid': item.uuid }}">
              {{ item.name }}
            </router-link>
          </template>
          <template v-slot:item.uuid="{ item }">
            <span v-tippy="item.uuid" v-text="firstPartOfUuid(item.uuid)"></span>
          </template>
          <template v-slot:item.rule_type="{ item }">
            <span>{{ ruleType(item) }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="flex gap-1 float-end">
              <v-btn size="small" variant="text" @click="deletePricingRule(item)">
                <v-icon size="small">mdi-delete</v-icon>
              </v-btn>
              <v-btn size="small" variant="text" @click="$router.push({ name: 'settings.pricing.advanced.edit', params: {'uuid': item.uuid } }).catch(() => {})">
                <v-icon size="small">mdi-pencil</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <PricingRuleDialog/>

    <v-alert class="mt-5" density="compact" type="info" variant="outlined">
      If you are seeing this page that means you have a beta tester flag.
    </v-alert>

  </div>
</template>

<script lang="ts">
import {EventBus} from '@/plugins/eventBus';
import {mapActions, mapGetters} from 'vuex';
import {get} from 'lodash';
import PricingRuleDialog from './Dialogs/PricingRuleDialog.vue';

export default {
  name: 'AdvancedPricing',

  components: {
    PricingRuleDialog,
  },

  data() {
    return {
      headers: [
        {text: '#', value: 'id', width: 60},
        {text: 'Listing Name', value: 'name'},
        {text: 'Status', value: 'active'},
        {text: 'ID', value: 'uuid'},
        {text: 'Type', value: 'rule_type'},
        {text: '', value: 'actions'},
      ],
      EventBus,
    };
  },

  created() {
    this.getPricingRules();

    EventBus.$on('create-pricing-rules-success', () => {
      this.getPricingRules();
    });
    EventBus.$on('delete-pricing-rules-success', () => {
      this.getPricingRules();
    });
  },

  computed: {
    ...mapGetters({
      pricingRules: 'pricing/pricingRules',
    }),
  },

  methods: {
    ...mapActions({
      getPricingRules: 'pricing/getPricingRules',
      deletePricingRules: 'pricing/deletePricingRules',
    }),
    firstPartOfUuid(uuid) {
      return uuid.split('-')[0];
    },
    ruleType(rule) {
      const type = get(rule, 'rule_type', 'N/A');
      switch (type) {
        case 'fill-the-gap':
          return 'Fill the gap';
        case 'raise-lower-prices':
          return 'Raise/Lower prices';
        default:
          return 'Unknown';
      }
    },
    deletePricingRule(rule) {
      this.deletePricingRules([rule.uuid])
        .finally(() => {
          this.getPricingRules();
        });
    },
  },

  beforeUnmount() {
    EventBus.$off('create-pricing-rules-success');
    EventBus.$off('delete-pricing-rules-success');
  },
};
</script>
