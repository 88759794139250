import type {RouteRecordRaw} from 'vue-router';
import {CommonRouteMeta} from '@/types';

// Lazy-load the BaseDashboard component
const loadBaseDashboard = () => import('@/views/dashboard/BaseDashboard.vue');
const loadBasePerks = () => import('@/views/dashboard/perks/BasePerks.vue');


// Define perks routes
const perksRoutes: RouteRecordRaw[] = [
    {
        path: '/perks',
        component: loadBaseDashboard,
        meta: {
            title: 'Perks',
            icon: 'mdi-cash-multiple',
            noCache: false,
            isBase: true,
            middleware: ['auth'],
            layout: 'Dashboard',
        } as CommonRouteMeta,
        children: [
            {
                path: '',
                component: loadBasePerks,
                name: 'perks.index',
                meta: {
                    title: 'Perks',
                    icon: 'mdi-car-convertible',
                    noCache: false,
                    isBase: true,
                } as CommonRouteMeta,
            },
        ],
    }
];

export default perksRoutes;
