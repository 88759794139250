<template>
  <div class="space-y-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium text-gray-900 dark:text-gray-100">Global Price Adjustment</h3>
          <p class="mt-1 text-sm text-gray-600 dark:text-gray-400">
            Adjust all your listing prices at once. You can add a fixed amount or percentage to every listing's base
            price.
          </p>
        </div>
      </div>

      <div class="mt-4 md:mt-0 md:col-span-2">
        <v-card class="mb-5">
          <v-card-text>
            <div class="space-y-4 ">
              <div class="flex flex-col sm:flex-row items-start gap-4">
                <v-btn-toggle
                  v-model="settings['global_listing_price_adjustment_mode']"
                  class="mt-1"
                  group
                  mandatory
                  @update:model-value="val => updateDefaultMeta('global_listing_price_adjustment_mode', val)"
                >
                  <v-btn v-tippy content="Fixed amount" size="small" value="amount">
                    <v-icon>mdi-cash</v-icon>
                  </v-btn>
                  <v-btn v-tippy content="Percentage" size="small" value="percent">
                    <v-icon>mdi-percent</v-icon>
                  </v-btn>
                </v-btn-toggle>

                <v-text-field
                  v-model="displayPriceAdjustmentValue"
                  :prefix="settings['global_listing_price_adjustment_mode'] === 'amount' ? companyMoneyTextInputSettings.prefix : ''"
                  :rules="adjustmentRules"
                  :suffix="settings['global_listing_price_adjustment_mode'] === 'amount' ? companyMoneyTextInputSettings.suffix : '%'"
                  class="w-full sm:w-auto"
                  label="Adjustment value"
                  type="number"
                  variant="outlined"
                  @blur="handlePriceAdjustmentUpdate"
                  @keyup.enter="handlePriceAdjustmentUpdate; $event.target.blur()"
                />
              </div>
              <div class="text-sm text-gray-600 dark:text-gray-400">
                <p v-if="settings['global_listing_price_adjustment_mode'] === 'amount'">
                  This will add a fixed amount to every listing's base price.
                </p>
                <p v-else>
                  This will adjust every listing's price by this percentage.
                </p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref, watch} from 'vue';
import {useMoneyFormatter} from '@/composables/useMoneyFormatter';
import {useDefaultMeta} from '@/composables/useDefaultMeta';
import {useAuth} from '@/composables/useAuth';

const {companyMoneyTextInputSettings} = useMoneyFormatter();
const {fetchSession} = useAuth();

const {state: settings, updateDefaultMeta} = useDefaultMeta({
  'global_listing_price_adjustment_value': {type: 'number'},
  'global_listing_price_adjustment_mode': {type: 'string'},
});

const displayPriceAdjustmentValue = ref(0);

// Validation
const validators = {
  amount: (val: number) => {
    if (isNaN(val)) return 'Amount must be a number';
    if (val < 0) return 'Amount cannot be negative';
    if (val > 100000) return 'Amount is too high';
    return true;
  },
  percent: (val: number) => {
    if (isNaN(val)) return 'Percent must be a number';
    if (val < 0 || val > 100) return 'Percent must be between 0 and 100';
    return true;
  }
};

const adjustmentRules = computed(() => [
  settings['global_listing_price_adjustment_mode'] === 'percent'
    ? validators.percent
    : validators.amount
]);


// Handlers
const handlePriceAdjustmentUpdate = () => {
  const mode = settings['global_listing_price_adjustment_mode'];
  const value = displayPriceAdjustmentValue.value;
  let storedValue = mode === 'amount' ? value * 100 : value;

  if (storedValue < 0) {
    displayPriceAdjustmentValue.value = 0;
    return;
  }

  if (mode === 'percent' && storedValue > 100) {
    storedValue = 100;
    displayPriceAdjustmentValue.value = 100;
  }

  updateDefaultMeta('global_listing_price_adjustment_value', storedValue).then(() => {
    fetchSession();
  });
};

// Watchers
watch(
  () => settings['global_listing_price_adjustment_value'],
  (value) => {
    const raw = +value || 0;
    displayPriceAdjustmentValue.value =
      settings['global_listing_price_adjustment_mode'] === 'amount'
        ? raw * 0.01
        : raw;
  },
  {immediate: true}
);

watch(
  () => settings['global_listing_price_adjustment_mode'],
  (newMode, oldMode) => {
    const currentValue = +settings['global_listing_price_adjustment_value'] || 0;

    if (newMode === 'amount' && oldMode === 'percent') {
      const newAmountInCents = currentValue * 100;
      updateDefaultMeta('global_listing_price_adjustment_value', newAmountInCents);
      displayPriceAdjustmentValue.value = newAmountInCents * 0.01;
    } else if (newMode === 'percent' && oldMode === 'amount') {
      const newPercent = currentValue * 0.01;
      if (newPercent > 100) {
        updateDefaultMeta('global_listing_price_adjustment_value', 10);
        displayPriceAdjustmentValue.value = 10;
      } else {
        updateDefaultMeta('global_listing_price_adjustment_value', newPercent);
        displayPriceAdjustmentValue.value = newPercent;
      }
    }
  }
);
</script>
