<template>
  <div v-if="checkPermission(['settings_view'])" class="mb-13">
    <h2 class="mt-8 mb-4 font-bold text-2xl">General</h2>

    <CompanyInformation/>
  </div>
</template>

<script lang="ts" setup>
import {defineAsyncComponent} from 'vue';
import checkPermission from '@/utils/permission';

const CompanyInformation = defineAsyncComponent(() => import('@/components/dashboard/settings/general/CompanyInformation.vue'));
</script>
