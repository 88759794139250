<template>
  <div>
    <v-card :disabled="vehicleLoading.class" :loading="vehicleLoading.class">
      <v-card-title>
        {{ vehicleClass.name }}
      </v-card-title>
      <v-card-text class="mt-3">
        <v-form class="space-y-4">
          <v-text-field
            v-model="currentWorkingClass.name"
            :error-messages="vehicleClassForm.errors.get('name')"
            class="w-full md:w-[300px]"
            label="Name"
            variant="outlined"
            @keyup="store.commit('vehicles/CLEAR_VEHICLE_FORM_ERRORS', 'name')"
          />
          <div class="flex flex-col md:flex-row md:items-center gap-2">
            <v-text-field
              v-model="currentWorkingClass.sipp_code"
              :error-messages="vehicleClassForm.errors.get('sipp_code')"
              class="w-full md:w-[300px]"
              label="SIPP Code"
              variant="outlined"
              @keyup="store.commit('vehicles/CLEAR_VEHICLE_FORM_ERRORS', 'sipp_code')"
            />
            <v-btn size="x-small" variant="text" @click="openSippDialog()">What is SIPP?</v-btn>
          </div>
          <v-text-field
            v-model="currentWorkingClass.minimum_age"
            :error-messages="vehicleClassForm.errors.get('minimum_age')"
            class="w-full md:w-[300px]"
            label="Minimum Age"
            type="number"
            variant="outlined"
            @keyup="store.commit('vehicles/CLEAR_VEHICLE_FORM_ERRORS', 'minimum_age')"
          />
          <div>
            <v-select
              v-model="currentWorkingClass.branch"
              :error-messages="vehicleClassForm.errors.get('branch')"
              :items="branchesFiltered"
              append-icon="mdi-close"
              class="fleet-branches-select w-full md:w-[300px]"
              label="Branches"
              variant="outlined"
              @keyup="store.commit('vehicles/CLEAR_VEHICLE_FORM_ERRORS', 'branch')"
              @click:append="currentWorkingClass.branch = null"
            >
              <template v-slot:append-item>
                <div class="border-t py-5 flex justify-center">
                  <router-link :to="{name: 'settings.fleet.branches'}">
                    <span class="text-sm">Manage Fleet Branches</span>
                  </router-link>
                </div>
              </template>
            </v-select>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="flex flex-wrap gap-2">
        <div class="flex items-center gap-2">
          <v-btn v-if="!confirmDelete" color="red" size="small" variant="text" @click="confirmDelete = true">
            <v-icon size="small">mdi-delete</v-icon>
          </v-btn>
          <v-btn v-if="confirmDelete" size="small" variant="text" @click="confirmDelete = false">Cancel</v-btn>
          <v-btn v-if="confirmDelete" color="red text-white" size="small" @click="deleteVehicleClassInit(fleetClassUuid)">
            Confirm
          </v-btn>
        </div>
        <v-spacer/>
        <div class="flex items-center gap-2">
          <v-btn size="small" variant="text" @click="backToClasses()">
            Back
          </v-btn>
          <v-btn :loading="vehicleLoading.class" @click="updateVehicleClass(currentWorkingClass)">Save</v-btn>
        </div>
      </v-card-actions>
    </v-card>
    <SippDialog v-model="isSippDialogOpen"/>
  </div>
</template>

<script lang="ts" setup>
import {computed, onBeforeMount, ref, watch} from 'vue';

import SippDialog from '@/views/dashboard/settings/Fleet/SippDialog.vue';
import {useVehicle} from '@/composables/useVehicle';
import {useRoute, useRouter} from 'vue-router';
import {useBranch} from '@/composables/useBranch';
import {VehicleClass} from '@/types';
import {useStore} from 'vuex';

const route = useRoute();
const router = useRouter();
const isSippDialogOpen = ref(false);

const store = useStore();

const {
  getVehicleClass,
  vehicleClass,
  vehicleClassForm,
  vehicleLoading,
  deleteVehicleClass,
  updateVehicleClass,
  getVehicleClasses,
} = useVehicle();
const {getFleetBranches, branches} = useBranch();

const fleetClassUuid = route.params.uuid as string;

const confirmDelete = ref(false);
const currentWorkingClass = ref<VehicleClass>({
  set: false,
  image_url: '',
  active: true,
  name: '',
  uuid: '',
  minimum_age: null,
  sipp_code: '',
  branch: null,
});
const classClone = ref({
  set: false,
  image_url: '',
  active: true,
  name: '',
  uuid: '',
  minimum_age: null,
  sipp_code: '',
  branch: null,
});

const openSippDialog = () => {
  isSippDialogOpen.value = true;
};

onBeforeMount(() => {
  getVehicleClass(route.params.uuid as string);
  getFleetBranches();

  currentWorkingClass.value = {
    set: false,
    image_url: '',
    active: true,
    name: '',
    uuid: '',
    minimum_age: null,
    sipp_code: '',
    branch: null,
  }
  classClone.value = {
    set: false,
    image_url: '',
    active: true,
    name: '',
    uuid: '',
    minimum_age: null,
    sipp_code: '',
    branch: null,
  };
});

const branchesFiltered = computed(() => {
  return branches.value.map((b) => ({
    value: b.uuid,
    title: b.name,
  }));
});

watch(vehicleClass, (vehicleClass) => {
  if (vehicleClass.uuid) {
    classClone.value = JSON.parse(JSON.stringify(vehicleClass));
    currentWorkingClass.value = classClone.value;
    currentWorkingClass.value.branch = classClone.value?.branch?.uuid;
    currentWorkingClass.value.set = true;
  }
});

const deleteVehicleClassInit = (uuid: string) => {
  deleteVehicleClass(uuid).then(() => {
    getVehicleClasses(true);
    router.push({name: 'settings.fleet.classes'}).catch(() => {
    });
  }).catch(() => {
    console.dir('error');
  });
};

const backToClasses = () => {
  router.push({name: 'settings.fleet.classes'}).catch(() => {
  });
};

</script>

