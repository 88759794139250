const irregularPlurals: Record<string, string> = {
    child: 'children',
    person: 'people',
    class: 'classes',
    tomato: 'tomatoes',
    boss: 'bosses',
    glass: 'glasses',
    mouse: 'mice',
    tooth: 'teeth',
    foot: 'feet',
    goose: 'geese',
    woman: 'women',
    man: 'men',
    cactus: 'cacti',
    focus: 'foci',
};


export function pluralize(time: unknown, label: unknown): string {
    if (typeof label !== 'string' || !label.trim()) {
        return ''; // Ensure label is a valid, non-empty string
    }

    const cleanedLabel = label.trim();
    const lowercaseLabel = cleanedLabel.toLowerCase();
    const count = Number(time) || 0;

    if (count === 1) {
        return cleanedLabel; // Return singular form unchanged
    }

    // Determine plural form
    let plural = irregularPlurals[lowercaseLabel] || `${cleanedLabel}s`;

    // Preserve case formatting
    if (cleanedLabel === cleanedLabel.toUpperCase()) {
        // All uppercase (e.g., "ITEM" → "ITEMS")
        plural = plural.toUpperCase();
    } else if (cleanedLabel[0] === cleanedLabel[0].toUpperCase()) {
        // Capitalized (e.g., "Person" → "People")
        plural = plural[0].toUpperCase() + plural.slice(1);
    }

    return plural;
}


export function upperFirst(value: unknown): string {
    if (typeof value === 'string') {
        const trimmed = value.trim(); // Ensure no leading/trailing spaces
        return trimmed.length ? trimmed.charAt(0).toUpperCase() + trimmed.slice(1) : '';
    }

    if (typeof value === 'number' || typeof value === 'boolean') {
        return String(value).charAt(0).toUpperCase() + String(value).slice(1);
    }

    return ''; // Return empty string for invalid types
}

export function snakeCase(value: unknown): string {
    if (typeof value !== 'string') return '';

    return value.trim()
        .replace(/([a-z])([A-Z])/g, '$1_$2') // Insert _ between lowercase and uppercase letters
        .replace(/(\d)([A-Za-z])/g, '$1_$2') // Ensure numbers remain with previous word
        .replace(/\s+/g, '_') // Replace spaces with underscores
        .toLowerCase();
}

export function truncate(value: unknown, length: number): string {
    if (typeof value !== 'string') return '';

    const trimmedValue = value.trim();
    if (trimmedValue.length <= length) return trimmedValue;

    return trimmedValue.slice(0, length) + '...';
}