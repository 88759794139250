import {useStore} from 'vuex';
import {computed} from 'vue';
import {VehicleClass} from "@/types";

const COLORS = ['black', 'blue', 'brown', 'gold', 'gray', 'green', 'orange', 'purple', 'red', 'silver', 'white', 'yellow', 'other'];
const VEHICLE_STATUSES = ['available', 'dirty', 'out of service', 'for sale', 'sold', 'new', 'reserved', 'rental', 'maintenance', 'other'];

const mapToTitleCodeArray = (statuses: string[]) => statuses.map(item => ({
    // uppercase first letter of each word
    title: item.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
    code: item
}));


export function useVehicle() {
    const store = useStore();


    const vehicleLoading = computed(() => store.getters['vehicles/vehicleLoading']);
    const vehicleMakes = computed(() => store.getters['vehicles/vehicleMakes']);
    const vehicleModels = computed(() => store.getters['vehicles/vehicleModels']);
    const vehicleColors = computed(() => mapToTitleCodeArray(COLORS));
    const availableVehicleStatuses = computed(() => mapToTitleCodeArray(VEHICLE_STATUSES));
    const vehicleClasses = computed(() => store.getters['vehicles/vehicleClasses']);
    const vehicleClass = computed<VehicleClass>(() => store.getters['vehicles/vehicleClass'])
    const vehicleClassForm = computed(() => store.getters['vehicles/vehicleClassForm']);
    const vehicleClassCreated = computed(() => store.getters['vehicles/vehicleClassCreated']);

    const getVehicleClass = (uuid: string) => {
        return store.dispatch('vehicles/getClass', uuid);
    }
    const createVehicleClass = (vehicleClass: VehicleClass) => {
        return store.dispatch('vehicles/createClass', vehicleClass);
    }
    const fetchVehicleMakes = () => {
        store.dispatch('vehicles/fetchVehicleMakes');
    }

    const fetchVehicleModels = (make: string) => {
        return store.dispatch('vehicles/fetchVehicleModels', make);
    }

    const deleteVehicleClass = (uuid: string) => {
        return store.dispatch('vehicles/deleteClass', uuid);
    }
    const updateVehicleClass = (vehicleClass: VehicleClass) => {
        return store.dispatch('vehicles/updateClass', vehicleClass);
    }
    const getVehicleClasses = (refresh = false) => {
        return store.dispatch('vehicles/getVehicleClasses', refresh);
    }

    return {
        vehicleColors,
        availableVehicleStatuses,
        vehicleLoading,
        vehicleMakes,
        vehicleModels,
        vehicleClasses,
        vehicleClass,
        vehicleClassForm,
        vehicleClassCreated,
        fetchVehicleMakes,
        fetchVehicleModels,
        getVehicleClass,
        deleteVehicleClass,
        updateVehicleClass,
        getVehicleClasses,
        createVehicleClass,
    }
}