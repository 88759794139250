import axios from 'axios';
import {EventBus} from '@/plugins/eventBus';
import {get} from 'lodash';
import {Form} from '@/plugins/FWForms/index';
import * as types from '../mutation-types';
import {performApiRequest} from '@/store/helpers/vuex-helpers';

export interface Provider {
    active: boolean,
    created_at: string,
    email: string | null,
    provider: string,
    publishable_key: string,
    uid: string,
    updated_at: string,
    uuid: string,
}

export interface ProvidersState {
    loading: Record<string, boolean>,
    form: Form<any>,
    paymentProviders: Provider[],
}

const state: ProvidersState = {
    loading: {
        get: false,
        delete: false,
        toggle: false,
        matching: false,
        create_unavailability: false,
    },
    form: new Form(),
    paymentProviders: [],
};

const getters = {
    form: (state: ProvidersState) => state.form,
    loading: (state: ProvidersState) => state.loading,
    paymentProviders: (state: ProvidersState) => state.paymentProviders,
};

const mutations = {
    [types.SET_PROVIDERS_LOADING](state: ProvidersState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_PAYMENT_PROVIDERS](state: ProvidersState, providers: Provider[]) {
        providers = !providers ? [] : providers;
        providers = !Array.isArray(providers) ? [providers] : providers;

        state.paymentProviders = providers;
    },
};

const actions = {

    getProviders({commit}) {
        commit('SET_PROVIDERS_LOADING', {subject: 'get', loading: true});

        axios.get('/v1/connections')
            .then((response) => {
                commit('outdoorsy/SET_OUTDOORSY_CONNECTIONS', get(response, 'data.providers.outdoorsy', []), {root: true});
                commit('hqRentals/SET_HQRENTALS_CONNECTIONS', get(response, 'data.providers.hq-rentals', []), {root: true});
                commit('lula/SET_LULA_CONNECTIONS', get(response, 'data.lula', []), {root: true});
                commit('twistedRoad/SET_TWISTED_ROAD_CONNECTIONS', get(response, 'data.providers.twisted_road', []), {root: true});
            }).catch(() => {
        }).finally(() => {
            commit('SET_PROVIDERS_LOADING', {subject: 'get', loading: false});
        });
    },

    getPaymentProfiles({commit}) {
        commit('SET_PROVIDERS_LOADING', {subject: 'get', loading: true});

        axios.get('/v1/payment-profiles')
            .then((response) => {
                commit('SET_PAYMENT_PROVIDERS', get(response, 'data.paymentProfile', []));
                EventBus.$emit('get-payment-profiles-success', get(response, 'data.paymentProfile'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_PROVIDERS_LOADING', {subject: 'get', loading: false});
        });
    },

    getPaymentProfile({commit}, paymentProfile) {
        commit('SET_PROVIDERS_LOADING', {subject: 'get', loading: true});

        const uuid = typeof paymentProfile === 'object' ? paymentProfile.uuid : paymentProfile;

        axios.get(`/v1/payment-profiles/${uuid}`)
            .then((response) => {
                EventBus.$emit('get-payment-profile-success', get(response, 'data.paymentProfile'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_PROVIDERS_LOADING', {subject: 'get', loading: false});
        });
    },

    async deletePaymentProfile({commit}, paymentProfile: string | { uuid: string }) {
        const uuid = typeof paymentProfile === 'object' ? paymentProfile.uuid : paymentProfile;

        return await performApiRequest(commit, 'SET_PROVIDERS_LOADING', 'delete', () => axios.delete(`/v1/payment-profiles/${uuid}`));
    },

    delete({commit, rootGetters}, provider) {
        commit('SET_PROVIDERS_LOADING', {subject: 'delete', loading: true});

        axios.delete(`/v1/company/${rootGetters['company/company'].uuid}/connections/${provider.uuid}`)
            .then((response) => {
                EventBus.$emit('delete-provider-success', get(response, 'data.provider'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_PROVIDERS_LOADING', {subject: 'delete', loading: false});
        });
    },

    toggleActive({commit, rootGetters}, provider) {
        commit('SET_PROVIDERS_LOADING', {subject: 'toggle', loading: true});

        axios.put(`/v1/company/${rootGetters['company/company'].uuid}/connections/${provider.uuid}/toggle-active`)
            .then((response) => {
                EventBus.$emit('toggle-provider-success', get(response, 'data.provider'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_PROVIDERS_LOADING', {subject: 'toggle', loading: false});
        });
    },

    matchListing({commit, rootGetters}, {provider_uuid, l_id}) {
        commit('SET_PROVIDERS_LOADING', {subject: 'matching', loading: true});

        axios.post(`/v1/company/${rootGetters['company/company'].uuid}/provider-listings/${provider_uuid}/match/listings/${l_id}`)
            .then((response) => {
                EventBus.$emit('provider-listings-match-success', get(response, 'data.listings', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_PROVIDERS_LOADING', {subject: 'matching', loading: false});
        });
    },

    unMatchProviderListing({commit, rootGetters}, {l_id, provider_uuid}) {
        commit('SET_PROVIDERS_LOADING', {subject: 'saveListing1', loading: true});

        axios.delete(`/v1/listings/${l_id}/provider-listings/${provider_uuid}`)
            .then(() => {
                EventBus.$emit('provider-listings-unmatched-success', provider_uuid);
            }).catch(() => {
        }).finally(() => {
            commit('SET_PROVIDERS_LOADING', {subject: 'saveListing1', loading: false});
        });
    },

    createListingUnavailability({commit, rootGetters}, data) {
        commit('SET_PROVIDERS_LOADING', {subject: 'create_unavailability', loading: true});

        axios.post(`/v1/company/${rootGetters['company/company'].uuid}/provider-bookings`, data)
            .then((response) => {
                if (get(response, 'data.booking')) {
                    commit('booking/SET_CURRENT_WORKING_BOOKING', get(response, 'data.booking'), {root: true});
                }
            }).catch(() => {
        }).finally(() => {
            commit('SET_PROVIDERS_LOADING', {subject: 'create_unavailability', loading: false});
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
