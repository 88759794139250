import axios from 'axios';
import {Form} from '@/plugins/FWForms/index';
import {EventBus} from '@/plugins/eventBus';
import {get} from 'lodash';
import * as types from '../mutation-types';

export interface TwistedRoadState {
    init: boolean;
    loading: Record<string, boolean>;
    form: Form<any>;
    connections: any[];
    vehicles: any[];
}

const state: TwistedRoadState = {
    init: false,
    loading: {
        login: false,
        vehicles: false,
        matching: false,
        disconnect: false,
        toggleActive: false,
    },
    form: new Form(),
    connections: [],
    vehicles: [],
};

const getters = {
    init: state => state.init,
    loading: state => state.loading,
    form: state => state.form,
    connections: state => state.connections,
    vehicles: state => state.vehicles,
};

const mutations = {
    [types.SET_TWISTED_ROAD_LOADING](state, loading) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_TWISTED_ROAD_CONNECTIONS](state, connections) {
        state.connections = connections;
    },
    [types.SET_TWISTED_ROAD_VEHICLES](state, vehicles) {
        state.vehicles = vehicles;
    },
};

const actions = {


    /**
     * Get tax categories
     *
     * @param commit
     * @param {Object} payload
     */
    login({commit}, payload) {
        commit('SET_TWISTED_ROAD_LOADING', {subject: 'login', loading: true});

        axios.post('/v1/connections/twisted-road/login', payload)
            .then(() => {
                EventBus.$emit('create-twisted-road-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_TWISTED_ROAD_LOADING', {subject: 'login', loading: false});
        });
    },

    disconnect({commit}, account) {
        commit('SET_TWISTED_ROAD_LOADING', {subject: 'disconnect', loading: true});

        axios.delete(`/v1/connections/twisted-road/${account.uuid}`)
            .then(() => {
                EventBus.$emit('delete-twisted-road-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_TWISTED_ROAD_LOADING', {subject: 'disconnect', loading: false});
        });
    },

    toggleActive({commit}, account) {
        commit('SET_TWISTED_ROAD_LOADING', {subject: 'toggleActive', loading: true});

        axios.put(`/v1/connections/twisted-road/${account.uuid}/toggle-active`)
            .then(() => {
            }).catch(() => {
        }).finally(() => {
            commit('SET_TWISTED_ROAD_LOADING', {subject: 'toggleActive', loading: false});
        });
    },

    fetchVehicles({commit}, payload) {
        commit('SET_TWISTED_ROAD_LOADING', {subject: 'vehicles', loading: true});

        axios.post('/v1/connections/twisted-road/vehicles', payload)
            .then((response) => {
                commit('SET_TWISTED_ROAD_VEHICLES', get(response, 'data.vehicles', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_TWISTED_ROAD_LOADING', {subject: 'vehicles', loading: false});
        });
    },

    /**
     * Match TR Vehicles
     *
     * @param commit
     * @param {Object} data
     */
    matchVehicle({commit}, data) {
        commit('SET_TWISTED_ROAD_LOADING', {subject: 'vehicles', loading: true});

        axios.post('/v1/connections/twisted-road/match-vehicle', data)
            .then(() => {
                EventBus.$emit('match-twisted-road-vehicle-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_TWISTED_ROAD_LOADING', {subject: 'vehicles', loading: false});
        });
    },

    /**
     * Match TR Vehicles
     *
     * @param commit
     * @param {Object} data
     */
    unmatchVehicle({commit}, data) {
        commit('SET_TWISTED_ROAD_LOADING', {subject: 'matching', loading: true});

        axios.post('/v1/connections/twisted-road/remove-matched-vehicle', data)
            .then(() => {
                EventBus.$emit('match-twisted-road-vehicle-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_TWISTED_ROAD_LOADING', {subject: 'matching', loading: false});
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
