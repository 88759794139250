<template>
  <div class="space-y-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <div class="flex items-center gap-2">
            <h3 class="text-lg font-medium text-gray-900 dark:text-gray-100">
              Stripe
            </h3>
            <span class="recommended">Recommended</span>
          </div>
          <div class="mt-1 text-sm text-gray-600 dark:text-gray-400">
            Accept payments through Stripe in your online store and via payment requests. Stripe enables you to process
            credit cards, debit cards, and other popular payment methods.<br><br>

            You can also securely charge cards or place authorization holds directly within Fleetwire.<br>
            <the-help-link url="https://help.fleetwire.io/en/article/integrating-with-stripe-747u5f/?bust=1678309814404"/>
          </div>
        </div>
      </div>
      <div class="mt-4 md:mt-0 md:col-span-2">
        <StripeProviderCard/>
      </div>
    </div>

    <v-divider class="my-6"/>

    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <div class="flex items-center gap-2">
            <h3 class="text-lg font-medium text-gray-900 dark:text-gray-100">
              Square
            </h3>
            <span class="under-development__red">Beta</span>
          </div>
          <div class="mt-1 text-sm text-gray-600 dark:text-gray-400">
            Accept payments through Square in your online store and via payment requests. Square enables you to process
            credit cards, debit cards, and other popular payment methods.
            <the-help-link
              url="https://help.fleetwire.io/en/article/integrating-with-square-1hg2hi2/?bust=1689725719338"
            />
            <v-alert
              v-if="!canUseSquareIntegration"
              class="mt-5"
              color="info"
              density="compact"
              icon="mdi-fire"
              variant="outlined"
            >
              <div class="flex flex-col sm:flex-row gap-2">
                <div class="grow">
                  Coming soon
                </div>
                <div class="shrink">
                  <v-btn v-if="!hasWaitlistRequest('SquarePaymentProvider')" size="small" @click="addFeatureWaitlist({feature: 'SquarePaymentProvider'})">
                    Notify Me
                  </v-btn>
                  <v-btn v-else :disabled="true" size="small">Will notify</v-btn>
                </div>
              </div>
            </v-alert>
          </div>
        </div>
      </div>
      <div class="mt-4 md:mt-0 md:col-span-2">
        <SquareProviderCard/>
      </div>
    </div>

    <v-divider class="my-6"/>

    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <div class="flex items-center gap-2">
            <h3 class="text-lg font-medium text-gray-900 dark:text-gray-100">
              PayPal
            </h3>
            <span class="under-development__yellow">Under Development</span>
          </div>
          <div class="mt-1 text-sm text-gray-600 dark:text-gray-400">
            Accept payments through PayPal or any major credit/debit card for online purchases and payment requests.
            <the-help-link url="https://help.fleetwire.io/en/article/integrating-with-paypal-14wog9m/?bust=1678311944038"/>

            <v-alert
              v-if="!canUseBraintreeIntegration"
              class="mt-5"
              color="info"
              density="compact"
              icon="mdi-fire"
              variant="outlined"
            >
              <div class="flex flex-col sm:flex-row gap-2">
                <div class="grow">
                  Coming soon
                </div>
                <div class="shrink">
                  <v-btn v-if="!hasWaitlistRequest('BraintreePaymentProvider')" size="small" variant="tonal" @click="addFeatureWaitlist({feature: 'BraintreePaymentProvider'})">
                    Notify Me
                  </v-btn>
                  <v-btn v-else :disabled="true" size="small">Will notify</v-btn>
                </div>
              </div>
            </v-alert>
          </div>
        </div>
      </div>
      <div class="mt-4 md:mt-0 md:col-span-2">
        <BraintreeProviderCard/>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import StripeProviderCard from '@/components/dashboard/settings/providers/StripeProviderCard.vue';
import BraintreeProviderCard from '@/components/dashboard/settings/providers/BraintreeProviderCard.vue';
import SquareProviderCard from '@/components/dashboard/settings/providers/SquareProviderCard.vue';
import {useAppFeatures} from '@/composables/useAppFeatures';
import {onBeforeMount} from 'vue';

const {
  hasWaitlistRequest,
  addFeatureWaitlist,
  getFeatureWaitlist,
  canUseBraintreeIntegration,
  canUseSquareIntegration,
} = useAppFeatures();

onBeforeMount(() => {
  getFeatureWaitlist();
});
</script>

<style>
.recommended {
  color: #6c36f4 !important;
  font-weight: 600;
  font-size: 12px;
  padding: 2px 8px;
  border: 1px solid #6c36f4;
  border-radius: 3px;
  background-color: #ebe7f5;
  height: 22px;
  white-space: nowrap;
}

.under-development__yellow {
  color: #f7b500 !important;
  font-weight: 600;
  font-size: 12px;
  padding: 2px 8px;
  border: 1px solid #f7b500;
  border-radius: 3px;
  background-color: #fff8e1;
  height: 22px;
  white-space: nowrap;
}

.under-development__red {
  color: #f44336 !important;
  font-weight: 600;
  font-size: 12px;
  padding: 2px 8px;
  border: 1px solid #f44336;
  border-radius: 3px;
  background-color: #ffebee;
  height: 22px;
  white-space: nowrap;
}
</style>
