<template>
  <div>
    <v-card :disabled="fleetLoading.updateBranch || fleetLoading.actionItem" :loading="fleetLoading.actionItem">
      <v-card-title>
        New Action Item
      </v-card-title>
      <v-card-text class="mt-3">
        <v-card-text class="mt-3">
          <v-form>
            <div class="grid grid-cols-2">
              <div class="mb-5">
                <v-text-field
                  v-model="currentWorkingActionItem.title"
                  :error-messages="fleetForm.errors.get('title')"
                  label="Name"
                  variant="outlined"
                  @keydown="$store.commit('fleet/CLEAR_FLEET_FORM_ERRORS', 'title')"
                />
              </div>
            </div>

            <div class="grid grid-cols-2">
              <div class="mb-5">
                <v-select
                  v-model="currentWorkingActionItem.when"
                  :error-messages="fleetForm.errors.get('when')"
                  :items="actionItemEras"
                  item-title="name"
                  item-value="value"
                  label="Era"
                  variant="outlined"
                />
              </div>
            </div>

            <div class="grid grid-cols-2">
              <div class="mb-5">
                <v-autocomplete
                  v-model="currentWorkingActionItem.vehicle_classes"
                  :items="vehicleClasses"
                  item-title="name"
                  item-value="uuid"
                  label="Vehicle Classes"
                  multiple
                  return-object
                  variant="outlined"
                />
              </div>
            </div>
          </v-form>
        </v-card-text>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn size="small" variant="text" @click="$router.push({ name: 'settings.action_items' }).catch(() => {});">
          Back
        </v-btn>
        <v-btn
          :disabled="fleetLoading.updateBranch || currentWorkingActionItem.title === '' || currentWorkingActionItem.when === ''"
          :loading="fleetLoading.updateBranch"
          @click="createActionItem(currentWorkingActionItem)"
        >Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>


<script lang="ts">
import {mapActions, mapGetters} from 'vuex';

const defaultActionItem = {
  uuid: '',
  title: '',
  when: 'pre-trip',
  order: 0,
  vehicle_classes: [],
};

export default {
  name: 'ActionItemNew.vue',

  data() {
    return {
      actionItemEras: [
        {name: 'Pre-Trip', value: 'pre-trip'},
        {name: 'Post-Trip', value: 'post-trip'},
      ],
      currentWorkingActionItem: {...defaultActionItem},
    };
  },

  created() {
    this.getVehicleClasses();
  },

  watch: {
    newlyCreatedActionItem(newlyCreatedActionItem) {
      if (newlyCreatedActionItem.uuid) {
        this.$router.push({
          name: 'settings.action_items.single',
          params: {uuid: newlyCreatedActionItem.uuid}
        }).catch(() => {
        });
      }
    },
  },

  computed: {
    ...mapGetters({
      fleetLoading: 'fleet/loading',
      newlyCreatedActionItem: 'fleet/newlyCreatedActionItem',
      vehicleClasses: 'vehicles/vehicleClasses',
      vehicleLoading: 'vehicles/vehicleLoading',
      fleetForm: 'fleet/fleetForm',
    }),
  },

  methods: {
    ...mapActions({
      createActionItem: 'fleet/createActionItem',
      getVehicleClasses: 'vehicles/getVehicleClasses',
    }),
    updateActionItemInit() {
      this.updateActionItem({
        uuid: this.$route.params.uuid,
        title: this.currentWorkingActionItem.title,
        when: this.currentWorkingActionItem.when,
        order: this.currentWorkingActionItem.order,
        vehicle_classes: this.currentWorkingActionItem.vehicle_classes,
      });
    },
  },

  beforeUnmount() {
    this.currentWorkingActionItem = {...defaultActionItem};
    this.$store.commit('fleet/CLEAR_FLEET_FORM_ERRORS');
  },
};
</script>

