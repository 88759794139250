import axios from 'axios';
import {
    DeliveryDistanceResults,
    ProductOrder,
    ProductOrderCosts,
    ProductOrderDates,
    ProductOrderDuration,
    StoreLoaders,
    TenantLocation
} from '@/types';
import {Module} from 'vuex'
import * as types from '@/store/mutation-types';
import {RootState} from '@/types/RootState';
import {CartService} from '@/services/cart.service';
import {Form} from '@/plugins/FWForms/index';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import router from '@/router';


export interface TenantCheckoutState {
    loading: StoreLoaders,
    dialogs: Record<string, boolean>,
    productOrder: ProductOrder,
    showDeliveryAddressForm: boolean,
    stripePaymentIntent: any,
    tenantCheckoutForm: Form<any>,
    deliveryDistanceResults: DeliveryDistanceResults,
}


const state: TenantCheckoutState = {
    loading: {
        checkout: false,
        stripe_oauth: false,
        createApiToken: false,
        start2FAVerify: false,
        check2FAVerify: false,
    },

    dialogs: {
        stripePayment: false,
    },

    stripePaymentIntent: null,

    productOrder: {
        version: 'v2',
        l_id: '',
        b_id: '',
        p_id: '',
        dates: {
            start: null,
            end: null,
        },
        costs: {
            vehicle: 0,
            extras: 0,
            requiredExtras: 0,
            delivery: 0,
            taxes: 0,
            discount: 0,
            subtotal: 0,
            grandTotal: 0,
            dueToday: 0,
            securityDepositCredit: 0,
            securityDepositDebit: 0,
        },
        discounts: {
            discounted: false,
            amount: 0,
            value: 0,
            code: null,
            reason: null,
            type: null,
            durationDiscountReason: null,
            durationDiscountAmount: null,
            durationDiscountValue: null,
        },
        duration: {
            seconds: 0,
            minutes: 0,
            hours: 0,
            days: 0,
            weeks: 0,
            months: 0,
            years: 0,
            units: 1,
        },
        customers: [
            {
                isPrimary: true,
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                dob: '',
                age: null,
                signaturePng: null,
                signFullName: null,
                signInitials: null,
                agreeAdoptSignature: false,
                agreeToS: false,
            },
        ],
        extras: [],
        extrasV2: [],
        requiredExtras: [],
        requiredExtrasV2: {},
        delivery: null,
        delivery_address: null,
        distance_allowance: {
            distance: null,
            unit: 'miles',
        },
        customFields: [],
        cartId: CartService.getCartUuid(),
    },

    tenantCheckoutForm: new Form(),

    showDeliveryAddressForm: false,
    deliveryDistanceResults: {
        checked: false as boolean,
        valid: false as boolean,
        message: null as string | null,
        distance: null as number | null,
    },
}


const tenantCheckoutModule: Module<TenantCheckoutState, RootState> = {
    namespaced: true,

    state,

    getters: {
        loading: (state: TenantCheckoutState) => state.loading,
        dialogs: (state: TenantCheckoutState) => state.dialogs,
        stripePaymentIntent: (state: TenantCheckoutState) => state.stripePaymentIntent,
        productOrder: (state: TenantCheckoutState) => state.productOrder,
        showDeliveryAddressForm: (state: TenantCheckoutState) => state.showDeliveryAddressForm,
        deliveryDistanceResults: (state: TenantCheckoutState) => state.deliveryDistanceResults,
        tenantCheckoutForm: (state: TenantCheckoutState) => state.tenantCheckoutForm,
    },


    mutations: {
        [types.SET_CHECKOUT_LOADING](state: TenantCheckoutState, loading: { subject: string, loading: boolean }) {
            state.loading[loading.subject] = loading.loading;
        },
        [types.SET_TENANT_CHECKOUT_DATES](state: TenantCheckoutState, dates: ProductOrderDates) {
            console.log('%cSET_TENANT_CHECKOUT_DATES', 'color: red; font-weight: bold;');
            state.productOrder.dates = dates;
        },
        [types.SET_TENANT_CHECKOUT_LOCATION](state: TenantCheckoutState, location: TenantLocation) {
            console.log('%cSET_TENANT_CHECKOUT_LOCATION', 'color: pink; font-weight: bold;');
            state.productOrder.delivery = location;
        },
        [types.SET_TENANT_CHECKOUT_DELIVERY_ADDRESS_FORM_SHOW](state: TenantCheckoutState, payload) {
            state.showDeliveryAddressForm = payload;
        },
        [types.SET_TENANT_CHECKOUT_STRIPE_PAYMENT_INTENT](state: TenantCheckoutState, payload) {
            state.stripePaymentIntent = payload;
        },
        [types.SET_TENANT_CHECKOUT_DIALOG](state: TenantCheckoutState, payload) {
            if (payload.topic === 'stripe-payment') {
                state.dialogs.stripePayment = payload.value;
            }
        },
        [types.SET_TENANT_CHECKOUT_DURATIONS](state: TenantCheckoutState, payload: ProductOrderDuration) {
            state.productOrder.duration = payload;
        },
        [types.SET_TENANT_CHECKOUT_BOOKING_ID](state: TenantCheckoutState, payload: string) {
            state.productOrder.b_id = payload;
        },
        [types.SET_TENANT_CHECKOUT_CUSTOM_ORDER_FIELDS](state: TenantCheckoutState, payload) {
            state.productOrder.customFields = payload;
        },
        [types.SET_TENANT_CHECKOUT_CUSTOMER_TOS_AGREE](state: TenantCheckoutState, data: {
            index: number,
            agreeToS: boolean
        }) {
            state.productOrder.customers[data.index].agreeToS = data.agreeToS;
        },
        [types.SET_TENANT_CHECKOUT_COSTS](state: TenantCheckoutState, payload: ProductOrderCosts) {
            state.productOrder.costs = payload;
        },
        [types.SET_TENANT_CHECKOUT_LISTING_ID](state: TenantCheckoutState, payload: string) {
            state.productOrder.l_id = payload;
        },
        [types.SET_TENANT_CHECKOUT_CUSTOMER_BY_INDEX](state: TenantCheckoutState, data: {
            index: number,
            customer: any
        }) {
            state.productOrder.customers[data.index] = data.customer;
        },
        [types.SET_TENANT_CHECKOUT_CUSTOMERS](state: TenantCheckoutState, payload) {
            state.productOrder.customers = payload;
        },
        [types.SET_TENANT_CHECKOUT_EXTRAS_V2](state: TenantCheckoutState, payload) {
            console.dir('SET_TENANT_CHECKOUT_EXTRAS_V2');
            state.productOrder.extrasV2 = payload;
        },
        [types.SET_TENANT_CHECKOUT_REQUIRED_EXTRAS_V2](state: TenantCheckoutState, payload) {
            if (!state.productOrder.requiredExtrasV2) {
                state.productOrder.requiredExtrasV2 = {};
            }

            state.productOrder.requiredExtrasV2[payload.index] = payload.extra;
        },

        [types.SET_TENANT_CHECKOUT_REQUIRED_EXTRAS](state: TenantCheckoutState, data) {
            if (data.requiredExtras) {
                state.productOrder.requiredExtras = data.requiredExtras.map(e => e.extras[0]);
            }

            if (data.requiredExtrasV2) {
                state.productOrder.requiredExtrasV2 = data.requiredExtrasV2.map(e => {
                    return {
                        parentUuid: e.uuid,
                        choice: e.options[0],
                    };
                });
            }
        },
        [types.SET_TENANT_CHECKOUT_ORDER_DELIVERY_ADDRESS](state: TenantCheckoutState, address) {
            state.productOrder.delivery_address = address;
        },
        [types.SET_TENANT_CHECKOUT_ORDER_DISTANCE_ALLOWANCE](state: TenantCheckoutState, payload: {
            distance: number,
            unit: string
        }) {
            state.productOrder.distance_allowance = payload;
        },
        [types.RESET_TENANT_CHECKOUT_DELIVERY_RESULTS](state: TenantCheckoutState) {
            state.deliveryDistanceResults = {
                checked: false,
                valid: false,
                message: null,
                distance: null,
            };
        },
        [types.SET_TENANT_CHECKOUT_DELIVERY_RESULTS](state: TenantCheckoutState, payload: DeliveryDistanceResults) {
            state.deliveryDistanceResults = payload;
        },
        [types.SET_TENANT_CHECKOUT_DISCOUNT](state, coupon) {
            console.dir(coupon);

            state.productOrder.discounts = {
                discounted: true,
                value: coupon?.value,
                code: coupon?.code,
                amount: coupon?.amount,
                reason: coupon?.reason,
                type: coupon?.type,
                durationDiscountReason: null,
                durationDiscountAmount: null,
                durationDiscountValue: null,
            };
        },
        [types.RESET_TENANT_CHECKOUT_DISCOUNT](state) {
            state.tenantCheckoutForm.errors.clear('coupon');
            state.productOrder.discounts = {
                discounted: false,
                code: '',
                amount: 0,
                reason: '',
                type: '',
                value: 0,
                durationDiscountReason: null,
                durationDiscountAmount: null,
                durationDiscountValue: null,
            };
        },
        [types.SET_TENANT_CHECKOUT_FORM_ERRORS](state, errors) {
            state.tenantCheckoutForm.errors.set(errors);
        },
        [types.RESET_TENANT_CHECKOUT_FORM_ERRORS](state) {
            state.tenantCheckoutForm.errors.clear();
        }
    },

    actions: {
        setLocation({commit}, location: TenantLocation) {
            commit(types.SET_TENANT_CHECKOUT_LOCATION, location);
        },
        setCustomOrderCheckoutFields({commit}, fields) {
            commit(types.SET_TENANT_CHECKOUT_CUSTOM_ORDER_FIELDS, fields);
        },
        resetDeliveryDistanceResults({commit}) {
            commit(types.RESET_TENANT_CHECKOUT_DELIVERY_RESULTS);
        },
        setDeliveryAddressFormVisibility({commit}, payload: boolean) {
            commit(types.SET_TENANT_CHECKOUT_DELIVERY_ADDRESS_FORM_SHOW, payload);
        },
        setDeliveryDistanceResults({state: TenantCheckoutState}, payload) {
            state.deliveryDistanceResults = payload;
        },
        /**
         * Check the distance between the delivery location and the listing
         */
        async checkDrivingDistance({commit}, location) {
            try {
                await performApiRequest(commit, 'SET_CHECKOUT_LOADING', 'checkout', () => axios.post('/v1/verify-driving-distance', location));
                commit(types.SET_TENANT_CHECKOUT_DELIVERY_RESULTS, {
                    checked: true,
                    valid: true,
                });
            } catch (e: any) {
                commit(types.SET_TENANT_CHECKOUT_DELIVERY_RESULTS, {
                    checked: true,
                    valid: false,
                    message: e.response?.data?.message || 'There was an error checking the driving distance.',
                });
            }
        },
        async verifyPayment({commit, rootGetters}, paymentIntentId: string) {
            try {
                return await performApiRequest(commit, 'SET_CHECKOUT_LOADING', 'checkout', () => axios.get(`/v1/company/${rootGetters['company/company'].uuid}/verify-payment-intent/${paymentIntentId}`));
            } catch (error) {
                console.error('error');
                console.dir(error);
            }
        },
        async tenantCheckout({commit, rootGetters, state: TenantCheckoutState}) {
            try {
                const paymentStrategy = rootGetters['company/company']?.meta?.payment_strategy;
                const paymentProvider = rootGetters['company/company']?.payment_profile?.provider;
                const notingDueToday = state.productOrder.costs.dueToday === 0;

                const responseData = await performApiRequest(commit, 'SET_CHECKOUT_LOADING', 'checkout', () => axios.post('/v1/checkout', state.productOrder));

                // if there is no payment provider, redirect to the success page
                if (paymentProvider === null || (paymentProvider !== 'square' && paymentProvider !== 'stripe') || notingDueToday) {
                    // send to checkout success page
                    router.push({
                        name: 'reservations.complete',
                        query: {
                            b_id: responseData?.b_id,
                            l_id: responseData?.l_id,
                            company: rootGetters['company/company']?.slug
                        }
                    }).catch(() => {
                    });
                    return;
                }

                // If payment provider is Square, redirect to Square payment page
                if (paymentProvider === 'square') {
                    window.location.href = responseData?.redirect;
                }

                if (responseData?.redirect) {
                    window.location.href = responseData?.redirect;
                    return;
                }


                // if payment provider is Stripe
                if (paymentProvider === 'stripe') {
                    const sessionId = responseData?.StripeSession?.id;
                    const customer = responseData?.customer;
                    const stripePaymentMethod = 'payment_intent';

                    if (stripePaymentMethod === 'payment_intent') {
                        // EventBus.$emit('set-booking-id', get(response, 'data.b_id'));
                        // EventBus.$emit('set-listing-id', get(response, 'data.l_id'));
                        // EventBus.$emit('open-checkout-payment-dialog', get(response, 'data.StripePaymentIntent'));

                        commit(types.SET_TENANT_CHECKOUT_BOOKING_ID, responseData?.b_id);
                        commit(types.SET_TENANT_CHECKOUT_STRIPE_PAYMENT_INTENT, responseData?.StripePaymentIntent);
                        commit(types.SET_TENANT_CHECKOUT_DIALOG, {
                            topic: 'stripe-payment',
                            value: true,
                        });
                    }
                }

                // If payment provider is Stripe, redirect to Stripe payment page

                console.dir(responseData);

            } catch (error) {
                console.error('error');
                console.dir(error);

                // tenantCheckoutForm.errors.record(error.response.data.errors);
                commit(types.SET_TENANT_CHECKOUT_FORM_ERRORS, error.response.data.errors);
            }
        }
    },

}

export default tenantCheckoutModule;