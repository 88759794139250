import {EventBus} from '@/plugins/eventBus';
import axios from 'axios';
import * as types from '@/store/mutation-types';
import {Form} from '@/plugins/FWForms/index';
import {performApiRequest} from "@/store/helpers/vuex-helpers";

export interface PricingState {
    loading: Record<string, boolean>;
    currentWorkingPricingStructure: any;
    couponForm: Form<any>;
    pricingRules: any[];
    pricingStructure: any;
    pricingStructures: any[];
}

// state
const state: PricingState = {
    loading: {
        get: false,
        create: false,
        update: false,
        delete: false,
        toggle: false,
        structures: false,
        structure: false,
        structureUpdate: false,
        structureDelete: false,
        structureCreate: false,
    },
    currentWorkingPricingStructure: {
        uuid: null,
        name: '',
        price_example: 100,
        added_amount: 100,
        duration_unit: 'days',
        multiplier: 1,
        price_tiers: [
            {
                name: '1 day',
                quantity: 1,
                period: 'days',
                multiplier: 1,
                example_price: 100,
            },
        ],
    },
    couponForm: new Form(),
    pricingRules: [],
    pricingStructure: null,
    pricingStructures: [],
};

const getters = {
    loading: state => state.loading,
    pricingRules: state => state.pricingRules,
    pricingStructure: state => state.pricingStructure,
    pricingStructures: state => state.pricingStructures,
    currentWorkingPricingStructure: state => state.currentWorkingPricingStructure,
};

const mutations = {
    [types.SET_PRICING_LOADING](state, loading) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_PRICING_RULES](state, rules) {
        state.pricingRules = rules;
    },
    [types.SET_PRICING_STRUCTURES](state, data) {
        state.pricingStructures = data;
    },
    [types.SET_PRICING_STRUCTURE](state, data) {
        state.pricingStructure = data;
    },
    [types.RESET_CURRENT_WORKING_PRICING_STRUCTURE](state) {
        state.currentWorkingPricingStructure = {
            uuid: null,
            name: '',
            price_example: 100,
            added_amount: 100,
            duration_unit: 'days',
            multiplier: 1,
            price_tiers: [
                {
                    name: '1 day',
                    quantity: 1,
                    period: 'days',
                    multiplier: 1,
                    example_price: 100,
                },
            ],
        };
    },
};

const actions = {

    /**
     * Get pricing rules
     */
    getPricingRules({commit}) {
        commit('SET_PRICING_LOADING', {subject: 'get', loading: true});
        return axios.get('/v1/pricing-rules')
            .then((response) => {
                commit('SET_PRICING_RULES', response?.data?.rules);
            }).catch(() => {
            }).finally(() => {
                commit('SET_PRICING_LOADING', {subject: 'get', loading: false});
            });
    },

    /**
     * Create pricing rule
     *
     * @param commit
     * @param {Object} rule
     */
    async createPricingRule({commit}, rule) {
        commit('SET_PRICING_LOADING', {subject: 'create', loading: true});

        try {
            await performApiRequest(commit, 'SET_PRICING_LOADING', 'create', () => axios.post('/v1/pricing-rules', rule));
            EventBus.$emit('create-pricing-rules-success');
        } catch (error) {
            EventBus.$emit('SystemMessage', {
                type: 'error',
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },

    /**
     * Update pricing rule
     *
     * @param state
     * @param commit
     * @param {Object} ruleData
     */
    savePricingRule({state, commit}, ruleData) {
        commit('SET_PRICING_LOADING', {subject: 'update', loading: true});

        axios.put(`/v1/pricing-rules/${ruleData.uuid}`, ruleData)
            .then(() => {
                EventBus.$emit('update-pricing-rules-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_PRICING_LOADING', {subject: 'update', loading: false});
        });
    },

    /**
     * Delete pricing rules
     *
     * @param commit
     * @param {Array} uuids
     */
    async deletePricingRules({commit}, uuids) {
        try {
            await performApiRequest(commit, 'SET_PRICING_LOADING', 'delete', () => axios.post('/v1/pricing-rules/delete', {uuids}));
            EventBus.$emit('delete-pricing-rules-success');
        } catch (error) {
            EventBus.$emit('SystemMessage', {
                type: 'error',
                message: error?.response?.data?.message || 'There was an error',

            });
        }
    },

    /**
     * Pricing structures
     *
     * @param commit
     */
    getPricingStructures({commit}) {
        commit('SET_PRICING_LOADING', {subject: 'structures', loading: true});

        axios.get('/v1/price-structures')
            .then((response) => {
                commit('SET_PRICING_STRUCTURES', response?.data?.data);
            })
            .catch(() => {
            }).finally(() => {
            commit('SET_PRICING_LOADING', {subject: 'structures', loading: false});
        });
    },

    /**
     * Single pricing structures
     *
     * @param commit
     * @param {String} uuid
     */
    getPricingStructure({commit}, uuid) {
        commit('SET_PRICING_LOADING', {subject: 'structure', loading: true});

        axios.get(`/v1/price-structures/${uuid}`)
            .then((response) => {
                commit('SET_PRICING_STRUCTURE', response?.data?.data);
            })
            .catch(() => {
            }).finally(() => {
            commit('SET_PRICING_LOADING', {subject: 'structure', loading: false});
        });
    },

    /**
     * Save pricing structures
     *
     * @param commit
     * @param {Object} data
     */
    savePriceStructure({commit}, data) {
        commit('SET_PRICING_LOADING', {subject: 'structureCreate', loading: true});

        axios.post('/v1/price-structures', data)
            .then(() => {
                EventBus.$emit('create-pricing-structure-success');
            })
            .catch(() => {
            }).finally(() => {
            commit('SET_PRICING_LOADING', {subject: 'structureCreate', loading: false});
        });
    },

    /**
     * Update pricing structures
     *
     * @param commit
     * @param {Object} data
     */
    updatePriceStructure({commit}, data) {
        commit('SET_PRICING_LOADING', {subject: 'structureUpdate', loading: true});

        axios.put(`/v1/price-structures/${data.uuid}`, data)
            .then(() => {
                EventBus.$emit('update-pricing-structure-success');
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Update successful',
                });
            })
            .catch((e) => {
                EventBus.$emit('SystemMessage', {
                    type: 'error',
                    message: e?.response?.data?.message || 'There was an error',
                });
            }).finally(() => {
            commit('SET_PRICING_LOADING', {subject: 'structureUpdate', loading: false});
        });
    },

    /**
     * Delete pricing structures
     *
     * @param commit
     * @param {Object} data
     */
    deletePriceStructure({commit}, data) {
        commit('SET_PRICING_LOADING', {subject: 'structureDelete', loading: true});

        axios.delete(`/v1/price-structures/${data.uuid}`)
            .then(() => {
                EventBus.$emit('delete-pricing-structure-success');
            })
            .catch((e) => {
                EventBus.$emit('SystemMessage', {
                    type: 'error',
                    message: e?.response?.data?.message || 'There was an error',
                });
            }).finally(() => {
            commit('SET_PRICING_LOADING', {subject: 'structureDelete', loading: false});
        });
    },

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
