import {store as defaultStore} from '@/store';

/**
 * Check if user has the required permission(s)
 * @param {string[]} value - Required permissions
 * @param {any} [customStore] - Optional store (for reactivity)
 */
export default function checkPermission(value: string[], customStore = defaultStore): boolean {
    if (!value || !Array.isArray(value) || value.length === 0) {
        console.error('Need permissions! Example: checkPermission("[\'manage permission\',\'edit article\']")');
        return false;
    }

    const permissions = customStore?.getters?.['auth/permissions'] as string[];

    if (!permissions) {
        console.warn('Permissions not found. Store might not be initialized.');
        return false;
    }

    return permissions.some(permission => value.includes(permission));
}
