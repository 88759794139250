import {useStore} from 'vuex';
import {computed, ref} from 'vue';
import dayjs from 'dayjs';


// Define types for expected data structures
interface Identity {
    person_details?: {
        first_name?: string;
        last_name?: string;
        dob?: { month: number; day: number; year: number };
        document_number?: string;
        expiration_date?: { month: number; day: number; year: number };
        address?: {
            city?: string;
            country?: string;
            line1?: string;
            line2?: string;
            postal_code?: string;
            state?: string;
        };
    };
    status?: string;
    vs_id?: string;
    last_error?: string;
    redirect_to_url?: string;
}

export function useStripe() {
    const store = useStore();

    const stripeLoading = computed(() => store.getters['stripe/loading']);
    const stripeAccounts = computed(() => store.getters['stripe/account']);
    const stripePrices = computed(() => store.getters['stripe/stripePrices']);

    const fetchStripeAccounts = async () => store.dispatch('stripe/fetchStripeAccount');

    const fetchStripeCapabilities = async () => {
        await store.dispatch('stripe/fetchStripeCapabilities');
    }

    const saveStripeKey = async (payload: string) => {
        await store.dispatch('company/saveStripeKey', payload);
    }
    const resetStripeKey = async () => {
        await store.dispatch('company/resetStripeKey');
    }
    const testStripeIdentity = async (payload: { stripeKey: string }) => {
        await store.dispatch('stripe/testStripeIdentity', payload);
    }

    const fetchSubscriptionDetails = async () => {
        await store.dispatch('stripe/fetchSubscriptionDetails');
    }

    const fetchStripePriceData = async () => {
        await store.dispatch('stripe/fetchStripePriceData');
    }


    const fetchCheckoutUrl = async (data) => {
        return await store.dispatch('stripe/fetchCheckoutUrl', data);
    }

    /**
     * Helper function for formatting dates.
     */
    const formatDate = (month?: number, day?: number, year?: number): string | null => {
        if (!month || !day || !year) return null;
        return dayjs(`${month}/${day}/${year}`, 'M/D/YYYY').format('MMM D, YYYY');
    };

    const stripeIdentityName = (identity: Identity) => {
        const firstName = identity?.person_details?.first_name || '';
        const lastName = identity?.person_details?.last_name || '';

        return {
            set: !!firstName,
            first_name: firstName,
            last_name: lastName,
            full_name: `${firstName} ${lastName}`.trim(),
        };
    }

    const stripeIdentityDOB = (identity: Identity) => {
        const dob = identity?.person_details?.dob;
        const formattedDate = formatDate(dob?.month, dob?.day, dob?.year);

        return {
            set: !!formattedDate,
            dob: formattedDate,
            age: formattedDate ? dayjs().diff(formattedDate, 'year') : null,
        };
    }

    /**
     * Formats identity status for display.
     */
    const stripeIdentityStatus = (identity: Identity) => {
        const status = identity?.status?.replace(/_/g, ' ') || 'unknown';
        if (!status) return 'unknown';

        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    }

    /**
     * Returns the Stripe Identity Verification ID.
     */
    const stripeIdentityVSId = (id: Identity) => id?.vs_id || null;

    /**
     * Returns the last error related to the Stripe identity.
     */
    const stripeIdentityErrors = (id: Identity) => id?.last_error || null;


    /**
     * Returns the Stripe identity verification URL.
     */
    const stripeIdentityUrl = (id: Identity) => id?.redirect_to_url || null;


    /**
     * Returns the document number for identity verification.
     */
    const stripeIdentityDocumentNumber = (id: Identity) => id?.person_details?.document_number || null;


    /**
     * Returns the formatted expiration date of an identity document.
     */
    const stripeIdentityDocumentExpiration = (id: Identity) => {
        const expiration = id?.person_details?.expiration_date;
        return expiration ? formatDate(expiration.month, expiration.day, expiration.year) : null;
    };


    /**
     * Extracts and returns the identity address.
     */
    const stripeIdentityAddress = (id: Identity) => {
        const address = id?.person_details?.address || {};
        return {
            city: address.city || '',
            country: address.country || '',
            line1: address.line1 || '',
            line2: address.line2 || '',
            postal_code: address.postal_code || '',
            state: address.state || '',
        };
    };

    const stripeInstance = ref<any>(null);

    /**
     * Dynamically loads Stripe.js and initializes the Stripe instance.
     */
    const loadStripe = (publishableKey: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            if (stripeInstance.value) {
                return resolve(stripeInstance.value);
            }

            if (window.Stripe) {
                stripeInstance.value = window.Stripe(publishableKey);
                return resolve(stripeInstance.value);
            }

            const script = document.createElement('script');
            script.src = 'https://js.stripe.com/v3/';
            script.async = true;
            script.onload = () => {
                if (window.Stripe) {
                    stripeInstance.value = window.Stripe(publishableKey);
                    resolve(stripeInstance.value);
                } else {
                    reject(new Error('Stripe failed to load'));
                }
            };
            script.onerror = () => reject(new Error('Failed to load Stripe script'));

            document.head.appendChild(script);
        });
    };

    const fetchSetupUrl = async (data) => {
        return await store.dispatch('stripe/fetchSetupUrl', data);
    }

    return {
        stripeLoading,
        stripeAccounts,
        stripePrices,
        fetchStripeCapabilities,
        fetchCheckoutUrl,
        fetchSetupUrl,
        loadStripe,
        fetchStripeAccounts,
        stripeIdentityName,
        stripeIdentityDOB,
        stripeIdentityStatus,
        stripeIdentityVSId,
        stripeIdentityDocumentNumber,
        stripeIdentityDocumentExpiration,
        stripeIdentityUrl,
        stripeIdentityErrors,
        stripeIdentityAddress,
        fetchSubscriptionDetails,
        fetchStripePriceData,
        saveStripeKey,
        resetStripeKey,
        testStripeIdentity,
    }
}