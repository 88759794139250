import {computed} from 'vue';
import {RouteLocationNormalizedLoaded, useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import checkPermission from '@/utils/permission';
import {AppCurrency} from '@/types';


export function useAppSettings() {
    const route: RouteLocationNormalizedLoaded = useRoute();
    const router = useRouter();
    const store = useStore();

    // State from store
    const roles = computed(() => store.getters['auth/roles']);
    const permissions = computed(() => store.getters['auth/permissions']);
    const setupItems = computed(() => store.getters['app/setupItems']);
    const currentCurrency = computed<AppCurrency>(() => store.getters['app/currentCurrency']);
    const polledBuildNumber = computed(() => store.getters['app/polled_build']);
    const updateAvailable = computed(() => store.getters['app/update_available']);
    const appCurrentRouteName = computed(() => route.name);
    const debugDrawerOpen = computed(() => store.getters['app/debugDrawerOpen']);
    const sidebar = computed(() => store.getters['app/sidebar']);
    const device = computed(() => store.getters['app/device']);
    const browser = computed(() => store.getters['app/browser']);

    const secondaryNavViews = computed(() => store.getters['app/secondaryNavViews']);

    // goBack
    const goBack = (numberOfRoutes: number = 1) => {
        router.go(-numberOfRoutes);
    }

    const isRouteMatch = (routeName: string, condition: () => boolean = () => true): boolean => {
        // Ensure that route.name is a string before calling string methods on it
        if (typeof route.name === 'string') {
            return route.name.startsWith(routeName) && condition();
        }
        return false;
    };

    function shouldSubMenuOpen(pageName: string) {
        const conditions: { [key: string]: () => boolean } = {
            'customers': () => typeof route.name === 'string' && !route.name.includes('customers.import') && !route.name.includes('customers.single'),
            'orders': () => typeof route.name === 'string' && route.name.startsWith('orders.index'),
            'documents': () => typeof route.name === 'string' && route.name.startsWith('documents.index'),
        };

        const condition = conditions[pageName] || (() => true);

        return {
            MainNavigation__open: isRouteMatch(pageName, condition)
        };
    }

    function userHasRole(role: string): boolean {
        return roles.value.includes(role);
    }

    const fetchBuildNumber = async () => {
        return await store.dispatch('app/fetchBuildNumber');
    }

    const toggleSideBar = () => {
        store.dispatch('app/toggleSideBar');
    };

    function userHasPermission(permissions: string[]): boolean {
        return checkPermission(permissions);
    }

    const updateSettings = async (settings: { settings: { [key: string]: any } }) => {
        return await store.dispatch('settings/updateSettings', settings);
    }

    const fetchCurrency = async (currency: string) => {
        return await store.dispatch('app/fetchCurrency', currency);
    }

    const getSettings = async () => {
        return await store.dispatch('settings/getSettings');
    }

    function toggleDebugDrawer() {
        store.dispatch('app/toggleDebugDrawer');
    }

    const appCurrencies: AppCurrency[] = [
        {name: 'British Pound Sterling', code: 'GBP', symbol: '£'},
        {name: 'Canadian Dollar', code: 'CAD', symbol: '$'},
        {name: 'Euro', code: 'EUR', symbol: '€'},
        {name: 'Japanese Yen', code: 'JPY', symbol: '¥'},
        {name: 'Mexican Peso', code: 'MXN', symbol: '$'},
        {name: 'South Korean Won', code: 'KRW', symbol: '₩'},
        {name: 'Turkish Lira', code: 'TRY', symbol: '₺'},
        {name: 'US Dollar', code: 'USD', symbol: '$'},
    ] as AppCurrency[]

    return {
        setupItems,
        debugDrawerOpen,
        appCurrencies,
        currentCurrency,
        roles,
        permissions,
        appCurrentRouteName,
        polledBuildNumber,
        updateAvailable,
        sidebar,
        device,
        browser,
        secondaryNavViews,
        goBack,
        fetchBuildNumber,
        updateSettings,
        getSettings,
        userHasRole,
        userHasPermission,
        shouldSubMenuOpen,
        toggleDebugDrawer,
        fetchCurrency,
        toggleSideBar,
    }
}