import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

export const copyTextToClipboard = async (textToCopy: string) => {
  // Navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    try {
      await navigator.clipboard.writeText(textToCopy);
      console.log('Text copied to clipboard using navigator.clipboard');
    } catch (error) {
      console.error('Failed to copy text using navigator.clipboard:', error);
    }
  } else {
    const isModalActive = () => {
      const modals = document.querySelectorAll('.v-overlay.v-overlay--active.v-dialog');
      for (const modal of modals) {
        if (modal.style.display !== 'none') {
          return modal;
        }
      }
      return false;
    };

    // Check if a modal is active
    const modal = isModalActive();

    const textArea = document.createElement("textarea");

    // Use the 'out of viewport hidden text area' trick
    textArea.value = textToCopy;

    // Move textarea out of the viewport, so it's not visible
    textArea.style.position = 'absolute';
    textArea.style.left = '-9999px';
    textArea.style.top = '0';
    textArea.style.opacity = '0';
    textArea.classList.add('copy-textarea');


    if (modal) {
      const dialog = modal.querySelector('.v-overlay__content');
      if (dialog) {
        dialog.appendChild(textArea);
      } else {
        document.body.prepend(textArea);
      }
    } else {
      document.body.prepend(textArea);
    }

    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        console.log('Text copied to clipboard using document.execCommand');
      } else {
        console.error('Failed to copy text using document.execCommand');
      }
    } catch (error) {
      console.error('Failed to copy text using document.execCommand:', error);
    } finally {
      textArea.remove();
    }
  }
};


export const round = (value: number, decimals: number = 2) => {
  return parseFloat( value.toFixed(decimals) );
}

export const formatNumberWithCommas = (x) => {
  if (x === null || isNaN(Number(x))) {
    return '0';
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};


export const titleCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const makeResizableDiv = (div) => {
  const element = document.querySelector(div);
  const resizers = document.querySelectorAll(`${div} .resizer`);
  const minimum_size = 20;
  let original_width = 0;
  let original_height = 0;
  let original_x = 0;
  let original_y = 0;
  let original_mouse_x = 0;
  let original_mouse_y = 0;
  for (let i = 0; i < resizers.length; i++) {
    const currentResizer = resizers[i];
    // eslint-disable-next-line no-loop-func
    currentResizer.addEventListener('mousedown', (e: MouseEvent) => {
      e.preventDefault();
      original_width = parseFloat(getComputedStyle(element, null).getPropertyValue('width').replace('px', ''));
      original_height = parseFloat(getComputedStyle(element, null).getPropertyValue('height').replace('px', ''));
      original_x = element.getBoundingClientRect().left;
      original_y = element.getBoundingClientRect().top;
      original_mouse_x = e.pageX;
      original_mouse_y = e.pageY;
      window.addEventListener('mousemove', resize);
      window.addEventListener('mouseup', stopResize);
    });

    // eslint-disable-next-line no-inner-declarations,no-loop-func
    function resize(e) {
      if (currentResizer.classList.contains('bottom-right')) {
        const width = original_width + (e.pageX - original_mouse_x);
        const height = original_height + (e.pageY - original_mouse_y);
        if (width > minimum_size) {
          element.style.width = `${width}px`;
        }
        if (height > minimum_size) {
          element.style.height = `${height}px`;
        }
      } else if (currentResizer.classList.contains('bottom-left')) {
        const height = original_height + (e.pageY - original_mouse_y);
        const width = original_width - (e.pageX - original_mouse_x);
        if (height > minimum_size) {
          element.style.height = `${height}px`;
        }
        if (width > minimum_size) {
          element.style.width = `${width}px`;
          element.style.left = `${original_x + (e.pageX - original_mouse_x)}px`;
        }
      } else if (currentResizer.classList.contains('top-right')) {
        const width = original_width + (e.pageX - original_mouse_x);
        const height = original_height - (e.pageY - original_mouse_y);
        if (width > minimum_size) {
          element.style.width = `${width}px`;
        }
        if (height > minimum_size) {
          element.style.height = `${height}px`;
          element.style.top = `${original_y + (e.pageY - original_mouse_y)}px`;
        }
      } else {
        const width = original_width - (e.pageX - original_mouse_x);
        const height = original_height - (e.pageY - original_mouse_y);
        if (width > minimum_size) {
          element.style.width = `${width}px`;
          element.style.left = `${original_x + (e.pageX - original_mouse_x)}px`;
        }
        if (height > minimum_size) {
          element.style.height = `${height}px`;
          element.style.top = `${original_y + (e.pageY - original_mouse_y)}px`;
        }
      }
    }

    // eslint-disable-next-line no-inner-declarations
    function stopResize() {
      window.removeEventListener('mousemove', resize);
    }
  }
};


export const adjustDurationForDaylightSavings = (dates) => {
  const getFirstSunday = (month, year) => {
    let date = dayjs(`${year}-${month + 1}-01`);
    while (date.day() !== 0) {
      date = date.add(1, 'day');
    }
    return date;
  };

  const dstStart = getFirstSunday(2, dayjs(dates.start).year()); // March
  const dstEnd = getFirstSunday(10, dayjs(dates.end).year()); // November

  if (dayjs(dates.start).isBefore(dstStart) && dayjs(dates.end).isAfter(dstStart)) {
    return 1;
  }
  if (dayjs(dates.start).isBefore(dstEnd) && dayjs(dates.end).isAfter(dstEnd)) {
    return -1;
  }

  return 0;
};

export const dragElement = (element: HTMLElement) => {
  let pos1 = 0;
  let pos2 = 0;
  let pos3 = 0;
  let pos4 = 0;
  if (document.getElementById(`${element.id}header`)) {
    // if present, the header is where you move the DIV from:
    document.getElementById(`${element.id}header`).onmousedown = dragMouseDown;
  } else {
    // otherwise, move the DIV from anywhere inside the DIV:
    element.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    element.style.top = `${element.offsetTop - pos2}px`;
    element.style.left = `${element.offsetLeft - pos1}px`;
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  }
};
