import {Store} from 'vuex';
import {RootState} from '@/types/RootState';


const debug: boolean = true;

export default async ({store}: { store: Store<RootState> }): Promise<boolean> => {
    console.log('%c -- MIDDLEWARE -- CHECK AUTH ✅', 'color: white;background: green; padding: 2px; font-weight: bold;');


    const userLoaded = store.getters['auth/userLoaded'];
    const token = store.getters['auth/token'];
    const user = store.getters['auth/user'];

    console.dir('userLoaded');
    console.dir(userLoaded);

    if (debug) {
        console.log('%c       \\/     ', 'color: white;background: red; padding: 2px');
        console.dir(store);
        console.dir(`userLoaded: ${userLoaded}`);
        console.dir(`token: ${token}`);
        console.dir(user);
        console.dir(user.email);

        console.table({
            userLoaded,
            token: token ?? 'No Token',
            user: user ? user.email ?? 'User Object Exists' : 'No User',
        });
        console.log('%c       /\\     ', 'color: white;background: red; padding: 2px');
    }


    if (!userLoaded) {
        try {
            console.log('%c Fetching user data...', 'color: yellow; font-weight: bold;');
            await store.dispatch('auth/fetchUser');
            console.log('%c ✅ User data fetched successfully!', 'color: lime; font-weight: bold;');
        } catch (error) {
            console.error('%c ❌ Error fetching user:', 'color: red; font-weight: bold;', error);
        }
    }

    return true;
}