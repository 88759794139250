import {useStore} from 'vuex';
import {computed} from 'vue';
import {StoreLoaders} from '@/types';

export function useAuth() {
    const store = useStore();

    const authLoading = computed<StoreLoaders>(() => store.getters['auth/loading']);

    const authForm = computed(() => store.getters['auth/authForm']);
    const apiTokens = computed(() => store.getters['auth/apiTokens']);
    const user = computed(() => store.getters['auth/user']);
    const masquerade = computed(() => store.getters['app/masquerade']);

    const saveToken = (payload: { token: string }) => {
        return store.dispatch('auth/saveToken', payload);
    }

    const checkStripe = async () => {
        return await store.dispatch('auth/checkStripe');
    }

    const setAuthLoading = (payload: { subject: string, loading: boolean }) => {
        return store.commit('auth/SET_AUTH_LOADING', payload);
    }

    const updateUserPassword = (payload: { password: string, newPassword: string }) => {
        return store.dispatch('auth/updateUserPassword', payload);
    }

    const registrationFormRules = computed(() => ({
        required: (value: string) => !!value || 'Required',
        min: (v: string) => v.length >= 8 || 'Min of 8 characters',
        email: [
            (v: string) => !!v || 'E-mail is required',
            (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be a valid e-mail',
        ],
        code: [
            (v: string) => !!v || '2FA code is required',
            (v: string) => v.length >= 4 || 'Should be 4 characters',
        ],
    }));

    const masqueradeEnd = () => {
        return store.dispatch('app/masqueradeEnd');
    }

    const logout = () => {
        return store.dispatch('auth/logout');
    }

    const createUserTokens = () => {
        return store.dispatch('auth/createUserTokens');
    }
    const deleteApiToken = (payload) => {
        return store.dispatch('auth/deleteApiToken', payload);
    }

    const fetchSession = () => {
        return store.dispatch('auth/fetchSession');
    }

    const fetchUserTokens = () => {
        return store.dispatch('auth/fetchApiTokens');
    }

    const start2FAVerify = async (payload: { code?: string, phone: string, channel: string }) => {
        try {
            return await store.dispatch('auth/start2FAVerify', payload);
        } catch (error) {
            throw error;
        }
    }

    const check2FAVerify = async (payload: { code: string, phone: string, disable: boolean }) => {
        try {
            return await store.dispatch('auth/check2FAVerify', payload);
        } catch (error) {
            throw error;
        }
    }

    return {
        user,
        masquerade,
        authLoading,
        authForm,
        apiTokens,
        registrationFormRules,
        setAuthLoading,
        checkStripe,
        saveToken,
        logout,
        masqueradeEnd,
        createUserTokens,
        deleteApiToken,
        fetchUserTokens,
        fetchSession,
        updateUserPassword,
        start2FAVerify,
        check2FAVerify
    }
}