import { find, get } from 'lodash';
import elFactory from './elFactory.service';
import UtilService from './util.service';
import { CartService } from '@/services/cart.service';
import ApiService from './api.service';
import ListService from './list.service';
import dayjs from 'dayjs';
import { easepick } from '@easepick/core';
import { RangePlugin } from '@easepick/range-plugin';

const COMPANY_URL = window.fleetwireOptions?.apiURL;


const timeSelect = `
<div class="fw-time-select-outer" id="fw-time-selector">
  <div class="Select__menu fw-time-select-inner">
    <div class="Select__menu-list css-o6rls7">
      <div class="Select__option Select__option--is-focused css-jt7bxz-option" id="react-select-2-option-0" tabindex="-1">12:00 AM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-1" tabindex="-1">01:00 AM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-2" tabindex="-1">02:00 AM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-3" tabindex="-1">03:00 AM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-4" tabindex="-1">04:00 AM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-5" tabindex="-1">05:00 AM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-6" tabindex="-1">06:00 AM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-7" tabindex="-1">07:00 AM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-8" tabindex="-1">08:00 AM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-9" tabindex="-1">09:00 AM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-10" tabindex="-1">10:00 AM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-11" tabindex="-1">11:00 AM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-12" tabindex="-1">12:00 PM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-13" tabindex="-1">01:00 PM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-14" tabindex="-1">02:00 PM</div>
      <div class="Select__option Select__option--is-selected css-r84qo7-option" id="react-select-2-option-15" tabindex="-1">03:00 PM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-16" tabindex="-1">04:00 PM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-17" tabindex="-1">05:00 PM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-18" tabindex="-1">06:00 PM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-19" tabindex="-1">07:00 PM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-20" tabindex="-1">08:00 PM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-21" tabindex="-1">09:00 PM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-22" tabindex="-1">10:00 PM</div>
      <div class="Select__option css-zhyjg9-option" id="react-select-2-option-23" tabindex="-1">11:00 PM</div>
    </div>
  </div>
</div>
`;


const DatepickerService = {

  data: {
    dialog: false,
    times: {
      start: null,
      end: null,
    },
  },

  prepDatepicker() {
    const datepicker = Array.from(document.getElementsByClassName('fleetwire-datepicker'));

    if (datepicker.length) {
      const markup = DatepickerService.buildDatepicker();

      markup.onclick = function () {
        DatepickerService.openDatepicker();
      };

      datepicker[0].appendChild(markup)
    }
  },

  buildDatepicker() {
    return elFactory('div', { class: `fleetwire-datepicker` },
      elFactory('div', { class: 'fleetwire-themed' },
        elFactory('div', { class: 'fleetwire-component' },
          elFactory('div', { class: 'fleetwire-datetime-wrapper' },
            elFactory('div', { class: 'fleetwire-datetime-container fleetwire-datetime-from' },
              elFactory('div', { class: 'fleetwire-datetime-from-detail' },
                elFactory('p', { class: 'fleetwire-datetime-placeholder fw-pickup' }, 'Pickup Date')
              )
            ),
            elFactory('div', { class: 'fleetwire-datetime-container fleetwire-datetime-till' },
              elFactory('div', { class: 'fleetwire-datetime-till-detail' },
                elFactory('p', { class: 'fleetwire-datetime-placeholder fw-return' }, 'Return Date')
              )
            ),
          )
        )
      )
    );
  },

  setCartDates(cart) {
    console.log('cart');
    console.log(cart);
    const pickup = Array.from(document.getElementsByClassName('fw-pickup'))
    const dropOff = Array.from(document.getElementsByClassName('fw-return'))


    if (pickup.length) {
      pickup[0].innerHTML = dayjs(get(cart, 'trip_start')).format('MMM DD, YYYY, hh:mm A');
      this.data.times.start = get(cart, 'trip_start');
    }

    if (dropOff.length) {
      dropOff[0].innerHTML = dayjs(get(cart, 'trip_end')).format('MMM DD, YYYY, hh:mm A');
      this.data.times.end = get(cart, 'trip_end');
    }
  },

  openDatepicker() {
    const markup = elFactory('div', { class: `fleetwire-datepicker-backdrop` },
      elFactory('div', { class: `fleetwire-datepicker-container fleetwire-modal-hide` },
        elFactory('h2', { class: `fleetwire-datepicker-close` },
          elFactory('i', { class: `fleetwire-datepicker-close-icon` }, 'X')
        ),
        elFactory('div', { class: `fleetwire-modal-form-container` },
          elFactory('form', { class: `` },
            elFactory('div', { class: `` },
              elFactory('div', { class: `picker-form` },
                elFactory('div', { class: `picker-block` },
                  elFactory('h3', { class: `picker-title` }, 'Pickup'),
                  elFactory('div', { class: `picker-spacer` }),
                  DatepickerService.buildModalFormInput(),
                ),
                elFactory('div', { class: `picker-spacer-main` }),
                elFactory('div', { class: `picker-block` },
                  elFactory('h3', { class: `picker-title` }, 'Return'),
                  elFactory('div', { class: `picker-spacer` }),
                  DatepickerService.buildModalFormInput('end'),
                ),
              ),
            )
          )
        ),
        elFactory('div', { class: `fleetwire-modal-actions` },
          elFactory('div', { class: `fleetwire-modal-actions-group` },
            elFactory('span', { class: `fleetwire-modal-actions-button-white` },
              elFactory('button', { class: `fleetwire-modal-actions-button-close`, type: 'button' }, 'Cancel')
            ),
            elFactory('span', { class: `fleetwire-modal-actions-button-blue` },
              elFactory('button', { class: ``, type: 'button', color: '#2B80FF' }, 'Apply')
            )
          )
        )
      ),
    );

    markup.onclick = function (event) {
      DatepickerService.removeDatepicker(event);
    };

    document.body.appendChild(markup);

    if (this.data.times.start) {
      const startValue = document.getElementById('start-datepicker');
      const startTimeValue = document.getElementById('start-placeholder');
      startValue.value = dayjs(this.data.times.start).format('YYYY-MM-DD');
      startTimeValue.innerText = dayjs(this.data.times.start).format('hh:mm A');
    }

    if (this.data.times.end) {
      const endValue = document.getElementById('end-datepicker');
      const endTimeValue = document.getElementById('end-placeholder');
      endValue.value = dayjs(this.data.times.end).format('YYYY-MM-DD');
      endTimeValue.innerText = dayjs(this.data.times.start).format('hh:mm A');
    }

    setTimeout(() => {
      const datepicker = Array.from(document.getElementsByClassName('fleetwire-datepicker-container'))

      if (datepicker.length) {
        datepicker[0].classList.remove('fleetwire-modal-hide');
        datepicker[0].classList.add('fleetwire-modal-show');

        document.body.classList.add('fw-modal-open');


        new easepick.create({
          element: document.getElementById('start-datepicker'),
          css: [
            'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
          ],
          plugins: [RangePlugin],
          zIndex: 999999999,
          setup(picker) {
            picker.on('select', (e) => {
              const { start, end } = e.detail;
              // let startDate = start.getFullYear() + '-' + ((start.getMonth() > 8) ? (start.getMonth() + 1) : ('0' + (start.getMonth() + 1))) + '-' + ((start.getDate() > 9) ? start.getDate() : ('0' + start.getDate())) + ' ' + UtilService.padTo2Digits(start.getHours()) + ':' + UtilService.padTo2Digits(start.getMinutes()) + ':' + UtilService.padTo2Digits(start.getSeconds());
              const startDate = dayjs(start).format('YYYY-MM-DD HH:mm:ss');
              // let endDate = end.getFullYear() + '-' + ((end.getMonth() > 8) ? (end.getMonth() + 1) : ('0' + (end.getMonth() + 1))) + '-' + ((end.getDate() > 9) ? end.getDate() : ('0' + end.getDate())) + ' ' + UtilService.padTo2Digits(end.getHours()) + ':' + UtilService.padTo2Digits(end.getMinutes()) + ':' + UtilService.padTo2Digits(end.getSeconds());
              const endDate = dayjs(end).format('YYYY-MM-DD HH:mm:ss');
              console.dir(start);
              console.dir(startDate);
              console.dir(endDate);

              DatepickerService.setDatePickerValues(startDate, endDate);
              DatepickerService.checkAvailability(startDate, endDate);
            });
          },
          RangePlugin: {
            elementEnd: "#end-datepicker",
            tooltip: false,
          },
        });
      }
    }, 50)
  },

  buildModalFormInput(period = 'start') {
    const timeSelectMarkup = elFactory('div', { class: `Select__value-container css-1hwfws3` },
      elFactory('div', {
        class: `Select__placeholder css-1wa3eu0-placeholder ${period}-placeholder`,
        id: `${period}-placeholder`
      }, 'Select...'),
    );

    timeSelectMarkup.onclick = function (event) {
      DatepickerService.addTimeSelector(event, period);
    };

    return elFactory('div', { class: `picker-input` },
      elFactory('div', { class: `BOxSN` },
        elFactory('div', { class: `InputField-ivJGtx ` },
          elFactory('div', { style: `width: 100%` },
            elFactory('div', { class: `lgjpsj` },
              elFactory('input', {
                id: `${period}-datepicker`,
                class: `Input-lchiQn dCoejz`,
                name: 'date',
                placeholder: 'MM-DD-YY'
              })
            ),
          ),
        ),
        elFactory('div', { class: `InputField-ivJGtx ` },
          elFactory('div', { style: `width: 100%; height: 100%` },
            elFactory('div', { class: `Container-bnWmgR hjMXLA`, style: 'height: 100%' },
              elFactory('div', { class: `Select--is-disabled css-14jk2my-container`, style: 'height: 100%' },
                elFactory('div', { class: `Select__control Select__control--is-disabled css-1fhf3k1-control` },
                  elFactory('div', { class: `Select__value-container css-1hwfws3` },
                    timeSelectMarkup,
                  ),
                  elFactory('div', { class: `Select__indicators css-1wy0on6` },
                    elFactory('div', { class: `Select__indicator Select__dropdown-indicator css-tlfecz-indicatorContainer` },
                      elFactory('svg', { class: `css-19bqh2r` },
                        elFactory('path', { d: `M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z` }),
                      ),
                    ),
                  ),
                ),
              ),
            ),
          ),
        ),
      ),
    );
  },

  addTimeSelector(event, period) {

    if ($fw('#fw-time-selector').length === 0) {
      $fw(document.body).append(timeSelect);
    }

    $fw('#fw-time-selector').show();

    console.dir("$fw('.fw-time-selector').first()");
    console.dir($fw('#fw-time-selector'));

    if ($fw('#fw-time-selector').length) {
      const picker = $fw($fw('#fw-time-selector')[0])
      console.dir('picker');
      console.dir(picker[0]);
      const top = event.target.getBoundingClientRect().top + window.pageYOffset + 35;
      picker[0].style.left = event.target.getBoundingClientRect().left + 'px';
      picker[0].style.top = top + 'px';
      picker[0].classList.remove('d-none');

      picker[0].onclick = function (event) {
        DatepickerService.selectTime(event, period);
      };
    } else {
      $fw('#fw-time-selector')[0].hide();
      // datepicker.classList.add('d-none')
    }
  },

  closeTimeSelector() {
    const datepicker = document.getElementById('fw-time-selector');

    if (!datepicker.classList.contains('d-none')) {
      datepicker.classList.add('d-none')
    }
  },

  selectTime(event, period) {
    const timePlaceholder = Array.from(document.getElementsByClassName(`${period}-placeholder`))

    if (timePlaceholder.length > 0) {

      timePlaceholder[0].innerHTML = event.target.innerHTML;
      DatepickerService.closeTimeSelector()

      if (this.data.times.start) {
        const date = this.data.times.start.split(' ')[0];
        let time1 = event.target.innerHTML.split(' ')[0];

        if (event.target.innerHTML.split(' ')[1] === 'PM') {
          time1 = (parseInt(time1.split(':')[0]) + 12) + ':' + time1.split(':')[1];
        }

        const time = dayjs(`${date} ${time1}:00`).format('HH:mm:ss');
        this.data.times.start = dayjs(`${date} ${time}`).format('YYYY-MM-DD HH:mm:ss');
        DatepickerService.checkAvailability(this.data.times.start, this.data.times.end);
      }

      if (this.data.times.end) {
        const date = this.data.times.end.split(' ')[0];
        let time1 = event.target.innerHTML.split(' ')[0];

        if (event.target.innerHTML.split(' ')[1] === 'PM') {
          time1 = (parseInt(time1.split(':')[0]) + 12) + ':' + time1.split(':')[1];
        }
        const time = dayjs(`${date} ${time1}:00`).format('HH:mm:ss');
        this.data.times.end = dayjs(`${date} ${time}`).format('YYYY-MM-DD HH:mm:ss');
        DatepickerService.checkAvailability(this.data.times.start, this.data.times.end);
      }
    }
  },

  checkAvailability(start, end) {
    console.log('checkAvailability');
    console.log(start);
    console.log(end);
    console.log('-----');

    if (dayjs(start).format('HH:mm:ss') === '00:00:00') {
      start = dayjs(start).format('YYYY-MM-DD 08:00:00')
    }
    if (dayjs(end).format('HH:mm:ss') === '00:00:00') {
      end = dayjs(end).format('YYYY-MM-DD 08:00:00')
    }


    const cartUuid = CartService.getCartUuid();

    CartService.updateCart(`${COMPANY_URL}/api/v1/carts/${cartUuid}/set-period`, { start, end })
      .then(data => {
        const { cart } = data
        window.fwCart = cart;

        DatepickerService.setCartDates(cart);
      }).catch(e => {
      UtilService.log(e)
    });

    const fwProduct = UtilService.collectFWComponents();

    ApiService.postData(`${COMPANY_URL}/api/v1/listings/availability?start=${start}&end=${end}`)
      .then(data => {
        const { listings } = data
        window.fwListings = listings;


        fwProduct.forEach(lid => {
          const timePlaceholder = Array.from(document.getElementsByClassName(`${lid}-card-main`))

          if (timePlaceholder.length > 0) {
            console.dir('timePlaceholder');
            console.dir('timePlaceholder');
            const cardStatus = timePlaceholder[0].getElementsByClassName(`fw-status`)
            console.dir(cardStatus);

            if (cardStatus.length > 0) {
              const foundListing = find(listings, (listing) => {
                return listing.l_id === lid;
              });


              if (foundListing) {
                if (get(foundListing, 'available.available')) {
                  cardStatus[0].classList.add('fw-available');
                  cardStatus[0].classList.remove('fw-unavailable');
                  cardStatus[0].innerHTML = 'Available';
                  cardStatus[0].classList.remove('fw-d-none');
                } else {
                  cardStatus[0].classList.add('fw-unavailable');
                  cardStatus[0].classList.remove('fw-available');
                  cardStatus[0].innerHTML = 'Unavailable';
                  cardStatus[0].classList.remove('fw-d-none');
                }
              }
            }
          }
        });

        ListService.addListingList()
      }).catch(e => {
      console.dir(e);
    });
  },

  setDatePickerValues(startDate, endDate) {
    document.querySelector('#start-datepicker').value = dayjs(startDate).format('YYYY-MM-DD');
    document.querySelector('#end-datepicker').value = dayjs(endDate).format('YYYY-MM-DD');
  },

  removeDatepicker(event) {
    const datepicker = Array.from(document.getElementsByClassName('fleetwire-datepicker-backdrop'))

    if (datepicker.length
      &&
      (event.target.classList.contains('fleetwire-datepicker-backdrop')
        || event.target.classList.contains('fleetwire-modal-actions-button-close')
        || event.target.classList.contains('fleetwire-modal-actions-button-white'))
    ) {
      DatepickerService.closeTimeSelector();
      const datepickerContainer = Array.from(document.getElementsByClassName('fleetwire-datepicker-container'))
      datepickerContainer[0].classList.remove('fleetwire-modal-show');
      datepickerContainer[0].classList.add('fleetwire-modal-hide');
      document.body.classList.remove('fw-modal-open');
      setTimeout(() => {
        if (datepicker.length) {
          datepicker[0].remove();
        }
      }, 100)
    }
  },

}


export default DatepickerService;