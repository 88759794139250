<template>
  <section class="bg-gray-50 dark:bg-gray-900">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white" href="/">
        <img alt="logo" class="w-8 h-8 mr-2" src="https://tenant.fleetwire.io/images/logo/fleetwire-logo-new.png">
        Fleetwire
      </a>
      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            {{ $t('login') }} {{ $t('to') }} Fleetwire
          </h1>
          <v-form :disabled="form.busy" action="#" class="space-y-4 md:space-y-6" @keydown="form.onKeydown($event)" @submit.prevent="login()">

            <div v-show="twoFactorAuth" class="w-full text-muted mb-4 text-subtitle-1">
              Enter the code we sent over SMS to
              <div>+1 (•••) ••• - ••{{ last2 }}</div>
            </div>

            <div v-if="!twoFactorAuth">
              <v-text-field
                v-model="form.email"
                :autofocus=true
                :label="$t('email')"
                :rules="registerFormRules.email"
                aria-label="Email Address"
                aria-required="true"
                autocomplete="username"
                class="mb-4"
                density="comfortable"
                name="email"
                required
                tabindex="1"
                type="email"
                variant="outlined"
              />

              <v-text-field
                v-model="form.password"
                :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :label="$t('password')"
                :rules="[registerFormRules.required, registerFormRules.min]"
                :type="showPassword ? 'text' : 'password'"
                aria-live="polite"
                aria-pressed="true"
                autocomplete="current-password"
                class="mb-4"
                density="comfortable"
                name="password"
                required
                tabindex="2"
                variant="outlined"
                @click:append-inner="togglePasswordVisibility"
              />

              <div class="flex items-center justify-between">
                <div class="flex items-start">
                  <div class="flex items-center h-5">
                    <input id="remember" v-model="form.remember" aria-describedby="remember" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required type="checkbox">
                  </div>
                  <div class="ml-3 text-sm">
                    <label class="text-gray-500 dark:text-gray-300" for="remember">Remember me</label>
                  </div>
                </div>

                <router-link
                  :to="{ name: 'password.request', query: { email: form.email }}"
                  class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                >
                  {{ $t('forgot_password') }}
                </router-link>
              </div>
            </div>

            <div v-if="twoFactorAuth">
              <v-text-field
                v-model="form.code"
                :label="$t('2FA Code')"
                :rules="registerFormRules.code"
                autocomplete="one-time-code"
                name="code"
                required
                tabindex="3"
                variant="outlined"
              />
            </div>

            <v-btn :disabled="isButtonDisabled" :loading="form.busy" block name="login-btn" size="large" tabindex="4" type="submit">
              Sign in
            </v-btn>
            <p class="text-sm font-light text-gray-500 dark:text-gray-400">
              Don’t have an account yet?
              <router-link :to="{ name: 'register' }" class="font-medium text-primary-600 hover:underline dark:text-primary-500" data-testid=register-link>
                <b>{{ $t('register') }}</b>
              </router-link>
            </p>

            <div>
              <v-alert v-if="loginFail" density="compact" role="alert" type="error" variant="outlined">
                {{ loginFailMessage }}
              </v-alert>
            </div>

          </v-form>

        </div>

      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import {codeRule, emailRule, minLengthRule, requiredRule} from '@/utils/formValidationRules';
import {computed, onBeforeMount, ref, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useAuth} from '@/composables/useAuth';
import Form from 'vform';

const {saveToken} = useAuth();
const route = useRoute();
const router = useRouter();

const form = ref(new Form({
  email: '',
  password: '',
  code: '',
  remember: true,
}));

const showPassword = ref(false);
const loginFail = ref(false);
const loginFailMessage = ref('Email and/or password is incorrect.');
const redirect = ref('');
const twoFactorAuth = ref(false);
const last2 = ref('');

const isFormValid = computed(() =>
  ['email', 'password'].every((field) => form.value[field]?.length)
);

const isButtonDisabled = computed(() => form.value.busy || !isFormValid.value);

const registerFormRules = {
  email: emailRule(),
  min: minLengthRule(8),
  code: codeRule(),
  required: requiredRule,
};

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};

onBeforeMount(() => {
  form.value.email = route.query?.email || route.params?.email || form.value?.email;
});

watch(() => route.query, (newQuery) => {
  if (!newQuery) return;

  const redirectQuery = newQuery.redirect;
  redirect.value = Array.isArray(redirectQuery) ? redirectQuery[0] : redirectQuery ?? undefined;
}, {immediate: true});


const handleLoginResponse = async (data: { last2?: string, access_token: string }) => {
  if (data?.['2fa']) {
    twoFactorAuth.value = true;
    last2.value = data?.last2 ?? 'xx';
    return;
  }

  await saveToken({token: data?.access_token});

  const redirectQuery = route.query.redirect;
  let redirectTo = {path: '/dashboard'};


  if (redirectQuery) {
    try {
      redirectTo = {path: decodeURIComponent(Array.isArray(redirectQuery) ? redirectQuery[0] : redirectQuery)};
    } catch (err) {
      console.warn("Invalid redirect URL:", redirectQuery);
    }
  }

  await router.push(redirectTo).catch(err => {
    console.error("Router navigation error:", err);
  });
};

const login = async () => {
  if (form.value.busy) return;

  loginFail.value = false;
  form.value.busy = true;

  try {
    const {data} = await form.value.post('/v1/auth/login');
    await handleLoginResponse(data);
  } catch (error) {
    handleLoginError(error);
  } finally {
    form.value.busy = false;
  }
};

const handleLoginError = (error: { response: { status: number, data: { error: string } } }) => {
  if (error?.response?.status === 422) {
    loginFailMessage.value = error?.response?.data?.error || 'An unknown error occurred. Please try again.';
    twoFactorAuth.value = false;
    form.value.code = '';
  }
  loginFail.value = true;
};
</script>
