import type {RouteRecordRaw} from 'vue-router';
import {CommonRouteMeta} from '@/types';

const BaseAnalytics = () => import('@/views/dashboard/analytics/BaseAnalytics.vue');
const BaseDashboard = () => import('@/views/dashboard/BaseDashboard.vue');
const ConversionAnalytics = () => import('@/views/dashboard/analytics/Conversion/ConversionAnalytics.vue');
const VehicleAnalytics = () => import('@/views/dashboard/analytics/Vehicle/VehicleAnalytics.vue');

const analyticsRoutes: RouteRecordRaw = {
    path: '/analytics',
    component: BaseDashboard,
    meta: {
        title: 'analytics',
        icon: 'mdi-poll',
        noCache: false,
        isBase: true,
        permissions: ['listings_view'],
        hidden: true,
    } as CommonRouteMeta,
    children: [
        {
            path: '',
            component: BaseAnalytics,
            name: 'analytics.index',
            meta: {title: 'Analytics', icon: 'mdi-poll', noCache: false, isBase: true},
        },
        {
            path: 'conversion',
            component: ConversionAnalytics,
            name: 'analytics.conversion',
            meta: {title: 'Conversion Analytics', icon: 'mdi-poll', noCache: false, isBase: true},
        },
        {
            path: 'vehicle',
            component: VehicleAnalytics,
            name: 'analytics.vehicle',
            meta: {title: 'Vehicle Analytics', icon: 'mdi-poll', noCache: false, isBase: true},
        },
    ],
};

export default analyticsRoutes;
