import axios from 'axios';
import {Form} from '@/plugins/FWForms/index';
import {EventBus} from '@/plugins/eventBus';
import {get} from 'lodash';
import * as types from '../mutation-types';
import {push} from 'notivue';

export interface CustodianState {
    init: boolean;
    loading: Record<string, boolean>;
    form: Form<object>;
    custodian: any;
    invite: any;
    custodians: any[];
    listings: any[];
    bookings: any[];

}

const state: CustodianState = {
    init: false,
    loading: {
        create: false,
        delete: false,
        update: false,
        join: false,
    },
    form: new Form(),
    custodian: null,
    invite: null,
    custodians: [],
    listings: [],
    bookings: [],
};

const getters = {
    init: (state: CustodianState) => state.init,
    loading: (state: CustodianState) => state.loading,
    form: (state: CustodianState) => state.form,
    custodian: (state: CustodianState) => state.custodian,
    custodians: (state: CustodianState) => state.custodians,
    invite: (state: CustodianState) => state.invite,
    listings: (state: CustodianState) => state.listings,
    bookings: (state: CustodianState) => state.bookings,
};

const mutations = {
    [types.SET_CUSTODIAN_LOADING](state: CustodianState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_CUSTODIANS](state: CustodianState, custodians) {
        state.custodians = custodians;
    },
    [types.SET_CUSTODIAN_INVITE](state: CustodianState, invite) {
        state.invite = invite;
    },
    [types.SET_CUSTODIAN_LISTINGS](state: CustodianState, listings) {
        state.listings = listings;
    },
    [types.SET_CUSTODIAN_BOOKINGS](state: CustodianState, bookings) {
        state.bookings = bookings;
    },
};

const actions = {


    /**
     * Get custodian
     */
    join({commit}, custodian_id: string) {
        commit(types.SET_CUSTODIAN_LOADING, {subject: 'join', loading: true});

        axios.get(`/v1/custodian-invite/${custodian_id}`)
            .then(() => {
                EventBus.$emit('custodian-join-success');
            }).catch((e) => {
            EventBus.$emit('custodian-join-failure', get(e, 'response.data.message', 'There was an error'));
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_CUSTODIAN_LOADING', {subject: 'join', loading: false});
        });
    },


    /**
     * Create custodian invite link
     */
    createInvite({commit}, data) {
        commit('SET_CUSTODIAN_LOADING', {subject: 'create', loading: true});

        axios.post('/v1/custodian-invite', data)
            .then((response) => {
                commit('SET_CUSTODIAN_INVITE', get(response, 'data.invite', []));
                EventBus.$emit('custodial-invite-success', get(response, 'data.invite'));
            }).catch((e) => {
            EventBus.$emit('custodian-create-invite-failure', get(e, 'response.data.message', 'There was an error'));
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_CUSTODIAN_LOADING', {subject: 'create', loading: false});
        });
    },

    /**
     * Update custodian
     */
    updateCustodian({commit}, data) {
        commit('SET_CUSTODIAN_LOADING', {subject: 'create', loading: true});

        axios.put(`/v1/custodians/${data.custodian.uuid}`, data)
            .then((response) => {
                commit('SET_CUSTODIAN_INVITE', get(response, 'data.invite', []));
                EventBus.$emit('custodial-invite-success', get(response, 'data.invite'));
            }).catch((e) => {
            EventBus.$emit('custodian-create-invite-failure', get(e, 'response.data.message', 'There was an error'));
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_CUSTODIAN_LOADING', {subject: 'create', loading: false});
        });
    },

    /**
     * Get custodian
     */
    getCustodians({commit}) {
        commit('SET_CUSTODIAN_LOADING', {subject: 'tax_categories', loading: true});

        axios.get('/v1/custodians')
            .then((response) => {
                commit('SET_CUSTODIANS', get(response, 'data.custodians', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_CUSTODIAN_LOADING', {subject: 'tax_categories', loading: false});
        });
    },

    /**
     * Get custodians listings
     */
    getCustodianListings({commit}, custodian) {
        commit('SET_CUSTODIAN_LOADING', {subject: 'tax_categories', loading: true});

        axios.get(`/v1/custodians/${custodian.uuid}/listings`)
            .then((response) => {
                commit('SET_CUSTODIAN_LISTINGS', get(response, 'data.listings', []));
                EventBus.$emit('get-custodian-listings-success', get(response, 'data.listings', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_CUSTODIAN_LOADING', {subject: 'tax_categories', loading: false});
        });
    },

    getCustodianBookings({commit}, options) {
        commit('SET_CUSTODIAN_LOADING', {subject: 'tax_categories', loading: true});

        let qString = '?';
        if (get(options, 'startDate')) {
            qString += `startDate=${get(options, 'startDate')}`;
        }
        if (get(options, 'endDate')) {
            qString += `&endDate=${get(options, 'endDate')}`;
        }


        if (qString === '?') {
            qString = '';
        }

        axios.get(`/v1/custodians/${options.custodian_uuid}/bookings${qString}`)
            .then((response) => {
                commit('SET_CUSTODIAN_BOOKINGS', get(response, 'data.bookings', []));
                EventBus.$emit('get-custodian-bookings-success', get(response, 'data.listings', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_CUSTODIAN_LOADING', {subject: 'tax_categories', loading: false});
        });
    },

    /**
     * Delete custodian
     */
    deleteCustodians({commit}, custodian_uuid) {
        commit('SET_CUSTODIAN_LOADING', {subject: 'delete', loading: true});

        axios.delete(`/v1/custodians/${custodian_uuid}`)
            .then((response) => {
                EventBus.$emit('delete-custodian-success', get(response, 'data.invite'));
            }).catch((e) => {
            EventBus.$emit('custodian-create-invite-failure', get(e, 'response.data.message', 'There was an error'));
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_CUSTODIAN_LOADING', {subject: 'delete', loading: false});
        });
    },

    /**
     * Send custodian invite
     */
    sendInviteEmailLink({commit}, data) {
        commit('SET_CUSTODIAN_LOADING', {subject: 'sending_invite_email', loading: true});

        axios.post(`/v1/custodians/${data.custodian_uuid}/send-invite-email`, data)
            .then((response) => {
                EventBus.$emit('delete-custodian-success', get(response, 'data.invite'));
            }).catch((e) => {
            EventBus.$emit('custodian-create-invite-failure', get(e, 'response.data.message', 'There was an error'));
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_CUSTODIAN_LOADING', {subject: 'sending_invite_email', loading: false});
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
