<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          Pricing Rule
          <v-spacer/>
          <v-chip color="orange text-white" label>Automation</v-chip>
        </v-card-title>

        <v-card-text>
          <v-select
            v-model="rule"
            :items="rules"
            density="compact"
            hide-details
            item-title="label"
            item-value="value"
            label="Rule"
            variant="outlined"
          ></v-select>

          <div v-if="rule === 'raise-lower-prices'" class="mt-5">
            <v-menu open-on-hover>
              <template v-slot:activator="{ props }">
                <v-btn
                  size="x-small"
                  v-bind="props"
                  variant="text"
                >
                  <span class="i-in-place-edit">
                    {{ ruleData['raise-lower-prices'].action }}
                  </span>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-model="ruleData['raise-lower-prices'].action" mandatory>
                  <v-list-item value="raise">
                    <v-list-item-title>Raise</v-list-item-title>
                  </v-list-item>
                  <v-list-item value="lower">
                    <v-list-item-title>Lower</v-list-item-title>
                  </v-list-item>
                </v-list-item>
              </v-list>
            </v-menu>
            listing prices if there are
            <v-menu :close-on-content-click="false" open-on-hover>
              <template v-slot:activator="{ props }">
                <v-btn size="x-small" v-bind="props" variant="text">
                  <span class="i-in-place-edit">
                    {{ ruleData['raise-lower-prices'].booking_cnt }}
                  </span>
                </v-btn>
              </template>

              <v-card>
                <v-card-text>
                  <v-text-field
                    v-model="ruleData['raise-lower-prices'].booking_cnt"
                    label="Booking Count"
                    max="365"
                    min="0"
                    type="number"
                    variant="outlined"
                  ></v-text-field>
                </v-card-text>
              </v-card>
            </v-menu>
            bookings in the
            <v-menu :close-on-content-click="false" open-on-hover>
              <template v-slot:activator="{ props }">
                <v-btn
                  size="x-small"
                  v-bind="props"
                  variant="text"
                >
                  <span class="i-in-place-edit" v-text="ruleData['raise-lower-prices'].timeframe.timeframe_label()"></span>
                </v-btn>
              </template>
              <v-card>
                <v-card-text class="mt-4">
                  <v-radio-group v-model="ruleData['raise-lower-prices'].timeframe.term" inline>
                    <v-radio label="Last" value="last"></v-radio>
                    <v-radio label="Next" value="next"></v-radio>
                  </v-radio-group>
                  <v-text-field
                    v-model="ruleData['raise-lower-prices'].timeframe.value"
                    label="Days"
                    max="365"
                    min="0"
                    type="number"
                    variant="outlined"
                  ></v-text-field>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>

          <div v-if="rule === 'fill-the-gap'" class="mt-1">

            <p class="mt-2">Fill the gap helps you fill in the time between reservations that may incentivize renters to
              book odd or shorter times.</p>

            <div class="text-2xl border-top">
              <v-menu open-on-hover>
                <template v-slot:activator="{ props }">
                  <v-btn
                    style="padding-left: 0; padding-right:0;"
                    v-bind="props"
                    variant="text"
                  >
                  <span class="i-in-place-edit">
                    {{ ruleData['fill-the-gap'].action }}
                  </span>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item v-model="ruleData['fill-the-gap'].action">
                    <v-list-item value="increase">
                      <v-list-item-title>Increase</v-list-item-title>
                    </v-list-item>
                    <v-list-item value="decrease">
                      <v-list-item-title>Decrease</v-list-item-title>
                    </v-list-item>
                  </v-list-item>
                </v-list>
              </v-menu>
              listing prices by
              <v-menu :close-on-content-click="false" open-on-hover>
                <template v-slot:activator="{ props }">
                  <v-btn
                    style="padding-left: 0; padding-right:0;"
                    v-bind="props"
                    variant="text"
                  >
                  <span class="i-in-place-edit">
                    <span v-if="ruleData['fill-the-gap'].mode ==='amount'">$</span>
                    {{ ruleData['fill-the-gap'].value }}
                    <span v-if="ruleData['fill-the-gap'].mode ==='percent'">%</span>
                  </span>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-text>
                    <v-text-field
                      v-model="ruleData['fill-the-gap'].value"
                      label="Amount"
                      max="365"
                      min="0"
                      type="number"
                      variant="outlined"
                    ></v-text-field>
                    <v-radio-group v-model="ruleData['fill-the-gap'].mode" inline>
                      <v-radio label="Percent" value="percent"></v-radio>
                      <v-radio label="Amount" value="amount"></v-radio>
                    </v-radio-group>
                  </v-card-text>
                </v-card>
              </v-menu>
              between reservations
              <v-menu :close-on-content-click="false" open-on-hover>
                <template v-slot:activator="{ props }">
                  <v-btn
                    style="padding-left: 0; padding-right:0;"
                    v-bind="props"
                    variant="text"
                  >
                  <span class="i-in-place-edit">
                    {{ ruleData['fill-the-gap'].time_value }}
                    <span>Days</span>
                  </span>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-text>
                    <v-text-field
                      v-model="ruleData['fill-the-gap'].time_value"
                      label="Amount"
                      max="365"
                      min="0"
                      type="number"
                      variant="outlined"
                    ></v-text-field>
                  </v-card-text>
                </v-card>
              </v-menu>
              out.

              <v-expansion-panels class="mt-3">
                <v-expansion-panel>
                  <v-expansion-panel-title v-slot="{ open }">
                    <div>
                      <div class="col-4">Exclusions</div>
                      <div class="col-8">
                        <v-fade-transition leave-absolute>
                          <span v-if="open">Add exclusion rules</span>
                          <span v-else class="float-end">{{ exclusionCount }} exclusion</span>
                        </v-fade-transition>
                      </div>
                    </div>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <v-checkbox
                      v-model="ruleData['fill-the-gap'].exclusions.weekends.on"
                      density="compact"
                      hide-details
                      label="Weekends"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="ruleData['fill-the-gap'].exclusions.holidays.on"
                      density="compact"
                      hide-details
                      label="Holidays"
                    ></v-checkbox>
                    <div>
                      <v-checkbox
                        v-model="ruleData['fill-the-gap'].exclusions.listings.on"
                        density="compact"
                        hide-details
                        label="These Listings"
                      ></v-checkbox>
                      <div v-if="ruleData['fill-the-gap'].exclusions.listings.on" class="mt-3 pl-10">
                        <v-autocomplete
                          v-model="ruleData['fill-the-gap'].exclusions.listings.listings"
                          :items="filteredListings"
                          chips
                          density="compact"
                          hide-details
                          label="Excluded Listings"
                          multiple
                        ></v-autocomplete>
                        <ListingAutocompleteDropdown
                          :allow-multiple="true"
                          :allow-select-all="true"
                          @selectedListing="listingSelected($event)"
                        ></ListingAutocompleteDropdown>
                      </div>
                    </div>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-btn size="small" variant="text">Example</v-btn>
          <v-spacer/>
          <v-btn color="grey" variant="text" @click="dialog = false">Cancel</v-btn>
          <v-btn color="primary" variant="text" @click="saveRuleInit()">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/eventBus';
import { mapActions, mapGetters } from 'vuex';

const ListingAutocompleteDropdown = () => import('@/components/dashboard/listings/ListingAutocompleteDropdown.vue');

export default {
  name: 'PricingRuleDialog',

  components: {
    ListingAutocompleteDropdown,
  },

  data() {
    return {
      snack: false,
      dialog: false,
      rule: 'fill-the-gap',
      rules: [
        {
          label: 'Raise/lower prices',
          value: 'raise-lower-prices',
          data: {
            action: 'raise',
            booking_cnt: 0,
          },
        },
        {
          label: 'Fill the Gap',
          value: 'fill-the-gap',
        },
      ],
      ruleData: {
        'fill-the-gap': {
          action: 'decrease',
          value: 10,
          mode: 'percent',
          time_value: 14,
          exclusions: {
            weekends: {
              on: false,
            },
            holidays: {
              on: false,
            },
            specials: {
              on: false,
            },
            listings: {
              on: false,
              listings: [],
            },
            gap_max: {
              on: false,
              value: 1,
            },
          },
          excluded_listings: [],
        },
        'raise-lower-prices': {
          action: 'raise',
          booking_cnt: 0,
          timeframe: {
            term: 'next',
            value: 0,
            unit: 'day',
            unit_label: () => (this.ruleData['raise-lower-prices'].timeframe.value === '1' ? this.ruleData['raise-lower-prices'].timeframe.unit : `${this.ruleData['raise-lower-prices'].timeframe.unit}s`),
            timeframe_label: () => (`${this.ruleData['raise-lower-prices'].timeframe.term} ${this.ruleData['raise-lower-prices'].timeframe.value} ${this.ruleData['raise-lower-prices'].timeframe.unit_label()}`),
            label: () => `${this.ruleData['raise-lower-prices'].timeframe.value} ${this.ruleData['raise-lower-prices'].timeframe.unit_label()}`,
          },
        },
      },
    };
  },

  created() {
    EventBus.$on('open-pricing-rule-dialog', () => {
      this.dialog = true;
    });

    this.fetchListings();
  },

  computed: {
    ...mapGetters({
      pricingRules: 'pricing/pricingRules',
      allListings: 'listing/allListings',
    }),
    exclusionCount() {
      if (!this.ruleClone) {
        return 0;
      }
      return Object.values(this.ruleClone.exclusions).filter((v) => v.on).length;
    },
    filteredListings() {
      return this.allListings;
    },
  },

  methods: {
    ...mapActions({
      fetchListings: 'listing/fetchListings',
      getPricingRules: 'pricing/getPricingRules',
      createPricingRule: 'pricing/createPricingRule',
    }),
    listingSelected(listings) {
      this.ruleData['fill-the-gap'].exclusions.listings.listings = listings.map((l) => l.l_id);
    },
    saveRuleInit() {
      this.createPricingRule({
        rule: this.rule,
        data: this.ruleData[this.rule],
      }).finally(() => {
        this.getPricingRules();
        this.dialog = false;
      });
    },
  },
};
</script>

<style lang="scss">
.i-in-place-edit {
  font-weight: bold;
  cursor: text;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

.i-in-place-edit:hover {
  text-decoration: none;
  border-bottom: 2px dotted var(--primary);
}
</style>
