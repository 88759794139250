import {computed, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {
    BookingInterface,
    CompanyInterface,
    DraftListingInterface,
    ListingInterface,
    TripExtra,
    VehicleClass,
    VehicleDetail,
    VehicleImageInterface
} from '@/types';
import {DailyPrice} from '@/store/modules/listing';
import {snakeCase} from '@/utils/text';
import {getMetaBooleanValue} from '@/utils/meta';
import axios from 'axios';
import {push} from 'notivue';

const VEHICLE_STATUSES = ['available', 'dirty', 'out of service', 'for sale', 'sold', 'new', 'reserved', 'rental', 'maintenance', 'other'];

const mapToTitleCodeArray = (array: string[]) =>
    array.map(item => ({
        title: item.charAt(0).toUpperCase() + item.slice(1).toLowerCase(),
        code: snakeCase(item),
    }));

export function useListing(initialListing?: ListingInterface) {
    const store = useStore();
    const listing = ref<ListingInterface>(initialListing || null);
    const isLoading = ref(false);
    const errors = ref<Record<string, string[]>>({});

    // Computed property to check if listing is loaded
    const isLoaded = computed(() => !!listing.value);

    // Watch for changes in the store's listing if we're working with the current listing
    if (!initialListing) {
        watch(
            () => store.state.listing.listing,
            (newListing) => {
                if (newListing) {
                    listing.value = {...newListing};
                }
            },
            {immediate: true}
        );
    }

    const initializeWithCurrentListing = () => {
        const currentListing = store.getters['listing/currentListing'];

        if (currentListing) {
            listing.value = {...currentListing};
        }
    };

    const updateListing = async (updates: Partial<ListingInterface>) => {
        console.dir('updateListing');
        console.dir(updates);
        console.dir(listing.value);
        if (!listing.value?.l_id) return;

        console.dir(updates);

        isLoading.value = true;
        errors.value = {};

        try {
            const response = await axios.patch(`/v1/listings/${listing.value.l_id}`, updates);

            // Check if the response has an error status
            if (response.status >= 400) {
                throw new Error(response.data.message || 'Update failed');
            }

            // Only update and show success if we get here (no errors)
            listing.value = {...listing.value, ...response.data?.listing};

            // Update store if this is the current listing
            if (store.getters['listing/currentListing']?.l_id === listing.value.l_id) {
                store.commit('listing/SET_CURRENT_LISTING', listing.value);
            }

            push.success({
                message: 'Listing updated successfully!',
            });
        } catch (error) {
            // Handle any type of error (validation, network, etc)
            const errorData = error?.response?.data;
            errors.value = errorData?.errors || {};

            push.error({
                message: errorData?.message || error.message || 'Failed to update listing',
            });
        } finally {
            isLoading.value = false;
        }
    };

    const updateField = async (path: string, value: any) => {
        const updates: Record<string, any> = {};
        updates[path] = value;
        await updateListing(updates);
    };

    const resetListing = () => {
        if (initialListing) {
            listing.value = {...initialListing};
        } else if (store.state.listing.listing) {
            listing.value = {...store.state.listing.listing};
        }
        errors.value = {};
    };

    const availableVehicleStatuses = ref(mapToTitleCodeArray(VEHICLE_STATUSES));

    const listingInit = computed(() => store.getters['listing/listingInit']);
    const listings = computed<ListingInterface[]>(() => store.getters['listing/allListings']);
    const listingLoading = computed(() => store.getters['listing/listingLoading']);
    const listingForm = computed(() => store.getters['listing/form']);
    const currentListing = computed<ListingInterface>(() => store.getters['listing/currentListing']);
    const originalListing = computed<ListingInterface>(() => store.getters['listing/originalListing']);
    const currentListingBookings = computed<BookingInterface[]>(() => store.getters['listing/currentListingBookings']);
    const dailyPricing = computed(() => store.getters['listing/dailyPricing']);
    const company = computed<CompanyInterface>(() => store.getters['company/company']);
    const listingSnacks = computed(() => store.getters['listing/listingSnacks']);
    const allVehicleFeatures = computed(() => store.getters['listing/allVehicleFeatures']);
    const allListingsInit = computed(() => store.getters['listing/allListingsInit']);
    const fleetResourceListings = computed(() => store.getters['listing/fleetResourceListings']);
    const visibleListings = computed(() => store.getters['listing/visibleListings']);
    const currentListingDailyPricing = computed<DailyPrice[]>(() => store.getters['listing/dailyPricing']);
    const listingExtras = computed<TripExtra[]>(() => store.getters['listing/listingExtras']);

    const listingDialogs = computed(() => store.getters['listing/dialogs']);


    const setListingDialog = (topic: string, value: boolean = true) => {
        store.commit('listing/SET_LISTING_DIALOG', {topic, value});
    }

    const allListingsAreArchived = computed(() => {
        return listings.value.every((listing) => listing.archived_at);
    });

    const setUnavailablePeriod = async (payload: { l_id: string, start: string, end: string }) => {
        return await store.dispatch('listing/setUnavailablePeriod', payload);
    }

    const currentListingVehicleOdometer = computed({
        get: () => currentListing.value?.vehicle?.last_odometer_reading,
        set: (value) => {
            store.commit('listing/SET_LISTING_VEHICLE', {
                last_odometer_reading: value,
            });
        }
    });

    const currentListingVehicleStatus = computed({
        get() {
            return currentListing.value?.vehicle?.status || '';
        },
        set(value) {
            store.commit('listing/SET_EXISTING_LISTING_DATA', {
                vehicle: {
                    status: value,
                },
            });
        },
    });

    const currentListingVehicleId = computed(() => currentListing.value?.vehicle?.v_id);

    const resetCurrentListing = () => {
        store.commit('listing/RESET_CURRENT_LISTING');
    }

    const fetchDailyPrices = async (payload: { l_id: string, start: string, end: string }) => {
        return await store.dispatch('listing/fetchDailyPrices', payload);
    }

    const fetchCompanySingleListingExtras = async (listingId: string) => {
        return await store.dispatch('listing/fetchCompanySingleListingExtras', listingId);
    }

    const updateVehicleDetails = async (payload: { l_id: string, details: VehicleDetail[] }) => {
        return await store.dispatch('listing/updateVehicleDetails', payload);
    }

    const getVehicleFeatures = async (l_id: string) => {
        return await store.dispatch('listing/getVehicleFeatures', l_id);
    }

    const fetchListings = async () => {
        return await store.dispatch('listing/fetchListings');
    };

    const addListing = async (listing: ListingInterface) => {
        try {
            return await store.dispatch('listing/addListing', listing);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    const setCurrentNewListing = (listing: ListingInterface | DraftListingInterface) => {
        store.commit('listing/SET_CURRENT_NEW_LISTING', listing);
    }

    const allowsSecondaryDriver = computed(() => {
        const listingLevel = currentListing.value?.use_secondary_driver;
        if (!listingLevel) return false;

        const companyLevel = getMetaBooleanValue('settings.security.use_secondary_driver');

        return listingLevel || companyLevel;
    });

    const saveListing = async (listing: ListingInterface) => {
        try {
            return await store.dispatch('listing/saveListing', listing);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
    const fetchCompanySingleListings = async (listingId: string) => {
        return await store.dispatch('listing/fetchCompanySingleListings', listingId);
    }

    const pauseListing = async (payload: { mode: string, listings: string[] }) => {
        return await store.dispatch('listing/pauseListing', payload);
    }
    const deleteListingImage = async (imageId: string) => {
        return await store.dispatch('listing/deleteListingImage', imageId);
    }

    const deleteListing = async (listing: ListingInterface) => {
        return await store.dispatch('listing/deleteListing', listing);
    }

    const archiveListing = async (listing: ListingInterface) => {
        return await store.dispatch('listing/archiveListing', listing);
    }

    const updateListingImageOrder = async (images: any) => {
        return await store.dispatch('listing/updateListingImageOrder', images);
    }

    const fetchFleetResourcesListings = async (refresh = false) => {
        return await store.dispatch('listing/fetchFleetResourcesListings', refresh);
    }

    const getVehicleIncidents = async (listingId: string) => {
        return await store.dispatch('listing/getVehicleIncidents', listingId);
    }

    const resetAndReImportCarShareImages = async (listingId: string) => {
        return await store.dispatch('listing/resetAndReImportCarShareImages', listingId);
    }

    const fetchAgencySingleListingBookings = async (listingId: string) => {
        return await store.dispatch('company/fetchAgencySingleListingBookings', listingId);
    }

    const getListingPublicLink = (listing: ListingInterface): string => {
        if (import.meta.env.MODE === 'production') {
            const base = 'https://' + company.value.slug + '.fleetwire.io';
            return `${base}/rental/reservations?l_id=${listing.l_id}`;
        }

        return `/rental/reservations?l_id=${listing.l_id}&company=${company.value.slug}`;
    }

    const listingVehicleStatus = (listing: ListingInterface) => {
        const status = listing?.vehicle?.status;
        if (!status) return status;
        const theStatus = availableVehicleStatuses.value.find(s => s.code === status);
        return theStatus?.title;
    }

    const listingNameDisplay = (listing: ListingInterface | { name: string }, characterLimit: number = -1): string => {
        const name = listing?.name || '';

        return characterLimit > 0 ?
            `${name.substring(0, characterLimit)}${name.length > characterLimit ? '...' : ''}`
            : name;
    }

    const currentListingAgeRequirements = computed(() => {
        return currentListing.value?.age_requirements || [];
    });

    const currentListingName = computed({
        get: () => currentListing.value?.name || '',
        set: (value: string) => {
            store.commit('listing/SET_EXISTING_LISTING_DATA', {
                name: value,
            });
        }
    });

    const currentListingVehicleUnitId = computed({
        get: () => currentListing.value?.vehicle?.unit_id || '',
        set: (value: string) => {
            store.commit('listing/SET_EXISTING_LISTING_DATA', {
                vehicle: {
                    unit_id: value,
                },
            });
        }
    });

    const currentListingDescription = computed({
        get: () => currentListing.value?.description?.replace(/[\r\n]+/g, '<br />') || '',
        set: (value: string) => {
            store.commit('listing/SET_EXISTING_LISTING_DATA', {
                description: value,
            });
        }
    });

    const currentListingVehicleVin = computed({
        get: () => currentListing.value?.vehicle?.vin || '',
        set: (value: string) => {
            store.commit('listing/SET_VEHICLE_VIN', value.toLocaleUpperCase());
        }
    });

    const currentListingVehicleYear = computed({
        get: () => currentListing.value?.vehicle?.year || '',
        set: (value: string | number) => {
            store.commit('listing/SET_VEHICLE_YEAR', Number(value));
        }
    });

    const currentVehicleFeatures = computed({
        get: () => store.getters['listing/currentVehicleFeatures'],
        set: (value: string[]) => {
            store.commit('listing/SET_VEHICLE_FEATURES', value);
        }
    });

    const currentListingVehicleMake = computed({
        get: () => currentListing.value?.vehicle?.make || '',
        set: (value: string) => {
            store.commit('listing/SET_VEHICLE_MAKE', value);
        }
    });

    const currentListingVehicleModel = computed({
        get: () => currentListing.value?.vehicle?.model || '',
        set: (value: string) => {
            store.commit('listing/SET_VEHICLE_MODEL', value);
        }
    });

    const currentListingVehicleColor = computed({
        get: () => currentListing.value?.vehicle?.color || '',
        set: (value: string) => {
            store.commit('listing/SET_VEHICLE_COLOR', value);
        }
    });

    const currentListingVehicleNumberOfSeats = computed({
        get: () => currentListing.value?.vehicle?.seats || 5,
        set: (value: number) => {
            store.commit('listing/SET_VEHICLE_SEATS', value);
        }
    });

    const currentListingVehicleNumberOfDoors = computed({
        get: () => currentListing.value?.vehicle?.doors || 4,
        set: (value: number) => {
            store.commit('listing/SET_VEHICLE_DOORS', value);
        }
    })

    const currentListingVehiclePlate = computed({
        get: () => currentListing.value?.vehicle?.plate || '',
        set: (value: string) => {
            store.commit('listing/SET_VEHICLE_PLATE', value);
        }
    });

    const currentListingVehicleClass = computed({
        get: () => currentListing.value?.vehicle?.vehicle_class,
        set: (value: VehicleClass) => {
            store.commit('listing/SET_VEHICLE_CLASS', value);
        }
    })

    const currentListingVehicleClassName = computed(() => currentListingVehicleClass.value?.name);

    const listingVehicleHasRemoteCapability = (listing: ListingInterface) => {
        return listing?.vehicle?.has_remote_capability;
    }

    const listingVehicleHasConnectedBouncie = (listing: ListingInterface) => {
        return listing.vehicle?.matched_bouncie_vehicle;
    }

    const listingVehicleImages = (listing: ListingInterface): VehicleImageInterface[] => {
        return listing?.listing_images ?? [];
    }

    const listingVehicleImage = (listing: ListingInterface): string => {
        const placeHolder = '/images/vehicle-placeholder.jpg';
        const singleListingImage = listing?.listing_image;

        if (singleListingImage) {
            return singleListingImage;
        }

        return (listing?.listing_images?.[0]?.path) ?? placeHolder;
    }

    const listingVehicleDoorsDisplay = (listing: ListingInterface): number => listing?.vehicle?.doors;
    const listingVehicleSeatsDisplay = (listing: ListingInterface): number => listing?.vehicle?.seats;
    const listingVehicleYearMakeModelDisplay = (l: ListingInterface): string => {
        const vehicleProperties = [
            listingVehicleYearDisplay(l),
            listingVehicleMakeDisplay(l),
            listingVehicleModelDisplay(l)
        ];

        return vehicleProperties.filter(Boolean).join(' ');
    }

    const listingDescriptionDisplay = (listing: ListingInterface) => {
        return listing?.description?.replace(/[\r\n]+/g, '<br />') || '';
    }

    const listingClass = (listing: ListingInterface) => {
        return listing?.vehicle?.vehicle_class;
    }

    const listingClassNameDisplay = (listing: ListingInterface): string => {
        return listingClass(listing)?.name || '';
    }

    const listingPeriodDisplay = (listing: ListingInterface): string => listing?.pricing?.price_period || 'Day';
    const listingPriceDisplay = (listing: ListingInterface): number => listing?.pricing?.adjusted_amount || 0;
    const listingVehicleYearDisplay = (listing: ListingInterface) => listing?.vehicle?.year;
    const listingVehicleMakeDisplay = (listing: ListingInterface) => listing?.vehicle?.make;
    const listingVehicleModelDisplay = (listing: ListingInterface) => listing?.vehicle?.model

    const listingVehicleDetailsDisplay = (listing: ListingInterface): VehicleDetail[] => {
        const details = listing?.vehicle?.details ?? [];
        return details.filter(d => d.type === 'feature');
    }

    const currentListingChanges = computed(() => {
        const originalClone = JSON.parse(JSON.stringify(originalListing.value));
        const currentClone = JSON.parse(JSON.stringify(currentListing.value));

        if (!currentClone || !originalClone) return {};

        const listingChanges = {
            l_id: currentClone.l_id,
        };

        const differences = {
            pricing: [],
            age_requirements: [],
            vehicle: [],
        };

        for (const key of Object.keys(originalClone.pricing)) {
            if (originalClone.pricing[key] !== currentClone.pricing[key]) {
                differences.pricing.push(key);
            }
        }
        for (const key of Object.keys(originalClone.age_requirements)) {
            if (originalClone.age_requirements[key] !== currentClone.age_requirements[key]) {
                differences.age_requirements.push(key);
            }
        }
        for (const key of Object.keys(originalClone.vehicle)) {
            if (originalClone.vehicle[key] !== currentClone.vehicle[key]) {
                differences.vehicle.push(key);
            }
        }

        // Iterating and setting values
        for (const [name, data] of Object.entries(differences)) {
            for (const value of data) {
                listingChanges[`${name}.${value}`] = currentClone[name][value];
            }
        }

        // Deleting properties
        const propertiesToDelete = ['pricing', 'bookings', 'listing_images', 'age_requirements', 'commission_partners', 'provider_listings', 'vehicle'];
        for (const prop of propertiesToDelete) {
            delete originalClone[prop];
            delete currentClone[prop];
        }

        // Finding and setting final differences
        for (const key of Object.keys(originalClone)) {
            if (originalClone[key] !== currentClone[key]) {
                listingChanges[key] = currentClone[key];
            }
        }

        return listingChanges;
    });

    /**
     * Match a Provider with a listing
     *
     * */
    const matchAListing = (listing: ListingInterface) => {
        let providerAutoMatchedBy = null;
        let theMatchedListing = null;

        const vin = listing?.vehicle?.vin;
        const unitId = listing?.unit_id;

        if (vin) {
            theMatchedListing = matchByVin(vin);
            if (theMatchedListing) {
                providerAutoMatchedBy = 'vin';
            }
        }
        // If VIN didn't yield a match, try to match by Unit ID
        else if (unitId) {
            theMatchedListing = matchByUnitId(unitId);
            if (theMatchedListing) {
                providerAutoMatchedBy = 'Unit ID';
            }
        }

        if (theMatchedListing) {
            return {providerAutoMatchedBy, theMatchedListing};
        }

        return {providerAutoMatchedBy, theMatchedListing};
    }

    const matchByVin = (workingVin: string): ListingInterface | null => {
        return listings.value.find((l) => {
            const vin = listingVehicleVINDisplay(l);
            return vin && vin.toUpperCase() === workingVin.toUpperCase();
        });
    }

    const matchByUnitId = (workingUnitId: string): ListingInterface | null => {
        return listings.value.find((l) => {
            const unitId = l.vehicle?.unit_id;
            return unitId && unitId.toUpperCase() === workingUnitId.toUpperCase();
        });
    }

    const listingFilter = (itemTitle, queryText: string, item) => {
        const searchText = queryText.toLowerCase();
        const raw = item.raw; // Ensure we're working with `item.raw`

        const keys = [
            'name',
            'l_id',
            ['vehicle', 'unit_id'],
            ['vehicle', 'vin'],
            ['vehicle', 'year'],
            ['vehicle', 'make'],
            ['vehicle', 'model'],
        ];

        return keys.some(key => {
            let value;

            if (Array.isArray(key)) {
                // Traverse nested keys safely
                value = key.reduce((acc, k) => (acc && acc[k] !== undefined ? acc[k] : null), raw);
            } else {
                value = raw[key];
            }

            if (value !== null && value !== undefined) {
                value = String(value).toLowerCase(); // Convert to string before applying `toLowerCase`
                return value.includes(searchText); // Use `includes` instead of `indexOf`
            }

            return false;
        });
    };

    const listingVehicleVINDisplay = (listing: ListingInterface, last4: boolean = false): string => {
        const vin = listing?.vehicle?.vin || '';

        if (!vin) return '';

        return last4 && vin.length >= 4 ? vin.slice(-4) : vin;
    }

    const listingVehicleMatchedTuro = (listing: ListingInterface) => {
        return listing?.vehicle?.matched_turo_vehicle;
    }

    const listingVehicleUnitId = (listing: ListingInterface) => {
        return listing?.vehicle?.unit_id;
    }

    const listingVehicleLicensePlate = (listing: ListingInterface) => {
        return listing?.vehicle?.plate
    }

    const listingVehicleBranch = (listing: ListingInterface) => {
        return listing?.vehicle?.branch;
    }

    return {
        listingDialogs,
        setListingDialog,
        listing,
        isLoading,
        errors,
        isLoaded,
        updateListing,
        updateField,
        resetListing,
        initializeWithCurrentListing,
        listingInit,
        fleetResourceListings,
        listingForm,
        listings,
        listingLoading,
        currentListing,
        originalListing,
        currentListingChanges,
        currentListingBookings,
        dailyPricing,
        listingSnacks,
        allVehicleFeatures,
        currentVehicleFeatures,
        allowsSecondaryDriver,
        allListingsAreArchived,
        currentListingVehicleOdometer,
        currentListingVehicleStatus,
        currentListingVehicleId,
        currentListingName,
        currentListingAgeRequirements,
        currentListingVehicleUnitId,
        currentListingDescription,
        currentListingVehicleVin,
        currentListingVehicleYear,
        currentListingVehicleMake,
        currentListingVehicleModel,
        currentListingVehicleColor,
        currentListingVehicleNumberOfSeats,
        currentListingVehicleNumberOfDoors,
        currentListingVehiclePlate,
        currentListingVehicleClass,
        currentListingVehicleClassName,
        allListingsInit,
        visibleListings,
        currentListingDailyPricing,
        availableVehicleStatuses,
        listingExtras,
        setUnavailablePeriod,
        listingVehicleMatchedTuro,
        listingVehicleUnitId,
        listingVehicleLicensePlate,
        listingVehicleBranch,
        listingVehicleHasConnectedBouncie,
        listingVehicleHasRemoteCapability,
        matchAListing,
        fetchFleetResourcesListings,
        listingVehicleImages,
        listingFilter,
        getListingPublicLink,
        listingVehicleStatus,
        fetchDailyPrices,
        fetchListings,
        pauseListing,
        getVehicleIncidents,
        saveListing,
        resetCurrentListing,
        fetchCompanySingleListings,
        setCurrentNewListing,
        addListing,
        deleteListing,
        archiveListing,
        deleteListingImage,
        updateListingImageOrder,
        resetAndReImportCarShareImages,
        fetchAgencySingleListingBookings,
        listingVehicleImage,
        listingNameDisplay,
        listingVehicleYearDisplay,
        listingVehicleMakeDisplay,
        listingVehicleModelDisplay,
        listingPriceDisplay,
        listingPeriodDisplay,
        listingVehicleDoorsDisplay,
        listingVehicleSeatsDisplay,
        listingVehicleYearMakeModelDisplay,
        listingDescriptionDisplay,
        listingVehicleVINDisplay,
        listingVehicleDetailsDisplay,
        listingClassNameDisplay,
        updateVehicleDetails,
        getVehicleFeatures,
        fetchCompanySingleListingExtras,
    }
}