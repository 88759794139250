import {EventBus} from '@/plugins/eventBus';
import axios from 'axios';
import * as types from '@/store/mutation-types';
import {Form} from '@/plugins/FWForms/index';
import {cloneDeep, get, uniqBy} from 'lodash';
import {StorageService} from '@/services/storage.service';

export interface AutomationsState {
    loading: Record<string, boolean>;
    form: Form<any>;
    automationUnits: any[];
    eventTypes: any[];
    currentEvent: any;
    currentEventOriginal: any;
    messageTemplates: any[];
    events: any[];
    currentPageEvents: any;
    eventsMeta: any;
    eventMeta: any;
    eventLogs: any[];

}

// state
const state: AutomationsState = {
    loading: {
        messageTemplate: false,
        triggered: false,
        upcoming: false,
        other: false,
        update: false,
        cancel: false,
        logs: false,
        sendNow: false,
        delete_automation: false,
        pause_automation: false,
    },
    form: new Form(),
    automationUnits: [
        {
            name: 'Minutes',
            code: 'minutes',
        },
        {
            name: 'Hours',
            code: 'hours',
        },
        {
            name: 'Days',
            code: 'days',
        },
        {
            name: 'Weeks',
            code: 'weeks',
        },
    ],
    eventTypes: [
        {
            label: 'Pre-trip Photos Rule',
            value: 'photos_rule',
            disabled: true,
            comingSoon: true,
        },
        {
            label: 'Booking Request',
            value: 'booking_request',
        },
        {
            label: 'Trip Booked',
            value: 'trip_booked',
        },
        {
            label: 'Start of Trip',
            value: 'start_of_trip',
        },
        {
            label: 'Halfway Point',
            value: 'halfway',
        },
        {
            label: '3/4 Point',
            value: '3_4',
        },
        {
            label: 'End of Trip',
            value: 'end_of_trip',
        },
        {
            label: 'Post-rental',
            value: 'post_rental',
        },
    ],
    currentEvent: {},
    currentEventOriginal: {},
    messageTemplates: [],
    events: [],
    currentPageEvents: {
        upcoming: [],
        triggered: [],
        other: [],
    },
    eventsMeta: {
        current_page: 0,
    },
    eventMeta: {
        upcoming: {
            current_page: 0,
            per_page: 100,
        },
        triggered: {
            current_page: 0,
            per_page: 100,
        },
        other: {
            current_page: 0,
            per_page: 100,
        },
    },
    eventLogs: [],
};

const getters = {
    loading: state => state.loading,
    eventTypes: state => state.eventTypes,
    messageTemplates: state => state.messageTemplates,
    currentEvent: state => state.currentEvent,
    currentEventOriginal: state => state.currentEventOriginal,
    automationUnits: state => state.automationUnits,
    automationForm: state => state.form,
    events: state => state.events,
    currentPageEvents: state => state.currentPageEvents,
    eventsMeta: state => state.eventsMeta,
    eventMeta: state => state.eventMeta,
    eventLogs: state => state.eventLogs,
};

const mutations = {
    [types.SET_AUTOMATION_LOADING](state, loading) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_MESSAGE_TEMPLATES](state, messageTemplates) {
        state.messageTemplates = messageTemplates;
    },
    [types.SET_CURRENT_AUTOMATION_EVENT](state, event) {
        state.currentEventOriginal = cloneDeep(event);
        state.currentEvent = event;
    },
    [types.SET_AUTOMATION_EVENTS](state, events) {
        state.currentPageEvents[events.type] = events.data.data;
        state.events = uniqBy([...state.events, ...events.data.data], w => w.uuid);

        delete events.data.data;
        delete events.data.links;
        state.eventMeta[events.type] = events.data;
    },
    [types.SET_CURRENT_EVENT_LOGS](state, logs) {
        state.eventLogs = logs;
    },
};

const actions = {


    /**
     * Fetch automations
     *
     * @param commit
     */
    fetchAutomations({commit}) {
        axios.get('/v1/automations')
            .then((response) => {
                commit('SET_MESSAGE_TEMPLATES', get(response, 'data.automations', []));
            }).catch(() => {
        }).finally(() => {
        });
    },


    /**
     * Fetch automations events
     *
     * @param commit
     * @param {Object} data
     */
    fetchAutomationsEvents({commit}, data) {
        commit('SET_AUTOMATION_LOADING', {subject: 'upcoming', loading: true});

        const page = get(data, 'page', 1);
        const perPage = get(data, 'perPage', 100);

        axios.get(`/v1/automations-events?type=upcoming&page=${page}&perPage=${perPage}`)
            .then((response) => {
                const data = {
                    data: get(response, 'data.events', []),
                    type: 'upcoming',
                };
                commit('SET_AUTOMATION_EVENTS', data);
            }).catch(() => {
        }).finally(() => {
            commit('SET_AUTOMATION_LOADING', {subject: 'upcoming', loading: false});
        });
    },


    /**
     * Fetch triggered automations events
     *
     * @param commit
     * @param {Object} data
     */
    fetchTriggeredAutomationsEvents({commit}, data) {
        commit('SET_AUTOMATION_LOADING', {subject: 'triggered', loading: true});

        const page = get(data, 'page', 1);
        const perPage = get(data, 'perPage', 100);

        axios.get(`/v1/automations-events?type=triggered&page=${page}&perPage=${perPage}`)
            .then((response) => {
                const data = {
                    data: get(response, 'data.events', []),
                    type: 'triggered',
                };
                commit('SET_AUTOMATION_EVENTS', data);
            }).catch(() => {
        }).finally(() => {
            commit('SET_AUTOMATION_LOADING', {subject: 'triggered', loading: false});
        });
    },

    /**
     * Fetch other automations events
     *
     * @param commit
     * @param {Object} data
     */
    fetchOtherAutomationsEvents({commit}, data) {
        commit('SET_AUTOMATION_LOADING', {subject: 'other', loading: true});

        const page = get(data, 'page', 1);
        const perPage = get(data, 'perPage', 100);

        axios.get(`/v1/automations-events?type=other&page=${page}&perPage=${perPage}`)
            .then((response) => {
                const data = {
                    data: get(response, 'data.events', []),
                    type: 'other',
                };
                commit('SET_AUTOMATION_EVENTS', data);
            }).catch(() => {
        }).finally(() => {
            commit('SET_AUTOMATION_LOADING', {subject: 'other', loading: false});
        });
    },


    /**
     * Update a automation event
     *
     * @param commit
     * @param {Object} event
     */
    updateAutomationEvent({commit}, event) {
        commit('SET_AUTOMATION_LOADING', {subject: 'update', loading: true});

        axios.put(`/v1/company/${StorageService.getCompanyUuid()}/automations-events/${event.uuid}`, event)
            .then((response) => {
                EventBus.$emit('update-automation-event-success', get(response, 'data.event'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_AUTOMATION_LOADING', {subject: 'update', loading: false});
        });
    },


    /**
     * Send an automation event now (override trigger date and send it now)
     *
     * @param commit
     * @param {Object} event
     */
    sendAutomationEventNow({commit}, event) {
        commit('SET_AUTOMATION_LOADING', {subject: 'sendNow', loading: true});

        axios.post(`/v1/automations-events/${event.uuid}/send`)
            .then((response) => {
                EventBus.$emit('send-now-automation-event-success', get(response, 'data.event'));
            }).catch((e) => {
            EventBus.$emit('send-now-automation-event-failure', get(e, 'response.data.message', 'There was an error.'));
        }).finally(() => {
            commit('SET_AUTOMATION_LOADING', {subject: 'sendNow', loading: false});
        });
    },

    /**
     * Cancel a automation event
     *
     * @param commit
     * @param {Object} event
     */
    cancelAutomationEvent({commit}, event) {
        commit('SET_AUTOMATION_LOADING', {subject: 'cancel', loading: true});

        axios.delete(`/v1/company/${StorageService.getCompanyUuid()}/automations-events/${event.uuid}`)
            .then((response) => {
                EventBus.$emit('cancel-automation-event-success', get(response, 'data.event'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_AUTOMATION_LOADING', {subject: 'cancel', loading: false});
        });
    },


    /**
     * Get an automation event logs
     *
     * @param commit
     * @param {Object} event
     */
    getAutomationEventLogs({commit}, event) {
        commit('SET_AUTOMATION_LOADING', {subject: 'logs', loading: true});

        axios.get(`/v1/company/${StorageService.getCompanyUuid()}/automations-events/${event.uuid}/activity-logs`)
            .then((response) => {
                commit('SET_CURRENT_EVENT_LOGS', get(response, 'data.logs'));
                EventBus.$emit('get-automation-event-logs-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_AUTOMATION_LOADING', {subject: 'logs', loading: false});
        });
    },


    /**
     * Add message templates
     *
     * @param state
     * @param commit
     * @param {Object} template
     */
    addMessageTemplates({state, commit}, template) {
        state.form.errors.clear();
        state.form.busy = true;

        axios.post('/v1/automations', template)
            .then((response) => {
                EventBus.$emit('add-message-template-success');
                commit('SET_MESSAGE_TEMPLATES', get(response, 'data.automations', []));
                commit('SET_AUTOMATION_EVENTS', get(response, 'data.events', []));
            }).catch((e) => {
            state.form.errors.set(get(e, 'response.data.errors'));
        }).finally(() => {
            state.form.busy = false;
        });
    },


    /**
     * Save message templates
     *
     * @param state
     * @param commit
     * @param {Object} template
     * @param {String} template.uuid
     */
    saveMessageTemplates({state, commit}, template) {
        state.form.errors.clear();
        state.form.busy = true;

        axios.put(`/v1/automations/${template.uuid}`, template)
            .then((response) => {
                EventBus.$emit('save-message-template-success');
                commit('SET_MESSAGE_TEMPLATES', get(response, 'data.automations', []));
                commit('SET_AUTOMATION_EVENTS', get(response, 'data.events', []));
            }).catch((e) => {
            state.form.errors.set(get(e, 'response.data.errors'));
        }).finally(() => {
            state.form.busy = false;
        });
    },


    /**
     * Delete message template
     *
     * @param commit
     * @param {Array<String>} uuids
     */
    deleteMessageTemplates({commit}, uuids) {
        commit('SET_AUTOMATION_LOADING', {subject: 'delete_automation', loading: true});

        axios.post('/v1/automations/delete', {uuids})
            .then((response) => {
                EventBus.$emit('delete-message-template-success');
                commit('SET_MESSAGE_TEMPLATES', get(response, 'data.automations', []));
                commit('SET_AUTOMATION_EVENTS', get(response, 'data.events', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_AUTOMATION_LOADING', {subject: 'delete_automation', loading: false});
        });
    },

    /**
     * Activate/deactivate message template
     *
     * @param commit
     * @param {Object} data
     */
    pauseMessageTemplates({commit}, data) {
        commit('SET_AUTOMATION_LOADING', {subject: 'pause_automation', loading: true});

        axios.post('/v1/automations/pause', data)
            .then((response) => {
                EventBus.$emit('pause-message-template-success');
                commit('SET_MESSAGE_TEMPLATES', get(response, 'data.automations', []));
                commit('SET_AUTOMATION_EVENTS', get(response, 'data.events', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_AUTOMATION_LOADING', {subject: 'pause_automation', loading: false});
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
