import dayjs from 'dayjs';


export function useDateFormatter() {

    const formatRentalDate = (date: Date | string | number, format: string = 'MMM DD, YYYY @ h:mma'): string => {
        const parsedDate = dayjs(date);
        return parsedDate.isValid() ? parsedDate.format(format) : 'Invalid Date';
    }

    return {
        formatRentalDate,
    }
}
