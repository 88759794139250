<template>
  <div>
    <v-card :loading="vehicleLoading.classes">
      <v-card-title>
        <div class="flex">
          Vehicle Classes
          <v-spacer/>
          <v-btn
            :to="{name: 'settings.fleet.classes.new'}"
            class="text-decoration-none"
            size="small"
          >
            Add Class
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text class="mt-5">
        <!-- Desktop Table View -->
        <div class="hidden md:block">
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th class="px-6 py-3" scope="col">Name</th>
                <th class="px-6 py-3" scope="col">Minimum Age</th>
                <th class="px-6 py-3" scope="col">SIPP Code</th>
                <th class="px-6 py-3" scope="col">Vehicles</th>
                <th class="px-6 py-3" scope="col">Branch</th>
                <th class="px-6 py-3" scope="col">
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(vehicleClass, index) in vehicleClasses"
                  :key="index"
                  class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" scope="row">
                  <router-link :to="{name: 'settings.fleet.classes.single', params: {uuid: vehicleClass.uuid}}" class="underline">
                    {{ vehicleClass.name }}
                  </router-link>
                </th>
                <td class="px-6 py-4">{{ vehicleClass.minimum_age }}</td>
                <td class="px-6 py-4">{{ vehicleClass.sipp_code }}</td>
                <td class="px-6 py-4">{{ vehicleClass.vehicles_count }}</td>
                <td class="px-6 py-4">{{ branchName(vehicleClass) }}</td>
                <td class="px-6 py-4 text-right">
                  <router-link
                    :to="{name: 'settings.fleet.classes.single', params: {uuid: vehicleClass.uuid}}"
                    class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Edit
                  </router-link>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Mobile Card View -->
        <div class="md:hidden grid grid-cols-1 gap-4">
          <div v-for="(vehicleClass, index) in vehicleClasses"
               :key="index"
               class="bg-white dark:bg-gray-800 rounded-lg shadow p-4 hover:shadow-md transition-shadow">
            <div class="flex justify-between items-start mb-3">
              <router-link
                :to="{name: 'settings.fleet.classes.single', params: {uuid: vehicleClass.uuid}}"
                class="text-lg font-medium text-blue-600 dark:text-blue-400 hover:underline">
                {{ vehicleClass.name }}
              </router-link>
              <router-link
                :to="{name: 'settings.fleet.classes.single', params: {uuid: vehicleClass.uuid}}"
                class="text-sm text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200">
                Edit
              </router-link>
            </div>

            <div class="space-y-2 text-sm text-gray-600 dark:text-gray-300">
              <div class="flex justify-between">
                <span class="font-medium">Minimum Age:</span>
                <span>{{ vehicleClass.minimum_age }}</span>
              </div>
              <div class="flex justify-between">
                <span class="font-medium">SIPP Code:</span>
                <span>{{ vehicleClass.sipp_code }}</span>
              </div>
              <div class="flex justify-between">
                <span class="font-medium">Vehicles:</span>
                <span>{{ vehicleClass.vehicles_count }}</span>
              </div>
              <div class="flex justify-between">
                <span class="font-medium">Branch:</span>
                <span>{{ branchName(vehicleClass) }}</span>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts" setup>
import {useStore} from 'vuex';
import {computed, onBeforeMount} from 'vue';
import {VehicleClass} from '@/types';

const {getters, dispatch} = useStore();
const vehicleClasses = computed(() => getters['vehicles/vehicleClasses']);
const vehicleLoading = computed(() => getters['vehicles/vehicleLoading']);

const getVehicleClasses = () => {
  dispatch('vehicles/getVehicleClasses');
};

const branchName = (item: VehicleClass) => {
  return item?.branch?.name;
};


onBeforeMount(() => {
  getVehicleClasses();
});
</script>

