import axios from 'axios';
import {EventBus} from '@/plugins/eventBus';
import {
    checkMasquerade,
    getRegisterId,
    getToken,
    removeAllUserCookies,
    removeToken,
    setRegisterToken,
    setToken
} from '@/utils/auth';
import {Form} from '@/plugins/FWForms/index';
import {CartService} from '@/services/cart.service';
import * as types from '../mutation-types';
import * as Sentry from '@sentry/vue';
import router from '@/router';
import {Module} from 'vuex'
import {ApiToken, CompanyInterface, StoreLoaders} from '@/types';
import {RootState} from '@/types/RootState';
import {performApiRequest} from '@/store/helpers/vuex-helpers';


interface User {
    crisp_email_signature?: string
    email?: string
    email_verified_at?: string | null
    first_name?: string
    last_name?: string
    loaded: boolean
    name?: string
    on_trial?: boolean
    phone?: string
    photo_url?: string
    uuid?: string
    roles: Array<string>
    permissions: Array<string>
    identities: object | undefined
}

export interface AuthState {
    loading: StoreLoaders
    authForm: Form<object>
    user: User
    token: string | null
    registerId: string | null
    roles: Array<string>
    permissions: Array<string>
    notifications: object
    apiTokens: ApiToken[]
}

const state: AuthState = {
    loading: {
        updatePassword: false,
        stripe_oauth: false,
        getApiToken: false,
        createApiToken: false,
        start2FAVerify: false,
        check2FAVerify: false,
    },
    authForm: new Form(),
    user: {
        loaded: false,
        roles: [],
        permissions: [],
        identities: {
            tesla: [],
            turo: {},
        },
        photo_url: '',
    },
    token: getToken() as string,
    registerId: getRegisterId(),
    notifications: {},

    roles: [],
    permissions: [],
    apiTokens: [],
}

function handleCompanyConnections(commit, company: CompanyInterface) {
    const connections = ['getaround', 'turo', 'tesla', 'bouncie', 'outdoorsy', 'hqRentals'];
    connections.forEach(connection => {
        const payload = company?.connections?.[connection] ?? [];

        commit(`${connection}/SET_${connection.toUpperCase()}_CONNECTIONS`, payload, {root: true});
    });
}

const authModule: Module<AuthState, RootState> = {
    namespaced: true,

    state,

    getters: {
        user: (state: AuthState) => state.user,
        token: (state: AuthState) => state.token,
        registerId: (state: AuthState) => state.registerId,
        check: (state: AuthState) => state.user.loaded !== false,
        userLoaded: (state: AuthState) => state.user.loaded,
        roles: (state: AuthState) => state.roles,
        permissions: (state: AuthState) => state.permissions,
        notifications: (state: AuthState) => state.notifications,
        loading: (state: AuthState) => state.loading,
        apiTokens: (state: AuthState) => state.apiTokens,
        authForm: (state: AuthState) => state.authForm,
    },

    actions: {
        saveToken({commit}, payload) {
            commit(types.SAVE_TOKEN, payload);
        },
        saveRegisterId({commit}, id: string) {
            commit(types.SAVE_REGISTER_ID, id);
        },

        async updateUserPassword({commit}, data: { password: string, newPassword: string }) {
            await performApiRequest(commit, 'SET_AUTH_LOADING', 'updatePassword', () => axios.post('/v1/password/change', data));
        },

        /**
         * Start a 2fa flow
         */
        async start2FAVerify({commit}, data: { channel: string, phone: string }) {
            try {
                return await performApiRequest(commit, 'SET_AUTH_LOADING', 'start2FAVerify', () => axios.post('/v1/2fa/start', data));
            } catch (error) {
                EventBus.$emit('SystemMessage', {
                    type: 'error',
                    message: error?.response?.data?.message || 'There was an error',
                });
                throw error;
            }
        },

        /**
         * Verify a 2fa code
         */
        async check2FAVerify({commit, state}, data) {
            commit('SET_AUTH_LOADING', {subject: 'check2FAVerify', loading: true});

            try {
                state.authForm.errors.clear();
                await performApiRequest(commit, 'SET_AUTH_LOADING', 'check2FAVerify', () => axios.post('/v1/2fa/check', data));
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: `2FA now ${data.disable ? 'disabled' : 'enabled'}`,
                });
            } catch (error) {
                state.authForm.errors.set(error?.response?.data?.errors || []);
                EventBus.$emit('SystemMessage', {
                    type: 'error',
                    message: error?.response?.data?.message || 'There was an error',
                });
            }
        },

        async fetchSession({commit, dispatch}) {
            commit('subscriptions/SET_SUBSCRIPTION_LOADING', {subject: 'subscription', loading: true}, {root: true});
            commit('listing/SET_LISTING_LOADING', {subject: 'listings', loading: true}, {root: true});
            commit('company/SET_COMPANY_LOADING', {subject: 'company', loading: true}, {root: true});

            try {
                const response = await axios.get('/v1/session');

                const {
                    subscription,
                    subscriptionMaster,
                    extraListingsAddon,
                    allPermissions,
                    enforceSubscription,
                    fwPublicStripeKey,
                    build,
                    meta,
                    currency,
                    settings,
                    counts,
                    features
                } = response.data;

                const company: CompanyInterface = response.data.company;

                commit('subscriptions/SET_SUBSCRIPTION', subscription, {root: true});
                commit('subscriptions/SET_SUBSCRIPTION_MASTER', subscriptionMaster, {root: true});
                commit('subscriptions/SET_ENFORCE_SUBSCRIPTION', enforceSubscription, {root: true});
                commit('subscriptions/SET_EXTRA_LISTINGS_ADDON', extraListingsAddon, {root: true});
                commit('providers/SET_PAYMENT_PROVIDERS', company.payment_profile, {root: true});
                commit('company/SET_COMPANY', company, {root: true});
                commit('company/SET_COMPANY_DEFAULT_META', meta, {root: true});
                commit('company/SET_COMPANY_CURRENCY', currency, {root: true});
                commit('company/SET_COMPANY_SETTINGS', settings, {root: true});
                commit('company/SET_COUNTS', counts, {root: true});

                if (Array.isArray(company?.listings)) {
                    commit('listing/SET_ALL_LISTINGS', company.listings, {root: true});
                }

                commit('app/SET_ALL_POSSIBLE_PERMISSIONS', allPermissions, {root: true});
                commit('app/SET_FW_STRIPE_KEY', fwPublicStripeKey, {root: true});
                commit('app/SET_FW_BUILD_NUMBER', build, {root: true});
                commit('app/SET_APP_FEATURES', features, {root: true});

                if (company.notification_settings === null) {
                    dispatch('settings/resetNotificationSettings', null, {root: true});
                } else {
                    commit('settings/SET_NOTIFICATION_SETTINGS', company.notification_settings, {root: true});
                }

                handleCompanyConnections(commit, company);
            } catch (error) {
                console.error('Error fetching session data:', error);
            } finally {
                commit('subscriptions/SET_SUBSCRIPTION_LOADING', {
                    subject: 'subscription',
                    loading: false
                }, {root: true});
                commit('listing/SET_LISTING_LOADING', {subject: 'listings', loading: false}, {root: true});
                commit('company/SET_COMPANY_LOADING', {subject: 'company', loading: false}, {root: true});
                commit('app/SET_APP_BOOTING', false, {root: true});
            }
        },

        async fetchApiTokens({commit}) {
            try {
                const responseData = await performApiRequest(commit, 'SET_AUTH_LOADING', 'getApiToken', () => axios.get('/v1/user/api-tokens'));
                commit('SET_USER_API_TOKENS', responseData.tokens);
            } catch (error) {
            }
        },

        async createUserTokens({commit}) {
            try {
                const responseData = await performApiRequest(commit, 'SET_AUTH_LOADING', 'createApiToken', () => axios.post('/v1/user/api-tokens'));
                commit('SET_USER_API_TOKENS', responseData.tokens);
                return responseData.token
            } catch (error) {
            }
        },

        async deleteApiToken({commit}, token: ApiToken) {
            try {
                const responseData = await performApiRequest(commit, 'SET_AUTH_LOADING', 'deleteApiToken', () => axios.delete(`/v1/user/api-tokens/${token.id}`));
                commit('SET_USER_API_TOKENS', responseData.tokens);
            } catch (error) {
            }
        },


        async checkStripe({commit, rootGetters}) {
            const responseData = await performApiRequest(commit, 'SET_AUTH_LOADING', 'check_stripe', () => axios.get(`/v1/company/${rootGetters['company/company'].uuid}/stripe`));
            if (responseData?.results) {
                EventBus.$emit('stripe-oauth-success');
                commit('company/SET_COMPANY', responseData.company, {root: true});
                commit(types.SET_AUTH_LOADING, {subject: 'stripe_oauth', loading: false});

                return responseData.results;
            }
        },


        async saveStripeUserId({state, commit}, code: string) {
            try {
                await performApiRequest(commit, 'SET_AUTH_LOADING', 'stripe_oauth', () => axios.post(`/v1/stripe/user-id/${code}`));
                EventBus.$emit('stripe-oauth-success');
            } catch (error) {
                throw error;
            }
        },

        async fetchUser({commit}) {
            const masquerade = checkMasquerade();

            if (masquerade) {
                commit('app/SET_MASQUERADE_STATUS', masquerade, {root: true});
                setToken(masquerade.user.token);
            }

            try {
                const {data} = await axios.post('/v1/auth/user');

                const {roles, permissions, email, phone, name} = data;


                if (email) {
                    Sentry.setUser({email, phone, name});

                    // const { config } = getOptions();
                    //
                    // query("config", config.id, {
                    //   'user_id': data.uuid,
                    // });
                }

                commit('SET_USER', data);
                commit('SET_ROLES', roles);
                commit('SET_PERMISSIONS', permissions);
            } catch (e) {
                console.error('fetchUser error', e);
                commit(types.FETCH_USER_FAILURE);
            }
        },

        resendConfirmEmail({state}) {
            axios.post(`/v1/users/${state.user.uuid}/confirm-email/resend`)
                .then(() => {
                    EventBus.$emit('verify-email-resent');
                }).catch(() => {
            }).finally(() => {
            });
        },

        updateUser({commit}, payload) {
            commit(types.UPDATE_USER, payload.user);
        },

        async logout({commit}) {
            try {
                await axios.post('/v1/auth/logout');
            } catch (e) {
            }

            removeToken();
            commit(types.LOGOUT);
            router.push({name: 'login'});
        },

    },

    mutations: {
        [types.SET_AUTH_LOADING](state, loading: { subject: string, loading: boolean }) {
            state.loading[loading.subject] = loading.loading;
        },
        [types.SET_TOKEN](state, {token, remember}) {
            state.token = token;
            setToken(token, remember);
        },
        [types.SAVE_TOKEN](state, {token, remember}) {
            state.token = token;
            setToken(token, remember);
        },
        [types.SAVE_REGISTER_ID](state: AuthState, id: string) {
            state.registerId = id;
            setRegisterToken(id);
        },
        [types.SET_USER](state, user) {
            user.loaded = true;
            state.user = user;
        },
        [types.SET_ROLES](state, roles) {
            state.roles = roles;
            if (roles.includes('super-admin')) {
                localStorage.setItem('super-admin', 'true');
            }
        },
        [types.SET_PERMISSIONS](state, permissions) {
            state.permissions = permissions;
        },

        [types.FETCH_USER_FAILURE](state) {
            state.token = null;
            removeToken();
        },

        [types.LOGOUT](state) {
            state.user = {
                loaded: false,
                roles: [],
                permissions: [],
                identities: {},
            };
            // remove token
            state.token = null;

            // unset roles/permissions
            state.roles = [];

            removeAllUserCookies();

            localStorage.removeItem('userListings');
            localStorage.removeItem('turo_me');
            localStorage.removeItem('turo_vehicles');
            localStorage.removeItem('message_templates');
            localStorage.removeItem('super-admin');
            localStorage.removeItem('welcome-panel');
            localStorage.removeItem('turo-selected-hosts');
            localStorage.removeItem('customer');
            CartService.removeCartUuid();
        },

        [types.UPDATE_USER](state, {user}) {
            state.user = user;
        },
        [types.SET_USER_NOTIFICATIONS](state, notifications) {
            state.notifications = notifications;
        },
        [types.SET_USER_API_TOKENS](state, tokens) {
            state.apiTokens = tokens;
        },
    }
}

export default authModule;
