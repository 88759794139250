<template>
  <v-dialog v-model="orderDocumentDialog" width="500">
    <v-card>
      <v-card-title>
        Downloading
        <v-spacer />
        <span v-if="fileDone">
          <v-icon class="mr-2 mb-1" color="green">mdi-check</v-icon> Done
        </span>
      </v-card-title>
      <v-card-text>
        <div class="mt-5 text-center">
          <v-progress-circular
              v-if="!fileDone"
              indeterminate
              color="primary"
          ></v-progress-circular>
          <div v-else>
           <v-btn variant="text" size="small" :href="fileUrl" target="_blank">
              Didnt start downloading automatically?
              <v-icon size="x-small" class="ml-2">mdi-open-in-new</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { EventBus } from '@/plugins/eventBus';
import { get } from 'lodash';

export default {
  name: 'StartOrderDocumentDownloadDialog',

  data() {
    return {
      orderDocumentDialog: false,
      currentWorkingOrderDocumentUuid: null,
    };
  },

  created() {
    EventBus.$on('open-download-order-document-dialog', (document_uuid) => {
      this.orderDocumentDialog = true;
      this.currentWorkingOrderDocumentUuid = document_uuid;

      this.downloadOrderDocument(document_uuid);
    });
    EventBus.$on('start-order-document-download-success', (status) => {
      if(['started', 'queued'].includes(get(status, 'status.status'))) {
        setTimeout(() => {
          this.downloadOrderDocument(this.currentWorkingOrderDocumentUuid);
        }, 3 * 1000);
      }
    });
  },

  watch: {
    fileDone: {
      handler(status) {
        if(status) {
          window.open(this.fileUrl, '_blank').focus();
        }
      },
    },
    fileStatus: {
      handler(status) {
        console.dir(status);
      },
    },
  },

  computed: {
    ...mapGetters({
      theNewOrder: 'order/order',
      documents: 'order/documents',
      downloadStatus: 'order/downloadStatus',
    }),
    fileUrl() {
      return get(this.downloadStatus, 'url');
    },
    fileDone() {
      return get(this.downloadStatus, 'done');
    },
    fileStatus() {
      return get(this.downloadStatus, 'status.status');
    },
  },

  methods: {
    ...mapActions({
      downloadOrderDocument: 'order/downloadOrderDocument',
    }),
  },

  beforeUnmount() {
    EventBus.$off('open-download-order-document-dialog');
    EventBus.$off('start-order-document-download-success');
  },
};
</script>
