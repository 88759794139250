<template>
  <div>
    <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between my-5">
      <div class="flex-grow">
        <h4 class="font-bold text-2xl">Subscription</h4>

        <div class="mt-2 flex flex-wrap gap-2 plan-features-title-list-item">
          <div class="pr-2">
            <span v-if="inTrial">Trailing ({{ upperFirst(subscriptionPlan) }})</span>
            <span v-else>
             {{ upperFirst(subscriptionPlan) }} Plan
            </span>
          </div>

          <div class="pr-2">{{ planSupportedFeatureCounts.listings }} Listings</div>
          <div class="pr-2">{{ planSupportedFeatureCounts.seats }} Seats</div>
          <div class="pr-2">{{ planSupportedFeatureCounts.locations }} Branches</div>
        </div>
      </div>

      <div class="mt-4 sm:mt-0">
        <v-btn
          :to="{name: 'settings.account.plans.confirm'}"
          class="text-decoration-none w-full sm:w-auto"
          variant="tonal"
        >
          Manage Subscription
        </v-btn>
      </div>
    </div>

    <v-alert
      v-if="hasPastDueSubscription"
      class="mb-4"
      color="orange"
      type="warning"
    >
      Subscription payment is past due and may result in service interruption. Please update your payment method to
      avoid interruption.
    </v-alert>

    <template v-if="checkPermission(['account_and_billing_manage'])">
      <div>
        <UpsellCard v-if="shouldShowUpsellCard"/>

        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 my-5">
          <RenewsOneCard/>
          <PlanPriceCard/>
          <CardOnFileCard/>
        </div>

        <div class="mb-10">
          <div class="mb-5 text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <ul class="flex flex-wrap -mb-px overflow-x-auto">
              <li class="mr-2">
                <router-link
                  v-slot="{ href, navigate, isExactActive }"
                  :to="{name: 'settings.account.overview'}"
                  custom
                >
                  <a
                    :href="href"
                    :style="[isExactActive && 'border-bottom: 2px solid blue;']"
                    class="inline-block p-3 sm:p-4 border-b-2 border-blue-600 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 whitespace-nowrap"
                    @click="navigate"
                  >Overview</a>
                </router-link>
              </li>
              <li class="mr-2">
                <router-link
                  v-slot="{ href, navigate, isExactActive }"
                  :to="{name: 'settings.account.receipts'}"
                  custom
                >
                  <a
                    :href="href"
                    :style="[isExactActive && 'border-bottom: 2px solid blue;']"
                    class="inline-block p-3 sm:p-4 border-b-2 border-blue-600 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 whitespace-nowrap"
                    @click="navigate"
                  >Receipts</a>
                </router-link>
              </li>
              <li class="mr-2">
                <router-link
                  v-slot="{ href, navigate, isExactActive }"
                  :to="{name: 'settings.account.billing'}"
                  custom
                >
                  <a
                    :href="href"
                    :style="[isExactActive && 'border-bottom: 2px solid blue;']"
                    class="inline-block p-3 sm:p-4 border-b-2 border-blue-600 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 whitespace-nowrap"
                    @click="navigate"
                  >Billing Details</a>
                </router-link>
              </li>
            </ul>
          </div>

          <router-view name="default"/>
        </div>
      </div>

      <div class="my-6 sm:my-10">
        <div class="">
          <v-card>
            <v-alert v-if="!defaultPayment" type="warning" variant="tonal">
              <div v-if="!hasActiveSubscription">
                You have {{ trialDaysLeftWithDay }} left on your trial and you don't have a payment method set up. <br>
              </div>
              Add a card for uninterrupted service.
            </v-alert>
          </v-card>
        </div>
      </div>

      <DeleteAccountSection/>
    </template>

    <div v-else class="h-100 row align-items-center">
      <div class="col text-center">
        <v-img height="200px" src="/images/illustrations/undraw_security.svg"/>
        <v-img height="200px" src="/images/illustrations/undraw_security.svg"/>
        <h2 class="newCustomer__header">No Permissions</h2>
        <p>You dont have permissions to view this page.<br> You will need to ask your company admin
          to add "Account & Billing Manage" permission.</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, defineAsyncComponent, onBeforeMount} from 'vue';
import UpsellCard from '@/components/dashboard/settings/account/UpsellCard.vue';
import checkPermission from '@/utils/permission';
import {useSubscription} from '@/composables/useSubscription';
import {useCompany} from '@/composables/useCompany';
import {useStripe} from '@/composables/useStripe';
import {upperFirst} from '@/utils/text';

const DeleteAccountSection = defineAsyncComponent(() => import('@/components/dashboard/settings/account/DeleteAccountSection.vue'));
const CardOnFileCard = defineAsyncComponent(() => import('@/views/dashboard/settings/Billing/SubscriptionDetailCards/CardOnFileCard.vue'));
const RenewsOneCard = defineAsyncComponent(() => import('@/views/dashboard/settings/Billing/SubscriptionDetailCards/RenewsOneCard.vue'));
const PlanPriceCard = defineAsyncComponent(() => import('@/views/dashboard/settings/Billing/SubscriptionDetailCards/PlanPriceCard.vue'));

const {
  inTrial,
  subscriptionPlan,
  hasActiveSubscription,
  trialDaysLeft,
  planSupportedFeatureCounts,
  hasPastDueSubscription,
} = useSubscription();
const {company} = useCompany();

const {fetchSubscriptionDetails} = useStripe();


const shouldShowUpsellCard = computed(() => {
  return subscriptionPlan.value === 'basic' || subscriptionPlan.value === 'standard';
});

const cardBrand = computed(() => company.value?.pm_type);
const cardLastFour = computed(() => company.value?.pm_last_four);
const defaultPayment = computed(() => {
  if (!cardBrand.value || !cardLastFour.value) return false;
  return `${upperFirst(cardBrand.value)}-${cardLastFour.value}`;
});

const trialDaysLeftWithDay = computed(() => {
  return `${trialDaysLeft.value} day${trialDaysLeft.value === 1 ? '' : 's'}`;
});

onBeforeMount(() => {
  fetchSubscriptionDetails();
});
</script>

<style>
.plan-features-title-list-item > :not(:last-child) {
  border-right: 1px solid rgb(224, 220, 229);
}

@media (max-width: 640px) {
  .plan-features-title-list-item > :not(:last-child) {
    border-right: none;
    border-bottom: 1px solid rgb(224, 220, 229);
    padding-bottom: 0.5rem;
  }
}
</style>