import axios from 'axios';
import * as types from '../mutation-types';
import {TenantActionItem, TenantBranch} from '@/types';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import Form from '@/plugins/FWForms';
import {push} from 'notivue'

export interface FleetState {
    loading: Record<string, boolean>,
    branches: TenantBranch[],
    branch: TenantBranch,
    actionItems: TenantActionItem[],
    actionItem: TenantActionItem
    actionItemDeleted: boolean
    newlyCreatedActionItem: TenantActionItem
    fleetForm: Form<any>,
}

const state: FleetState = {
    loading: {
        branches: false,
        createBranch: false,
        fetchBranch: false,
        updateBranch: false,
        actionItem: false,
        actionItems: false,
    },
    fleetForm: new Form(),
    branches: [],
    branch: null,
    actionItems: [],
    actionItem: null,
    newlyCreatedActionItem: null,
    actionItemDeleted: false,
};

const getters = {
    loading: (state: FleetState) => state.loading,
    fleetForm: (state: FleetState) => state.fleetForm,
    branches: (state: FleetState) => state.branches,
    branch: (state: FleetState) => state.branch,
    actionItems: (state: FleetState) => state.actionItems,
    actionItem: (state: FleetState) => state.actionItem,
    actionItemDeleted: (state: FleetState) => state.actionItemDeleted,
    newlyCreatedActionItem: (state: FleetState) => state.newlyCreatedActionItem,
};

const mutations = {
    [types.SET_COMPANY_BRANCHES_LOADING](state: FleetState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_COMPANY_BRANCHES](state: FleetState, branches: TenantBranch[]) {
        state.branches = branches;
    },
    [types.SET_COMPANY_BRANCH](state: FleetState, branch: TenantBranch) {
        state.branch = branch;
    },
    [types.SET_ACTION_ITEMS](state: FleetState, items: TenantActionItem[]) {
        state.actionItems = items;
    },
    [types.SET_ACTION_ITEM](state: FleetState, item: TenantActionItem) {
        state.actionItem = item;
    },
    [types.ACTION_ITEM_CREATED](state: FleetState, item: TenantActionItem) {
        state.newlyCreatedActionItem = item;
    },
    [types.ACTION_ITEM_DELETED](state: FleetState, value: boolean) {
        state.actionItemDeleted = value;

        // Reset the value after 2 seconds
        setTimeout(() => {
            state.actionItemDeleted = false;
        }, 2000);
    },
    [types.SET_FLEET_FORM_ERROR](state: FleetState, error: string | Record<string, string[]>) {
        state.fleetForm.errors.set(error);
    },
    [types.CLEAR_FLEET_FORM_ERRORS](state: FleetState, form_field: string | null = null) {
        if (form_field === null || form_field === undefined) {
            state.fleetForm.errors.clear();
        } else {
            state.fleetForm.errors.clear(form_field);
        }
    },
};

const actions = {

    /**
     * Get fleet branches.
     */
    async getFleetBranches({commit}) {
        const responseData = await performApiRequest(commit, 'SET_COMPANY_BRANCHES_LOADING', 'branches', () => axios.get('/v1/branches'));
        commit(types.SET_COMPANY_BRANCHES, responseData.branches);
    },


    /**
     * Create a fleet branch.
     */
    async createBranch({commit}, branch: TenantBranch) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COMPANY_BRANCHES_LOADING', 'createBranch', () => axios.post('/v1/branches', branch));
            commit('SET_COMPANY_BRANCHES', responseData.branches);
        } catch (error) {
            throw error;
        }
    },


    /**
     * Delete a fleet branch.
     */
    async deleteBranch({commit}, uuid: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COMPANY_BRANCHES_LOADING', 'createBranch', () => axios.delete(`/v1/branches/${uuid}`));
            commit('SET_COMPANY_BRANCHES', responseData.branches);
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'Failed to delete branch',
            });
            throw error;
        }
    },

    /**
     * Get a fleet branch.
     */
    async getFleetBranch({commit}, uuid: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COMPANY_BRANCHES_LOADING', 'fetchBranch', () => axios.get(`/v1/branches/${uuid}`));
            commit('SET_COMPANY_BRANCH', responseData.branch);
        } catch (error) {
        }
    },

    /**
     * Delete a fleet branch.
     */
    async updateFleetBranch({commit}, branch: TenantBranch) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COMPANY_BRANCHES_LOADING', 'updateBranch', () => axios.put(`/v1/branches/${branch.uuid}`, branch));
            commit('SET_COMPANY_BRANCH', responseData.branch);
            push.success({
                message: 'Branch saved successfully',
            });
            return responseData.branch;
        } catch (error) {
            push.error({
                message: 'Failed to save branch',
            });
        }
    },


    /**
     * Get action items.
     *
     */
    async getActionItems({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COMPANY_BRANCHES_LOADING', 'actionItems', () => axios.get('/v1/action-items'));
            commit('SET_ACTION_ITEMS', responseData.actionItems);
        } catch (error) {
        }
    },


    /**
     * Get action items.
     */
    async getActionItem({commit}, uuid: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COMPANY_BRANCHES_LOADING', 'actionItems', () => axios.get(`/v1/action-items/${uuid}`));
            commit('SET_ACTION_ITEM', responseData.actionItem);
        } catch (error) {
        }
    },

    /**
     * Create action item.
     */
    async createActionItem({commit}, actionItem: TenantActionItem) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COMPANY_BRANCHES_LOADING', 'actionItem', () => axios.post('/v1/action-items', actionItem));
            commit(types.ACTION_ITEM_CREATED, responseData.actionItem);
            push.success({
                message: 'Action Item created successfully',
            });
        } catch (error) {
            commit(types.SET_FLEET_FORM_ERROR, error.response.data.errors)
        }
    },

    /**
     * Update action item.
     */
    async updateActionItem({commit}, actionItem: TenantActionItem) {
        try {
            await performApiRequest(commit, 'SET_COMPANY_BRANCHES_LOADING', 'actionItems', () => axios.put(`/v1/action-items/${actionItem.uuid}`, actionItem));
            push.success({
                message: 'Action Item saved successfully',
            });
        } catch (error) {
            commit(types.SET_FLEET_FORM_ERROR, error.response.data.errors)
        }
    },

    /**
     * Delete action item.
     */
    async deleteActionItem({commit}, actionItem: TenantActionItem) {
        try {
            await performApiRequest(commit, 'SET_COMPANY_BRANCHES_LOADING', 'actionItem', () => axios.delete(`/v1/action-items/${actionItem.uuid}`));
            commit(types.ACTION_ITEM_DELETED, true);
            push.success({
                message: 'Action Item deleted successfully',
            });
        } catch (error) {
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
