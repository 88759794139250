import axios from 'axios';
import * as types from '../mutation-types';
import {EventBus} from '@/plugins/eventBus';
import {Form} from '@/plugins/FWForms/index';
import {RootState} from '@/types/RootState';
import {Module} from 'vuex';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {AffiliateInterface} from '@/types';
import {push} from 'notivue'

export interface AffiliateState {
    loading: Record<string, boolean>;
    affiliate: AffiliateInterface;
    affiliatePayouts: any[];
    affiliateApplication: any;
    affiliatePayoutsFuture: number;
    affiliatePayoutsPast: number;
    affiliateForm: Form<any>;
}


const state: AffiliateState = {
    loading: {
        affiliateApplication: false,
        affiliateAgreeTOS: false,
        getAffiliate: false,
        getAffiliatePayouts: false,
    },
    affiliate: null,
    affiliatePayouts: [],
    affiliateApplication: {},
    affiliatePayoutsFuture: 0,
    affiliatePayoutsPast: 0,
    affiliateForm: new Form(),
};


const mutations = {
    [types.SET_AFFILIATE_LOADING](state: AffiliateState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_AFFILIATE](state: AffiliateState, affiliate: AffiliateInterface) {
        state.affiliate = affiliate;
    },
    [types.SET_AFFILIATE_PAYOUTS](state: AffiliateState, payouts) {
        state.affiliatePayouts = payouts;
    },
    [types.SET_AFFILIATE_FUTURE_PAYOUT](state: AffiliateState, payout) {
        state.affiliatePayoutsFuture = payout;
    },
    [types.SET_AFFILIATE_PAST_PAYOUT](state: AffiliateState, payout) {
        state.affiliatePayoutsPast = payout;
    },
    [types.SET_AFFILIATE_APPLICATION](state: AffiliateState, application) {
        state.affiliateApplication = application;
    },
};


const actions = {

    /**
     * Submit Affiliate application
     */
    async submitAffiliateApplication({commit, state}, data) {
        try {
            const responseData = await performApiRequest(commit, 'SET_AFFILIATE_LOADING', 'submit', () => axios.post('/v1/affiliate/signup', data));
            EventBus.$emit('affiliate-application-submit-success');
            commit(types.SET_AFFILIATE_APPLICATION, responseData.application);
        } catch (error) {
            state.affiliateForm.errors.set(error?.response?.data?.errors || []);
        }
    },

    /**
     * Get Affiliate application
     */
    async affiliateApplications({commit, state}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_AFFILIATE_LOADING', 'affiliateApplication', () => axios.get('/v1/affiliate/application'));
            commit(types.SET_AFFILIATE_APPLICATION, responseData.application);
            commit(types.SET_AFFILIATE, responseData.affiliate);
        } catch (error) {
            state.affiliateForm.errors.set(error?.response?.data?.errors || []);
        }
    },

    /**
     * Get Affiliate
     */
    async getAffiliate({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_AFFILIATE_LOADING', 'getAffiliate', () => axios.get('/v1/affiliate'));
            commit(types.SET_AFFILIATE, responseData.affiliate);
        } catch (error) {
            state.affiliateForm.errors.set(error?.response?.data?.errors || []);
        }
    },

    /**
     * Get Affiliates Payouts
     */
    async getAffiliatePayouts({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_AFFILIATE_LOADING', 'getAffiliatePayouts', () => axios.get('/v1/affiliate/payouts'));
            commit(types.SET_AFFILIATE_PAYOUTS, responseData.payouts);
            commit(types.SET_AFFILIATE_FUTURE_PAYOUT, responseData.future_payouts);
            commit(types.SET_AFFILIATE_PAST_PAYOUT, responseData.past_payouts);
        } catch (error) {
            state.affiliateForm.errors.set(error?.response?.data?.errors || []);
        }
    },

    /**
     * Affiliate TOS agreement
     */
    async affiliateTOSAgree({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_AFFILIATE_LOADING', 'getAffiliate', () => axios.post('/v1/affiliate/agree-tos'));
            push.success({
                message: 'Agree to TOS recorded successfully!',
            });
            EventBus.$emit('affiliate-application-tos-agree-success');
            return responseData;
        } catch (error) {
            state.affiliateForm.errors.set(error?.response?.data?.errors || []);
        }
    },
};


const affiliateModule: Module<AffiliateState, RootState> = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters: {
        loading: (state: AffiliateState) => state.loading,
        affiliate: (state: AffiliateState) => state.affiliate,
        affiliateForm: (state: AffiliateState) => state.affiliateForm,
        affiliatePayouts: (state: AffiliateState) => state.affiliatePayouts,
        affiliateApplication: (state: AffiliateState) => state.affiliateApplication,
        affiliatePayoutsPast: (state: AffiliateState) => state.affiliatePayoutsPast,
        affiliatePayoutsFuture: (state: AffiliateState) => state.affiliatePayoutsFuture,
    }
};


export default affiliateModule;