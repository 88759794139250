import type {RouteRecordRaw} from 'vue-router';
import {CommonRouteMeta} from "@/types";

const BaseDashboard = () => import('@/views/dashboard/BaseDashboard.vue');

const listingRoutes: RouteRecordRaw[] = [
    {
        path: '/trip-extras',
        component: BaseDashboard,
        meta: {
            title: 'Trip Extras',
            icon: 'mdi-car-convertible',
            noCache: false,
            isBase: true,
            permissions: ['listings_view'],
            middleware: ['auth'],
            layout: 'Dashboard',
        } as CommonRouteMeta,
        children: [
            {
                path: '',
                components: {
                    default: () => import('@/views/dashboard/listings/tripExtras/BaseTripExtras.vue'),
                    navRightActions: () => import('@/components/dashboard/navActions/AddNewTripExtra.vue'),
                },
                name: 'tripExtras.index',
                meta: {title: 'Trip Extras', icon: 'mdi-car-convertible', noCache: false, isBase: true},
            },
            {
                path: 'new',
                components: {
                    default: () => import('@/views/dashboard/listings/tripExtras/NewTripExtra.vue'),
                    navRightActions: () => import('@/components/dashboard/navActions/AddExtraSaveBtn.vue'),
                },
                name: 'extra.new',
                meta: {title: 'New Extra', icon: 'mdi-car-convertible', noCache: false},
            },
        ],
    }
];

export default listingRoutes;
