import type {RouteRecordRaw} from 'vue-router';
import ThemeBase from '@/views/themes/ThemeBase/ThemeBase.vue';
import {CommonRouteMeta} from "@/types";


const themeRoutes: RouteRecordRaw[] = [{
    path: '/themes',
    component: ThemeBase,
    meta: {
        title: 'themes',
        icon: 'mdi-wallpaper',
        noCache: false,
        hidden: true,
    } as CommonRouteMeta,
    children: [
        {
            path: '',
            components: {
                default: () => import('@/views/themes/ThemeCustomize.vue'),
            },
            name: 'themes.index',
            meta: {title: 'Trip Extras', icon: 'mdi-car-convertible', noCache: false, isBase: true},
        },
    ],
}];

export default themeRoutes;
