/**
 * Build element
 * @param {string} type
 * @param {*} attributes
 * @param {*} children
 */
const elFactory = (type, attributes, ...children) => {
  const el = document.createElement(type)

  for (const key in attributes) {
    el.setAttribute(key, attributes[key])
  }

  children.forEach(child => {
    if (typeof child === 'string') {
      el.appendChild(document.createTextNode(child))
    } else {
      el.appendChild(child)
    }
  })

  return el
}

export default elFactory;