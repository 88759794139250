import {App, Component} from 'vue';

/**
 * Register layouts in the app instance
 *
 * @param {App<Element>} app
 */
export async function registerLayouts(app: App<Element>) {
    const layouts = import.meta.glob('./*.vue'); // Returns a map of lazy-loaded modules

    console.log('Layouts:', layouts);

    for (const [path, layoutImporter] of Object.entries(layouts)) {
        try {
            // Dynamically import the layout
            const module = (await layoutImporter()) as { default: Component };
            const component = module.default;

            if (!component) {
                console.warn(`Failed to load layout at path: ${path}`);
                continue;
            }

            // Use the `name` property or fallback to the filename
            const componentName = component.name || extractFileName(path);

            if (!componentName) {
                console.error(`Unable to determine component name for layout at path: ${path}`);
                continue;
            }

            app.component(componentName, component);
        } catch (error) {
            console.error(`Error loading layout at path: ${path}`, error);
        }
    }
}

/**
 * Extracts the filename from a given path and removes the extension.
 */
function extractFileName(path: string): string {
    return path.split('/').pop()?.replace(/\.\w+$/, '') || 'UnnamedComponent';
}
