import axios from 'axios';
import {EventBus} from '@/plugins/eventBus';
import {StorageService} from '@/services/storage.service';
import * as types from '../mutation-types';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {ESignerFieldData} from '@/types';
import {push} from 'notivue'

export type DocumentType = {
    created_at: string;
    data: ESignerFieldData[];
    dimensions: { height: number, width: number };
    doc_width: number;
    document_images: string[];
    file_id: string;
    files: string[];
    name: string;
    pages: number;
    pdf_path: string;
    template: any;
    updated_at: string;
    uuid: string;
}

export type TemplateData = {
    name: string;
    documents: DocumentType;
    pages: number;
    file_id: string;
    data: ESignerFieldData[];
    version: string | 'v2';
    created_at?: string;
    updated_at?: string;
    uuid?: string;
    custodian?: boolean;
}

export interface DocumentState {
    init: boolean;
    loading: Record<string, boolean>;
    users: any[];
    documents: DocumentType[];
    document: DocumentType;
    templates: TemplateData[];
    template?: TemplateData;
}

const state: DocumentState = {
    init: false,
    loading: {
        get: false,
        template: false,
        saving: false,
    },
    users: [],
    documents: [],
    document: null,
    templates: [],
    template: null,
};

const getters = {
    init: (state: DocumentState) => state.init,
    loading: (state: DocumentState) => state.loading,
    document: (state: DocumentState) => state.document,
    documents: (state: DocumentState) => state.documents,
    templates: (state: DocumentState) => state.templates,
    template: (state: DocumentState) => state.template,
};

const mutations = {
    [types.SET_TEMPLATES_LOADING](state: DocumentState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_ONE_DOCUMENT](state: DocumentState, document: DocumentType) {
        state.document = document;
    },
    [types.SET_ALL_DOCUMENTS](state: DocumentState, documents: DocumentType[]) {
        state.documents = documents;
    },
    [types.RESET_TEMPLATE](state: DocumentState) {
        state.template = null;
        state.init = false;
    },
    [types.SET_ONE_TEMPLATE](state: DocumentState, template: TemplateData) {
        state.template = template;
        state.init = true;
    },
    [types.SET_ALL_TEMPLATES](state: DocumentState, templates: TemplateData[]) {
        state.templates = templates;
    },
};

const actions = {

    /**
     * Fetch a template
     */
    async fetchTemplates({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_TEMPLATES_LOADING', 'get', () => axios.get('/v1/templates'));
            commit(types.SET_ALL_TEMPLATES, responseData.templates);
        } catch (error) {
        }
    },


    /**
     * Create a template
     *
     */
    createTemplate() {
        axios.post('/v1/templates')
            .then((response) => {
                EventBus.$emit('create-template-success', response?.data?.template);
            }).catch((e) => {
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
        });
    },


    /**
     * Get a guest template
     */
    getTemplateGuest({commit}, uuid: string) {
        commit('SET_TEMPLATES_LOADING', {subject: 'template', loading: true});
        axios.get(`/v1/templates/${uuid}`)
            .then((response) => {
                EventBus.$emit('get-template-success', response?.data?.template);
                commit(types.SET_ONE_TEMPLATE, response?.data?.template);
            }).catch(() => {
        }).finally(() => {
            commit('SET_TEMPLATES_LOADING', {subject: 'template', loading: false});
        });
    },


    /**
     * Update a template
     */
    async updateTemplate({commit}, data: { template: TemplateData, actions: any }) {
        try {
            await performApiRequest(commit, 'SET_TEMPLATES_LOADING', 'saving', () => axios.put(`/v1/documents/${data.template.documents.uuid}`, {actions: data.actions}));

            push.success({
                message: 'Template updated successfully!',
            });
        } catch (error) {
        }
    },


    /**
     * Get a document
     */
    getDocument({state, commit, rootGetters}) {
        axios.get(`/v1/company/${rootGetters['company/company'].uuid}/documents/${state.document.uuid}`)
            .then((response) => {
                commit(types.SET_ONE_DOCUMENT, response?.data?.document);
            }).catch(() => {
        }).finally(() => {
        });
    },


    /**
     * Save a document
     */
    saveDocument({state, commit, rootGetters}, data: object) {
        commit('SET_TEMPLATES_LOADING', {subject: 'saving', loading: true});
        axios.put(`/v1/company/${rootGetters['company/company'].uuid}/documents/${state.document.uuid}`, {
            data,
            document: state.document,
        })
            .then(() => {
                push.success({
                    message: 'Document saved successfully!',
                });
            }).catch(() => {
        }).finally(() => {
            commit('SET_TEMPLATES_LOADING', {subject: 'saving', loading: false});
        });
    },


    /**
     * Update a template documents name
     */
    async updateDocumentName({commit}, name: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_TEMPLATES_LOADING', 'saving', () => axios.put(`/v1/documents/${state.document.uuid}/name`, {name}));

            commit(types.SET_ALL_TEMPLATES, responseData.templates);

            push.success({
                message: 'Document name updated successfully!',
            });
        } catch (error) {
        }
    },


    /**
     * Delete a template document
     */
    deleteDocument({state, commit}) {
        axios.delete(`/v1/company/${StorageService.getCompanyUuid()}/documents/${state.document.uuid}`)
            .then((response) => {
                commit(types.SET_ALL_DOCUMENTS, response?.data?.documents || []);

                push.success({
                    message: 'Document removed successfully!',
                });
            }).catch(() => {
        }).finally(() => {
        });
    },


    /**
     * Delete a template
     */
    async deleteTemplate({commit}, template: TemplateData) {
        try {
            const responseData = await performApiRequest(commit, 'SET_TEMPLATES_LOADING', 'deleting', () => axios.delete(`/v1/templates/${template.uuid}`));

            commit(types.SET_ALL_TEMPLATES, responseData.templates);

            push.success({
                message: 'Template deleted successfully!',
            });
        } catch (error) {
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
