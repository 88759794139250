import {useStore} from 'vuex';
import {computed} from 'vue';
import {default as dayjs} from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import {pluralize} from "@/utils/text";

dayjs.extend(isSameOrBefore);


export function useSubscription() {
    const store = useStore();


    const enforceSubscription = computed(() => store.getters['subscriptions/enforceSubscription']);
    const customersLoading = computed(() => store.getters['renters/rentersLoading']);

    const hasActiveSubscription = computed(() => store.getters['subscriptions/hasActiveSubscription']);
    const subscriptionPlans = computed(() => store.getters['subscriptions/plans']);
    const subscriptionPlan = computed(() => store.getters['subscriptions/subscriptionPlan']);
    const subscription = computed(() => store.getters['subscriptions/subscription']);
    const user = computed(() => store.getters['auth/user']);
    const company = computed(() => store.getters['company/company']);
    const stripeSubscriptionObject = computed(() => store.getters['stripe/stripeSubscriptionObject']);
    const subscriptionsLoading = computed(() => store.getters['subscriptions/loading']);
    const subscriptionEndDate = computed(() => store.getters['subscriptions/subscriptionEndDate']);
    const hasPastDueSubscription = computed(() => store.getters['subscriptions/hasPastDueSubscription']);
    const hasPausedSubscription = computed(() => store.getters['subscriptions/hasPausedSubscription']);

    const stripeTrialDate = computed(() => subscription.value?.trial_ends_at);

    const subscriptionFeatures = {
        seats: {
            basic: 2,
            standard: 5,
            premium: 20,
        },
        listings: {
            basic: 5,
            standard: 50,
            premium: 150,
        },
        locations: {
            basic: 1,
            standard: 2,
            premium: 2,
        },
        carSharingConnections: {
            basic: 1,
            standard: 5,
            premium: 10,
        },
        smartCarConnections: {
            basic: 1,
            standard: 5,
            premium: 10,
        },
        orders: {
            basic: 5,
            standard: 50,
            premium: 20,
        },
    }

    const subscriptionStatus = computed(() => {
        const stripeStatus = subscription.value?.stripe_status;

        if (stripeStatus) return stripeStatus;

        if (user.value?.on_trial) return 'trialing';

        return 'inactive';
    });

    const trialEndDateRaw = computed(() => {
        return company.value?.trial_ends_at ?? stripeTrialDate.value;
    });

    const planIncludes = {
        premium: {
            api: true,
            sso: true,
            coupons: true,
            additional_scripts: true,
            custom_domain: true,
            custom_tos: true,
            listing_exports: true,
            listing_imports: true,
            esign: true,
            webhooks: true,
            barcodes: true,
            custom_checkout_fields: true,
        },
        standard: {
            sso: true,
            coupons: true,
            custom_tos: true,
            listing_exports: true,
            listing_imports: true,
            esign: true,
            barcodes: true,
            custom_checkout_fields: true,
        },
        basic: {
            // esign: true,
        },
    }


    const cancelSubscription = async () => {
        return store.dispatch('subscriptions/cancelSubscription');
    }

    const cancelSubscriptionStripePortal = async () => {
        return store.dispatch('subscriptions/cancelSubscriptionStripePortalUrl');
    }

    const endTrial = async () => {
        return store.dispatch('subscriptions/endTrial');
    }

    const switchPlan = async (plan: string) => {
        return store.dispatch('subscriptions/switchPlan', plan);
    }

    const resumeSubscription = async () => {
        return store.dispatch('subscriptions/resumeSubscription');
    }

    const addSubscriptionAddon = async (addon: string) => {
        return store.dispatch('subscriptions/addAddon', addon);
    }
    const removeSubscriptionAddon = async (addon: string) => {
        return store.dispatch('subscriptions/removeAddon', addon);
    }

    const trialEndsMessage = computed(() => {
        const rawDate = trialEndDateRaw.value;

        // Check if rawDate is valid
        if (!rawDate || !dayjs(rawDate).isValid() || subscriptionStatus.value === 'active') {
            return 'Trial has ended';
        }

        const trialDate = dayjs(rawDate);
        const trialDateFmt = trialDate.format('MMMM D, YYYY');

        // Determine if the trial has already ended
        const status = trialDate.isSameOrBefore(dayjs()) ? 'ended' : 'ends';

        return `Trial ${status} ${trialDateFmt}`;
    });

    const stripeEndDate = computed(() => {
        const ends_at = subscription.value?.ends_at;
        if (!ends_at) return;
        return dayjs(ends_at).format('MMMM D, YYYY');
    });

    const inGracePeriod = computed(() => {
        const endDate = subscription.value?.ends_at;
        const status = subscriptionStatus.value;

        if (!endDate) return false;

        return !(status === 'canceled' && dayjs(endDate).diff(dayjs()) <= 0);
    });


    const inTrial = computed((): boolean => {
        const trialDate = user?.value?.on_trial;

        if (trialDate) return true;

        if (subscriptionStatus.value === 'trialing') return true;
        if (subscriptionStatus.value === 'active') return false;

        if (stripeTrialDate.value) {
            return dayjs(stripeTrialDate.value).diff(dayjs()) > 0;
        }

        return false;
    });

    const isTrialing = computed((): boolean => {
        return subscriptionStatus.value === 'trialing';
    })

    const trialEndDate = computed(() => {

        if (!trialEndDateRaw.value) {
            return 'N/A';
        }

        return dayjs(trialEndDateRaw.value).format('MMMM D, YYYY');
    });


    const currentStripeSubscriptionObjectRenewalDate = computed(() => {
        const date = stripeSubscriptionObject.value?.current_period_end;
        const status = stripeSubscriptionObject.value?.status;

        if (!date) return null;
        if (status === 'canceled') return null;

        return dayjs(date * 1000).format('MMMM D, YYYY');
    });

    const currentStripeSubscriptionObjectPlanAmount = computed(() => {
        const amount = stripeSubscriptionObject.value?.plan?.amount;

        if (!amount) return '$0';

        if (amount % 100 === 0) {
            return `$${(amount * 0.01).toFixed(0)}`;
        }

        return `$${(amount * 0.01).toFixed(2)}`;
    });

    const currentStripeSubscriptionObjectPlanInterval = computed(() => {
        return stripeSubscriptionObject.value?.plan?.interval ?? 'month';
    });

    const includedInSubscription = (topic: string): boolean => {
        if (!enforceSubscription.value || inTrial.value) return true;
        if (!hasActiveSubscription.value) return false;

        // Safely get the subscription plan in lowercase
        const plan = subscriptionPlan.value?.toLowerCase() || '';

        // Ensure plan exists in planIncludes
        const planFeatures = planIncludes[plan] ?? null;
        if (!planFeatures) return false;

        // Check if the topic is included in the plan
        return planFeatures[topic] ?? false;
    };


    const planIncludesApi = computed(() => {
        if (!enforceSubscription.value) return true;

        if (inTrial.value && !hasActiveSubscription.value) {
            return true;
        }

        if (!hasActiveSubscription.value) {
            return false;
        }

        return planIncludes?.[subscriptionPlan.value]?.api ?? false;
    })


    const trialDaysLeft = computed((): number => {

        if (!trialEndDateRaw.value) {
            return 0;
        }

        const daysLeft = dayjs(trialEndDateRaw.value).diff(dayjs(), 'days', true);
        return daysLeft > 0 ? Math.ceil(daysLeft) : 0; // Round up
    })

    const trialDaysLeftPercent = computed((): number => {
        return trialDaysLeft.value === 0 ? 0 : Math.round((trialDaysLeft.value / 14) * 100);
    });

    const trialDaysLeftMessage = computed((): string => {
        if (hasActiveSubscription.value) {
            const plan = subscriptionPlan.value
                ? `${subscriptionPlan.value.charAt(0).toUpperCase()}${subscriptionPlan.value.slice(1)}`
                : 'Unknown';
            return `Membership: ${plan} Plan`;
        }
        if (trialDaysLeft.value <= 0) {
            return 'Your trial has ended';
        }
        return `You have ${trialDaysLeft.value} ${pluralize(trialDaysLeft.value, 'day')} left on your trial.`;
    })

    const trialDaysClass = computed(() => ({
        'text-danger': trialDaysLeft.value === 0,
        'text-warning': trialDaysLeft.value < 2,
    }));

    const currentPlanDetails = computed(() => {
        return subscriptionPlans.value.find((p) => p.name === subscriptionPlan.value);
    });

    const currentPlanBullets = computed(() => {
        return currentPlanDetails.value?.description.bullets;
    });

    const seatLimitReached = computed(() => {
        if (!enforceSubscription.value) return false;

        if (inTrial.value && !hasActiveSubscription.value) {
            return false;
        }

        if (!hasActiveSubscription.value) {
            return true;
        }

        return (currentPlanDetails.value?.seats !== -1)
            && company.value?.seat_count >= currentPlanDetails.value?.seats;
    })

    const seatLimitReachedMessage = computed(() => {
        if (!enforceSubscription.value) return 'You need a subscription plan to add seats.';
        return `You've reached your max seats of <b>${currentPlanDetails.value?.seats}</b> for your plan.`;
    });


    const listingLimitReachedMessage = computed(() => {
        if (!enforceSubscription.value) return 'You need a subscription plan to add listings.';
        return `You've reached your max listings of <b>${currentPlanDetails.value?.listings}</b> for your plan.`;
    });

    const listingLimitReached = computed(() => {
        if (!enforceSubscription.value) return false;

        if (inTrial.value && !hasActiveSubscription.value) {
            return false;
        }

        if (!hasActiveSubscription.value) {
            return true;
        }

        return (currentPlanDetails.value?.listings !== -1)
            && company.value?.listings >= currentPlanDetails.value?.listings;
    });

    const planSupportedFeatureCounts = computed(() => {
        const plan = subscriptionPlan.value;

        return {
            listings: subscriptionFeatures.listings[plan],
            locations: subscriptionFeatures.locations[plan],
            carSharingConnections: subscriptionFeatures.carSharingConnections[plan],
            smartCarConnections: subscriptionFeatures.smartCarConnections[plan],
            orders: subscriptionFeatures.orders[plan],
            seats: subscriptionFeatures.seats[plan],
        }
    });

    return {
        subscription,
        seatLimitReached,
        seatLimitReachedMessage,
        subscriptionEndDate,
        currentPlanBullets,
        subscriptionsLoading,
        hasActiveSubscription,
        hasPausedSubscription,
        subscriptionStatus,
        subscriptionPlan,
        subscriptionPlans,
        enforceSubscription,
        customersLoading,
        inTrial,
        isTrialing,
        planIncludesApi,
        trialEndDate,
        currentStripeSubscriptionObjectRenewalDate,
        currentStripeSubscriptionObjectPlanAmount,
        currentStripeSubscriptionObjectPlanInterval,
        stripeEndDate,
        inGracePeriod,
        trialDaysLeftMessage,
        trialDaysLeft,
        trialDaysLeftPercent,
        trialDaysClass,
        trialEndsMessage,
        listingLimitReachedMessage,
        listingLimitReached,
        hasPastDueSubscription,
        planSupportedFeatureCounts,
        currentPlanDetails,
        addSubscriptionAddon,
        removeSubscriptionAddon,
        resumeSubscription,
        endTrial,
        switchPlan,
        cancelSubscription,
        cancelSubscriptionStripePortal,
        includedInSubscription,
    }
}