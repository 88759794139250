import axios from 'axios';
import {EventBus} from '@/plugins/eventBus';
import {get} from 'lodash';
import {Form} from '@/plugins/FWForms/index';
import * as types from '../mutation-types';

export interface HqRentalsState {
    loading: Record<string, boolean>;
    form: Form<any>;
    connections: any[];
    listings: any[];
    bookings: any[];
    contacts: any[];
}

const state: HqRentalsState = {
    loading: {
        login: false,
        test: false,
        listings: false,
        bookings: false,
        contacts: false,
        meta: false,
        settings: false,
    },
    form: new Form(),
    connections: [],
    listings: [],
    bookings: [],
    contacts: [],
};

const getters = {
    form: state => state.form,
    connections: state => state.connections,
    loading: state => state.loading,
    listings: state => state.listings,
    bookings: state => state.bookings,
    contacts: state => state.contacts,
};

const mutations = {
    [types.SET_HQRENTALS_LOADING](state, loading) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_HQRENTALS_CONNECTIONS](state, connections) {
        state.connections = connections;
    },
    [types.SET_HQRENTALS_LISTINGS](state, listings) {
        state.listings = listings;
    },
    [types.SET_HQRENTALS_BOOKINGS](state, bookings) {
        state.bookings = bookings;
    },
    [types.SET_HQRENTALS_CONTACTS](state, contacts) {
        state.contacts = contacts;
    },
};

const actions = {

    login({commit, rootGetters}, credentials) {
        commit('SET_HQRENTALS_LOADING', {subject: 'login', loading: true});

        axios.post(`/v1/company/${rootGetters['company/company'].uuid}/connections/hq-rentals`, credentials)
            .then((response) => {
                EventBus.$emit('hqrentals-login-successful', get(response, 'data.provider'));
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'HQ Rentals logged in successfully!',
                });
            }).catch(() => {
        }).finally(() => {
            commit('SET_HQRENTALS_LOADING', {subject: 'login', loading: false});
        });
    },

    connect({commit, rootGetters}, data) {
        commit('SET_HQRENTALS_LOADING', {subject: 'login', loading: true});

        axios.post(`/v1/company/${rootGetters['company/company'].uuid}/connections/hq-rentals/connect`, data)
            .then((response) => {
                EventBus.$emit('hqrentals-connection-successful', get(response, 'data.providers'));
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'HQ Rentals connection connected successfully!',
                });
            }).catch(() => {
        }).finally(() => {
            commit('SET_HQRENTALS_LOADING', {subject: 'login', loading: false});
        });
    },

    setMeta({commit, rootGetters}, provider) {
        commit('SET_HQRENTALS_LOADING', {subject: 'meta', loading: true});

        axios.post(`/v1/company/${rootGetters['company/company'].uuid}/connections/hq-rentals/${provider.uuid}/set-meta`)
            .then((response) => {
                EventBus.$emit('hqrentals-set-meta-successful', get(response, 'data.fields'));
            })
            .catch(() => {
            })
            .finally(() => {
                commit('SET_HQRENTALS_LOADING', {subject: 'meta', loading: false});
            });
    },

    saveSettings({commit, rootGetters}, data) {
        commit('SET_HQRENTALS_LOADING', {subject: 'settings', loading: true});

        axios.put(`/v1/company/${rootGetters['company/company'].uuid}/connections/hq-rentals/${data.provider_uuid}/settings`, data)
            .then(() => {
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'HQ Rentals settings saved successfully!',
                });
            })
            .catch(() => {
            })
            .finally(() => {
                commit('SET_HQRENTALS_LOADING', {subject: 'settings', loading: false});
            });
    },

    testConnection({commit, rootGetters}, provider) {
        commit('SET_HQRENTALS_LOADING', {subject: 'test', loading: true});

        axios.post(`/v1/connections/hq-rentals/${provider.uuid}/test-connection`)
            .then(() => {
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'HQ Rentals connection connected successfully!',
                });
            }).catch((e) => {
            EventBus.$emit('hqrentals-test-connection-failed');
        }).finally(() => {
            commit('SET_HQRENTALS_LOADING', {subject: 'test', loading: false});
        });
    },

    fetchListings({commit, rootGetters}, provider_uuid) {
        commit('SET_HQRENTALS_LOADING', {subject: 'listings', loading: true});

        axios.get(`/v1/company/${rootGetters['company/company'].uuid}/connections/hq-rentals/${provider_uuid}/listings`)
            .then((response) => {
                commit('SET_HQRENTALS_LISTINGS', get(response, 'data.listings'));
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'HQ Rentals connection connected successfully!',
                });
            }).catch(() => {
        }).finally(() => {
            commit('SET_HQRENTALS_LOADING', {subject: 'listings', loading: false});
        });
    },

    fetchBookings({commit, rootGetters}, provider_uuid) {
        commit('SET_HQRENTALS_LOADING', {subject: 'bookings', loading: true});

        axios.get(`/v1/company/${rootGetters['company/company'].uuid}/connections/hq-rentals/${provider_uuid}/bookings`)
            .then((response) => {
                commit('SET_HQRENTALS_BOOKINGS', get(response, 'data.bookings'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_HQRENTALS_LOADING', {subject: 'bookings', loading: false});
        });
    },

    fetchContacts({commit, rootGetters}, provider_uuid) {
        commit('SET_HQRENTALS_LOADING', {subject: 'contacts', loading: true});

        axios.get(`/v1/company/${rootGetters['company/company'].uuid}/connections/hq-rentals/${provider_uuid}/contacts`)
            .then((response) => {
                commit('SET_HQRENTALS_CONTACTS', get(response, 'data.contacts'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_HQRENTALS_LOADING', {subject: 'contacts', loading: false});
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
