<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="flex items-center">
          Price Structure
          <TheHelpLink url="https://help.fleetwire.io/en/article/advanced-pricing-cxlndp/?bust=1666982473968"></TheHelpLink>
          <v-spacer/>
          <v-btn size="small" @click="$router.push({ name: 'settings.pricing.template.new'}).catch(() => {})">
            <v-icon class="mr-1" size="small">mdi-plus</v-icon>
            Structure
          </v-btn>
        </div>
      </v-card-title>
      <v-card-subtitle>
        Create template pricing structures for multiple products following the same pricing structure.
      </v-card-subtitle>
      <v-card-text class="mt-3">
        <v-table v-if="pricingStructures.length > 0">
          <template v-slot:default>
            <thead>
            <tr>
              <th>Name</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(structure, index) in pricingStructures" :key="index">
              <td>
                <v-btn color="blue" variant="plain" @click="$router.push({ name: 'settings.pricing.template.edit', params: { uuid: structure.uuid } }).catch(() => {})">
                  {{ structure.name }}
                </v-btn>
              </td>
              <td class="text-right">
                <v-btn size="small" variant="outlined" @click="$router.push({ name: 'settings.pricing.template.edit', params: { uuid: structure.uuid } }).catch(() => {})">
                  <v-icon class="mr-2" size="small">mdi-calculator</v-icon>
                  Preview
                </v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-table>
        <div v-else>
          No Pricing Structures
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/eventBus';
import { mapActions, mapGetters } from 'vuex';


export default {
  name: 'PricingTemplate',


  data() {
    return {
      headers: [
        { text: '#', value: 'id', width: 60 },
        { text: 'Listing Name', value: 'name' },
        { text: 'Status', value: 'active' },
        { text: 'ID', value: 'uuid' },
        { text: 'Type', value: 'rule_type' },
        { text: '', value: 'actions' },
      ],
      EventBus,
    };
  },

  created() {
    this.getPricingStructures();

    EventBus.$on('create-pricing-rules-success', () => {
      this.getPricingRules();
    });
    EventBus.$on('delete-pricing-rules-success', () => {
      this.getPricingRules();
    });
  },

  computed: {
    ...mapGetters({
      pricingStructures: 'pricing/pricingStructures',
    }),
  },

  methods: {
    ...mapActions({
      getPricingStructures: 'pricing/getPricingStructures',
    }),
  },

  beforeUnmount() {
    EventBus.$off('create-pricing-rules-success');
    EventBus.$off('delete-pricing-rules-success');
  },
};
</script>
