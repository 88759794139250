<template>
  <div>

    <h1 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Change Subscription</h1>

    <div class="grid gap-5 mt-10 lg:grid-cols-[60%_auto]">

      <div>
        <!-- Plan Selection -->
        <SubscriptionSteps/>

        <!-- Terms & Discounts -->
        <SubscriptionTermsAndDiscount/>

        <!-- Review & Confirm -->
        <SubscriptionReviewAndConfirm/>
      </div>

      <div>
        <SubscriptionOverview/>
      </div>
    </div>

  </div>
</template>

<script lang="ts" setup>
import {onMounted, watch} from 'vue';
import {useSubscription} from '@/composables/useSubscription';
import {usePayments} from '@/composables/usePayments';
import {useStripe} from '@/composables/useStripe';
import {useSubscriptionManager} from '@/composables/useSubscriptionManager';
import SubscriptionSteps from '@/views/dashboard/settings/Billing/SubscriptionCheckout/SubscriptionSteps.vue';
import SubscriptionTermsAndDiscount
  from '@/views/dashboard/settings/Billing/SubscriptionCheckout/SubscriptionTermsAndDiscount.vue';
import SubscriptionReviewAndConfirm
  from '@/views/dashboard/settings/Billing/SubscriptionCheckout/SubscriptionReviewAndConfirm.vue';
import SubscriptionOverview from '@/views/dashboard/settings/Billing/SubscriptionCheckout/SubscriptionOverview.vue';

const {
  subscriptionPlan,

} = useSubscription();

const {billingSessionUrl} = usePayments()

const {fetchStripePriceData} = useStripe();

const {

  updatedSubscription
} = useSubscriptionManager();

onMounted(() => {
  fetchStripePriceData()
})

watch(subscriptionPlan, (newVal) => {
  updatedSubscription.value.plan = newVal;
});

watch(billingSessionUrl, (newUrl) => {
  if (newUrl) {
    window.open(newUrl, '_blank');
  }
});
</script>

<style>
.subscription__card {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 12px;
  justify-content: space-between;
  align-items: start;
}

.subscription__card--first {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
}

.subscription__card--second {
  display: grid;
  gap: 4px;
  grid-template-rows: repeat(2, auto);
  justify-items: end;
  padding-top: 12px;
}

.subscription__card--details {
  display: inline-grid;
  gap: 6px;
  font-size: 16px;
  color: rgb(62, 52, 70);
}

.subscription__card--title {
  display: flex;
  align-items: center;
  flex-flow: row;
  gap: 8px;
  flex-wrap: wrap;
}

.subscription__card--description {
  line-height: 1.5;
  font-size: 14px;
  color: rgb(128, 112, 143);
  margin: 0;
}

.subscription__card--bullets {
  display: grid;
  grid-template-rows: auto;
  gap: 8px;
  font-size: 14px;
  color: rgb(128, 112, 143);
}

.subscription__card--bullet {
  display: flex;
  gap: 8px;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.subscription__card--price-title {
  color: rgb(128, 112, 143);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}

.subscription__card--price-price {
  display: inline-grid;
  grid-template-columns: repeat(3, auto);
  color: rgb(62, 52, 70);
}

.subscription__card--summary-card {
  background: rgb(255, 255, 255);
  border-radius: 6px;
  border: 1px solid rgb(224, 220, 229);
  margin-bottom: 16px;
  position: relative;
  display: grid;
  grid-template-rows: repeat(2, auto);
  gap: 30px;
  padding: 30px 20px 20px;
}

.subscription__card--summary-card-title {
  display: grid;
  grid-template-rows: repeat(2, auto);
  gap: 12px;
  font-size: 18px;
  place-items: center;
  text-align: center;
}

.subscription__card--summary-card-pricing {
  display: inline-grid;
  grid-template-columns: repeat(3, auto);
  font-size: 56px;
}

.subscription__card--summary-card-details {
  color: rgb(128, 112, 143);
  font-size: 16px;
}

.subscription__card--summary-card-details-row1 {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
  padding: 16px 0;
  border-top: 1px solid rgb(240, 236, 243);
}

.subscription__card--summary-card-details-row1-title {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: rgb(128, 112, 143);
  margin-bottom: 8px;
}

.plan-selected {
  background-color: #e8e8e8;
}

@media (max-width: 768px) {
  .subscription__card {
    grid-template-columns: 1fr;
  }

  .subscription__card--first {
    grid-template-columns: 1fr;
  }

  .subscription__card--second {
    justify-items: start;
  }

  .subscription__card--summary-card-pricing {
    font-size: 36px;
  }
}
</style>
