<template>
  <div>
    <div class="grid grid-cols-1">
      <div class="">

        <div class="mb-4 font-bold">
          <div class="flex">
            <span class="small flex-fill">
              <v-btn :to="{ name: 'settings.providers.webhooks' }" class="mr-2" size="small" variant="text">
                <v-icon size="small">mdi-arrow-left</v-icon>
              </v-btn>
              <v-icon size="small">mdi-webhook</v-icon> WEBHOOK
            </span>
            <small>
              uuid: {{ webhookClone.uuid }}
              <v-progress-circular v-if="webhookLoading.webhooks || webhookLoading.webhooksDelete" :size="12" :width="1" indeterminate></v-progress-circular>
            </small>
          </div>
          <div class="flex">
            <div class="flex-fill">{{ webhookClone.url }}</div>
            <div>
              <v-menu>
                <template v-slot:activator="{ props }">
                  <v-btn size="small" v-bind="props">
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="showEditForm = !showEditForm">
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 my-5">
      <div>
        <v-card v-show="showEditForm" :disabled="!includedInSubscription('webhooks') || webhookLoading.webhooks || webhookLoading.webhooksUpdate || webhookLoading.webhooksDelete">
          <v-card-title>
            Listen to Stripe events
            <v-spacer/>
            <v-btn size="small" @click="showEditForm = !showEditForm">Close</v-btn>
          </v-card-title>
          <v-card-subtitle>
            Set up your webhook endpoint to receive live events from Fleetwire.
          </v-card-subtitle>

          <v-card-text>
            <v-form>
              <v-text-field
                v-model="webhookClone.url"
                label="URL Endpoint"
                variant="outlined"
              ></v-text-field>
              <v-text-field
                v-model="webhookClone.description"
                label="Description"
                variant="outlined"
              ></v-text-field>

              <v-combobox
                v-model="webhookClone.events"
                :items="items"
                density="compact"
                label="Select events to listen to"
                multiple
                variant="outlined"
              ></v-combobox>
              <v-select
                v-model="webhookClone.status"
                :items="['enabled', 'disabled']"
                label="Status"
                variant="outlined"
              ></v-select>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn :loading="webhookLoading.webhooksDelete" color="red" size="small" variant="text" @click="deleteWebhook(webhookClone)">
              <v-icon size="small">mdi-delete</v-icon>
            </v-btn>
            <v-spacer/>
            <v-btn size="small" variant="text" @click="showEditForm = !showEditForm">Close</v-btn>
            <v-btn :disabled="!webhookFormReady" :loading="webhookLoading.webhooksUpdate" @click="updateWebhookEndpoint(webhookClone)">
              Update Endpoint
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>

    <div class="mt-5 border-t">
      <div class="flex">
        <div class="border-e py-2 px-4">
          <span class="block text-gray-600">Status</span>
          <span v-if="webhookClone.status === 'enabled'" class="badge badge-success">
            Enabled
          </span>
          <span v-if="webhookClone.status === 'disabled'" class="badge badge-danger">
            Disabled
          </span>
        </div>
        <div class="border-e py-2 px-4">
          <span class="block text-gray-600">Listening for</span>
          <span class="badge badge-secondary">
            {{ webhookClone.events.length }} Events
          </span>
        </div>
        <div class="border-e py-2 px-4">
          <span class="block text-gray-600">Signing secret</span>
          <span>
            <v-btn v-if="!webhookEndpointSecret" :loading="webhookLoading.secret" size="x-small" variant="text" @click="getWebhookEndpointSecret(webhookClone.uuid)">Reveal</v-btn>
            <span v-if="webhookEndpointSecret" class="small">{{ webhookEndpointSecret }}</span>
          </span>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <div class="flex">
        <v-card :disabled="webhookLoading.webhookConversationResend">
          <v-card-text>
            <div style="min-width: 500px; width: 500px">
              <div v-for="(conversations, date) in orderedConversations" :key="date">
                <div class="border-b w-100 mt-5">
                  <strong>{{ date }}</strong>
                </div>
                <div v-for="(conversation) in conversations" :key="conversation.uuid" :class="conversationListClassObject(conversation)" class="cursor-pointer hover:bg-blue-100" @click="selectConversation(conversation)">
                  <div class="py-1 px-3 flex">
                    <div class="flex-fill">
                      <v-icon v-if="conversation.result_type === 'success'" color="green" size="small">
                        mdi-check-circle
                      </v-icon>
                      <v-icon v-else-if="conversation.result_type === 'error'" color="red" size="small">
                        mdi-close-circle
                      </v-icon>
                      <v-icon v-else color="orange" size="small">mdi-help-circle</v-icon>
                      {{ webhookEventLabel(conversation) }}
                    </div>
                    <div>
                      {{ webhookEventLabelTime(conversation) }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="webhookConversations.length === 0">No Events yet</div>
            </div>
          </v-card-text>
        </v-card>

        <div :class="conversationResultClassObject" class="flex-fill p-2">
          <v-card v-show="currentWorkingConversation.uuid" class="flex-fill" flat>
            <v-card-title>
              {{ webhookEventLabel(currentWorkingConversation) }}
              <v-spacer/>
              <v-btn :loading="webhookLoading.webhookConversationResend" size="small" variant="outlined" @click="resendWebhookConversationInit(currentWorkingConversation)">
                <v-icon class="mr-2" size="x-small">mdi-restore</v-icon>
                Resend
              </v-btn>
            </v-card-title>
            <v-card-subtitle>
              Created: <small>{{ webhookEventLabelTime(currentWorkingConversation) }}</small>
            </v-card-subtitle>
            <v-card-text>
              <div>
                <label class="block font-bold">Response:</label>
                <div>{{ currentWorkingConversation.http_status }}</div>
                <div style="max-width: 500px; max-height: 250px; overflow: auto">
                  {{ currentWorkingConversation.response_body }}
                </div>
              </div>
              <div class="mt-3">
                <label class="block font-bold">Request:</label>
                <div class="response-code">
                  <pre>{{ currentWorkingConversation.request_body }}</pre>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>

    <v-snackbar v-model="updateSuccess">
      Webhook updated Successfully
      <template v-slot:actions>
        <v-btn variant="text" @click="updateSuccess = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { cloneDeep, groupBy, orderBy, clone } from 'lodash';
import { EventBus } from '@/plugins/eventBus';
import dayjs from 'dayjs';
import { useCompany } from '@/composables/useCompany';
import { useSubscription } from '@/composables/useSubscription';
import { useStore } from 'vuex';

export default {
  name: 'SettingsWebhookUpdate',

  setup() {
    const { getters, dispatch } = useStore();
    const { company, companyLoading } = useCompany();
    const { includedInSubscription } = useSubscription();

    const showEditForm = ref(false);
    const updateSuccess = ref(false);
    const webhookClone = ref({
      uuid: '',
      url: '',
      description: '',
      status: '',
      events: [],
    });
    const currentWorkingConversation = ref({});
    const items = ref([
      'reservation.created',
    ]);

    const conversationResultClassObject = ref({
      'bg-red-100': currentWorkingConversation.value?.result_type === 'error',
      'bg-green-100': currentWorkingConversation.value?.result_type === 'success',
    });

    // computed
    const webhookLoading = computed(() => getters['webhook/loading']);
    const currentWebhook = computed(() => getters['webhook/webhookEndpoint']);
    const webhookConversations = computed(() => getters['webhook/webhookConversations']);
    const webhookEndpointSecret = computed(() => getters['webhook/webhookEndpointSecret']);

    const webhookFormReady = computed(() => {
      for (const [key, value] of Object.entries(webhookClone.value)) {
        if (!['metadata', 'type'].includes(key)) {
          if (!value) return false;
        }
      }
      return true;
    });

    const orderedConversations = computed(() => {
      const conversation = groupBy(webhookConversations.value, (c) => dayjs(c.created_at).format('MMM DD, YYYY'));

      const orderedDates = {};
      // @ts-ignore: The right-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
      Object.keys(conversation).sort((a, b) => dayjs(b).toDate() - dayjs(a).toDate()).forEach((key) => {
        orderedDates[key] = conversation[key];
      });

      Object.keys(orderedDates).forEach((element) => {
        orderedDates[element] = orderBy(orderedDates[element], 'created_at', 'desc');
      });

      return orderedDates;
    });


    const getWebhookEndpoint = (uuid) => dispatch('webhook/getWebhookEndpoint', uuid);
    const getWebhookConversations = (uuid) => dispatch('webhook/getWebhookConversations', uuid);
    const resendWebhookConversation = (conversation) => dispatch('webhook/resendWebhookConversation', conversation);
    const updateWebhookEndpoint = (webhook) => dispatch('webhook/updateWebhookEndpoint', webhook);
    const deleteWebhook = (webhook) => dispatch('webhook/deleteWebhookEndpoint', webhook);
    const getWebhookEndpointSecret = (uuid) => dispatch('webhook/getWebhookEndpointSecret', uuid);


    const webhookEventLabel = (conversation) => conversation?.event;

    const webhookEventLabelTime = (conversation) => dayjs(conversation.created_at).format('H:mm');

    const selectConversation = (conversation) => currentWorkingConversation.value = clone(conversation);

    const conversationListClassObject = (conversation) => ({
      'bg-blue-100': currentWorkingConversation.value?.uuid === conversation.uuid,
      'is-selected': currentWorkingConversation.value?.uuid === conversation.uuid,
    });

    const resendWebhookConversationInit = (conversation) => resendWebhookConversation(conversation);

    return {
      company,
      webhookLoading,
      companyLoading,
      showEditForm,
      updateSuccess,
      webhookClone,
      currentWorkingConversation,
      items,
      currentWebhook,
      webhookConversations,
      webhookEndpointSecret,
      conversationResultClassObject,
      webhookFormReady,
      orderedConversations,
      webhookEventLabel,
      includedInSubscription,
      getWebhookConversations,
      getWebhookEndpoint,
      resendWebhookConversation,
      updateWebhookEndpoint,
      deleteWebhook,
      getWebhookEndpointSecret,
      webhookEventLabelTime,
      selectConversation,
      conversationListClassObject,
      resendWebhookConversationInit,
    };
  },

  created() {
    EventBus.$on('update-webhook-endpoint-success', () => {
      this.updateSuccess = true;
      this.showEditForm = false;
    });
    EventBus.$on('webhook-conversation-created-success', () => {
      this.getWebhookConversations(this.$route.params.uuid);
    });
    EventBus.$on('delete-webhook-endpoint-success', () => {
      this.$router.push({ name: 'settings.providers.webhooks' }).catch(() => {
      });
    });
  },

  watch: {
    company: {
      immediate: true,
      handler(companyNew) {
        if (companyNew.uuid) {
          this.getWebhookEndpoint(this.$route.params.uuid);
          this.getWebhookConversations(this.$route.params.uuid);
        }
      },
    },
    currentWebhook: {
      immediate: true,
      handler(webhook) {
        if (webhook?.uuid) {
          this.webhookClone = cloneDeep(webhook);
          this.webhookClone.events = this.webhookClone.events.map((e) => e.event);
        }
      },
    },
    orderedConversations: {
      immediate: true,
      handler(conversation) {
        if (Object.keys(conversation).length > 0) {
          this.currentWorkingConversation = clone(conversation[Object.keys(conversation)[0]][0]);
        }
      },
    },
  },

  beforeUnmount() {
    this.webhookClone = null;
    this.webhookClone = {
      uuid: '',
      url: '',
      description: '',
      events: [],
    };

    EventBus.$off('update-webhook-endpoint-success');
    EventBus.$off('webhook-conversation-created-success');
    EventBus.$off('delete-webhook-endpoint-success');
  },
};
</script>

<style>
.response-code {
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 85%;
  font-weight: 400;
}

.is-selected {
  background-color: #f6f8fa;
  box-shadow: inset 0 -1px 0 0 #ebeef1, inset 4px 0 0 0 #81b3fe;
  font-weight: 500;
}
</style>
