import axios from 'axios';
import {Form} from '@/plugins/FWForms/index';
import {EventBus} from '@/plugins/eventBus';
import {get} from 'lodash';
import * as types from '../mutation-types';

export interface LulaState {
    init: boolean;
    loading: Record<string, boolean>;
    form: Form<any>;
    connections: any[];
    vehicles: any[];
}

const state: LulaState = {
    init: false,
    loading: {
        login: false,
        vehicles: false,
        matching: false,
    },
    form: new Form(),
    connections: [],
    vehicles: [],
};

const getters = {
    init: (state: LulaState) => state.init,
    loading: (state: LulaState) => state.loading,
    form: (state: LulaState) => state.form,
    connections: (state: LulaState) => state.connections,
    vehicles: (state: LulaState) => state.vehicles,
};

const mutations = {
    [types.SET_LULA_LOADING](state: LulaState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_LULA_CONNECTIONS](state: LulaState, connections) {
        state.connections = connections;
    },
    [types.SET_LULA_VEHICLES](state: LulaState, vehicles) {
        state.vehicles = vehicles;
    },
};

const actions = {


    /**
     * Get tax categories
     */
    login({commit}, payload) {
        commit('SET_LULA_LOADING', {subject: 'login', loading: true});

        axios.post('/v1/lula/login', payload)
            .then(() => {
                // commit('SET_LULA_CONNECTIONS', get(response, 'data.lula', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_LULA_LOADING', {subject: 'login', loading: false});
        });
    },

    fetchVehicles({commit}, payload) {
        commit('SET_LULA_LOADING', {subject: 'vehicles', loading: true});

        axios.post('/v1/lula/vehicles', payload)
            .then((response) => {
                commit('SET_LULA_VEHICLES', get(response, 'data.vehicles', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_LULA_LOADING', {subject: 'vehicles', loading: false});
        });
    },

    /**
     * Match Lula Vehicles
     */
    matchLulaVehicle({commit}, data) {
        commit('SET_LULA_LOADING', {subject: 'vehicles', loading: true});

        axios.post('/v1/lula/match-vehicle', data)
            .then(() => {
                EventBus.$emit('match-lula-vehicle-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_LULA_LOADING', {subject: 'vehicles', loading: false});
        });
    },

    /**
     * UnMatch Lula Vehicles
     */
    unMatchLulaVehicle({commit}, data) {
        commit('SET_LULA_LOADING', {subject: 'matching', loading: true});

        axios.post('/v1/lula/remove-matched-vehicle', data)
            .then(() => {
                EventBus.$emit('match-lula-vehicle-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_LULA_LOADING', {subject: 'matching', loading: false});
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
