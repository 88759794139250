<template>
  <div class="Div-bMiTSW Content-jqUogp  bymsnf">
    <div class="Preview-jvKtmK bymkXl" mode="desktop">
      <iframe id="preview" class="Frame-hJxVdP fiTgfO" src="http://fleetwire.test/theme-test1/e7d76370-0635-11ee-9558-3f5153343edb"></iframe>
    </div>
  </div>
</template>

<script lang="ts">
import {EventBus} from '@/plugins/eventBus';
import {mapActions} from 'vuex';
import {get} from 'lodash';

export default {
  name: 'ThemeMainArea',

  created() {
    EventBus.$on('mouse-over-theme-component', data => {
      const selector = this.findBlockElement(data);

      this.toggleBorderAttribute(selector, 'add');
    });
    EventBus.$on('mouse-leave-theme-component', data => {
      const selector = this.findBlockElement(data);
      this.toggleBorderAttribute(selector, 'remove');
    });
    EventBus.$on('update-theme-settings-success', () => {
      console.dir('update-theme-settings-success');
      document.getElementById('preview').contentWindow.location.reload();
    });
  },

  methods: {
    ...mapActions({
      getExportDetails: 'settings/getExportDetails',
    }),
    toggleBorderAttribute(selector, action) {
      const iframe = document.getElementById('preview');
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

      const iframeContent = iframeDocument.documentElement;
      const element = iframeContent.querySelector(selector);


      if (element === null) {
        console.error('Element not found');
        return;
      }

      if (action === 'add') {
        element.setAttribute('data-focus', 'true');
      } else {
        element.removeAttribute('data-focus');
      }
    },
    findBlockElement(data) {
      const section = get(data, 'section');
      const id = get(data, 'id');

      let selector = '';
      if (section.indexOf('image-banner') === 0) {
        if (id === null) {
          return `.image-banner-${section}`;
        }
        return `.image-banner-${section} #${id}`;
      }
      if (section.indexOf('columns') === 0) {
        if (id === null) {
          return `.columns-${section}`;
        }
        return `.columns-${section} #${id}`;
      }
      if (section.indexOf('spacer') === 0) {
        selector = `#section-${section}.spacer`;
      }

      return selector;
    },
  },
};
</script>

<style>
.bymsnf {
  padding: 26px 26px 90px;
  flex: 1 1 0;
  order: 2;
  height: calc(100vh - 62px);
  z-index: 29;
  background: rgb(246, 248, 248);
  position: relative;
}

.bymsnf {
  overflow-y: scroll;
}

.bymkXl {
  height: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 15, 0.12) 0 4px 12px;
}

.fiTgfO {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
