import axios from 'axios';
import {get} from 'lodash';
import {EventBus} from '@/plugins/eventBus';
import {StorageService} from '@/services/storage.service';
import * as types from '../mutation-types';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {OrderDocumentType, OrderLine, OrderType} from '@/types';

export interface OrderState {
    loading: Record<string, boolean>;
    orders: OrderType[];
    order: OrderType;
    documents: OrderDocumentType[];
    document: OrderDocumentType | null;
    downloadStatus: any;
    availability: any;
    currentWorkingPricingWizardOrderLine: OrderLine | null;
    dialogs: {
        pricingWizard: boolean;
    };
}

export interface UpdateOrderPayload {
    orderUuid: string;
    attributes?: Record<string, any>;
}

const state: OrderState = {
    loading: {
        orders: false,
        newOrder: false,
        update: false,
        cancelOrder: false,
        reserving: false,
        concept: false,
        deleteOrderDocument: false,
        updateLine: false,
        addLine: false,
        removeLine: false,
        holdDeposit: false,
        addCustomerToOrder: false,
        removeCustomer: false,
        getDocuments: false,
    },
    dialogs: {
        pricingWizard: false,
    },
    currentWorkingPricingWizardOrderLine: null,
    orders: [],
    order: null,
    documents: [],
    document: null,
    downloadStatus: null,
    availability: {},
};

interface AddLinePayload {
    orderUuid: string;
    line: OrderLine;
}

const getters = {
    orders: (state: OrderState) => state.orders,
    order: (state: OrderState) => state.order,
    documents: (state: OrderState) => state.documents,
    document: (state: OrderState) => state.document,
    loading: (state: OrderState) => state.loading,
    downloadStatus: (state: OrderState) => state.downloadStatus,
    availability: (state: OrderState) => state.availability,
    orderDialogs: (state: OrderState) => state.dialogs,
    currentWorkingPricingWizardOrderLine: (state: OrderState) => state.currentWorkingPricingWizardOrderLine,
};

const mutations = {
    [types.SET_ORDERS_LOADING](state: OrderState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_ORDER](state: OrderState, order: OrderType) {
        console.dir('SET_ORDER');
        state.order = order;
    },
    [types.UPDATE_ORDER_LINE](state: OrderState, line) {
        let lines = state.order.lines;

        lines = lines.map((l) => {
            if (l.uuid === line.uuid) {
                return line;
            }
            return l;
        });

        state.order.lines = lines;
    },
    [types.SET_ORDER_DOCUMENTS](state: OrderState, documents: OrderDocumentType[]) {
        state.documents = documents;
    },
    [types.SET_ORDER_DOCUMENT](state: OrderState, document: OrderDocumentType) {
        state.document = document;
    },
    [types.SET_ORDER_DOCUMENT_DOWNLOAD_STATUS](state: OrderState, status) {
        state.downloadStatus = status;
    },
    [types.SET_ORDER_AVAILABILITY](state: OrderState, availability) {
        state.availability = availability;
    },
    [types.SET_OPEN_PRICING_WIZARD_DIALOG](state: OrderState, data) {
        state.currentWorkingPricingWizardOrderLine = data.lineItem;
        state.dialogs.pricingWizard = data.open;
    },
};

const actions = {

    async newOrder({commit}) {
        try {
            const responseData = await performApiRequest(
                commit,
                'SET_ORDERS_LOADING',
                'newOrder',
                () => axios.get('/v1/orders/new')
            );

            commit('SET_ORDER', responseData.order);
            EventBus.$emit('get-new-order-success', responseData.order);
        } catch (error) {
        }
    },

    /**
     * Get order
     */
    async getOrder({commit}, orderUuid: string): Promise<void> {
        try {
            const responseData = await performApiRequest(
                commit,
                'SET_ORDERS_LOADING',
                'getOrder',
                () => axios.get(`/v1/orders/${orderUuid}`)
            );

            commit('SET_ORDER', responseData.order);
        } catch (error) {
            EventBus.$emit('SystemMessage', {
                type: 'error',
                message: error?.response?.data?.message || 'Failed to load order',
            });
        }
    },

    /**
     * Update order
     */
    async update({commit}, {orderUuid, attributes = {}}: UpdateOrderPayload) {
        try {
            const responseData = await performApiRequest(
                commit,
                'SET_ORDERS_LOADING',
                'update',
                () => axios.put(`/v1/orders/${orderUuid}`, {attributes})
            );
            const {order} = responseData;
            commit('SET_ORDER', order);
            EventBus.$emit('update-order-success', order);
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'There was an error';
            EventBus.$emit('SystemMessage', {
                type: 'error',
                message: errorMessage,
            });
        }
    },

    async makeCustomerPrimary({commit}, {orderUuid, r_id}) {
        try {
            const responseData = await performApiRequest(
                commit,
                'SET_ORDERS_LOADING',
                'update',
                () => axios.post(`/v1/orders/${orderUuid}/make-customer-primary`, {r_id})
            );

            commit(types.SET_ORDER, responseData.order);
            EventBus.$emit('update-order-success', responseData.order);
        } catch (error) {
        }
    },


    removeCustomer({commit}, {orderUuid, r_id}) {
        commit('SET_ORDERS_LOADING', {subject: 'removeCustomer', loading: true});

        axios.post(`/v1/orders/${orderUuid}/remove-customer`, {r_id})
            .then((response) => {
                commit('SET_ORDER', response.data.order);
                EventBus.$emit('update-order-success', response.data.order);
            }).catch(() => {
        }).finally(() => {
            commit('SET_ORDERS_LOADING', {subject: 'removeCustomer', loading: false});
        });
    },

    addCustomerToOrder({commit}, {orderUuid, r_id}) {
        commit('SET_ORDERS_LOADING', {subject: 'addCustomerToOrder', loading: true});

        axios.post(`/v1/orders/${orderUuid}/add-customer`, {r_id})
            .then((response) => {
                commit('SET_ORDER', response.data.order);
                EventBus.$emit('update-order-success', response.data.order);
                EventBus.$emit('add-customer-to-order-success', response.data.order);
            }).catch(() => {
        }).finally(() => {
            commit('SET_ORDERS_LOADING', {subject: 'addCustomerToOrder', loading: false});
        });
    },


    /**
     * Reserve a new order.
     */
    reserve({commit}, orderUuid: string) {
        commit('SET_ORDERS_LOADING', {subject: 'reserving', loading: true});

        axios.post(`/v1/orders/${orderUuid}/reserve`)
            .then((response) => {
                commit(types.SET_ORDER, response.data.order);
                EventBus.$emit('reserve-order-success', response.data);
            }).catch(() => {
        }).finally(() => {
            commit('SET_ORDERS_LOADING', {subject: 'reserving', loading: false});
        });
    },


    /**
     * Create a new order concept.
     */
    concept({commit}, orderUuid: string) {
        commit('SET_ORDERS_LOADING', {subject: 'concept', loading: true});

        axios.post(`/v1/company/${StorageService.getCompanyUuid()}/orders/${orderUuid}/concept`)
            .then((response) => {
                commit('SET_ORDER', response.data.order);
                EventBus.$emit('reserve-order-success', response.data);
            }).catch((e) => {
            EventBus.$emit('set-order-concept-error', e.response.data);
        }).finally(() => {
            commit('SET_ORDERS_LOADING', {subject: 'concept', loading: false});
        });
    },


    /**
     * Check availability of a new order.
     */
    availability({commit}, orderUuid: string) {
        commit('SET_ORDERS_LOADING', {subject: 'availability', loading: true});

        axios.get(`/v1/orders/${orderUuid}/availability`)
            .then((response) => {
                commit('SET_ORDER_AVAILABILITY', get(response, 'data.available', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_ORDERS_LOADING', {subject: 'availability', loading: false});
        });
    },


    /**
     * Hold the deposit of an order
     */
    holdOrderDeposit({commit}, {orderUuid, attributes}) {
        commit('SET_ORDERS_LOADING', {subject: 'holdDeposit', loading: true});

        axios.post(`/v1/orders/${orderUuid}/hold-deposit`, attributes)
            .then(() => {
                EventBus.$emit('hold-order-deposit-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_ORDERS_LOADING', {subject: 'holdDeposit', loading: false});
        });
    },


    /**
     * Create a new quote
     */
    createNewQuote({commit}, attributes) {
        commit('SET_ORDERS_LOADING', {subject: 'update', loading: true});

        axios.post('/v1/quotes', attributes)
            .then((response) => {
                EventBus.$emit('create-new-order-quote-success', get(response, 'data.document'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_ORDERS_LOADING', {subject: 'update', loading: false});
        });
    },

    createNewOrderDocument({commit}, attributes) {
        commit('SET_ORDERS_LOADING', {subject: 'update', loading: true});

        axios.post('/v1/order-documents', attributes)
            .then((response) => {
                EventBus.$emit('create-new-order-quote-success', get(response, 'data.document'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_ORDERS_LOADING', {subject: 'update', loading: false});
        });
    },


    getAllOrderDocuments({commit}) {
        commit('SET_ORDERS_LOADING', {subject: 'update', loading: true});

        axios.get('/v1/order-documents')
            .then((response) => {
                commit('SET_ORDER_DOCUMENTS', get(response, 'data.documents'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_ORDERS_LOADING', {subject: 'update', loading: false});
        });
    },


    /**
     *Get the orders documents (rental agreement envelope, invoice, etc)
     */
    getOrderDocuments({commit}, orderUuid: string) {
        commit('SET_ORDERS_LOADING', {subject: 'getDocuments', loading: true});

        axios.get(`/v1/order-documents?order_id=${orderUuid}`)
            .then((response) => {
                commit('SET_ORDER_DOCUMENTS', get(response, 'data.documents'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_ORDERS_LOADING', {subject: 'getDocuments', loading: false});
        });
    },

    async getOrderDocument({commit}, documentUuid: string) {
        const responseData = await performApiRequest(
            commit,
            'SET_ORDERS_LOADING',
            'update',
            () => axios.get(`/v1/order-documents/${documentUuid}`)
        );

        commit(types.SET_ORDER_DOCUMENT, responseData?.document);

        return responseData?.document;
    },

    updateOrderDocument({commit}, {documentUuid, attributes}) {
        commit('SET_ORDERS_LOADING', {subject: 'update', loading: true});

        axios.put(`/v1/order-documents/${documentUuid}`, {attributes})
            .then((response) => {
                commit('SET_ORDER_DOCUMENT', get(response, 'data.document'));
                EventBus.$emit('update-order-document-success', get(response, 'data.document'));
            }).catch((e) => {
            EventBus.$emit('update-order-document-error', e.response);
            EventBus.$emit('SystemMessage', {
                type: 'error',
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_ORDERS_LOADING', {subject: 'update', loading: false});
        });
    },

    async deleteOrderDocument({commit}, documentUuid: string) {
        console.dir('deleteOrderDocument');
        return await performApiRequest(
            commit,
            'SET_ORDERS_LOADING',
            'deleteOrderDocument',
            () => axios.delete(`/v1/order-documents/${documentUuid}`)
        );
    },

    downloadOrderDocument({commit}, documentUuid: string) {
        commit('SET_ORDERS_LOADING', {subject: 'update', loading: true});

        axios.get(`/v1/company/${StorageService.getCompanyUuid()}/order-documents/${documentUuid}/download`)
            .then((response) => {
                commit('SET_ORDER_DOCUMENT_DOWNLOAD_STATUS', response.data);
                EventBus.$emit('start-order-document-download-success', response.data);
            }).catch(() => {
        }).finally(() => {
            commit('SET_ORDERS_LOADING', {subject: 'update', loading: false});
        });
    },


    /**
     * Add a line item to an order
     */
    async addLine({commit}, {orderUuid, line}: AddLinePayload) {
        try {
            const responseData = await performApiRequest(
                commit,
                'SET_ORDERS_LOADING',
                'addLine',
                () => axios.post(`/v1/orders/${orderUuid}/line`, {line})
            );

            EventBus.$emit('add-order-line-success', responseData.order);
            commit('SET_ORDER', responseData.order);
        } catch (error) {
        }
    },

    async updateLine({commit}, {line, attributes}: { line: string, attributes: Record<string, any> }) {
        const responseData = await performApiRequest(
            commit,
            'SET_ORDERS_LOADING',
            'updateLine',
            () => axios.put(`/v1/line/${line}`, {attributes})
        );

        commit('SET_ORDER', responseData.order);
        EventBus.$emit('update-order-success', responseData.order);
    },

    updateLines({commit}, lines) {
        commit('SET_ORDERS_LOADING', {subject: 'updateLine', loading: true});

        axios.put('/v1/lines', {lines})
            .then(() => {
                EventBus.$emit('update-order-lines-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_ORDERS_LOADING', {subject: 'updateLine', loading: false});
        });
    },


    async removeLine({commit}, line: string) {
        try {
            await performApiRequest(
                commit,
                'SET_ORDERS_LOADING',
                'removeLine',
                () => axios.delete(`/v1/line/${line}`)
            );
        } catch (error) {
        }
    },

    /**
     * Get a line item model (ex. location model)
     */
    getOrderItemModel({commit}, data) {
        commit('SET_ORDERS_LOADING', {subject: 'itemModel', loading: true});

        axios.get(`/v1/line-item-model/${data.model}/${data.id}`)
            .then((response) => {
                EventBus.$emit('get-order-line-model-success', get(response, 'data.model'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_ORDERS_LOADING', {subject: 'itemModel', loading: false});
        });
    },


    /**
     * Cancel an order
     *
     * @param commit
     * @param {String} orderUuid The orders UUID
     */
    cancelOrder({commit}, orderUuid: string) {
        commit('SET_ORDERS_LOADING', {subject: 'cancelOrder', loading: true});

        axios.post(`/v1/orders/${orderUuid}/cancel`)
            .then(() => {
                EventBus.$emit('cancel-order-success');
            }).catch((e) => {
            EventBus.$emit('cancel-order-failure', get(e, 'response.data.message', 'There was an error'));
        }).finally(() => {
            commit('SET_ORDERS_LOADING', {subject: 'cancelOrder', loading: false});
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
