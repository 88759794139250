<template>
  <div class="space-y-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium text-gray-900 dark:text-gray-100">General</h3>
          <p class="mt-1 text-sm text-gray-600 dark:text-gray-400">
            Configure how currency is displayed across your fleet. This affects all money values shown in the app,
            booking emails, SMS notifications, and Stripe payments.
            Need a different currency? Contact support to have it added.
          </p>
        </div>
      </div>


      <div class="mt-4 md:mt-0 md:col-span-2">
        <v-card :disabled="companyLoading.saveCurrency" class="mb-5">
          <v-card-text>
            <div class="space-y-4">
              <v-select
                v-model="settings['settings.pricing.currency.currency']"
                :items="appCurrencies"
                class="w-full"
                item-title="name"
                item-value="code"
                label="Currency"
                variant="outlined"
                @update:model-value="val => updateDefaultMeta('settings.pricing.currency.currency', val)"
              >
                <template v-slot:item="{ props, item }">
                  <v-list-item v-bind="props">
                    {{ item.raw.name }} ({{ item.raw.code }}) {{ item.raw.symbol }}
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <v-list-item>
                    {{ item.raw.name }} ({{ item.raw.code }}) {{ item.raw.symbol }}
                  </v-list-item>
                </template>
              </v-select>

              <div class="text-sm">
                <b>Example:</b> {{ currencyExample }}
              </div>

              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <v-select
                  v-model="settings['settings.pricing.currency.format']"
                  :items="currencyDisplay"
                  class="w-full"
                  item-title="name"
                  item-value="value"
                  label="Display currency as"
                  variant="outlined"
                  @update:model-value="val => updateDefaultMeta('settings.pricing.currency.format', val)"
                />
                <v-select
                  v-model="settings['settings.pricing.currency.position']"
                  :items="currencyPosition"
                  class="w-full"
                  item-title="name"
                  item-value="value"
                  label="Currency position"
                  variant="outlined"
                  @update:model-value="val => updateDefaultMeta('settings.pricing.currency.position', val)"
                />
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <GlobalPriceAdjustment/>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref, watch} from 'vue';
import GlobalPriceAdjustment from '@/components/dashboard/settings/pricing/GlobalPriceAdjustment.vue';
import {useCompany} from '@/composables/useCompany';
import {useAppSettings} from '@/composables/useAppSettings';
import {useDefaultMeta} from '@/composables/useDefaultMeta';


interface CurrencyShape {
  decimal: string;
  format: string;
  name: string;
  precision: number;
  symbol: string;
  thousand: string;
}

interface DisplayOption {
  name: string;
  value: string;
}

const DEFAULT_CURRENCY: CurrencyShape = {
  decimal: '.',
  format: '%s%v',
  name: 'USD',
  precision: 2,
  symbol: '$',
  thousand: ',',
};

// Composables
const {company, companyLoading} = useCompany();
const {appCurrencies, currentCurrency, fetchCurrency} = useAppSettings();
const {state: settings, updateDefaultMeta} = useDefaultMeta({
  'settings.pricing.currency.format': {type: 'string', default: 'symbol'},
  'settings.pricing.currency.position': {type: 'string', default: 'left'},
  'settings.pricing.currency.currency': {type: 'string', default: 'USD'},
});


const currentLocalCurrency = ref<CurrencyShape>({...DEFAULT_CURRENCY});

const currencyExample = computed(() => {
  const example = '312.00';
  const displaySymbol = settings['settings.pricing.currency.format'] === 'symbol';
  const symbol = displaySymbol ? currentLocalCurrency.value.symbol : currentLocalCurrency.value.name;

  return settings['settings.pricing.currency.position'] === 'left'
    ? `${symbol} ${example}`
    : `${example} ${symbol}`;
});

const currencyDisplay = computed<DisplayOption[]>(() => [
  {name: `Symbol (${currentLocalCurrency.value.symbol})`, value: 'symbol'},
  {name: `Code (${currentLocalCurrency.value.name})`, value: 'code'},
]);

const currencyPosition = computed<DisplayOption[]>(() => [
  {name: `${currentLocalCurrency.value.symbol} 123.00`, value: 'left'},
  {name: `123.00 ${currentLocalCurrency.value.symbol}`, value: 'right'},
]);

// Watchers
watch(company, (newCompany) => {
  if (newCompany?.uuid) {
    currentLocalCurrency.value = {...newCompany.currency};
  }
}, {immediate: true});

watch(() => settings['settings.pricing.currency.currency'], (code) => {
  if (code) fetchCurrency(code);
});

watch(currentCurrency, (currency) => {
  if (!currency) return;

  currentLocalCurrency.value = {
    ...DEFAULT_CURRENCY,
    name: currency.name,
    symbol: currency.symbol ?? DEFAULT_CURRENCY.symbol,
  };
});
</script>
