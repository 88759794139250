/**
 * Deep copy the given object.
 *
 * @param  {Object} obj
 * @return {Object}
 */
export function deepCopy<T>(obj: T): T {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    const copy: any = Array.isArray(obj) ? [] : {};

    Object.keys(obj).forEach((key) => {
        copy[key] = deepCopy((obj as any)[key]);
    });

    return copy as T;
}

/**
 * If the given value is not an array, wrap it in one.
 *
 * @param  value
 * @return {Array}
 */
export function arrayWrap<T>(value: T | T[]): T[] {
    return Array.isArray(value) ? value : [value];
}
