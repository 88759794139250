<template>
  <div>
    <h2 class="mt-8 mb-4 font-bold text-2xl">Checkout</h2>

    <Payments/>
    <v-divider class="my-10" opacity="50"/>

    <TermsAndCancellationPolicy/>
    <v-divider class="my-10" opacity="50"/>

    <eSign/>
    <v-divider class="my-10" opacity="50"/>

    <AdditionalScripts/>
    <v-divider class="my-10" opacity="50"/>

    <AdditionalStyling/>
    <v-divider class="my-10" opacity="50"/>

    <div v-if="false" class="my-5">
      <div class="">
        <div class="setting-section-description">
          <FeatureTitle
            featurePremium
            featureStandard
            title="Coupon Codes"
          />
          <div class="feature-description">
            Allow renters to use coupon codes at checkout.
          </div>
        </div>
      </div>
      <div class="">
        <v-card :disabled="!includedInSubscription('coupons')" max-width="100%" variant="outlined">
          <v-card-text>
            <div class="list-group list-group-flush">
              <div class="list-group-item">
                <div class="align-items-center">
                  <div>
                    <p class="mb-0">
                      Accept coupons
                    </p>
                    <small class="text-gray-700">
                      Allow your customers to use coupons
                    </small>
                  </div>
                  <div class="col-auto">
                    <div class="custom-control custom-switch">
                      <v-switch v-model="localAcceptCoupons" :loading="companyLoading.company" inset/>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer/>
            <v-btn :loading="companyLoading.company" location="right" @click="saveCompanyMeta()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {defineAsyncComponent, ref, watch} from 'vue';
import {useCompany} from '@/composables/useCompany';
import {useSubscription} from '@/composables/useSubscription';
import {getMetaBooleanValue} from '@/utils/meta';

const eSign = defineAsyncComponent(() => import('@/components/dashboard/settings/checkout/eSign.vue'));
const AdditionalScripts = defineAsyncComponent(() => import('@/components/dashboard/settings/checkout/AdditionalScripts.vue'));
const AdditionalStyling = defineAsyncComponent(() => import('@/components/dashboard/settings/checkout/AdditionalStyling.vue'));
const FeatureTitle = defineAsyncComponent(() => import('@/components/dashboard/FeatureTitle.vue'));
const TermsAndCancellationPolicy = defineAsyncComponent(() => import('@/components/dashboard/settings/checkout/TermsAndCancellationPolicy.vue'));
const Payments = defineAsyncComponent(() => import('@/components/dashboard/settings/checkout/Payments.vue'));

const {includedInSubscription} = useSubscription();
const {companyLoading, saveCompanyMeta, defaultMeta} = useCompany();

const localAcceptCoupons = ref(false);


watch(defaultMeta, () => {
  localAcceptCoupons.value = getMetaBooleanValue('settings.payment.accept_coupons')
}, {immediate: true});
</script>
