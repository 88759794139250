import {computed, ComputedRef} from 'vue';
import {useStore} from 'vuex';
import {CompanyInterface, CurrencySettings} from '@/types';
import getDefaultMeta from '@/utils/meta';


type MoneyTextInputSettings = {
    prefix: string,
    suffix: string,
}

export function useMoneyFormatter() {
    const store = useStore();

    const company: ComputedRef<CompanyInterface> = computed(() => store.getters['company/company']);

    const DEFAULT_CURRENCY_FORMAT = 'symbol';
    const DEFAULT_CURRENCY_POSITION = 'left';
    const DEFAULT_CURRENCY: CurrencySettings = {
        decimal: '.',
        format: '%s%v',
        name: 'USD',
        precision: 2,
        symbol: '$',
        thousand: ',',
    };

    /**
     * Converts an integer amount (cents) to a decimal value (dollars)
     */
    const fromCents = (amount: number | undefined): number => {
        return Number(((amount ?? 0) * 0.01).toFixed(2));
    };

    /**
     * Converts a decimal value (dollars) to an integer amount (cents)
     */
    const toCents = (amount: number): number => {
        if (isNaN(amount)) {
            return 0;
        }

        console.dir('toCents');
        console.dir(amount);
        return Math.round(amount * 100);
    };

    const formatNumberWithCommas = (x: number | string): string => {
        if (x === null || isNaN(Number(x))) {
            return '0';
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    /**
     * Formats an amount to include commas and decimal places.
     * Handles integer-based currency storage.
     */
    const formatAmount = (amount: number, convertFromInt = true): string => {
        const multiplier = convertFromInt ? 0.01 : 1;
        const formattedAmount = (amount * multiplier).toFixed(2);
        return formatNumberWithCommas(formattedAmount);
    };

    const formatMoneyDisplayV2 = (amount: number, convertFromInt: boolean = true) => {
        const currency = getCompanyCurrency();
        const {format, position} = getCompanyCurrencyFormatSettings();
        return setCurrencyAndValue(formatAmount(amount, convertFromInt), currency, position, format);
    };

    /**
     * Combines currency symbol/name with the value based on position.
     */
    const setCurrencyAndValue = (
        value: string | number,
        currency: CurrencySettings,
        position: 'left' | 'right' = 'left',
        format: 'symbol' | 'name' | string = 'symbol'
    ): string => {
        const symbolOrName = format === 'symbol' ? currency.symbol : currency.name;
        return position === 'left' ? `${symbolOrName}${value}` : `${value}${symbolOrName}`;
    }

    /**
     * Retrieves the company's currency settings or defaults.
     */
    const getCompanyCurrency = (): CurrencySettings => {
        return company.value?.currency || DEFAULT_CURRENCY;
    };

    /**
     * Determines the currency format and position for the company.
     */
    const getCompanyCurrencyFormatSettings = (): { format: string; position: 'left' | 'right' } => {
        return {
            format: getDefaultMeta('settings.pricing.currency.format') as string || DEFAULT_CURRENCY_FORMAT,
            position: getDefaultMeta('settings.pricing.currency.position') as 'left' | 'right' || DEFAULT_CURRENCY_POSITION,
        };
    };


    /**
     * Returns the currency symbol for the company.
     */
    const companyCurrencySymbol = (): string => getCompanyCurrency().symbol;


    const companyMoneyTextInputSettings = computed<MoneyTextInputSettings>(() => {
        const currency = getCompanyCurrency();
        const {format, position} = getCompanyCurrencyFormatSettings();
        const symbol = format === 'symbol' ? currency.symbol : currency.name;

        return {
            prefix: position === 'left' ? symbol : '',
            suffix: position === 'right' ? symbol : '',
        };
    });

    /**
     * Formats money without adding a currency symbol.
     */
    const formatMoney = (amount: number, convertFromInt = true): string => {
        return amount ? formatAmount(amount, convertFromInt) : '0';
    };

    /**
     * Formats money specifically in USD.
     */
    const formatMoneyDisplayUSD = (amount: number, convertFromInt = true): string => {
        return `$${formatMoney(amount, convertFromInt)}`;
    };

    /**
     * Returns the current currency symbol.
     */
    const currencySymbol = (): string => getCompanyCurrency().symbol;


    return {
        companyMoneyTextInputSettings,
        currencySymbol,
        formatMoney,
        formatAmount,
        formatMoneyDisplayV2,
        setCurrencyAndValue,
        companyCurrencySymbol,
        formatMoneyDisplayUSD,
        formatNumberWithCommas,
        fromCents,
        toCents,
    };
}
