<template>
  <section class="bg-gray-50 dark:bg-gray-900">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white" href="/">
        <img alt="logo" class="w-8 h-8 mr-2" src="https://tenant.fleetwire.io/images/logo/fleetwire-logo-new.png">
        Fleetwire
      </a>
      <div class="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
        <h1 class="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
          {{ $t('forgot_password') }}
        </h1>
        <p class="font-light text-gray-500 dark:text-gray-400">{{ $t('forgot_password_p') }}</p>
        <v-form v-show="!form.successful" :disabled="form.busy" class="mt-4 space-y-4 lg:mt-5 md:space-y-5" @submit.prevent="send">
          <div>
            <v-text-field
              v-model="form.email"
              :autofocus="true"
              :label="$t('email')"
              density="comfortable"
              required
              type="email"
              variant="outlined"
            />
          </div>
          <v-btn :disabled="submitButtonDisabled" :loading="form.busy" block size="large" type="submit">
            {{ $t('send_password_reset_link') }}
          </v-btn>

          <div>
            <v-alert v-if="errorMessage.length > 0" density="compact" role="alert" type="error" variant="outlined">
              {{ errorMessage }}
            </v-alert>
          </div>
        </v-form>

        <div v-if="form.successful">
          <v-alert density="compact" role="alert" type="success" variant="outlined">
            {{ $t('password_reset_link_sent') }}
          </v-alert>
        </div>

        <p class="mt-10 text-sm font-light text-gray-500 dark:text-gray-400">
          Remember Now?
          <router-link :to="{ name: 'login', query: { email: form.email } }" class="font-medium text-primary-600 hover:underline dark:text-primary-500" data-testid=register-link>
            <b>{{ $t('login') }}</b>
          </router-link>
        </p>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import Form from 'vform';
import {computed, onBeforeMount, ref} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';

const {getters} = useStore();
const route = useRoute();

const status = ref<string>('');
const form = ref(new Form({
  email: '',
}));

const errorMessage = ref('');

const submitButtonDisabled = computed(() => {
  return !form.value.email;
});

const send = async () => {
  if (!form.value.email) {
    errorMessage.value = 'Please enter your email address.';
    return;
  }

  try {
    const {data} = await form.value.post('/v1/password/email');

    if (data.success) {
      console.dir(data);
      form.value.reset();
    }
  } catch (e) {
    console.dir(e);
    errorMessage.value = e.response?.data?.message || 'An error occurred. Please try again.';
  }
};

onBeforeMount(() => {
  if (route.query.email) {
    console.dir(route.query.email);
    form.value.email = route.query.email;
  }
  if (route.params.email) {
    console.dir(route.params.email);
    form.value.email = route.params.email;
  }
});
</script>
