<template>
  <div id="content_sidebar-left" class="content_sidebar content_sidebar-left overflow-x-hidden">
    <v-scroll-x-transition hide-on-leave>
      <div v-if="showFieldSearch" class="sidebar_footer">
        <div class="form_item-iconLeft form_item-iconRight">
          <v-text-field v-model="searchTool" append-icon="mdi-close" class="input-text mx-1 px-1" density="compact" placeholder="Search Tools" prepend-inner-icon="mdi-magnify" type="text" @click:append="searchTool = ''; showFieldSearch = false;"></v-text-field>
        </div>
      </div>
    </v-scroll-x-transition>
    <div class="">
      <v-btn :class="{'left-sidebar-group-active' : leftSidebarView === 1}" class="left-sidebar-group" size="small" variant="text" @click="leftSidebarView = 1">
        <v-icon size="small">mdi-format-textbox</v-icon>
      </v-btn>
      <v-btn :class="{'left-sidebar-group-active' : leftSidebarView === 2}" class="left-sidebar-group" size="small" variant="text" @click="leftSidebarView = 2">
        <v-icon size="small">mdi-cogs</v-icon>
      </v-btn>
      <v-btn class="left-sidebar-group" size="small" variant="text" @click="showFieldSearch = !showFieldSearch">
        <v-icon size="small">mdi-magnify</v-icon>
      </v-btn>
    </div>
    <div class="w-100 overflow-auto bg-gray-100">
      <div class="h-100 flex">
        <div class="w-100 mx-2 overflow-x-hidden">
          <div v-if="leftSidebarView === 1">
            <div class="mx-3 my-2 l-flex-between border-b">
              <span>Fields <v-btn href="https://help.fleetwire.io/en/article/esign-fields-q9oeuf/" size="small" target="_blank" variant="text"><v-icon size="x-small">mdi-help-circle-outline</v-icon></v-btn></span>
            </div>
            <div class="mb-5">
              <v-select
                v-model="currentSigner"
                :items="signerItems"
                density="compact"
                item-title="title"
                item-value="value"
                label="Signer"
                variant="outlined"
              >
                <template v-slot:selection="{ item }">
                  <v-icon :color="item.raw.color" class="mr-1" size="small">mdi-account-circle</v-icon>
                  <span>{{ item.title }}</span>
                </template>
                <template v-slot:item="{ props, item }">
                  <v-list-item :subtitle="item.raw.title" v-bind="props">
                    <template v-slot:prepend>
                      <v-icon :color="item.raw.color" icon="mdi-account-circle" size="small"></v-icon>
                    </template>

                  </v-list-item>
                </template>
              </v-select>
              <div v-for="(category, key) in categorizedTools" :key="key">
                <span class="block my-2 underline">{{ key }}</span>
                <v-btn v-for="(tool, toolKey) in category" :key="toolKey" v-tippy :content="tool.name" block class="mb-2 justify-left" size="small" variant="outlined" @click="$emit('addField', tool.id)">
                  <v-icon v-if="tool.icon" class="mr-2" size="small">{{ tool.icon }}</v-icon>
                  {{ truncateFieldName(tool.name) }}
                </v-btn>
              </div>

              <div v-if="!categorizedTools['Custom Fields']">
                <span class="block my-2 underline">Custom Fields</span>
                <small>Want to create a custom field?
                  <router-link :to="{name: 'settings.custom_fields'}">Configure Fields</router-link>
                </small>
              </div>
            </div>
          </div>
          <div v-if="leftSidebarView === 2">
            <div class="l-flex-between border-b mx-3 my-2">
              <span>Settings</span>
            </div>
            <div class="mb-5">
              <v-btn v-tippy :disabled="templateStageData.length <= 0" block class="mb-2" content="Remove all fields on template (reset)" variant="outlined" @click="$emit('clear-all-fields')">
                <v-icon class="mr-2" size="small">mdi-delete</v-icon>
                All Fields
              </v-btn>
              <div class="mt-5 muted">
                <span>Field Count:</span> {{ templateStageData.length }}
              </div>
              <div>

                <v-expansion-panels class="mb-6">
                  <v-expansion-panel>
                    <v-expansion-panel-title>
                      Place holders.
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <div v-for="(pageFields, index) in templateStageDataGroupedByPage" :key="index" class="border my-1">
                        <div class="text-h6">Page: {{ index }}</div>
                        <div v-for="field in pageFields" :key="field?.id" :class="pageFieldsOverviewClassObject(field)" class="border rounded m-1 px-1 small relative cursor-pointer" @click="scrollToField(field)">
                          <div class="small font-bold ">{{ field.text }}</div>
                          {{ field?.id }} <br>
                          x:{{ field.x }} <br>
                          y:{{ field.y }}
                          <div class="absolute right-0 top-1/2">
                            <v-btn size="x-small" variant="text" @click.stop="removeField(field)">
                              <v-icon size="x-small">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue';
import {useCompany} from '@/composables/useCompany';
import {useTemplate} from '@/composables/useTemplate';
import {ESignerFieldData, ESignTool} from '@/types';
import {truncate} from '@/utils/text';

const {company} = useCompany();
const {
  currentSigner,
  allTools,
  selectedFieldV2,
  signerItems,
  templateStageData,
  templateStageDataGroupedByPage,
  removeField,
  scrollToField,
} = useTemplate();

const showFieldSearch = ref(false);
const leftSidebarView = ref(1);
const searchTool = ref('');

const currentSignerTools = computed(() => {
  const tools = allTools.value.filter((t: ESignTool) => !t.disabled);

  if (currentSigner.value === 'primary') {
    return tools.filter((t) => t.primary || !t.secondary);
  }
  return tools.filter((t) => t.secondary || !t.primary);
});

const filteredTools = computed(() => {
  let tools = currentSignerTools.value;

  const companyDefaultProperties = company.value?.default_properties || [];

  const defaultProperties = companyDefaultProperties.map((dp) => {
    const width = (dp.name.length * 9) + 10;
    const signer = currentSigner.value === 'primary' ? 'primary' : 'secondary';
    const data = {
      id: `custom-field-${dp.uuid}`,
      name: dp.name,
      type: 'field',
      category: 'Custom Fields',
      icon: 'mdi-focus-field-horizontal',
      customField: true,
      w: width,
      h: 20,
    };
    data[`${signer}_customer`] = true;
    return data;
  });

  tools = tools.filter((t) => t.name.toLowerCase().includes(searchTool.value.toLowerCase()));

  tools = tools.concat(defaultProperties);
  return tools;
});


const categorizedTools = computed(() => {
  return filteredTools.value.reduce((acc, tool) => {
    const category = tool.category || 'Uncategorized';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(tool);
    return acc;
  }, {});
})

const truncateFieldName = (name: string) => {
  return truncate(name, 25);
};

const pageFieldsOverviewClassObject = (field: ESignerFieldData) => {
  const hasError = field.x < 0 || field.y < 0 || field.w < 0 || field.h < 0;
  return {
    'bg-yellow-100': field?.id === selectedFieldV2.value?.id,
    'field-error': hasError,
  };
};
</script>

<style>
.page-fields-overview .v-expansion-panel-text__wrap {
  padding: 0 5px !important;
}

.site-editor .content_sidebar-left {
  background: #e9e9e9;
}

.site-editor .content_sidebar-left {
  border-right: 1px solid #ec0606;
  position: relative;
  width: 240px;
}

.page-fields-overview .field-error {
  border: 1px solid #ec0606 !important;
}

.content_sidebar-left {
  border-right: 1px solid transparent;
  max-width: 375px;
  min-width: 220px;
  overflow: visible;
  position: relative;
}

.content_sidebar-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
</style>
