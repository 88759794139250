import Cookies from 'js-cookie';
import * as types from '../mutation-types';
import Tr from "@/plugins/i18n/translations";

const locales = [];

export interface LangState {
    locale: string,
    locales: string[],
}

// state
const state: LangState = {
  locale: Tr.guessDefaultLocale(),
  locales,
};

// getters
const getters = {
  locale: (state: LangState) => state.locale,
  locales: (state: LangState) => state.locales,
};

// mutations
const mutations = {
  [types.SET_LOCALE](state: LangState, { locale }) {
    state.locale = locale;
  },
};

// actions
const actions = {
  setLocale({ commit }, { locale }) {
    commit(types.SET_LOCALE, { locale });

    Cookies.set('locale', locale, { expires: 365 });
  },
};


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
