<template>
  <div class="grid grid-cols-2 gap-5">
    <v-card class="mb-2">
      <v-card-text>
        <div class="my-5">
          Upload your document here. In the next step you will be able to add variable placeholders to your documents.
        </div>
        <div v-if="!documentsUploadedSuccess" class="dragBox" v-bind="getRootProps()">
          <input v-bind="getInputProps()"/>
          <p v-if="isDragActive">Drop the files here ...</p>
          <p v-else>Drag and drop your file here, or click to select files</p>
        </div>

        <div v-else>
          <div class="h-100 row align-items-center">
            <div class="col text-center">
              <v-img height="200" src="/images/illustrations/my_files.svg"/>
              <h2 class="newCustomer__header">Success</h2>
              <p>Click next to add placeholders to your template.</p>
            </div>
          </div>
        </div>

        <div v-if="rejectedReasons.length" class="mt-5">
          <v-alert density="comfortable" type="error">
            <ul>
              <li v-for="(reason, index) in rejectedReasons" :key="index">{{ reason }}</li>
            </ul>
          </v-alert>
        </div>

      </v-card-text>
      <v-card-actions>
        <v-btn :to="{name: 'documents.index'}" size="small" variant="text">back</v-btn>
        <v-spacer/>
        <v-btn :disabled="!documentsUploadedSuccess" :to="{name: 'documentsv2.fields', params: { uuid: documentUuid}}" color="primary">
          Next
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script lang="ts" setup>
import {useStore} from 'vuex'
import {useRoute} from 'vue-router'
import axios from 'axios';
import type {FileRejectReason} from 'vue3-dropzone'
import {useDropzone} from 'vue3-dropzone';
import {ref} from 'vue';

interface GetUploadUrlParams {
  document_uuid: string;
  actor: string;
  extension: string;
  mime: string;
}

interface UploadUrlResponse {
  uploadUrl: string;
}

const route = useRoute();
const store = useStore();
const token = store.getters['auth/token'];
const url = `/v1/templates/${route.params.uuid}/documents/1`;
const thePhotosUploading = ref([]);
const filesForS3 = ref([]);
const theTripPhotos = ref([]);
const templateDocs = ref({});
const uploadProgress = ref('');
const completing = ref(false);
const documentsUploadedSuccess = ref(false);
const rejectedReasons = ref([]);
const uploadUrls = ref([]);

const documentUuid = route.params.uuid as string;


const getUploadUrl = async (file: File, documentUuid: string) => {
  // Extract the file extension and fallback if necessary
  const extension = file.name.split('.').pop() || 'txt';

  // Prepare the payload for the API
  const payload: GetUploadUrlParams = {
    document_uuid: documentUuid,
    actor: 'host',
    extension,
    mime: file.type || 'application/octet-stream',
  };

  try {
    const response = await fetch('https://tljgib4tw6.execute-api.us-west-1.amazonaws.com/default/TenantFileSignedUploadUrl', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      console.error(`Failed to fetch upload URL. Status: ${response.status} - ${response.statusText}`);
      return null;
    }

    const {uploadUrl}: UploadUrlResponse = await response.json();
    return uploadUrl;
  } catch (error) {
    console.error('Error getting upload URL', error);
    return null;
  }
};

const uploadS3Init = async () => {
  thePhotosUploading.value = [];
  uploadUrls.value = await Promise.all(filesForS3.value.map(file => getUploadUrl(file, documentUuid)));

  console.dir('uploadUrls%%%');
  console.dir(uploadUrls.value);
  const filteredFiles = filesForS3.value.filter((_, index) => uploadUrls.value[index]);
  await uploadS3(uploadUrls.value, filteredFiles);
};

const createAwsAxiosInstance = () => {
  return axios.create({
    transformRequest: (data, headers) => {
      if (headers?.common?.Authorization) {
        delete headers.common.Authorization;
      }
      return data;
    },
  });
};

const uploadS3 = async (filesPaths: string[], filteredFiles: File[]) => {
  const awsAxios = createAwsAxiosInstance();
  console.dir('uploadS3');
  console.dir(filesPaths);
  console.dir(filteredFiles);
  await Promise.all(filteredFiles.map((file, i) => uploadFileToS3(awsAxios, filesPaths[i], file, i)));
};

const uploadFileToS3 = async (awsAxios, filePath: string, file: File, i: number) => {
  console.dir('initializeUploadData', i);


  try {
    console.dir(filePath);
    console.dir(file);
    await awsAxios.put(filePath, file, {
      onUploadProgress: event => {
        console.dir(event);
      },
      headers: {'Content-Type': file.type},
    });
  } catch (error) {
    console.error('Error uploading file', error);
  } finally {
    console.dir('finally');
  }
};


function onDrop(acceptedFiles: File[], rejectReasons: FileRejectReason[]) {
  rejectedReasons.value = [];
  console.log('acceptedFiles', acceptedFiles)
  console.log('rejectReasons', rejectReasons)

  if (rejectReasons.length > 0) {
    rejectReasons.forEach(reason => {
      if (!rejectedReasons.value.includes(reason.errors[0].message)) {
        rejectedReasons.value.push(reason.errors[0].message);
      }
    });
  }

  filesForS3.value = acceptedFiles;
  uploadS3Init().then(() => {
    uploadDocument();
  });
}

const uploadDocument = async () => {
  if (uploadUrls.value.length > 0) {

    const uploadUrlsClean = uploadUrls.value.map(url => {
      return url.split('?')[0];
    });

    const formData = new FormData();
    formData.append('file_path', uploadUrlsClean[0]);
    formData.append('original_name', filesForS3.value[0].name);
    try {
      const response = await axios.post(`/v1/templates/${documentUuid}/documents/uploaded`, formData);
      console.dir(response);
      documentsUploadedSuccess.value = true;
      templateDocs.value = response.data.templateDocs;
    } catch (error) {
      console.dir(error);
    }
  }
};


const {getRootProps, getInputProps, isDragActive,} = useDropzone({
  onDrop,
  maxSize: 10000000, // 10MB
  maxFiles: 1,
  accept: 'application/pdf',
});
</script>

<style>
.fw-file-drop {
  position: relative;
  border: 3px dashed #a7d2b8 !important;
  border-radius: 10px;
  box-shadow: 0 0 25px -20px #333;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 20px;
  overflow: auto;
  cursor: pointer !important;
  height: 200px;
  padding-top: 10px;
}

.fw-file-drop:hover {
  border: 3px dashed #5b7365 !important;
}

.fw-file-drop__inner {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  overflow: auto;
}

.fw-file-drop input {
  width: 100%;
  opacity: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.fw-file-drop svg {
  margin: auto
}

.fw-file-drop label {
  width: 100%;
  font-size: 22px;
  font-weight: 400;

  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
}

.dragBox {
  width: 100%;
  margin: 0 auto;
  position: relative;
  text-align: center;
  font-weight: bold;
  line-height: 95px;
  color: #999;
  border: 2px dashed #ccc;
  display: inline-block;
  transition: transform 0.3s;
  border-radius: 10px;
}

.dragBox.active {
  border: 2px dashed #5a81e0;
}

.dragBox input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
}
</style>
