<template>
  <div>
    <h2 class="mt-8 mb-4 font-bold text-2xl">Online Reservations</h2>

    <General/>
    <SectionDivider/>

    <OperatingHours/>
    <SectionDivider/>

    <GuestCancellation/>
    <SectionDivider/>

    <CheckoutFields/>
    <SectionDivider/>

    <SecondaryDrivers/>
    <SectionDivider/>

    <StoreCustomizations/>
    <SectionDivider/>

    <CustomDomain/>
    <SectionDivider/>

    <EcommerceTracking/>
    <SectionDivider/>

    <Install/>
  </div>
</template>

<script lang="ts" setup>
import {defineAsyncComponent} from 'vue';

const SectionDivider = defineAsyncComponent(() => import('@/components/common/SectionDivider.vue'));

const components = {
  OperatingHours: defineAsyncComponent(() => import('@/components/dashboard/settings/onlineReservations/OperatingHours.vue')),
  General: defineAsyncComponent(() => import('@/components/dashboard/settings/onlineReservations/SettingsGeneral.vue')),
  GuestCancellation: defineAsyncComponent(() => import('@/components/dashboard/settings/onlineReservations/AllowBookingCancellation.vue')),
  CheckoutFields: defineAsyncComponent(() => import('@/components/dashboard/settings/onlineReservations/CheckoutFields.vue')),
  SecondaryDrivers: defineAsyncComponent(() => import('@/components/dashboard/settings/onlineReservations/AllowSecondaryDrivers.vue')),
  StoreCustomizations: defineAsyncComponent(() => import('@/components/dashboard/settings/onlineReservations/StoreCustomizations.vue')),
  CustomDomain: defineAsyncComponent(() => import('@/components/dashboard/settings/onlineReservations/CustomDomain.vue')),
  EcommerceTracking: defineAsyncComponent(() => import('@/components/dashboard/settings/onlineReservations/EcommerceTracking.vue')),
  Install: defineAsyncComponent(() => import('@/components/dashboard/settings/onlineReservations/Install.vue')),
};

// Destructure components for template use
const {
  OperatingHours,
  General,
  GuestCancellation,
  CheckoutFields,
  SecondaryDrivers,
  StoreCustomizations,
  CustomDomain,
  EcommerceTracking,
  Install,
} = components;
</script>
