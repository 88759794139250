import axios from 'axios';
import * as types from '../mutation-types';
import {TripExtra} from '@/types';
import {RootState} from '@/types/RootState';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {Module} from 'vuex';
import {push} from 'notivue'

export interface RegularExtraData {
    extra?: TripExtra;
    mode: 'add' | 'edit';
    code?: 'secondary_driver' | 'young_driver';
    type?: 'convenience';
}

export interface TripExtrasState {
    loading: Record<string, boolean>,
    extras: TripExtra[],
    tripExtraSettingDialog: boolean,
    regularExtraDialog: boolean,
    regularExtraData: RegularExtraData | null,
    requiredExtraAddEditDialog: boolean,
    requiredExtraAddEditData: TripExtra | null,
    extraListingsDialog: boolean,
}

const state: TripExtrasState = {
    loading: {
        extras: false,
        extra_delete: false,
        extra_update: false,
    },
    extras: [] as TripExtra[],
    tripExtraSettingDialog: false,
    requiredExtraAddEditDialog: false,
    regularExtraDialog: false,
    requiredExtraAddEditData: null,
    regularExtraData: null,
    extraListingsDialog: false,
};


const tripExtrasModule: Module<TripExtrasState, RootState> = {
    namespaced: true,

    state,

    getters: {
        loading: (state: TripExtrasState) => state.loading,
        extras: (state: TripExtrasState) => state.extras,
        tripExtraSettingDialog: (state: TripExtrasState) => state.tripExtraSettingDialog,
        requiredExtraAddEditDialog: (state: TripExtrasState) => state.requiredExtraAddEditDialog,
        regularExtraDialog: (state: TripExtrasState) => state.regularExtraDialog,
        requiredExtraAddEditData: (state: TripExtrasState) => state.requiredExtraAddEditData,
        regularExtraData: (state: TripExtrasState) => state.regularExtraData,
        extraListingsDialog: (state: TripExtrasState) => state.extraListingsDialog,
    },

    mutations: {
        [types.SET_EXTRAS_LOADING](state: TripExtrasState, loading) {
            state.loading[loading.subject] = loading.loading;
        },
        [types.SET_EXTRAS](state: TripExtrasState, extras) {
            state.extras = extras;
        },
        [types.SET_TRIP_EXTRA_SETTING_DIALOG](state: TripExtrasState, value) {
            state.tripExtraSettingDialog = value;
        },
        [types.SET_REQUIRED_EXTRA_ADD_EDIT_DIALOG](state: TripExtrasState, value) {
            state.requiredExtraAddEditDialog = value;
        },
        [types.SET_REQUIRED_EXTRA_ADD_EDIT_DATA](state: TripExtrasState, payload) {
            state.requiredExtraAddEditData = payload;
        },
        [types.SET_REGULAR_EXTRA_DATA](state: TripExtrasState, payload: RegularExtraData | null) {
            state.regularExtraData = payload;
        },
        [types.SET_REGULAR_EXTRA_DIALOG](state, value) {
            state.regularExtraDialog = value;
        },
        [types.SET_EXTRA_LISTINGS_DIALOG](state, value) {
            state.extraListingsDialog = value;
        },
    },

    actions: {

        async getExtras({commit}) {
            const data = await performApiRequest(commit, 'SET_EXTRAS_LOADING', 'extras', () => axios.get('/v1/trip-extras?with=listings'));
            if (data) commit('SET_EXTRAS', data.extras);
        },

        async createExtras({commit}, data) {
            try {
                await performApiRequest(commit, 'SET_EXTRAS_LOADING', 'extras', () => axios.post('/v1/trip-extras', data));
                push.success({
                    message: 'Trip Extra created successfully!',
                });
            } catch (error) {
                push.error({
                    message: 'Failed to create Trip Extra',
                });
            }
        },

        async deleteExtra({commit}, extra: TripExtra) {
            try {
                await performApiRequest(commit, 'SET_EXTRAS_LOADING', 'extra_delete', () => axios.delete(`/v1/trip-extras/${extra.uuid}`));
                push.success({
                    message: 'Trip Extra deleted successfully!',
                });
            } catch (error) {
                push.error({
                    message: 'Failed to delete Trip Extra',
                });
            }
        },

        async deleteOldVersionExtras({commit}) {
            await performApiRequest(commit, 'SET_EXTRAS_LOADING', 'extras', () => axios.delete('/v1/trip-extras/old-version'));
            push.success({
                message: 'Old Extras deleted successfully!',
            });
        },

        async deleteFromListings({commit}, data: { extraUuid: string, listings: string[] }) {
            await performApiRequest(commit, 'SET_EXTRAS_LOADING', 'extra_delete', () => axios.post(`/v1/trip-extras/${data.extraUuid}/remove-listings`, {listings: data.listings}));
        },

        async updateExtras({commit}, extra: TripExtra) {
            try {
                await performApiRequest(commit, 'SET_EXTRAS_LOADING', 'extra_update', () => axios.put(`/v1/trip-extras/${extra.uuid}`, extra));

                push.success({
                    message: 'Trip Extra updated successfully!',
                });
            } catch (error) {
                push.error({
                    message: 'Failed to update Trip Extra',
                });
            }
        },

        async updateExtraListings({commit}, data: { extraUuid: string, listingIds: string[] }) {
            await performApiRequest(commit, 'SET_EXTRAS_LOADING', 'extra_update', () => axios.put(`/v1/trip-extras/${data.extraUuid}/listings`, {listings: data.listingIds}));

            push.success({
                message: 'Trip Extra Listings updated successfully!',
            });
        },
    },
}

export default tripExtrasModule;
