<template>
  <section class="bg-gray-50 dark:bg-gray-900 signup-setup">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white" href="/">
        <img alt="logo" class="w-8 h-8 mr-2" src="https://tenant.fleetwire.io/images/logo/fleetwire-logo-new.png">
        Fleetwire
      </a>
      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Continue with your setup
          </h1>
          <v-form :disabled="form.busy" class="space-y-2 md:space-y-3" @keydown="form.onKeydown($event)" @submit.prevent="completeRegister">
            <div>
              <v-text-field
                v-model="form.first_name"
                :autofocus="true"
                :error-messages="form.errors.get('first_name')"
                :label="$t('first_name')"
                name="first_name"
                variant="outlined"
              />
            </div>
            <div>
              <v-text-field
                v-model="form.last_name"
                :error-messages="form.errors.get('last_name')"
                :label="$t('last_name')"
                name="last_name"
                variant="outlined"
              />
            </div>
            <div>
              <vue-tel-input
                v-model="form.phone"
                v-bind="tellProps"
                @validate="inputChanged"
              />
            </div>

            <div class="mb-10 mt-5">
              <div class="preferences mb-2">
                <div class="preferences__preview">
                  <div v-if="!editPreferences">
                    <v-btn class="edit-toggle" size="small" variant="text" @click="editPreferences = !editPreferences">
                      Edit
                    </v-btn>
                    <span class="flag">
                      <span style="width: 1em; height: 1em; margin: 0 0.05em 0 0.1em; vertical-align: -0.1em;">
                        {{ displayCountryFlag }}
                      </span>
                    </span>
                    <div class="text">
                      <div class="country">{{ displayCountry }}</div>
                      Currency: {{ displayCurrency }}<br>
                      Date format: {{ displayDateFormat }}<br>
                      Time format: {{ form.timezone }}, {{ displayTimeFormat }}
                    </div>
                  </div>
                  <div v-if="editPreferences">
                    <div>
                      <div class="col-6">
                        <v-autocomplete
                          v-model="form.country"
                          :items="countriesAsArray"
                          density="compact"
                          label="Country"
                          variant="outlined"
                        />
                      </div>
                      <div class="col-6">
                        <v-autocomplete
                          v-model="form.timezone"
                          :items="timezones"
                          density="compact"
                          label="Timezone"
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div>
                      <div class="col-6">
                        <v-autocomplete
                          v-model="form.currency"
                          :items="currenciesAsArray"
                          density="compact"
                          label="Currency"
                          variant="outlined"
                        />
                      </div>
                      <div class="col-6">
                        <v-select
                          v-model="form.date_format"
                          :items="dataFormatOptions"
                          density="compact"
                          label="Date Format"
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div>
                      <div class="col-6">
                        <v-autocomplete
                          v-model="form.time_format"
                          :items="timeFormatOptions"
                          density="compact"
                          item-title="label"
                          item-value="value"
                          label="Time Format"
                          variant="outlined"
                        />
                      </div>
                    </div>

                    <v-btn class="edit-toggle close" size="small" variant="plain" @click="editPreferences = !editPreferences">
                      Close
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

            <v-btn :disabled="form.busy || !regFieldsMissing" :loading="form.busy" block class="mt-5" size="large" type="submit" variant="elevated">
              {{ $t('complete') }}
            </v-btn>
          </v-form>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import {computed, onMounted, ref, watch} from 'vue';
import Form from 'vform';
import {useStore} from 'vuex';
import {isEmpty, isSet} from 'lodash';
import {VueTelInput} from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {useRouter} from 'vue-router';
import {timezones} from '@/utils/dates';
import {CountryOption} from '@/types';

dayjs.extend(utc);
dayjs.extend(timezone);


const router = useRouter();
const store = useStore();
const editPreferences = ref(false);

const countryOptions = store.getters['app/countryOptions'];
const registerId = store.getters['auth/registerId'];

const saveToken = async (token: { token: string, remember: boolean }) => {
  await store.dispatch('auth/saveToken', token);
};

const removeRegisterId = async () => {
  await store.dispatch('auth/saveRegisterId', '');
};

const countries = countryOptions.reduce((acc: string[], country: CountryOption) => {
  acc[country.code] = country.name;
  return acc;
}, {});


const form = ref(new Form({
  phone: '',
  phoneMeta: null,
  first_name: '',
  last_name: '',
  team_size: 1,
  register_id: '',
  timezone: '',
  country: 'US',
  currency: 'USD',
  date_format: 'mm-dd-yyyy',
  time_format: '12-hour',
}));

const guessCountryData = ref({
  country: '',
  timezone: '',
  flag: '',
});

const regMissing = ref(false);
const tellProps = ref({
  defaultCountry: 'US',
  preferredCountries: ['US', 'CA'],
});
const ipData = ref(null);
const dataFormatOptions = ref(['mm-dd-yyyy', 'dd-mm-yyyy', 'yyyy-mm-dd']);
const timeFormatOptions = ref([
  {
    label: '12-Hour clock',
    value: '12-hour',
  },
  {
    label: '24-Hour clock',
    value: '24-hour',
  },
]);
const intl = ref(Intl.DateTimeFormat().resolvedOptions());

const currenciesAsArray = computed(() => {
  const uniqueCodes = new Set();
  const prioritized = ['USD', 'EUR']

  const filtered = countryOptions
    .map((country: CountryOption) => country.currency)
    .filter((code: string) => {
      if (code && !uniqueCodes.has(code)) {
        uniqueCodes.add(code);
        return true;
      }
      return false;
    });

  return [...new Set([...prioritized, ...filtered])];
});

const countriesAsArray = computed(() => {
  return countryOptions.map((country: CountryOption) => ({
    title: country.name,
    value: country.code,
  }));
});


const displayCountry = computed(() => {
  return countries[form.value.country];
});

const displayCurrency = computed(() => {
  return form.value.currency;
});

const displayDateFormat = computed(() => {
  return form.value.date_format.toUpperCase();
});

const displayTimeFormat = computed(() => {
  return form.value.time_format;
});

const displayCountryFlag = computed(() => {
  return getFlagEmoji(form.value.country);
});

const regFieldsMissing = computed(() => {
  return form.value.phone.length
    && form.value.team_size >= 1
    && form.value.first_name.length
    && form.value.last_name.length
    && form.value.phoneMeta?.valid;
});


const getFlagEmoji = (countryCode: string) => {
  countryCode = countryCode.slice(0, 2);
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
};

const inputChanged = (phone: any) => {
  form.value.phoneMeta = phone;
};

const completeRegister = async () => {
  const response = await form.value.put(`/v1/register/${registerId}/complete`);

  if (response.status >= 200 && response.status < 300) {
    // Log in the user.
    const {data} = await form.value.post('/v1/auth/login');

    // Save the token.
    await saveToken({
      token: data.access_token,
      remember: true,
    });

    // remove the register id.
    await removeRegisterId();

    await store.dispatch('auth/fetchUser');

    // Redirect home.
    router.push({name: 'dashboard'}).catch(() => {
    });
  }
};


onMounted(async () => {
  try {
    const response = await fetch('https://api.geoapify.com/v1/ipinfo?apiKey=dc3d43b77c0e4876be94173b5b8a7bc9');

    if (!response.ok) {
      console.warn('GeoAPI request failed:', response.statusText);
      return;
    }

    const json = await response.json();
    if (!json?.country?.iso_code) {
      console.warn('GeoAPI response missing country data:', json);
      return;
    }

    ipData.value = json;

    guessCountryData.value.country = countries[json.country.iso_code] || 'United States';
    guessCountryData.value.flag = getFlagEmoji(json.country.iso_code);
    form.value.currency = json.country.currency?.split(',')[0] || 'USD';
    form.value.country = json.country.iso_code || 'US';
  } catch (error) {
    console.error('Error fetching IP info:', error);
  }

  if (!isSet(registerId) || isEmpty(registerId)) {
    regMissing.value = true;
  }

  form.value.register_id = registerId;
  guessCountryData.value.timezone = intl.value.timeZone;
  form.value.timezone = intl.value.timeZone;

  const country = intl.value.locale.slice(-2).toUpperCase();
  guessCountryData.value.country = countries[country] || 'United States';
});

watch(() => ipData.value, (data) => {
  if (data?.country?.iso_code) {
    guessCountryData.value.country = countries[data.country.iso_code] || 'United States';
    guessCountryData.value.flag = getFlagEmoji(data.country.iso_code);
    form.value.currency = data.country.currency?.split(',')[0] || 'USD';
    form.value.country = data.country.iso_code;
  }
});
</script>

<style>
.signup-setup .preferences {
  border: 1px solid #E2E6EB;
  padding: 18px 18px 0 18px;
  border-radius: 6px;
  position: relative;
}

.signup-setup .preferences__preview {
  margin-bottom: 18px;
  font-size: 13px;
  color: #546972;
  line-height: 18px;
}

.signup-setup .preferences .edit-toggle {
  position: absolute;
  right: 18px;
  z-index: 99;
}

.signup-setup .preferences .edit-toggle.close {
  bottom: 10px;
}

.signup-setup .preferences__preview span.flag {
  font-size: 24px;
  display: inline-block;
  position: absolute;
  left: 13px;
}

.signup-setup .preferences__preview .text {
  padding-left: 30px;
}

.signup-setup .preferences__preview .country {
  font-size: 14px;
  font-weight: bold;
  color: #181818;
}
</style>

<style>
.vue-tel-input {
  min-height: 56px;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  position: relative;

  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  color: rgba(0, 0, 0, .38);
  border-collapse: collapse;
  border: 1px solid;
  right: 0;
  top: -5px;
}

.vue-tel-input input {
  color: rgba(0, 0, 0, .87);
  max-height: 32px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  line-height: 20px;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  border: none;
  border-radius: 0 2px 2px 0;
  outline: none;
  padding: 8px 0 8px 7px;
}

.vue-tel-input:focus-within {
  border: #62a477 2px solid;
  caret-color: #62a477 !important;
  box-shadow: none;
}

.vue-tel-input .vti__dropdown {
  min-height: 100%;
}
</style>
