import axios from 'axios';
import * as types from '../mutation-types';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {ListingInterface, MaintenanceTask} from '@/types';
import {push} from 'notivue';


export interface VehicleRecall {
    NHTSA_action_number: string | null,
    NHTSA_campaign_number: string,
    component: string,
    consequence: string | null,
    created_at: string | null,
    id: number,
    manufacturer: string,
    notes: string,
    remedy: string,
    report_received_date: string,
    summary: string,
    updated_at: string | null,
    vehicle_make: string,
    vehicle_model: string,
    vehicle_year: number
}

export interface VehicleListingRecall {
    addressed_at: string | null,
    created_at: string | null,
    notified_at: string,
    status: string | null,
    updated_at: string | null,
    vehicle_recall: VehicleRecall
}

export interface MaintenanceState {
    loading: Record<string, boolean>,
    reminderDays: number[],
    reminderInterval: string[],
    maintenanceTypes: string[],
    maintenanceTasks: string[],
    repairTasks: string[],
    currentMaintenance: MaintenanceTask[],
    currentRecalls: VehicleListingRecall[],
}

const state: MaintenanceState = {
    loading: {
        fetch: false,
        adding: false,
        updating: false,
        complete: false,
        delete: false,
        recalls: false,
    },
    currentMaintenance: [],
    currentRecalls: [],

    reminderDays: [30, 60, 90],
    reminderInterval: ['yearly'],
    maintenanceTypes: ['Maintenance', 'Repair'],
    maintenanceTasks: [
        'Oil Change',
        'Tire Replacement',
        'Wheel Alignment',
        'Air Filter',
        'Fluids',
        'Breaks',
        'Engine',
        'Transmission',
        'Suspension',
        'Body',
        'Interior',
        'Battery',
        'Electronics',
        'Other',
    ],
    repairTasks: [],
};


const getters = {
    loading: (state: MaintenanceState) => state.loading,
    reminderDays: (state: MaintenanceState) => state.reminderDays,
    reminderInterval: (state: MaintenanceState) => state.reminderInterval,
    maintenanceTypes: (state: MaintenanceState) => state.maintenanceTypes,
    maintenanceTasks: (state: MaintenanceState) => state.maintenanceTasks,
    currentMaintenance: (state: MaintenanceState) => state.currentMaintenance,
    currentRecalls: (state: MaintenanceState) => state.currentRecalls,
};


const mutations = {
    [types.SET_MAINTENANCE_LOADING](state: MaintenanceState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_CURRENT_VEHICLE_MAINTENANCE](state: MaintenanceState, maintenance: MaintenanceTask[]) {
        state.currentMaintenance = maintenance;
    },
    [types.SET_CURRENT_VEHICLE_RECALLS](state: MaintenanceState, recalls: VehicleListingRecall[]) {
        state.currentRecalls = recalls;
    },
    [types.RESET_CURRENT_WORKING_MAINTENANCE_ITEM](state: MaintenanceState) {
        state.currentMaintenance = [];
    },
};

const actions = {

    /**
     * Fetch a vehicle maintenance record
     */
    async fetchVehicleMaintenance({commit}, listing: ListingInterface) {
        const responseData = await performApiRequest(commit, 'SET_MAINTENANCE_LOADING', 'fetch', () => axios.get(`/v1/listings/${listing.l_id}/maintenance`));
        commit(types.SET_CURRENT_VEHICLE_MAINTENANCE, responseData.maintenance);
    },

    /**
     * Fetch a vehicles recalls
     */
    async fetchVehicleRecalls({commit}, listing: ListingInterface) {
        const responseData = await performApiRequest(commit, 'SET_MAINTENANCE_LOADING', 'recalls', () => axios.get(`/v1/listings/${listing.l_id}/recalls`));
        commit(types.SET_CURRENT_VEHICLE_RECALLS, responseData.recall_listings);
    },


    /**
     * Creates a vehicle maintenance record
     */
    async createVehicleMaintenance({commit}, data: { listing: ListingInterface, maintenance: MaintenanceTask }) {
        try {
            const listingId = data.listing.l_id;
            const maintenanceItem = data.maintenance;
            await performApiRequest(commit, 'SET_MAINTENANCE_LOADING', 'adding', () => axios.post(`/v1/listings/${listingId}/maintenance`, maintenanceItem));
            push.sucess({
                message: 'Added successfully',
            });
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },


    /**
     * Update a vehicle maintenance record
     */
    async updateVehicleMaintenance({commit}, data: { listing: ListingInterface, maintenance: MaintenanceTask }) {
        try {
            const listingId = data.listing.l_id;
            const maintenanceItem = data.maintenance;

            await performApiRequest(commit, 'SET_MAINTENANCE_LOADING', 'updating', () => axios.put(`/v1/listings/${listingId}/maintenance/${maintenanceItem.uuid}`, maintenanceItem));
            push.sucess({
                message: 'Updated successfully',
            });
            commit('RESET_CURRENT_WORKING_MAINTENANCE_ITEM');
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },


    /**
     * Mark complete a vehicle maintenance record
     */
    async completeVehicleMaintenance({commit}, {listing, maintenance}) {
        const responseData = await performApiRequest(commit, 'SET_MAINTENANCE_LOADING', 'complete', () => axios.post(`/v1/listings/${listing.l_id}/maintenance/${maintenance.uuid}/complete`, maintenance));
        commit(types.SET_CURRENT_VEHICLE_MAINTENANCE, responseData.maintenance);

        push.sucess({
            message: 'Completed successfully',
        });
    },


    /**
     * Delete a vehicle maintenance record
     */
    async deleteVehicleMaintenance({commit}, maintenance: MaintenanceTask) {
        await performApiRequest(commit, 'SET_MAINTENANCE_LOADING', 'delete', () => axios.delete(`/v1/maintenance/${maintenance.uuid}`));
        push.sucess({
            message: 'Deleted successfully',
        });
    },
};


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
