<template>
  <div>
    <v-card>
      <v-card-title>Custodians</v-card-title>
      <v-card-subtitle>
        Manage your custodians
        <TheHelpLink url="https://help.fleetwire.io/en/article/custodians-1wu4w2h/?bust=1664293159925"/>
      </v-card-subtitle>
      <v-card-text>
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th class="px-6 py-3" scope="col">
              #
            </th>
            <th class="px-6 py-3" scope="col">
              Invite Url
            </th>
            <th class="px-6 py-3" scope="col">
              Accepted
            </th>
            <th class="px-6 py-3" scope="col">
              Company
            </th>
            <th class="px-6 py-3" scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(custodian, index) in custodians" :key="custodian.uuid" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" scope="row">
              <span v-tippy="custodian.uuid">{{ index + 1 }}</span>
              <v-icon v-if="isAccepted(custodian)" color="green" size="small">mdi-check</v-icon>
              <v-icon v-else color="orange" size="small">mdi-account-clock-outline</v-icon>
            </th>
            <td class="px-6 py-4">
              {{ inviteUrl(custodian) }}

              <v-btn v-if="!isAccepted(custodian)" variant="text" @click="copyToClipboardInit(inviteUrl(custodian)); copyToClipboardSuccessSnack = true">
                <v-icon size="small">mdi-content-copy</v-icon>
              </v-btn>
              <input :id="`share-link-hidden-${index}`" :value="inviteUrl(custodian)" class="d-none">
            </td>
            <td class="px-6 py-4">
              {{ acceptedAt(custodian) }}
            </td>
            <td class="px-6 py-4">
              {{ childCompanyName(custodian) }}
              <div class="small">{{ childCompanyEmail(custodian) }}</div>
            </td>
            <td class="px-6 py-4">
              <div class="float-end">
                <v-btn size="small" variant="text" @click="openDeleteConfirmDialogInit(custodian)">
                  <v-icon color="red" size="small">mdi-delete</v-icon>
                </v-btn>
                <v-btn size="small" variant="text" @click="openUpdateConfirmDialogInit(custodian)">
                  <v-icon size="small">mdi-pencil</v-icon>
                </v-btn>
                <v-btn class="d-none" size="small" variant="text">
                  <v-icon size="small">mdi-eye</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="openDeleteConfirmDialog" width="400">
      <v-card :disabled="custodiansLoading.delete">
        <v-card-title class="break-normal">
          Are you sure?
        </v-card-title>
        <v-card-text>
          <div v-if="isAccepted(currentWorkingCustodian)">
            If you delete this custodian ({{ childCompanyName(currentWorkingCustodian) }}), they will no longer have
            access to the resources you've allowed.
            <TheHelpLink url="https://help.fleetwire.io/en/article/custodians-1wu4w2h/?bust=1664293159925"/>
          </div>
          <div v-else>
            If you delete this custodian invite, they will no longer be able to claim the invite link.
            <TheHelpLink url="https://help.fleetwire.io/en/article/custodians-1wu4w2h/?bust=1664293159925"/>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn :loading="custodiansLoading.delete" color="error" @click="deleteCustodian(currentWorkingCustodian)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ListingAddManagersDialog :custodian="currentWorkingCustodian" mode="Edit"/>

    <v-snackbar v-model="copyToClipboardSuccessSnack">
      Copied to clipboard!
      <template v-slot:actions>
        <v-btn variant="text" @click="copyToClipboardSuccessSnack = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import {mapActions, mapGetters} from 'vuex';
import {get} from 'lodash';
import dayjs from 'dayjs';
import {EventBus} from '@/plugins/eventBus';
import {copyTextToClipboard} from '@/helpers';

import ListingAddManagersDialog from '@/components/dashboard/dialogs/ListingAddManagersDialog.vue';

export default {
  name: 'CustodianIndex',

  components: {
    ListingAddManagersDialog,
  },

  data() {
    return {
      copyToClipboardSuccessSnack: false,
      openDeleteConfirmDialog: false,
      currentWorkingCustodian: null,
    };
  },

  created() {
    this.getCustodians();

    EventBus.$on('delete-custodian-success', () => {
      this.openDeleteConfirmDialog = false;
      this.getCustodians();
    });
  },

  computed: {
    ...mapGetters({
      custodians: 'custodian/custodians',
      custodiansLoading: 'custodian/loading',
    }),
  },

  methods: {
    ...mapActions({
      getCustodians: 'custodian/getCustodians',
      deleteCustodians: 'custodian/deleteCustodians',
    }),
    deleteCustodian(custodian) {
      this.deleteCustodians(custodian.uuid);
    },
    isAccepted(custodian) {
      if (!custodian) return false;
      return !!custodian.accepted_at;
    },
    acceptedAt(custodian) {
      if (custodian && custodian.accepted_at) {
        return dayjs(custodian.accepted_at).format('MMM D, YYYY h:mm A');
      }
      return 'Not yet accepted';
    },
    inviteUrl(custodian) {
      if (custodian.invite_url) {
        return custodian.invite_url;
      }
      return 'Claimed';
    },
    childCompanyName(custodian) {
      return get(custodian, 'company.name', '-');
    },
    childCompanyEmail(custodian) {
      return get(custodian, 'company.email', '-');
    },
    openDeleteConfirmDialogInit(custodian) {
      this.currentWorkingCustodian = custodian;
      this.openDeleteConfirmDialog = true;
    },
    openUpdateConfirmDialogInit(custodian) {
      this.currentWorkingCustodian = custodian;
      EventBus.$emit('open-add-listing-manager-dialog', custodian);
    },
    async copyToClipboardInit(value) {
      try {
        await copyTextToClipboard(value);
        this.copiedText = true;
      } catch (error) {
        console.error(error);
      }
    },
  },

  beforeUnmount() {
    EventBus.$off('delete-custodian-success');
  },
};
</script>
