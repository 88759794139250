<template>
  <div>
    <div class="grid grid-cols-2">
      <div class="">
        <v-card :disabled="webhookCardReady">
          <v-card-title>
            Listen to Fleetwire events
          </v-card-title>
          <v-card-subtitle>
            Set up your webhook endpoint to receive live events from Fleetwire.
          </v-card-subtitle>

          <v-card-text>
            <v-form>
              <v-text-field
                v-model="webhookForm.url"
                :error-messages="webhookForm.errors.get('url')"
                class="mb-2"
                density="compact"
                label="URL Endpoint"
                name="url"
                variant="outlined"
                @keyup="clearErrors('url')"
              />
              <v-text-field
                v-model="webhookForm.description"
                :error-messages="webhookForm.errors.get('description')"
                class="mb-2"
                density="compact"
                label="Description"
                name="description"
                variant="outlined"
                @keyup="clearErrors('description')"
              />

              <v-combobox
                v-model="webhookForm.events"
                :error-messages="webhookForm.errors.get('events')"
                :items="items"
                class="mb-2"
                density="compact"
                label="Select events to listen to"
                multiple
                name="events"
                variant="outlined"
                @update:modelValue="clearErrors('events')"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn :disabled="!webhookFormReady" :loading="webhookLoading.webhooks" @click="addWebhookEndpointInit()">
              Add Endpoint
            </v-btn>
            <v-btn size="small" variant="text" @click="router.push({ name: 'settings.providers.webhooks' })">Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>

    <v-snackbar v-model="createWebhookSuccess">
      Webhook created Successfully
      <template v-slot:actions>
        <v-btn variant="text" @click="createWebhookSuccess = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue';
import {useStore} from 'vuex';
import {useSubscription} from "@/composables/useSubscription";
import {useRouter} from 'vue-router';
import Form from "@/plugins/FWForms";
import {WebhookEndpoint} from "@/store/modules/webhook";

const {getters, dispatch} = useStore();

const store = useStore();
const router = useRouter();
const {includedInSubscription} = useSubscription();

interface WebhookFormData {
  url: string;
  description: string;
  events: string[];
}

const webhookForm = ref(new Form<WebhookFormData>({
  url: '',
  description: '',
  events: [],
}));

const webhookLoading = computed(() => store.getters['webhook/loading']);

const webhookCardReady = computed(() => !includedInSubscription('coupons') || webhookLoading.value.webhook);

const webhookFormReady = computed(() => {
  const formData = webhookForm.value.data();
  for (const value of Object.values(formData)) {
    if (!value) return false;
  }
  return formData.events.length > 0;
});

const clearErrors = (field: string) => {
  webhookForm.value.errors[field] = [];
};

const addWebhookEndpoint = async (webhook: WebhookEndpoint) => {
  return await store.dispatch('webhook/addWebhookEndpoint', webhook);
};

const addWebhookEndpointInit = () => {
  webhookForm.value.url = webhookForm.value.url.startsWith('https://')
    ? webhookForm.value.url
    : `https://${webhookForm.value.url.replace(/^http:\/\//, '')}`;

  console.dir(webhookForm.value.data());

  addWebhookEndpoint(webhookForm.value.data()).then((data) => {
    createWebhookSuccess.value = true;
    webhookForm.value.errors.clear();
    router.push({name: 'settings.providers.webhooks.update', params: {uuid: data.webhook.uuid}}).catch(() => {
    });
  }).catch((error) => {
    console.dir(error);
    webhookForm.value.errors.set(error.response.data.errors)
  });
}

const createWebhookSuccess = ref(false);
const items = ref([
  'reservation.created',
]);
</script>
