import axios from 'axios';
import {findIndex, get} from 'lodash';
import {EventBus} from '@/plugins/eventBus';
import {StorageService} from '@/services/storage.service';
import * as types from '../mutation-types';
import {Connection} from '@/types';
import {performApiRequest} from '@/store/helpers/vuex-helpers';

type GetaroundConnection = Connection & {
    gaid?: string;
}

export interface GetaroundState {
    init: boolean;
    loading: Record<string, boolean>;
    importedVehicles: any[];
    vehicles: any[];
    currentWorkingTrip: any;
    trips: any[];
    reservation: any;
    reservationReceipt: any;
    connections: GetaroundConnection[];
}

const state: GetaroundState = {
    init: false,

    loading: {
        pulse: false,
        delete: false,
        toggle: false,
        vehicles: false,
        vehicleHistory: false,
        importedVehicles: false,
        receipt: false,
    },

    importedVehicles: [],
    vehicles: [],

    currentWorkingTrip: {},

    trips: [],
    reservation: {},
    reservationReceipt: {},

    connections: [],
};

const getters = {
    loading: (state: GetaroundState) => state.loading,
    init: (state: GetaroundState) => state.init,
    trips: (state: GetaroundState) => state.trips,
    reservation: (state: GetaroundState) => state.reservation,
    reservationReceipt: (state: GetaroundState) => state.reservationReceipt,
    vehicles: (state: GetaroundState) => state.vehicles,
    importedVehicles: (state: GetaroundState) => state.importedVehicles,
    currentWorkingTrip: (state: GetaroundState) => state.currentWorkingTrip,
    connections: (state: GetaroundState) => state.connections,
};

const mutations = {
    [types.SET_GETAROUND_INIT](state: GetaroundState, init) {
        state.init = init;
    },
    [types.SET_GETAROUND_LOADING](state: GetaroundState, loading) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_GETAROUND_CONNECTIONS](state: GetaroundState, connections) {
        state.connections = connections;
    },
    [types.SET_GETAROUND_VEHICLES](state: GetaroundState, vehicles) {
        state.vehicles = vehicles;
    },
    [types.SET_GETAROUND_TRIPS](state: GetaroundState, trips) {
        state.trips = trips;
    },
    [types.SET_GETAROUND_RESERVATION](state: GetaroundState, reservation) {
        state.reservation = reservation;
    },
    [types.SET_GETAROUND_RESERVATION_RECEIPT](state: GetaroundState, receipt) {
        state.reservationReceipt = receipt;
    },
    [types.SET_ALL_IMPORTED_GETAROUND_VEHICLES](state: GetaroundState, vehicles) {
        state.importedVehicles = vehicles;
    },
};

const actions = {

    getaroundPulse({state: GetaroundState, commit, rootGetters}, gaid: string) {
        commit('SET_GETAROUND_LOADING', {subject: 'pulse', loading: true});

        let connIndex = findIndex(state.connections, (c) => gaid === c.uuid);

        if (connIndex === -1) {
            connIndex = state.connections.push({
                gaid,
                loading: true,
                connected: true,
                active: true,
                email: '',
                provider: 'getaround',
            });
            connIndex--;
            console.dir(connIndex);
        }

        state.connections[connIndex].loading = true;
        state.connections[connIndex].connected = false;
        axios.get(`/v1/company/${rootGetters['company/company'].uuid}/connections/getaround/${gaid}/pulse`)
            .then(() => {
                state.connections[connIndex].connected = true;
            }).catch(() => {
            state.connections[connIndex].connected = false;
        }).finally(() => {
            state.connections[connIndex].loading = false;
            commit('SET_GETAROUND_LOADING', {subject: 'pulse', loading: false});
        });
    },

    async toggleGetaroundActive({commit}, ga) {
        try {
            const responseData = await performApiRequest(commit, 'SET_GETAROUND_LOADING', 'toggle', () => axios.put(`/v1/connections/getaround/${ga.uuid}/toggle`));
            commit('SET_GETAROUND_CONNECTIONS', responseData?.company?.connections?.getaround);
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Getaround connections toggled successfully!',
            });
        } catch (error) {
            console.dir(error);
        }
    },
    refreshToken({commit}, ga) {
        commit('SET_GETAROUND_LOADING', {subject: 'refresh-token', loading: true});
        axios.post(`/v1/company/${StorageService.getCompanyUuid()}/connections/getaround/${ga.uuid}/refresh-token`)
            .then(() => {
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Getaround token refreshed successfully!',
                });
            }).catch((e) => {
            console.dir(e);
        }).finally(() => {
            commit('SET_GETAROUND_LOADING', {subject: 'refresh-token', loading: false});
        });
    },

    disconnectGetaroundCommit({commit, rootGetters}, ga) {
        commit('SET_GETAROUND_LOADING', {subject: 'delete', loading: true});
        axios.delete(`/v1/company/${rootGetters['company/company'].uuid}/connections/getaround/${ga.uuid}`)
            .then((response) => {
                commit('SET_GETAROUND_CONNECTIONS', get(response, 'data.company.connections.getaround'));
                EventBus.$emit('remove-getaround-connection-success');
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Getaround connections removed successfully!',
                });
            }).catch((e) => {
            console.dir(e);
        }).finally(() => {
            commit('SET_GETAROUND_LOADING', {subject: 'delete', loading: false});
        });
    },

    fetchBookings({commit, rootGetters}, ga) {
        commit('SET_GETAROUND_LOADING', {subject: 'vehicleHistory', loading: true});
        axios.get(`/v1/company/${StorageService.getCompanyUuid()}/connections/getaround/${ga.uuid}/reservations`)
            .then((response) => {
                commit('SET_GETAROUND_TRIPS', get(response, 'data.trips'));
            }).catch((e) => {
            console.dir(e);
        }).finally(() => {
            commit('SET_GETAROUND_LOADING', {subject: 'vehicleHistory', loading: false});
        });
    },

    fetchOneReservation({commit}, {ga, reservation_id}) {
        commit('SET_GETAROUND_LOADING', {subject: 'vehicleHistory', loading: true});
        axios.get(`/v1/company/${StorageService.getCompanyUuid()}/connections/getaround/${ga.uuid}/reservations/${reservation_id}`)
            .then((response) => {
                commit('SET_GETAROUND_RESERVATION', get(response, 'data.trip'));
            }).catch((e) => {
            console.dir(e);
        }).finally(() => {
            commit('SET_GETAROUND_LOADING', {subject: 'vehicleHistory', loading: false});
        });
    },
    fetchOneReservationReceipt({commit}, {ga, reservation_id}) {
        commit('SET_GETAROUND_LOADING', {subject: 'receipt', loading: true});
        axios.get(`/v1/company/${StorageService.getCompanyUuid()}/connections/getaround/${ga.uuid}/reservations/${reservation_id}/receipt`)
            .then((response) => {
                commit('SET_GETAROUND_RESERVATION_RECEIPT', get(response, 'data.receipt'));
            }).catch((e) => {
            console.dir(e);
        }).finally(() => {
            commit('SET_GETAROUND_LOADING', {subject: 'receipt', loading: false});
        });
    },

    fetchVehicles({commit, rootGetters}, ga) {
        commit('SET_GETAROUND_LOADING', {subject: 'vehicles', loading: true});
        axios.get(`/v1/company/${rootGetters['company/company'].uuid}/connections/getaround/${ga.uuid}/vehicles`)
            .then((response) => {
                commit('SET_GETAROUND_VEHICLES', get(response, 'data.vehicles'));
                commit('SET_ALL_IMPORTED_GETAROUND_VEHICLES', get(response, 'data.vehicles'));
            }).catch((e) => {
            console.dir(e);
        }).finally(() => {
            commit('SET_GETAROUND_LOADING', {subject: 'vehicles', loading: false});
        });
    },

    importGetaroundListings({commit}, {listings, ga}) {
        commit('SET_GETAROUND_LOADING', {subject: 'import_listings', loading: true});
        EventBus.$emit('save-imported-listings-started', 'getaround');

        axios.post(`/v1/company/${StorageService.getCompanyUuid()}/connections/getaround/${ga.uuid}/import`, {listings})
            .then((response) => {
                commit('company/SET_COMPANY', response.data.company, {root: true});
                commit('SET_ALL_LISTINGS', get(response.data.company, 'listings', []));
                EventBus.$emit('save-getaround-imported-listings-success');
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Getaround listing Import success',
                });
            }).catch(() => {
        }).finally(() => {
            EventBus.$emit('save-turo-imported-listings-success');
            commit('SET_GETAROUND_LOADING', {subject: 'import_listings', loading: false});
        });
    },

    fetchImportedVehicles({commit}) {
        commit('SET_GETAROUND_LOADING', {subject: 'importedVehicles', loading: true});
        axios.get('/v1/imported-getaround-vehicles')
            .then((response) => {
                commit('SET_ALL_IMPORTED_GETAROUND_VEHICLES', response.data.vehicles);
            }).catch(() => {
        }).finally(() => {
            commit('SET_GETAROUND_LOADING', {subject: 'importedVehicles', loading: false});
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
