import Errors from './Errors';
import {deepCopy} from './util';

interface ErrorResponse {
    data: {
        errors?: Record<string, string[]>;
        message?: string;
    }
}

class Form<T extends Record<string, any>> {
    static ignore: string[];
    static errorMessage: string;
    busy: boolean;
    successful: boolean;
    originalData: T;
    errors: Errors;

    // Dynamic properties from `T`
    [key: string]: any; // Allows dynamic assignment of properties

    /**
     * Create a new form instance.
     *
     * @param {Object} data
     */
    constructor(data: T = {} as T) {
        this.busy = false;
        this.successful = false;
        this.errors = new Errors();
        this.originalData = deepCopy(data);

        Object.assign(this, data);
    }

    /**
     * Fill form data.
     *
     * @param {Object} data
     */
    fill(data: Partial<T> | undefined): void {
        if (!data || typeof data !== 'object') {
            // Do nothing if `data` is undefined or not an object
            return;
        }

        this.keys().forEach((key) => {
            if (key in data) {
                this[key] = data[key];
            }
        });
    }


    /**
     * Get the form data.
     *
     * @return {Object}
     */
    data(): T {
        const result = {} as T;
        this.keys().forEach((key) => {
            (result as any)[key] = this[key]; // Safely assign properties
        });
        return result;
    }

    dataGet(name: string): any {
        return this.originalData[name];
    }

    /**
     * Get the form data keys.
     *
     * @return {Array}
     */
    keys(): string[] {
        return Object.keys(this)
            .filter(key => !Form.ignore.includes(key));
    }

    startProcessing(): void {
        this.errors.clear();
        this.busy = true;
        this.successful = false;
    }

    finishProcessing(success = true): void {
        this.busy = false;
        this.successful = success;
    }

    /**
     * Extract the errors from the response object.
     *
     * @param  {Object} response
     * @return {Object}
     */
    extractErrors(response: ErrorResponse): object {
        const {data} = response;

        if (!data || typeof data !== 'object') {
            return {error: Form.errorMessage};
        }

        if (data.errors) {
            return {...data.errors};
        }

        if (data.message) {
            return {error: data.message};
        }

        return {...data};
    };

    /**
     * Clear errors on keydown.
     *
     * @param {KeyboardEvent} event
     */
    onKeydown(event: KeyboardEvent): void {
        if (event.target instanceof Element) {
            const targetElement = event.target as HTMLInputElement;
            if (targetElement.name) {
                this.errors.clear(targetElement.name);
            }
        }
    }
}

Form.errorMessage = 'Something went wrong. Please try again.';
Form.ignore = ['busy', 'successful', 'errors', 'originalData'];

export default Form;
