export default async function companyStore({store}) {
    console.log('%c -- MIDDLEWARE -- COMPANY STORE ✅', 'color: white; background: green; padding: 2px; font-weight: bold;');

    try {
        // Extract company from URL parameters, fwData, or fallback to window location
        const urlParams = new URLSearchParams(window.location.search);
        const urlCompany = urlParams.get('company');

        let company = urlCompany || window.fwData?.company || window.location.host;

        // if company has .fleetwire.io in it, remove it
        if (company.includes('.fleetwire.io')) {
            company = company.replace('.fleetwire.io', '');
        }

        console.log('%c 🏢 Detected Company:', 'color: cyan; background: black; font-weight: bold;', company);

        if (!company) {
            console.warn('%c ⚠️ No company identifier found!', 'color: orange; font-weight: bold;');
            return;
        }

        // Fetch company details from Vuex store
        await store.dispatch('company/fetchCompany', company);
    } catch (error) {
        console.error('%c ❌ Company Store Middleware Error:', 'color: red; font-weight: bold;', error);
    }
}