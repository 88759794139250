import axios from 'axios';
import {EventBus} from '@/plugins/eventBus';
import {Form} from '@/plugins/FWForms/index';
import * as types from '../mutation-types';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {StoreLoaders} from '@/types';

export interface EmailTemplate {
    context: string;
    context_type: string;
    created_at: string;
    default: boolean;
    email_body: string;
    email_body_blocks: {
        blocks: any[];
        time?: number;
        version?: string;
    };
    email_layout_id: string;
    identifier: string;
    is_automatic: boolean;
    name: string;
    subject: string;
    updated_at: string;
    uuid: string;
}

export type Email = {
    attachments: Array<any>,
    blocks: Array<any>,
    body: string,
    order_id: string,
    recipients: Array<string>,
    subject: string,
    template: {
        name: string,
        subject: string,
        identifier: string,
        uuid: string,
    },
}

type EmailHistory = {
    created_at: string,
    email_body: string,
    email_full_body: string,
    email_layout_id: string,
    order_id: string,
    recipients: Array<string>,
    status: string,
    subject: string,
    updated_at: string,
    uuid: string,
}

export interface EmailState {
    loading: StoreLoaders,
    emailForm: Form<any>,
    history: EmailHistory[],
    emails: Array<any>,
    emailTemplates: EmailTemplate[],
    emailTemplate: EmailTemplate,
    email: Email,
}

const state: EmailState = {
    loading: {
        emails: false,
        createEmail: false,
        sendEmail: false,
        history: false,
        email_templates: false,
    },
    emailForm: new Form(),
    history: [],
    emails: [],
    emailTemplates: [],
    emailTemplate: null,
    email: null,
};

const getters = {
    loading: (state: EmailState) => state.loading,
    emailForm: (state: EmailState) => state.emailForm,
    emails: (state: EmailState) => state.emails,
    email: (state: EmailState) => state.email,
    history: (state: EmailState) => state.history,
    emailTemplates: (state: EmailState) => state.emailTemplates,
    emailTemplate: (state: EmailState) => state.emailTemplate,
};

const mutations = {
    [types.SET_EMAILS_LOADING](state: EmailState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_EMAILS](state: EmailState, emails: Array<any>) {
        state.emails = emails;
    },
    [types.SET_EMAIL_HISTORY](state: EmailState, emails: EmailHistory[]) {
        state.history = emails;
    },
    [types.SET_EMAIL_TEMPLATES](state: EmailState, templates: EmailTemplate[]) {
        state.emailTemplates = templates;
    },
    [types.SET_EMAIL_TEMPLATE](state: EmailState, template: EmailTemplate) {
        state.emailTemplate = template;
    },
    [types.SET_EMAIL_FORM_ERROR](state: EmailState, error: string | Record<string, string[]>) {
        state.emailForm.errors.set(error);
    },
    [types.CLEAR_EMAIL_FORM_ERRORS](state: EmailState, form_field: string | null = null) {
        if (form_field === null || form_field === undefined) {
            state.emailForm.errors.clear();
        } else {
            state.emailForm.errors.clear(form_field);
        }
    },
};

const actions = {

    /**
     * Create an email.
     */
    async sendEmail({commit}, email: Email) {
        commit(types.CLEAR_EMAIL_FORM_ERRORS);

        try {
            const responseData = await performApiRequest(commit, 'SET_EMAILS_LOADING', 'sendEmail', () => axios.post('/v1/emails/send', email));
            EventBus.$emit('send-email-success', responseData.email);
        } catch (e) {
            const message = e?.response?.data?.errors?.[0] || e?.response?.data?.message || 'There was an error';
            EventBus.$emit('send-email-error', message);
            commit(types.SET_EMAIL_FORM_ERROR, e.response.data?.errors || 'There was an error');
        }
    },

    /**
     * Get email history
     */
    async history({commit}, data: { type: string, id: string }) {
        const params = new URLSearchParams(data).toString();

        try {
            const responseData = await performApiRequest(commit, 'SET_EMAILS_LOADING', 'history', () => axios.get(`/v1/emails/history?${params}`));
            commit(types.SET_EMAIL_HISTORY, responseData.emails || []);
        } catch (e) {
        }
    },

    /**
     * Get email templates
     */
    async getEmailTemplates({commit}, data: { context?: string } = undefined) {
        const qString = data?.context ? `?context=${data.context}` : '';

        try {
            const responseData = await performApiRequest(commit, 'SET_EMAILS_LOADING', 'email_templates', () => axios.get(`/v1/email-templates${qString}`));
            commit('SET_EMAIL_TEMPLATES', responseData.emailTemplates || []);
        } catch (error) {
        }
    },

    /**
     * Get email template
     */
    async getEmailTemplate({commit}, uuid: string) {
        const responseData = await performApiRequest(commit, 'SET_EMAILS_LOADING', 'email_templates', () => axios.get(`/v1/email-templates/${uuid}`));
        commit(types.SET_EMAIL_TEMPLATE, responseData.emailTemplate || {});
    },

    /**
     * Update email template
     */
    async updateEmailTemplate({commit}, emailTemplate: EmailTemplate) {
        try {
            const responseData = await performApiRequest(commit, 'SET_EMAILS_LOADING', 'email_templates', () => axios.put(`/v1/email-templates/${emailTemplate.uuid}`, emailTemplate));
            commit(types.SET_EMAIL_TEMPLATE, responseData.emailTemplate || {});
            return responseData.emailTemplate;
        } catch (error) {
        }
    },

    /**
     * Reset email template
     */
    async resetEmailTemplate({commit}, emailTemplate: EmailTemplate) {
        const responseData = await performApiRequest(commit, 'SET_EMAILS_LOADING', 'email_templates', () => axios.post(`/v1/email-templates/${emailTemplate.uuid}/reset`));
        commit(types.SET_EMAIL_TEMPLATE, responseData.emailTemplate || {});
        return responseData.emailTemplate;
    },

    /**
     * Send test email template
     */
    async sendTestTemplateEmail({commit}, emailTemplate: EmailTemplate) {
        try {
            await performApiRequest(commit, 'SET_EMAILS_LOADING', 'email_templates', () => axios.post(`/v1/email-templates/${emailTemplate.uuid}/send-test`));
        } catch (error) {
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
