import {createI18n} from 'vue-i18n'
import enUS from '@/lang/en-US.json';
import es from '@/lang/es.json';
import zncn from '@/lang/zh-CN.json';
import {ref} from 'vue';

/**
 * Supported locale types in the application
 */
export type SupportedLocale = 'en-US' | 'es' | 'zh-CN';

/**
 * List of supported locales
 */
export const SUPPORT_LOCALES: SupportedLocale[] = ['en-US', 'es', 'zh-CN'];

/**
 * Default locale for the application
 */
const DEFAULT_LOCALE: SupportedLocale = 'en-US';

/**
 * Initial locale state
 */
const locale = ref<SupportedLocale>(DEFAULT_LOCALE);

/**
 * Creates and configures the i18n instance
 */
const i18n = createI18n({
    legacy: false,
    locale: locale.value,
    fallbackLocale: DEFAULT_LOCALE,
    messages: {
        'en-US': enUS,
        'es': es,
        'zh-CN': zncn,
    },
    silentTranslationWarn: true,
    globalInjection: true,
    modifiers: {
        snakeCase: (str: string) => str.split(' ').join('_')
    }
});

/**
 * Loads messages for a specific locale if they haven't been loaded yet
 * @param locale - The locale to load messages for
 */
export async function loadMessages(locale: SupportedLocale): Promise<void> {
    try {
        const messages = i18n.global.getLocaleMessage(locale);
        if (Object.keys(messages).length === 0) {
            const importedMessages = await import(/* @vite-ignore */`@/lang/${locale}.json`);
            i18n.global.setLocaleMessage(locale, importedMessages.default);
        }

        if (i18n.global.locale.value !== locale) {
            i18n.global.locale.value = locale;
        }
    } catch (error) {
        console.error(`Failed to load messages for locale ${locale}:`, error);
        throw error;
    }
}

// Initialize with default locale
loadMessages(DEFAULT_LOCALE).catch(error => {
    console.error('Failed to initialize i18n with default locale:', error);
});

export default i18n;
