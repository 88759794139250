import axios from 'axios';
import {Form} from '@/plugins/FWForms/index';
import {EventBus} from '@/plugins/eventBus';
import {StorageService} from '@/services/storage.service';
import * as types from '../mutation-types';
import {createUrlQueryString} from '@/utils/urls';
import {CreditCards, RenterInterface, StoreLoaders} from '@/types';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {push} from 'notivue';

export interface fetchRenterOptions {
    r_id: string,
    include?: string | string[],
    options?: { includeOrders: boolean | 1 | 0 },
}

export interface RenterState {
    loading: StoreLoaders,
    init: boolean,
    currentRenter: RenterInterface,
    booking_charge: any,
    renters: RenterInterface[],
    allRenters: RenterInterface[],
    searchedRenters: RenterInterface[],
    stripeCustomerObj: any,
    renterForm: Form<any>,
    totalCustomers: number,
    notificationsTotal: number,
    notifications: any[],
    dialogs: Record<string, boolean>,
    customerTagListCounts: Record<string, number>,
    customerPagination: {
        current_page: number,
        from: number,
        last_page: number,
        per_page: number,
        to: number,
        total: number,
    }
    customersQuery?: {
        page?: number,
        itemsPerPage?: number,
        sortBy?: string[],
        sortDesc?: boolean[],
        filter?: {
            tag_list?: string[],
            archived?: boolean,
            search?: string,
        },
        include?: string,
    }
}

interface RentersResponseInterface {
    data: RenterInterface[],
    links: object,
    meta: {
        current_page: number,
        from: number,
        last_page: number,
        links: object[],
        path: string,
        per_page: number,
        to: number,
        total: number,
        stats: {
            tag_list: Record<string, number>,
            total: number
        },
        total_customers: number,
    },
}

const state: RenterState = {
    loading: {
        renter: false,
        renterOrders: false,
        renters: false,
        adding: false,
        deleting: false,
        searching: false,
        stripeLoading: false,
        teslaFeeInvoice: false,
        paymentMethod: false,
        exportRenters: false,
        renterNotifications: false,
        mergeRenters: false,

    },
    init: false,
    currentRenter: {
        first_name: '',
        last_name: '',
        full_name: '',
        email: '',
        phone: '',
        r_id: '',
        loaded: false,
        payment_sources: [],
        creditCards: [],
        uuid: null,
        source: '',
        created_at: '',
    },
    booking_charge: {},
    renters: [],
    allRenters: [],
    searchedRenters: [],
    stripeCustomerObj: {},
    renterForm: new Form(),
    totalCustomers: 0,
    notificationsTotal: 0,
    notifications: [],
    dialogs: {
        exportCustomers: false,
    },
    customerTagListCounts: {},
    customerPagination: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
    },
    customersQuery: {
        page: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: [],
        filter: {
            tag_list: [],
            archived: false,
            search: '',
        },
        include: '',
    }
};


const getters = {
    init: (state: RenterState) => state.init,
    rentersLoading: (state: RenterState) => state.loading,
    renters: (state: RenterState) => state.renters,
    allRenters: (state: RenterState) => state.allRenters,
    searchedRenters: (state: RenterState) => state.searchedRenters,
    currentRenter: (state: RenterState) => state.currentRenter,
    stripeCustomerObj: (state: RenterState) => state.stripeCustomerObj,
    renterForm: (state: RenterState) => state.renterForm,
    totalCustomers: (state: RenterState) => state.totalCustomers,
    notificationsTotal: (state: RenterState) => state.notificationsTotal,
    notifications: (state: RenterState) => state.notifications,
    dialogs: (state: RenterState) => state.dialogs,
    customerTagListCounts: (state: RenterState) => state.customerTagListCounts,
    customersQuery: (state: RenterState) => state.customersQuery,
    customerPagination: (state: RenterState) => state.customerPagination,
};

const mutations = {
    [types.SET_RENTERS_LOADING](state: RenterState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
        state.init = true;
    },
    [types.SET_RENTERS](state: RenterState, data: RentersResponseInterface) {
        console.dir('SET_RENTERS');
        console.dir(data);
        state.renters = data.data;
        state.totalCustomers = data.meta.total_customers;
        state.customerTagListCounts = data.meta.stats.tag_list;
        state.customerPagination = {
            current_page: data.meta.current_page,
            from: data.meta.from,
            last_page: data.meta.last_page,
            per_page: data.meta.per_page,
            to: data.meta.to,
            total: data.meta.total,
        }
        state.init = true;
    },
    [types.SET_ALL_RENTERS](state: RenterState, renters: { renters: RenterInterface[] }) {
        state.allRenters = renters?.renters;
    },

    [types.SET_RENTERS_DIALOG](state: RenterState, payload: { topic: string, value: boolean }) {
        state.dialogs[payload.topic] = payload.value;
    },
    [types.SET_RENTER](state: RenterState, renter: RenterInterface) {
        state.currentRenter = {...state.currentRenter, ...renter};
        state.currentRenter.loaded = true;
        state.init = true;
    },
    [types.ADD_RENTER](state: RenterState, renter: RenterInterface) {
        const renterIndex = state.renters.findIndex((r) => r.r_id === renter.r_id);
        if (renterIndex >= 0) {
            state.renters[renterIndex] = renter;
        } else {
            state.renters.push(renter);
        }
    },
    [types.RESET_RENTER](state: RenterState, renter: RenterInterface) {
        state.currentRenter = renter;
    },
    [types.SET_RENTERS_CREDIT_CARDS](state: RenterState, cards: CreditCards[]) {
        state.currentRenter.creditCards = cards;
    },
    [types.SET_PAYMENT_SOURCES](state: RenterState, sources) {
        state.currentRenter.payment_sources = sources;
    },
    [types.SET_BOOKING_PAYMENT](state: RenterState, charge) {
        state.booking_charge = charge;
    },
    [types.SET_PHONE_NUMBER](state: RenterState, number: string) {
        state.currentRenter.phone = number;
    },
    [types.SET_SEARCHED_RENTERS](state: RenterState, renters: any[]) {
        state.searchedRenters = renters;
    },
    [types.CLEAR_RENTERS_FORM_ERROR](state: RenterState, form_field: string) {
        if (form_field === null || form_field === undefined) {
            state.renterForm.errors.clear();
        } else {
            state.renterForm.errors.clear(form_field);
        }
    },
    [types.SET_STRIPE_CUSTOMER](state: RenterState, stripe_customer) {
        state.stripeCustomerObj = stripe_customer;
    },
    [types.SET_RENTER_NOTIFICATIONS](state: RenterState, notifications) {
        state.notifications = notifications?.data || [];
        state.notificationsTotal = notifications?.total || 0;
    },
    [types.SET_CUSTOMER_QUERY](state: RenterState, data: { key: string, value: any }) {
        console.dir('SET_CUSTOMER_QUERY');
        console.dir(data);

        state.customersQuery[data.key] = data.value;
    },
};

const actions = {

    /**
     * Reset renters
     */
    resetRenter({commit}) {
        const renter = {
            first_name: '',
            last_name: '',
            full_name: '',
            email: '',
            phone: '',
            r_id: '',
            loaded: false,
            payment_sources: [],
            creditCards: [],
            uuid: null,
            source: '',
            created_at: '',
        };

        commit(types.RESET_RENTER, renter);
    },


    /**
     * Fetch renters
     */
    async fetchCompanyRenters({commit, state}) {
        try {
            const options = state.customersQuery;

            const {sortBy = [], sortDesc = [], filter = {}} = options;

            const queryObject = {
                'sort': sortBy.length > 0 ? (sortDesc?.[0] ? `-${sortBy[0]}` : sortBy[0]) : null,
                'filter[tag_list]': filter.tag_list || null,
                'filter[archived]': filter.archived ?? null,
                'filter[q]': filter.search || null,
                'page': options.page || 1,
                'itemsPerPage': options.itemsPerPage || 30,
                'include': options.include,
            };

            // Convert the object into a query string
            const query = createUrlQueryString(queryObject, true);
            console.log('✅ API Query String:', query);

            const responseData = await performApiRequest(commit, 'SET_RENTERS_LOADING', 'renters', () => axios.get(`/v1/renters${query}`));
            commit(types.SET_RENTERS, responseData);
        } catch (error) {
            console.error(error);
        }
    },


    /**
     * Fetch all renters for export
     */
    async fetchRentersForExport({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_RENTERS_LOADING', 'exportRenters', () => axios.get('/v1/renters-export'));

            commit(types.SET_ALL_RENTERS, responseData);
        } catch (error) {
            console.error(error)
        }
    },

    async mergeCustomers({commit}, data: { main: string, second: string, mode: 'simple' | 'advanced' }) {
        await performApiRequest(commit, 'SET_RENTERS_LOADING', 'mergeRenters', () => axios.post('/v1/merge-renters', data));
    },

    importCustomers({commit}, data: any) {
        commit(types.SET_RENTERS_LOADING, {subject: 'mergeRenters', loading: true});

        axios.post('/v1/renters/import/csv', {data})
            .then(() => {
                EventBus.$emit('renter-import-success');
            }).catch(() => {
        }).finally(() => {
            commit(types.SET_RENTERS_LOADING, {subject: 'mergeRenters', loading: false});
        });
    },


    /**
     * Fetch all renters for export
     */
    fetchRenterNotifications({commit}, renter: RenterInterface) {
        commit(types.SET_RENTERS_LOADING, {subject: 'renterNotifications', loading: true});

        axios.get(`/v1/renters/${renter.r_id}/notifications`)
            .then((response) => {
                commit(types.SET_RENTER_NOTIFICATIONS, response?.data?.notifications || []);
            }).catch(() => {
        }).finally(() => {
            commit(types.SET_RENTERS_LOADING, {subject: 'renterNotifications', loading: false});
        });
    },


    /**
     * Fetch a renter
     */
    async fetchRenter({commit}, data: fetchRenterOptions) {

        console.dir('data');
        console.dir(data);
        const renterId = data.r_id;
        if (!renterId) {
            throw new Error('No renter id provided');
        }

        if (data?.include) {
            if (Array.isArray(data?.include)) {
                data.include = data.include.join(',');
            }
        }

        const queryObject = {
            include: data?.include,
            includeOrders: data?.options?.includeOrders,
        };
        console.dir(queryObject);

        const query = createUrlQueryString(queryObject, true);

        console.dir(query);

        await axios.get(`/v1/renters/${renterId}${query}`)
            .then((response) => {
                commit(types.SET_RENTER, response.data.renter);
            }).catch(() => {
                EventBus.$emit('fetch-renter-failure');
            }).finally(() => {
                commit(types.SET_RENTERS_LOADING, {subject: 'renter', loading: false});

                if (data?.options?.includeOrders) {
                    commit(types.SET_RENTERS_LOADING, {subject: 'renterOrders', loading: false});
                }
            });
    },

    /**
     * Update a renter
     */
    async updateRenter({commit}, renter: RenterInterface) {
        try {
            const responseData = await performApiRequest(commit, 'SET_RENTERS_LOADING', 'renters', () => axios.put(`/v1/renters/${renter.r_id}`, renter));

            commit(types.SET_RENTER, responseData?.renter);
            push.success({
                message: 'Customer saved successfully!',
            });
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error saving this renter.',
            });
        }
    },


    /**
     * Create a renter
     */
    async createRenter({state, commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_RENTERS_LOADING', 'adding', () => axios.post('/v1/renters', state.currentRenter));

            commit(types.SET_RENTER, responseData?.renter);
            commit(types.ADD_RENTER, responseData?.renter);
            push.success({
                message: 'Customer created successfully!',
            });
            EventBus.$emit('new-customer-created-success', responseData?.renter);

            return responseData?.renter;
        } catch (error) {
            if (error?.response?.data?.errors) {
                const errorMessage = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0];
                push.error({
                    message: errorMessage,
                });
            } else {
                push.error({
                    message: 'Customer create error',
                });
            }
            state.renterForm.errors.set(error?.response?.data?.errors);
        }
    },


    /**
     * Delete a renter
     */
    async deleteRenter({commit}, renter: RenterInterface) {
        try {
            await performApiRequest(commit, 'SET_RENTERS_LOADING', 'deleting', () => axios.delete(`/v1/renters/${renter.r_id}`));
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
            throw new Error(error);
        }
    },


    /**
     * Stripe customer
     */
    stripeCustomer({commit}, r_id: string) {
        commit(types.SET_RENTERS_LOADING, {subject: 'stripeLoading', loading: true});

        axios.get(`/v1/renters/${r_id}/stripe-customer`)
            .then((response) => {
                console.dir(response.data);
                commit(types.SET_STRIPE_CUSTOMER, response?.data?.stripeCustomer);
            }).catch(() => {
        }).finally(() => {
            commit(types.SET_RENTERS_LOADING, {subject: 'stripeLoading', loading: false});
        });
    },


    /**
     * Create renter invoice
     */
    renterInvoiceCreate({commit}, order: any) {
        commit(types.SET_RENTERS_LOADING, {subject: 'stripeLoading', loading: true});
        axios.post(`/v1/company/${StorageService.getCompanyUuid()}/renters/${order.r_id}/invoice`, order)
            .then((response) => {
                EventBus.$emit('renter-invoice-create-success', response?.data?.payment);
            }).catch(() => {
        }).finally(() => {
            commit(types.SET_RENTERS_LOADING, {subject: 'stripeLoading', loading: false});
        });
    },


    /**
     * Create renter Tesla fee invoice
     */
    teslaFeeInvoiceCreate({commit}, data: any) {
        commit(types.SET_RENTERS_LOADING, {subject: 'teslaFeeInvoice', loading: true});

        axios.post(`/v1/company/${StorageService.getCompanyUuid()}/renters/${data.order.renter.r_id}/tesla-fee-invoice`, data.order)
            .then((response) => {
                push.success({
                    message: 'Invoice sent successfully',
                });
                commit('tesla/SET_TESLA_INVOICES', response?.data?.invoices, {root: true});
                EventBus.$emit('tesla-fee-invoice-created-successfully');
            }).catch((e) => {
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit(types.SET_RENTERS_LOADING, {subject: 'teslaFeeInvoice', loading: false});
        });
    },


    /**
     * Fetch renter payments
     */
    renterPayments({commit}, r_id: string) {
        axios.get(`/v1/company/${StorageService.getCompanyUuid()}/renters/${r_id}/payments`)
            .then((response) => {
                commit(types.SET_PAYMENT_SOURCES, response?.data?.customer?.sources?.data || []);
            }).catch(() => {
        }).finally(() => {
            commit('SET_RENTERS_LOADING', {subject: 'renters', loading: false});
        });
    },


    /**
     * Fetch renter charges
     */
    async renterCharges({commit}, r_id: string) {
        return await performApiRequest(commit, 'SET_RENTERS_LOADING', 'searching', () => axios.get(`/renters/${r_id}/charges`));
    },


    /**
     * Search renters
     */
    async searchRenters({commit}, query: string) {
        const responseData = await performApiRequest(commit, 'SET_RENTERS_LOADING', 'searching', () => axios.get(`/v1/search/renters?search=${query}`));
        commit(types.SET_SEARCHED_RENTERS, responseData.renters || []);
    },

    /**
     * Block renters
     */
    async blockRenter({commit}, data) {
        return await performApiRequest(commit, 'SET_RENTERS_LOADING', 'block_renter', () => axios.post(`/v1/renters/block`, data));
    },

    async unblockRenter({commit}, data) {
        return await performApiRequest(commit, 'SET_RENTERS_LOADING', 'unblock_renter', () => axios.post(`/v1/renters/unblock`, data));
    },

    /**
     * Save a Stripe payment method for renter
     */
    stripeSavePaymentMethod({commit}, {renter, paymentMethod}) {
        commit(types.SET_RENTERS_LOADING, {subject: 'paymentMethod', loading: true});
        axios.post(`/v1/company/${StorageService.getCompanyUuid()}/renters/${renter.r_id}/payment-method/${paymentMethod}`)
            .then((response) => {
                commit(types.SET_RENTERS_CREDIT_CARDS, response?.data?.creditCards || []);
                EventBus.$emit('renter-stripe-payment-method-success', response?.data?.creditCards);
            }).catch(() => {
        }).finally(() => {
            commit(types.SET_RENTERS_LOADING, {subject: 'paymentMethod', loading: false});
        });
    },


    /**
     * Get a renters credit cards
     */
    renterCreditCards({commit}, renter: RenterInterface) {
        commit(types.SET_RENTERS_LOADING, {subject: 'creditCards', loading: true});
        axios.get(`/v1/renters/${renter.r_id}/credit-cards`)
            .then((response) => {
                commit(types.SET_RENTERS_CREDIT_CARDS, response?.data?.creditCards || []);
                EventBus.$emit('renter-credit-cards-success');
            }).catch(() => {
        }).finally(() => {
            commit(types.SET_RENTERS_LOADING, {subject: 'creditCards', loading: false});
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
