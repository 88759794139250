<template>
  <div>
    <v-dialog v-model="agreeToSDialog" fullscreen>
      <v-card>
        <v-card-text class="p-5">
          <div id="terms-of-service">
            <h2 class="h2" style="text-align:center;">
              <strong>Fleetwire.io AFFILIATE PROGRAM. TERMS AND CONDITIONS</strong>
            </h2>
            <small>Updated Dec 16, 2021</small>

            <p style="text-align:justify;">These Affiliate Program Operating Terms and Conditions
              (the
              “<strong>Affiliate Program Terms</strong>”) contain terms and conditions between
              Fleetwire LLC, 30 N Gould St STE R Sheridan, WY 82801, USA.
              (“<strong>Fleetwire.io</strong>”, “<strong>us</strong>”, or “<strong>we</strong>”,
              “<strong>our</strong>”), and you (“<strong>you</strong>”, “<strong>your</strong>” or
              “<strong>Affiliate</strong>”), that govern your participation in our affiliate program
              accessible through this website(“<strong>Affiliate Program</strong>”).</p>

            <p style="text-align:justify;">The Affiliate Program Terms are governed by Fleetwire.io
              General Terms and Conditions for Marketing Campaigns (“<strong>General Terms</strong>”)
              available at <a href="https://fleetwire.io/terms">https://fleetwire.io/terms</a>. The
              Affiliate Program Terms and the General Terms are together referred to as the
              “<strong>Agreement</strong>”.
              You confirm that you have read and understood the Agreement, and agree to the
              provisions
              stated therein. In the event of any inconsistency between the Affiliate Program Terms
              and General Terms, the terms of this Affiliate Program Terms shall prevail.</p>

            <p style="text-align:justify;">Each Affiliate Program offer (an "<strong>Offer</strong>")
              may be for any offering by Fleetwire.io or a third party (each such third party a
              "<strong>Client</strong>") and may link to a specific website for that particular
              Offer
              or other websites of Fleetwire.io (“<strong>Program Website</strong>”). Furthermore,
              each Offer and its accompanying documents may have additional terms and conditions on
              pages within the Affiliate Program and forms a part of this Agreement.</p>

            <p style="text-align:justify;">We reserve the right to include to the Affiliate Program
              additional products in our sole discretion anytime.</p>

            <p style="text-align:justify;">The Fleetwire.io entity which you are contracting with
              under these Affiliate Program Terms is Fleetwire, LLC. In such a case your
              counterparty
              under these Affiliate Program Terms is Fleetwire, LLC, 30 N Gould St STE R Sheridan,
              WY
              82801, USA.</p>

            <ol>
              <li style="text-align:justify;"><strong>ENROLLMENT IN THE AFFILIATE
                PROGRAM</strong><br><br>
                1.1. In order to enrol in the Affiliate Program, you must submit an Affiliate
                Program
                application form on our website (<a href="https://tenant.fleetwire.io/settings/affiliate/">https://tenant.fleetwire.io/settings/affiliate/</a>).
                You must accurately complete the application form to become an affiliate. You must
                not
                use any aliases or other means to mask your true identity or contact information.You
                must provide us with any future updates regarding your contact details immediately
                after they change.<br><br>
                1.2. After we review your application form, we will notify you of your acceptance or
                rejection to the AffiliateProgram. The notification generally is provided within two
                (2) business days after you submit an application form. We may accept or reject your
                application in our sole discretion for any reason.<br>
              </li>

              <li style="text-align:justify;">
                <strong>LINKS TO THE PROGRAM WEBSITE AND PROMOTION OF AFFILIATE
                  RELATIONSHIP</strong>
                <br><br>
                2.1. If you qualify and agree to participate as an Affiliate and we accept your
                application, you will become anAffiliate and we will make available graphic and
                textual links and accesses to the Program Website(collectively,
                “<strong>Links</strong>”) and/or other creative materials to you via the Affiliate
                Program.<br><br>
                2.2. You may display the Links on the websites owned or controlled by you, in emails
                sent by you and/or in online advertisements (collectively, “<strong>Media</strong>”).
                You must be always identified when displaying Links. TheLinks will serve to identify
                you as a member of our Affiliate Program and will establish a connection from your
                Media to the Program Website. The Links may connect to any area of our websites
                (althoughCommissions will only be issued for Qualified Actions).<br><br>
                2.3. In utilizing the Links, you agree that you will reasonably cooperate with us in
                order to establish and maintain such Links. All Affiliate websites shall display the
                Links, including, but not limited to, graphic and/or textual images, prominently in
                relevant sections of their websites.<br><br>
                2.4. Fleetwire.io may change, suspend or discontinue any aspect of an Offer or Link
                or
                remove, alter, or modify any tags, text, graphic or banner advertisement in
                connection
                with the Link. You agree to promptly implement any request from Fleetwire.io to
                remove,
                alter or modify any Link, graphic or banner advertisement that is being used by you
                as
                part of the Affiliate Program.<br><br>
                2.5. When a user follows your Affiliate Link to the Program Website, we place a
                tracking cookie on that user’s computer or mobile device which will be used to
                identify
                that user as a referral from you provided that user creates an account (including a
                free account) on Fleetwire's website within thirty (30) days (after which the cookie
                expires). If the user uses another computer or mobile device to create an account or
                deletes the tracking cookie before creating the account, then we shall have no
                liability to pay to you any Commission in respect of any Fleetwire.io services
                purchased by that user.<br><br>
                2.6. You are not allowed to:<br><br>
                2.6.1. use cookie stuffing techniques that set the Affiliate tracking cookie without
                the knowledge of the users of your Media and your websites (example:
                iframe);<br><br>
                2.6.2. post any refunds, credits or discounts, or other similar content concerning
                Fleetwire, unless we have given you prior written permission in each instance.
                Affiliates
                may only use coupons and discounts that are provided exclusively through the
                Affiliate
                Program using banners and links;<br><br>
                2.7. Each Link connecting users of your Media and/or your website to the pertinent
                area of our websites shall in no way alter the look, feel, or functionality of our
                websites. Any violations of the terms surroundingLinks, other links, coupons,
                refunds,
                credits or discounts shall constitute a material breach of thisAgreement and may
                result in your termination from the Affiliate Program and/or withholding
                ofCommissions.<br></li>
              <li style="text-align:justify;"><strong>DETERMINATION OF COMMISSIONS</strong><br><br>
                3.1. We will pay you payments for each Qualified Action
                (“<strong>Commission</strong>”).
                A “<strong>Qualified Action</strong>” is counted when all of the following actions
                are
                performed:
                <ol class="ml-10" style="list-style-type:lower-roman;">
                  <li>an individual person accesses the Program Website via the Link;</li>
                  <li>pre-populated fields are not used;</li>
                  <li>individual completes all of the information required for accessing the
                    Fleetwire.io service within the time period allowed by Fleetwire;
                  </li>
                  <li>individual purchases Fleetwire.io service(s); and</li>
                  <li>individual does not require a refund,chargeback or cancellation within thirty
                    (30) days after the completion of purchase for such purchase.
                  </li>
                </ol>
                <br>
                3.2. The actions that do not satisfy the conditions for the Qualified Actions
                stipulated in Clause 3.1, are not considered Qualified Action, including, but not
                limited to, the following cases:
                <ol class="ml-10" style="list-style-type:lower-roman;">
                  <li>the Program Website is accessed by a computer-generated user, such as a robot,
                    spider,computer script or other automated, artificial or fraudulent method to
                    appear like an individual, real live person;
                  </li>
                  <li>the access is later determined by Fleetwire.io to be fraudulent, incomplete,
                    unqualified or a duplicate;
                  </li>
                  <li>the actions performed by the Affiliate violate this Agreement, (including, but
                    not limited to, the obligations of the Affiliate stipulated herein) and/or the
                    requirements of the applicable law.
                  </li>
                </ol>
                <br>
                3.3. In some specifically identified cases we, at our sole discretion, may offer
                payouts for free individual registrations. In these cases, the Qualified Action is
                counted when all of the following actions are performed:
                <ol class="ml-10" style="list-style-type:lower-roman;">
                  <li>an individual access the Program Website via the Link;</li>
                  <li>pre-populated fields are not used;</li>
                  <li>individual completes all of the information required for accessing the
                    Fleetwire.io service within the time period allowed by Fleetwire;
                  </li>
                  <li>individual is actively using his/her account for a period of time determined
                    solely by Fleetwire.
                  </li>
                </ol>
                <br></li>
              <li style="text-align:justify;"><strong>PAYMENT OF COMMISSIONS</strong><br><br>
                4.1. We will pay you any earned Commissions when the amount of the Commissions in
                your
                Affiliate account is greater than one hundred United States dollars ($100).
                Commissions
                will be paid via PayPal. Direct wire transfer is available only for sums over one
                thousand United States dollars ($1,000) per month. You shall cover any and all costs
                and expenses related to the payment of Commissions. We shall not have any liability
                for
                Commissions lost due to incorrect payment details provided by you.<br><br>
                4.2. Subject to Clauses 3 “Determination of Commission” and 12 “Fraud”, Commissions
                are paid only if and when we determine that your actions are considered Qualified
                Actions and have remained in compliance with this Agreement for the entirety of the
                period for which the Commissions are paid and throughout to the payment date. We
                reserve the right to charge back to your Affiliate account any and all
                previous payments for Qualified Actions that are later determined by Fleetwire.io to
                have not met the requirements to be aQualified Action.<br><br>
                4.3. All tracking of Links, determinations of Qualified Actions and counting of
                Commissions shall be made by us in our sole discretion according to our technology.
                In
                the event that you are also tracking Links andQualified Actions, tracking results of
                Fleetwire.io shall always prevail.<br><br>
                4.4. Payments for revenue generated by you will be transferred to you once a month,
                following your request. In exceptional cases this term can be extended with a prior
                written notice to you. Due to our Refund policy,you can request a payment of the
                respective Commissions not earlier than forty (40) days after generating this
                respective amount.<br><br>
                4.5. You should send requests for payments to <a
                  href="mailto:hi@fleetwire.io">hi@fleetwire.io</a> and we will
                provide you with our calculations regarding the amount of Commissions earned by you.
                In
                the event that you dispute any portion of the Commissions calculated by us in good
                faith (e.g., calculations contain a mathematical error),you agree and must (i)
                notify
                Fleetwire.io of such disputed amount in writing with sufficient detail within thirty
                (30)days’ after being notified regarding the Commission amount for the respective
                period, (ii) make authorized personnel available to resolve the dispute. If you do
                not
                dispute the calculated amount as set forth herein,you irrevocably waive any claims
                based upon that amount.All amounts not timely and appropriately disputed shall be
                deemed final and not subject to further dispute.<br><br>
                4.6. Fleetwire's Commission payments are directly dependent upon receipt of payments
                from
                customers (except for limited cases of payouts for free individual registrations).
                Therefore, we shall only be liable for theCommissions to the extent that we have
                received such payments from the customers. You shall have no right to any claims for
                Commissions if we have not received respective payments from the customers(including
                but not limited, due to the chargebacks, refunds, cancellations).<br><br>
                4.7. In the event customers terminate our services after the purchase (e.g. any
                chargebacks,refunds,cancellations), the respective amount of your Commission shall
                be
                cancelled. We grant refunds to our customers in accordance with our Refund policy or
                in
                our sole discretion, which means that our customers usually have the right to cancel
                our services and request for a refund within a specified period after the purchase.
                Nevertheless, customers’ right to request for a chargeback is not limited in time.
                Therefore, if we have already paid the relevant Commission to you, we shall deduct
                the
                respective amount from our next payment.<br><br>
                4.8. Where the rate of chargeback(s), refunds or cancellations on your Affiliate
                account is unusually high or we suspect your Affiliate account is being used for
                fraudulent activity or in violation of this Agreement and/or applicable law, we
                reserve
                the right to place restrictions on your Affiliate account immediately upon
                written notice to you, including, but not limited to, suspend your Affiliate account
                and suspend all payments related to your Affiliate account, as well as commence a
                full
                investigation (internal or involving any appropriate third parties). If we find it
                necessary, we have full rights in our sole discretion to inform the appropriate law
                enforcement or regulatory authorities or any third parties of any actual or alleged
                breach of law and/or fraud performed by you (either in or not in connection with
                this
                Agreement). Only once we are satisfied that the matter is resolved, we will remove
                the
                restrictions applied to your Affiliate account.<br><br>
                4.9. If you have an outstanding balance due to Fleetwire.io under this Agreement or
                any other agreement between you and Fleetwire, whether or not related to the
                Affiliate
                Program, you agree that Fleetwire.io may offset any such amounts due to you from
                amounts payable to you under this Agreement.<br><br>
                4.10. If you have not earned any Commissions or have not logged into your Affiliate
                account within a six (6)months period, then we may, in our sole discretion
                unilaterally terminate this Agreement, close yourAffiliate account, and withhold any
                unpaid Commissions. We are relieved from any and all obligations to you, including,
                without limitations, financial obligations, in accordance with the Agreement if you
                do
                not dispute the closing of your Affiliate account as specified in this Clause 4.10
                within five (5) business days from notification informing you about the termination
                of
                the Agreement along with the closure of your account and withholding of unpaid
                Commissions.<br></li>
              <li style="text-align:justify;"><strong>OBLIGATIONS OF THE AFFILIATE</strong><br><br>
                5.1. As an Affiliate you shall:<br><br>
                5.1.1. comply with all (i) obligations, requirements and restrictions under this
                Agreement, and (ii) applicable law,rules and regulations, including but without
                limitation in relation to your business, your Media or your use of the Links, for
                the
                entirety of the period of this Agreement;<br><br>
                5.1.2. have sole responsibility for the development, operation, and maintenance of,
                and all content on or linked to your Media;<br><br>
                5.1.3. disclose commercial relationships between you, your Media and Fleetwire, and
                clearly
                identify advertising and paid promotions. Review sites shall clearly state how
                reviews
                are composed and checked;<br><br>
                5.1.4. ensure that all materials posted on your Media or otherwise used in
                connection
                with the Affiliate Program:
                <ol class="ml-10" style="list-style-type:lower-roman;">
                  <li>comply with the requirements of applicable law and are not illegal,</li>
                  <li>do not infringe any rights of third parties, including, without limitation,
                    intellectual property,personality, publicity rights,
                  </li>
                  <li>do not contain ethically unacceptable content, creative materials or copy
                    (including, but not limited to, keywords, terms, meta tags, descriptions, web
                    designs, etc.),
                  </li>
                  <li>do not contain, create, promote or link to any content which is in any way
                    deceptive,misleading,and does not represent actual and accurate information
                    about
                    Fleetwire.io and it’s products or services,
                  </li>
                  <li>do not contain, create, promote or link to any material which is harmful,
                    threatening, defamatory,obscene, sexually explicit, harassing, promotes
                    violence,
                    discrimination (whether based on sex,religion, race, ethnicity, nationality,
                    disability or age), illegal activities (such as gambling), contains profanity,
                    and/or do not otherwise contain any material which, as informed by Fleetwire.io
                    to
                    you, Fleetwire considers objectionable (collectively, “<strong>Objectionable
                      Content</strong>”);
                  </li>
                </ol>
                <br>
                5.1.5. not make any representations, warranties or other statements concerning
                Fleetwire.io or Client, or any of their respective products or services, except as
                expressly authorized by Fleetwire.io in writing;<br><br>
                5.1.6. not disseminate information that does not correspond to reality and/or any
                other allegations that damages the reputation of Fleetwire.io and each of its
                parents,
                subsidiaries, affiliates, their principals, directors, officers,partners, agents,
                employees, and assignees, whether or not such information/allegations relate to
                thisAgreement or Affiliate Program;<br><br>
                5.1.7. make sure that your Media does not copy or resemble the look, feel and/or
                trade-dress of the ProgramWebsite or create the impression that you or your Media is
                part of or endorsed by Fleetwire.io or Client, or is apart of the Program Website,
                without prior written permission from us;<br><br>
                5.1.8. comply with the terms, conditions, guidelines and policies of any third-party
                services used by you in connection with this Agreement and the Affiliate Program,
                including, but not limited to, email providers,social networking services and
                advertising networks;<br><br>
                5.1.9. always prominently post and make available to any visitors of your websites
                and/or users of your Media(“<strong>your end-users</strong>”), including prior to
                the
                processing of any personal data and/or other personally identifiable information, a
                privacy policy in compliance with all applicable laws that clearly and
                thoroughly discloses all personal data processing and information collection, use
                and
                sharing practices. Also, obtain informed, unambiguous, specific and freely given
                consent from your end-users, if necessary, in accordance with applicable law, for
                the
                use of cookies and/or the collection and processing of their information;<br><br>
                5.1.10. always prominently post and make available to your end-users any terms and
                conditions in connection with the Offer set forth by Fleetwire.io or Client, or as
                required by applicable law regarding such Offers;<br><br>
                5.1.11. make sure to not place Fleetwire.io ads on any online auction platform
                (including, but not limited to, eBay,Amazon, etc.);<br><br>
                5.1.12. be transparent to Fleetwire.io in relation to your actions under the
                Affiliate
                Program and, if you are planning any action that does not fully comply with the
                usual
                business practice and/or you feel that your actions might violate this Agreement or
                applicable law, to consult your Affiliate account manager.<br></li>
              <li style="text-align:justify;"><strong>ADDITIONAL PROGRAM-SPECIFIC
                TERMS</strong><br><br><strong>The following additional program-specific terms shall
                apply to any promotional programs set forth below:</strong><br><br>
                6.1. <strong>Email Campaigns</strong>. All email campaigns shall be approved in
                advance and in writing by us.<br><br>
                6.2. <strong>Advertising Campaigns</strong>:
                <ol class="ml-10" style="list-style-type:lower-roman;">
                  <li>No Links can appear to be associated with or be positioned on chat rooms or
                    bulletin boards unless otherwise agreed by us in writing. Any pop-ups/unders are
                    forbidden.
                  </li>
                  <li>Advertising campaigns shall not violate any applicable national or
                    international
                    advertising and marketing laws, as well, they shall not violate the intellectual
                    property rights of third parties.
                  </li>
                  <li>You are solely responsible for confirming the legal requirements when
                    publishing
                    the advertisements.
                  </li>
                  <li>It is prohibited to use Banned keywords (as defined below in these Affiliate
                    Program Terms) and to make any bidding on any referencing service or search
                    engine
                    (such as Google AdWords), paid or otherwise, in any meta tags, advertising,
                    search
                    terms, code, or in a similar manner, for any goods or services, either similar
                    or
                    not to Fleetwire's products or services.
                  </li>
                  <li>We can never be held responsible for the content of commercials or the
                    context,
                    how and where,the commercials are used, released, made available, published, or
                    disseminated.
                  </li>
                </ol>
                <br>
                6.3. <strong>Affiliate Network Campaigns</strong>:

                <ol class="ml-10" style="list-style-type:lower-roman;">
                  <li>For all Affiliates that maintain their own affiliate networks, Affiliate
                    agrees
                    to place the Links in its affiliate network
                    (“<strong>Affiliate’sNetwork</strong>”)
                    for access and use by those affiliates in Affiliate's Network(each a “<strong>Third
                      Party Affiliate</strong>”). Affiliate agrees that it will expressly forbid any
                    Third Party Affiliate to modify the Links in any way.
                  </li>
                  <li>Affiliate agrees to maintain the Affiliate’s Network according to the highest
                    industry standards.Affiliate shall not permit any party whose website or
                    business
                    model involves content containingObjectionable Content to be a Third Party
                    Affiliate.
                  </li>
                  <li>All Third Party Affiliates must be in good standing with the Affiliate.
                    Affiliate must require and confirm that all Third Party Affiliates affirmatively
                    accept, through verifiable means, this Agreement prior to obtaining access to
                    the
                    Links.
                  </li>
                  <li>Affiliate shall disclose any and all Third Party Affiliates which access the
                    Affiliate Program and use the Links for generation of Commissions to Fleetwire.
                  </li>
                  <li>Affiliate shall promptly terminate any Third Party Affiliate who takes, or
                    could
                    reasonably be expected to take, any action that violates the terms and
                    conditions
                    of this Agreement. In the event that either party suspects any wrongdoing by a
                    Third Party Affiliate with respect to the Links and/or violation of this
                    Agreement
                    and/or applicable law in relation to this Agreement by a Third PartyAffiliate,
                    Affiliate shall promptly disclose to Fleetwire.io the identity and contact
                    information of such ThirdParty Affiliate.
                  </li>
                  <li>Affiliate shall promptly remove any Third Party Affiliate from the Affiliate
                    Program and terminate their access to future Offers of Fleetwire.io in the
                    Affiliate’s Network, including but not limited to, anyLinks, upon written notice
                    from Fleetwire.
                  </li>
                  <li>Unless Fleetwire.io has been provided with all truthful and complete contact
                    information of a Third PartyAffiliate and such Third Party Affiliate has
                    affirmatively accepted this Agreement as recorded byFleetwire.io (and,
                    therefore,
                    is liable directly to Fleetwire), Affiliate shall remain liable for all acts or
                    omissions(including, but not limited to, violations of this Agreement and/or
                    applicable law) by any Third PartyAffiliate.
                  </li>
                </ol>
                <br></li>
              <li style="text-align:justify;"><strong>ANTI-SPAM POLICY</strong><br><br>
                7.1. When participating in our Affiliate Program, you may not use for selling or
                promoting Fleetwire's products or services any spamming methods, including, but not
                limited
                to, the following:<br><br>
                7.1.1. <strong>Email SPAM</strong>: sending unsolicited marketing email. However,
                you
                may send emails to people who have given you consent to send them marketing emails
                for
                products and services similar to Fleetwire.io products. In any case, sending of
                emails
                shall comply with legal requirements of applicable laws (including, but not limited
                to,
                privacy laws).<br><br>
                7.1.2. <strong>Short texts SPAM</strong>: sending unsolicited short texts to the
                cell
                phones without the permission of the user of the cell phone and/or otherwise in
                violation of applicable laws (including but not limited to, U.S. TelephoneConsumers
                Protection Act, EU General Data Protection Regulation, etc.).<br><br>
                7.1.3. <strong>Comment SPAM</strong>: posting Links on blogs, message boards and
                forums in cases where the discussion or content is not related to the services we
                offer. However, you may advertise your Link on websites which are discussing our
                services or in a forum signature. You should never use automated bots to post
                comments
                containing your Affiliate Link.<br><br>
                7.1.4. <strong>Black Hat techniques</strong>: using “Black hat” SEO marketing on
                your
                website, such as keyword stuffing,invisible text or doorway pages; Incentives:
                offering incentives to users such as cash back or other rewards, except where
                facilitated by features on our websites.<br><br>
                7.2. In the event of a breach of the requirements of this Clause, Fleetwire.io
                reserves the right to terminate thisAgreement and/or place restrictions, suspend or
                delete your Affiliate account, including but not limited to,withholding all sums
                within your Affiliate account.<br></li>
              <li style="text-align:justify;"><strong>PROHIBITION OF FRAUD</strong><br><br>
                8.1. Credibility of the affiliates is of the essence to us. You are expressly
                prohibited from using any persons,means, devices or arrangements to commit fraud,
                violate any applicable law, interfere with other affiliates or falsify and/or hide
                information in connection with referrals through the Links or the generation
                ofCommissions, or exceed your permitted access to the Affiliate Program. Such acts
                include, but are in noway limited to, using automated means to increase the number
                of
                clicks through the Links or completion of any required information, hiding of
                traffic
                or making it otherwise unavailable to Fleetwire, using spyware, using stealware,
                cookie-stuffing and other deceptive acts or click-fraud. Such and similar acts shall
                constitute a material breach of this Agreement. We shall make all determinations
                about
                fraudulent activity in our sole discretion.<br><br>
                8.2. If we have reason to believe your Affiliate account is being used to conduct
                fraudulent activities and/or fraudulent transactions or is in any way associated
                with
                fraudulent activity, we may reject any payments of the Commissions to you (in full
                or
                in part), terminate this Agreement, suspend or delete your Affiliate account, and/or
                require you to return to us any Commissions paid by us to you in relation to
                your participation in the Affiliate Program.<br></li>
              <li style="text-align:justify;"><strong>LIMITED LICENSE &amp; INTELLECTUAL
                PROPERTY</strong><br><br>
                9.1. <strong>Licence.</strong> Provided that you comply with the terms of this
                Agreement and are a member in good standing of the Affiliate Program, only for the
                sole
                purpose of identifying your Media as a participant of the AffiliateProgram, and
                assisting in increasing sales of Fleetwire.io products or services through the
                Program
                Website,during the validity of the Agreement we grant you a limited, revocable,
                non-exclusive, non-transferable licence to:<br><br>
                9.1.1. reproduce and make available on your Media Fleetwire's intellectual property
                (as
                defined in General Terms)that was made available to you via the Affiliate Program,
                including, without limitation, the Links;<br><br>
                9.1.2. use Fleetwire's intellectual property that was made available to you via the
                Affiliate Program only for creating editorial content (illustrate an article, etc.)
                or
                graphical advertisements (banners, pop-up, etc.). Subject to Clause 9.3.1 below, you
                are not allowed to modify and/or create derivative works from Fleetwire's
                intellectual
                property if this has not been pre-approved in writing by us.<br><br>
                9.2. <strong>Revocation of Licence.</strong> We, at our sole discretion, have a
                right
                to revoke, partially or in whole, your license any time by giving you a written
                notice.<br><br>
                9.3. <strong>Restrictions on Licence.</strong> We do not transfer to you any rights,
                express or implied, in respect to Links,Program Website and/or any other Fleetwire's
                intellectual property, and we retain right, title, interest, ownership in and to
                Fleetwire's
                intellectual property. Fleetwire's intellectual property are licensed, not sold, and
                you
                receive no title to or ownership of any copy or of Fleetwire's intellectual property
                itself.
                Furthermore, you receive no rights toFleetwire's intellectual property other than
                those
                specifically granted in Section 9.1 above. Without limiting the generality of the
                foregoing, you are imperatively prohibited, and you shall not, among other
                things:<br><br>
                9.3.1. modify, create derivative works from, distribute, publicly display, publicly
                perform, sublicense, or otherwise use the Links or any other Fleetwire's
                intellectual
                property;<br>
                9.3.2. register and/or use the domain name with any suffix that consists of or
                contains any mark that is identical or similar to or misspellings, variations,
                modifications of Fleetwire's trademarks and/or any other Fleetwire’s
                intellectual property, for any goods or services, either similar or not to
                Fleetwire’s
                products or services<br>
                9.3.3. use and/or to make bidding for any mark and/or keyword that is identical or
                similar to or misspellings, variations, modifications of Fleetwire’s trademarks
                and/or
                any other
                Fleetwire’s intellectual property (“<strong>Banned Keywords</strong>”) on any
                referencing
                service or search engine (such as Google AdWords), paid or otherwise, in any meta
                tags,
                advertising, search terms, code, or in a similar manner, for any goods or services,
                either similar or not to Fleetwire’s products or services;<br>
                9.3.4. cause or create, or act in any way that causes or creates or could cause or
                create any initial interest confusion over the use of Fleetwire's intellectual
                property on
                the internet or in any search engine advertising;<br>
                9.3.5. take unfair advantage and/or benefit of the distinctive character or repute
                of
                Fleetwire.io trademarks and/or other Fleetwire's intellectual property (free-riding)
                and/or
                dilute, whittle away and blur the distinctive character of Fleetwire's intellectual
                property
                and/or tarnish the repute of Fleetwire's intellectual property.<br><br>
                9.4. All rights not expressly granted in this Agreement are reserved by
                Fleetwire.<br><br>
                9.5. You represent and warrant that you are fully aware that your use of Fleetwire's
                intellectual property to any other extent than as expressly permitted in Section 9.1
                above (in addition to being a breach of thisAgreement) shall constitute the
                infringement of our rights to Fleetwire's intellectual property, and therefore may
                be
                subject to claims for damages from you.<br><br>
                9.6. You hereby grant us a perpetual, irrevocable, worldwide license to use any
                Feedback (as defined below)you communicate to us during the validity of this
                Agreement, without compensation, without any obligation to report on such use, and
                without any other restriction. Our rights granted in the previous sentence include,
                without limitation, the right to exploit Feedback in any and every way, as well as
                the
                right to grant sub licenses. (“<strong>Feedback</strong>” refers to any suggestion,
                comment, recommendation or idea arising out or in connection with the performance of
                this Agreement, including without limitation all intellectual property rights in any
                such suggestion, comment, recommendation or idea.)<br></li>
              <li style="text-align:justify;"><strong>VALIDITY AND TERMINATION</strong><br><br>
                10.1. This Agreement comes into force upon our approval of your Affiliate Program
                application and will be valid until terminated by either you or us.<br><br>
                10.2. Either you or we may terminate this Agreement at any time, without cause, by
                giving the other party 3(three) days’ prior written notice of termination.
                Fleetwire.io may, without limitation and free from any and all liability, terminate
                the
                Agreement with Cause immediately at any time if you are in a material breach of your
                obligations hereunder.<br><br>
                10.3. “Cause” shall mean including, but without limitation:<br><br>
                10.3.1. The services provided by you (a) are not compliant, fully or in part, with
                the
                Agreement; (b) are not compliant, fully or in part, with established business
                practice,
                professional standards, industry codes; (c)violate any applicable law, regulation,
                judicial or administrative action, or rights of a third parties; (d) are defamatory,
                obscene, harassing, tortuous, vulgar, invasive of another’s privacy, hateful, or
                racially,ethnically or otherwise objectionable; (d) damage the reputation, brand,
                image, likeness, expression in the public domain of Fleetwire, its representatives,
                affiliates, officers, directors, employees, agents, clients,service providers and
                all
                other related third parties;<br>
                10.3.2. you breach the General Warranties and Representations as specified in the
                General Terms and/provisions of these Affiliate Program Terms;<br>
                10.3.3. we believe that we may face potential claims or liability in connection with
                your participation in theAffiliate program;<br>
                10.3.4. we believe that our brand or reputation may be tarnished by you or in
                connection with your participation in the Affiliate Program.<br><br>
                10.4. Upon the termination of this Agreement for any reason, you shall immediately
                cease to: (i) use the Links,remove them from your Media and delete them; (ii) use
                all
                Fleetwire's intellectual property and Client’s intellectual property, and (iii)
                represent
                yourself as a Fleetwire.io Affiliate.<br><br>
                10.5. Upon termination of your participation in the Affiliate Program for one or
                more
                Offers or termination of thisAgreement for any reason, all rights to validly accrued
                payments, causes of action and any provisions,which by their terms are intended to
                survive termination, shall survive any termination.<br><br>
                10.6. We may hold accrued unpaid Commissions for a reasonable period of time
                following
                the termination of this Agreement to ensure that the correct amount of Commissions
                is
                paid (for example, to account for any chargebacks, refunds, cancellations or
                returns).<br></li>
              <li style="text-align:justify;"><strong>REMEDIES</strong><br><br>
                11.1. In addition to any other rights and remedies available to us under this
                Agreement, in law or in equity, were serve the right to (i) terminate the Agreement
                and/or suspend your Affiliate account or delete it, (ii)delete any actions submitted
                through your Links, and (iii) suspend and/or withhold any unpaidCommissions, and/or
                charge back paid Commissions to your account immediately upon written notice to you
                if:<br><br>
                11.1.1. we determine that you have violated this Agreement;<br>
                11.1.2. we receive any complaints about your participation in the Affiliate Program
                which we reasonably believe to violate this Agreement; or<br>
                11.1.3. any Qualified Action is later determined to have not met the requirements
                set
                forth in this Agreement or on the Affiliate Program.<br><br>
                11.2. Such suspending and/or withholding of the Commissions, or charge backs for
                paid
                Commissions, shall be without regard as to whether or not such Commissions were
                earned
                as a result of such violation.<br></li>
              <li style="text-align:justify;"><strong>MODIFICATIONS TO THIS
                AGREEMENT</strong><br><br>
                12.1. Fleetwire.io reserves the right to modify any of these terms and conditions of
                this Agreement at any time inFleetwire's sole discretion. Such modifications shall
                take
                effect upon posting to the Fleetwire's website. Fleetwire, in its sole discretion,
                reserves
                the
                right to notify you by e-mail and further reserves the right to withhold
                notification
                of any changes made to this Agreement.<br><br>
                12.2. Modifications may include, but are not limited to, changes in the scope of
                available Commissions,Commission amounts/percentages, payment procedures, Commission
                fee payment schedules, andAffiliate Program rules.<br><br>
                12.3. If any modification is unacceptable to you, your only recourse is to terminate
                this Agreement. Your continued participation in the Affiliate Program following our
                posting of a change notice or new agreement on our website will constitute binding
                acceptance of the change.<br></li>
              <li style="text-align:justify;"><strong>INDEPENDENT REVIEW AND
                INVESTIGATION</strong><br><br>
                13.1. Your submission of the application for the Affiliate Program acknowledges that
                you understand and have read this Agreement (including the General Terms) in full,
                and
                agree to be bound by all the terms and conditions of this Agreement. You understand
                that other Affiliates may have a different relationship with Fleetwire.io than you,
                including competitors.<br><br>
                13.2. You acknowledge that you have independently reviewed and assessed the
                advantages
                and disadvantages of participating in this Affiliate Program, and you are not
                relying
                on any guarantees,representations, or statements other than this Agreement as set
                forth herein.
              </li>
            </ol>
            <p style="text-align:justify;">I, AS A POTENTIAL AFFILIATE, INDICATE MY ASSENT TO THIS
              AGREEMENT AND MY DESIRE TO BECOME AN AFFILIATE HEREIN BOUND BY THE AFOREMENTIONED
              TERMS
              AND CONDITIONS, BY COMPLETING AND SUBMITTING THE AFFILIATE PROGRAM APPLICATION FORM,
              BY
              SUBMITTING PROPOSED REFERRED CUSTOMERS AND/OR INFORMATION REGARDING THE QUALIFYING
              ACTIONS TO FLEETWIRE.IO UNDER THIS AFFILIATE PROGRAM AND/OR BY COLLECTING ANY
              COMMISSION
              PAYMENTS FROM FLEETWIRE.</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="agreeToSDialog = false;">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts" setup>
import {onMounted, ref} from 'vue';
import {EventBus} from '@/plugins/eventBus';


const agreeToSDialog = ref(false);

onMounted(() => {
  EventBus.$on('open-affiliate-terms-dialog', () => {
    agreeToSDialog.value = true;
  });
});
</script>

