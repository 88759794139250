import {useStore} from 'vuex';
import {computed} from 'vue';
import {TaxCategory} from "@/types";

export function useTaxAndFee() {
    const store = useStore();


    const taxCategories = computed(() => store.getters['taxes/taxCategories']);
    const taxForm = computed(() => store.getters['taxes/taxForm']);
    const taxCategoryLoading = computed(() => store.getters['taxes/loading']);

    const resetTaxFormErrors = () => {
        store.commit('taxes/CLEAR_TAX_CATEGORY_FORM_ERRORS');
    }

    const setTaxCategory = (taxCategories: TaxCategory[]) => {
        store.commit('taxes/SET_TAX_CATEGORIES', taxCategories);
    }
    const saveTaxCategory = (taxCategory: TaxCategory) => {
        return store.dispatch('taxes/saveTaxCategory', taxCategory);
    }
    const deleteTaxCategory = (taxCategory: TaxCategory) => {
        return store.dispatch('taxes/deleteTaxCategory', taxCategory);
    }
    const updateTaxCategory = (taxCategory: TaxCategory) => {
        return store.dispatch('taxes/updateTaxCategory', taxCategory);
    }
    const taxCategorySetDefault = (taxCategory: TaxCategory) => {
        return store.dispatch('taxes/taxCategorySetDefault', taxCategory);
    }

    const getTaxCategories = () => {
        return store.dispatch('taxes/getTaxCategories');
    }

    return {
        taxCategories,
        taxForm,
        taxCategoryLoading,
        setTaxCategory,
        resetTaxFormErrors,
        saveTaxCategory,
        deleteTaxCategory,
        updateTaxCategory,
        taxCategorySetDefault,
        getTaxCategories,
    }
}