<template>
  <div>
    <v-card variant="outlined">
      <v-card-title>
        <v-btn class="mr-2" variant="text" @click="$router.push({ name: 'settings.pricing.advanced' }).catch(() => {});">
          <v-icon size="small">mdi-arrow-left</v-icon>
        </v-btn>
        Price Rules
        <v-spacer/>
      </v-card-title>

      <v-card-text class="mt-3">
        <div v-if="ruleClone">
          <div class="col-6">
            <v-select
              v-model="rule"
              :items="rules"
              density="compact"
              item-title="label"
              item-value="value"
              label="Rule"
              variant="outlined"
            ></v-select>

            <v-text-field
              v-model="ruleClone.name"
              density="compact"
              label="Name"
              variant="outlined"
            ></v-text-field>

            <div v-if="rule === 'fill-the-gap'" class="mt-1">

              <p class="mt-2">Fill the gap helps you fill in the time between reservations that may incentivize renters
                to book odd or shorter times.</p>

              <div class="text-2xl border-top">
                <v-menu open-on-hover>
                  <template v-slot:activator="{ props }">
                    <v-btn
                      style="padding-left: 0; padding-right:0;"
                      v-bind="props"
                      variant="text"
                    >
                  <span class="i-in-place-edit">
                    {{ ruleClone.rules.action }}
                  </span>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item v-model="ruleClone.rules.action">
                      <v-list-item value="increase">
                        <v-list-item-title>Increase</v-list-item-title>
                      </v-list-item>
                      <v-list-item value="decrease">
                        <v-list-item-title>Decrease</v-list-item-title>
                      </v-list-item>
                    </v-list-item>
                  </v-list>
                </v-menu>
                listing prices by
                <v-menu :close-on-content-click="false" open-on-hover>
                  <template v-slot:activator="{ props }">
                    <v-btn
                      style="padding-left: 0; padding-right:0;"
                      v-bind="props"
                      variant="text"
                    >
                  <span class="i-in-place-edit">
                    <span v-if="ruleClone.rules.mode ==='amount'">$</span>
                    {{ ruleClone.rules.value }}
                    <span v-if="ruleClone.rules.mode ==='percent'">%</span>
                  </span>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-text>
                      <v-text-field
                        v-model="ruleClone.rules.value"
                        label="Amount"
                        max="365"
                        min="0"
                        type="number"
                        variant="outlined"
                      ></v-text-field>
                      <v-radio-group v-model="ruleClone.rules.mode" inline>
                        <v-radio label="Percent" value="percent"></v-radio>
                        <v-radio label="Amount" value="amount"></v-radio>
                      </v-radio-group>
                    </v-card-text>
                  </v-card>
                </v-menu>
                between reservations
                <v-menu :close-on-content-click="false" open-on-hover>
                  <template v-slot:activator="{ props}">
                    <v-btn
                      style="padding-left: 0; padding-right:0;"
                      v-bind="props"
                      variant="text"
                    >
                      <span class="i-in-place-edit">
                        {{ ruleClone.rules.time_value }}
                        <span>Days</span>
                      </span>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-text>
                      <v-text-field
                        v-model="ruleClone.rules.time_value"
                        label="Amount"
                        max="365"
                        min="0"
                        type="number"
                        variant="outlined"
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                </v-menu>
                out.

                <v-expansion-panels class="mt-3">
                  <v-expansion-panel>
                    <v-expansion-panel-title v-slot="{ open }">
                      <div>
                        <div class="col-4">Exclusions</div>
                        <div class="col-8">
                          <v-fade-transition leave-absolute>
                            <span v-if="open">Add exclusion rules</span>
                            <span v-else class="float-end">{{ exclusionCount }} exclusion</span>
                          </v-fade-transition>
                        </div>
                      </div>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <div :class="{ '': ruleExclusionsGapMax}">
                        <v-checkbox v-model="ruleExclusionsGapMax" density="compact" hide-details>
                          <template v-slot:label>
                            <div class="flex">
                              Only when any two reservations are
                              {{ ruleExclusionsGapMaxValue }}
                              day apart or less
                              <tippy
                                allowHTML
                                arrow
                                interactive>
                                <template v-slot:trigger>
                                  <v-icon class=" ml-1" size="small">mdi-help-circle-outline</v-icon>
                                </template>
                                <span>
                                  This exclusion will only apply when the gap between two reservations is {{
                                    ruleExclusionsGapMaxValue
                                  }} day or less. This is useful for when you want to fill the gap between two reservations that are {{
                                    ruleExclusionsGapMaxValue
                                  }} day apart.
                                <TheHelpLink url="https://help.fleetwire.io/en/article/advanced-pricing-cxlndp/?bust=1666982473968"></TheHelpLink>
                              </span>
                              </tippy>
                            </div>
                          </template>
                        </v-checkbox>
                        <div v-if="ruleExclusionsGapMax" class="ml-10">
                          <v-text-field
                            v-model="ruleExclusionsGapMaxValue"
                            class="mt-1 mb-1"
                            density="compact"
                            hide-details
                            label="Max Gap"
                            max="365"
                            min="0"
                            style="width: 100px;"
                            type="number"
                            variant="outlined"
                          ></v-text-field>
                        </div>
                        <hr v-if="ruleExclusionsGapMax">
                      </div>

                      <v-checkbox
                        v-model="ruleClone.exclusions.weekends.on"
                        density="compact"
                        hide-details
                        label="Weekends"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="ruleClone.exclusions.holidays.on"
                        density="compact"
                        hide-details
                        label="Holidays"
                      ></v-checkbox>
                      <div>
                        <v-checkbox
                          v-model="ruleClone.exclusions.listings.on"
                          density="compact"
                          hide-details
                          label="These Listings"
                        ></v-checkbox>
                        <div v-if="ruleClone.exclusions.listings.on" class="mt-3 pl-10">
                          <ListingAutocompleteDropdown
                            :allow-multiple="true"
                            :allow-select-all="true"
                            :pre-selected="ruleClone.exclusions.listings.listings"
                            style="z-index: 1000"
                            @selectedListing="listingSelected($event)"
                          ></ListingAutocompleteDropdown>
                        </div>
                      </div>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <DeleteButton class="mr-2" @deleteConfirmed="deletePricingRule"/>
        <v-btn :loading="pricingRulesLoading.update" size="small" @click="savePricingRuleInit()">Save</v-btn>
      </v-card-actions>
    </v-card>

  </div>
</template>

<script lang="ts">
import {defineAsyncComponent} from 'vue';
import {app} from '@/app';
import {EventBus} from '@/plugins/eventBus';
import {mapActions, mapGetters} from 'vuex';
import {get} from 'lodash';

const DeleteButton = defineAsyncComponent(() => import('@/components/buttons/DeleteButton.vue'));
const ListingAutocompleteDropdown = () => import('@/components/dashboard/listings/ListingAutocompleteDropdown.vue');

export default {
  name: 'AdvancedPricingEdit',

  components: {
    DeleteButton,
    ListingAutocompleteDropdown,
  },

  data() {
    return {
      timer: 0,
      pricingRuleUuid: this.$route.params.uuid,
      EventBus,
      rule: null,
      rules: [
        {
          label: 'Raise/lower prices',
          value: 'raise-lower-prices',
          data: {
            action: 'raise',
            booking_cnt: 0,
          },
        },
        {
          label: 'Fill the Gap',
          value: 'fill-the-gap',
        },
      ],
      ruleClone: null,
    };
  },

  created() {
    if (this.pricingRules.length === 0) {
      this.getPricingRules();
    }

    EventBus.$on('create-pricing-rules-success', () => {
      this.getPricingRules();
    });
    EventBus.$on('delete-pricing-rules-success', () => {
      this.getPricingRules();
    });
  },

  watch: {
    pricingRules: {
      immediate: true,
      handler() {
        this.findPricingRule();
      },
    },
    currentPricingRule: {
      immediate: true,
      handler(rule) {
        this.rule = get(rule, 'rule_type', null);
        this.ruleClone = rule;
      },
    },
  },

  computed: {
    ...mapGetters({
      pricingRules: 'pricing/pricingRules',
      pricingRulesLoading: 'pricing/loading',
    }),
    ruleExclusionsGapMax: {
      get() {
        return get(this.ruleClone, 'exclusions.gap_max.on', false);
      },
      set(value) {
        if (!get(this.ruleClone, 'exclusions.gap_max')) {
          app.set(this.ruleClone.exclusions, 'gap_max', {on: false, value: 1});
        }
        app.set(this.ruleClone.exclusions.gap_max, 'on', value);
      },
    },
    ruleExclusionsGapMaxValue: {
      get() {
        return get(this.ruleClone, 'exclusions.gap_max.value', 1);
      },
      set(value) {
        if (!get(this.ruleClone, 'exclusions.gap_max')) {
          app.set(this.ruleClone.exclusions, 'gap_max', {on: false, value: 1});
        }
        app.set(this.ruleClone.exclusions.gap_max, 'value', +value);
      },
    },
    currentPricingRule() {
      return this.pricingRules.find((rule) => rule.uuid === this.pricingRuleUuid);
    },
    exclusionCount() {
      if (!this.ruleClone) {
        return 0;
      }
      return Object.values(this.ruleClone.exclusions).filter((v) => v.on).length;
    },
  },

  methods: {
    ...mapActions({
      getPricingRules: 'pricing/getPricingRules',
      deletePricingRules: 'pricing/deletePricingRules',
      savePricingRule: 'pricing/savePricingRule',
    }),
    listingSelected(listings) {
      if (!this.ruleClone.exclusions.listings.listings) {
        app.set(this.ruleClone.exclusions.listings, 'listings', []);
      }
      this.ruleClone.exclusions.listings.listings = listings.map((l) => {
        if (typeof l === 'string') {
          return l;
        }
        return l.l_id;
      });
    },
    savePricingRuleInit() {
      this.savePricingRule({
        uuid: this.pricingRuleUuid,
        data: this.ruleClone,
        rule: this.ruleClone.rule_type,
      });
    },
    findPricingRule() {
      return this.pricingRules.find((pricingRule) => pricingRule.uuid === this.pricingRuleUuid);
    },
    firstPartOfUuid(uuid) {
      return uuid.split('-')[0];
    },
    ruleType(rule) {
      const type = get(rule, 'rule_type', 'N/A');
      switch (type) {
        case 'fill-the-gap':
          return 'Fill the gap';
        case 'raise-lower-prices':
          return 'Raise/Lower prices';
        default:
          return 'Unknown';
      }
    },
    deletePricingRule() {
      this.deletePricingRules([rule.uuid])
        .finally(() => {
          this.$router.push({name: 'settings.pricing.advanced'}).catch();
        });
    },
  },

  beforeUnmount() {
    this.rule = null;
    this.ruleClone = null;

    EventBus.$off('create-pricing-rules-success');
    EventBus.$off('delete-pricing-rules-success');
  },
};
</script>
