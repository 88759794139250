import axios from 'axios';
import {EventBus} from '@/plugins/eventBus';
import {get} from 'lodash';
import * as types from '../mutation-types';
import {performApiRequest} from "@/store/helpers/vuex-helpers";


type Webhook = {
    created_at: string,
    description: string,
    livemode: boolean,
    metadata: null,
    status: string,
    type: null,
    updated_at: string,
    url: string,
    uuid: string,
}

export type WebhookEndpoint = {
    url: string,
    description: string,
    events: Array<string>,
}

export interface WebhookState {
    loaded: boolean;
    loading: Record<string, boolean>;
    webhooks: Webhook[]
    webhookEndpoints: WebhookEndpoint[],
    webhookConversations: Array<any>,
    webhook: Webhook,
    webhookEndpoint: WebhookEndpoint,
    webhookEndpointSecret: String,
}

const state: WebhookState = {
    loaded: false,
    loading: {
        webhooks: false,
        webhooksUpdate: false,
        webhooksDelete: false,
        webhookConversationResend: false,
        secret: false,
    },
    webhooks: [],
    webhook: null,
    webhookEndpoints: [],
    webhookEndpoint: null,
    webhookConversations: [],
    webhookEndpointSecret: '',
};


const getters = {
    loaded: (state: WebhookState) => state.loaded,
    loading: (state: WebhookState) => state.loading,
    webhooks: (state: WebhookState) => state.webhooks,
    webhook: (state: WebhookState) => state.webhook,
    webhookEndpoint: (state: WebhookState) => state.webhookEndpoint,
    webhookEndpoints: (state: WebhookState) => state.webhookEndpoints,
    webhookConversations: (state: WebhookState) => state.webhookConversations,
    webhookEndpointSecret: (state: WebhookState) => state.webhookEndpointSecret,
};


const mutations = {
    [types.SET_WEBHOOKS_LOADING](state: WebhookState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },

    [types.SET_WEBHOOK_ENDPOINTS](state: WebhookState, webhookEndpoints: WebhookEndpoint[]) {
        state.webhookEndpoints = webhookEndpoints;
    },
    [types.SET_WEBHOOK_ENDPOINT](state: WebhookState, webhookEndpoint: WebhookEndpoint) {
        state.webhookEndpoint = webhookEndpoint;
    },

    [types.SET_WEBHOOKS_CONVERSATIONS](state: WebhookState, conversations: Array<any>) {
        state.webhookConversations = conversations;
    },
    [types.SET_WEBHOOK_ENDPOINT_SECRET](state: WebhookState, secret: string) {
        state.webhookEndpointSecret = secret;
    },
};

const actions = {

    /**
     * Get webhook endpoints
     */
    getWebhookEndpoints({commit}) {
        commit('SET_WEBHOOKS_LOADING', {subject: 'webhooks', loading: true});

        axios.get('/v1/webhook-endpoints')
            .then((response) => {
                commit('SET_WEBHOOK_ENDPOINTS', get(response, 'data.webhookEndpoints'));
            }).catch((e) => {
            console.dir(e);
        }).finally(() => {
            commit('SET_WEBHOOKS_LOADING', {subject: 'webhooks', loading: false});
        });
    },


    /**
     * Get webhook endpoint
     */
    getWebhookEndpoint({commit}, webhook_uuid: string) {
        commit('SET_WEBHOOKS_LOADING', {subject: 'webhooks', loading: true});

        axios.get(`/v1/webhook-endpoints/${webhook_uuid}`)
            .then((response) => {
                commit('SET_WEBHOOK_ENDPOINT', get(response, 'data.webhookEndpoint'));
            }).catch((e) => {
            console.dir(e);
        }).finally(() => {
            commit('SET_WEBHOOKS_LOADING', {subject: 'webhooks', loading: false});
        });
    },


    /**
     * Add webhook
     */
    async addWebhookEndpoint({commit}, data: WebhookEndpoint) {
        return await performApiRequest(commit, 'SET_WEBHOOKS_LOADING', 'webhooks', () => axios.post('/v1/webhook-endpoints', data));
    },


    /**
     * Update webhook
     */
    updateWebhookEndpoint({commit}, webhook: Webhook) {
        commit('SET_WEBHOOKS_LOADING', {subject: 'webhooksUpdate', loading: true});

        axios.put(`/v1/webhook-endpoints/${webhook.uuid}`, webhook)
            .then(() => {
                EventBus.$emit('update-webhook-endpoint-success');
            }).catch((e) => {
            console.dir(e);
        }).finally(() => {
            commit('SET_WEBHOOKS_LOADING', {subject: 'webhooksUpdate', loading: false});
        });
    },

    /**
     * Get webhook conversations
     */
    getWebhookConversations({commit}, uuid: string) {
        commit('SET_WEBHOOKS_LOADING', {subject: 'webhooks', loading: true});

        axios.get(`/v1/webhook-endpoints/${uuid}/conversations`)
            .then((response) => {
                commit('SET_WEBHOOKS_CONVERSATIONS', get(response, 'data.conversations'));
            }).catch((e) => {
            console.dir(e);
        }).finally(() => {
            commit('SET_WEBHOOKS_LOADING', {subject: 'webhooks', loading: false});
        });
    },


    /**
     * Resend webhook conversations
     */
    resendWebhookConversation({commit}, conversation: any) {
        commit('SET_WEBHOOKS_LOADING', {subject: 'webhookConversationResend', loading: true});

        axios.post(`/v1/webhooks-conversations/${conversation.uuid}/retry`)
            .then(() => {
            }).catch((e) => {
            console.dir(e);
        }).finally(() => {
            commit('SET_WEBHOOKS_LOADING', {subject: 'webhookConversationResend', loading: false});
        });
    },


    /**
     * Delete webhook endpoint
     */
    deleteWebhookEndpoint({commit}, webhook: Webhook) {
        commit('SET_WEBHOOKS_LOADING', {subject: 'webhooksDelete', loading: true});

        axios.delete(`/v1/webhook-endpoints/${webhook.uuid}`)
            .then(() => {
                commit('SET_WEBHOOKS_CONVERSATIONS', []);
                EventBus.$emit('delete-webhook-endpoint-success');
            }).catch((e) => {
            console.dir(e);
        }).finally(() => {
            commit('SET_WEBHOOKS_LOADING', {subject: 'webhooksDelete', loading: false});
        });
    },


    /**
     * Fetch webhook endpoints signing secret
     */
    async getWebhookEndpointSecret({commit}, uuid: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_WEBHOOKS_LOADING', 'secret', () => axios.get(`/v1/webhook-endpoints/${uuid}/secret`));
            commit('SET_WEBHOOK_ENDPOINT_SECRET', responseData.secret);
        } catch (error) {
            throw error;
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
