<template>
  <v-card class="mt-5">
    <v-card-text>
      <div class="">
        <v-data-table-server
          v-model:options="dataTableOptions"
          :headers="envelopesHeaders"
          :items="envelopes"
          :items-length="envelopesTotal"
          :items-per-page="dataTableOptions.itemsPerPage"
          :items-per-page-options="itemsPerPageOptions"
          :items-per-page-text="'Contracts per page'"
          :loading="envelopeLoading.envelopes"
          :mobile-breakpoint="0"
        >
          <template v-slot:body="props">
            <tr v-for="(item, index) in props.items" :key="index" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 border-gray-200">
              <th class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" scope="row">
                <div v-for="(recipient, key) in recipients(item)" :key="key" class="content-center">
                  <router-link v-if="renterId(recipient)" :to="{name: 'customers.single', params: { r_id: renterId(recipient)}}">
                    {{ recipient.name }}
                  </router-link>
                </div>
              </th>
              <td>
                <div class="content-center">
                  <v-chip :color="envelopeStatusText(item).color" class="text-white" size="x-small">
                    {{ envelopeStatusText(item).text }}
                  </v-chip>
                  <div v-if="item.completed_at">{{ formatRentalDate(item.completed_at) }}</div>
                </div>
              </td>
              <td>
                <div class="content-center">
                  <v-btn v-if="item.file_path" v-tippy :href="item.file_path" content="Open in new window" size="small" target="_blank" variant="text">
                    <v-icon size="small">mdi-file-outline</v-icon>
                  </v-btn>
                  <v-btn v-else v-tippy :href="item.pdf_url" content="Incomplete - Open in new window" size="small" target="_blank" variant="text">
                    <v-icon size="small">mdi-file-outline</v-icon>
                  </v-btn>
                </div>
              </td>
              <td>
                <div class="content-center">
                  <span v-if="item.attachments.length === 0"></span>
                  <v-menu
                    v-else
                    v-model="item.attachmentsMenu"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        variant="text"
                      >
                        {{ item.attachments.length }}
                      </v-btn>
                    </template>

                    <v-card max-width="300">
                      <v-list density="compact">
                        <template v-for="attachment in item.attachments" :key="attachment.uuid">
                          <v-list-item v-if="attachmentImage(attachment)" tile>
                            <img :src="attachmentImage(attachment)" alt="Attachment Image" class="border">
                          </v-list-item>
                          <v-list-item v-else tile>
                            <template v-slot:prepend>
                              <v-avatar
                                color="primary"
                                size="25"
                              >
                                <v-icon size="sma">
                                  mdi-file-outline
                                </v-icon>
                              </v-avatar>
                            </template>

                            <template v-slot:append>
                              <v-btn :href="attachment.url" target="_blank" variant="text">
                                <v-icon size="small">mdi-open-in-new</v-icon>
                              </v-btn>
                            </template>

                            <template v-slot:subtitle>
                              {{ attachment.attachment_id }}
                            </template>

                            <template v-slot:title>
                              {{ attachment.name }}
                            </template>
                          </v-list-item>


                        </template>
                      </v-list>
                    </v-card>
                  </v-menu>
                </div>
              </td>
              <td>
                <div class="content-center">
                  <span v-if="item.created_at">{{ formatRentalDate(item.created_at) }}</span>
                </div>
              </td>
              <td>
                <div class="content-center">
                  <v-menu>
                    <template v-slot:activator="{ props }">
                      <v-btn
                        size="small"
                        v-bind="props"
                        variant="text"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-if="item.file_path" @click="openInNewTab(item.file_path)">
                        <v-list-item-title>
                          <v-icon class="mr-1" size="small">mdi-download</v-icon>
                          Download PDF
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item v-else @click="openInNewTab(item.pdf_url)">
                        <v-list-item-title>
                          <v-icon class="mr-1" size="small">mdi-download</v-icon>
                          Download Template Used
                        </v-list-item-title>
                      </v-list-item>
                      <!--                      <v-list-item>-->
                      <!--                        <v-list-item-title>-->
                      <!--                          <v-icon size="small" class="mr-1">mdi-eye</v-icon>-->
                      <!--                          Preview-->
                      <!--                        </v-list-item-title>-->
                      <!--                      </v-list-item>-->
                      <v-list-item :disabled="!hasPendingSigners(item) || envelopeVoided(item)" @click="openReminderDialog(item)">
                        <v-list-item-title>
                          <v-icon class="mr-1" size="small">mdi-email-outline</v-icon>
                          Send Reminder
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item :disabled="envelopeVoided(item)" @click="openVoidEnvelopeDialog(item)">
                        <v-list-item-title>
                          <v-icon class="mr-1" size="small">mdi-close-circle-outline</v-icon>
                          Void
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="openEnvelopInfoDialog(item)">
                        <v-list-item-title>
                          <v-icon class="mr-1" size="small">mdi-information-outline</v-icon>
                          View Info
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table-server>
      </div>
    </v-card-text>
  </v-card>

  <v-dialog v-model="voidEnvelopeDialog" max-width="400">
    <v-card :loading="envelopeLoading.voiding">
      <v-card-title class="text-h5">Are you sure?</v-card-title>
      <v-divider class="opacity-50"></v-divider>
      <v-card-text>
        Are you sure you want to void this envelope?

        <v-alert v-if="envelopeForm.errors.any()" class="mt-5" color="red" density="compact">
          <strong>There was an error:</strong>
          {{ envelopeForm.errors.flatten()[0] }}
        </v-alert>
      </v-card-text>

      <v-divider class="opacity-50"></v-divider>

      <v-card-actions>
        <v-spacer/>

        <v-btn :disabled="envelopeLoading.voiding" color="grey" size="small" variant="text" @click="voidEnvelopeDialog = false; envelopeForm.errors.clear();">
          Nevermind
        </v-btn>

        <v-btn :disabled="envelopeLoading.voiding || envelopeForm.errors.any()" color="red text-white" @click="voidEnvelopeCommit(currentWorkingEnvelope)">
          Void
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="reminderDialog" aria-label="Send Reminder" max-width="600">
    <v-card :disabled="envelopeLoading.reminder" :loading="envelopeLoading.reminder">
      <v-card-title>Send Reminder</v-card-title>
      <v-divider class="opacity-50"/>
      <v-card-text>
        <div class="my-2">This is the name that will be used in the emails/sms sent to your guests.</div>
        <v-checkbox
          v-for="(reminder, index) in reminderData.recipients"
          :key="index"
          v-model="reminder.send"
          :label="reminder.label"
          hide-details
        >
        </v-checkbox>
      </v-card-text>
      <v-divider class="opacity-50"/>
      <v-card-actions>
        <v-spacer/>
        <v-btn :disabled="envelopeLoading.reminder" color="grey" size="small" variant="text" @click="reminderDialog = false">
          Nevermind
        </v-btn>
        <v-btn :disabled="sendReminderReady" :loading="envelopeLoading.reminder" color="primary text-white" @click="sendReminderInit()">
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="envelopInfoDialog" max-width="600">
    <v-card :disabled="documentsLoading.saving" :loading="documentsLoading.saving">
      <v-card-title>Info</v-card-title>
      <v-card-text>
        <div class="mb-5">
          <v-chip :color="envelopeStatusText(currentWorkingEnvelope).color" size="x-small">
            <span>{{ envelopeStatusText(currentWorkingEnvelope).text }}</span>
          </v-chip>
        </div>

        <div>
          <div v-for="recipient in currentWorkingEnvelopeRecipients" :key="recipient.uuid">
            <div class="flex">
              <div v-if="recipient.completed_at">
                <v-icon v-tippy :content="`Signed at ${recipient.completed_at}`" class="mr-1" size="small">mdi-check
                </v-icon>
              </div>
              <div v-else-if="recipient.opened_at">
                <v-icon v-tippy :content="`Opened at ${recipient.opened_at}`" class="mr-1" size="small">mdi-eye
                </v-icon>
              </div>
              <div v-else>
                <v-icon v-tippy :content="`Waiting`" class="mr-1" size="small">mdi-clock-outline</v-icon>
              </div>
              <div>
                {{ recipient.renter.full_name }}
                ({{ recipient.recipient }})
              </div>
            </div>
          </div>
        </div>

        <div>
          <div v-for="(signature, key) in envelopeRecipientSignatures(currentWorkingEnvelope)" :key="key">
            <div v-if="signature.signature" class="border rounded my-1 p-1">
              <v-menu
                v-if="signature.signature"
                v-model="signature.largerSignature"
                :close-on-content-click="false"
                open-on-hover
              >
                <template v-slot:activator="{ props }">
                  <v-img :src="signature.signature" height="50" v-bind="props" width="100"></v-img>
                  <small>{{ signature.name }}</small>
                </template>

                <v-card>
                  <v-card-text>
                    <v-img :src="signature.signature" height="200"></v-img>
                    <small>{{ signature.name }}</small>
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn :disabled="documentsLoading.saving" color="grey" size="small" variant="text" @click="envelopInfoDialog = false">
          Okay
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import {computed, ref, watch} from 'vue';
import {useEnvelope} from "@/composables/useEnvelope";
import {useStore} from 'vuex';
import {useDateFormatter} from "@/composables/useDate";
import {EnvelopeInterface} from "@/store/modules/envelope";
import {RenterInterface} from "@/types";

const store = useStore();
const {
  envelopeForm,
  envelopeLoading,
  envelopes,
  envelopesQuery,
  envelopesPagination,
  sendEnvelopeReminder,
  voidEnvelope,
  getEnvelopes,
  updateCustomerQuery,
} = useEnvelope();
const {formatRentalDate} = useDateFormatter();

const documentsLoading = computed(() => store.getters['document/loading']);

const envelopesTotal = computed(() => envelopesPagination.value.total);

const currentWorkingEnvelope = ref<EnvelopeInterface>(null);
const envelopInfoDialog = ref(false);
const voidEnvelopeDialog = ref(false);
const reminderDialog = ref(false);

const currentWorkingEnvelopeRecipients = computed(() => {
  return currentWorkingEnvelope.value.recipients;
});

const dataTableOptions = ref({
  page: 1,
  itemsPerPage: 25,
  sortBy: ['created_at'],
  sortDesc: [true],
  status: 'all',
});
const envelopeVoided = (envelope: EnvelopeInterface) => {
  return envelope.status === 'voided';
};

const sendReminderReady = computed(() => {
  return reminderData.value.recipients.length === 0 || reminderData.value.recipients.every(r => !r.send);
});

const itemsPerPageOptions = [
  {value: 10, title: '10'},
  {value: 25, title: '25'},
  {value: 50, title: '50'},
  {value: 100, title: '100'},
  {value: -1, title: '$vuetify.dataFooter.itemsPerPageAll'}
];

const envelopesHeaders = ref([
  {title: 'Customers', key: 'renter', sortable: false},
  {title: 'Status', key: 'status', sortable: false},
  // { text: 'Opened', value: 'opened', width: 100 },
  // { text: 'Completed', value: 'completed_at', width: 200 },
  // { text: 'Signatures', value: 'signature' },
  {title: 'Files', key: 'pdf_url', sortable: false},
  {title: 'Attachments', key: 'attachments', width: 125, sortable: false},
  {title: 'Created', key: 'created_at', width: 200},
  {title: '', sortable: false, key: 'uuid'},
]);

const reminderData = ref({
  recipients: [],
});

watch(() => dataTableOptions.value.page, (newPage) => {
  updateCustomerQuery({key: 'page', value: newPage});
  getEnvelopes();
});

watch(() => dataTableOptions.value.itemsPerPage, (newItemsPerPage) => {
  updateCustomerQuery({key: 'itemsPerPage', value: newItemsPerPage});
  getEnvelopes();
});

watch(() => dataTableOptions.value.sortBy, (newSort) => {
  updateCustomerQuery({key: 'sortBy', value: [newSort?.[0]?.['key']]});
  updateCustomerQuery({key: 'sortDesc', value: [newSort?.[0]?.['order'] === 'desc']});
  getEnvelopes();
});

watch(dataTableOptions, (val) => {
  // if there is no status filter, then we need to reset the page to 1
  if (!val.status) {
    dataTableOptions.value.status = 'all';
  }
});

const recipients = (envelope: EnvelopeInterface) => {
  // Check if there are no recipients in the envelope
  if (!envelope.recipients?.length) {
    return [{
      name: renterFullName(envelope.renter),
      r_id: envelope.renter?.r_id,
    }];
  }

  // Process each recipient to extract their details
  return envelope.recipients.map(recipient => ({
    name: renterFullName(recipient.renter),
    r_id: recipient.renter?.r_id,
  }));
};

const renterFullName = (renter: RenterInterface) => {
  const name = renter?.full_name;
  if (name) {
    return name;
  }
  return `${renter?.first_name} ${renter?.last_name}`.trim();
};

const renterId = (renter: { name: string, r_id: string }) => {
  return renter.r_id;
};


const envelopeStatusText = (envelope: EnvelopeInterface) => {
  if (envelope.status === 'init') return {text: "Init", color: "purple"};
  if (envelope.status === 'voided') return {text: "Voided", color: "red"};
  if (envelope.status === 'in_progress') return {text: "In Progress", color: "blue", textColor: "black"};
  if (envelope.status === 'expired') return {text: "Expired", color: "red"};
  if (envelope.status === 'pending') return {text: "Expired", color: "blue"};
  if (envelope.recipients?.some(r => !r.completed_at)) return {text: "Pending signature", color: "orange"};
  if (envelope.completed_at) return {text: "Completed", color: "green"};
  return {text: envelope.status, color: "gray"};
};

const envelopeRecipientSignatures = (envelope: EnvelopeInterface) => {
  // Check if there are no recipients in the envelope
  if (!envelope.recipients?.length) {
    return [{
      signature: envelope.signature,
      recipient: envelope.renter?.full_name ?? 'Unknown Renter',
      name: envelope.renter?.full_name ?? 'Unknown Renter',
    }];
  }

  // Process each recipient to extract signature information
  return envelope.recipients.map(recipient => ({
    signature: recipient.signature,
    recipient: recipient.recipient,
    name: recipient.renter?.full_name ?? 'Unknown Renter',
  }));
};

const attachmentImage = (attachment) => {
  return attachment?.document_images?.[0];
};

const openInNewTab = (url) => {
  window.open(url, '_blank', 'noreferrer');
};

const hasPendingSigners = (envelope: EnvelopeInterface) => {
  return pendingSigners(envelope).length > 0;
};

const pendingSigners = (envelope: EnvelopeInterface) => {
  return envelope.recipients.filter((r) => !r.completed_at);
};
const openEnvelopInfoDialog = (envelope: EnvelopeInterface) => {
  currentWorkingEnvelope.value = envelope;
  envelopInfoDialog.value = true;
};

const openVoidEnvelopeDialog = (envelope: EnvelopeInterface) => {
  currentWorkingEnvelope.value = envelope;
  voidEnvelopeDialog.value = true;
};


const openReminderDialog = (envelope: EnvelopeInterface) => {
  currentWorkingEnvelope.value = envelope;

  const pendingSignersData = pendingSigners(envelope);

  if (pendingSignersData.length === 0) {
    return alert("No pending signers for this envelope!");
  }

  reminderData.value.recipients = pendingSignersData.map(signer => ({
    email: signer.recipient,
    label: `${signer.renter?.full_name ?? 'Unknown Name'} (${signer.recipient})`,
    send: true,
  }));

  reminderDialog.value = true;
};

const voidEnvelopeCommit = async (envelope: EnvelopeInterface) => {
  await voidEnvelope(envelope);
  voidEnvelopeDialog.value = false;
  currentWorkingEnvelope.value = null;

  // Wait before fetching new data
  setTimeout(() => getEnvelopes(), 300);
};

const sendReminderInit = () => {
  reminderData.value.recipients.forEach((r) => {
    if (r.send) {
      sendEnvelopeReminder({
        envelope: currentWorkingEnvelope.value,
        email: r.email,
      });
    }
  });
};
</script>
