import {EventBus} from '@/plugins/eventBus';
import axios from 'axios';
import * as types from '@/store/mutation-types';
import {CartService} from '@/services/cart.service';
import {createUrlQueryString} from '@/utils/urls';
import {performApiRequest} from "@/store/helpers/vuex-helpers";


interface Barcode {
    barcode_type: string;
    created_at?: string;
    image_url: string;
    number: string;
    owner_id: string;
    owner_type: string;
    updated_at?: string;
    uuid?: string;
}


export interface BarcodeState {
    loading: Record<string, boolean>;
    barcode: Barcode;
}

// state
const state: BarcodeState = {
    loading: {
        fetchCart: false,
        delete: false,
        create: false,
    },
    barcode: null,
};

const getters = {
    loading: (state: BarcodeState) => state.loading,
    barcode: (state: BarcodeState) => state.barcode,
};

const mutations = {
    [types.SET_BARCODE_LOADING](state: BarcodeState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_BARCODE](state: BarcodeState, barcode: Barcode) {
        state.barcode = barcode;
    },
};

const actions = {

    async findBarcode({commit}, payload: Record<string, string>) {
        const queryString = createUrlQueryString(payload);

        return axios.get(`/v1/barcodes${queryString}`)
            .then((response) => {
                commit(types.SET_BARCODE, response.data.barcode);
                EventBus.$emit('get-barcode-success', response.data.barcode);
            }).catch(() => {
            }).finally(() => {
            });
    },


    /**
     * Update a barcode
     */
    updateBarcode({commit}, barcode: Barcode) {
        axios.put(`/v1/barcodes/${barcode.uuid}`, barcode)
            .then((response) => {
                commit(types.SET_BARCODE, response.data.barcode);
                EventBus.$emit('update-barcode-success', response.data.barcode);
            }).catch(() => {
        }).finally(() => {
        });
    },


    /**
     * Create a new barcode
     */
    async createBarcode({commit}, data: Barcode) {
        try {
            const responseData = await performApiRequest(commit, 'SET_BARCODE_LOADING', 'create', () => axios.post('/v1/barcodes', data));
            commit(types.SET_BARCODE, responseData.barcode);
            EventBus.$emit('create-barcode-success', responseData.barcode);
        } catch (error) {
            EventBus.$emit('fetch-cart-error', error.response);
            if (error.response.status === 404) {
                CartService.removeCartUuid();
                window.location.reload();
            }
        }
    },

    /**
     * Delete a barcode
     */
    async deleteBarcode({commit}, barcode: Barcode) {
        try {
            await performApiRequest(commit, 'SET_BARCODE_LOADING', 'delete', () => axios.delete(`/v1/barcodes/${barcode.uuid}`));
            commit(types.SET_BARCODE, null);
        } catch (error) {
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
