<template>
  <div>
    <v-card>
      <v-card-title>
       <div class="flex">
         Action Items
         <v-spacer/>
         <v-btn size="small" :to="{name: 'settings.action_item.new'}">Add Item</v-btn>
       </div>
      </v-card-title>
      <v-card-text class="mt-3">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400" v-if="actionItems.length > 0">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" class="px-6 py-3">Title</th>
            <th scope="col" class="px-6 py-3">Era</th>
            <th scope="col" class="px-6 py-3">Vehicle Classes</th>
            <th scope="col" class="px-6 py-3"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item) in actionItems" :key="item.uuid">
            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              <router-link :to="{name: 'settings.action_items.single', params: {uuid: item.uuid}}" class="">{{ item.title }}</router-link>
            </th>
            <td class="px-6 py-4">
             {{ item.when }}
            </td>
            <td class="px-6 py-4">{{ item.vehicle_classes_count }}</td>
            <td class="px-6 py-4">
              <router-link
                :to="{name: 'settings.action_items.single', params: {uuid: item.uuid}}"
                class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
              >View</router-link>
            </td>
          </tr>
          </tbody>
        </table>
        <div v-else>No action items yet</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ActionItems.vue',

  created() {
    this.getActionItems();
  },

  computed: {
    ...mapGetters({
      actionItems: 'fleet/actionItems',
    }),
  },

  methods: {
    ...mapActions({
      getActionItems: 'fleet/getActionItems',
    }),
  },
};
</script>

