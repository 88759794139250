import {useStore} from 'vuex';
import {computed} from 'vue';
import {StripePaymentIntent} from "@/store/modules/payments";
import {useMoneyFormatter} from "@/composables/useMoneyFormatter";

export function usePayments() {
    const store = useStore();
    const {fromCents, toCents} = useMoneyFormatter();

    const paymentMethods = computed(() => store.getters['payments/paymentMethods']);
    const paymentsLoading = computed(() => store.getters['payments/loading']);
    const billingSessionUrl = computed(() => store.getters['payments/billingSessionUrl']);
    const paymentForm = computed(() => store.getters['payments/paymentForm']);
    const newPayment = computed(() => store.getters['payments/newPayment']);
    const payment = computed(() => store.getters['payments/payment']);


    // Fetch actions
    const fetchPaymentMethods = () => store.dispatch('payments/fetchPaymentMethods');
    const fetchPayments = (payload: Record<string, any>) => store.dispatch('payments/fetchPayments', payload);
    const fetchPayment = async (paymentId: string) => store.dispatch('payments/fetchPayment', paymentId);
    const fetchStripeCheckoutUrl = (payload: { price: string }) => store.dispatch('stripe/fetchCheckoutUrl', payload);
    const sendPaymentRequestLinkViaSms = (payload: Record<string, any>) => store.dispatch('payments/sendPaymentRequestLinkViaSms', payload);
    const updateDefaultBillingDetails = (paymentMethodId: string) => store.dispatch('payments/updateDefaultBillingDetails', paymentMethodId);
    const addPaymentMethod = (paymentIntent: StripePaymentIntent) => store.dispatch('payments/addPaymentMethod', paymentIntent);

    // Computed values for new payment amounts
    const newPaymentAmount = computed({
        get: () => fromCents(newPayment.value?.amount),
        set: (amount: number) => {
            console.dir(newPayment.value);
            console.dir(amount);
            if (newPayment.value) newPayment.value.amount = toCents(amount);
        },
    });

    const newPaymentKeepDepositAmount = computed({
        get: () => fromCents(newPayment.value?.keep_deposit_amount),
        set: (amount: number) => {
            if (newPayment.value) newPayment.value.keep_deposit_amount = toCents(amount);
        },
    });

    const newPaymentSecurityDeposit = computed({
        get: () => fromCents(newPayment.value?.security_deposit),
        set: (amount: number) => {
            if (newPayment.value) newPayment.value.security_deposit = toCents(amount);
        },
    });

    //RESET_NEW_PAYMENT
    const resetNewPayment = () => store.commit('payments/RESET_NEW_PAYMENT');


    return {
        payment,
        paymentForm,
        paymentMethods,
        paymentsLoading,
        billingSessionUrl,
        newPayment,
        newPaymentAmount,
        newPaymentKeepDepositAmount,
        newPaymentSecurityDeposit,
        resetNewPayment,
        fetchPayment,
        fetchPayments,
        sendPaymentRequestLinkViaSms,
        updateDefaultBillingDetails,
        fetchStripeCheckoutUrl,
        fetchPaymentMethods,
        addPaymentMethod,
    }
}