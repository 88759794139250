import {useStore} from 'vuex';
import {computed} from 'vue';
import {Email, EmailTemplate} from '@/store/modules/email';
import {StoreLoaders} from '@/types';

export function useEmail() {
    const store = useStore();

    const emailTemplate = computed<EmailTemplate | null>(() => store.getters['email/emailTemplate']);
    const emailLoading = computed<StoreLoaders>(() => store.getters['email/loading']);
    const emailHistory = computed(() => store.getters['email/history']);
    const emailForm = computed(() => store.getters['email/emailForm']);
    const emailsLoading = computed(() => store.getters['email/loading']);
    const emailTemplates = computed<EmailTemplate[]>(() => store.getters['email/emailTemplates']);


    const sendEmail = async (payload: Email) => {
        return await store.dispatch('email/sendEmail', payload);
    }

    const getEmailTemplate = async (uuid: string): Promise<EmailTemplate> => {
        return await store.dispatch('email/getEmailTemplate', uuid);
    };

    const getEmailHistory = async (data: { type: string, id: string }) => {
        return await store.dispatch('email/history', data);
    };

    const getEmailTemplates = async (data?: { context?: string }): Promise<EmailTemplate[]> => {
        return await store.dispatch('email/getEmailTemplates', data);
    };

    const updateEmailTemplate = async (payload: EmailTemplate) => {
        return await store.dispatch('email/updateEmailTemplate', payload);
    }

    const sendTestTemplateEmail = async (payload: EmailTemplate) => {
        return await store.dispatch('email/sendTestTemplateEmail', payload);
    }

    const resetEmailTemplate = async (payload: EmailTemplate) => {
        return await store.dispatch('email/resetEmailTemplate', payload);
    }


    return {
        emailTemplate,
        emailLoading,
        emailHistory,
        emailForm,
        emailsLoading,
        emailTemplates,
        getEmailTemplate,
        updateEmailTemplate,
        sendTestTemplateEmail,
        resetEmailTemplate,
        getEmailHistory,
        getEmailTemplates,
        sendEmail
    }
}