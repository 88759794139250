import router from "@/router";
import {RouteLocationNormalizedLoaded} from 'vue-router';

const PARTNER_UUID: string = 'cp_uuid';

const CommissionPartnerService = {

  /**
   * Saves a partner uuid
   */
  savePartnerUuid(uuid: string): void {
    localStorage.setItem(PARTNER_UUID, uuid);
  },

  /**
   * Gets a partner uuid
   */
  getPartnerUuid(): string | null {
    return localStorage.getItem(PARTNER_UUID);
  },

  /**
   * Removed a partner uuid (cp) from the URL query.
   */
  removePartnerUuidParam(route: RouteLocationNormalizedLoaded): void {
      const query = {...route.query};
    delete query.cp;
    router.replace({ query });
  },

  /**
   * Removes a partner uuid from localstorage
   */
  removePartnerUuid(): void {
    localStorage.removeItem(PARTNER_UUID);
  },
};

export { CommissionPartnerService };
