import { EventBus } from '@/plugins/eventBus';
import axios from 'axios';
import * as types from '@/store/mutation-types';


export interface BraintreeState {
    loading: Record<string, boolean>
}

// state
const state: BraintreeState = {
    loading: {
        analytics: false,
        fetchCart: false,
    },
};

const getters = {
    loading: (state: BraintreeState) => state.loading,
};

const mutations = {
    [types.SET_BRAINTREE_LOADING](state: BraintreeState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
};

const actions = {

    getBraintreeOauthUrl({ commit }) {
        commit('SET_BRAINTREE_LOADING', {subject: 'analytics', loading: true});

        axios.get('/v1/braintree/url')
            .then((response) => {
                EventBus.$emit('braintree-oauth-url-create-success', response.data);
            }).catch((e) => {
            EventBus.$emit('braintree-oauth-url-create-error', e.response);
        }).finally(() => {
            commit('SET_BRAINTREE_LOADING', {subject: 'analytics', loading: false});
        });
    },

    saveBraintreeCode({ commit }, data) {
        commit('SET_BRAINTREE_LOADING', {subject: 'analytics', loading: true});

        axios.post('/v1/braintree/redirect', data)
        .then((response) => {
            EventBus.$emit('braintree-oauth-url-create-success', response.data);
        }).catch((e) => {
            EventBus.$emit('braintree-oauth-url-create-error', e.response);
        }).finally(() => {
            commit('SET_BRAINTREE_LOADING', {subject: 'analytics', loading: false});
        });
    },

    getBraintreeProvider() {
        axios.get('/v1/braintree/')
        .then((response) => {
            EventBus.$emit('braintree-oauth-success', response.data);
        }).catch(() => {
        }).finally(() => {
        });
    },
    testPayment() {
        axios.post('/v1/braintree/testPayment')
        .then((response) => {
            EventBus.$emit('delete-braintree-oauth-success', response.data);
        }).catch(() => {
        }).finally(() => {
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
