import axios from 'axios';
import {get} from 'lodash';
import dayjs from 'dayjs';
import {Form} from '@/plugins/FWForms/index';
import {EventBus} from '@/plugins/eventBus';
import {StorageService} from '@/services/storage.service';
import * as types from '../mutation-types';
import {
    BookingInterface,
    CompanyInterface,
    CompanyMeta,
    CompanySettings,
    CompanyState,
    CurrencySettings,
    DefaultMeta,
    DeliveryLocation,
    Employee,
    NotificationPagination,
    OperatingRule,
    PermissionsArray,
    SavingEmployeeNotificationSettings
} from '@/types';
import {RootState} from '@/types/RootState';
import {Module} from 'vuex'
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {createUrlQueryString} from '@/utils/urls';
import {push} from 'notivue'

const defaultMetaValues: CompanyMeta = {
    cancellation_policy: '',
    rental_tos_id: '',
};

const state: CompanyState = {
    loaded: false,

    loading: {
        company: false,
        company_meta: false,
        bookings: false,
        listing: true,
        listings: false,
        employee: false,
        invite: false,
        notifications: false,
        deliveryLocation: false,
        getOperatingRules: false,
        createOperatingRule: false,
        deleteOperatingRule: false,
        saveStripeKey: false,
        resetStripeKey: false,
        markAccountForDeletion: false,
        saveCurrency: false,
        listingReset: false,
        addDefaultMeta: false,
        getDefaultMeta: false,
    },

    companyForm: new Form(),
    companyMetaForm: new Form(),

    originalCompany: {},
    company: {
        active: false,
        address: {
            address_1: '',
            address_2: '',
            city: '',
            state: '',
            postal_code: '',
        },
        bookings: [],
        currency: null,
        delivery_locations: [],
        email: null,
        email_proxy: '',
        found: false,
        init: false,
        listings: [],
        logo: '',
        mark_to_delete_at: null,
        meta: {...defaultMetaValues},
        name: null,
        phone: '',
        phone_fmt: '',
        slug: '',
        timezone: '',
        uuid: '',
    },

    employees: [],

    employee: {
        init: false,
        found: false,
        uuid: '',
        first_name: '',
        last_name: '',
        full_name: '',
        name: '',
        email: '',
        phone: '',
        photo_url: '',
        permissions: [],
        roles: [],
        notificationSettings: null,
    },

    defaultMeta: [],

    possiblePermissions: [
        'listings_manage',
        'settings_manage',
        'account_and_billing_manage',
        'turnover_view',
        'orders_cancel',
        'orders_revert',
    ],

    registerFormRules: {
        required: (value) => !!value || 'Required',
        min: (v) => v.length >= 8 || 'Min of 8 characters',
        email: [
            (v) => !!v || 'E-mail is required',
            (v) => /.+@.+\..+/.test(v) || 'E-mail must be a valid e-mail',
        ],
        code: [
            (v) => !!v || '2FA code is required',
            (v) => v.length >= 4 || 'Should be 4 characters',
        ],
    },

    notifications: [],
    notificationsTotal: 0,
    nextNotifications: 1,
    notificationsLast: 1,
    operating_rules: [],
    feedback_reviews: [],
    trialOver: false,
    settings: {
        'settings.store.enabled': true,
        'settings.store.public': true,
        'settings.store.test': false,
    } as CompanySettings,
    counts: null,
};

const getters = {
    loaded: (state: CompanyState) => state.loaded,
    trialOver: (state: CompanyState) => state.trialOver,
    companyLoading: (state: CompanyState) => state.loading,
    possiblePermissions: (state: CompanyState) => state.possiblePermissions,
    check: (state: CompanyState) => state.company.found,
    company: (state: CompanyState) => state.company,
    settings: (state: CompanyState) => state.settings,
    originalCompany: (state: CompanyState) => state.originalCompany,
    meta: (state: CompanyState) => state.company.meta,
    paymentProfile: (state: CompanyState) => state.company.payment_profile,
    listings: (state: CompanyState) => state.company.listings,
    bookings: (state: CompanyState) => state.company.bookings,
    employees: (state: CompanyState) => state.employees,
    employee: (state: CompanyState) => state.employee,
    registerFormRules: (state: CompanyState) => state.registerFormRules,
    notifications: (state: CompanyState) => state.notifications,
    notificationsTotal: (state: CompanyState) => state.notificationsTotal,
    companySeatCount: (state: CompanyState) => get(state.company, 'employees', []).length,
    companyForm: (state: CompanyState) => state.companyForm,
    companyMetaForm: (state: CompanyState) => state.companyMetaForm,
    operatingRules: (state: CompanyState) => state.operating_rules,
    feedbackReviews: (state: CompanyState) => state.feedback_reviews,
    defaultMeta: (state: CompanyState) => state.defaultMeta,
    notificationsLast: (state: CompanyState) => state.notificationsLast,
    nextNotifications: (state: CompanyState) => state.nextNotifications,
    counts: (state: CompanyState) => state.counts,
};

const mutations = {
    [types.SET_COMPANY_LOADING](state: CompanyState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_COMPANY](state: CompanyState, company: CompanyInterface) {
        state.trialOver = company?.trial_ends_at ? dayjs(company.trial_ends_at) < dayjs() : true;

        state.company = {
            ...state.company, ...company, found: true, init: true,
        };

        if (get(company, 'defaultMeta')) {
            state.defaultMeta = company.defaultMeta;
        }

        if (!company.meta) {
            state.company.meta = {
                cancellation_policy: '',
                rental_tos_id: '',
            };
        }
        state.loaded = true;

        if (company?.operating_rules) {
            state.operating_rules = company.operating_rules;
        }

        state.originalCompany = JSON.parse(JSON.stringify(company));

        StorageService.saveCompanyUuid(get(state.company, 'uuid'));
    },
    [types.SET_COMPANY_PHONE](state: CompanyState, phone: string) {
        state.company.phone = phone;
    },
    [types.SET_COMPANY_META](state: CompanyState, meta: CompanyMeta) {
        console.dir('meta', meta);
        state.company.meta = meta;
    },
    [types.SET_COMPANY_SETTINGS](state: CompanyState, settings: CompanySettings) {
        // merge the settings
        state.settings = {...state.settings, ...settings};
    },
    [types.SET_COUNTS](state: CompanyState, counts: Record<string, number>) {
        state.counts = counts;
    },
    [types.SET_COMPANY_CURRENCY](state: CompanyState, currency: CurrencySettings) {
        state.company.currency = currency;
    },
    [types.SET_COMPANY_DEFAULT_META](state: CompanyState, meta: DefaultMeta) {
        state.defaultMeta = meta;
    },
    [types.SET_POSSIBLE_PERMISSIONS](state: CompanyState, permission: Array<string>) {
        state.possiblePermissions = permission;
    },
    [types.SET_COMPANY_BOOKINGS](state: CompanyState, bookings: BookingInterface[]) {
        state.company.bookings = bookings;
    },
    [types.SET_COMPANY_EMPLOYEES](state: CompanyState, employees: Employee[]) {
        state.employees = employees;
    },
    [types.SET_DELIVERY_LOCATIONS](state: CompanyState, locations: DeliveryLocation[]) {
        state.company.delivery_locations = locations;
    },
    [types.RESET_EMPLOYEE](state: CompanyState) {
        state.employee = {
            full_name: '',
            init: false,
            found: false,
            uuid: '',
            first_name: '',
            last_name: '',
            name: '',
            email: '',
            phone: '',
            photo_url: '',
            permissions: [],
            roles: [],
            notificationSettings: null
        };
    },
    [types.SET_COMPANY_NOTIFICATIONS](state: CompanyState, notifications: NotificationPagination) {
        const data = notifications?.data || [];
        const uniqueIds = new Set([...state.notifications, ...data].map(w => w.id));
        state.notifications = Array.from(uniqueIds).map(id =>
            [...state.notifications, ...data].find(w => w.id === id)
        );
        state.nextNotifications = notifications?.current_page ? notifications.current_page + 1 : 1;
        state.notificationsLast = notifications?.last_page || 1;
        state.notificationsTotal = notifications?.total || 0;
    },
    [types.SET_EMPLOYEE_PERMISSIONS](state: CompanyState, permissions: PermissionsArray[]) {
        state.employee.permissions = permissions;
    },
    [types.SET_EMPLOYEE_ROLES](state: CompanyState, roles: string[]) {
        state.employee.roles = roles;
    },
    [types.SET_COMPANY_EMPLOYEE](state: CompanyState, employee: Employee) {
        state.employee = {
            ...state.employee, ...employee, found: true, init: true,
        };
    },
    [types.SET_OPERATING_RULES](state: CompanyState, rules: OperatingRule[]) {
        state.operating_rules = rules;
    },
    [types.SET_FEEDBACK_REVIEWS](state: CompanyState, reviews: any[]) {
        state.feedback_reviews = reviews;
    },
    [types.SET_COMPANY_FORM_ERROR](state: CompanyState, error: string | Record<string, string[]>) {
        state.companyForm.errors.set(error);
    },
    [types.SET_COMPANY_META_FORM_ERROR](state: CompanyState, error: string | Record<string, string[]>) {
        state.companyMetaForm.errors.set(error);
    },
    [types.CLEAR_COMPANY_META_FORM_ERRORS](state: CompanyState, form_field: string | null = null) {
        if (form_field === null || form_field === undefined) {
            state.companyMetaForm.errors.clear();
        } else {
            state.companyMetaForm.errors.clear(form_field);
        }
    },
};

const actions = {

    async fetchCompany({commit}, companyName: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'company', () => axios.get(`/v1/company/${companyName}`));
            commit(types.SET_COMPANY, responseData?.company);
            if (Array.isArray(responseData?.company?.listings)) {
                commit('listing/SET_ALL_LISTINGS', responseData.company.listings, {root: true});
            }

            state.company.init = true;
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },

    /**
     * Save company Info
     */
    async saveCompanyInfo({state, commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'company', () => axios.put('/v1/company', state.company));
            commit(types.SET_COMPANY, responseData.company);

            push.success({
                message: 'Company saved successfully',
            });
        } catch (error) {
            push.error({
                message: 'There was an error saving company info.',
            });

            console.dir('error');
            console.dir(error);
            commit(types.SET_COMPANY_META_FORM_ERROR, error.response.data.errors);
        }
    },

    async saveCompanyTimezone({commit}, data: { timezone: string, updateListings: boolean }) {
        try {
            await performApiRequest(commit, 'SET_COMPANY_LOADING', 'company', () => axios.put('/v1/company/timezone', data));
            push.success({
                message: 'Timezone saved successfully',
            });
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },

    async saveCompanyMeta({state, commit}) {
        try {
            commit(types.CLEAR_COMPANY_META_FORM_ERRORS);
            const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'company_meta', () => axios.put('/v1/meta', state.company.meta));
            commit(types.SET_COMPANY_META, responseData.meta);
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Company information saved successfully',
            });
        } catch (error) {
            commit(types.SET_COMPANY_META_FORM_ERROR, error.response.data.errors);
            throw error;
        }
    },

    async updateCancellationPolicy({commit, dispatch}, cancellation: string) {
        try {
            await performApiRequest(commit, 'SET_COMPANY_LOADING', 'company', () => axios.put('/v1/company/cancellation-policy', {cancellation}));
            dispatch('fetchCompany', state.company.slug);
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Cancellation policy saved successfully',
            });
        } catch (error) {
            commit('SET_COMPANY_META_FORM_ERROR', error.response.data.errors);
        }
    },

    async updateTermsOfService({commit, dispatch}, terms: string) {
        try {
            await performApiRequest(commit, 'SET_COMPANY_LOADING', 'company', () => axios.put('/v1/company/terms-of-service', {terms}));
            dispatch('fetchCompany', state.company.slug);

            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Terms of service saved successfully',
            });
        } catch (error) {
            commit('SET_COMPANY_META_FORM_ERROR', error.response.data.errors);
        }
    },

    async removeCompanyLogo({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'company', () => axios.delete('/v1/company/logo'));
            commit(types.SET_COMPANY, responseData.company);
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Terms of service saved successfully',
            });
        } catch (error) {
            commit('SET_COMPANY_META_FORM_ERROR', error.response.data.errors);
        }
    },

    fetchCompanyNotifications({commit}) {
        commit('SET_COMPANY_LOADING', {subject: 'notifications', loading: true});

        axios.get(`/v1/notifications?page=${state.nextNotifications}`)
            .then((response) => {
                commit('SET_COMPANY_NOTIFICATIONS', response.data.notifications);
            }).catch((e) => {
            console.dir(e);
        }).finally(() => {
            commit('SET_COMPANY_LOADING', {subject: 'notifications', loading: false});
        });
    },

    async markReadCompanyNotifications({commit}, id: string) {
        try {
            await performApiRequest(commit, 'SET_COMPANY_LOADING', 'notifications', () => axios.put(`/v1/notifications/${id}`));
        } catch (error) {
            commit('SET_COMPANY_META_FORM_ERROR', error.response.data.errors);
        }
    },

    /**
     * Fetch an employee by their invite token
     */
    async fetchEmployeeByInviteToken({commit}, inviteToken: string) {
        try {
            const returnData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'invite', () => axios.get(`/v1/company/invites/${inviteToken}`));
            commit('SET_COMPANY_EMPLOYEE', returnData.employee);
            return returnData;
        } catch (error) {
            commit('SET_COMPANY_META_FORM_ERROR', error.response.data.errors);
        }
    },

    fetchAgencySingleListingBookings({commit}, l_id: string) {
        commit('SET_COMPANY_LOADING', {subject: 'bookings', loading: true});

        axios.get(`/v1/listings/${l_id}/bookings`)
            .then((response) => {
                commit('listing/SET_CURRENT_LISTING_BOOKINGS', response.data.bookings, {root: true});
            }).catch(() => {
        }).finally(() => {
            commit('SET_COMPANY_LOADING', {subject: 'bookings', loading: false});
        });
    },

    /**
     * Save a Stripe API key
     */
    async saveStripeKey({commit}, key: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'saveStripeKey', () => axios.post('/v1/stripe/key', {key}));
            commit(types.SET_COMPANY, responseData.company);
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Stripe secret key added',
            });
            EventBus.$emit('save-stripe-secret-key-success');

            return responseData;
        } catch (error) {
            commit(types.SET_COMPANY_META_FORM_ERROR, error.response.data.errors);
        }
    },

    async resetStripeKey({commit}) {
        const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'resetStripeKey', () => axios.delete('/v1/stripe/key'));
        commit(types.SET_COMPANY, responseData.company);
        EventBus.$emit('SystemMessage', {
            type: 'success',
            message: 'Stripe secret key removed',
        });
        return responseData;
    },

    getCustomPaymentMethods({commit}) {
        commit('SET_COMPANY_LOADING', {subject: 'customPaymentMethods', loading: true});

        axios.get(`/v1/custom-payment-methods`)
            .then((response) => {
                commit('payments/SET_CUSTOM_PAYMENT_METHODS', response.data.meta, {root: true});
            }).catch((e) => {
            console.dir(e);
        }).finally(() => {
            commit('SET_COMPANY_LOADING', {subject: 'customPaymentMethods', loading: false});
        });
    },

    async addCustomPaymentMethods({commit}, name: string) {
        const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'customPaymentMethods', () => axios.post(`/v1/custom-payment-methods`, {name}));
        commit('payments/SET_CUSTOM_PAYMENT_METHODS', responseData.meta, {root: true});
    },

    async getDefaultMeta({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'getDefaultMeta', () => axios.get('/v1/default-meta'));
            commit(types.SET_COMPANY_DEFAULT_META, responseData.meta);
        } catch (error) {
        }
    },

    async addDefaultMeta({commit}, data: {
        meta: ({ value: number; key: string } | { value: string; key: string })[]
    }) {
        try {
            await performApiRequest(commit, 'SET_COMPANY_LOADING', 'addDefaultMeta', () => axios.post('/v1/default-meta', data));
            push.success({
                message: 'Settings saved successfully',
            });
        } catch (error) {
            push.error({
                message: 'There was an error saving settings',
            });
            commit(types.SET_COMPANY_META_FORM_ERROR, error.response.data.errors);
            throw error;
        }
    },

    async fetchDeliveryLocations({commit}) {
        try {
            commit('CLEAR_COMPANY_META_FORM_ERRORS');
            const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'deliveryLocation', () => axios.get('/v1/delivery-locations'));
            EventBus.$emit('fetch-delivery-location-success');
            commit(types.SET_DELIVERY_LOCATIONS, responseData.locations);

            return responseData;
        } catch (error) {
            commit('SET_COMPANY_META_FORM_ERROR', error.response.data.errors);
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },

    async createDeliveryLocation({commit, dispatch}, deliveryLocation: DeliveryLocation) {
        try {
            commit('CLEAR_COMPANY_META_FORM_ERRORS');
            const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'deliveryLocation', () => axios.post('/v1/delivery-locations', deliveryLocation));
            dispatch('fetchDeliveryLocations');
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Delivery location created',
            });
            return responseData;
        } catch (error) {
            commit('SET_COMPANY_META_FORM_ERROR', error.response.data.errors);
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },

    async deleteDeliveryLocation({commit, dispatch}, uuid: string) {
        try {
            commit('CLEAR_COMPANY_META_FORM_ERRORS');
            await performApiRequest(commit, 'SET_COMPANY_LOADING', 'deliveryLocation', () => axios.delete(`/v1/delivery-locations/${uuid}`));
            dispatch('fetchDeliveryLocations');
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Delivery location deleted',
            });
        } catch (error) {
            commit('SET_COMPANY_META_FORM_ERROR', error.response.data.errors);
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },

    async toggleDeliveryLocation({commit, dispatch}, uuid: string) {
        try {
            commit('CLEAR_COMPANY_META_FORM_ERRORS');
            await performApiRequest(commit, 'SET_COMPANY_LOADING', 'deliveryLocation', () => axios.post(`/v1/delivery-locations/${uuid}/toggle`));
            dispatch('fetchDeliveryLocations');

            push.success({
                message: 'Delivery location toggled',
            });
        } catch (error) {
            commit('SET_COMPANY_META_FORM_ERROR', error.response.data.errors);
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },

    async updateDeliveryLocation({commit, dispatch}, location: DeliveryLocation) {
        try {
            commit(types.CLEAR_COMPANY_META_FORM_ERRORS);
            await performApiRequest(commit, 'SET_COMPANY_LOADING', 'deliveryLocation', () => axios.put(`/v1/delivery-locations/${location.uuid}/`, location));
            dispatch('fetchDeliveryLocations');
            push.success({
                message: 'Delivery location updated',
            });
        } catch (error) {
            commit('SET_COMPANY_META_FORM_ERROR', error.response.data.errors);
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },

    async getOperatingRules({commit}) {
        try {
            commit('CLEAR_COMPANY_META_FORM_ERRORS');
            const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'getOperatingRules', () => axios.get('/v1/operating-rules'));
            commit('SET_OPERATING_RULES', responseData.operatingRules);
        } catch (error) {
            commit('SET_COMPANY_META_FORM_ERROR', error.response.data.errors);
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },

    async saveOperatingRule({commit, dispatch}, data: { type: string, data: any }) {
        try {
            commit('CLEAR_COMPANY_META_FORM_ERRORS');
            await performApiRequest(commit, 'SET_COMPANY_LOADING', 'createOperatingRule', () => axios.post('/v1/operating-rules', data));
            dispatch('getOperatingRules');
            EventBus.$emit('save-operating-rule-success', data);
        } catch (error) {
            commit('SET_COMPANY_META_FORM_ERROR', error.response.data.errors);
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },

    /**
     * Delete a companies' operating rule
     */
    async deleteOperatingRule({commit, dispatch}, rule: OperatingRule) {
        try {
            commit('CLEAR_COMPANY_META_FORM_ERRORS');
            await performApiRequest(commit, 'SET_COMPANY_LOADING', 'deleteOperatingRule', () => axios.delete(`/v1/operating-rules/${rule.uuid}`));
            dispatch('getOperatingRules');
            EventBus.$emit('delete-operating-rule-success', rule);
        } catch (error) {
            commit('SET_COMPANY_META_FORM_ERROR', error.response.data.errors);
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },

    async getActivityLog({commit}, data: { page: number }) {
        try {
            const query = createUrlQueryString({
                page: data.page || 1,
            })

            const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'activity_log', () => axios.get(`/v1/activity-log${query}`));

            return responseData.activity;
        } catch (error) {
            console.error('Failed to get activity log:', error);
        }
    },

    getFeedbackReviews({commit}) {
        axios.get('/v1/feedback-reviews')
            .then((response) => {
                commit('SET_FEEDBACK_REVIEWS', get(response, 'data.feedback'));
            }).catch(() => {
        }).finally(() => {
        });
    },

    saveFeedbackReview({commit}, review: { turo_id: string, review: string }) {
        axios.post('/v1/feedback-reviews', review)
            .then((response) => {
                commit('SET_FEEDBACK_REVIEWS', get(response, 'data.feedback'));
                EventBus.$emit('save-feedback-review-success');
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Feedback review saved successfully',
                });
            }).catch(() => {
        }).finally(() => {
        });
    },

    deleteFeedbackReview({commit}, review) {
        axios.delete(`/v1/feedback-reviews/${review.uuid}`, review)
            .then((response) => {
                commit(types.SET_FEEDBACK_REVIEWS, get(response, 'data.feedback'));
                EventBus.$emit('delete-feedback-review-success');
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Feedback review saved successfully',
                });
            }).catch((e) => {
            console.dir(e);
        }).finally(() => {
        });
    },

    /**
     * Mark the account for deletion
     */
    markAccountForDeletion({commit}, data: { reason: string, comments: string }) {
        commit('SET_COMPANY_LOADING', {subject: 'markAccountForDeletion', loading: true});
        axios.post('/v1/mark-account-for-deletion', data)
            .then(() => {
                EventBus.$emit('mark-account-for-deletion-success');
            }).catch((error) => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_COMPANY_LOADING', {subject: 'markAccountForDeletion', loading: false});
        });
    },


    /**
     * Extend company trial
     */
    extendCompanyTrial({commit, state}) {
        commit('SET_COMPANY_LOADING', {subject: 'extendTrial', loading: true});

        axios.post(`/v1/company/${state.company.uuid}/extend-trial`)
            .then((response) => {
                commit('SET_COMPANY', response.data.company);
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Trial extended',
                });
                EventBus.$emit('extend-trial-success');
            }).catch((error) => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_COMPANY_LOADING', {subject: 'extendTrial', loading: false});
        });
    },

    async getEmployees({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'employee', () => axios.get('/v1/employees'));
            commit('SET_COMPANY_EMPLOYEES', responseData.employees);
            EventBus.$emit('employee-get-success');
        } catch (error) {
            commit('SET_COMPANY_META_FORM_ERROR', error.response.data.errors);
        }
    },

    async saveEmployee({commit}, data: { employee_uuid: string, data: object }) {
        try {
            await performApiRequest(commit, 'SET_COMPANY_LOADING', 'employee', () => axios.put(`/v1/employees/${data.employee_uuid}`, data.data));
            EventBus.$emit('employee-updated-success');
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },

    async updateEmployeeNotificationSettings({commit}, data: SavingEmployeeNotificationSettings) {
        try {
            await performApiRequest(commit, 'SET_COMPANY_LOADING', 'employee', () => axios.put(`/v1/employees/${data.uuid}/notification-settings`, data));
            EventBus.$emit('employee-updated-success');
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Settings updated successfully!',
            });
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },


    async listingMatchCompanyUseSecondaryDriver({commit}) {
        try {
            await performApiRequest(commit, 'SET_COMPANY_LOADING', 'listingReset', () => axios.post('/v1/reset-listings-allow-secondary-driver'));
            EventBus.$emit('reset-use-secondary-driver-listings-success');
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Listing settings updated successfully',
            });
        } catch (error) {
            console.error('Failed to get activity log:', error);
        }
    },
};

const companyModule: Module<CompanyState, RootState> = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}

export default companyModule;
