<template>
  <div>
    <h2 class="mt-8 mb-4 font-bold text-2xl">Security</h2>

    <ChangePassword/>

    <TwoFactorAuth/>
  </div>
</template>

<script lang="ts" setup>
import {defineAsyncComponent} from 'vue';

const ChangePassword = defineAsyncComponent(() => import('@/components/dashboard/settings/general/ChangePassword.vue'));
const TwoFactorAuth = defineAsyncComponent(() => import('@/components/dashboard/settings/general/TwoFactorAuth.vue'));
</script>
