<template>
  <div>
    <div v-if="hasAffiliate" class="mb-5">
      <div class="">
        <v-card variant="outlined">
          <v-card-title>
            <div class="flex">
              Payouts
              <v-spacer/>
              <v-btn :to="{name: 'settings.affiliate.payouts'}" size="small" variant="text">
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </v-card-title>
        </v-card>
      </div>
    </div>

    <v-card v-if="!hasAffiliate">
      <v-card-title>
        Fleetwire affiliate program
      </v-card-title>
      <v-card-subtitle>
        Join now and earn money by promoting one of the best fleet management services.
      </v-card-subtitle>
      <v-card-text></v-card-text>
    </v-card>

    <v-card v-if="hasAffiliate && !affiliate.agree_tos_at" :loading="affiliateLoading.affiliateAgreeTOS" class="my-5" variant="outlined">
      <v-card-title>
        Agree to ToS
      </v-card-title>
      <v-card-text>
        <v-checkbox
          v-model="agreeToS"
          label="I agree to the Fleetwire Affiliate Partner Terms of Service."
        >
          <template v-slot:label>
            I agree to the
            <v-btn size="small" variant="text" @click="EventBus.$emit('open-affiliate-terms-dialog');">Fleetwire
              Affiliate Partner Terms of
              Service.
            </v-btn>
          </template>

        </v-checkbox>

        <v-btn :disabled="!agreeToS" :loading="affiliateLoading.affiliateAgreeTOS" @click="affiliateTOSAgree()">Accept
        </v-btn>
      </v-card-text>
    </v-card>

    <v-card v-if="hasAffiliate" :disabled="!affiliate.agree_tos_at" variant="outlined">
      <v-card-title>
        Affiliate Referrals
        <v-spacer/>
      </v-card-title>
      <v-card-text class="mt-3">
        <div class="my-5">
          Affiliate URL:
          <a :href="`https://fleetwire.io/r/${affiliate.slug}`">https://fleetwire.io/r/{{ affiliate.slug }}</a>

        </div>
        <v-data-table
          :headers="headers"
          :items="affiliate.referral"
          :items-per-page="10"
        >
          <template v-slot:item.id="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.user.full_name="{ item }">
            {{ item.user.full_name }}
          </template>
          <template v-slot:item.user.subscribed="{ item }">
            <span v-if="item.user.subscribed" class="text-green-700">
              Subscribed <span class="italic">({{ item.subscribed_product }})</span>
            </span>
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ formatRentalDate(item.created_at, 'ddd MMM DD @ h:mm a') }}
          </template>
          <template v-slot:item.user.trial_ends_at="{ item }">
            <span v-if="item.user.trial_ends_at">
              {{ formatRentalDate(item.user.trial_ends_at, 'ddd MMM DD @ h:mm a') }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <div class="mt-10">
      <v-btn v-if="agreeToS" size="small" variant="text" @click="EventBus.$emit('open-affiliate-terms-dialog');">Terms &
        Conditions
      </v-btn>
    </div>

    <AffiliateTerms/>

  </div>
</template>

<script lang="ts" setup>
import {computed, onBeforeMount, ref, watch} from 'vue';
import {EventBus} from '@/plugins/eventBus';
import AffiliateTerms from '@/views/dashboard/settings/Affiliate/AffiliateTerms.vue';
import {useDateFormatter} from '@/composables/useDate';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';

const router = useRouter();

const store = useStore();
const {formatRentalDate} = useDateFormatter();

const hasAffiliate = ref(false);
const agreeToS = ref(false);

const headers = [
  {
    text: '#',
    align: 'start',
    value: 'id',
  },
  {
    text: 'Name',
    value: 'user.full_name',
  },
  {
    text: 'Email',
    value: 'user.email',
  },
  {text: 'Referred', value: 'created_at'},
  {text: 'Trial Ends', value: 'user.trial_ends_at'},
  {text: 'Active Subscription', value: 'user.subscribed'},
];

const affiliateLoading = computed(() => store.getters['settings/loading']);
const affiliate = computed(() => store.getters['affiliate/affiliate']);
const affiliateApplication = computed(() => store.getters['affiliate/affiliateApplication']);

const affiliateApplications = () => store.dispatch('affiliate/affiliateApplications');
const getAffiliate = () => store.dispatch('affiliate/getAffiliate');
const affiliateTOSAgree = () => {
  store.dispatch('affiliate/affiliateTOSAgree').then(() => {
    getAffiliate();
  });
}

watch(affiliate, (affiliate) => {
  if (affiliate) {
    hasAffiliate.value = true;
    // affiliateApplications();
  }
});

watch(affiliateApplication, (application) => {
  ray(application);
  if (!application || application.status !== 'approved') {
    router.push({name: 'settings.affiliate.signup'}).catch(() => {
    });
  }
});

onBeforeMount(() => {
  getAffiliate();
});
</script>

