import {useStore} from 'vuex';
import {computed} from 'vue';
import {TenantBranch} from '@/types';

export function useBranch() {
    const store = useStore();

    const branches = computed<TenantBranch[]>(() => store.getters['fleet/branches']);
    const branch = computed<TenantBranch>(() => store.getters['fleet/branch']);
    const branchLoading = computed(() => store.getters['fleet/loading']);

    const getFleetBranches = async () => {
        return await store.dispatch('fleet/getFleetBranches');
    }

    const createBranch = (branch: TenantBranch) => {
        return store.dispatch('fleet/createBranch', branch);
    }

    const getFleetBranch = (uuid: string) => {
        return store.dispatch('fleet/getFleetBranch', uuid);
    }

    const deleteFleetBranch = (uuid: string) => {
        return store.dispatch('fleet/deleteBranch', uuid);
    }
    return {
        branchLoading,
        branch,
        branches,
        createBranch,
        getFleetBranches,
        deleteFleetBranch,
        getFleetBranch,
    }
}