import type {RouteRecordRaw} from 'vue-router';
import {CommonRouteMeta} from "@/types";

// Lazy-loaded components
const BaseDashboard = () => import('@/views/dashboard/BaseDashboard.vue');
const BaseOrders = () => import('@/views/dashboard/orders/BaseOrders.vue');
const OrderNewV2 = () => import('@/views/dashboard/orders/OrderNewV2.vue');
const OrderSingleV2 = () => import('@/views/dashboard/orders/OrderSingleV2.vue');
const TheRightSidebar = () => import('@/components/dashboard/TheRightSidebar.vue');
const AddNewOrder = () => import('@/components/dashboard/navActions/AddNewOrder.vue');
const NewOrderOptions = () => import('@/components/dashboard/navActions/NewOrderOptions.vue');
const SingleOrderOptions = () => import('@/components/dashboard/navActions/SingleOrderOptions.vue');


const ordersRoutes: RouteRecordRaw[] = [
    {
        path: '/orders',
        components: {
            default: BaseDashboard,
        },
        meta: {
            title: 'orders',
            icon: 'mdi-newspaper-variant-multiple-outline',
            noCache: false,
            isBase: true,
            permissions: ['orders_view'],
            showInMenu: true,
            middleware: ['auth'],
            layout: 'Dashboard',
        } as CommonRouteMeta,
        children: [
            {
                path: '',
                components: {
                    default: BaseOrders,
                    navRightActions: AddNewOrder,
                },
                name: 'orders.index',
                meta: {title: 'Orders', icon: 'mdi-account-outline', noCache: false, isBase: true},
            },
            {
                path: 'upcoming',
                components: {
                    default: BaseOrders,
                    navRightActions: AddNewOrder,
                },
                name: 'orders.index.upcoming',
                meta: {title: 'Orders Upcoming', icon: 'mdi-account-outline', noCache: false, isBase: true},
            },
            {
                path: 'overdue',
                components: {
                    default: BaseOrders,
                    navRightActions: AddNewOrder,
                },
                name: 'orders.index.overdue',
                meta: {title: 'Orders Overdue', icon: 'mdi-account-outline', noCache: false, isBase: true},
            },
            {
                path: 'new',
                components: {
                    default: OrderNewV2,
                    navRightActions: NewOrderOptions,
                },
                name: 'orders.new',
                meta: {title: 'New', icon: 'mdi-account-outline', noCache: false, hasRightSidebar: false},
            },
            {
                path: 'new-order',
                components: {
                    default: OrderNewV2,
                    rightSidebar: TheRightSidebar,
                    navRightActions: NewOrderOptions,
                },
                name: 'orders.newV2',
                meta: {title: 'New', icon: 'mdi-account-outline', noCache: false, hasRightSidebar: true},
            },
            {
                path: ':b_id',
                components: {
                    default: OrderSingleV2,
                    rightSidebar: TheRightSidebar,
                    navRightActions: SingleOrderOptions,
                },
                name: 'orders.single',
                meta: {
                    title: 'order',
                    icon: 'mdi-account-outline',
                    noCache: false,
                    hasRightSidebar: true,
                    resource: 'b_id',
                    isBase: false
                },
            },
            {
                path: 'v2/:b_id',
                components: {
                    default: OrderSingleV2,
                    rightSidebar: TheRightSidebar,
                    navRightActions: SingleOrderOptions,
                },
                name: 'orders.singleV2',
                meta: {title: 'order', icon: 'mdi-account-outline', noCache: false, hasRightSidebar: true},
            },
        ],
    }
];

export default ordersRoutes;
