<template>
  <section class="">

    <slot>
      <router-view />
    </slot>

  </section>
</template>

<script>

export default {
  name: 'LayoutCompany',

  middleware: ['company'],

  layout: 'company',

  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.fullPath;
    },
  },
};
</script>

<style lang="scss">
  .v-application ul,
  .v-application ol {
    padding-left: 0 !important;
  }

  .options-subtitle {
    line-height: 1.2;
    color: rgba(255, 255, 255, 0.7);
    font-size: 1rem;
    margin-top: 5px;
  }
</style>
