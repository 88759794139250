<template>
  <div class="">
    <div class=" m-auto">
      <card :title="$t('verify_email')">
        <form @keydown="form.onKeydown($event)" @submit.prevent="send">
          <alert-success :form="form" :message="status"/>

          <!-- Email -->
          <div class="form-group row">
            <label class="col-form-label text-md-right">{{ $t('email') }}</label>
            <div class="">
              <input v-model="form.email" :class="{ 'is-invalid': form.errors.has('email') }" class="form-control" name="email" type="email">
              <has-error :form="form" field="email"/>
            </div>
          </div>

          <!-- Submit Button -->
          <div class="form-group row">
            <div class=" ml-md-auto">
              <v-button :loading="form.busy" block large>
                {{ $t('send_verification_link') }}
              </v-button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>

<script>
import Form from 'vform';

export default {
  name: 'ResendComponent',

  layout: 'blank',

  middleware: 'guest',

  metaInfo() {
    return { title: this.$t('verify_email') };
  },

  data: () => ({
    status: '',
    form: new Form({
      email: '',
    }),
  }),

  created() {
    if (this.$route.query.email) {
      this.form.email = this.$route.query.email;
    }
  },

  methods: {
    async send() {
      const { data } = await this.form.post('/api/v1/email/resend');

      this.status = data.status;

      this.form.reset();
    },
  },
};
</script>
