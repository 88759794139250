<template>
  <v-card :disabled="vehicleLoading.class" :loading="vehicleLoading.class">
    <v-card-title>
      New vehicle class
    </v-card-title>
    <v-card-text class="mt-3">
      <v-form class="space-y-4">
        <v-text-field
          v-model="currentWorkingClass.name"
          :error-messages="vehicleClassForm.errors.get('name')"
          class="w-full md:w-[300px]"
          label="Name"
          variant="outlined"
          @keyup="commit('vehicles/CLEAR_VEHICLE_FORM_ERRORS', 'name')"
        />
        <div class="flex flex-col md:flex-row md:items-center gap-2">
          <v-text-field
            v-model="currentWorkingClass.sipp_code"
            :error-messages="vehicleClassForm.errors.get('sipp_code')"
            class="w-full md:w-[300px]"
            label="SIPP Code"
            variant="outlined"
            @keyup="commit('vehicles/CLEAR_VEHICLE_FORM_ERRORS', 'sipp_code')"
          />
          <v-btn size="x-small" variant="text" @click="openSippDialog()">What is SIPP?</v-btn>
        </div>
        <v-text-field
          v-model="currentWorkingClass.minimum_age"
          :error-messages="vehicleClassForm.errors.get('minimum_age')"
          class="w-full md:w-[300px]"
          label="Minimum Age"
          type="number"
          variant="outlined"
          @keyup="commit('vehicles/CLEAR_VEHICLE_FORM_ERRORS', 'minimum_age')"
        />
        <div>
          <v-select
            v-model="vehicleClassBranch"
            :error-messages="vehicleClassForm.errors.get('branch')"
            :items="branchesFiltered"
            append-icon="mdi-close"
            class="fleet-branches-select w-full md:w-[300px]"
            label="Branches"
            variant="outlined"
            @update:modelValue="commit('vehicles/CLEAR_VEHICLE_FORM_ERRORS', 'branch')"
            @click:append="vehicleClassBranch = null"
          >
            <template v-slot:append-item>
              <div class="border-t py-5 flex justify-center">
                <router-link :to="{name: 'settings.fleet.branches'}">
                  <span class="text-sm">Manage Fleet Branches</span>
                </router-link>
              </div>
            </template>
          </v-select>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions class="flex flex-wrap gap-2">
      <v-spacer/>
      <div class="flex items-center gap-2">
        <v-btn size="small" variant="text" @click="cancel">
          Cancel
        </v-btn>
        <v-btn :loading="vehicleLoading.class" @click="createVehicleClassInit">Save</v-btn>
      </div>
    </v-card-actions>
  </v-card>
  <SippDialog v-model="isSippDialogOpen"/>
</template>

<script lang="ts" setup>
import SippDialog from '@/views/dashboard/settings/Fleet/SippDialog.vue';
import {computed, onBeforeMount, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import {VehicleClass} from '@/types';
import {useVehicle} from '@/composables/useVehicle';
import {useBranch} from '@/composables/useBranch';

const {dispatch, commit} = useStore();
const router = useRouter();

const {createVehicleClass, vehicleClassForm, vehicleLoading, vehicleClassCreated} = useVehicle();
const {branches, getFleetBranches} = useBranch();

const createVehicleClassInit = () => {
  createVehicleClass(currentWorkingClass.value);
};

const currentWorkingClass = ref<VehicleClass>({
  name: '',
  uuid: '',
  minimum_age: null,
  sipp_code: '',
  branch: null,
  active: true,
  image_url: null,
});


const branchesFiltered = computed(() => branches.value.map((b) => ({
  value: b.uuid,
  title: b.name,
})));

const isSippDialogOpen = ref(false);

const openSippDialog = () => {
  isSippDialogOpen.value = true;
};

onBeforeMount(() => {
  getFleetBranches();
});

const vehicleClassBranch = computed({
  get() {
    return currentWorkingClass.value.branch;
  },
  set(value) {
    currentWorkingClass.value.branch = value;
  },
});

const cancel = () => {
  router.push({name: 'settings.fleet.classes'}).catch(() => {
  });
};


watch(branchesFiltered, (filteredBranches) => {
  if (!currentWorkingClass.value.branch && filteredBranches.length === 1) {
    const branch = branches.value.find(b => b.uuid === filteredBranches[0].value);
    if (branch) {
      currentWorkingClass.value.branch = branch;
    }
  }
}, {immediate: true});

watch(vehicleClassCreated, (newValue) => {
  if (newValue) {
    dispatch('vehicles/SET_VEHICLE_CLASS_CREATED', false);
    router.push({name: 'settings.fleet.classes'}).catch(() => {
    });
  }
});
</script>

