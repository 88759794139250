<template>
  <div class="md:grid md:grid-cols-3 md:gap-6">
    <div class="md:col-span-1 flex justify-between mb-4 md:mb-0">
      <div class="px-4 sm:px-0">
        <h3 class="text-lg font-medium text-gray-900 dark:text-gray-100">Team Management</h3>
        <p class="mt-1 text-sm text-gray-600 dark:text-gray-400">
          Add team members to your Fleetwire account and manage their access levels with customizable role
          permissions.<br><br>
          <TheHelpLink url="https://help.fleetwire.io/en/article/creating-team-accounts-3vjoe5/?bust=1675454097202"></TheHelpLink>
        </p>
      </div>
    </div>

    <div class="md:col-span-2">
      <v-card>
        <v-alert v-if="seatLimitReached" class="card-alert-full" density="compact" type="warning">
          Seat limit reached for your plan.
        </v-alert>

        <v-card-title class="px-4 sm:px-6">
          <div class="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-0">
            <v-badge :content="companyEmployees.length" :inline="true">
              Seats
            </v-badge>
            <v-spacer/>
            <v-btn
              :disabled="seatLimitReached || !checkRole(['company_admin'])"
              :to="{ name: 'settings.account.employees.new' }"
              class="text-decoration-none w-full sm:w-auto"
              color="primary"
              size="small"
              variant="outlined"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>
              Invite team member
            </v-btn>
          </div>
        </v-card-title>
        <v-divider opacity="50"/>
        <v-container class="px-4 sm:px-6">
          <div v-if="checkRole(['company_admin'])">
            <v-list class="team-list">
              <v-list-item
                v-for="employee in companyEmployees"
                :key="employee.email"
                :class="{'border-l-4 border-primary': employee.email === user.email}"
                :to="{ name: 'settings.account.employees', params: { 'e_id': employee.uuid }}"
                class="text-decoration-none py-3 px-4 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors duration-200"
              >
                <div class="flex items-center justify-between w-full">
                  <div class="flex items-center">
                    <v-avatar
                      class="shadow-sm"
                      color="primary"
                      size="40"
                    >
                      <span class="text-white font-medium">{{ employeeInitials(employee) }}</span>
                    </v-avatar>
                    <div class="ml-4">
                      <div class="flex items-center gap-2">
                        <span class="text-gray-900 dark:text-gray-100 font-medium">
                          {{ employee.name }}
                        </span>
                        <v-chip
                          v-if="employee.email === user.email"
                          class="text-xs"
                          color="primary"
                          size="small"
                          variant="outlined"
                        >
                          Account Owner
                        </v-chip>
                      </div>
                      <div class="text-sm text-gray-500 dark:text-gray-400 mt-1">
                        {{ employee.email }}
                      </div>
                    </div>
                  </div>
                  <v-icon class="opacity-50" color="gray" size="small">mdi-chevron-right</v-icon>
                </div>
              </v-list-item>
            </v-list>
          </div>
          <div v-else class="flex items-center gap-2 text-gray-500 dark:text-gray-400">
            <v-icon size="small">mdi-lock</v-icon>
            You are not the company admin to be able to view this section.
          </div>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, onMounted} from 'vue';
import {useStore} from 'vuex';
import {useSubscription} from '@/composables/useSubscription';
import checkRole from '@/utils/role';
import {Employee} from '@/types';

const store = useStore();

const {seatLimitReached} = useSubscription();

const getEmployees = () => {
  store.dispatch('company/getEmployees');
};

const companyEmployees = computed<Employee[]>(() => store.getters['company/employees']);
const user = store.getters['auth/user'];

const employeeInitials = (employee: Employee): string => {
  if (employee.first_name && employee.last_name) {
    return employee.first_name[0] + employee.last_name[0];
  }

  const matches = employee.name?.match(/\b\w/g) || [];

  return matches.slice(0, 2).join('').toUpperCase();
};


onMounted(() => {
  getEmployees();
});
</script>

<style scoped>
.team-list {
  @apply divide-y divide-gray-100 dark:divide-gray-800;
}

.team-list :deep(.v-list-item) {
  @apply min-h-0;
}

.team-list :deep(.v-list-item--active) {
  @apply bg-gray-50 dark:bg-gray-800;
}
</style>