import {Store} from 'vuex';
import {RootState} from '@/types/RootState';

export default async function auth({store}: { store: Store<RootState> }) {
    console.log('%c -- MIDDLEWARE -- AUTH ✅', 'color: white;background: green; padding: 2px; font-weight: bold;');

    try {
        // Check if user is authenticated
        if (!isLoggedIn(store)) {
            console.warn('%c 🚪 User is NOT logged in - Redirecting...', 'color: orange; font-weight: bold;');
            return false;
        }

        console.log('%c 🔓 User is logged in - Loading dynamic routes...', 'color: lime; font-weight: bold;');

        await addDynamicRoutes(store);

        return true;
    } catch (error) {
        console.error('%c ❌ Auth Middleware Error:', 'color: red; font-weight: bold;', error);
        return false;
    }
}

/**
 * Check if the user is logged in
 */
function isLoggedIn(store: Store<RootState>): boolean {
    const token = store.getters['auth/token'];
    console.log('%c 🔑 Auth Token:', 'color: cyan; font-weight: bold;', token);
    return !!token;
}

/**
 * Add dynamic routes
 */
async function addDynamicRoutes(store: Store<RootState>) {
    try {
        const roles = store.getters['auth/roles'];
        const permissions = store.getters['auth/permissions'];

        console.log('%c 🚀 Generating dynamic routes...', 'color: lightblue; font-weight: bold;');
        console.table({roles});
        console.table({permissions});

        await store.dispatch('permission/generateRoutes', {roles, permissions});

        console.log('%c ✅ Dynamic routes added successfully!', 'color: lime; font-weight: bold;');
    } catch (error) {
        console.error('%c ❌ Error adding dynamic routes:', 'color: red; font-weight: bold;', error);
    }
}