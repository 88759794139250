import axios from 'axios';
import {EventBus} from '@/plugins/eventBus';
import * as types from '../mutation-types';
import {Connection, StoreLoaders} from '@/types';
import {performApiRequest} from '@/store/helpers/vuex-helpers';


export interface BouncieState {
    init: boolean;
    loading: StoreLoaders;
    credentials: {
        email: string;
        password: string;
    };
    connections: Connection[];
    vehicles: any[];

}

const state: BouncieState = {
    init: false,

    loading: {
        login: false,
        toggle: false,
        delete: false,
        vehicleState: false,
        deleteConnection: false,
    },

    credentials: {
        email: '',
        password: '',
    },

    connections: [],
    vehicles: [],
};

const getters = {
    init: (state: BouncieState) => state.init,
    loading: (state: BouncieState) => state.loading,
    credentials: (state: BouncieState) => state.credentials,
    connections: (state: BouncieState) => state.connections,
    vehicles: (state: BouncieState) => state.vehicles,
};

const mutations = {
    [types.SET_BOUNCIE_INIT](state: BouncieState, init: boolean) {
        state.init = init;
    },
    [types.SET_BOUNCIE_LOADING](state: BouncieState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_BOUNCIE_CONNECTIONS](state: BouncieState, connections: Connection[]) {
        state.connections = connections;
    },
};

const actions = {


    /**
     * Fetch Bouncie vehicles
     */
    async fetchVehicles({commit}, bouncie: Connection) {
        try {
            const responseData = await performApiRequest(commit, 'SET_BOUNCIE_LOADING', 'vehicles', () => axios.get(`/v1/connections/bouncie/${bouncie.uuid}/vehicles`));

            EventBus.$emit('fetch-bouncie-vehicles-success', responseData);
            commit('SET_BOUNCIE_CONNECTIONS', [responseData.bouncie]);
        } catch (error) {
            console.error(error);
        }
    },


    /**
     * Get Bouncie vehicles
     */
    async getVehicles({commit}, bouncie: Connection) {
        try {
            await performApiRequest(commit, 'SET_BOUNCIE_LOADING', 'vehicles', () => axios.get(`/v1/connections/bouncie/${bouncie.uuid}/vehicles`));
        } catch (error) {
            console.error(error);
        }
    },


    /**
     * Fetch Bouncie vehicle state
     */
    async getVehicleState({commit}, vehicle) {
        try {
            const responseData = await performApiRequest(commit, 'SET_BOUNCIE_LOADING', 'vehicleState', () => axios.get(`/v1/connections/bouncie/${vehicle.bouncie_uuid}/vehicles/${vehicle.uuid}`));

            EventBus.$emit('bouncie-vehicle-ping-success', responseData.state);
        } catch (error) {
            console.error(error);
        }
    },

    async getVehicleTrips({commit}, data) {
        try {
            const responseData = await performApiRequest(commit, 'SET_BOUNCIE_LOADING', 'vehicleState', () => axios.get(`/v1/connections/bouncie/${data.vehicle.bouncie_uuid}/trips/${data.vehicle.uuid}`));
            EventBus.$emit('bouncie-trips-success', responseData.trips);
        } catch (error) {
            console.error(error);
        }
    },


    /**
     * Toggle Bouncie vehicle active
     */
    async toggleActive({commit}, bouncie: Connection) {
        try {
            const connIndex = state.connections.findIndex((c) => bouncie.uuid === c.uuid);

            state.connections[connIndex].loading = true;

            const responseData = await performApiRequest(commit, 'SET_BOUNCIE_LOADING', 'vehicleState', () => axios.put(`/v1/connections/bouncie/${bouncie.uuid}/toggle`));
            state.connections[connIndex].connected = true;
            EventBus.$emit('toggle-bouncie-success');
            commit('SET_BOUNCIE_CONNECTIONS', responseData?.company?.connections?.bouncie || []);

            state.connections[connIndex].loading = false;
        } catch (error) {
            console.error(error);
        }
    },


    /**
     * Delete Bouncie connection
     */
    async deleteConnection({commit}, bouncie: Connection) {
        const responseData = await performApiRequest(commit, 'SET_BOUNCIE_LOADING', 'deleteConnection', () => axios.delete(`/v1/connections/bouncie/${bouncie.uuid}`));
        EventBus.$emit('SystemMessage', {
            type: 'success',
            message: 'Bouncie connection removed successfully',
        });
        commit(types.SET_BOUNCIE_CONNECTIONS, responseData?.company?.connections?.bouncie || []);
    },

};


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
