import {store} from '@/store';

/**
 * @example see @/views/permission/Directive.vue
 */
export default function checkRole(value: string[]): boolean {
    if (value && value instanceof Array && value.length > 0) {
        const roles = store.getters['auth/roles'];
        const requiredRoles = value;

        return roles.some((role: string) => requiredRoles.includes(role));
    }
    console.error('Need roles! Like v-role="[\'admin\',\'editor\']"');
    return false;
}
