import axios from 'axios';
import {EventBus} from '@/plugins/eventBus';
import {Form} from '@/plugins/FWForms/index';
import * as types from '../mutation-types';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {VehicleClass, VehicleMake, VehicleModel} from '@/types';
import {push} from 'notivue';

interface VehicleStatesResponse {
    states: string[];
}

export type VehicleState = {
    loading: Record<string, boolean>,
    vehicleClassCreated: boolean,
    vehicleClassesLoaded: boolean,
    vehicleClassForm: Form<any>,
    vehicleMakes: VehicleMake[],
    vehicleModels: VehicleModel[],
    vehicleClasses: VehicleClass[],
    vehicleClass: VehicleClass | {},
}

const state: VehicleState = {
    loading: {
        makes: false,
        models: false,
        fetchOdometer: false,
        classes: false,
        class: false,
    },
    vehicleClassCreated: false,
    vehicleClassesLoaded: false,
    vehicleClassForm: new Form(),
    vehicleMakes: [],
    vehicleModels: [],
    vehicleClasses: [],
    vehicleClass: {},
};

const getters = {
    vehicleMakes: (state: VehicleState) => state.vehicleMakes,
    vehicleModels: (state: VehicleState) => state.vehicleModels,
    vehicleLoading: (state: VehicleState) => state.loading,
    vehicleClasses: (state: VehicleState) => state.vehicleClasses,
    vehicleClass: (state: VehicleState) => state.vehicleClass,
    vehicleClassForm: (state: VehicleState) => state.vehicleClassForm,
    vehicleClassCreated: (state: VehicleState) => state.vehicleClassCreated,
};

const mutations = {
    [types.SET_VEHICLES_LOADING](state: VehicleState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_VEHICLE_MAKES](state: VehicleState, makes: VehicleMake[]) {
        state.vehicleMakes = makes;
    },
    [types.SET_VEHICLE_MODELS](state: VehicleState, models: VehicleModel[]) {
        state.vehicleModels = models;
    },
    [types.SET_VEHICLE_CLASS_CREATED](state: VehicleState, value: boolean) {
        state.vehicleClassCreated = value;
    },
    [types.SET_VEHICLE_CLASSES](state: VehicleState, classes: VehicleClass[]) {
        state.vehicleClassesLoaded = true;
        state.vehicleClasses = classes;
    },
    [types.SET_VEHICLE_CLASS](state: VehicleState, vehicleClass: VehicleClass) {
        state.vehicleClass = vehicleClass;
    },
    [types.SET_VEHICLE_FORM_ERRORS](state: VehicleState, errors: any) {
        state.vehicleClassForm.errors.set(errors);
    },
    [types.CLEAR_VEHICLE_FORM_ERRORS](state: VehicleState, form_field: string | null) {
        if (form_field === null || form_field === undefined) {
            state.vehicleClassForm.errors.clear();
        } else {
            state.vehicleClassForm.errors.clear(form_field);
        }
    },
};

const actions = {

    /**
     * Fetch vehicle makes
     */
    async fetchVehicleMakes({commit}, refresh = false) {
        if (state.vehicleMakes.length > 0 && !refresh) {
            return;
        }

        try {
            const responseData = await performApiRequest(commit, 'SET_VEHICLES_LOADING', 'makes', () => axios.get('/v1/vehicles/makes'));
            commit('SET_VEHICLE_MAKES', responseData.makes);
        } catch (error) {
        }
    },

    /**
     * Fetch vehicle models
     */
    async fetchVehicleModels({commit}, make: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_VEHICLES_LOADING', 'models', () => axios.get(`/v1/vehicles/makes/${make}/models`));
            commit('SET_VEHICLE_MODELS', responseData.models);
        } catch (error) {
        }
    },


    /**
     * Get vehicle classes
     */
    async getVehicleClasses({commit}, refresh = false) {
        if (state.vehicleClassesLoaded && !refresh) {
            return;
        }

        try {
            const responseData = await performApiRequest(commit, 'SET_VEHICLES_LOADING', 'classes', () => axios.get('/v1/vehicle-classes'));
            commit('SET_VEHICLE_CLASSES', responseData.classes);
        } catch (error) {
        }
    },


    /**
     * Get vehicle class
     */
    async getClass({commit}, uuid: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_VEHICLES_LOADING', 'class', () => axios.get(`/v1/vehicle-classes/${uuid}`));
            commit('SET_VEHICLE_CLASS', responseData.class);
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },


    /**
     * Create a vehicle class
     */
    async createClass({commit, dispatch}, vehicleClass: VehicleClass) {
        try {
            await performApiRequest(commit, 'SET_VEHICLES_LOADING', 'class', () => axios.post('/v1/vehicle-classes', vehicleClass));
            EventBus.$emit('create-vehicle-class-success');
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Vehicle class created successfully!',
            });
            commit('SET_VEHICLE_CLASS_CREATED', true);
            dispatch('getVehicleClasses', true);
        } catch (error) {
            commit('SET_VEHICLE_FORM_ERRORS', error.response.data.errors);
        }
    },


    /**
     * Update a vehicle class
     */
    async updateClass({commit, dispatch}, vehicleClass: VehicleClass) {
        try {
            const responseData = await performApiRequest(commit, 'SET_VEHICLES_LOADING', 'class', () => axios.put(`/v1/vehicle-classes/${vehicleClass.uuid}`, vehicleClass));
            commit('SET_VEHICLE_CLASS', responseData.class);
            dispatch('getVehicleClasses', true);
            EventBus.$emit('update-vehicle-class-success');
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Vehicle class saved successfully!',
            });
        } catch (error) {
            state.vehicleClassForm.errors.set(error.response.data.errors);
        }
    },


    /**
     * Create a vehicle class
     */
    async deleteClass({commit}, uuid: string) {
        try {
            await performApiRequest(commit, 'SET_VEHICLES_LOADING', 'class', () => axios.delete(`/v1/vehicle-classes/${uuid}`));
            EventBus.$emit('delete-vehicle-class-success');
        } catch (error) {
        }
    },


    /**
     * Get a vehicle state
     */
    async getStates({commit}, v_id: string) {
        try {
            const responseData = await performApiRequest<VehicleStatesResponse>(commit, 'SET_VEHICLES_LOADING', 'getStates', () => axios.get(`/v1/vehicles/${v_id}/states`));
            EventBus.$emit('fetch-vehicle-states-success', responseData.states);
        } catch (error) {
        }
    },


    /**
     * Fetch a vehicles odometer
     */
    async fetchOdometer({commit}, v_id: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_VEHICLES_LOADING', 'fetchOdometer', () => axios.get(`/v1/vehicles/${v_id}/odometer`));
            EventBus.$emit('fetch-odometer-reading-success', responseData.vehicle);
            EventBus.$emit('fetch-vehicle-states-success', responseData.states);
        } catch (error) {
            if (error.response.status === 408) {
                EventBus.$emit('tesla-is-asleep', error.response.data.tesla);
                EventBus.$emit('show-tesla-wake-options', error.response.data.tesla);
            }
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
