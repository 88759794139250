<template>
  <div>
    <v-card :disabled="branchLoading.updateBranch || branchLoading.fetchBranch" :loading="branchLoading.fetchBranch || branchLoading.updateBranch">
      <v-card-title>
        Branch: {{ branch?.name }}
      </v-card-title>
      <v-card-text class="mt-3">
        <v-form v-if="currentWorkingBranch.set">
          <div class="space-y-6">
            <!-- Name Field -->
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div v-show="!editBranch.name" class="flex items-center space-x-2">
                <span class="font-bold">Name:</span>
                <span>{{ branch.name }}</span>
                <v-btn size="small" variant="text" @click="editBranch.name = !editBranch.name">
                  <v-icon size="small">mdi-pencil</v-icon>
                </v-btn>
              </div>
              <v-text-field
                v-show="editBranch.name"
                v-model="currentWorkingBranch.name"
                class="w-full"
                label="Name"
                variant="outlined"
              />
            </div>

            <!-- Email Field -->
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div class="flex items-center space-x-2">
                <span class="font-bold">Email:</span>
                <span>{{ branch.email }}</span>
                <v-btn size="small" variant="text" @click="editBranch.email = !editBranch.email">
                  <v-icon size="small">mdi-pencil</v-icon>
                </v-btn>
              </div>
              <v-text-field
                v-show="editBranch.email"
                v-model="currentWorkingBranch.email"
                class="w-full"
                label="Email"
                variant="outlined"
              />
            </div>

            <!-- Rental Agreement Field -->
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div class="flex items-center space-x-2">
                <span class="font-bold">Rental Agreement:</span>
                <span>{{ documentName(document) }}</span>
                <v-btn size="small" variant="text" @click="editBranch.agreement = !editBranch.agreement">
                  <v-icon size="small">mdi-pencil</v-icon>
                </v-btn>
              </div>
              <v-select
                v-show="editBranch.agreement"
                v-model="currentWorkingBranch.document"
                :items="documents"
                class="w-full"
                item-title="name"
                item-value="uuid"
                label="Rental Agreement"
                return-object
                variant="outlined"
              >
                <template v-slot:item="{ props, item }">
                  <v-list-item :subtitle="item.raw.uuid" v-bind="props"/>
                </template>
              </v-select>
            </div>

            <!-- Timezone Field -->
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div class="flex items-center space-x-2">
                <span class="font-bold">Timezone:</span>
                <span>{{ currentWorkingBranch.timezone }}</span>
                <v-btn size="small" variant="text" @click="editBranch.timezone = !editBranch.timezone">
                  <v-icon size="small">mdi-pencil</v-icon>
                </v-btn>
              </div>
              <v-autocomplete
                v-show="editBranch.timezone"
                v-model="currentWorkingBranch.timezone"
                :items="timezones"
                :label="$t('timezone')"
                :persistent-hint="true"
                class="w-full"
                hint="This will set all your listings timezone under this branch"
                variant="outlined"
              />
            </div>

            <!-- Location Settings -->
            <div class="mt-8 pt-4 border-t">
              <h4 class="text-lg font-semibold mb-4">Location Settings</h4>

              <!-- Branch location -->
              <div class="space-y-4">
                <div class="flex items-center space-x-2">
                  <span class="font-bold">Branch Location</span>
                  <v-btn size="small" variant="text" @click="changeLocationInit()">
                    <v-icon size="small">mdi-pencil</v-icon>
                  </v-btn>
                </div>

                <div v-if="branchLocationName && !editingLocation" class="space-y-4">
                  <p>{{ branchLocationName }}</p>
                  <v-img
                    :src="locationStaticMap"
                    class="rounded-lg"
                    height="200"
                    width="300"
                  />
                </div>
                <div v-else>
                  <MapBoxAddressAutofill
                    class="w-full"
                    @locationData="addLocationData"
                  />
                </div>
              </div>

              <!-- Pickup/Return location -->
              <div class="mt-6 space-y-4">
                <div class="flex items-center space-x-2">
                  <span class="font-bold">Pickup/Return Location:</span>
                  <span v-if="pickupReturnSameAsBranch">Same as branch</span>
                  <span v-else>{{ branchClone.pickup_location }}</span>
                  <v-btn size="small" variant="text" @click="editBranch.pickup_location = !editBranch.pickup_location">
                    <v-icon size="small">mdi-pencil</v-icon>
                  </v-btn>
                </div>

                <div v-show="editBranch.pickup_location" class="border rounded p-4 space-y-4">
                  <v-checkbox
                    v-model="pickupReturnSameAsBranch"
                    label="Same as branch location"
                  />
                  <div v-if="!pickupReturnSameAsBranch" class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <v-text-field
                      v-model="currentWorkingBranch.pickup_location"
                      class="w-full"
                      label="Default Pickup Location"
                      variant="outlined"
                    />
                    <v-text-field
                      v-model="currentWorkingBranch.return_location"
                      class="w-full"
                      label="Default Return Location"
                      variant="outlined"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-form>
        <div v-else class="flex justify-center items-center py-8">Loading...</div>
      </v-card-text>
      <v-divider opacity="50"/>
      <v-card-actions>
        <DeleteButton @deleteConfirmed="deleteFleetBranchInit()"/>
        <v-spacer/>
        <v-btn variant="text" @click="router.push({ name: 'settings.fleet.branches' }).catch(() => {});">Back</v-btn>
        <v-btn :loading="branchLoading.updateBranch" color="primary" @click="updateFleetBranchInit()">Save</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="matchPickupReturnLocationToBranchDialog" width="400">
      <v-card>
        <v-card-title>
          Update Locations
        </v-card-title>
        <v-card-text>
          Do you want to make the pickup & return locations the same as the branch location?
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn variant="text" @click="matchPickupReturnLocationToBranchDialog = false;">No</v-btn>
          <v-btn color="primary" @click="matchPickupReturnLocationsToBranch()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script lang="ts" setup>
import {computed, defineAsyncComponent, onBeforeMount, onBeforeUnmount, ref, watch} from 'vue';
import MapBoxAddressAutofill from '@/components/widgets/MapBoxAddressAutofill.vue';
import {timezones} from '@/utils/dates';
import {useBranch} from '@/composables/useBranch';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import {TenantBranch, TenantBranchLocation} from '@/types';
import {DocumentType, TemplateData} from '@/store/modules/document';
import {useTemplate} from '@/composables/useTemplate';

const DeleteButton = defineAsyncComponent(() => import('@/components/buttons/DeleteButton.vue'));


const router = useRouter();
const store = useStore();
const {branch, deleteFleetBranch, getFleetBranch, branchLoading} = useBranch();
const {documentTemplates, fetchTemplates} = useTemplate();

//
const currentBranchUuid = router.currentRoute.value.params.uuid as string;
const confirmDelete = ref(false);
const editingLocation = ref(false);
const matchPickupReturnLocationToBranchDialog = ref(false);
const currentWorkingBranch = ref<TenantBranch>({
  active: true,
  short_code: '',
  set: false,
  name: '',
  uuid: '',
  email: '',
  pickup_location: '',
  return_location: '',
  pickup_location_id: '',
  return_location_id: '',
  timezone: '',
  document: null,
  location_id: '',
  location: null,
  order: null,
  document_set: null,
});
const branchClone = ref<TenantBranch>({
  active: true,
  short_code: '',
  set: false,
  name: '',
  uuid: '',
  email: '',
  pickup_location: '',
  return_location: '',
  pickup_location_id: '',
  return_location_id: '',
  timezone: '',
  document: null,
  location_id: '',
  location: null,
  order: null,
  document_set: null,
});
const pickupReturnSame = ref(true);
const pickupReturnSameAsBranch = ref(true);
const editBranch = ref({
  name: false,
  email: false,
  pickup_location: false,
  agreement: false,
  timezone: false,
});

// computed
const missingNewLocationInfo = computed(() => !branchClone.value.location);
const documents = computed(() => {
  if (!documentTemplates?.value) return [];

  const filteredTemplates = documentTemplates.value?.filter((t: TemplateData) => t?.documents);

  return filteredTemplates.map((t: TemplateData) => ({
    name: t?.documents?.name,
    uuid: t?.documents?.uuid,
  }));
});
const document = computed({
  get() {
    return currentWorkingBranch.value.document;
  },
  set(value) {
    currentWorkingBranch.value.document = value;
  },
});

const branchLocation = computed(() => currentWorkingBranch.value?.location);
const branchLocationName = computed(() => branchLocation.value?.name);

const locationStaticMap = computed<string>(() => {
  const {lat, lng} = branchLocation.value;

  const pin = `pin-s+555555(${lng},${lat})`;
  return `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${pin}/${lng},${lat},13,0/300x200?access_token=${import.meta.env.VITE_MAPBOX_TOKEN}`;
});

const deleteFleetBranchInit = () => {
  deleteFleetBranch(branchClone.value.uuid).then(() => {
    router.push({name: 'settings.fleet.branches'}).catch(() => {
    });
  }).catch(() => {
    confirmDelete.value = false;
  });
};

const updateFleetBranch = (branch: TenantBranch) => store.dispatch('fleet/updateFleetBranch', branch).then((updatedBranch) => {
  editingLocation.value = false;
  branchClone.value = JSON.parse(JSON.stringify(updatedBranch));
  currentWorkingBranch.value = branchClone.value;
  currentWorkingBranch.value.set = true;
});

const updateFleetBranchInit = () => {
  if (pickupReturnSameAsBranch.value) {
    currentWorkingBranch.value.pickup_location = currentWorkingBranch.value.location;
    currentWorkingBranch.value.return_location = currentWorkingBranch.value.location;
  }

  updateFleetBranch(currentWorkingBranch.value);
};

watch(branch, (branch) => {
  if (branch?.uuid) {
    branchClone.value = JSON.parse(JSON.stringify(branch));
    pickupReturnSame.value = branchClone.value.pickup_location === branchClone.value.return_location;
    pickupReturnSameAsBranch.value = (branchClone.value.pickup_location_id === branchClone.value.return_location_id
      && branchClone.value.pickup_location_id === branchClone.value.location_id) || (branchClone.value.location_id && !branchClone.value.pickup_location_id && !branchClone.value.return_location_id);
    currentWorkingBranch.value = branchClone.value;
    currentWorkingBranch.value.document = branchClone.value.document;
    currentWorkingBranch.value.set = true;
  }
}, {immediate: true});

const matchPickupReturnLocationsToBranch = () => {
  matchPickupReturnLocationToBranchDialog.value = true;
  currentWorkingBranch.value.pickup_location = currentWorkingBranch.value.location;
  currentWorkingBranch.value.return_location = currentWorkingBranch.value.location;
  pickupReturnSame.value = true;
  pickupReturnSameAsBranch.value = true;
};

const documentName = (document: DocumentType) => {
  return document?.name || 'NA';
};

const addLocationData = (locationData: TenantBranchLocation) => {
  currentWorkingBranch.value.location = locationData;

  if (missingNewLocationInfo.value) {
    matchPickupReturnLocationToBranchDialog.value = true;
  }
};

const changeLocationInit = () => {
  editingLocation.value = !editingLocation.value;
};

onBeforeMount(() => {
  getFleetBranch(currentBranchUuid);
  fetchTemplates();
});

onBeforeUnmount(() => {
  console.dir('onBeforeUnmount');
  store.commit('fleet/SET_COMPANY_BRANCH', {});
  currentWorkingBranch.value = {
    active: true,
    short_code: '',
    set: false,
    name: '',
    uuid: '',
    email: '',
    pickup_location: '',
    return_location: '',
    pickup_location_id: '',
    return_location_id: '',
    timezone: '',
    document: null,
    location_id: '',
    location: null,
    order: null,
    document_set: null,
  };
  branchClone.value = {
    active: true,
    short_code: '',
    set: false,
    name: '',
    uuid: '',
    email: '',
    pickup_location: '',
    return_location: '',
    pickup_location_id: '',
    return_location_id: '',
    timezone: '',
    document: null,
    location_id: '',
    location: null,
    order: null,
    document_set: null,
  };
});

</script>

