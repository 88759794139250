import {useStore} from 'vuex';
import {computed} from 'vue';
import {Coupon, StoreLoaders} from '@/types';

export function useCoupons() {
    const store = useStore();

    const coupons = computed<Coupon[]>(() => store.getters['coupon/coupons']);
    const couponLoading = computed<StoreLoaders>(() => store.getters['coupon/loading']);

    const couponForm = computed(() => store.getters['coupon/form']);
    const couponRedemptions = computed(() => store.getters['coupon/redemptions']);

    const fetchCoupons = async () => {
        return await store.dispatch('coupon/fetchCoupons');
    }

    const clearCoupons = async () => {
        return store.commit('coupon/SET_COUPONS', []);
    }

    const createCoupon = async (coupon: Coupon) => {
        return await store.dispatch('coupon/createCoupon', coupon);
    }
    const updateCoupon = async (coupon: Coupon) => {
        couponForm.value.errors.clear();
        return await store.dispatch('coupon/updateCoupon', coupon);
    }
    const deleteCoupon = async (couponUuid: string) => {
        return await store.dispatch('coupon/deleteCoupon', couponUuid);
    }

    const getCouponRedemptions = async (coupon: string) => {
        return await store.dispatch('coupon/redemptions', coupon);
    }

    const currentCouponReset = async (coupon: Coupon) => {
        return await store.dispatch('coupon/currentCouponReset', coupon);
    }

    const toggleCouponActive = async (coupon: Coupon) => {
        return await store.dispatch('coupon/toggleCouponActive', coupon);
    }

    const multiCouponToggle = async (coupons: Coupon[]) => {
        return await store.dispatch('coupon/multiCouponToggle', coupons);
    }

    const multiCouponDelete = async (coupons: Coupon[]) => {
        return await store.dispatch('coupon/multiCouponDelete', coupons);
    }

    return {
        coupons,
        couponLoading,
        couponForm,
        couponRedemptions,
        fetchCoupons,
        createCoupon,
        updateCoupon,
        deleteCoupon,
        getCouponRedemptions,
        currentCouponReset,
        toggleCouponActive,
        multiCouponToggle,
        multiCouponDelete,
        clearCoupons,
    }
}