<template>
  <div class="CardOnFileCard">
    <v-card style="border: 1px solid rgb(224, 220, 229);" variant="flat">
      <v-card-text>
        <div>
          <div class="text-gray-500 dark:text-gray-400">Card On File</div>
          <div class="mb-4 text-xl font-medium text-gray-900 dark:text-white">
            <span v-if="!!cardLastFour"> **** **** **** {{ cardLastFour }}</span>
            <span v-else>No Card on File</span>
          </div>
          <div>
            <a style="cursor: pointer;color: rgb(37, 98, 212);" @click="fetchSetupUrlInit()">
              Update credit card
            </a>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts" setup>
import {computed} from 'vue';
import {useCompany} from "@/composables/useCompany";
import {useStripe} from "@/composables/useStripe";


const {fetchSetupUrl} = useStripe();
const {company} = useCompany()
const cardLastFour = computed(() => company.value?.pm_last_four);

const fetchSetupUrlInit = () => {
  fetchSetupUrl({
    returnUrl: `${window.location.origin}/settings/account`,
    cancelUrl: `${window.location.origin}/settings/account`,
  }).then((response) => {
    console.dir(response);
    window.location.href = response.url;
  });
}

</script>