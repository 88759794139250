<template>
  <div>
    Provider: {{ profileProvider }} <br>
    UID: {{ profileUid }} <br>
    Created: {{ formatRentalDate(profileCreated) }} <br>
    Active: {{ profileActive }} <br>

    <v-card variant="outlined">
      <v-card-text>
        <v-btn @click="testConnection()">Test Connection</v-btn>
      </v-card-text>

      <div id="dropin-container"></div>
    </v-card>
  </div>
</template>

<script lang="ts">
import {mapActions} from 'vuex';
import {get} from 'lodash';
import {EventBus} from '@/plugins/eventBus';
import {useDateFormatter} from "@/composables/useDate";


export default {
  name: 'SinglePaymentProvider',

  setup() {
    const {formatRentalDate} = useDateFormatter();
    return {
      formatRentalDate,
    };
  },

  data() {
    return {
      paymentProfile: null,
    };
  },

  mounted() {
    this.getPaymentProfile(this.$route.params.uuid);

    EventBus.$on('get-payment-profile-success', (profile) => {
      this.paymentProfile = profile;
    });
    this.addJs();
    // check for braintree js id every second
    // if it exists, then we can load the braintree dropin
    this.checkJs();
  },

  computed: {
    profileProvider() {
      return get(this.paymentProfile, 'provider');
    },
    profileUid() {
      return get(this.paymentProfile, 'uid');
    },
    profileCreated() {
      return get(this.paymentProfile, 'created_at');
    },
    profileActive() {
      return get(this.paymentProfile, 'active');
    },
  },

  methods: {
    ...mapActions({
      getPaymentProfile: 'providers/getPaymentProfile',
      braintreeTestPayment: 'braintree/testPayment',
      squareTestPayment: 'square/testPayment',
    }),
    checkJs() {
      this.braintreeCheckInterval = setInterval(() => {
        console.dir('checking');
        if (document.getElementById('FWBraintreeJS')) {
          clearInterval(this.braintreeCheckInterval);

          braintree.dropin.create({
            authorization: 'sandbox_bnrhnp5x_yytkcsfc4sdbbspv',
            container: '#dropin-container',
          }).then((instance) => {
            console.dir('instance');
            console.dir(instance);
          });
        }
      }, 1000);
    },
    addJs() {
      if (!document.getElementById('FWStripeJS')) {
        const stripeScript = document.createElement('script');
        stripeScript.setAttribute('src', 'https://js.braintreegateway.com/web/dropin/1.34.0/js/dropin.min.js');
        stripeScript.async = true;
        stripeScript.id = 'FWBraintreeJS';
        document.head.appendChild(stripeScript);
      }
    },
    testConnection() {
      console.dir('squareTestPayment');
      this.squareTestPayment();
    },
  },

  beforeUnmount() {
    EventBus.$off('get-payment-profile-success');
  },
};
</script>
