<template>
  <div id="site_toolbar">
    <div class="toolbar_left recipient-selector"></div>
    <div class="toolbar_main text-h6">Fleetwire eSign Editor</div>
    <div class="toolbar_right">
      <div class="mr-4">
        <v-btn
          v-if="company.uuid"
          :to="sampleRoute"
          class="mr-1"
          size="small"
          target="_blank"
        >
          <v-icon class="mr-2" size="small">mdi-file-document-outline</v-icon>
          View Sample
        </v-btn>
      </div>
      <div class="mr-4">
        <v-btn :to="backRoute" class="mr-1" size="small">
          <v-icon class="mr-2" size="small">mdi-arrow-left</v-icon>
          Back
        </v-btn>
      </div>
      <div>
        <v-btn :loading="isSaving" color="primary" size="small" @click="handleSave">
          <v-icon class="mr-2" size="small">mdi-content-save-outline</v-icon>
          Save
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, onMounted, ref} from 'vue';
import {useCompany} from '@/composables/useCompany';
import {store} from '@/store';
import {useRoute} from 'vue-router';

const route = useRoute();

const emit = defineEmits(['toolbarHeight']);

const {company} = useCompany();

const toolbarHeight = ref(0);

const documentLoading = computed(() => store.getters['document/loading']);
const isSaving = computed(() => documentLoading.value.saving);

// Route objects
const sampleRoute = computed(() => ({
  name: 'esign.recipient.v2.sample',
  params: {
    envelope_id: route.params.uuid,
    recipient_id: company.value.uuid,
  },
}));
const backRoute = computed(() => ({name: 'documents.index'}));

const handleSave = () => {
  emit('saveTemplate');
};

onMounted(() => {
  const toolbar = document.getElementById('site_toolbar');
  toolbarHeight.value = toolbar.offsetHeight;
  emit('toolbarHeight', toolbarHeight.value);
});
</script>

<style>
.site-editor #site_toolbar {
  border-bottom: 1px solid #ccc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  min-height: 41px;
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

#site_toolbar .toolbar_main {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 12px;
}

</style>
