<template>
  <nav class="Header-igPdDJ ikpJHJ">
    <div class="BHeader-iqbMU gDKljt">
      <div class="Group-kSMArG jbSlEt">
        <v-btn size="small" variant="outlined">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn size="small" variant="outlined">
          <v-icon class="mr-2" size="small">mdi-file-document-outline</v-icon>
          Templates
        </v-btn>
        <v-btn size="small" variant="outlined">
          <v-icon class="mr-2" size="small">mdi-brush</v-icon>
          Theme settings
        </v-btn>
      </div>
      <div class="Preview-igMYrV fvcbKx">
        <v-btn variant="outlined">
          <div class="flex flex-col">
            <v-icon class="mr-2" size="small">mdi-laptop</v-icon>
            Preview mode
          </div>
        </v-btn>
      </div>
      <div class="Group-kSMArG jbSlEt">
        <v-btn size="small" variant="outlined">
          <v-icon class="mr-2" size="small">mdi-brush</v-icon>
          Preview
        </v-btn>
        <v-btn size="small" variant="outlined">
          <v-icon class="mr-2" size="small">mdi-brush</v-icon>
          Publish
        </v-btn>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
</script>

<style>

.ikpJHJ {
  border-bottom: 1px solid rgb(226, 230, 235);
  height: 62px;
  top: 0;
  position: fixed;
  padding: 0 26px;
  background: rgb(255, 255, 255);
  z-index: 30;
  transition: all 250ms cubic-bezier(0.86, 0, 0.07, 1) 0s;
  overflow: hidden;
  width: 100%
}

.fMkfAf .Header-igPdDJ {
  padding: 0 18px;
}

.gDKljt {
  position: relative;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  height: 99%;
}

.jbSlEt {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.buJHmi {
  display: block;
  width: 100%;
}

.jbSlEt > :not(:last-of-type), .jbSlEt .Disable-iDtbRZ:not(:last-of-type) {
  margin-right: 18px;
}
</style>
