<template>
  <div>
    <v-card class="mb-5" variant="flat">
      <v-card-title>People to Notify</v-card-title>
      <v-card-text>
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div class="border rounded p-4 flex flex-col sm:flex-row items-start sm:items-center">
            <div class="flex-grow">
              <strong>{{ company.name }}</strong>
              <p class="text-sm text-gray-600">{{ company.email }}</p>
              <p class="text-sm text-gray-600">{{ company.phone }}</p>
            </div>
            <div class="flex flex-col sm:flex-row gap-2">
              <v-checkbox v-model="notificationSettings.notify_sms" :disabled="!company.phone" label="SMS" @update:model-value="updateNotificationSettings()"/>
              <v-checkbox v-model="notificationSettings.notify_email" :disabled="!company.email" label="EMAIL" @update:model-value="updateNotificationSettings()"/>
            </div>
          </div>
          <div v-for="employee in employeeNotificationSettings" :key="employee.uuid" class="border rounded p-4 flex flex-col sm:flex-row items-start sm:items-center">
            <div class="flex-grow">
              <strong>{{ employee.name }}</strong>
              <p class="text-sm text-gray-600">{{ employee.email }}</p>
              <p class="text-sm text-gray-600">{{ employee.phone }}</p>
            </div>
            <div class="flex flex-col sm:flex-row gap-2">
              <v-checkbox v-model="employee.notify_sms" :disabled="!employee.phone" label="SMS" @update:model-value="updateEmployeeNotificationSettings(employee)"/>
              <v-checkbox v-model="employee.notify_email" :disabled="!employee.email" label="EMAIL" @update:model-value="updateEmployeeNotificationSettings(employee)"/>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="mb-5" variant="flat">
      <v-card-title>
        Fleetwire
      </v-card-title>
      <v-card-text v-if="notificationSettings">
        <div class="mt-1">
          <div class="grid grid-cols-2">
            <div></div>
            <div class="">
              <span class="mr-md-4 mr-4">SMS</span>
              <span class="mr-md-0 mr-2">EMAIL</span>
            </div>
          </div>
          <div class="">
            <div class="list-group-item">
              <div class="grid grid-cols-2 ">
                <div>
                  <p class="mb-0" style="font-size: 1rem;">
                    Fleetwire Booking
                  </p>
                  <small class="text-gray-700">
                    If you get a direct booking
                  </small>
                </div>
                <div class="col-auto">
                  <div class="notification-checkbox-group">
                    <v-checkbox
                      v-model="notificationSettings.fw_booking_sms"
                      :disabled="settingsLoading.notifications"
                      class="mr-2"
                    />
                    <v-checkbox
                      v-model="notificationSettings.fw_booking_email"
                      :disabled="settingsLoading.notifications"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="list-group list-group-flush">
          <div class="list-group-item">
            <div class="grid grid-cols-2 align-items-center">
              <div>
                <p class="mb-0" style="font-size: 1rem;">
                  Vehicle Maintenance Reminders
                </p>
                <small class="text-gray-700">
                  If you have a vehicle maintenance reminder set up.
                </small>
              </div>
              <div class="col-auto">
                <div class="notification-checkbox-group">
                  <v-checkbox
                    v-model="notificationSettings.vehicle_maintenance_reminder_sms"
                    :disabled="settingsLoading.notifications"
                    class="mr-2"
                  />
                  <v-checkbox
                    v-model="notificationSettings.vehicle_maintenance_reminder_email"
                    :disabled="settingsLoading.notifications"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="list-group list-group-flush">
          <div class="list-group-item">
            <div class="grid grid-cols-2 align-items-center">
              <div>
                <p class="mb-0" style="font-size: 1rem;">
                  Vehicle Recall Notifications
                </p>
                <small class="text-gray-700">
                  If you have a vehicle with a known recall from NHTSA.
                </small>
              </div>
              <div class="col-auto">
                <div class="notification-checkbox-group">
                  <v-checkbox
                    v-model="notificationSettings.vehicle_recall_sms"
                    :disabled="settingsLoading.notifications"
                    class="mr-2"
                  />
                  <v-checkbox
                    v-model="notificationSettings.vehicle_recall_email"
                    :disabled="settingsLoading.notifications"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="list-group list-group-flush">
          <div class="list-group-item">
            <div class="grid grid-cols-2">
              <div class="">
                <p class="mb-0" style="font-size: 1rem;">
                  Renter Invoice Paid
                </p>
                <small class="text-gray-700">
                  When a renter invoice is paid
                </small>
              </div>
              <div class="">
                <div class="notification-checkbox-group">
                  <v-checkbox
                    v-model="notificationSettings.renter_invoice_paid_sms"
                    :disabled="settingsLoading.notifications"
                    class="mr-2"
                  />
                  <v-checkbox
                    v-model="notificationSettings.renter_invoice_paid_email"
                    :disabled="settingsLoading.notifications"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="list-group list-group-flush">
          <div class="list-group-item">
            <div class="grid grid-cols-2">
              <div class="">
                <p class="mb-0" style="font-size: 1rem;">
                  New Toll Transaction
                </p>
                <small class="text-gray-700">
                  When a vehicle receives a new toll
                </small>
              </div>
              <div>
                <div class="notification-checkbox-group">
                  <v-checkbox
                    v-model="notificationSettings.toll_new_transaction_sms"
                    :disabled="settingsLoading.notifications"
                    class="mr-2"
                  />
                  <v-checkbox
                    v-model="notificationSettings.toll_new_transaction_email"
                    :disabled="settingsLoading.notifications"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="mb-5" variant="flat">
      <v-card-title>
        Turo
      </v-card-title>
      <v-card-text v-if="notificationSettings">
        <div class="mt-3">
          <div class="grid grid-cols-2">
            <div></div>
            <div class="float-right pr-md-3 pr-0">
              <span class="mr-md-4 mr-4">SMS</span>
              <span class="mr-md-0 mr-2">EMAIL</span>
            </div>
          </div>
          <div class="list-group list-group-flush">
            <div class="list-group-item">
              <div class="grid grid-cols-2 align-items-center">
                <div>
                  <p class="mb-0 list-title">
                    Turo Error
                  </p>
                  <small class="text-gray-700">
                    If we get a Turo communication error
                  </small>
                </div>
                <div>
                  <div class="notification-checkbox-group">
                    <v-checkbox
                      v-model="notificationSettings.turo_communication_error_sms"
                      :disabled="settingsLoading.notifications"
                      class="mr-2"
                    />
                    <v-checkbox
                      v-model="notificationSettings.turo_communication_error_email"
                      :disabled="settingsLoading.notifications"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="list-group-item">
              <div class="grid grid-cols-2 align-items-center">
                <div class="">
                  <p class="mb-0" style="font-size: 1rem;">
                    Turo Booking
                  </p>
                  <small class="text-gray-700">
                    If we get a Turo booking notification
                  </small>
                </div>
                <div class="col-auto">
                  <div class="notification-checkbox-group">
                    <v-checkbox
                      v-model="notificationSettings.turo_booking_sms"
                      :disabled="settingsLoading.notifications"
                      class="mr-2"
                    />
                    <v-checkbox
                      v-model="notificationSettings.turo_booking_email"
                      :disabled="settingsLoading.notifications"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts" setup>
import {computed, onBeforeMount, ref, watch} from 'vue';
import {useCompany} from '@/composables/useCompany';
import {useStore} from 'vuex';

const {company} = useCompany();

const store = useStore();

const settings = ref({
  turo: {},
  fw: {},
});

const employeeNotificationSettings = ref([]);

const updateNotificationSettings = () => {
  store.dispatch('settings/updateNotificationSettings', settings.value);
};

const updateEmployeeNotificationSettings = (employee) => {
  store.dispatch('company/updateEmployeeNotificationSettings', employee);
};

const companyEmployees = computed(() => store.getters['company/employees']);
const settingsLoading = computed(() => store.getters['settings/loading']);
const notificationSettings = computed(() => store.getters['settings/notifications']);


const getEmployees = () => {
  return store.dispatch('company/getEmployees');
};

onBeforeMount(() => {
  getEmployees().then(() => {
    employeeNotificationSettings.value = companyEmployees.value.map((e) => ({
      type: 'employee',
      uuid: e.uuid,
      name: e.name,
      phone: e.phone,
      email: e.email,
      notify_sms: e.phone ? e?.notificationSettings?.notify_sms : false,
      notify_email: e.email ? e?.notificationSettings?.notify_email : false,
    }));
  });
});

watch(notificationSettings, (notification, notificationOld) => {
  if (notificationOld.set) {
    updateNotificationSettings();
  }
}, {deep: true});
</script>

<style lang="scss">
.v-list-item__action--stack {
  flex-direction: row !important;
}

.v-list-item__action .v-input--checkbox:first-child {
  margin-right: 25px !important;
}

.notification-checkbox-group {
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-width: 24px;
  -webkit-box-orient: horizontal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  align-self: stretch;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  white-space: nowrap;
  -webkit-box-direction: normal;
}

.list-title {
  font-size: 1rem;
}

</style>
