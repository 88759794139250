<template>
  <div>
    <v-dialog v-model="openDialog" :persistent="true" width="500">
      <v-card :disabled="custodianLoading.create">
        <v-card-title class="break-normal">
          {{ mode }} external custodial
        </v-card-title>
        <v-card-text>
          <p class="mb-5">
            Allow others outside your company to manage the orders of the selected
            listings. Whoever accepts the invite link will have control over the
            listings you select below.
            <TheHelpLink url="https://help.fleetwire.io/en/article/custodians-1wu4w2h/?bust=1664293159925"/>
          </p>

          <div v-if="mode === 'Edit'" class="grid grid-cols-4 mb-5">
            <strong>Company:</strong> <span class="col-span-3">{{ childCompanyName }}</span>
            <strong>Company Email:</strong> <span class="col-span-3">{{ childCompanyEmail }}</span>
            <strong>Accepted:</strong> <span class="col-span-3">{{ acceptedAt }}</span>
          </div>

          <ListingAutocompleteDropdown
            :allow-multiple="true"
            :allow-select-all="true"
            label="Listings to manage"
            @selectedListing="listingSelected($event)"
          />


          <div class="d-none">
            <div class="text-h6">Permissions</div>
            <v-checkbox v-model="custodianPermissions.order_view" density="compact" hide-details>
              <template v-slot:label>
                <div>
                  View Listing Orders
                </div>
              </template>
            </v-checkbox>
            <v-checkbox v-model="custodianPermissions.listing_update" density="compact" hide-details>
              <template v-slot:label>
                <div>
                  Update Listing Details
                </div>
              </template>
            </v-checkbox>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="grey" variant="text" @click="openDialog = false">Cancel</v-btn>
          <v-btn v-if="mode === 'Add'" :disabled="nextButtonDisabled" :loading="custodianLoading.create" color="primary" @click="generateLink()">
            Next
          </v-btn>
          <v-btn v-if="mode === 'Edit'" :disabled="nextButtonDisabled" :loading="custodianLoading.update" color="primary" @click="updateCustodianInit()">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="openDialogStep2" width="500">
      <v-card>
        <v-card-title class="break-normal">
          Here is your invite link
        </v-card-title>

        <v-card-text>
          <p>
            Email or Copy & send this link to a custodian you want to
            have access to the listings you selected.
            You can delete or change access at any time.
            <TheHelpLink url="https://help.fleetwire.io/en/article/custodians-1wu4w2h/?bust=1664293159925"/>
          </p>
          <div class="mt-5">
            <v-text-field
              v-model="managerShareEmailLink"
              label="Email Invite Link"
              variant="outlined"
            >
              <template v-slot:append>
                <v-fade-transition :leave-absolute="true">
                  <v-progress-circular
                    v-if="emailLoading"
                    color="info"
                    indeterminate
                    size="24"
                  ></v-progress-circular>
                  <v-icon v-else @click="sendInviteEmailLinkInit()">mdi-send</v-icon>
                </v-fade-transition>
              </template>
            </v-text-field>
          </div>

          <div class="my-5 hr-lr">
            <span>Or</span>
          </div>
          <v-scroll-y-transition>
            <div v-if="showManagerShareLink" class="manager-link-container mt-2">
              <input id="share-link-hidden" :value="managerShareLink" class="d-none">
              <div class="manager-link-field">{{ managerShareLink }}</div>
              <div data-link-only="true">
                <v-btn variant="text" @click="copyToClipboardInit(managerShareLink); copyToClipboardSuccessSnack = true">
                  <v-icon size="small">mdi-content-copy</v-icon>
                </v-btn>
              </div>
            </div>
          </v-scroll-y-transition>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" @click="openDialogStep2 = false">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="copyToClipboardSuccessSnack">
      Copied to clipboard!
      <template v-slot:actions>
        <v-btn variant="text" @click="copyToClipboardSuccessSnack = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import {mapActions, mapGetters} from 'vuex';
import {get} from 'lodash';
import {EventBus} from '@/plugins/eventBus';
import dayjs from 'dayjs';
import {copyTextToClipboard} from '@/helpers';

const ListingAutocompleteDropdown = () => import('@/components/dashboard/listings/ListingAutocompleteDropdown.vue');

export default {
  name: 'ListingAddManagersDialog',

  components: {
    ListingAutocompleteDropdown
  },

  props: {
    custodian: {
      type: Object,
      default: null,
    },
    mode: {
      type: String,
      default: 'Add',
    },
  },

  data() {
    return {
      emailLoading: false,
      openDialog: false,
      openDialogStep2: false,
      copyToClipboardSuccessSnack: false,
      showManagerShareLink: false,
      selectedListings: [],
      managerShareLink: '',
      managerShareEmailLink: '',
      currentWorkingInvite: null,
      custodianPermissions: {
        order_view: true,
        listing_update: true,
      },
    };
  },

  created() {
    EventBus.$on('open-add-listing-manager-dialog', (custodian = null) => {
      if (this.mode === 'Edit') {
        this.getCustodianListings(custodian);
      }

      this.openDialog = true;
      this.fetchListings();
    });
    EventBus.$on('custodial-invite-success', (invite) => {
      this.openDialog = false;

      if (this.mode === 'Add') {
        this.openDialogStep2 = true;
        this.managerShareLink = get(invite, 'invite_url', '');
        this.currentWorkingInvite = invite;
        this.showManagerShareLink = true;
      }

      this.selectedListings = [];
    });

    EventBus.$on('get-custodian-listings-success', (listings) => {
      this.selectedListings = listings;
      EventBus.$emit('set-selected-listings-for-dropdown', listings);
    });
  },

  computed: {
    ...mapGetters({
      fleetResourceListings: 'listing/fleetResourceListings',
      custodianLoading: 'custodian/loading',
      invite: 'custodian/invite',
      custodianListings: 'custodian/listings',
    }),
    childCompanyName() {
      return get(this.custodian, 'company.name', 'na');
    },
    childCompanyEmail() {
      return get(this.custodian, 'company.email', 'na');
    },
    acceptedAt() {
      if (this.custodian && this.custodian.accepted_at) {
        return dayjs(this.custodian.accepted_at).format('MMM D, YYYY h:mm A');
      }
      return 'Not yet accepted';
    },
    allAvailableVehiclesSelected() {
      return this.selectedListings.length === this.fleetResourceListings.length;
    },
    hasSomeVehiclesSelected() {
      return this.selectedListings.length > 0;
    },
    nextButtonDisabled() {
      return !this.hasSomeVehiclesSelected;
    },
    selectedListingIds() {
      return this.selectedListings.map((listing) => listing.l_id);
    },
    icon() {
      if (this.allAvailableVehiclesSelected) return 'mdi-close-box';
      if (this.hasSomeVehiclesSelected) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },

  methods: {
    ...mapActions({
      fetchListings: 'listing/fetchListings',
      createInvite: 'custodian/createInvite',
      updateCustodian: 'custodian/updateCustodian',
      getCustodianListings: 'custodian/getCustodianListings',
      sendInviteEmailLink: 'custodian/sendInviteEmailLink',
    }),
    sendInviteEmailLinkInit() {
      this.emailLoading = true;
      setTimeout(() => {
        this.emailLoading = false;
      }, 2000);
      this.sendInviteEmailLink({
        custodian_uuid: this.currentWorkingInvite.uuid,
        email: this.managerShareEmailLink,
      });
    },
    updateCustodianInit() {
      this.updateCustodian({
        listings: this.selectedListingIds,
        custodian: this.custodian,
      });
    },
    generateLink() {
      this.createInvite({
        listings: this.selectedListingIds,
      });
    },
    listingSelected(listing) {
      this.selectedListings = listing;
    },
    copyToClipboardInit(text) {
      try {
        copyTextToClipboard(text);
        this.copiedText = true;
      } catch (err) {
        console.dir(err);
        // eslint-disable-next-line no-alert
        alert('Oops, unable to copy');
      }
    },
  },

  beforeUnmount() {
    EventBus.$off('open-add-listing-manager-dialog');
    EventBus.$off('custodial-invite-success');
    EventBus.$off('get-custodian-listings-success');
  },
};
</script>

<style>
/*.hr-lr {*/
/*  width: 100%;*/
/*  text-align: center;*/
/*  border-bottom: 1px solid rgba(130, 147, 131, 0.85);*/
/*  line-height: 0.1em;*/
/*  margin: 10px 0 20px;*/
/*}*/
/*.hr-lr span {*/
/*  background:#fff;*/
/*  padding:0 10px;*/
/*}*/

.hr-lr {
  overflow: hidden;
  text-align: center;
}

.hr-lr:before,
.hr-lr:after {
  background-color: #000;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.hr-lr:before {
  right: 0.5em;
  margin-left: -50%;
}

.hr-lr:after {
  left: 0.5em;
  margin-right: -50%;
}

.manager-link-container {
  align-items: center;
  background: #f1f3f4;
  border-radius: 4px;
  color: #5f6368;
  display: flex;
  padding-left: 0.75rem;
}

.manager-link-field {
  letter-spacing: .00625em;
  font-family: Roboto, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: #202124;
  flex-grow: 1;
}
</style>
