import {createStore, Store} from 'vuex';

import affiliate from '@/store/modules/affiliate';
import analytics from '@/store/modules/analytics';
import app from '@/store/modules/app';
import auth from '@/store/modules/auth';
import automations from '@/store/modules/automations';
import axle from '@/store/modules/axle';
import barcode from '@/store/modules/barcode';
import booking from '@/store/modules/booking';
import bouncie from '@/store/modules/bouncie';
import braintree from '@/store/modules/braintree';
import cart from '@/store/modules/cart';
import checkout from '@/store/modules/checkout';
import commissionPartners from '@/store/modules/commission-partners';
import company from '@/store/modules/company';
import coupon from '@/store/modules/coupon';
import custodian from '@/store/modules/custodian';
import customFields from '@/store/modules/custom-fields';
import defaultProperties from '@/store/modules/default-properties';
import document from '@/store/modules/document';
import email from '@/store/modules/email';
import emailDomainSettings from '@/store/modules/email-domain-settings';
import envelope from '@/store/modules/envelope';
import files from '@/store/modules/files';
import fleet from '@/store/modules/fleet';
import getaround from '@/store/modules/getaround';
import hqRentals from '@/store/modules/hq-rentals';
import payments from '@/store/modules/payments';
import settings from '@/store/modules/settings';
import theme from '@/store/modules/theme';
import lang from '@/store/modules/lang';
import listing from '@/store/modules/listing';
import lula from '@/store/modules/lula';
import notes from '@/store/modules/notes';
import outdoorsy from '@/store/modules/outdoorsy';
import permission from '@/store/modules/permission';
import pricing from '@/store/modules/pricing';
import providers from '@/store/modules/providers';
import renters from '@/store/modules/renters';
import square from '@/store/modules/square';
import subscriptions from '@/store/modules/subscriptions';
import superAdmin from '@/store/modules/superAdmin';
import tagsView from '@/store/modules/tags-view';
import taxes from '@/store/modules/taxes';
import maintenance from '@/store/modules/maintenance';
import order from '@/store/modules/order';
import stripe from '@/store/modules/stripe';
import template from '@/store/modules/template';
import tesla from '@/store/modules/tesla';
import tolls from '@/store/modules/tolls';
import tripExtras from '@/store/modules/trip-extras';
import turo from '@/store/modules/turo';
import turoPricing from '@/store/modules/turo-pricing';
import turoResearch from '@/store/modules/turo-research';
import twilio from '@/store/modules/twilio';
import twistedRoad from '@/store/modules/twisted-road';
import vehicles from '@/store/modules/vehicles';
import whatsNew from '@/store/modules/whats-new';
import webhook from '@/store/modules/webhook';
import tenantCheckout from '@/store/modules/tenant-checkout';
import tags from '@/store/modules/tags';
import {RootState} from '@/types/RootState';

export const store: Store<RootState> = createStore({
    modules: {
        axle,
        cart,
        affiliate,
        analytics,
        app,
        auth,
        automations,
        barcode,
        booking,
        bouncie,
        braintree,
        checkout,
        commissionPartners,
        company,
        coupon,
        custodian,
        customFields,
        defaultProperties,
        document,
        email,
        emailDomainSettings,
        envelope,
        files,
        fleet,
        getaround,
        hqRentals,
        lang,
        listing,
        lula,
        maintenance,
        notes,
        order,
        outdoorsy,
        payments,
        permission,
        pricing,
        providers,
        renters,
        settings,
        square,
        theme,
        stripe,
        subscriptions,
        superAdmin,
        tagsView,
        taxes,
        template,
        tenantCheckout,
        tesla,
        tolls,
        tripExtras,
        turo,
        turoPricing,
        turoResearch,
        twilio,
        twistedRoad,
        vehicles,
        webhook,
        whatsNew,
        tags,
    },
    // strict: import.meta.env.MODE === 'development',
});