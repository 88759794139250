import Tr from "@/plugins/i18n/translations";

export default async () => {
    console.log('%c -- MIDDLEWARE -- LOCALE ✅', 'color: white; background: green; padding: 2px; font-weight: bold;');

    try {
        const guessedLocale = Tr.guessDefaultLocale() || Tr.defaultLocale;
        console.log('%c 🌍 Switching Locale:', 'color: cyan; font-weight: bold;', guessedLocale);

        await Tr.switchLanguage(guessedLocale);
    } catch (error) {
        console.error('%c ❌ Locale Middleware Error:', 'color: red; font-weight: bold;', error);
    }
}
