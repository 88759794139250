import axios from 'axios';
import * as types from '../mutation-types';
import {performApiRequest} from "@/store/helpers/vuex-helpers";
import {Note} from "@/types";

export type NotableType = 'order' | 'document' | 'customer' | '*';

export type NewNote = {
    body: string,
    notable_id: string,
    notable_type: string,
}

export type NotesPaginateOptions = {
    page?: number,
    sortBy?: string,
    sortDesc?: boolean[],
    itemsPerPage?: number,
    notable_type?: NotableType,
    notable_id?: string,
}

export interface NoteResponseMeta {
    count: number,
    current_page: number,
    next_page: number,
    per_page: number,
    total: number,
    total_pages: number,
}

export interface NoteResponseData {
    notes: {
        data: Note[],
        meta: NoteResponseMeta,
    }
    notable_type: NotableType,
}

export interface NotesState {
    loading: Record<string, boolean>,
    notes: Note[],
    note: Note,
}

const state: NotesState = {
    loading: {
        notes: false,
        createNote: false,
        deleteNote: false,
    },
    notes: [],
    note: null,
};

const getters = {
    notes: (state: NotesState) => state.notes,
    note: (state: NotesState) => state.note,
    loading: (state: NotesState) => state.loading,
};

const mutations = {
    [types.SET_NOTES_LOADING](state: NotesState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
};

const actions = {


    /**
     * Get all notes.
     */
    async getNotes({commit}, options: NotesPaginateOptions): Promise<NoteResponseData> {
        const {
            page = 1,
            sortBy = 'created_at',
            sortDesc = [true],
            itemsPerPage = 5,
            notable_type = '*',
            notable_id = null,
        } = options;

        const params = new URLSearchParams({
            page: page.toString(),
            sortBy,
            sortDesc: sortDesc.toString(), // Convert array to string
            itemsPerPage: itemsPerPage.toString(),
            notableType: notable_type,
            notable_id: notable_id ? notable_id.toString() : '',
        }).toString();

        const responseData = await performApiRequest(commit, 'SET_NOTES_LOADING', 'notes', () => axios.get(`/v1/notes?${params}`));
        return {
            notes: responseData.notes,
            notable_type,
        };
    },


    /**
     * Create a note.
     */
    async createNote({commit}, note: NewNote) {
        const responseData = await performApiRequest(commit, 'SET_NOTES_LOADING', 'createNote', () => axios.post('/v1/notes', note));
        return responseData.note;
    },


    /**
     * Delete a note.
     * @throws Error
     */
    async deleteNote({commit}, uuid: string) {
        await performApiRequest(commit, 'SET_NOTES_LOADING', 'deleteNote', () => axios.delete(`/v1/notes/${uuid}`));
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
