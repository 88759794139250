<template>
  <div>
    <v-card variant="flat">
      <v-card-title>
        <v-btn :to="{name: 'settings.account.affiliate.index'}" class="mr-2" size="small" variant="text">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        Payouts
        <v-spacer/>
      </v-card-title>
      <v-card-text class="mt-3">
        <div class="mb-5">
          <div class="grid grid-cols-2 gap-2">
            <div>
              <v-card>
                <v-card-title>Past Payouts</v-card-title>
                <v-card-text>{{ formatMoneyDisplayUSD(affiliatePayoutsPast) }}</v-card-text>
              </v-card>
            </div>
            <div>
              <v-card>
                <v-card-title>Future Payouts</v-card-title>
                <v-card-text>{{ formatMoneyDisplayUSD(affiliatePayoutsFuture) }}</v-card-text>
              </v-card>
            </div>
          </div>
        </div>

        <v-data-table
          :headers="headers"
          :items="affiliatePayouts"
          :items-per-page="20"
        >
          <template v-slot:item.id="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.payout="{ item }">
            {{ formatMoneyDisplayUSD(item.payout) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts" setup>
import {onMounted, computed} from 'vue';
import {useMoneyFormatter} from '@/composables/useMoneyFormatter';
import {useStore} from 'vuex';

const store = useStore();

const {formatMoneyDisplayUSD, formatNumberWithCommas} = useMoneyFormatter();

const affiliate = computed(() => store.getters['affiliate/affiliate']);
const affiliatePayouts = computed(() => store.getters['affiliate/affiliatePayouts']);
const affiliatePayoutsPast = computed(() => store.getters['affiliate/affiliatePayoutsPast']);
const affiliatePayoutsFuture = computed(() => store.getters['affiliate/affiliatePayoutsFuture']);

const getAffiliatePayouts = () => {
  store.dispatch('affiliate/getAffiliatePayouts');
};

const headers = [
  {text: '#', value: 'id'},
  {text: 'Subscriptions', value: 'subscriptions'},
  {text: 'Trials', value: 'trials'},
  {text: 'Period Start', value: 'period_start'},
  {text: 'Period End', value: 'period_end'},
  {text: 'Payout', value: 'payout'},
  {text: 'Paid', value: 'payout_at'},
];

onMounted(() => {
  getAffiliatePayouts();
});
</script>

