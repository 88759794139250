import axios from 'axios';
import {get, uniq, uniqBy} from 'lodash';
import dayjs from 'dayjs';
import {EventBus} from '@/plugins/eventBus';
import {StorageService} from '@/services/storage.service';
import * as types from '../mutation-types';
import {Connection} from '@/types';


export type TuroConnection = Connection & {
    turo_uuid?: string;
    turo_id?: string;
    me?: any;
}

export interface TuroState {
    init: boolean;
    loading: Record<string, boolean>;
    credentials: {
        username: string,
        turo_url?: string,
        password: string,
        token: string,
        method: string,
        challenge_code: string,
        grant_type: string,
        mode: string,
        testing?: boolean
    };
    vehicles: any[];
    allImportedVehicles: any[];
    allVehicles: any[];
    allTuroVehicles: any[];
    currentWorkingTrip: any;
    currentWorkingTripMessages: any[];
    currentWorkingTripImages: any[];
    currentWorkingTripImagesComplete: any[];
    trips: any[];
    emails: any[];
    turoMessageSending: boolean;
    turoGoSet: boolean;
    currentTripTuroGo: any;
    connections: TuroConnection[];
    earnings: any[];
    tripHistory: {
        list: any[],
        numPages: number,
    };
    pendingRequests: any[];
    activityFeed: {
        activities: any[],
    };
    reimbursementDetails: any;
    captchaId: string;
    captchaTest: boolean;
    changeRequestSettings: any[];
    feedbackMode: string;
    feedback: {
        pending: any[],
        history: any[],
    };
    feedbackPage: {
        pending: number,
        history: number,
    };
    feedbackNextPage: {
        pending: number,
        history: number,
    };
    feedbackTotal: {
        pending: number,
        history: number,
    };
    feedbackPerPage: {
        pending: number,
        history: number,
    };
    feedbackLastPage: {
        pending: number,
        history: number,
    };
    allTuroMessages: any[];
    allTuroMessagesPaginate: any;
    allTuroMessagesCurrentPage: number;
    allTuroMessagesPages: number;
    emailsPagination: any;
}

const state: TuroState = {
    init: false,

    loading: {
        me: false,
        login: false,
        refresh_token: false,
        logout: false,
        trips: false,
        listings: false,
        emails: false,
        messages: false,
        images: false,
        active: false,
        captchaInit: false,
        submitReimbursement: false,
        submitReimbursementWithdraw: false,
        reimbursementDetails: false,
        removeTuro: false,
        loginProcess: false,
        fetch_unavailability: false,
        create_unavailability: false,
        remove_unavailability: false,
        saving_change_request: false,
        update_feedback: false,
        delete_feedback: false,
        match_with_listing: false,
        earnings: false,
        feedback: false,
        vehicles: false,
    },

    turoMessageSending: false,
    credentials: {
        username: '',
        turo_url: '',
        password: '',
        token: '',
        method: 'general',
        challenge_code: '',
        grant_type: 'password',
        mode: 'SMS',
    },
    vehicles: [],
    allImportedVehicles: [],

    allVehicles: [], // Stored on FW
    allTuroVehicles: [], // Fetched straight from Turo

    currentWorkingTrip: {},
    currentWorkingTripMessages: [],
    currentWorkingTripImages: [],
    currentWorkingTripImagesComplete: [],

    trips: [],

    emails: [],

    turoGoSet: false,
    currentTripTuroGo: {
        odometer: {
            scalar: 0,
            unit: '',
        },
    },

    connections: [],
    earnings: [],
    tripHistory: {
        list: [],
        numPages: 0,
    },
    pendingRequests: [],
    activityFeed: {
        activities: [],
    },
    reimbursementDetails: null,
    captchaId: null,
    captchaTest: false,
    changeRequestSettings: [],
    feedbackMode: null,
    feedback: {
        pending: [],
        history: [],
    },
    feedbackPage: {
        pending: 0,
        history: 0,
    },
    feedbackNextPage: {
        pending: 0,
        history: 0,
    },
    feedbackTotal: {
        pending: 0,
        history: 0,
    },
    feedbackPerPage: {
        pending: 0,
        history: 0,
    },
    feedbackLastPage: {
        pending: 0,
        history: 0,
    },
    allTuroMessages: [],
    allTuroMessagesPaginate: {},
    allTuroMessagesCurrentPage: 0,
    allTuroMessagesPages: 1,
    emailsPagination: {},
};

const getters = {
    turoInit: (state: TuroState) => state.init,
    loading: (state: TuroState) => state.loading,
    credentials: (state: TuroState) => state.credentials,
    trips: (state: TuroState) => state.trips,
    currentWorkingTrip: (state: TuroState) => state.currentWorkingTrip,
    currentWorkingTripMessages: (state: TuroState) => state.currentWorkingTripMessages,
    currentWorkingTripImages: (state: TuroState) => state.currentWorkingTripImages,
    turoMessageSending: (state: TuroState) => state.turoMessageSending,
    allTuroMessages: (state: TuroState) => state.allTuroMessages,
    allTuroMessagesPaginate: (state: TuroState) => state.allTuroMessagesPaginate,
    allTuroMessagesCurrentPage: (state: TuroState) => state.allTuroMessagesCurrentPage,
    emails: (state: TuroState) => state.emails,
    vehicles: (state: TuroState) => state.vehicles,
    allVehicles: (state: TuroState) => state.allVehicles,
    allTuroVehicles: (state: TuroState) => state.allTuroVehicles,
    allImportedVehicles: (state: TuroState) => state.allImportedVehicles,
    turoGoSet: (state: TuroState) => state.turoGoSet,
    currentTripTuroGo: (state: TuroState) => state.currentTripTuroGo,
    connections: (state: TuroState) => state.connections,
    earnings: (state: TuroState) => state.earnings,
    reimbursementDetails: (state: TuroState) => state.reimbursementDetails,
    captchaId: (state: TuroState) => state.captchaId,
    tripHistory: (state: TuroState) => state.tripHistory,
    pendingRequests: (state: TuroState) => state.pendingRequests,
    activityFeed: (state: TuroState) => state.activityFeed,
    changeRequestSettings: (state: TuroState) => state.changeRequestSettings,
    feedback: (state: TuroState) => state.feedback,
    feedbackPage: (state: TuroState) => state.feedbackPage,
    feedbackNextPage: (state: TuroState) => state.feedbackNextPage,
    feedbackMode: (state: TuroState) => state.feedbackMode,
    feedbackTotal: (state: TuroState) => state.feedbackTotal,
    feedbackPerPage: (state: TuroState) => state.feedbackPerPage,
    feedbackLastPage: (state: TuroState) => state.feedbackLastPage,
    emailsPagination: (state: TuroState) => state.emailsPagination,
};

const mutations = {
    [types.SET_INIT](state: TuroState, init) {
        state.init = init;
    },
    [types.SET_TURO_LOADING](state: TuroState, loading) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_VEHICLES](state: TuroState, vehicles) {
        state.vehicles = vehicles;
    },
    [types.SET_ALL_TURO_VEHICLES](state: TuroState, vehicles) {
        state.allTuroVehicles = uniqBy([...state.allTuroVehicles, ...vehicles], (w) => w.id);
    },
    [types.CLEAR_ALL_TURO_VEHICLES](state: TuroState) {
        state.allTuroVehicles = [];
    },
    [types.SET_TURO_EMAILS](state: TuroState, emails) {
        state.emails = emails;
    },
    [types.SET_TURO_EMAILS_PAGINATION](state: TuroState, pagination) {
        state.emailsPagination = pagination;
    },
    [types.RESET_VEHICLES](state: TuroState) {
        state.vehicles = [];
    },
    [types.SET_TURO_EARNINGS](state: TuroState, earnings) {
        state.earnings = earnings;
    },
    [types.SET_TURO_FEEDBACK](state: TuroState, {data, mode}) {
        state.feedback[mode] = uniqBy([...state.feedback[mode], ...data], w => w.uuid);
        state.feedbackMode = mode;
    },
    [types.SET_TURO_FEEDBACK_PAGE](state: TuroState, {page, mode}) {
        state.feedbackPage[mode] = page;
    },
    [types.SET_TURO_FEEDBACK_TOTAL](state: TuroState, {total, mode}) {
        state.feedbackTotal[mode] = total;
    },
    [types.SET_TURO_FEEDBACK_NEXT_PAGE](state: TuroState, {page, mode}) {
        state.feedbackNextPage[mode] = page;
    },
    [types.SET_TURO_FEEDBACK_PER_PAGE](state: TuroState, {per_page, mode}) {
        state.feedbackPerPage[mode] = per_page;
    },
    [types.SET_TURO_FEEDBACK_LAST_PAGE](state: TuroState, {last_page, mode}) {
        state.feedbackLastPage[mode] = last_page;
    },

    [types.SET_TRIPS](state: TuroState, trips) {
        state.init = true;


        state.trips = trips.map(trip => ({
            key: trip.reservationId,
            renter: trip.actor.firstName,
            listing: trip.vehicle.name,
            time: dayjs(trip.itemTimestamp).format('ddd MMM DD @ H:mm a'),
            make: trip.vehicle.make,
            model: trip.vehicle.model,
            trim: trip.vehicle.trim,
            style: trip.vehicle.style,
            vin: trip.vehicle.vin,
            vin4: trip.vehicle.vin.substring(trip.vehicle.vin.length - 4),
            year: trip.vehicle.year,
            address: trip.address,
            inProgress: trip.inProgress,
            reservationId: trip.reservationId,
            host: trip.host,
            upcomingTripFeedItemType: trip.upcomingTripFeedItemType,
            vehicle: trip.vehicle,
            startF: dayjs(trip.startF).format('ddd MMM DD @ H:mm a'),
            endF: dayjs(trip.endF).format('ddd MMM DD @ H:mm a'),
        }));
    },
    [types.SET_TRIP_SINGLE](state: TuroState, trip) {
        state.init = true;
        state.currentWorkingTrip = trip;
    },

    [types.SET_TRIP_SINGLE_MESSAGES](state: TuroState, messages) {
        state.currentWorkingTripMessages = messages;
    },
    [types.SET_TRIP_SINGLE_MESSAGES_STORED](state: TuroState, messages) {
        state.currentWorkingTripMessages = messages;
    },
    [types.SET_TRIP_SINGLE_IMAGES](state: TuroState, images) {
        state.currentWorkingTripImages = images;
    },
    [types.SET_TURO_MESSAGE_SENDING](state: TuroState, sending) {
        state.turoMessageSending = sending;
    },
    [types.SET_TURO_ALL_VEHICLES](state: TuroState, vehicles) {
        state.allVehicles = vehicles;
    },
    [types.SET_ALL_IMPORTED_TURO_VEHICLES](state: TuroState, vehicles) {
        state.allImportedVehicles = vehicles;
    },
    [types.SET_TRIP_TURO_GO](state: TuroState, data) {
        state.currentTripTuroGo = data;
        state.turoGoSet = true;
    },
    [types.SET_TURO_CONNECTIONS](state: TuroState, connections) {
        state.connections = connections;
    },
    [types.SET_TURO_REIMBURSEMENT_DETAILS](state: TuroState, details) {
        state.reimbursementDetails = details;
    },
    [types.RESET_TURO_CREDS](state: TuroState) {
        state.credentials = {
            username: '',
            password: '',
            token: '',
            method: 'general',
            challenge_code: '',
            grant_type: 'password',
            mode: 'SMS',
        };
    },
    [types.SET_TURO_CAPTCHA_ID](state: TuroState, id) {
        state.captchaId = id;
    },
    [types.SET_TURO_CAPTCHA_TESTING](state: TuroState, testing) {
        state.captchaTest = testing;
    },
    [types.SET_TRIP_HISTORY](state: TuroState, history) {
        state.tripHistory = history;
    },
    [types.SET_TRIP_PENDING_REQUESTS](state: TuroState, requests) {
        state.pendingRequests = requests;
    },
    [types.SET_ACTIVITY_FEED](state: TuroState, activity) {
        state.activityFeed = activity;
    },
    [types.SET_TURO_CHANGE_REQUEST_SETTINGS](state: TuroState, settings) {
        state.changeRequestSettings = settings;
    },
    [types.SET_ALL_TURO_MESSAGES](state: TuroState, messages) {
        state.allTuroMessages = uniqBy([...state.allTuroMessages, ...messages], w => w.reservation.id);
    },
    [types.SET_ALL_TURO_MESSAGES_CURRENT_PAGINATE](state: TuroState, paginate) {
        state.allTuroMessagesPaginate = paginate;
    },
    [types.SET_ALL_TURO_MESSAGES_TOTAL_PAGES](state: TuroState, pages) {
        state.allTuroMessagesPages = pages;
    },
};

const actions = {

    createTuroConnection({state, commit}) {
        if (state.captchaTest) state.credentials.testing = true;
        if (!state.loading.login) {
            commit('SET_TURO_LOADING', {subject: 'login', loading: true});

            axios.post('/v1/connections/turo', state.credentials)
                .then(response => {
                    commit('turo/SET_TURO_CONNECTIONS', get(response, 'data.company.connections.turo', []), {root: true});
                    EventBus.$emit('create-turo-connection-success');
                    EventBus.$emit('SystemMessage', {
                        type: 'success',
                        message: 'Turo connection added successfully',
                    });
                    commit('RESET_TURO_CREDS');
                }).catch(e => {
                console.dir(e);
                if (e.response.status === 400 || e.response.status === 403) {
                    EventBus.$emit('create-turo-connection-failed-400', e.response.data.message);
                }

                if (e.response.status === 412) {
                    EventBus.$emit('create-turo-connection-failed-412', get(e, 'response.data'));
                }

                if (e.response.status === 422) {
                    EventBus.$emit('create-turo-connection-failed-400', e.response.data.message);
                }
            }).finally(() => {
                commit('SET_TURO_LOADING', {subject: 'login', loading: false});
                commit('SET_TURO_LOADING', {subject: 'loginProcess', loading: false});
            });
        }
    },

    sendSecurityChallengeCode({state: TuroState, dispatch}, data) {
        axios.post('/v1/connections/turo/code-challenge-init', data)
            .then(() => {
                EventBus.$emit('turo-code-challenge-send-success');
            }).catch(() => {
        });
    },


    /**
     * Refresh a Turo connection
     *
     * @param commit
     * @param {Object} turo
     */
    turoRefreshToken({commit}, turo) {
        commit('SET_TURO_LOADING', {subject: 'refresh_token', loading: true});

        axios.post(`/v1/connections/turo/${turo.uuid}/refresh-token`, turo)
            .then(response => {
                EventBus.$emit('turo-code-challenge-send-success');
                EventBus.$emit('turo-refresh-token-success');
                EventBus.$emit('create-turo-connection-success');
                commit('turo/SET_TURO_CONNECTIONS', get(response, 'data.company.connections.turo', []), {root: true});
            }).catch(e => {
            if (e.response.status === 422 || e.response.status === 500) {
                if (get(e, 'response.data.code') === 'invalid_refresh_token') {
                }
                EventBus.$emit('turo-refresh-token-failed');
            }
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'refresh_token', loading: false});
        });
    },

    getFeedback({state, commit}, options) {
        commit('SET_TURO_LOADING', {subject: 'feedback', loading: true});

        const mode = get(options, 'mode', false);
        const nextPage = state.feedbackNextPage[mode]++;
        let query = `?page=${nextPage}`;
        const history = mode === 'history';
        const pending = mode === 'pending';

        if (history) {
            query = `${query}&history=${history}`;
        }
        if (pending) {
            query = `${query}&pending=${pending}`;
        }


        axios.get(`/v1/connections/turo/feedback${query}`)
            .then(response => {
                commit('SET_TURO_FEEDBACK', {
                    data: get(response, 'data.feedback.data', []),
                    mode,
                });
                commit('SET_TURO_FEEDBACK_PAGE', {
                    page: get(response, 'data.feedback.current_page', 1),
                    mode,
                });
                commit('SET_TURO_FEEDBACK_NEXT_PAGE', {
                    page: get(response, 'data.feedback.current_page', 1) + 1,
                    mode,
                });
                commit('SET_TURO_FEEDBACK_TOTAL', {
                    total: get(response, 'data.feedback.total'),
                    mode,
                });
                commit('SET_TURO_FEEDBACK_PER_PAGE', {
                    per_page: get(response, 'data.feedback.per_page'),
                    mode,
                });
                commit('SET_TURO_FEEDBACK_LAST_PAGE', {
                    last_page: get(response, 'data.feedback.last_page'),
                    mode,
                });
            }).catch(() => {
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'feedback', loading: false});
        });
    },

    updateFeedback({state, commit, rootGetters}, feedback) {
        commit('SET_TURO_LOADING', {subject: 'update_feedback', loading: true});

        axios.put(`/v1/company/${rootGetters['company/company'].uuid}/connections/turo/feedback/${feedback.uuid}`, feedback)
            .then(response => {
                commit('SET_TURO_FEEDBACK', get(response, 'data.feedback', []));
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Auto feedback updated successfully',
                });
            }).catch(() => {
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'update_feedback', loading: false});
        });
    },

    sendFeedback({state, commit, rootGetters}, feedback) {
        commit('SET_TURO_LOADING', {subject: 'update_feedback', loading: true});

        axios.post(`/v1/company/${rootGetters['company/company'].uuid}/connections/turo/feedback/${feedback.uuid}/send`, feedback)
            .then(response => {
                EventBus.$emit('turo-auto-feedback-send-success');
                commit('SET_TURO_FEEDBACK', get(response, 'data.feedback', []));
            }).catch(e => {
            EventBus.$emit('turo-auto-feedback-send-error');
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
            commit('SET_TURO_FEEDBACK', get(e, 'response.data.feedback', []));
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'update_feedback', loading: false});
        });
    },

    deleteFeedback({state, commit, rootGetters}, feedback) {
        commit('SET_TURO_LOADING', {subject: 'delete_feedback', loading: true});

        axios.delete(`/v1/company/${rootGetters['company/company'].uuid}/connections/turo/feedback/${feedback.uuid}`)
            .then(response => {
                commit('SET_TURO_FEEDBACK', get(response, 'data.feedback', []));
                EventBus.$emit('turo-auto-feedback-deleted-success');
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Auto feedback deleted successfully',
                });
            }).catch(() => {
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'delete_feedback', loading: false});
        });
    },

    getFeedbackSettings({commit}) {
        commit('SET_TURO_LOADING', {subject: 'active', loading: true});

        axios.get('/v1/connections/turo/feedback-settings')
            .then(response => {
                commit('SET_TURO_CONNECTIONS', get(response, 'data.turoAccounts', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'active', loading: false});
        });
    },

    updateFeedbackSettings({state, commit}, settings) {
        commit('SET_TURO_LOADING', {subject: 'active', loading: true});

        axios.put(`/v1/connections/turo/feedback-settings/${settings.uuid}`, settings)
            .then(response => {
                commit('SET_TURO_FEEDBACK', get(response, 'data.feedback', []));
                commit('company/SET_FEEDBACK_REVIEWS', get(response, 'data.feedbackReviews'), {root: true});
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Turo updated successfully',
                });
            }).catch(() => {
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'active', loading: false});
        });
    },

    /**
     * Toggle a Turo connections active state.
     */
    toggleTuroActive({state: TuroState, commit}, turo: TuroConnection) {
        commit('SET_TURO_LOADING', {subject: 'active', loading: true});

        const connIndex = state.connections.findIndex(c => turo.uuid === c.uuid);

        if (connIndex !== -1) {
            state.connections[connIndex].loading = true;
        }

        axios.put(`/v1/connections/turo/${turo.uuid}/toggle`)
            .then((response) => {
                EventBus.$emit('toggle-turo-active-success', get(response, 'data.turo', []));

                if (turo.active) {
                    EventBus.$emit('toggle-turo-connection-success', turo);
                }
                if (connIndex !== -1) {
                    state.connections[connIndex].connected = turo.active;
                }
            }).catch(e => {
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'active', loading: false});
            if (connIndex !== -1) {
                state.connections[connIndex].loading = false;
            }
        });
    },


    /**
     * Delete a Turo connection
     *
     * @param commit
     * @param {Object} turo
     */
    removeTuroConnection({commit}, turo) {
        commit('SET_TURO_LOADING', {subject: 'removeTuro', loading: true});

        axios.delete(`/v1/connections/turo/${turo.uuid}`)
            .then(response => {
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Turo connection removed successfully',
                });
                EventBus.$emit('remove-turo-connection-success');
                commit('SET_TURO_CONNECTIONS', get(response, 'data.connections.turo', []));
            }).catch(e => {
            console.dir(e);
            if (e.response.status === 400 || e.response.status === 403) {
                EventBus.$emit('create-turo-connection-failed-400', e.response.data.message);
            }
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'removeTuro', loading: false});
        });
    },

    /**
     * Fetch Turo /me
     */
    fetchTuroMe({state: TuroState, commit}, turo_uuid: string) {
        commit('SET_TURO_LOADING', {subject: 'login', loading: true});
        commit('SET_TURO_LOADING', {subject: 'listings', loading: true});
        commit('SET_TURO_LOADING', {subject: 'me', loading: true});

        let connIndex = state.connections.findIndex(c => turo_uuid === c.uuid);

        if (connIndex === -1) {
            connIndex = state.connections.push({
                turo_uuid,
                loading: true,
                connected: true,
                active: true,
                email: '',
                provider: 'turo',
            });
            connIndex--;
        }

        state.connections[connIndex].loading = true;

        axios.get(`/v1/connections/turo/${turo_uuid}/me?updateVehicles=1`)
            .then(response => {
                commit('SET_VEHICLES', response.data.me.vehicles);
                state.connections[connIndex].connected = true;
                state.connections[connIndex].me = get(response, 'data.me');
                EventBus.$emit('turo-me-success', {
                    me: response.data.me,
                    turo_uuid,
                });
            }).catch(e => {
            if (e.response.status === 400 || e.response.status === 403) {
                EventBus.$emit('create-turo-connection-failed-400', e.response.data.message);
            } else if (e.response.status === 428) {
                EventBus.$emit('create-turo-connection-failed-428', {
                    message: e.response.data.message,
                    turo_uuid,
                });
            } else if (e.response.status === 422) {
                EventBus.$emit('create-turo-connection-failed-422', {
                    message: get(e.response, 'data.error'),
                    turo_uuid,
                });
            }
            state.connections[connIndex].connected = false;
            state.connections[connIndex].me = null;
            state.connections[connIndex].loading = false;
        }).finally(() => {
            state.connections[connIndex].loading = false;
            commit('SET_TURO_LOADING', {subject: 'login', loading: false});
            commit('SET_TURO_LOADING', {subject: 'listings', loading: false});
            commit('SET_TURO_LOADING', {subject: 'me', loading: false});
        });
    },

    /**
     * Fetch Turo Vehicles
     *
     * @param state
     * @param commit
     * @param {Array} turo_uuids The Turo uuids to include
     */
    fetchTuroVehicles({state, commit}, turo_uuids) {
        commit('SET_TURO_LOADING', {subject: 'vehicles', loading: true});

        const ids = uniq(turo_uuids).join();

        axios.get(`/v1/connections/turo/fetch-vehicles?turo_ids=${ids}`)
            .then(response => {
                commit('SET_ALL_TURO_VEHICLES', get(response, 'data.vehicles'));
                EventBus.$emit('turo-fetch-vehicles-success', turo_uuids);
            }).catch(e => {
            console.dir(e);
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'vehicles', loading: false});
        });
    },

    getTuroVehicles({state, commit}, turo_uuids) {
        commit('SET_TURO_LOADING', {subject: 'vehicles', loading: true});

        const ids = uniq(turo_uuids).join();

        axios.get(`/v1/connections/turo/vehicles?turo_ids=${ids}`)
            .then(response => {
                commit('SET_ALL_TURO_VEHICLES', get(response, 'data.vehicles'));
                EventBus.$emit('turo-vehicles-success', turo_uuids);
            }).catch(e => {
            console.dir(e);
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'vehicles', loading: false});
        });
    },

    /**
     * Match a Turo vehicle with a Fleetwire listing
     *
     * @param commit
     * @param {Object} data
     */
    matchVehicleWithFleetwireListing({commit}, data) {
        commit('SET_TURO_LOADING', {subject: 'match_with_listing', loading: true});

        axios.post('/v1/connections/turo/match-with-listing', data)
            .then(() => {
                EventBus.$emit('match-turo-vehicle-with-listing-success');
            }).catch(e => {
            console.dir(e);
            EventBus.$emit('match-turo-vehicle-with-listing-error', get(e, 'response'));
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'match_with_listing', loading: false});
        });
    },


    fetchAllTuroVehicles({commit}) {
        commit('SET_TURO_LOADING', {subject: 'login', loading: true});
        commit('SET_TURO_LOADING', {subject: 'listings', loading: true});

        axios.get('/v1/connections/turo/all/vehicles')
            .then(response => {
                commit('SET_TURO_ALL_VEHICLES', response.data.vehicles);
            }).catch(e => {
            console.dir(e);
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'login', loading: false});
            commit('SET_TURO_LOADING', {subject: 'listings', loading: false});
        });
    },

    /**
     * Adjust a vehicle unavailability
     *
     * @param state
     * @param commit
     * @param {Object} accounts
     */
    fetchMyTaxEarnings({state, commit}, accounts) {
        commit('SET_TURO_LOADING', {subject: 'earnings', loading: true});

        const qString = accounts.join(',');
        axios.get(`/v1/connections/turo/tax-earnings?turo_uuids=${qString}`)
            .then(response => {
                commit('SET_TURO_EARNINGS', get(response, 'data.earnings', []));
            }).catch(e => {
            console.dir(e);
            if (e.response.status === 400 || e.response.status === 403) {
                EventBus.$emit('create-turo-connection-failed-400', e.response.data.message);
            }
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'earnings', loading: false});
        });
    },


    /**
     * Re-fetch Turo earnings
     *
     * @param state
     * @param commit
     * @param {String} turo_uuid
     */
    refreshAccountEarnings({state, commit}, turo_uuid) {
        commit('SET_TURO_LOADING', {subject: 'earnings', loading: true});

        axios.get(`/v1/connections/turo/${turo_uuid}/tax-earnings`)
            .then(() => {
                EventBus.$emit('refresh-turo-earnings-success');
            }).catch(e => {
            console.dir(e);
            if (e.response.status === 400 || e.response.status === 403) {
                EventBus.$emit('create-turo-connection-failed-400', e.response.data.message);
            }
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'earnings', loading: false});
        });
    },

    checkPulse({state, commit, rootGetters}, turo_uuid) {
        commit('SET_TURO_LOADING', {subject: 'login', loading: true});
        axios.get(`/v1/company/${rootGetters['company/company'].uuid}/connections/turo/${turo_uuid}/me`)
            .catch(e => {
                console.dir(e);
                if (e.response.status === 400 || e.response.status === 403) {
                    EventBus.$emit('create-turo-connection-failed-400', e.response.data.message);
                }
            }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'login', loading: false});
        });
    },

    fetchTuroGoTrip({state, commit, rootGetters}, {turo_uuid, vehicle_id}) {
        commit('SET_TURO_LOADING', {subject: 'trips', loading: true});
        axios.get(`/v1/company/${rootGetters['company/company'].uuid}/connections/turo/${turo_uuid}/${vehicle_id}/turo-go`)
            .then(response => {
                commit('SET_TRIP_TURO_GO', get(response, 'data.turoGo'));
            }).catch(e => {
            console.dir(e);
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'trips', loading: false});
        });
    },

    /**
     * Fetch a vehicle unavailability on Turo
     *
     * @param commit
     * @param {String} turo_uuid
     * @param {String} vehicle_uuid
     */
    fetchVehicleUnavailability({commit}, {turo_uuid, vehicle_uuid}) {
        commit('SET_TURO_LOADING', {subject: 'trips', loading: true});

        axios.get(`/v1/connections/turo/${turo_uuid}/vehicle/${vehicle_uuid}/unavailability`)
            .then(() => {
                // commit('SET_TRIPS', get(response, 'data.trips'));
                // commit('listing/SET_CURRENT_LISTING_BOOKINGS', get(response, 'data.bookings', []), { root: true });
            }).catch(e => {
            if (e.response.status === 400 || e.response.status === 403) {
                EventBus.$emit('create-turo-connection-failed-400', e.response.data.message);
            }
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'trips', loading: false});
        });
    },

    /**
     * Create a vehicle unavailability on Turo
     *
     * @param state
     * @param commit
     * @param {Object} data
     */
    createVehicleUnavailability({state, commit}, data) {
        commit('SET_TURO_LOADING', {subject: 'create_unavailability', loading: true});

        axios.post(`/v1/connections/turo/${data.turo_uuid}/vehicle/${data.vehicle_uuid}/unavailability`, data)
            .then(response => {
                EventBus.$emit('create-turo-unavailability', response.data);
                // commit('booking/SET_CURRENT_WORKING_BOOKING', get(response, 'data.booking'), { root: true });
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Turo vehicle unavailability period created successfully',
                });
            }).catch(e => {
            console.dir(e);
            if (e.response.status === 400 || e.response.status === 403) {
                EventBus.$emit('create-turo-unavailability-failed-400', e.response.data);
            }
            if (e.response.status === 422) {
                EventBus.$emit('create-turo-unavailability-failed-422', e.response.data);
            }
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'create_unavailability', loading: false});
        });
    },

    removeVehicleUnavailability({state, commit, rootGetters}, data) {
        commit('SET_TURO_LOADING', {subject: 'remove_unavailability', loading: true});

        axios.delete(`/v1/company/${rootGetters['company/company'].uuid}/connections/turo/${data.turo_uuid}/vehicle/${data.vehicle_uuid}/unavailability?reservationId=${data.reservationId}`)
            .then(() => {
                EventBus.$emit('remove-turo-unavailability', data.reservationId);
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Turo vehicle unavailability period removed successfully',
                });
            }).catch(e => {
            if (e.response.status === 400 || e.response.status === 403) {
                EventBus.$emit('remove-turo-unavailability-failed-400', e.response.data);
            }
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'remove_unavailability', loading: false});
        });
    },

    /**
     * Adjust a vehicle unavailability
     *
     * @param state
     * @param commit
     * @param {Object} data
     */
    removeVehicleUnavailabilityAndAdjust({state, commit}, data) {
        commit('SET_TURO_LOADING', {subject: 'remove_unavailability', loading: true});

        axios.post(`/v1/company/${StorageService.getCompanyUuid()}/connections/turo/${data.turo_uuid}/vehicle/${data.vehicle_uuid}/unavailability/adjust`, data)
            .then(response => {
                commit('booking/SET_CURRENT_WORKING_BOOKING', response.data.booking, {root: true});
                EventBus.$emit('remove-turo-unavailability-adjust-success', data);
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Turo vehicle unavailability period removed successfully',
                });
            }).catch(e => {
            if (e.response.status === 400 || e.response.status === 403) {
                EventBus.$emit('remove-turo-unavailability-failed-400', e.response.data);
            }
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'remove_unavailability', loading: false});
        });
    },

    fetchTripHistory({state, commit, rootGetters}, turo_uuid) {
        commit('SET_TURO_LOADING', {subject: 'trips', loading: true});
        axios.get(`/v1/connections/turo/${turo_uuid}/trips/history`)
            .then(response => {
                commit('SET_TRIP_HISTORY', get(response, 'data.history', []));
            }).catch(e => {
            if (e.response.status === 400 || e.response.status === 403) {
                EventBus.$emit('fetch-turo-trip-single-failed', e.response.data.message);
            }
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'trips', loading: false});
            state.init = true;
        });
    },

    fetchPendingRequests({state, commit, rootGetters}, turo_uuid) {
        commit('SET_TURO_LOADING', {subject: 'trips', loading: true});
        axios.get(`/v1/connections/turo/${turo_uuid}/trips/pending-requests`)
            .then(response => {
                commit('SET_TRIP_PENDING_REQUESTS', get(response, 'data.requests', []));
            }).catch(e => {
            if (e.response.status === 400 || e.response.status === 403) {
                EventBus.$emit('fetch-turo-trip-single-failed', e.response.data.message);
            }
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'trips', loading: false});
            state.init = true;
        });
    },

    fetchActivityFeed({state, commit, rootGetters}, turo_uuid) {
        commit('SET_TURO_LOADING', {subject: 'trips', loading: true});

        axios.get(`/v1/connections/turo/${turo_uuid}/trips/activity-feed`)
            .then(response => {
                commit('SET_ACTIVITY_FEED', get(response, 'data.activity', []));
            }).catch(e => {
            if (e.response.status === 400 || e.response.status === 403) {
                EventBus.$emit('fetch-turo-trip-single-failed', e.response.data.message);
            }
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'trips', loading: false});
            state.init = true;
        });
    },

    fetchTuroTripSingle({state, commit}, data) {
        commit('SET_TURO_LOADING', {subject: 'trips', loading: true});

        axios.get(`/v1/connections/turo-trips/${data.tripId}?turoId=${data.turoId}`)
            .then(response => {
                commit('SET_TRIP_SINGLE', get(response, 'data.trip'));
                EventBus.$emit('turo-fetch-sing-trip-success', get(response, 'data.trip'));
            }).catch(e => {
            if (e.response.status === 400 || e.response.status === 403) {
                EventBus.$emit('fetch-turo-trip-single-failed', e.response.data.message);
            }
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'trips', loading: false});
            state.init = true;
        });
    },

    /**
     * Fetch a Turo trip messages
     *
     * @param commit
     * @param {Object} data
     */
    fetchTuroTripSingleMessages({commit}, data) {
        commit('SET_TURO_LOADING', {subject: 'messages', loading: true});

        axios.get(`/v1/connections/turo-trips/${data.tripId}/messages?account_id=${data.accountId}`)
            .then(response => {
                commit('SET_TRIP_SINGLE_MESSAGES', response.data.messages);
            }).catch(e => {
            if (e.response.status === 400 || e.response.status === 403) {
                EventBus.$emit('create-turo-connection-failed-400', e.response.data.message);
            }
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'messages', loading: false});
        });
    },

    getStoredTuroTripSingleMessages({commit}, tripId) {
        commit('SET_TURO_LOADING', {subject: 'messages', loading: true});

        axios.get(`/v1/connections/turo-trips/${tripId}/stored-messages`)
            .then(response => {
                commit('SET_TRIP_SINGLE_MESSAGES_STORED', response.data.messages);
            }).catch(e => {
            console.dir(e);
            if (e.response.status === 400 || e.response.status === 403) {
                EventBus.$emit('create-turo-connection-failed-400', e.response.data.message);
            }
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'messages', loading: false});
        });
    },


    /**
     * Fetch a Turo trips images
     *
     * @param commit
     * @param {String} tripId
     */
    fetchTuroTripSingleImages({commit}, tripId) {
        commit('SET_TURO_LOADING', {subject: 'images', loading: true});

        axios.get(`/v1/connections/turo-trips/${tripId}/images`)
            .then(response => {
                commit('SET_TRIP_SINGLE_IMAGES', get(response, 'data.images'));
            }).catch(e => {
            console.dir(e);
            if (e.response.status === 400 || e.response.status === 403) {
                EventBus.$emit('fetch-turo-images-failed-400', e.response.data.message);
            }
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'images', loading: false});
        });
    },

    /**
     * Get Turo messages
     *
     * @param state
     * @param commit
     * @param {Object} data
     */
    getTuroConversations({state, commit}, data) {
        commit('SET_TURO_LOADING', {subject: 'messages', loading: true});

        if (!get(state.allTuroMessagesCurrentPage, data.turoId)) {
            state.allTuroMessagesCurrentPage = {
                ...state.allTuroMessagesCurrentPage,
                [data.turoId]: {page: 0, totalPages: 1}
            };
        }
        state.allTuroMessagesCurrentPage[data.turoId].page += 1;

        const qString = `page=${state.allTuroMessagesCurrentPage[data.turoId].page}`;

        axios.get(`/v1/connections/turo/${data.turoUuid}/messages?${qString}`)
            .then(response => {
                commit('SET_ALL_TURO_MESSAGES', get(response, 'data.conversations.list', []));
                commit('SET_ALL_TURO_MESSAGES_CURRENT_PAGINATE', get(response, 'data.conversations.numPages', 1));
                state.allTuroMessagesCurrentPage[data.turoId].totalPages = get(response, 'data.conversations.numPages', 1);
                commit('SET_ALL_TURO_MESSAGES_TOTAL_PAGES', get(response, 'data.conversations.numPages', 1));
            }).catch(e => {
            console.dir(e);
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'messages', loading: false});
            commit('SET_TURO_MESSAGE_SENDING', false);
        });
    },

    /**
     * Send a message to a renter
     *
     * @param state
     * @param commit
     * @param {String} tripId
     * @param {String} message
     */
    sendTuroMessage({state, commit}, {tripId, message}) {
        commit('SET_TURO_LOADING', {subject: 'trips', loading: true});
        commit('SET_TURO_MESSAGE_SENDING', true);

        axios.post(`/v1/company/${StorageService.getCompanyUuid()}/connections/turo-trips/${tripId}/message`, {message})
            .then(response => {
                commit('SET_TRIP_SINGLE_MESSAGES', response.data.messages);
                EventBus.$emit('send-turo-message-success');
            }).catch(e => {
            console.dir(e);
            if (e.response.status === 400 || e.response.status === 403) {
                EventBus.$emit('create-turo-connection-failed-400', e.response.data.message);
            }
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'trips', loading: false});
            commit('SET_TURO_MESSAGE_SENDING', false);
        });
    },

    fetchTuroEmails({commit}, paginate: {
        page?: number,
        itemsPerPage?: number,
        sortBy?: string,
        sortDesc?: boolean
    } = {}) {
        commit('SET_TURO_LOADING', {subject: 'emails', loading: true});

        if (paginate.page === undefined) {
            paginate.page = 1;
        }

        if (paginate.itemsPerPage === undefined) {
            paginate.itemsPerPage = 10;
        }
        if (paginate.sortBy === undefined) {
            paginate.sortBy = 'created_at';
        }
        if (paginate.sortDesc === undefined) {
            paginate.sortDesc = true;
        }

        axios.get(`/v1/connections/turo/emails?page=${paginate.page}&perPage=${paginate.itemsPerPage}&sortBy=${paginate.sortBy}&sortDesc=${paginate.sortDesc}`)
            .then(response => {
                const emails = response.data.data;
                const pagination = response.data.meta;

                commit('SET_TURO_EMAILS', emails);
                commit('SET_TURO_EMAILS_PAGINATION', pagination);
            }).catch(e => {
            console.dir(e);
            if (e.response.status === 400 || e.response.status === 403) {
                EventBus.$emit('create-turo-connection-failed-400', e.response.data.message);
            }
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'emails', loading: false});
        });
    },

    submitReimbursement({state, commit, rootGetters}, data) {
        commit('SET_TURO_LOADING', {subject: 'submitReimbursement', loading: true});
        axios.post(`/v1/company/${rootGetters['company/company'].uuid}/connections/turo/${data.turo_uuid}/submit-reimbursement`, data)
            .then(() => {
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Submit reimbursement request successfully',
                });
                EventBus.$emit('submit-turo-reimbursement-success');
            }).catch(e => {
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'submitReimbursement', loading: false});
        });
    },

    getTuroChangeRequestSettings({commit}) {
        commit('SET_TURO_LOADING', {subject: 'submitReimbursement', loading: true});

        axios.get('/v1/connections/turo/change-request-settings')
            .then(response => {
                commit('SET_TURO_CHANGE_REQUEST_SETTINGS', get(response, 'data.changeRequestSettings', []));
            }).catch(e => {
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'submitReimbursement', loading: false});
        });
    },

    saveTuroChangeRequestSettings({commit}, data) {
        commit('SET_TURO_LOADING', {subject: 'saving_change_request', loading: true});

        axios.post('/v1/connections/turo/change-request-settings', data)
            .then(response => {
                commit('SET_TURO_CHANGE_REQUEST_SETTINGS', get(response, 'data.changeRequestSettings', []));
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Change request settings saved successfully',
                });
                EventBus.$emit('submit-turo-reimbursement-success');
            }).catch(e => {
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'saving_change_request', loading: false});
        });
    },

    reimbursementDetails({commit, rootGetters}, data) {
        commit('SET_TURO_LOADING', {subject: 'reimbursementDetails', loading: true});
        axios.post(`/v1/connections/turo/${data.turo_uuid}/reimbursement-details`, data)
            .then(response => {
                commit('SET_TURO_REIMBURSEMENT_DETAILS', response.data.reimbursementDetails);
                EventBus.$emit('turo-reimbursement-details-success');
            }).catch(e => {
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'reimbursementDetails', loading: false});
        });
    },

    withdrawReimbursement({state, commit, rootGetters}, data) {
        commit('SET_TURO_LOADING', {subject: 'submitReimbursementWithdraw', loading: true});
        axios.post(`/v1/company/${rootGetters['company/company'].uuid}/connections/turo/${data.turo_uuid}/withdraw-reimbursement`, data)
            .then(() => {
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Submit reimbursement request successfully',
                });
                EventBus.$emit('submit-turo-withdraw-success');
            }).catch(e => {
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_TURO_LOADING', {subject: 'submitReimbursementWithdraw', loading: false});
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
