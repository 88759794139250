import type {RouteMeta, RouteRecordRaw} from 'vue-router';
import type {CommonRouteMeta} from '@/types';

const Quickstart = () => import('@/views/dashboard/Quickstart.vue');
const BaseDashboard = () => import('@/views/dashboard/BaseDashboard.vue');
const HomeDashboard = () => import('@/views/dashboard/Home.vue');


interface ExtendedRouteMeta extends RouteMeta, CommonRouteMeta {
    layout?: string;
    requiresAuth?: boolean;
    middleware?: string[];
    showInMenu?: boolean;
}

const dashboardChildrenRoutes: Array<RouteRecordRaw> = [
    {
        path: '',
        component: HomeDashboard,
        name: 'dashboard',
        meta: {title: '', icon: 'mdi-view-dashboard', noCache: false} as ExtendedRouteMeta,
    },
    {
        path: 'quickstart',
        component: Quickstart,
        name: 'quickstart',
        meta: {title: 'quickstart', icon: 'mdi-account', noCache: false} as ExtendedRouteMeta,
    },
    {
        path: 'account',
        component: HomeDashboard,
        name: 'me',
        meta: {title: 'me', icon: 'mdi-account', noCache: false} as ExtendedRouteMeta,
    },
    {
        path: 'debug',
        component: Quickstart,
        name: 'debug.index',
        meta: {title: 'debug', icon: 'mdi-account', noCache: false} as ExtendedRouteMeta,
    },
];

const dashboardRoutes: RouteRecordRaw = {
    path: '/dashboard',
    component: BaseDashboard,
    meta: {
        title: 'dashboard',
        icon: 'mdi-view-dashboard',
        noCache: false,
        layout: 'Dashboard',
        requiresAuth: true,
        middleware: ['auth',],
        showInMenu: true,
    } as CommonRouteMeta,
    children: dashboardChildrenRoutes,
};

export default dashboardRoutes;
