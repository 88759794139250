import axios from 'axios';
import {Form} from '@/plugins/FWForms/index';
import {EventBus} from '@/plugins/eventBus';
import * as types from '../mutation-types';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {TaxCategory} from '@/types';
import {push} from 'notivue'

export interface TaxesState {
    init: boolean,
    loading: Record<string, boolean>,
    taxForm: Form<any>,
    taxCategories: TaxCategory[],
    currentWorkingTaxCategory: TaxCategory,
}

const state: TaxesState = {
    init: false,
    loading: {
        tax_categories: false,
        set_default: false,
        index: false,
        create: false,
        delete: false,
        update: false,
    },
    taxForm: new Form(),
    taxCategories: [],
    currentWorkingTaxCategory: {
        default: false,
        name: '',
        tax_rates: [{
            name: '',
            percentage: '',
            amount: null,
            type: 'trip',
        }],
    },
};

const getters = {
    init: (state: TaxesState) => state.init,
    loading: (state: TaxesState) => state.loading,
    taxForm: (state: TaxesState) => state.taxForm,
    taxCategories: (state: TaxesState) => state.taxCategories,
    currentWorkingTaxCategory: (state: TaxesState) => state.currentWorkingTaxCategory,
};

const mutations = {
    [types.SET_TAXES_LOADING](state: TaxesState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_TAX_CATEGORIES](state: TaxesState, tax) {
        state.taxCategories = tax;
    },
    [types.SET_CURRENT_WORKING_TAX_CATEGORY](state: TaxesState, tax) {
        state.currentWorkingTaxCategory = tax;
    },
    [types.SET_TAX_CATEGORY_FORM_ERRORS](state: TaxesState, error: string | Record<string, string[]>) {
        state.taxForm.errors.set(error);
    },
    [types.CLEAR_TAX_CATEGORY_FORM_ERRORS](state: TaxesState, form_field) {
        if (form_field === null || form_field === undefined) {
            state.taxForm.errors.clear();
        } else {
            state.taxForm.errors.clear(form_field);
        }
    },
    [types.RESET_CURRENT_WORKING_TAX_CATEGORY](state: TaxesState) {
        state.currentWorkingTaxCategory = {
            default: false,
            name: '',
            tax_rates: [{
                name: '',
                percentage: '',
                amount: null,
                type: 'trip',
            }],
        };
    },
};

const actions = {


    /**
     * Get tax categories
     */
    async getTaxCategories({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_TAXES_LOADING', 'index', () => axios.get('/v1/tax-categories'));
            EventBus.$emit('get-tax-category-success');
            commit('SET_TAX_CATEGORIES', responseData.taxCategories || []);
        } catch (error) {
            commit(types.SET_TAX_CATEGORY_FORM_ERRORS, error);
            throw error;
        }
    },


    /**
     * Save tax categories
     */
    async saveTaxCategory({commit}, taxCategory: TaxCategory) {
        try {
            commit('CLEAR_TAX_CATEGORY_FORM_ERRORS');
            const responseData = await performApiRequest(commit, 'SET_TAXES_LOADING', 'create', () => axios.post('/v1/tax-categories', taxCategory));
            commit('SET_TAX_CATEGORIES', responseData?.taxCategories || []);
            push.success({
                message: 'Tax Category created successfully',
            });
            EventBus.$emit('save-tax-category-success');
        } catch (error) {
            console.dir(error);
            commit(types.SET_TAX_CATEGORY_FORM_ERRORS, error?.response?.data?.errors || 'There was an error');
            throw error;
        }
    },


    /**
     * Update tax categories
     */
    async updateTaxCategory({commit}, taxCategory: TaxCategory) {
        try {
            commit(types.CLEAR_TAX_CATEGORY_FORM_ERRORS);
            const responseData = await performApiRequest(commit, 'SET_TAXES_LOADING', 'update', () => axios.put(`/v1/tax-categories/${taxCategory.uuid}`, taxCategory));
            commit(types.SET_TAX_CATEGORIES, responseData?.taxCategories || []);
            push.success({
                message: 'Tax Category updated successfully',
            });
        } catch (error) {
            commit(types.SET_TAX_CATEGORY_FORM_ERRORS, error.response.data?.errors || 'There was an error');
            throw error;
        }
    },


    /**
     * Delete tax categories
     */
    async deleteTaxCategory({commit}, taxCategory: TaxCategory) {
        try {
            const responseData = await performApiRequest(commit, 'SET_TAXES_LOADING', 'delete', () => axios.delete(`/v1/tax-categories/${taxCategory.uuid}`));
            commit(types.SET_TAX_CATEGORIES, responseData?.taxCategories || []);

            push.success({
                message: 'Tax Category deleted successfully',
            });
        } catch (error) {
            throw error;
        }
    },


    /**
     * Set tax category default
     */
    async taxCategorySetDefault({commit}, taxCategory: TaxCategory) {
        try {
            const responseData = await performApiRequest(commit, 'SET_TAXES_LOADING', 'set_default', () => axios.post(`/v1/tax-categories/${taxCategory.uuid}/set-default`));
            commit(types.SET_TAX_CATEGORIES, responseData?.taxCategories || []);

            push.success({
                message: 'Tax Category set as default',
            });
        } catch (error) {
            throw error;
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
