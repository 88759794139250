const TOKEN_KEY = 'access_token';
const COMPANY_UUID_KEY = 'company_uuid';
const REFRESH_TOKEN_KEY = 'refresh_token';

const StorageService = {

    saveCompanyUuid(uuid: string): void {
        localStorage.setItem(COMPANY_UUID_KEY, uuid);
    },

    getCompanyUuid() {
        return localStorage.getItem(COMPANY_UUID_KEY);
    },

    removeCompanyUuid() {
        localStorage.removeItem(COMPANY_UUID_KEY);
    },


    saveShepherdTour(name: string) {
        localStorage.setItem(`shepherd-${name}`, new Date().getTime().toString());
    },

    getShepherdTour(name: string): string | null {
        return localStorage.getItem(`shepherd-${name}`);
    },
};

export {StorageService};
