import {EventBus} from '@/plugins/eventBus';
import axios from 'axios';
import * as types from '@/store/mutation-types';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {push} from 'notivue';


export interface EmailDomainSettingsState {
    loading: Record<string, boolean>;
    emailDomainSettings: EmailDomainSettings[];
}

export interface EmailDomainSettings {
    DKIMHost: string;
    DKIMPendingHost: string;
    DKIMPendingTextValue: string;
    DKIMTextValue: string;
    DKIMUpdateStatus: string;
    DKIMVerified: boolean;
    ReturnPathDomain: string;
    ReturnPathDomainCNAMEValue: string;
    ReturnPathDomainVerified: boolean;
    SPFHost: string;
    SPFTextValue: string;
    SPFVerified: boolean;
    WeakDKIM: boolean;
    active: boolean;
    created_at: string;
    domain: string;
    email: string;
    pm_id: number;
    updated_at: string;
    uuid: string;
}

const state: EmailDomainSettingsState = {
    loading: {
        deletingEmailDomain: false,
        creatingEmailDomain: false,
        checkingReturnPathStatus: false,
        sendingTestEmail: false,
    },
    emailDomainSettings: [],
};

const getters = {
    loading: (state: EmailDomainSettingsState) => state.loading,
    emailDomainSettings: (state: EmailDomainSettingsState) => state.emailDomainSettings,
};

const mutations = {
    [types.SET_COMPANY_LOADING](state: EmailDomainSettingsState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_EMAIL_DOMAIN_SETTINGS](state: EmailDomainSettingsState, domains: EmailDomainSettings[]) {
        state.emailDomainSettings = domains;
    },
};

const actions = {

    /**
     * Get the email domains
     */
    async fetchEmailDomain({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'company', () => axios.get('/v1/email-domain-settings'));
            commit('SET_EMAIL_DOMAIN_SETTINGS', responseData.domains);
        } catch (error) {
        }
    },

    /**
     * Create a email domain
     */
    async createEmailDomain({commit}, domain: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'creatingEmailDomain', () => axios.post('/v1/email-domain-settings', {domain}));
            commit('SET_EMAIL_DOMAIN_SETTINGS', responseData.domains);
            EventBus.$emit('create-email-domain-success');
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Email domain added',
            });
        } catch (error) {
            EventBus.$emit('create-email-domain-error', error?.response?.data?.message || 'There was an error');
        }
    },

    async deleteEmailDomain({commit}, domain: EmailDomainSettings) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'deletingEmailDomain', () => axios.delete(`/v1/email-domain-settings/${domain.uuid}`));
            commit('SET_EMAIL_DOMAIN_SETTINGS', responseData.domains);
            EventBus.$emit('delete-email-domain-success');
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Email domain deleted',
            });
        } catch (error) {
            EventBus.$emit('create-email-domain-error', error?.response?.data?.message || 'There was an error');
        }
    },

    async emailDomainCheckDKIM({commit}, domain: EmailDomainSettings) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'deletingEmailDomain', () => axios.get(`/v1/email-domain-settings/${domain.uuid}/check-dkim`));
            commit('SET_EMAIL_DOMAIN_SETTINGS', responseData.domains);
        } catch (error) {
        }
    },

    async emailDomainCheckReturnPathStatus({commit}, domain: EmailDomainSettings) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'checkingReturnPathStatus', () => axios.get(`/v1/email-domain-settings/${domain.uuid}/check-return-path-status`));
            commit('SET_EMAIL_DOMAIN_SETTINGS', responseData.domains);
        } catch (error) {
        }
    },

    async updateEmailDomainUsername({commit}, domain: EmailDomainSettings) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'checkingReturnPathStatus', () => axios.put(`/v1/email-domain-settings/${domain.uuid}`, {email: domain.email}));
            commit('SET_EMAIL_DOMAIN_SETTINGS', responseData.domains);
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Email username updated',
            });
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },

    async setAsDefaultSendingDomain({commit}, domain: EmailDomainSettings) {
        if (domain.uuid) {
            try {
                const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'creatingEmailDomain', () => axios.post(`/v1/email-domain-settings/${domain.uuid}/set-default`));
                commit('SET_EMAIL_DOMAIN_SETTINGS', responseData.domains);
            } catch (error) {
                push.error({
                    message: error?.response?.data?.message || 'There was an error',
                });
            }
        } else {

            try {
                const responseData = await performApiRequest(commit, 'SET_COMPANY_LOADING', 'creatingEmailDomain', () => axios.post('/v1/email-domain-settings/set-default'));
                commit('SET_EMAIL_DOMAIN_SETTINGS', responseData.domains);
            } catch (error) {
            }
        }
    },

    async sendTestEmail({commit}, email: string) {
        try {
            await performApiRequest(commit, 'SET_COMPANY_LOADING', 'sendingTestEmail', () => axios.post('/v1/email-domain-settings/send-test-email', {email}));
            EventBus.$emit('send-test-email-success');
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: `Test email sent to ${email}`,
            });
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
