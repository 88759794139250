import i18n, {SUPPORT_LOCALES} from './i18n'
import {nextTick} from 'vue'

/**
 * Supported locale types in the application
 */
type SupportedLocale = 'en-US' | 'es' | 'zh-CN';

/**
 * Interface for user locale information
 */
interface UserLocale {
    locale: SupportedLocale;
    localeNoRegion: SupportedLocale;
}

/**
 * Translation service for handling internationalization
 */
const Trans = {
    /**
     * Switches the application language to the specified locale
     * @param newLocale - The locale to switch to
     */
    async switchLanguage(newLocale: SupportedLocale): Promise<void> {
        try {
            await Trans.loadLocaleMessages(newLocale);
            Trans.currentLocale = newLocale;
            document.documentElement.setAttribute('lang', newLocale);
            localStorage.setItem('user-locale', newLocale);
        } catch (error) {
            console.error('Failed to switch language:', error);
            throw error;
        }
    },

    /**
     * Loads locale messages if they haven't been loaded yet
     * @param locale - The locale to load messages for
     */
    async loadLocaleMessages(locale: SupportedLocale): Promise<void> {
        if (!i18n.global.availableLocales.includes(locale)) {
            try {
                const messages = await import(/* @vite-ignore */ `@/lang/${locale}.json`);
                i18n.global.setLocaleMessage(locale, messages.default);
            } catch (error) {
                console.error(`Failed to load messages for locale ${locale}:`, error);
                throw error;
            }
        }
        await nextTick();
    },

    /**
     * Sets the current locale in i18n
     */
    set currentLocale(newLocale: SupportedLocale) {
        i18n.global.locale.value = newLocale;
    },

    /**
     * Gets the list of supported locales
     */
    get supportedLocales(): SupportedLocale[] {
        return SUPPORT_LOCALES as SupportedLocale[];
    },

    /**
     * Gets the default locale
     */
    get defaultLocale(): SupportedLocale {
        return 'en-US';
    },

    /**
     * Checks if a locale is supported
     * @param locale - The locale to check
     */
    isLocaleSupported(locale: string): boolean {
        return Trans.supportedLocales.includes(locale as SupportedLocale);
    },

    /**
     * Gets the user's preferred locale from browser settings
     */
    getUserLocale(): UserLocale {
        const locale = (window.navigator.language ||
            (window.navigator as any).userLanguage ||
            Trans.defaultLocale) as SupportedLocale;

        return {
            locale,
            localeNoRegion: locale.split('-')[0] as SupportedLocale
        };
    },

    /**
     * Gets the persisted locale from localStorage
     */
    getPersistedLocale(): SupportedLocale | null {
        const persistedLocale = localStorage.getItem('user-locale') as SupportedLocale | null;
        return Trans.isLocaleSupported(persistedLocale) ? persistedLocale : null;
    },

    /**
     * Determines the default locale based on user preferences and persisted settings
     */
    guessDefaultLocale(): SupportedLocale {
        const userPersistedLocale = Trans.getPersistedLocale();
        if (userPersistedLocale) {
            return userPersistedLocale;
        }

        const userPreferredLocale = Trans.getUserLocale();
        if (Trans.isLocaleSupported(userPreferredLocale.locale)) {
            return userPreferredLocale.locale;
        }

        if (Trans.isLocaleSupported(userPreferredLocale.localeNoRegion)) {
            return userPreferredLocale.localeNoRegion;
        }

        return Trans.defaultLocale;
    },
}

export default Trans;