import type {RouteRecordRaw} from 'vue-router';
import {CommonRouteMeta} from "@/types";

const BaseDashboard = () => import('@/views/dashboard/BaseDashboard.vue');


const settingsRoutes: RouteRecordRaw[] = [
    {
        path: '/settings',
        component: BaseDashboard,
        redirect: '/settings/general',
        meta: {
            title: 'settings',
            noCache: false,
            showInMenu: true,
            icon: 'mdi-cog-outline',
            permissions: ['settings_view'],
            middleware: ['auth',],
            layout: 'Dashboard',
        } as CommonRouteMeta,
        children: [
            {
                path: 'general',
                name: 'settings.general',
                components: {
                    default: () => import('@/views/dashboard/settings/General.vue'),
                },
                meta: {title: 'general', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'locations',
                name: 'settings.general.delivery',
                components: {
                    default: () => import('@/components/dashboard/settings/general/DeliveryLocations.vue'),
                },
                meta: {title: 'locations', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'delivery/add',
                name: 'settings.general.delivery.add',
                components: {
                    default: () => import('@/components/dashboard/settings/general/DeliveryLocationsAdd.vue'),
                },
                meta: {title: 'location add', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'delivery/:uuid',
                name: 'settings.general.delivery.edit',
                components: {
                    default: () => import('@/components/dashboard/settings/general/DeliveryLocationsEdit.vue'),
                },
                meta: {title: 'location edit', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'custom-fields',
                name: 'settings.custom_fields',
                components: {
                    default: () => import('@/components/dashboard/settings/general/CustomFields.vue'),
                },
                meta: {title: 'custom fields', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'localization',
                name: 'settings.general.localization',
                components: {
                    default: () => import('@/components/dashboard/settings/general/Localization.vue'),
                },
                meta: {title: 'localization', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'pricing',
                name: 'settings.pricing',
                components: {
                    default: () => import('@/views/dashboard/settings/Pricing/Pricing.vue'),
                },
                meta: {title: 'pricing', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'coupons',
                name: 'settings.pricing.coupons',
                components: {
                    default: () => import('@/views/dashboard/settings/Pricing/Coupons.vue'),
                },
                meta: {title: 'coupons', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'pricing/advanced',
                name: 'settings.pricing.advanced',
                components: {
                    default: () => import('@/views/dashboard/settings/Pricing/AdvancedPricing.vue'),
                },
                meta: {title: 'advanced pricing', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'pricing-structure',
                name: 'settings.pricing.template',
                components: {
                    default: () => import('@/views/dashboard/settings/Pricing/PricingStructure/PricingTemplate.vue'),
                },
                meta: {title: 'pricing template', icon: 'mdi-cog', noCache: false},
                children: [],
            },
            {
                path: 'pricing-structure/new',
                name: 'settings.pricing.template.new',
                components: {
                    default: () => import('@/views/dashboard/settings/Pricing/PricingStructure/PricingTemplateNew.vue'),
                },
                meta: {title: 'New', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'pricing-structure/:uuid/edit',
                name: 'settings.pricing.template.edit',
                components: {
                    default: () => import('@/views/dashboard/settings/Pricing/PricingStructure/PricingTemplateEdit.vue'),
                },
                meta: {title: 'Edit', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'pricing/advanced/edit/:uuid',
                name: 'settings.pricing.advanced.edit',
                components: {
                    default: () => import('@/views/dashboard/settings/Pricing/AdvancedPricingEdit.vue'),
                },
                meta: {title: 'advanced pricing', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'taxes',
                name: 'settings.taxes',
                components: {
                    default: () => import('@/views/dashboard/settings/Taxes.vue'),
                },
                meta: {title: 'taxes', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'security-deposits',
                name: 'settings.pricing.deposits',
                components: {
                    default: () => import('@/components/dashboard/settings/pricing/SecurityDeposits.vue'),
                },
                meta: {title: 'Security Deposits', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'security',
                name: 'settings.security',
                components: {
                    default: () => import('@/views/dashboard/settings/Security.vue'),
                },
                meta: {title: 'Security', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'security/api-tokens',
                name: 'settings.security.api_tokens',
                components: {
                    default: () => import('@/views/dashboard/settings/ApiToken.vue'),
                },
                meta: {title: 'Security', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'security/activity-log',
                name: 'settings.security.activity_log',
                components: {
                    default: () => import('@/views/dashboard/settings/ActivityLog.vue'),
                },
                meta: {title: 'Security', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'account',
                name: 'settings.account',
                component: () => import('@/views/dashboard/settings/AccountAndBilling.vue'),
                meta: {title: 'Account', icon: 'mdi-cog', noCache: false},
                redirect: '/settings/account/overview',
                children: [
                    {
                        path: 'overview',
                        name: 'settings.account.overview',
                        component: () => import('@/components/dashboard/settings/account/TheOverview.vue'),
                        meta: {title: 'Overview'},
                    },
                    {
                        path: 'receipts',
                        name: 'settings.account.receipts',
                        component: () => import('@/components/dashboard/settings/account/TheReceipts.vue'),
                        meta: {title: 'Receipts'},
                    },
                    {
                        path: 'billing',
                        name: 'settings.account.billing',
                        component: () => import('@/components/dashboard/settings/account/TheBillingDetails.vue'),
                        meta: {title: 'Billing Details'},
                    },
                ],
            },
            {
                path: 'team',
                name: 'settings.account.team',
                components: {
                    default: () => import('@/views/dashboard/settings/Team.vue'),
                },
                meta: {title: 'Account', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'notifications',
                name: 'settings.account.notifications',
                components: {
                    default: () => import('@/views/dashboard/settings/CompanyNotifications.vue'),
                },
                meta: {title: 'Notifications', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'email',
                name: 'settings.account.email',
                components: {
                    default: () => import('@/views/dashboard/settings/Emails/Email.vue'),
                },
                meta: {title: 'Email', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'email_template/:uuid',
                name: 'settings.account.email.email_template_single',
                components: {
                    default: () => import('@/views/dashboard/settings/Emails/EmailTemplateSingle.vue'),
                },
                meta: {title: 'Email', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'account/plans/confirm',
                name: 'settings.account.plans.confirm',
                components: {
                    default: () => import('@/views/dashboard/settings/Billing/SubscriptionCheckout/SubscriptionCheckout.vue'),
                },
                meta: {title: 'Plans', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'providers',
                name: 'settings.providers',
                components: {
                    default: () => import('@/views/dashboard/settings/Providers.vue'),
                },
                meta: {title: 'Providers', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'payment-providers',
                name: 'settings.providers.payment-providers',
                components: {
                    default: () => import('@/views/dashboard/settings/PaymentProviders.vue'),
                },
                meta: {title: 'Payment Providers', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'payment-providers/:uuid',
                name: 'settings.payment-provider.single',
                components: {
                    default: () => import('@/views/dashboard/settings/PaymentProviders/SinglePaymentProvider.vue'),
                },
                meta: {title: 'Payment Provider', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'providers/commission-partners',
                name: 'settings.providers.commission_partners',
                components: {
                    default: () => import('@/views/dashboard/settings/CommissionPartners.vue'),
                },
                meta: {title: 'Commission Partners', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'providers/webhooks',
                name: 'settings.providers.webhooks',
                components: {
                    default: () => import('@/views/dashboard/settings/Webhooks/Webhooks.vue'),
                },
                meta: {title: 'Webhooks', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'providers/webhooks/create',
                name: 'settings.providers.webhooks.create',
                components: {
                    default: () => import('@/views/dashboard/settings/Webhooks/Create.vue'),
                },
                meta: {title: 'Create Webhook', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'providers/webhooks/:uuid',
                name: 'settings.providers.webhooks.update',
                components: {
                    default: () => import('@/views/dashboard/settings/Webhooks/UpdateWebhook.vue'),
                },
                meta: {title: 'Edit Webhook', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'checkout',
                name: 'settings.checkout',
                components: {
                    default: () => import('@/views/dashboard/settings/Checkout.vue'),
                },
                meta: {title: 'Checkout', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'online-reservations',
                name: 'settings.checkout.online_reservations',
                components: {
                    default: () => import('@/views/dashboard/settings/OnlineReservations.vue'),
                },
                meta: {title: 'Checkout', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'account/employees/new',
                name: 'settings.account.employees.new',
                components: {
                    default: () => import('@/views/dashboard/settings/EmployeesNew.vue'),
                },
                meta: {title: 'Invite Team Member', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'account/employees/:e_id',
                name: 'settings.account.employees',
                components: {
                    default: () => import('@/views/dashboard/settings/Employees.vue'),
                },
                meta: {title: 'Account', icon: 'mdi-cog', noCache: false},
            },
            {
                path: 'fleet',
                name: 'settings.fleet',
                components: {
                    default: () => import('@/views/dashboard/settings/Fleet/FleetBase.vue'),
                },
                meta: {title: 'Account', icon: 'mdi-cog', noCache: false},
                children: [
                    {
                        path: 'branches',
                        name: 'settings.fleet.branches',
                        components: {
                            default: () => import('@/views/dashboard/settings/Fleet/FleetBranches.vue'),
                        },
                        meta: {title: 'Branches', icon: 'mdi-cog', noCache: false},
                    },
                    {
                        path: 'branches/new',
                        name: 'settings.fleet.branches.new',
                        components: {
                            default: () => import('@/views/dashboard/settings/Fleet/FleetBranchesNew.vue'),
                        },
                        meta: {title: 'Branches', icon: 'mdi-cog', noCache: false},
                    },
                    {
                        path: 'branches/:uuid',
                        name: 'settings.fleet.branches.single',
                        components: {
                            default: () => import('@/views/dashboard/settings/Fleet/FleetBranchesSingle.vue'),
                        },
                        meta: {title: 'Branches', icon: 'mdi-cog', noCache: false},
                    },
                    {
                        path: 'vehicle-classes',
                        name: 'settings.fleet.classes',
                        components: {
                            default: () => import('@/views/dashboard/settings/Fleet/FleetClass.vue'),
                        },
                        meta: {title: 'Vehicle Classes', icon: 'mdi-cog', noCache: false},
                    },
                    {
                        path: 'vehicle-classes/new',
                        name: 'settings.fleet.classes.new',
                        components: {
                            default: () => import('@/views/dashboard/settings/Fleet/FleetClassNew.vue'),
                        },
                        meta: {title: 'New Vehicle Classe', icon: 'mdi-cog', noCache: false},
                    },
                    {
                        path: 'vehicle-classes/:uuid',
                        name: 'settings.fleet.classes.single',
                        components: {
                            default: () => import('@/views/dashboard/settings/Fleet/FleetClassSingle.vue'),
                        },
                        meta: {title: 'Vehicle Class', icon: 'mdi-cog', noCache: false},
                    },
                    {
                        path: 'action-items',
                        name: 'settings.action_items',
                        components: {
                            default: () => import('@/views/dashboard/settings/Fleet/ActionItems/ActionItems.vue'),
                        },
                        meta: {title: 'Action Items', icon: 'mdi-cog', noCache: false},
                    },
                    {
                        path: 'action-items/new',
                        name: 'settings.action_item.new',
                        components: {
                            default: () => import('@/views/dashboard/settings/Fleet/ActionItems/ActionItemNew.vue'),
                        },
                        meta: {title: 'New Action Item', icon: 'mdi-cog', noCache: false},
                    }, {
                        path: 'action-items/:uuid',
                        name: 'settings.action_items.single',
                        components: {
                            default: () => import('@/views/dashboard/settings/Fleet/ActionItems/ActionItemSingle.vue'),
                        },
                        meta: {title: 'Action Item', icon: 'mdi-cog', noCache: false},
                    },
                    {
                        path: 'vehicle-features',
                        name: 'settings.fleet.features',
                        components: {
                            default: () => import('@/views/dashboard/settings/Fleet/FleetFeatures.vue'),
                        },
                        meta: {title: 'Vehicle Features', icon: 'mdi-cog', noCache: false},
                    },
                ],
            },
            {
                path: 'affiliate',
                components: {
                    default: () => import('@/views/dashboard/settings/Fleet/FleetBase.vue'),
                },
                meta: {title: 'Affiliate', icon: 'mdi-cog', noCache: false},
                children: [
                    {
                        path: '',
                        name: 'settings.account.affiliate.index',
                        components: {
                            default: () => import('@/views/dashboard/settings/Affiliate/AffiliateIndex.vue'),
                        },
                        meta: {title: 'Affiliate', icon: 'mdi-cog', noCache: false},
                    },
                    {
                        path: 'signup',
                        name: 'settings.affiliate.signup',
                        components: {
                            default: () => import('@/views/dashboard/settings/Affiliate/AffiliateSignup.vue'),
                        },
                        meta: {title: 'Affiliate', icon: 'mdi-cog', noCache: false},
                    },
                    {
                        path: 'payouts',
                        name: 'settings.affiliate.payouts',
                        components: {
                            default: () => import('@/views/dashboard/settings/Affiliate/AffiliatePayouts.vue'),
                        },
                        meta: {title: 'Affiliate', icon: 'mdi-cog', noCache: false},
                    },
                ],
            },
            {
                path: 'custodian',
                components: {
                    default: () => import('@/views/dashboard/settings/Fleet/FleetBase.vue'),
                },
                meta: {title: 'Affiliate', icon: 'mdi-cog', noCache: false},
                children: [
                    {
                        path: '',
                        name: 'settings.custodian.index',
                        components: {
                            default: () => import('@/views/dashboard/settings/Custodian/Index.vue'),
                        },
                        meta: {title: 'Custodian', icon: 'mdi-cog', noCache: false},
                    },
                ],
            },
        ],
    }
];

export default settingsRoutes;
