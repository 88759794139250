import {EventBus} from '@/plugins/eventBus';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import axios from 'axios';
import * as types from '@/store/mutation-types';
import {Module} from 'vuex';
import {createUrlQueryString} from '@/utils/urls';

export interface AnalyticsState {
    loading: Record<string, boolean>;
    analytics: any;
    conversionAnalytics: any;
    vehicleAnalytics: any;
    allVehiclesAnalytics: any;
}

type AnalyticsData = {
    listing_id?: number,
    start?: string,
    end?: string,
}


// state
const state: AnalyticsState = {
    loading: {
        analytics: false,
        fetchCart: false,
    },
    analytics: {},
    conversionAnalytics: {},
    vehicleAnalytics: {},
    allVehiclesAnalytics: {},
};

const mutations = {
    [types.SET_ANALYTICS_LOADING](state: AnalyticsState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_CONVERSION_ANALYTICS](state: AnalyticsState, analytics) {
        state.conversionAnalytics = analytics;
    },
    [types.SET_VEHICLE_ANALYTICS](state: AnalyticsState, analytics) {
        state.vehicleAnalytics = analytics;
    },
    [types.SET_VEHICLES_ANALYTICS](state: AnalyticsState, analytics) {
        state.allVehiclesAnalytics = analytics;
    },
};

const actions = {

    /**
     * Fetch conversion analytics
     */
    async fetchConversionAnalytics({commit}, data: AnalyticsData = {}) {
        const query = createUrlQueryString({
            l_id: data?.listing_id,
            start: data?.start,
            end: data?.end,
        });

        try {
            const responseData = await performApiRequest(commit, 'SET_ANALYTICS_LOADING', 'analytics', () => axios.get(`/v1/conversion-analytics${query}`));
            commit(types.SET_CONVERSION_ANALYTICS, responseData.data);
        } catch (e) {
            EventBus.$emit('fetch-conversion-analytics-error', e.response);
        }
    },

    async fetchAllVehicleAnalytics({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_ANALYTICS_LOADING', 'analytics', () => axios.get('/v1/vehicle-analytics'));
            commit(types.SET_VEHICLES_ANALYTICS, responseData.analytics);
        } catch (e) {
            EventBus.$emit('fetch-conversion-analytics-error', e.response);
        }
    },

    async fetchVehicleAnalytics({commit}, data: AnalyticsData = {}) {
        const query = createUrlQueryString({
            l_id: data?.listing_id,
            start: data?.start,
            end: data?.end,
        });

        try {
            const responseData = await performApiRequest(commit, types.SET_ANALYTICS_LOADING, 'analytics', () => axios.get(`/v1/listings/utilization${query}`));
            commit(types.SET_VEHICLES_ANALYTICS, responseData.data);
        } catch (e) {
            EventBus.$emit('fetch-conversion-analytics-error', e.response);
        }
    },
};

const analytics: Module<AnalyticsState, any> = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters: {
        loading: (state: AnalyticsState) => state.loading,
        conversionAnalytics: (state: AnalyticsState) => state.conversionAnalytics,
        vehicleAnalytics: (state: AnalyticsState) => state.vehicleAnalytics,
        allVehiclesAnalytics: (state: AnalyticsState) => state.allVehiclesAnalytics,
    },
};


export default analytics;