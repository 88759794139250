import type {RouteRecordRaw} from 'vue-router';
import BaseCompany from '@/components/company/index.vue';
import {CommonRouteMeta} from "@/types";

const loadPay = () => import('@/views/pay/index.vue');

const payRoutes: RouteRecordRaw[] = [
    {
        path: '/pay',
        component: BaseCompany,
        meta: {
            hidden: true,
            noCache: false,
            requiresAuth: false,
            middleware: ['companyStore'],
            layout: 'Default',
        } as CommonRouteMeta,
        children: [
            {
                path: ':p_id',
                component: loadPay,
                name: 'pay',
                meta: {title: 'single payment', icon: 'mdi-account-outline', noCache: false},
            },
        ],
    }
];

export default payRoutes;
