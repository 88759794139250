import dayjs from 'dayjs';

/**
 * Builds a query string from an object of parameters, with optional ignore of empty values and handling of nested objects.
 * @param params - The parameters object
 * @param removeEmpty - Whether to remove empty values
 * @returns The query string
 */
export function createUrlQueryString(params: Record<string, any>, removeEmpty = true): string {
    if (params === undefined || typeof params !== 'object') {
        throw new Error('Params must be an object');
    }

    if (removeEmpty) {
        params = Object.entries(params)
            .filter(([, value]) => {
                // Filter out null, undefined, empty strings, and empty arrays
                if (value == null || value === '') {
                    return false;
                }
                return !(Array.isArray(value) && value.length === 0);
            })
            .reduce((acc, [key, value]) => ({...acc, [key]: value}), {});
    }

    const buildNestedQueryString = (obj: Record<string, any>, parentKey = ''): string => {
        return Object.entries(obj)
            .map(([key, value]) => {
                const fullKey = parentKey ? `${parentKey}[${key}]` : key;

                if (value === null || value === undefined) {
                    return `${fullKey}=`;
                } else if (typeof value === 'object' && !Array.isArray(value)) {
                    return buildNestedQueryString(value, fullKey);
                } else if (Array.isArray(value)) {

                    return value.length
                        ? value.map((arrayItem) => `${fullKey}[]=${encodeURIComponent(String(arrayItem))}`).join('&')
                        : `${fullKey}=`; // Handle empty arrays
                } else if (
                    typeof value === 'string' ||
                    typeof value === 'number' ||
                    typeof value === 'boolean'
                ) {
                    return `${fullKey}=${encodeURIComponent(value)}`;
                } else {
                    throw new Error('Invalid value type');
                }
            })
            .join('&');
    }

    const queryString = buildNestedQueryString(params);
    return queryString ? `?${queryString}` : '';
}


/**
 * Builds a query string from an object of parameters, with optional ignore of empty values and handling of nested objects.
 * @param data - The parameters object
 * @param removeEmpty - Whether to remove empty values
 * @returns The query string
 */
export const createOrderUrlQueryString = (data, removeEmpty = false) => {
    if (data === undefined) {
        return '';
    }
    // Check if data is an object
    if (typeof data !== 'object') {
        throw new Error('Data must be an object');
    }

    if (removeEmpty) {
        Object.keys(data).forEach((key) => {
            if (data[key] === '' || data[key] === null || data[key] === undefined) {
                delete data[key];
            }
        });
    }

    let queryString = '?';

    Object.keys(data).forEach((key) => {
        if (typeof data[key] === 'object') {
            Object.keys(data[key]).forEach((subKey) => {
                queryString += `${key}[${subKey}]=${data[key][subKey]}&`;
            });
        } else {
            queryString += `${key}=${data[key]}&`;
        }
    });

    // Remove the leading ? if there are no params
    if (queryString === '?') {
        queryString = '';
    }

    // remove last & if any
    if (queryString[queryString.length - 1] === '&') {
        queryString = queryString.slice(0, -1);
    }
    return queryString;
};


export const formatQueryStringDate = (date: string) => {
    if (!date) throw new Error('Date is required');

    const dateWithHyphen = (input: string): string => {
        const parts = input.split('-');
        let year = parts[0];
        if (year.length === 2) year = `20${year}`;

        const newDate = dayjs(`${year}-${parts[1]}-${parts[2]}`);
        if (newDate.isValid()) {
            return newDate.format('YYYY-MM-DD');
        }

        throw new Error('Invalid date format');
    };

    const dateWithoutHyphen = (input: string, format: string): string => {
        let year, month, day;

        if (format === 'YYYYMMDD') {
            year = input.substring(0, 4);
            month = input.substring(4, 6);
            day = input.substring(6, 8);
        } else if (format === 'YYMMDD') {
            year = `20${input.substring(0, 2)}`;
            month = input.substring(2, 4);
            day = input.substring(4, 6);
        } else {
            throw new Error('Invalid date format');
        }

        const newDate = dayjs(`${year}-${month}-${day}`);
        if (newDate.isValid()) {
            return newDate.format('YYYY-MM-DD');
        }

        throw new Error('Invalid date format');
    };

    if (date.includes('-')) {
        return dateWithHyphen(date);
    }

    if (date.length === 8) {
        return dateWithoutHyphen(date, 'YYYYMMDD');
    }

    if (date.length === 6) {
        return dateWithoutHyphen(date, 'YYMMDD');
    }

    throw new Error('Invalid date format');
}

export const formatQueryStringTime = (time: string | undefined): string => {
    if (!time) return '10:00:00';

    if (time.length !== 4) {
        throw new Error('Invalid time format. Expected HHMM format.');
    }

    const hours = time.slice(0, 2);
    const minutes = time.slice(2, 4);

    return `${hours}:${minutes}:00`;
};


const baseUrls: Record<string, string> = {
    production: 'https://tenant.fleetwire.io/',
    development: 'http://fleetwire.test/',
};

/**
 * Get the base URL based on the environment mode.
 * @param envOverride Optional override for the environment mode.
 * @returns The base URL for the current or specified environment.
 */
export const getBaseUrl = (envOverride: string | null = null): string => {
    const env = envOverride || import.meta.env.MODE;
    return baseUrls[env] || baseUrls.development;
};