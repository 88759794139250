<template>
  <div>
    <h2 class="mt-8 mb-4 font-bold text-2xl">
      <FeatureTitle
        featurePremium
        title="API Tokens"
      />
      <the-help-link url="https://help.fleetwire.io/en/article/introduction-to-the-fleetwire-api-izq6u/?bust=1676569061818"></the-help-link>
    </h2>

    <v-card :disabled="authLoading.createApiToken">
      <v-card-title>
        <div class="flex flex-col sm:flex-row gap-2 sm:gap-0">
          <div>Standard keys</div>
          <v-spacer class="hidden sm:block"/>
          <v-btn
            :disabled="!planIncludesApi"
            :loading="authLoading.createApiToken"
            class="w-full sm:w-auto"
            size="small"
            @click="createUserTokensInit()"
          >
            <v-icon class="mr-1" size="small">mdi-plus</v-icon>
            Secret Key
          </v-btn>
        </div>
      </v-card-title>
      <v-card-subtitle>
        <div class="flex flex-col sm:flex-row items-start sm:items-center gap-2">
          <span>These keys will allow you to authenticate API requests.</span>
          <v-btn
            class="text-decoration-none"
            href="https://documenter.getpostman.com/view/263138/Tz5p6dWS"
            size="x-small"
            target="_blank"
            variant="text"
          >
            API Documentation
            <v-icon class="ml-1" size="x-small">mdi-open-in-new</v-icon>
          </v-btn>
        </div>
      </v-card-subtitle>
      <v-card-text>
        <div v-if="apiTokens.length > 0" class="overflow-x-auto">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th class="px-4 sm:px-6 py-3" scope="col">Name</th>
              <th class="hidden sm:table-cell px-6 py-3" scope="col">Last Used</th>
              <th class="hidden sm:table-cell px-6 py-3" scope="col">Created</th>
              <th class="px-4 sm:px-6 py-3" scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, key) in apiTokens" :key="key" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td class="px-4 sm:px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {{ item.name }}
              </td>
              <td class="hidden sm:table-cell px-6 py-4">
                {{ item.last_used_at ? formatRentalDate(item.last_used_at, 'MMM DD') : '--' }}
              </td>
              <td class="hidden sm:table-cell px-6 py-4">
                {{ formatRentalDate(item.created_at, 'MMM DD') }}
              </td>
              <td class="px-4 sm:px-6 py-4 text-right">
                <v-menu location="bottom">
                  <template v-slot:activator="{ props }">
                    <v-btn size="small" v-bind="props" variant="text">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="deleteApiTokenInit(item)">
                      <v-list-item-title class="text-red-500">
                        Delete
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="text-center py-4">No API Tokens</div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showInitialTokenDialog" :persistent="true" width="500">
      <v-card>
        <v-card-title class="pb-0">
          <div class="flex items-center">
            <span class="text-xl font-medium">Your New API Token</span>
            <v-spacer/>
            <v-icon color="green" size="small">mdi-shield-lock</v-icon>
          </div>
        </v-card-title>

        <v-card-text>
          <div class="mb-4 p-3 bg-red-50 border border-red-200 rounded-lg">
            <div class="flex items-center">
              <v-icon class="mr-2" color="red" size="small">mdi-alert-circle</v-icon>
              <span class="text-red-600 text-sm">
                This secret key will only be shown once. Make sure to copy it now.
              </span>
            </div>
          </div>

          <v-tooltip location="right">
            <template v-slot:activator="{ props }">
              <div class="relative">
                <label for="new-token"></label>
                <input
                  id="new-token"
                  :value="newToken"
                  class="cursor-pointer w-full border-2 border-gray-200 rounded-lg p-3 pr-10 font-mono text-sm bg-gray-50 hover:bg-gray-100 transition-colors"
                  readonly
                  v-bind="props"
                  @click="copyToClipboardInit(newToken)"
                >
                <v-icon class="absolute right-3 top-1/2 -translate-y-1/2" color="gray" size="small" style="position: absolute !important;">
                  mdi-content-copy
                </v-icon>
              </div>
            </template>
            <span>Click to copy token</span>
          </v-tooltip>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer/>
          <v-btn
            color="primary"
            variant="elevated"
            @click="showInitialTokenDialog = false"
          >
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="copiedText" location="bottom">
      Token Copied
    </v-snackbar>
    <v-snackbar v-model="tokenDeletedSnack" location="bottom">
      Token Deleted
    </v-snackbar>

    <UpgradePlanFeatureDialog2
      :bullets="[
        {
          text: 'Build on top of Fleetwire to extend and customize its capability.'
        },
         {
          text: 'Build amazing custom reports the way you want and wherever you want.'
        },
         {
          text: 'Combine Fleetwire\'s data with any other data from analytics tools.'
        },
      ]"
      :tiers="['premium']"
      description="Benefit from efficient data exchange and integration, reducing the need for manual processes."
      feature="API"
      right-side-message="Unlock the full potential of your fleet management game by seamlessly integrating Fleetwire into your favorite tools."
    >
      <template v-slot:rightImage>
        <svg fill="none" viewBox="0 0 589 528" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#api-paywall_svg__clip0_1620_2881)">
            <circle cx="295.396" cy="260.542" fill="#A886B2" opacity="0.16" r="244.5"></circle>
            <circle cx="295.396" cy="260.542" fill="#A886B2" opacity="0.16" r="194.5"></circle>
            <circle cx="295.396" cy="260.542" fill="#A886B2" opacity="0.16" r="144.5"></circle>
            <circle cx="295.396" cy="260.542" fill="#654B6B" r="94.5"></circle>
            <!--                <path d="M284.715 33.416 C 284.276 33.527,283.017 33.892,281.918 34.227 C 279.199 35.057,98.279 124.029,95.457 125.924 C 73.759 140.493,86.104 175.524,112.288 173.685 C 117.805 173.297,116.700 173.813,204.196 130.782 C 315.734 75.926,304.418 81.689,308.295 77.765 C 321.856 64.040,316.703 41.323,298.493 34.557 C 295.555 33.466,287.247 32.778,284.715 33.416 M243.066 155.241 C 239.188 155.944,240.963 155.097,169.506 190.356 C 102.068 223.632,104.477 222.367,100.604 226.549 C 82.731 245.854,102.443 277.094,127.594 269.326 C 131.666 268.068,261.695 203.835,264.424 201.733 C 285.979 185.131,270.004 150.356,243.066 155.241" fill="#FF7F3B"/>-->
            <!--                <path d="M173.027 290.750 C 170.160 291.517,166.160 293.411,137.741 307.455 L 113.983 319.196 110.949 322.231 C 94.358 338.822,106.013 366.983,129.471 366.983 C 136.232 366.983,136.247 366.977,162.438 354.037 C 174.525 348.065,186.417 342.215,188.863 341.037 C 199.885 335.730,205.780 327.104,205.780 316.284 C 205.780 299.316,189.233 286.416,173.027 290.750" fill="#FF7F3B"/>-->
            <path d="M457.773 59.38l10.643 6.858c1.856-2.942 28.944-36.493 72.231-49.026 37.966-10.991 92.595-1.24 96.786-2.31 2.955-3.261 12.981-10.827 2.453-9.95-4.945.878-61.817-8.555-102.259 2.978-46.617 13.295-77.392 47.932-79.854 51.45z" fill="#DBC4E1"></path>
            <path d="M430.527 136.07l-11.89-5.98 1.02-2.03c6.18-12.27 1.23-27.23-11.04-33.4l-21.21 42.15a4.243 4.243 0 001.89 5.7l41.28 20.77 21.46-42.58c-11.54-5.81-15.7 3.82-21.5 15.36l-.01.01z" fill="#FE8787"></path>
            <path d="M426.367 148.46l-35.77-18-2.25 4.47 38.02 13.53z" fill="#F65B5B"></path>
            <path d="M470.352 67.128L442.896 97.04l-59.02 62.04 8.08 7.68 60.915-64.03 29.351-31.902-11.87-3.7zM367.207 171.601l13.08 12.44-.44 26.6-39.19-37.28 26.55-1.76z" fill="#DBC4E1"></path>
            <path d="M340.667 173.347l-18.568 19.519 39.19 37.281 18.568-19.519-39.19-37.281z" fill="#DBC4E1"></path>
            <path d="M345.587 207.42l-19.6-18.64 3.96-4.16 15.64 22.8z" fill="#DBC4E1"></path>
            <path d="M322.068 185.031l-7.795 8.195 47.051 44.759 7.795-8.195-47.051-44.759zM382.257 157.541l11.31 10.76-13.28 15.74-13.08-12.44 15.05-14.06z" fill="#DBC4E1"></path>
            <path d="M408.657 45.93l-32.75 65.08c-2.66 5.29-.51 11.79 4.77 14.45l45.69 22.99-5.1 10.13 9.3 4.68 41.08-28.61h41.06l-.05-73.61s-37.31-.09-40.73-.09l-48.82-19.79c-5.29-2.66-11.79-.51-14.45 4.77z" fill="#FE8787"></path>
            <path d="M502.616 140.441l-.24-84.67 26.14.35-.36 84.37-25.54-.05z" fill="#fff"></path>
            <path d="M516.887 52.79l80.38-.83v97.37l-80.22-5.68-.16-90.86z" fill="#1D1D1D"></path>
            <path d="M157.89 404.115l12.04 6.06-1.03 2.05c-6.26 12.43-1.25 27.58 11.18 33.83l21.49-42.69c1.07-2.12.21-4.71-1.91-5.78l-41.81-21.04-21.74 43.13c11.69 5.88 15.9-3.87 21.78-15.56z" fill="#FE8787"></path>
            <path d="M162.1 391.556l36.23 18.23 2.28-4.53-38.51-13.7z" fill="#F65B5B"></path>
            <path d="M248.79 287.865a3.515 3.515 0 00-4.96.34l-17.45 20.01 5.3 4.62 17.45-20.01c1.28-1.46 1.12-3.68-.34-4.96zM276.84 312.325a3.524 3.524 0 00-4.96.34l-17.45 20.01 5.3 4.62 17.45-20.01a3.524 3.524 0 00-.34-4.96zM190.24 375l-56.62 64.93-12.17 25.9 12.17 3.23 10.34-23.805 54.79-62.835-8.51-7.42zM126.113 532.23c-1.127-6.431 4.887-38.744 9.997-46.462 0-4.401-2.784-10.867-9.998-3.149-6.621 8.549-11.286 44.483-10.284 52.438l10.285-2.827zM215.23 368.575l-13.78-12.02-.73-26.93 41.29 36-26.78 2.95z" fill="#DBC4E1"></path>
            <path d="M218.654 309.064l-17.929 20.56 41.294 36.01 17.929-20.56-41.294-36.01z" fill="#DBC4E1"></path>
            <path d="M218.269 301.138l-7.525 8.63 49.569 43.226 7.525-8.629-49.569-43.227zM200.61 383.456l-11.91-10.38 12.75-16.52 13.78 12.02-14.62 14.88z" fill="#DBC4E1"></path>
            <path d="M190.4 374.556l8.51 7.42-4.51 5.16-4-12.58zM235.53 331.375l20.65 18.01-3.82 4.38-16.83-22.39z" fill="#DBC4E1"></path>
            <path d="M180.03 495.395l33.18-65.92c2.69-5.35.52-11.94-4.83-14.64l-46.28-23.29 5.17-10.26-9.42-4.74-41.61 28.98H74.65l.05 74.56s37.78.09 41.26.09l49.44 20.06c5.35 2.69 11.94.52 14.63-4.84z" fill="#FE8787"></path>
            <path d="M84.87 399.055l.24 85.14-26.48.27.37-85.46 25.87.05z" fill="#fff"></path>
            <path d="M70.42 487.835l-81.42 6.42v-98.62l81.25.17.17 92.03z" fill="#1D1D1D"></path>
            <path d="M118.185 229.31l-5.136 7.441-8.464 2.833 7.259 5.195 2.723 8.621 5.136-7.441 8.465-2.834-7.259-5.194-2.724-8.621z" fill="#90AFFF" opacity="0.6"></path>
            <path d="M376.691 372.335l-5.135 7.441-8.465 2.833 7.259 5.195 2.724 8.621 5.135-7.441 8.465-2.834-7.259-5.194-2.724-8.621z" fill="#FFD282" opacity="0.6"></path>
            <path d="M157.568 286.023l-3.853 5.58-6.348 2.126 5.444 3.896 2.043 6.465 3.853-5.579 6.348-2.126-5.444-3.897-2.043-6.465z" fill="#99D8BA" opacity="0.6"></path>
            <path d="M183.993 135.209l-6.421 9.298-10.58 3.545 9.072 6.495 3.407 10.774 6.418-9.299 10.582-3.544-9.074-6.495-3.404-10.774z" fill="#FFAB90" opacity="0.6"></path>
            <path d="M459.189 211.132l-6.421 9.299-10.579 3.544 9.071 6.495 3.407 10.775 6.418-9.299 10.583-3.544-9.075-6.496-3.404-10.774z" fill="#90AFFF" opacity="0.6"></path>
            <path d="M297.657 87.452l-6.421 9.298-10.579 3.544 9.071 6.496 3.407 10.774 6.419-9.299 10.582-3.544-9.075-6.496-3.404-10.773z" fill="#FFD282" opacity="0.6"></path>
            <path d="M91.3 321.76l-11.16-2.765-7.174-8.797-2.917 10.97-9.032 7.115 11.16 2.765 7.174 8.797 2.918-10.971 9.031-7.114z" fill="#FFAB90" opacity="0.6"></path>
            <path d="M98.886 147.048l-11.009-2.73-7.079-8.677-2.88 10.822-8.908 7.019 11.008 2.73 7.08 8.677 2.88-10.822 8.908-7.019z" fill="#99D8BA" opacity="0.6"></path>
            <path d="M329.447 449.45l-15.287-3.793-9.832-12.048-4.001 15.028-12.37 9.749 15.286 3.788 9.833 12.053 4-15.032 12.371-9.745z" fill="#90AFFF" opacity="0.6"></path>
            <path d="M530.402 272.411l-12.978-3.22-8.347-10.228-3.396 12.758-10.502 8.276 12.977 3.217 8.348 10.231 3.395-12.761 10.503-8.273z" fill="#FFD282" opacity="0.6"></path>
            <path d="M470.366 413.876l-15.287-3.793-9.833-12.048-4 15.028-12.371 9.749 15.286 3.789 9.834 12.052 3.999-15.032 12.372-9.745z" fill="#FFAB90" opacity="0.6"></path>
          </g>
          <defs>
            <clipPath id="api-paywall_svg__clip0_1620_2881">
              <path d="M0 0h589v528H0z" fill="#fff"></path>
            </clipPath>
          </defs>
        </svg>
      </template>
    </UpgradePlanFeatureDialog2>

  </div>
</template>

<script lang="ts" setup>
import {defineAsyncComponent, onMounted, ref} from 'vue';
import {copyTextToClipboard} from '@/helpers';
import {useDateFormatter} from '@/composables/useDate';
import {useAuth} from '@/composables/useAuth';
import {useSubscription} from '@/composables/useSubscription';

const FeatureTitle = defineAsyncComponent(() => import('@/components/dashboard/FeatureTitle.vue'));

const UpgradePlanFeatureDialog2 = defineAsyncComponent(() => import('@/components/dashboard/dialogs/UpgradePlanFeatureDialog2.vue'));

const {formatRentalDate} = useDateFormatter();
const {planIncludesApi} = useSubscription();

const {
  apiTokens,
  authLoading,
  fetchUserTokens,
  createUserTokens,
  deleteApiToken,
} = useAuth();

const showInitialTokenDialog = ref(false);
const newToken = ref('');
const copiedText = ref(false);
const tokenDeletedSnack = ref(false);

onMounted(() => {
  fetchUserTokens();
});

const copyToClipboardInit = (text) => {
  try {
    copyTextToClipboard(text);
    copiedText.value = true;
  } catch (err) {
    // eslint-disable-next-line no-alert
    alert('Oops, unable to copy');
  }
};

const createUserTokensInit = () => {
  createUserTokens().then((token) => {
    showInitialTokenDialog.value = true;
    newToken.value = token;
  });
};

const deleteApiTokenInit = (token) => {
  deleteApiToken(token).then(() => {
    fetchUserTokens();
    tokenDeletedSnack.value = true;
  });
};
</script>
