import axios from 'axios';
import * as types from '@/store/mutation-types';
import {CommissionPartnerService} from '@/services/commission-partner.service';
import {CartService} from '@/services/cart.service';
import {performApiRequest} from "@/store/helpers/vuex-helpers";
import {Cart} from "@/types";
import dayjs from "dayjs";


export interface CartState {
    loading: Record<string, boolean>;
    cart: Cart;
}

// state
const state: CartState = {
    loading: {
        cart: false,
        fetchCart: false,
    },
    cart: {
        loaded: false,
        uuid: CartService.getCartUuid(),
        l_id: null,
        trip_start: null,
        trip_end: null,
        b_id: null,
        checkout_url: null,
        commission_partner_id: null,
        company_uuid: '',
        complete: 0,
        coupon_discount: null,
        coupon_id: null,
        created_at: '',
        deposit: null,
        grand_total: null,
        price: null,
        tax: null,
        total_due: null,
        updated_at: '',
        website_url: null,
    },
};

const getters = {
    loading: (state: CartState) => state.loading,
    cart: (state: CartState) => state.cart,
};

const mutations = {
    [types.SET_CART_LOADING](state: CartState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_CART_L_ID](state: CartState, l_id: string) {
        state.cart.l_id = l_id;
    },
    [types.SET_CART](state: CartState, cart: Cart) {
        console.dir('SET_CART', cart);
        CartService.saveCartUuid(cart.uuid);
        state.cart = cart;
        state.cart.loaded = true;
    },
};

const actions = {

    /**
     * Create a cart
     */
    async createCart({commit}, payload: any = {}) {
        const params = new URLSearchParams();
        if (import.meta.env.MODE !== 'production' && !payload?.company) {
            // params.append('company_slug', 'frunkyea-tesla-rentals');
        }
        const partnerUuid = CommissionPartnerService.getPartnerUuid();
        if (partnerUuid) {
            payload.cp = partnerUuid;
        }

        // if params is missing company_slug, add it
        if (!params.has('company_slug')) {
            console.dir('HERE %%66663333');

            // get hostname subdomain
            const hostname = window.location.hostname;

            // if hostname has fleetwire.io in it then use the subdomain
            if (hostname.includes('fleetwire.io')) {
                const subdomain = window.location.hostname.split('.')[0];
                console.dir(subdomain);
                params.append('company_slug', subdomain);
            } else {
                // if hostname is not fleetwire.io then use the full hostname
                params.append('company_custom_domain', window.location.hostname);
            }
        }

        try {
            const responseData = await performApiRequest(commit, 'SET_CART_LOADING', 'createCart', () => axios.post(`/v1/carts${params.toString() ? `?${params}` : ''}`, payload));
            commit('SET_CART', responseData.cart);

            return responseData;
        } catch (e) {
        }
    },


    /**
     * Fetch a cart
     */
    async fetchCart({commit}, cartId: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_CART_LOADING', 'fetchCart', () => axios.get(`/v1/carts/${cartId}`));
            commit(types.SET_CART, responseData.cart);
            return responseData.cart;
        } catch (e) {
            if (e?.response?.status === 404) {
                CartService.removeCartUuid();
                window.location.reload();
            }
        }
    },

    /**
     * Update a cart
     */
    async updateCart({state, commit}) {
        const cartId = state.cart?.uuid;

        if (typeof cartId === 'string') {
            try {
                const responseData = await performApiRequest(commit, 'SET_CART_LOADING', 'updateCart', () => axios.put(`/v1/carts/${cartId}`, state.cart));
                commit('SET_CART', responseData.cart);
            } catch (e) {
                if (e.response.status === 404) {
                    CartService.removeCartUuid();
                    window.location.reload();
                }
            }
        }
    },


    /**
     * Set a carts period
     */
    async setPeriod({state, commit}, dates: { start: string, end: string }) {
        const cartId = state.cart?.uuid;

        console.dir('setPeriod');
        console.dir(cartId);
        console.dir(dates);

        if (typeof cartId === 'string') {
            const start = dayjs(dates.start).format('YYYY-MM-DD HH:mm:00');
            const end = dayjs(dates.end).format('YYYY-MM-DD HH:mm:00');

            try {
                const responseData = await performApiRequest(commit, 'SET_CART_LOADING', 'setPeriod', () => axios.post(`/v1/carts/${cartId}/set-period`, {
                    start,
                    end
                }));
                commit(types.SET_CART, responseData.cart);

                return responseData.cart;
            } catch (e) {
                if (e.response.status === 404) {
                    CartService.removeCartUuid();
                    window.location.reload();
                }
                throw e;
            }
        }
    },

    /**
     * Report a cart change
     */
    async report({commit}, data = {}) {
        try {
            await performApiRequest(commit, 'SET_CART_LOADING', 'report', () => axios.post('/v1/report', data));
        } catch (e) {
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
