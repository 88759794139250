<template>
  <div>
    <div v-if="affiliateLoading.affiliateApplication">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>

    <v-card v-else-if="!affiliateApplication">
      <v-card-title>
        Affiliate Signup
      </v-card-title>
      <v-card-text class="mt-3">
        <v-form v-model="formReady">
          <h5 class="h5 my-5">Account Details</h5>
          <v-text-field
            v-model="application.company_name"
            :error-messages="affiliateForm.errors.get('company_name')"
            label="Company Name"
            name="company"
            required
            variant="outlined"
            @keydown="affiliateForm.errors.clear('company_name')"
          ></v-text-field>

          <v-text-field
            v-model="application.website"
            :error-messages="affiliateForm.errors.get('website')"
            label="Website"
            name="website-url"
            variant="outlined"
            @keydown="affiliateForm.errors.clear('website')"
          ></v-text-field>

          <v-text-field
            v-model="application.phone"
            :error-messages="affiliateForm.errors.get('phone')"
            label="Phone"
            name="phone"
            variant="outlined"
            @keydown="affiliateForm.errors.clear('phone')"
          ></v-text-field>

          <h5 class="h5">Additional Details</h5>

          <v-text-field
            v-model="application.channel"
            :error-messages="affiliateForm.errors.get('channel')"
            label="URL of website/channel you intend to promote our product(s) on?"
            name="url"
            variant="outlined"
            @keydown="affiliateForm.errors.clear('channel')"
          ></v-text-field>

          <v-select
            v-model="application.use_popup"
            :error-messages="affiliateForm.errors.get('use_popup')"
            :items="['yes', 'no']"
            label="Do you plan to drive popup/popunder traffic?"
            variant="outlined"
            @update:model-value="affiliateForm.errors.clear('use_popup')"
          ></v-select>
          <v-select
            v-model="application.use_ppc"
            :error-messages="affiliateForm.errors.get('use_ppc')"
            :items="['yes', 'no']"
            label="Do you plan to drive traffic to your site(s) through PPC/paid advertising?"
            variant="outlined"
            @update:model-value="affiliateForm.errors.clear('use_ppc')"
          ></v-select>

          <v-textarea
            v-model="application.method_to_promote"
            :error-messages="affiliateForm.errors.get('method_to_promote')"
            label="How do you intend to promote our product(s)?"
            name="usage"
            variant="outlined"
            @keydown="affiliateForm.errors.clear('method_to_promote')"
          ></v-textarea>

          <v-text-field
            v-model="application.url"
            :error-messages="affiliateForm.errors.get('url')"
            hint="The Affiliate URL is the link your referrals will sign up with and how we know how to track and match the signup with you. Only alphanumeric, '-', '_' characters."
            label="Desired Affiliate URL"
            prefix="https://fleetwire.io/r/"
            @keydown="nameKeydown($event)"
            @keyup="affiliateForm.errors.clear('url')"
          ></v-text-field>

          <v-checkbox
            v-model="application.agreeToS"
            :error-messages="affiliateForm.errors.get('agreeToS')"
            label="I agree to the Fleetwire Affiliate Partner Terms of Service."
            @update:model-value="affiliateForm.errors.clear('agreeToS')"
          >
            <template v-slot:label>
              I agree to the
              <v-btn size="small" variant="text" @click="EventBus.$emit('open-affiliate-terms-dialog');">Fleetwire
                Affiliate Partner Terms of
                Service.
              </v-btn>
            </template>

          </v-checkbox>

          <div v-if="affiliateForm.errors.any()" class="mt-5">
            <v-alert closable type="error">
              Please correct the errors above.
            </v-alert>
          </div>

          <v-btn class="mt-5" @click="submitAffiliateApplicationInit(application)">Sign Up</v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card v-else-if="affiliateApplication" variant="outlined">
      <v-card-title>
        Affiliate Signup Success
      </v-card-title>
      <v-card-text>
        Date of application: {{ formatRentalDate(affiliateApplicationCreatedAt) }}<br>

        We will review your application and get back to you shortly.

        <div class="mt-10">
          <v-btn size="small" variant="text" @click="EventBus.$emit('open-affiliate-terms-dialog');">Terms &
            Conditions
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <AffiliateTerms/>
  </div>
</template>

<script lang="ts" setup>
import {computed, onBeforeMount, ref, watch} from 'vue';
import {EventBus} from '@/plugins/eventBus';
import AffiliateTerms from '@/views/dashboard/settings/Affiliate/AffiliateTerms.vue';
import {useDateFormatter} from '@/composables/useDate';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';

const store = useStore();
const {formatRentalDate} = useDateFormatter();

const router = useRouter();

const submitSuccess = ref(false);
const formReady = ref(null);
const application = ref({
  company_name: '',
  website: '',
  phone: '',
  channel: '',
  use_popup: '',
  use_ppc: '',
  method_to_promote: '',
  url: '',
  agreeToS: false,
});

const affiliateLoading = computed(() => store.getters['affiliate/loading']);
const affiliate = computed(() => store.getters['affiliate/affiliate']);
const affiliatePayouts = computed(() => store.getters['affiliate/affiliatePayouts']);
const affiliateApplication = computed(() => store.getters['affiliate/affiliateApplication']);
const affiliateForm = computed(() => store.getters['affiliate/affiliateForm']);
const affiliateApplicationCreatedAt = computed(() => affiliateApplication.value?.created_at);

const affiliateUrl = computed(() => affiliate.value?.url);
const affiliateActive = computed(() => affiliate.value?.active);

const affiliateApplications = () => store.dispatch('affiliate/affiliateApplications');
const submitAffiliateApplication = (application) => store.dispatch('affiliate/submitAffiliateApplication', application);

const nameKeydown = (e) => {
  if (/[^a-zA-Z0-9-_]/.test(e.key)) {
    e.preventDefault();
  }
};

const submitAffiliateApplicationInit = (application) => {
  submitAffiliateApplication(application).then(() => {
    submitSuccess.value = true;
    application.value = {
      company_name: '',
      website: '',
      phone: '',
      channel: '',
      use_popup: '',
      use_ppc: '',
      method_to_promote: '',
      url: '',
      agreeToS: false,
    };
  });
};

watch(affiliateUrl, (val) => {
  if (!val) return;

  application.value.url = val.replace(/[^a-zA-Z0-9-_]/g, '');
});

watch(affiliateActive, (val) => {
  if (val) {
    ray('affiliate active');
    router.push({name: 'settings.account.affiliate.index'}).catch(() => {
    });
  }
});

onBeforeMount(() => {
  affiliateApplications();
});
</script>

