import {reactive, watch} from 'vue';
import getDefaultMeta, {getMetaBooleanValue} from '@/utils/meta';
import {useCompany} from '@/composables/useCompany';

type MetaType = 'boolean' | 'string' | 'number';
type MetaConfig = {
    type: MetaType;
    default?: any;
};

export function useDefaultMeta(keys: Record<string, MetaConfig>) {
    const {defaultMeta, addDefaultMeta, companyMetaForm} = useCompany();
    const state = reactive<Record<string, any>>({});

    const getValue = (key: string, config: MetaConfig) => {
        const {type, default: fallback} = config;

        switch (type) {
            case 'boolean': {
                const val = getMetaBooleanValue(key);
                return val ?? fallback ?? false;
            }
            case 'number': {
                const val = Number(getDefaultMeta(key));
                return isNaN(val) ? (fallback ?? 0) : val;
            }
            case 'string': {
                const val = getDefaultMeta(key);
                return val ?? fallback ?? '';
            }
            default:
                return fallback ?? '';
        }
    };

    const initialize = () => {
        Object.entries(keys).forEach(([key, config]) => {
            state[key] = getValue(key, config);
        });
    };

    initialize();

    watch(
        defaultMeta,
        initialize,
        {immediate: true}
    );

    const updateDefaultMeta = (key: string, value: any) => {
        state[key] = value;
        companyMetaForm.value?.errors?.clear(key);
        return addDefaultMeta({meta: [{key, value}]});
    };

    return {
        state,
        updateDefaultMeta,
    };
}