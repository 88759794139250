<template>
  <section class="bg-gray-50 dark:bg-gray-900">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white" href="/">
        <img alt="logo" class="w-8 h-8 mr-2" src="https://tenant.fleetwire.io/images/logo/fleetwire-logo-new.png">
        Fleetwire
      </a>
      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Welcome to Fleetwire.io
          </h1>
          <p class="text-muted mb-4 text-subtitle-1">
            You have been invited by one of your team members. To get started, please fill our some info below.
          </p>

          <div v-if="!employee.init && companyLoading.invite">
            Loading...
          </div>

          <div v-else-if="!employee.found && !companyLoading.invite">
            <v-alert density="compact" type="error" variant="outlined">
              This invite token does not exist, expired or has been deleted by the company admins.
            </v-alert>
          </div>

          <v-form action="#" autocomplete="off" class="space-y-4 md:space-y-6" @keydown="form.onKeydown($event)" @submit.prevent="completeInvite">
            <div class="flex flex-col mb-4 space-y-6 md:flex-row md:space-y-0 md:space-x-6">
              <div class="w-full">
                <v-text-field
                  v-model="form.first_name"
                  :label="$t('first_name')"
                  variant="outlined"
                />
              </div>
              <div class="w-full">
                <v-text-field
                  v-model="form.last_name"
                  :label="$t('last_name')"
                  variant="outlined"
                />
              </div>
            </div>
            <div>
              <v-text-field
                v-model="form.phone"
                :error-messages="form.errors.get('phone')"
                :label="$t('phone')"
                autofocus
                variant="outlined"
                @keydown="form.errors.clear('phone')"
              />

              <v-text-field
                v-model="form.password"
                :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :label="$t('password')"
                :rules="[registerFormRules.required, registerFormRules.min]"
                :type="showPassword ? 'text' : 'password'"
                hint="This is a new password for you"
                persistent-hint
                variant="outlined"
                @click:append-inner="showPassword = !showPassword"
              />
            </div>
            <div class="flex items-start">
              <div class="flex items-center h-5">
                <input id="fw-agree-checkbox" v-model="form.agree" :error-messages="form.errors.get('agree')" aria-describedby="terms" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" name="agree" required type="checkbox">
              </div>
              <div class="ml-3 text-sm">
                <label class="font-light text-gray-500 dark:text-gray-300" for="fw-agree-checkbox">I accept the
                  <a v-tippy class="font-medium text-primary-600 hover:underline dark:text-primary-500" content="Opens in a new window" href="https://fleetwire.io/terms" target="_blank" @click.stop>{{
                      $t('terms_of_service')
                    }}</a>
                  &
                  <a v-tippy class="font-medium text-primary-600 hover:underline dark:text-primary-500" content="Opens in a new window" href="https://fleetwire.io/privacy-policy" target="_blank" @click.stop>Privacy
                    Policy</a>
                </label>
              </div>
            </div>

            <v-btn :disabled="isButtonDisabled" :loading="form.busy" block size="large" @click="completeInvite">
              {{ $t('complete') }}
            </v-btn>
          </v-form>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import Form from 'vform';
import {useCompany} from '@/composables/useCompany';
import {computed, onBeforeMount, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';

const {companyLoading} = useCompany();
const route = useRoute();
const router = useRouter();
const store = useStore();

const form = ref(new Form({
  phone: '',
  first_name: '',
  last_name: '',
  password: '',
  agree: true,
  invite_token: '',
}));

const employee = computed(() => store.getters['company/employee']);
const registerFormRules = computed(() => store.getters['company/registerFormRules']);

const fetchEmployeeByInviteToken = async (invite_token: string) => {
  await store.dispatch('company/fetchEmployeeByInviteToken', invite_token);
};

const saveToken = async (data) => {
  await store.dispatch('auth/saveToken', data);
};

const saveRegisterId = async (data) => {
  await store.dispatch('auth/saveRegisterId', data);
};

const showPassword = ref(false);

const isFormValid = computed(() =>
  ['first_name', 'last_name', 'password', 'invite_token', 'phone'].every((field) => form.value[field]?.length) && form.value.agree
);

const isButtonDisabled = computed(() => form.value.busy || !isFormValid.value);


onBeforeMount(() => {
  form.value.invite_token = route.params.invite_token;
  fetchEmployeeByInviteToken(form.value.invite_token).then(() => {
    form.value.first_name = employee.value.first_name;
    form.value.last_name = employee.value.last_name;
    if (employee.value.phone) {
      form.value.phone = employee.value.phone;
    }
  });
});

const completeInvite = async () => {
  try {
    const {data} = await form.value.put(`/v1/invite/${form.value.invite_token}/complete`);

    console.table(data);

    if (data.success) {
      // Log in the user.
      const {data} = await form.value.post('/v1/auth/login');

      // Save the token.
      await saveToken({
        token: data.access_token,
        remember: true,
      });

      // remove the register id.
      await saveRegisterId('');

      await store.dispatch('auth/fetchUser');

      // Redirect home.
      router.push({name: 'dashboard'}).catch(() => {
      });
    }
  } catch (e) {
    console.dir(e);
  }
};
</script>
