import './bootstrap';
import '../css/app.css';

// Core Vue imports
import {createApp, defineAsyncComponent} from 'vue';
import App from './App.vue';

// Store and Router
import {store} from './store';
import router from './router';

// Plugins
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n/i18n';
import {initGtag} from './plugins/gtag';
import {initSentry} from './plugins/sentry';
import {plugin as VueTippy} from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import {VueDragula} from 'vue3-dragula';
import './plugins';
/** Register Layouts */
import {registerLayouts} from './layouts/register';
import {createNotivue} from 'notivue'


const notivue = createNotivue({
    position: 'top-center',
    limit: 4,
    enqueue: true,
    avoidDuplicates: true,
    notifications: {
        global: {
            duration: 2000
        }
    }
})

/** Initialize App */
const app = createApp(App)
    .use(store)
    .use(i18n)
    .use(router)
    .use(vuetify)
    .use(VueTippy, {
        directive: 'tippy', // => v-tippy
        component: 'tippy', // => <tippy/>
    })
    .use(VCalendar, {
        componentPrefix: 'vc',
    })
    .use(VueDragula, {
        logging: {
            service: true,
        },
    });

/** Register Components */
app.component('TheHelpLink', defineAsyncComponent(() =>
    import('@/components/widgets/HelpLink.vue')
));
app.component('FwDivider', defineAsyncComponent(() =>
    import('@/components/Global/Fleetify/fw-divider.vue')
));
app.component('FeatureTitle', defineAsyncComponent(() =>
    import('@/components/dashboard/FeatureTitle.vue')
));

app.use(notivue);

(async () => {
    await registerLayouts(app);
})();

/** Initialize Plugins */
if (navigator.onLine) {
    initSentry(app);
    initGtag(app, router);
} else {
    console.warn('Offline: Skipping Sentry and Google Analytics initialization.');
}


/** Mount App */
router.isReady().then(() => {
    app.mount('#app');
});

export {app};
