import axios from 'axios';
import {get, set} from 'lodash';
import {Form} from '@/plugins/FWForms/index';
import {EventBus} from '@/plugins/eventBus';
import {StorageService} from '@/services/storage.service';
import * as types from '../mutation-types';
import {
    BookingInterface,
    ListingFilter,
    ListingInterface,
    TaxCategory,
    TripExtra,
    VehicleClass,
    VehicleInterface
} from '@/types';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {RootState} from '@/types/RootState';
import {Module} from 'vuex'
import {push} from 'notivue'


export type DailyPrice = {
    date: string,
    localizedDayOfWeek: string,
    localizedShortDayOfWeek: string,
    price: number,
    source: 'custom' | 'default',
}

type VehicleIncident = {
    amount: null,
    coordinates: { x: number, y: number },
    created_at: string,
    created_by: string,
    damage_type: string,
    description: string,
    fixed_at: string,
    incident_at: string,
    updated_at: string,
    uuid: string,
}

export interface ListingState {
    loading: Record<string, boolean>,
    dialogs: Record<string, boolean>,
    snacks: Record<string, boolean>,
    listingInit: boolean,
    allListingsInit: boolean,
    allListings: ListingInterface[],
    custodialListings: ListingInterface[],
    fleetResourceListingsLoaded: boolean,
    fleetResourceListings: ListingInterface[],
    listing: ListingInterface,
    originalListing: ListingInterface,
    currentListing: ListingInterface,
    currentExtra: any,
    allExtras: any[],
    listingExtras: any[],
    currentVehicleFeatures: any[],
    allVehicleFeatures: Record<string, string>[],
    availability_message: string,
    dailyPricing: DailyPrice[],
    incidents: VehicleIncident[],
    form: any,
    listingExtraForm: any,
    listingBookableTimes: Record<string, string>[],
    filters: ListingFilter,
    filterOptions: Record<string, any>,
}

const defaultVehicle: VehicleInterface = {
    v_id: '',
    active: false,
    year: null,
    make: '',
    model: '',
    trim: '',
    color: '',
    title: '',
    vin: '',
    features: null,
    in_service: false,
    vehicle_class: null,
    plate: '',
    details: null,
    seats: null,
    doors: null,
    branch: null,
    last_odometer_reading: 0,
    toll_transponder: '',
    has_remote_capability: false,
    lockbox_code: '',
    lockbox_location: '',
    matched_bouncie_vehicle: null,
    matched_getaround_vehicle: null,
    status: null,
    unit_id: null,
    matched_turo_vehicle: null,
};

const state: ListingState = {
    loading: {
        listing: false,
        listings: false,
        addListing: false,
        saveListing: false,
        deleteListing: false,
        archiveListing: false,
        pausing: false,
        decoding_vin: false,
        unavailable_period: false,
        matchingTeslaVehicle: false,
        matchBouncieVehicle: false,
        tripExtraAdd: false,
        tripExtraEdit: false,
        import_listings: false,
        importImages: false,
        importDelete: false,
        getAllExtras: false,
        syncCarShareData: false,
        dailyPrices: false,
        updateDailyPrices: false,
        updateOdomProvider: false,
        vehicle: false,
        vehicleDetails: false,
        getIncidents: false,
        saveIncident: false,
        deleteIncident: false,
        updateIncident: false,
        listing_extras: false,
        vehicleFeatures: false,
    },
    dialogs: {
        pricingOptions: false,
    },
    filters: {
        year: {
            selected: false,
            years: [],
            available_years: [],
        },
        make: {
            selected: false,
            makes: [],
            available_makes: [],
        },
        model: {
            selected: false,
            models: [],
            available_models: [],
        },
    },

    snacks: {
        vehicle_details_success: false,
    },
    listingInit: false,
    allListingsInit: false,

    form: new Form(),
    listingExtraForm: new Form(),
    allListings: [],
    custodialListings: [],
    listing: null,

    originalListing: null,
    currentListing: {
        active: false,
        init: false,
        approved: false,
        loaded: false,
        l_id: null,
        unit_id: null,
        name: '',
        description: '',
        slug: '',
        sub_title: '',
        timezone: '',
        checkout_mode: '',
        archived_at: '',
        cover_photo: '',
        listing_image: '',
        order: 0,
        paused: false,
        use_secondary_driver: false,
        use_esign_template: false,
        longer_weekend_trip_preferred: false,
        miles_included: 0,
        miles_extra: 0,
        extras: [],
        distance_allowance_max: 0,
        minimum_request_duration: 0,
        min_age: 0,
        seasoned_driver: 0,
        provider_listings: null,
        tax_category: null,
        is_custodian: false,
        title: '',
        pricing: {
            price_type: 'flat_fee',
            price_period: 'Day',
            price_structure_id: null,
            amount: 1,
            security_deposit_credit: 0,
            security_deposit_debit: 0,
            adjusted_amount: 0,
            discountable: false,
            taxable: false,
            original_price: 0,
            price_structure: null,
            sec_dep: 0,
            total_per_day: 0,
            monthlyPercentageDiscount: 0,
            threeDayPercentageDiscount: 0,
            weeklyPercentageDiscount: 0,
        },
        listing_images: [],
        padding_before: 0,
        padding_after: 0,

        vehicle: {
            v_id: '',
            active: false,
            year: null,
            make: '',
            model: '',
            trim: '',
            color: '',
            title: '',
            vin: '',
            features: null,
            in_service: false,
            vehicle_class: null,
            plate: '',
            details: null,
            seats: null,
            doors: null,
            branch: null,
            last_odometer_reading: 0,
            toll_transponder: '',
            has_remote_capability: false,
            lockbox_code: '',
            lockbox_location: '',
            matched_bouncie_vehicle: null,
            matched_getaround_vehicle: null,
            status: null,
            unit_id: null,
            matched_turo_vehicle: null,
        },
        bookings: [],
        age_requirements: {
            min_age: 21,
            young_driver: 21,
            young_driver_fee: 19,
            young_driver_security_deposit: 250,
            seasoned_driver: 25,
        },
        commission_partners: [],
        template: null,
    },

    currentExtra: {
        title: 'Insurance',
        type: 'trip',
        active: true,
        l_id: '',
        required: true,
        code: null,
        settings: {
            display: null,
        },
        tax_category: {},
        extras: [
            {
                title: 'Basic',
                subtitle: '',
                price: 0,
                description: '<p>Example: Cover your trip with comprehensive rental insurance.</p><ul><li><p>Covers damage up to the full value of the vehicle.</p></li><li><p>Liability coverage up to state minimum.</p></li></ul>',
                recommended: false,
                allow_multi: 1,
            },
        ],
    },
    dailyPricing: [],
    allExtras: [],
    availability_message: '',
    allVehicleFeatures: [],
    currentVehicleFeatures: [],
    fleetResourceListingsLoaded: false,
    fleetResourceListings: [],
    incidents: [],
    listingExtras: [],
    listingBookableTimes: [],
    filterOptions: {
        sort: 'standard',
        make: [],
    }
};

const getters = {
    allListings: (state: ListingState) => state.allListings,
    filters: (state: ListingState) => state.filters,
    fleetResourceListings: (state: ListingState) => state.fleetResourceListings,
    allExtras: (state: ListingState) => state.allExtras,
    listingInit: (state: ListingState) => state.listingInit,
    allListingsInit: (state: ListingState) => state.allListingsInit,
    dailyPricing: (state: ListingState) => state.dailyPricing,
    originalListing: (state: ListingState) => state.originalListing,
    currentListing: (state: ListingState) => state.currentListing,
    currentListingBookings: (state: ListingState) => state.currentListing.bookings,
    listingLoading: (state: ListingState) => state.loading,
    listingSnacks: (state: ListingState) => state.snacks,
    loading: (state: ListingState) => state.loading,
    listing: (state: ListingState) => state.listing,
    form: (state: ListingState) => state.form,
    listingExtraForm: (state: ListingState) => state.listingExtraForm,
    currentListingExtra: (state: ListingState) => state.currentExtra,
    availabilityMessage: (state: ListingState) => state.availability_message,
    currentVehicleFeatures: (state: ListingState) => state.currentVehicleFeatures,
    allVehicleFeatures: (state: ListingState) => state.allVehicleFeatures,
    listingExtras: (state: ListingState) => state.listingExtras,
    dialogs: (state: ListingState) => state.dialogs,
    incidents: (state: ListingState) => state.incidents,
    filterOptions: (state: ListingState) => state.filterOptions,

    visibleListings: (state: ListingState) => {
        return (state.allListings || [])
            .filter(listing => !listing.paused && listing.active && listing.approved)
            .slice()
            .sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    },
};

function setFilterValues(state: ListingState, filterKey: string, dataKey: string, data: string[] | number[]) {
    if (data) {
        state.filters[filterKey][dataKey] = data;
    }
}

const mutations = {
    [types.SET_LISTING_LOADING](state: ListingState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_LISTING_SNACK](state: ListingState, snack: { subject: string, value: boolean }) {
        state.snacks[snack.subject] = snack?.value || false;
    },
    [types.SET_LISTING_DIALOG](state: ListingState, payload: { topic: string, value: boolean }) {
        state.dialogs[payload.topic] = payload.value;

        setTimeout(() => {
            state.dialogs[payload.topic] = false;
        }, 500);
    },
    [types.SET_LISTING_FILTER_AVAILABLE_FILTERS](state: ListingState, data: {
        years?: number[],
        makes?: string[],
        models?: string[]
    }) {
        setFilterValues(state, 'year', 'available_years', data.years);
        setFilterValues(state, 'make', 'available_makes', data.makes);
        setFilterValues(state, 'model', 'available_models', data.models);
    },
    [types.SET_LISTING_FILTER_SELECTED_FILTERS](state: ListingState, data: {
        years?: number[],
        makes?: string[],
        models?: string[]
    }) {
        setFilterValues(state, 'year', 'years', data.years);
        setFilterValues(state, 'make', 'makes', data.makes);
        setFilterValues(state, 'model', 'models', data.models);
    },
    [types.TOGGLE_LISTING_FILTER](state: ListingState, filter: 'year' | 'make' | 'model') {
        state.filters[filter].selected = !state.filters[filter].selected;
    },
    [types.CLEAR_LISTING_FILTER](state: ListingState) {
        state.filters.year.years = [];
        state.filters.make.makes = [];
        state.filters.model.models = [];
        state.filters.year.selected = false;
        state.filters.make.selected = false;
        state.filters.model.selected = false;
    },
    [types.SET_LISTING](state: ListingState, listing: ListingInterface) {
        state.listing = listing;
        state.listingInit = true;
    },
    [types.SET_LISTING_INIT](state: ListingState, isInit: boolean) {
        state.listingInit = isInit;
    },
    [types.SET_ALL_LISTINGS](state: ListingState, listings: ListingInterface[]) {
        state.allListings = listings;
        state.allListingsInit = true;
    },
    [types.SET_BOOKABLE_TIMES](state: ListingState, times: { timeF: string, time: string }[]) {
        state.listingBookableTimes = times;
    },
    [types.SET_CUSTODIAL_LISTING](state: ListingState, listings: ListingInterface[]) {
        state.custodialListings = listings;
    },
    [types.SET_FLEET_RESOURCE_LISTINGS](state: ListingState, listings: ListingInterface[]) {
        state.fleetResourceListingsLoaded = true;
        state.fleetResourceListings = listings;
    },
    [types.SET_FLEET_RESOURCE_LISTINGS_DATA](state: ListingState, {l_id, data}) {
        const listing = state.fleetResourceListings.find(item => item.l_id === l_id);

        if (listing) {
            // Iterate over the keys in the data
            Object.keys(data).forEach(key => {
                if (typeof data[key] === 'object' && data[key] !== null) {
                    // Ensure the nested object exists
                    if (!listing[key]) listing[key] = {};

                    // Iterate over the nested object's keys
                    Object.keys(data[key]).forEach(subKey => {
                        listing[key][subKey] = data[key][subKey];
                    });
                } else {
                    // Direct assignment for non-object properties
                    listing[key] = data[key];
                }
            });
        }
    },

    [types.SET_VEHICLE_VIN](state: ListingState, vin: string) {
        state.currentListing.vehicle.vin = vin;
    },
    [types.SET_CURRENT_LISTING_INCIDENTS](state: ListingState, incidents: VehicleIncident[]) {
        state.incidents = incidents;
    },
    [types.SET_LISTING_FILTER_OPTIONS](state: ListingState, filterOptions: { key: string, value: any }) {
        console.dir('SET_LISTING_FILTER_OPTIONS');
        console.dir(filterOptions);
        state.filterOptions[filterOptions.key] = filterOptions.value;
    },
    [types.SET_VEHICLE_YEAR](state: ListingState, value: number) {
        // Ensure the 'vehicle' object exists in 'currentListing'
        if (!state.currentListing.vehicle) {
            state.currentListing.vehicle = {...defaultVehicle};
        }

        // Directly set the 'year' property on the 'vehicle' object
        state.currentListing.vehicle.year = value;
    },
    [types.SET_LISTING_PRICE_TYPE](state: ListingState, value: string) {
        set(state.currentListing, 'pricing.price_type', value);
    },
    [types.SET_LISTING_PRICE_PERIOD](state: ListingState, value: string) {
        set(state.currentListing, 'pricing.price_period', value);
    },
    [types.SET_LISTING_PRICE_STRUCTURE_ID](state: ListingState, value: string) {
        set(state.currentListing, 'pricing.price_structure_id', value);
    },
    [types.SET_ALL_AVAILABLE_FEATURES](state: ListingState, features: Record<string, string>[]) {
        state.allVehicleFeatures = features;
    },
    [types.SET_VEHICLE_EXTRAS](state: ListingState, extras: TripExtra[]) {
        state.listingExtras = extras;
    },
    [types.SET_VEHICLE_FEATURES](state: ListingState, features: Record<string, string>[]) {
        state.currentVehicleFeatures = features.map((feature) => feature.value);
    },
    [types.SET_VEHICLE_MAKE](state: ListingState, value: string) {
        set(state.currentListing, 'vehicle.make', value);
    },
    [types.SET_VEHICLE_MODEL](state: ListingState, value: string) {
        set(state.currentListing, 'vehicle.model', value);
    },
    [types.SET_VEHICLE_COLOR](state: ListingState, color: string) {
        set(state.currentListing, 'vehicle.color', color);
    },
    [types.SET_VEHICLE_SEATS](state: ListingState, seats: number) {
        set(state.currentListing, 'vehicle.seats', seats);
    },
    [types.SET_VEHICLE_DOORS](state: ListingState, doors: number) {
        set(state.currentListing, 'vehicle.doors', doors);
    },
    [types.SET_VEHICLE_PLATE](state: ListingState, plate: string) {
        set(state.currentListing, 'vehicle.plate', plate);
    },
    [types.SET_VEHICLE_CLASS](state: ListingState, value: VehicleClass) {
        state.currentListing = {
            ...state.currentListing,
            vehicle: {
                ...state.currentListing.vehicle,
                vehicle_class: value
            }
        };
    },

    [types.TOGGLE_PAUSE_CURRENT_LISTING](state: ListingState) {
        state.currentListing.paused = !state.currentListing.paused;
    },
    [types.SET_CURRENT_LISTING_CHECKOUT_MODE](state: ListingState, mode: string) {
        state.currentListing.checkout_mode = mode;
    },

    [types.SET_CURRENT_DECODED_VIN](vehicleData) {
        console.dir('SET_CURRENT_DECODED_VIN');
        console.dir(vehicleData);
    },
    [types.SET_EXISTING_LISTING_DATA](state: ListingState, data: any) {
        Object.keys(data).forEach(key => {
            if (data[key] === null || typeof data[key] !== 'object') {
                // For null or non-object properties, assign directly
                state.currentListing[key] = data[key];
            } else {
                // Ensure the nested object exists
                if (!state.currentListing[key]) state.currentListing[key] = {};

                // Assign properties of the nested object
                Object.keys(data[key]).forEach(subKey => {
                    state.currentListing[key][subKey] = data[key][subKey];
                });
            }
        });
    },
    [types.SET_CURRENT_NEW_LISTING](state: ListingState, listing: ListingInterface) {
        state.currentListing.name = listing.name;
        state.currentListing.vehicle.year = listing.vehicle.year;
        state.currentListing.vehicle.make = listing.vehicle.make;
        state.currentListing.vehicle.model = listing.vehicle.model;
        state.currentListing.vehicle.color = listing.vehicle.color;
        state.currentListing.vehicle.plate = listing.vehicle.plate;
        state.currentListing.pricing.amount = listing.pricing.amount;
        state.currentListing.pricing.price_period = listing.pricing.price_period;
    },

    [types.SET_CURRENT_LISTING](state: ListingState, listing: ListingInterface) {
        state.currentListing.loaded = true;

        if (listing.vehicle === null) {
            state.currentListing = {...state.currentListing, ...listing};

            // Reset vehicle object
            state.currentListing.vehicle = {...defaultVehicle};
        } else {
            state.currentListing = {...state.currentListing, ...listing};
        }
        // Direct property assignment for age_requirements
        state.currentListing.age_requirements = {
            min_age: listing.min_age || 21,
            seasoned_driver: listing.seasoned_driver || 25
        };

        state.listingInit = true;

        // Deep clone using JSON methods
        state.originalListing = JSON.parse(JSON.stringify(state.currentListing));


        // Find and update or add the listing in allListings
        let allListingIndex = -1;
        // if state.allListings is undefined set it to an empty array
        if (!state.allListings) {
            state.allListings = [];
        }

        if (state.allListings) {
            allListingIndex = state.allListings.findIndex(l => l.l_id === listing.l_id);
        }
        if (allListingIndex >= 0) {
            state.allListings[allListingIndex] = listing;
        } else {
            state.allListings.push(listing);
        }
    },

    [types.SET_CURRENT_LISTING_BOOKINGS](state: ListingState, bookings: BookingInterface[]) {
        state.currentListing.bookings = bookings;
    },
    [types.SET_CURRENT_LISTING_MILE_ALLOWANCE](state: ListingState, data) {
        if (data.miles_included) {
            state.currentListing.miles_included = data.miles_included;
        }
        if (data.distance_allowance_max) {
            state.currentListing.distance_allowance_max = data.distance_allowance_max;
        }
    },
    [types.SET_CURRENT_LISTING_DISCOUNTABLE](state: ListingState, discountable: boolean) {
        state.currentListing.pricing.discountable = discountable
    },
    [types.SET_CURRENT_LISTING_DURATION_DISCOUNT](state: ListingState, discount: any) {
        console.dir(discount);
        if (discount.threeDayPercentageDiscount) {
            state.currentListing.pricing.threeDayPercentageDiscount = discount.threeDayPercentageDiscount;
        }
        if (discount.weeklyPercentageDiscount) {
            state.currentListing.pricing.weeklyPercentageDiscount = discount.weeklyPercentageDiscount;
        }
        if (discount.monthlyPercentageDiscount) {
            state.currentListing.pricing.monthlyPercentageDiscount = discount.monthlyPercentageDiscount;
        }
    },
    [types.SET_CURRENT_TRIP_EXTRA](state: ListingState, extras) {
        state.currentExtra = extras;
    },
    [types.SET_ALL_EXTRAS](state: ListingState, extras) {
        state.allExtras = extras;
    },
    [types.SET_LISTING_PRICE](state: ListingState, amount: number) {
        state.currentListing.pricing.amount = amount;
    },
    [types.SET_LISTING_VEHICLE](state: ListingState, data: any) {
        if (data.last_odometer_reading) {
            state.currentListing.vehicle.last_odometer_reading = data.last_odometer_reading;
        }
    },
    [types.SET_LISTING_PRICE_TAXABLE](state: ListingState, taxable: boolean) {
        state.currentListing.pricing.taxable = taxable;
    },
    [types.SET_LISTING_PRICE_TAX_CATEGORY](state: ListingState, tax_category: TaxCategory) {
        state.currentListing.tax_category = tax_category;
    },
    [types.SET_LISTING_SEC_DEP](state: ListingState, amount: number) {
        state.currentListing.pricing.sec_dep = amount;
    },
    [types.SET_LISTING_SEC_DEP_CREDIT](state: ListingState, amount: number) {
        console.dir('SET_LISTING_SEC_DEP_CREDIT');
        console.dir(amount);
        state.currentListing.pricing.security_deposit_credit = amount;
    },
    [types.SET_LISTING_SEC_DEP_DEBIT](state: ListingState, amount: number) {
        state.currentListing.pricing.security_deposit_debit = amount;
    },
    [types.SET_LISTING_MILES_OVERAGE](state: ListingState, amount: number) {
        state.currentListing.miles_extra = amount;
    },
    [types.SET_CURRENT_LISTING_IMAGES](state: ListingState, images: any[]) {
        state.currentListing.listing_images = images;
    },
    [types.SET_LISTING_AVAILABILITY_MESSAGE](state: ListingState, message: string) {
        state.availability_message = message;
    },
    [types.SET_LISTING_DAILY_PRICES](state: ListingState, prices: DailyPrice[]) {
        state.dailyPricing = prices;
    },
    [types.CLEAR_LISTING_FORM_ERRORS](state: ListingState, form_field: string | null = null) {
        if (form_field === null || form_field === undefined) {
            state.form.errors.clear();
        } else {
            state.form.errors.clear(form_field);
        }
    },
    [types.SET_LISTING_FORM_ERRORS](state: ListingState, errors: string | Record<string, string[]>) {
        state.form.errors.set(errors);
    },
    [types.RESET_CURRENT_LISTING](state: ListingState) {
        state.currentListing = {
            active: false,
            approved: false,
            archived_at: '',
            checkout_mode: '',
            commission_partners: undefined,
            cover_photo: '',
            distance_allowance_max: 0,
            is_custodian: false,
            l_id: '',
            listing_image: '',
            longer_weekend_trip_preferred: false,
            miles_extra: 0,
            miles_included: 0,
            min_age: 0,
            minimum_request_duration: 0,
            order: 0,
            paused: false,
            provider_listings: undefined,
            seasoned_driver: 0,
            slug: '',
            sub_title: '',
            timezone: '',
            title: '',
            extras: [],
            use_esign_template: false,
            use_secondary_driver: false,
            loaded: false,
            name: '',
            pricing: {
                price_type: 'flat_fee',
                price_period: 'Day',
                price_structure_id: null,
                amount: 1,
                security_deposit_credit: 0,
                security_deposit_debit: 0,
                adjusted_amount: 0,
                discountable: false,
                taxable: false,
                original_price: 0,
                price_structure: null,
                sec_dep: 0,
                total_per_day: 0,
                monthlyPercentageDiscount: 0,
                threeDayPercentageDiscount: 0,
                weeklyPercentageDiscount: 0,
            },
            listing_images: [],
            padding_before: 0,
            padding_after: 0,
            vehicle: {...defaultVehicle},

            bookings: []
        };
        state.originalListing = null;
    },
};

const actions = {

    setAvailableFilters({commit}, data: { years?: number[], makes?: string[], models?: string[] }) {
        commit(types.SET_LISTING_FILTER_AVAILABLE_FILTERS, data);
    },
    toggleListingFilter({commit}, filter: 'year' | 'make' | 'model') {
        commit(types.TOGGLE_LISTING_FILTER, filter);
    },
    setSelectedFilters({commit}, data: { years?: number[], makes?: string[], models?: string[] }) {
        commit(types.SET_LISTING_FILTER_SELECTED_FILTERS, data);
    },

    clearFilters({commit}) {
        commit(types.CLEAR_LISTING_FILTER);
    },

    /**
     * Fetch a listing
     */
    async fetchListing({commit}, l_id: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_LISTING_LOADING', 'listing', () => axios.get(`/v1/listing/${l_id}`));
            commit(types.SET_LISTING, responseData.listing);
            commit(types.SET_CURRENT_LISTING, responseData.listing);
            commit(types.SET_VEHICLE_FEATURES, responseData?.listing?.vehicle?.details);
            commit('booking/SET_BOOKING_LISTING', responseData.listing, {root: true});
            EventBus.$emit('fetch-listing-success');

            commit('SET_LISTING_INIT', true);
            return responseData.listing;
        } catch (error) {
        }
    },

    /**
     * Fetch all listings
     */
    async fetchListings({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_LISTING_LOADING', 'listings', () => axios.get('/v1/listings'));
            commit(types.SET_ALL_LISTINGS, responseData.listings);
            EventBus.$emit('fetch-listings-success');
            commit(types.SET_LISTING_INIT, true);
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },

    /**
     * Fetch all fleet resource listings
     */
    async fetchFleetResourcesListings({commit}, refresh = false) {
        if (state.fleetResourceListingsLoaded && !refresh) {
            return;
        }

        try {
            const responseData = await performApiRequest(commit, 'SET_LISTING_LOADING', 'listings', () => axios.get('/v1/fleet-resource-listings'));
            commit('SET_FLEET_RESOURCE_LISTINGS', responseData.listings);
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },


    /**
     * Add a listing
     */
    async addListing({commit}, listing: ListingInterface) {
        try {
            commit('CLEAR_LISTING_FORM_ERRORS');

            const responseData = await performApiRequest(commit, 'SET_LISTING_LOADING', 'addListing', () => axios.post('/v1/listings', listing));
            commit('SET_CURRENT_LISTING', responseData.listing);
            push.success({
                message: 'New listing added successfully!',
            });

            return responseData.listing;
        } catch (e) {
            console.dir(e);
            const errorMessage = e.response?.data?.message || 'There was an error';
            const statusCode = e.response?.status;

            commit('SET_LISTING_FORM_ERRORS', e.response.data.errors);

            if (statusCode === 401 || statusCode === 422) {
                push.error({
                    message: errorMessage,
                });
            } else {
                commit('SET_LISTING_FORM_ERRORS', e.response.data.errors);
            }

            throw e;
        }
    },


    /**
     * Delete a listing
     */
    async deleteListing({commit}, listing: ListingInterface) {
        try {
            await performApiRequest(commit, 'SET_LISTING_LOADING', 'deleteListing', () => axios.delete(`/v1/listings/${listing.l_id}`));
            EventBus.$emit('delete-listing-success');
            push.success({
                message: 'Listing has been removed',
            });
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },


    /**
     * Update the order of the listings
     */
    async updateOrder({commit}, listings: ListingInterface[]) {
        try {
            const responseData = await performApiRequest(commit, 'SET_LISTING_LOADING', 'listings_order', () => axios.put('/v1/listings/order', listings));
            commit('SET_ALL_LISTINGS', responseData.listings);
            commit('SET_FLEET_RESOURCE_LISTINGS', responseData.listings);
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },


    /**
     * Get a listings bookings
     */
    fetchListingBookings({commit}, l_id: string) {
        axios.get(`/v1/listings/${l_id}/bookings?future=1`)
            .then((response) => {
                commit(types.SET_CURRENT_LISTING_BOOKINGS, response.data.bookings);
                EventBus.$emit('fetch-listing-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_LISTING_INIT', true);
        });
    },

    /**
     * Get a listings bookings
     */
    async getVehicleIncidents({commit}, l_id: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_LISTING_LOADING', 'getIncidents', () => axios.get(`/v1/vehicle-incidents?l_id=${l_id}`));
            commit(types.SET_CURRENT_LISTING_INCIDENTS, responseData.incidents);
            EventBus.$emit('fetch-incidents-success');
        } catch (error) {
        }
    },

    /**
     * Save a vehicle incident
     */
    async saveIncident({commit}, data: VehicleIncident) {
        try {
            await performApiRequest(commit, 'SET_LISTING_LOADING', 'saveIncident', () => axios.post('/v1/vehicle-incidents', data));
            EventBus.$emit('save-incidents-success');
        } catch (error) {
        }
    },
    /**
     * Update a vehicle incident
     */
    async updateIncident({commit}, data: VehicleIncident) {
        try {
            await performApiRequest(commit, 'SET_LISTING_LOADING', 'updateIncident', () => axios.put(`/v1/vehicle-incidents/${data.uuid}`, data));
            EventBus.$emit('update-incidents-success');
        } catch (error) {
        }
    },

    /**
     * Delete a vehicle incident
     */
    async deleteIncident({commit}, uuid: string) {
        try {
            await performApiRequest(commit, 'SET_LISTING_LOADING', 'deleteIncident', () => axios.delete(`/v1/vehicle-incidents/${uuid}`));
            EventBus.$emit('delete-incidents-success');
        } catch (error) {
        }
    },


    /**
     * Get a listings bookings
     */
    checkAvailability({commit}, {l_id, dates}) {
        axios.get(`/v1/listing/${l_id}/availability?start=${dates.start}&end=${dates.end}`)
            .then((response) => {
                commit('SET_LISTING_AVAILABILITY_MESSAGE', get(response, 'data.unavailableDescription', ''));
            }).catch((e) => {
            commit('SET_LISTING_AVAILABILITY_MESSAGE', get(e, 'response.data.unavailableDescription', ''));
        }).finally(() => {
            commit('SET_LISTING_INIT', true);
        });
    },

    /**
     * Get all listings availability
     */
    checkAllAvailability({commit}, dates: { start: string, end: string }) {
        axios.get(`/v1/listings/availability?start=${dates.start}&end=${dates.end}`)
            .then((response) => {
                commit('SET_LISTING_AVAILABILITY_MESSAGE', get(response, 'data.unavailableDescription', ''));
            }).catch((e) => {
            commit('SET_LISTING_AVAILABILITY_MESSAGE', get(e, 'response.data.unavailableDescription', ''));
        }).finally(() => {
            commit('SET_LISTING_INIT', true);
        });
    },


    /**
     * Set an unavailable period
     */
    async setUnavailablePeriod({commit}, period: {
        l_id: string,
        trip_start: string,
        trip_end: string,
        source: string,
        notes: string,
        supplemental: boolean
    }) {
        try {
            commit('CLEAR_LISTING_FORM_ERRORS');
            const responseData = await performApiRequest(commit, 'SET_LISTING_LOADING', 'unavailable_period', () => axios.post(`/v1/listings/${period.l_id}/unavailability`, period));
            push.success({
                message: 'Unavailable period set successfully!',
            });
            commit(types.SET_CURRENT_LISTING_BOOKINGS, responseData.bookings);
            EventBus.$emit('set-unavailable-period-success', responseData);

            commit('SET_LISTING_INIT', true);
        } catch (error) {
            commit('SET_LISTING_FORM_ERRORS', error.response.data.errors);
            push.error({
                message: 'There was an error setting the unavailable period',
            });
        }
    },

    /**
     * Set an unavailable period from a FW booking
     */
    setUnavailablePeriodFromFWBooking({commit}, period: { l_id: string, source: string, b_id: string }) {
        commit('SET_LISTING_LOADING', {subject: 'unavailable_period', loading: true});
        commit('CLEAR_LISTING_FORM_ERRORS');

        axios.post(`/v1/listings/${period.l_id}/set-existing-unavailability`, period)
            .then((response) => {
                push.success({
                    message: 'Unavailable period set successfully!',
                });
                commit(types.SET_CURRENT_LISTING_BOOKINGS, get(response, 'data.bookings', []));
                EventBus.$emit('set-unavailable-period-success', response.data);
            }).catch((e) => {
            console.dir(get(e, 'response.data.errors'));
            commit('SET_LISTING_FORM_ERRORS', e.response.data.errors);
            push.error({
                message: 'There was an error setting the unavailable period',
            });
        }).finally(() => {
            commit('SET_LISTING_LOADING', {subject: 'unavailable_period', loading: false});
        });
    },


    /**
     * Remove an unavailable period
     */
    removeUnavailablePeriod({commit}, period: { l_id: string, b_id: string }) {
        commit('SET_LISTING_LOADING', {subject: 'unavailable_period', loading: true});

        axios.delete(`/v1/listings/${period.l_id}/unavailability/${period.b_id}`)
            .then((response) => {
                commit(types.SET_CURRENT_LISTING_BOOKINGS, get(response, 'data.bookings', []));
                push.success({
                    message: 'Unavailable period removed successfully!',
                });
                EventBus.$emit('RemoveUnavailablePeriodSuccess');
            }).catch(() => {
            push.error({
                message: 'There was an error removing this unavailable period',
            });
        }).finally(() => {
            commit('SET_LISTING_LOADING', {subject: 'unavailable_period', loading: false});
            commit('SET_LISTING_INIT', true);
        });
    },


    /**
     * Update an unavailable period
     */
    async updateUnavailablePeriod({commit}, period: {
        l_id: string,
        b_id: string,
        trip_start: string,
        trip_end: string
    }) {
        try {
            const responseData = await performApiRequest(commit, 'SET_LISTING_LOADING', 'unavailable_period', () => axios.put(`/v1/listings/${period.l_id}/unavailability/${period.b_id}`, period));
            commit(types.SET_CURRENT_LISTING_BOOKINGS, responseData.bookings);
            push.success({
                message: 'Unavailable period updated successfully!',
            });
            EventBus.$emit('UpdateUnavailablePeriodSuccess');
            commit('SET_LISTING_INIT', true);

            return responseData;
        } catch (error) {
            EventBus.$emit('update-unavailable-period-failed');
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },


    /**
     * Get a company listing
     */
    async fetchCompanySingleListings({commit}, l_id: string) {
        commit('SET_LISTING_LOADING', {subject: 'listing', loading: true});

        await axios.get(`/v1/listings/${l_id}`)
            .then((response) => {
                commit('SET_CURRENT_LISTING', response.data.listing);
                commit('SET_VEHICLE_FEATURES', get(response.data.listing, 'vehicle.details', {}));
                commit('SET_VEHICLE_EXTRAS', get(response.data.listing, 'extras', []));
                EventBus.$emit('fetch-listing-success');
            }).catch(() => {
            }).finally(() => {
                commit('SET_LISTING_LOADING', {subject: 'listing', loading: false});
                commit('SET_LISTING_INIT', true);
            });
    },

    fetchCompanySingleListingExtras({commit}, l_id: string) {
        commit('SET_LISTING_LOADING', {subject: 'listing_extras', loading: true});

        axios.get(`/v1/listings/${l_id}/extras`)
            .then((response) => {
                commit('SET_VEHICLE_EXTRAS', get(response.data, 'extras', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_LISTING_LOADING', {subject: 'listing_extras', loading: false});
        });
    },

    /**
     * Update a vehicles details
     */
    async updateVehicleDetails({commit, dispatch}, data: { v_id: string, l_id: string, vehicleFeatures: string[] }) {
        try {
            await performApiRequest(commit, 'SET_LISTING_LOADING', 'vehicleDetails', () => axios.put(`/v1/vehicles/${data.v_id}/details`, data));
            EventBus.$emit('save-vehicle-details-success');
            dispatch('fetchCompanySingleListings', data.l_id);
            commit('SET_LISTING_SNACK', {subject: 'vehicle_details_success', value: true});
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },


    /**
     * Reset the current listing
     */
    resetListing({commit}) {
        commit(types.RESET_CURRENT_LISTING);
    },


    /**
     * Save a listing
     */
    async saveListing({commit}, listing: ListingInterface) {
        try {
            const responseData = await performApiRequest(commit, 'SET_LISTING_LOADING', 'saveListing', () => axios.put(`/v1/listings/${listing.l_id}`, listing));
            commit('CLEAR_LISTING_FORM_ERRORS');
            push.success({
                message: 'Listings saved successfully',
            });
            commit('SET_CURRENT_LISTING', responseData.listing);
            EventBus.$emit('save-listing-success');
        } catch (e) {
            commit('SET_LISTING_FORM_ERRORS', e.response.data.errors);

            push.error({
                message: e.response?.data?.errors?.message || 'There was an error pausing the listing',
            });
        }
    },


    /**
     * Pause a listing
     */
    async pauseListing({commit, state}, data: { mode: string, listings: string[] }) {
        try {
            await performApiRequest(commit, 'SET_LISTING_LOADING', 'pausing', () => axios.put('/v1/listings/pause', {
                mode: data.mode,
                listings: data.listings
            }));
            push.success({
                message: 'Listings saved successfully',
            });
            EventBus.$emit('listings-toggle-pause-success');

            // Loop through data.listings and update the paused state
            data.listings.forEach((listing) => {
                const index = state.fleetResourceListings.findIndex(item => item.l_id === listing);

                if (index !== -1) {
                    commit('SET_FLEET_RESOURCE_LISTINGS_DATA', {
                        l_id: listing,
                        data: {
                            paused: !state.fleetResourceListings[index].paused,
                        },
                    });
                }
            });
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error pausing the listing',
            });
        }
    },


    /**
     * Fetch a Listings daily pricing
     */
    async fetchDailyPrices({commit}, {l_id, start, end}) {
        try {
            let qString = '';
            if (start) {
                qString += `?start=${start}`;
            }
            if (end) {
                qString += start ? `&end=${end}` : `?end=${end}`;
            }

            const responseData = await performApiRequest(commit, 'SET_LISTING_LOADING', 'dailyPrices', () => axios.get(`/v1/listings/${l_id}/daily_pricing${qString}`));
            commit(types.SET_LISTING_DAILY_PRICES, responseData.dailyPricing);
            EventBus.$emit('get-listings-daily-price-success');
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },


    /**
     * Update a Listings daily pricing
     */
    updateDailyPrice({commit}, data: { l_id: string, date: string, price: number, sources: object }) {
        commit('SET_LISTING_LOADING', {subject: 'updateDailyPrices', loading: true});

        axios.put(`/v1/listings/${data.l_id}/daily_pricing`, data)
            .then((response) => {
                EventBus.$emit('update-listings-daily-price-success', response);
            }).catch(() => {
        }).finally(() => {
            commit('SET_LISTING_LOADING', {subject: 'updateDailyPrices', loading: false});
        });
    },


    /**
     * Get all Extras
     */
    getAllExtras({commit, state}) {
        commit('SET_LISTING_LOADING', {subject: 'getAllExtras', loading: true});

        axios.get('/v1/extras', state.currentExtra)
            .then((response) => {
                commit(types.SET_ALL_EXTRAS, get(response, 'data.extras'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_LISTING_LOADING', {subject: 'getAllExtras', loading: false});
        });
    },


    /**
     * Import Turo listings
     */
    importTuroListings({commit}, {listings, turo_uuid}) {
        commit('SET_LISTING_LOADING', {subject: 'import_listings', loading: true});
        EventBus.$emit('save-imported-listings-started', 'turo');

        axios.post(`/v1/listings/import/turo/${turo_uuid}`, {listings})
            .then((response) => {
                commit('company/SET_COMPANY', response.data.company, {root: true});
                commit('SET_ALL_LISTINGS', get(response.data.company, 'listings', []));
                EventBus.$emit('save-turo-imported-listings-success');
                push.success({
                    message: 'Turo listing Import success',
                });
            }).catch((e) => {
            commit('SET_LISTING_FORM_ERRORS', e.response.data.errors);
        }).finally(() => {
            EventBus.$emit('save-turo-imported-listings-failed');
            commit('SET_LISTING_LOADING', {subject: 'import_listings', loading: false});
        });
    },

    syncTuroListings({commit}, {listings, turo_uuid}) {
        commit('SET_LISTING_LOADING', {subject: 'import_listings', loading: true});

        axios.post(`/v1/listings/sync/turo/${turo_uuid}/listings`, {listings})
            .then(() => {
            }).catch((e) => {
            commit('SET_LISTING_FORM_ERRORS', e.response.data.errors);
        }).finally(() => {
            EventBus.$emit('save-turo-imported-listings-failed');
            commit('SET_LISTING_LOADING', {subject: 'import_listings', loading: false});
        });
    },

    /**
     * Match Turo vehicle
     */
    matchTuroVehicle({commit}, data) {
        commit('SET_LISTING_LOADING', {subject: 'saveListing', loading: true});

        axios.post(`/v1/listings/${data.listing_id}/match-turo-vehicle`, {
            id: data.turo_vehicle_id,
            turo_account_uuid: data.turo_account_uuid
        }).then((response) => {
            commit('SET_ALL_LISTINGS', get(response.data, 'listings', []));
            commit('SET_CURRENT_LISTING', get(response.data, 'listing', []));
            push.success({
                message: 'Matched Turo vehicle has been added from this listing.',
            });
            EventBus.$emit('matched-turo-vehicle-with-listing-success');
        }).catch((error) => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_LISTING_LOADING', {subject: 'saveListing', loading: false});
        });
    },

    /**
     * Match Bouncie vehicles
     */
    matchBouncieVehicle({commit}, {l_id, bouncie_uuid}) {
        commit('SET_LISTING_LOADING', {subject: 'matchBouncieVehicle', loading: true});

        axios.post(`/v1/listings/${l_id}/match-bouncie-vehicle`, {id: bouncie_uuid})
            .then((response) => {
                commit('SET_ALL_LISTINGS', get(response.data, 'listings', []));
                commit('SET_CURRENT_LISTING', get(response.data, 'listing', []));
                push.success({
                    message: 'Matched Bouncie vehicle has been added from this listing.',
                });
                EventBus.$emit('matched-bouncie-vehicle-with-listing-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_LISTING_LOADING', {subject: 'matchBouncieVehicle', loading: false});
        });
    },


    /**
     * Un-match Bouncie vehicles
     */
    unMatchBouncieVehicle({commit}, l_id: string) {
        commit('SET_LISTING_LOADING', {subject: 'saveListing', loading: true});

        axios.delete(`/v1/listings/${l_id}/matched-bouncie-vehicle`)
            .then((response) => {
                commit('SET_ALL_LISTINGS', get(response.data, 'listings', []));
                commit('SET_CURRENT_LISTING', get(response.data, 'listing', []));
                push.success({
                    message: 'Matched Bouncie vehicle has been removed from this listing.',
                });
                EventBus.$emit('unmatched-bouncie-vehicle-with-listing-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_LISTING_LOADING', {subject: 'saveListing', loading: false});
        });
    },

    matchTeslaVehicle({commit}, data: { l_id: string, tesla: string }) {
        commit('SET_LISTING_LOADING', {subject: 'matchingTeslaVehicle', loading: true});
        axios.post(`/v1/listings/${data.l_id}/match-tesla-vehicle`, {uuid: data.tesla})
            .then((response) => {
                commit('SET_ALL_LISTINGS', get(response.data, 'listings', []));
                commit('SET_CURRENT_LISTING', get(response.data, 'listing', []));
                commit('tesla/SET_TESLA_CONNECTIONS', get(response.data, 'company.connections.tesla', []), {root: true});
                commit('tesla/SET_CURRENT_WORKING_TESLA_VEHICLE', get(response.data, 'teslaVehicle', []), {root: true});
                push.success({
                    message: 'Matched Tesla vehicle has been added from this listing.',
                });
                EventBus.$emit('matched-tesla-vehicle-with-listing-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_LISTING_LOADING', {subject: 'matchingTeslaVehicle', loading: false});
        });
    },

    matchGetaroundVehicle({commit}, vehicle) {
        commit('SET_LISTING_LOADING', {subject: 'saveListing', loading: true});
        axios.post(`/v1/company/${StorageService.getCompanyUuid()}/listings/${state.currentListing.l_id}/match-getaround-vehicle`, {id: vehicle.id})
            .then((response) => {
                commit('SET_ALL_LISTINGS', response.data.listings);
                commit('SET_CURRENT_LISTING', response.data.listing);
                push.success({
                    message: 'Matched Getaround vehicle has been added from this listing.',
                });
                EventBus.$emit('matched-getaround-vehicle-with-listing-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_LISTING_LOADING', {subject: 'saveListing', loading: false});
        });
    },


    /**
     * Un-match a Turo vehicle from Fleetwire
     */
    unMatchTuroVehicle({commit, state}) {
        commit('SET_LISTING_LOADING', {subject: 'saveListing', loading: true});
        axios.delete(`/v1/listings/${state.currentListing.l_id}/matched-turo-vehicle`)
            .then((response) => {
                commit('SET_ALL_LISTINGS', response.data.listings);
                commit('SET_CURRENT_LISTING', response.data.listing);
                push.success({
                    message: 'Matched Turo vehicle has been removed from this listing.',
                });
            }).catch(() => {
        }).finally(() => {
            commit('SET_LISTING_LOADING', {subject: 'saveListing', loading: false});
        });
    },

    unMatchTeslaVehicle({commit}, listing: ListingInterface) {
        commit('SET_LISTING_LOADING', {subject: 'matchingTeslaVehicle', loading: true});
        axios.delete(`/v1/company/${StorageService.getCompanyUuid()}/listings/${listing.l_id}/matched-tesla-vehicle`)
            .then((response) => {
                commit('SET_ALL_LISTINGS', response.data.listings);
                commit('SET_CURRENT_LISTING', response.data.listing);
                commit('tesla/SET_CURRENT_WORKING_TESLA_VEHICLE', response.data?.teslaVehicle, {root: true});
                push.success({
                    message: 'Matched Tesla vehicle has been removed from this listing.',
                });
            }).catch(() => {
        }).finally(() => {
            commit('SET_LISTING_LOADING', {subject: 'matchingTeslaVehicle', loading: false});
        });
    },

    unMatchGetaroundVehicle({commit}) {
        commit('SET_LISTING_LOADING', {subject: 'saveListing', loading: true});
        axios.delete(`/v1/company/${StorageService.getCompanyUuid()}/listings/${state.currentListing.l_id}/matched-getaround-vehicle`)
            .then((response) => {
                commit('SET_CURRENT_LISTING', response.data.listing);
                push.success({
                    message: 'Matched Getaround vehicle has been removed from this listing.',
                });
            }).catch(() => {
        }).finally(() => {
            commit('SET_LISTING_LOADING', {subject: 'saveListing', loading: false});
        });
    },


    /**
     * Archive a listing
     */
    async archiveListing({commit}, listing: ListingInterface) {
        try {
            await performApiRequest(commit, 'SET_LISTING_LOADING', 'archiveListing', () => axios.post(`/v1/listings/${listing.l_id}/archive`));
            push.success({
                message: 'Listing has been archived',
            });
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },


    /**
     * Delete a listing
     */
    async deleteListings({commit}, listings: string[]) {
        try {
            await performApiRequest(commit, 'SET_LISTING_LOADING', 'deleteListing', () => axios.post('/v1/listings/delete', {listings}));
            EventBus.$emit('delete-listing-success');
            push.success({
                message: 'Listing has been removed',
            });
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },

    updateListingImageOrder({commit}, images: string[]) {
        console.dir(images);
        commit('SET_LISTING_LOADING', {subject: 'saveListing', loading: true});

        axios.put(`/v1/listings/${state.currentListing.l_id}/images`, {images})
            .then(() => {
            }).catch(() => {
        }).finally(() => {
            commit('SET_LISTING_LOADING', {subject: 'saveListing', loading: false});
        });
    },

    /**
     *
     * @param commit
     */
    resetAndReImportCarShareImages({commit}) {
        commit('SET_LISTING_LOADING', {subject: 'importImages', loading: true});
        axios.post(`/v1/listings/${state.currentListing.l_id}/reset-import-images`)
            .then(() => {
                EventBus.$emit('reimport-listing-image-success');
            }).catch((e) => {
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_LISTING_LOADING', {subject: 'importImages', loading: false});
        });
    },

    /**
     *
     */
    async getVehicleFeatures({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_LISTING_LOADING', 'vehicleFeatures', () => axios.get('/v1/vehicle-features'));
            commit(types.SET_ALL_AVAILABLE_FEATURES, responseData.features);
            EventBus.$emit('sync-listing-data-success', responseData.details);
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }
    },

    /**
     * Sync car share data
     */
    syncCarShareData({commit}) {
        commit('SET_LISTING_LOADING', {subject: 'syncCarShareData', loading: true});

        axios.post(`/v1/listings/${state.currentListing.l_id}/sync-car-share-data`)
            .then((response) => {
                EventBus.$emit('sync-listing-data-success', response.data.details);
            }).catch(() => {
        }).finally(() => {
            commit('SET_LISTING_LOADING', {subject: 'syncCarShareData', loading: false});
        });
    },


    /**
     * Delete a single listing image
     */
    deleteListingImage({state, commit}, image: string) {
        commit('SET_LISTING_LOADING', {subject: 'importDelete', loading: true});

        axios.delete(`/v1/listings/${state.currentListing.l_id}/images/${image}`)
            .then((response) => {
                push.success({
                    message: 'Listing image removed',
                });
                commit(types.SET_CURRENT_LISTING, response.data.listing);
                EventBus.$emit('delete-listing-image-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_LISTING_LOADING', {subject: 'importDelete', loading: false});
        });
    },

    setListingBookableTimes({commit}) {
        const startTimes = [];
        let time: string;
        let timeF: string;
        let ampm: string;
        let lefZero: string | number;

        for (let i = 0; i < 24; i++) {
            time = `${i}:00`;
            timeF = i > 12 ? `${(i - 12)}:00` : `${i}:00`;
            ampm = i >= 12 ? 'pm' : 'am';
            lefZero = i >= 10 ? '' : 0;
            startTimes.push({timeF: `${timeF} ${ampm}`, time: `${lefZero}${time}`});

            time = `${i}:30`;
            timeF = i >= 12 ? `${(i - 12)}:30` : `${i}:30`;
            ampm = i >= 12 ? 'pm' : 'am';
            lefZero = i >= 10 ? '' : 0;
            startTimes.push({timeF: `${timeF} ${ampm}`, time: `${lefZero}${time}`});
        }

        commit('SET_BOOKABLE_TIMES', startTimes);
    },

    async vinDecode({commit}, vin: string) {
        if (vin.length === 17) {
            try {
                const responseData = await performApiRequest(commit, 'SET_LISTING_LOADING', 'decoding_vin', () => axios.get(`/v1/decode-vin/${vin}`));
                EventBus.$emit('listing-vin-decoded-success', responseData.vehicleData);
                commit('SET_CURRENT_DECODED_VIN', responseData.vehicleData);
            } catch (error) {
                push.error({
                    message: error?.response?.data?.message || 'There was an error',
                });
            }
        }
    },


    /**
     * Import listings from csv
     */
    importCSVListings({commit}, listings) {
        commit('SET_LISTING_LOADING', {subject: 'import_listings', loading: true});
        commit('CLEAR_LISTING_FORM_ERRORS');

        axios.post('/v1/listings/import/csv', {listings})
            .then((response) => {
                commit('listing/SET_ALL_LISTINGS', response.data.listings, {root: true});
                push.success({
                    message: 'Imported listings successfully',
                });
                EventBus.$emit('import-csv-vehicles-success');
            }).catch((e) => {
            console.dir(e.response.data);
            commit('SET_LISTING_FORM_ERRORS', e.response.data.errors);

            push.error({
                message: e.response?.data?.errors?.plan_limits?.[0] || 'There was an error',
            });
        }).finally(() => {
            commit('SET_LISTING_LOADING', {subject: 'import_listings', loading: false});
        });
    },

    /**
     * Update odometer with a provider.
     */
    updateOdomWithProvider({commit}, {listing, provider}) {
        commit('SET_LISTING_LOADING', {subject: 'updateOdomProvider', loading: true});

        axios.post(`/v1/listings/${listing.l_id}/provider-update-odometer`, {provider})
            .then(() => {
                EventBus.$emit('update-odometer-with-provider-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_LISTING_LOADING', {subject: 'updateOdomProvider', loading: false});
        });
    },
};

const listingModule: Module<ListingState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default listingModule;