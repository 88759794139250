<template>
  <nav class="Sidebar-ewrzQP hJbBaq" data-tid="Sidebar" open="" width="320px">
    <div class="Div-bMiTSW Inner-eASQkV fNYben felMTq">
      <div class="BSidebar-loQfvV kWdRwd">
        <div aria-expanded="false" class="Wrapper-iVPPu">
          <div class="Trigger-huDzbV eBqQQq">
            <div class="BTemplateSelect-cjqqCH fmnNSR" data-tid="Template select">
              <div class="H3-bOhkOq lgizXY">
                <v-icon>mdi-file-document-outline</v-icon>
                <div class="Div-bMiTSW Spacer-dThvcf  jaLOqC"></div>
                <div class="Label-edLtQd fZbXLc">{{ themeName }}</div>
                <div class="Div-bMiTSW Spacer-dThvcf  jaLOqC"></div>
                <i class="I-gmFDEA Icon-chbHoy eaYeOY hGUdVH far fa-chevron-down fa-fw"></i>
                <v-icon>mdi-chevron-down</v-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="Sections-ivYnKr kQUSAR">
          <div class="Container-goTTRW cneSZt">
            <div class="Section-eCkBMz iwkHtv">
              <div class="InnerContainer-dPNjJg gAUqSD">
                <i class="I-gmFDEA Icon-chbHoy eaYeOY hMXRVX far fa-fw fa-fw"></i>
              </div>
              <div class="InnerContainer-dPNjJg kwXbLo" @mouseleave="containerHoverLeave" @mouseover="containerHover">
                <div class="LabelContainer-XFiUr kuxGbJ">
                  <label class="Label-eJXSuD fUNwul" cursor="pointer">Header</label></div>
                <div class="Div-bMiTSW Spacer-dThvcf  jaLOqC"></div>
                <div class="Div-bMiTSW Description-dTYEvj  dfsrYj">Edit for all pages</div>
              </div>
            </div>
          </div>
          <hr class="Hr-bhdsCb cfZQHO">
          <div class="sortable-container" data-tid="Sections">
            <div v-for="(section,i) in orderedThemeSections" :key="i" class="Container-goTTRW cneSZt">
              <div class="Section-eCkBMz iwkHtv">
                <div v-if="showChevronUp(i)" class="InnerContainer-dPNjJg gvmluV">
                  <v-icon class="I-gmFDEA Icon-chbHoy eaYeOY hMXRVX far fa-chevron-up fa-fw">mdi-chevron-up</v-icon>
                </div>
                <div v-if="!showChevronUp(i)" class="InnerContainer-dPNjJg gAUqSD">
                  <i class="I-gmFDEA Icon-chbHoy eaYeOY hMXRVX far fa-fw fa-fw"></i>
                </div>
                <div class="InnerContainer-dPNjJg kwXbLo" @mouseleave="containerHoverLeave(i)" @mouseover="containerHover(i)">
                  <div class="LabelContainer-XFiUr kuxGbJ">
                    <label class="Label-eJXSuD fUNwul">
                      {{ sectionLabel(i) }} {{ section.disabled }}
                    </label>
                  </div>
                  <div class="Div-bMiTSW Spacer-dThvcf  jaLOqC"></div>
                  <v-icon v-if="section.disabled" class="I-gmFDEA Icon-chbHoy eaYeOY hMXRVX far fa-eye fa-fw" size="small">
                    mdi-eye-outline
                  </v-icon>
                  <v-icon v-if="!section.disabled" class="I-gmFDEA Icon-chbHoy eaYeOY hMXRVX far fa-eye fa-fw" size="small">
                    mdi-eye-outline
                  </v-icon>
                  <div class="Div-bMiTSW Spacer-dThvcf  fbTExq"></div>
                  <v-icon class="I-gmFDEA Icon-chbHoy eaYeOY hMXRVX far fa-grip-lines sort" size="small">
                    mdi-reorder-horizontal
                  </v-icon>
                </div>
              </div>

              <div id="firstContainer" class="sortable-container" data-tid="image-banner blocks" style="transition-duration: 300ms; transform: translate3d(0px, 0px, 0px);">
                <div v-for="(block, j) in sectionBlocksOrdered(section)" :key="`section${i}-block${j}`" class="drag-item">
                  <div class="Container-goTTRW cneSZt">
                    <div :class="sectionItemClassObject(block)" class="Section-eCkBMz ">
                      <div class="InnerContainer-dPNjJg gAUqSD">
                        <i class="I-gmFDEA Icon-chbHoy eaYeOY hMXRVX far fa-fw fa-fw"></i>
                      </div>
                      <div class="InnerContainer-dPNjJg kwXbLo" @mouseleave="containerHoverLeave(i, block, j)" @mouseover="containerHover(i, block, j)">
                        <div class="LabelContainer-XFiUr kuxGbJ">
                          <label class="Label-eJXSuD cMyTnJ" cursor="pointer">
                            {{ blockLabel(block) }}
                            <div class="BlockSummary-bMgBTA eNDwgr">
                              {{ blockSummary(block) }}
                            </div>
                          </label>
                        </div>
                        <v-icon v-if="!block.disabled" class="I-gmFDEA Icon-chbHoy eaYeOY hMXRVX far fa-eye fa-fw" size="small" @click="toggleDisableBlock(i, j, true)">
                          mdi-eye-outline
                        </v-icon>
                        <v-icon v-if="block.disabled" class="I-gmFDEA Icon-chbHoy eaYeOY hMXRVX far fa-eye-slash fa-fw" size="small" @click="toggleDisableBlock(i, j, false)">
                          mdi-eye-off-outline
                        </v-icon>
                        <div class="Div-bMiTSW Spacer-dThvcf  fbTExq"></div>
                        <v-icon class="I-gmFDEA Icon-chbHoy eaYeOY hMXRVX far fa-grip-lines sort" size="small">
                          mdi-reorder-horizontal
                        </v-icon>
                      </div>
                    </div>
                  </div>
                </div>
                <span v-if="canAddBlock(i)" aria-expanded="false" class="Wrapper-iVPPu">
                  <div class="Trigger-huDzbV eBqQQq">
                    <div class="Container-goTTRW cneSZt" data-tid="image-banner add block">
                      <div class="Section-eCkBMz iwkHtv">
                        <div class="InnerContainer-dPNjJg gAUqSD">
                          <i class="I-gmFDEA Icon-chbHoy eaYeOY hMXRVX far fa-fw fa-fw"></i>
                        </div>
                        <div class="InnerContainer-dPNjJg hMXcFq">
                        <a class="A-fypPmk Link-cksnAZ  cWXaAG" color="primary">
                          <v-icon class="mb-1 I-gmFDEA Icon-chbHoy eaYeOY hGUdVH far fa-plus-circle fa-fw" color="rgb(43, 128, 255)" size="small">mdi-plus-circle-outline</v-icon>
                          <span class="Span-dxRpuh fqDCBT">Add block </span>
                        </a>
                      </div>
                      </div>
                    </div>
                  </div>
                </span>
              </div>

            </div>
          </div>
        </div>

        <div class="Div-bMiTSW Spacer-dThvcf jVlDvc"></div>

        <span aria-expanded="false" class="Wrapper-iVPPu eyAPpw">
          <div class="Trigger-huDzbV eBqQQq">
            <div class="PickerContainer-biFfmk hTPolk">
              <span class="Span-dxRpuh ButtonContainer-kOntak fqDCBT hDDKkN" color="white">
                <v-btn block variant="outlined">Add section</v-btn>
              </span>
            </div>
          </div>
        </span>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import {EventBus} from '@/plugins/eventBus';
import {mapActions, mapGetters} from 'vuex';
import {get} from 'lodash';
import dragula from 'dragula';

export default {
  name: 'LeftSideNav',

  data() {
    return {
      localTheme: null,
    };
  },

  created() {
    this.getStoreThemes().then(() => {
      this.localTheme = get(this.storeThemes, 0);
      this.initDragula();
    });
  },

  computed: {
    ...mapGetters({
      storeThemes: 'theme/storeThemes',
    }),
    themeName() {
      return get(this.storeThemes, '0.name', 'default');
    },
    themeSections() {
      return get(this.storeThemes, '0.theme_data.sections', {});
    },
    themeSectionsOrder() {
      return get(this.storeThemes, '0.theme_data.order', []);
    },
    orderedThemeSections() {
      const order = this.themeSectionsOrder;
      const sections = this.themeSections;
      const orderedSections = {};
      order.forEach((key) => {
        orderedSections[key] = sections[key];
      });
      return orderedSections;
    },
  },

  methods: {
    ...mapActions({
      getExportDetails: 'settings/getExportDetails',
      getStoreThemes: 'theme/getStoreThemes',
      updateStoreTheme: 'theme/updateStoreTheme',
    }),
    initDragula() {
      console.dir('initDragula');
      console.dir(document.getElementById('firstContainer'));
      const drake = dragula([document.getElementById('firstContainer')], {
        moves: (el, source, handle) => {
          console.dir('moves');
          console.dir(el);
          console.dir(handle);
          console.dir(el.classList);
          console.dir(handle.classList.contains('sort'));
          return handle.classList.contains('sort');
        },
      });

      drake.on('drag', (el) => {
        // add 'is-moving' class to element being dragged
        el.classList.add('is-moving');
      })
        // .on('shadow', (el, container, source) => {
        //   if (el !== container.children[container.children.length - 1]) {
        //     container.appendChild(el);
        //   }
        // })
        .on('dragend', (el) => {
          // remove 'is-moving' class from element after dragging has stopped
          el.classList.remove('is-moving');

          // add the 'is-moved' class for 600ms then remove it
          window.setTimeout(() => {
            el.classList.add('is-moved');
            window.setTimeout(() => {
              el.classList.remove('is-moved');
            }, 600);
          }, 100);
        });
    },
    toggleDisableBlock(sectionId, blockId, disable) {
      console.dir('disableBlock');
      console.dir(sectionId);
      console.dir(blockId);
      const section = get(this.localTheme, `theme_data.sections.${sectionId}`, {});
      console.dir(section);

      if (section) {
        const blocks = get(section, 'blocks', []);
        blocks[blockId].disabled = disable;
        this.updateStoreTheme(this.localTheme);
      }
    },
    /**
     * @param section
     * @returns {boolean}
     */
    showChevronUp(section) {
      return section.indexOf('date-picker') !== 0
        && section.indexOf('products') !== 0
        && section.indexOf('spacer') !== 0;
    },
    sectionItemClassObject(block) {
      return {
        bJkMPt: block.disabled,
        iwkHtv: !block.disabled,
      };
    },
    canAddBlock(section) {
      return section.indexOf('date-picker') !== 0
        // && section.indexOf('products') !== 0
        && section.indexOf('spacer') !== 0;
    },
    containerHover(section, block = null, id = null) {
      EventBus.$emit('mouse-over-theme-component', {section, block, id});
    },
    containerHoverLeave(section, block = null, id = null) {
      EventBus.$emit('mouse-leave-theme-component', {section, block, id});
    },
    sectionBlocksOrdered(section) {
      const blockOrder = get(section, 'block_order', []);
      const blocks = get(section, 'blocks', {});
      const orderedBlocks = {};
      blockOrder.forEach((key) => {
        orderedBlocks[key] = blocks[key];
      });
      return orderedBlocks;
    },
    sectionLabel(section) {
      // if section begins with image-banner, return Image Banner
      if (section.indexOf('image-banner') === 0) {
        return 'Image Banner';
      }
      if (section.indexOf('date-picker') === 0) {
        return 'Date Picker';
      }
      if (section.indexOf('products') === 0) {
        return 'Products';
      }
      if (section.indexOf('spacer') === 0) {
        return 'Spacer';
      }
      if (section.indexOf('columns') === 0) {
        return 'Columns';
      }

      return section;
    },
    blockLabel(block) {
      if (block.type === 'heading') {
        return 'Heading';
      }
      if (block.type === 'text') {
        return 'Text';
      }
      if (block.type === 'button') {
        return 'Button';
      }
      if (block.type === 'column') {
        return 'Column';
      }
    },
    blockSummary(block) {
      if (block.type === 'heading') {
        return get(block, 'settings.text', 'na');
      }
      if (block.type === 'text') {
        return get(block, 'settings.text', 'na');
      }
      if (block.type === 'button') {
        return get(block, 'settings.text', 'na');
      }
      if (block.type === 'column') {
        return get(block, 'settings.title', 'na');
      }
    },
  },
};
</script>

<style>
.drag-item {
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.drag-item.is-moving {
  transform: scale(1.1);
}

.gu-transit {
  opacity: 0.5;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 6px;

  transform: translate3d(0px, 29px, 0px);
  z-index: 1000;
}

.gu-mirror {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
</style>

<style>
.fzkgnE *, .fzkgnE ::before, .fzkgnE ::after {
  box-sizing: border-box;
}

.hJbBaq {
  background: rgb(247, 248, 248);
  transition: width 250ms cubic-bezier(0.86, 0, 0.07, 1) 0s;
  display: block;
  border-color: rgb(226, 230, 235);
  overflow-x: hidden;
  position: sticky;
  height: calc(100vh - 62px);
  order: 1;
  z-index: 29;
  left: 0;
  width: 320px;
  border-right-width: 1px;
  border-right-style: solid;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.felMTq {
  padding: 18px;
  width: 100%;
  overflow-y: hidden;
}

.hJbBaq .Inner-eASQkV {
  height: calc(100vh - 62px);
}

.fNYben {
  padding: 0 !important;
}

.kWdRwd {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  position: relative;
}

.fZbXLc {
  width: 90%;
}

.fmnNSR {
  padding: 12px;
  border-bottom: 1px solid rgb(221, 225, 227);
  cursor: pointer;
}

.fmnNSR .H3-bOhkOq {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.lgizXY {
  margin: 0;
  color: rgb(24, 24, 24);
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.iwkHtv {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin: 0 6px;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  padding: 0;
}

.gAUqSD {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 6px;
  padding: 12px 6px;
  pointer-events: none;
}

.kQUSAR {
  flex: 1 1 0;
  padding-top: 6px;
  overflow-y: auto;
}

.hMXRVX {
  cursor: pointer;
}

.kwXbLo {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 6px;
  width: 100%;
  padding: 0 12px;
}

.kwXbLo:hover {
  background: rgb(226, 230, 235);
}

.kwXbLo .I-gmFDEA {
  color: rgb(33, 59, 71);
  visibility: hidden;
}

.iwkHtv .I-gmFDEA:not(:first-child) {
  color: rgb(33, 59, 71);
  visibility: hidden;
}

.kwXbLo:hover .I-gmFDEA {
  visibility: visible;
}

.bJkMPt .I-gmFDEA:not(:first-child) {
  color: rgb(33, 59, 71);
  visibility: hidden;
}

.kuxGbJ {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex: 1 1 0;
  padding: 12px 0;
}

.jaLOqC.jaLOqC.jaLOqC {
  display: inline-block;
  width: 0;
  margin-top: 0;
  margin-left: 6px;
}

.dfsrYj {
  margin: 0;
  color: rgb(118, 127, 130);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.fUNwul {
  display: block;
  color: rgb(24, 24, 24);
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  cursor: pointer;
  margin: 0 !important;
}

.cfZQHO {
  border-color: rgb(221, 225, 227);
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 18px !important;
  margin-left: 18px !important;
}

.fbTExq.fbTExq.fbTExq {
  display: inline-block;
  width: 0;
  margin-top: 0;
  margin-left: 12px;
}

.eNDwgr {
  display: inline-block;
  max-width: 130px;
  vertical-align: bottom;
  margin-left: 6px;
  color: rgb(118, 127, 130);
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cMyTnJ {
  display: block;
  color: rgb(24, 24, 24);
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  font-weight: 400 !important;
  margin: 0 !important;
}

.cWXaAG {
  cursor: pointer;
  align-self: center;
  font-size: 14px;
  color: rgb(43, 128, 255);
}

.bJkMPt {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin: 0 6px;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  opacity: 0.55;
  padding: 0;
}

.bJkMPt .I-gmFDEA:not(:first-child) {
  color: rgb(33, 59, 71);
  visibility: hidden;
}

.bJkMPt .fa-eye-slash {
  visibility: visible !important;
}

.bJkMPt .fa-eye-slash {
  visibility: visible !important;
}

.gAUqSD {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 6px;
  padding: 12px 6px;
  pointer-events: none;
}

.jVlDvc.jVlDvc.jVlDvc {
  width: 100%;
  margin-top: 6px;
  display: block !important;
}
</style>

<style>
.eyAPpw {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 6px;
}

.hTPolk {
  padding: 6px 18px;
}

.hDDKkN {
  display: block;
  width: 100%;
}
</style>
