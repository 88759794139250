<template>
  <div>
    Fleet Vehicle Features
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SettingsFleetFeatures',

  data() {
    return {
      settings: {
        turo: {},
        fw: {},
      },
    };
  },

  watch: {
    notificationSettings: {
      deep: true,
      handler(notification, notificationOld) {
        if(notificationOld.set) {
          this.updateNotificationSettings();
        }
      },
    },
  },

  computed: {
    ...mapGetters({
      loading: 'settings/loading',
      notificationSettings: 'settings/notifications',
    }),
  },

  methods: {
    ...mapActions({
      updateNotificationSettings: 'settings/updateNotificationSettings',
    }),
  },
};
</script>

