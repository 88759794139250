import axios from 'axios';
import { EventBus } from '@/plugins/eventBus';
import { get } from 'lodash';
import * as types from '../mutation-types';

export interface ThemeState {
    loading: Record<string, boolean>;
    storeThemes: any[];
    storeTheme: any;
}

const state: ThemeState = {
  loading: {
    notes: false,
    createNote: false,
    deleteNote: false,
  },
  storeThemes: [],
  storeTheme: null,
};

const getters = {
  storeThemes: state => state.storeThemes,
  storeTheme: state => state.storeTheme,
  loading: state => state.loading,
};

const mutations = {
  [types.SET_NOTES_LOADING](state, loading) {
    state.loading[loading.subject] = loading.loading;
  },
  [types.SET_STORE_THEMES](state, data) {
    state.storeThemes = data;
  },
  [types.SET_STORE_THEME_DATA](state, data) {
    state.storeTheme = data;
  },
};

const actions = {


  /**
   * Get all notes.
   *
   * @param commit
   * @param {Object} options
   */
  async getStoreThemes({ commit }) {
    commit('SET_NOTES_LOADING', { subject: 'notes', loading: true });

    const query = '';
    await axios.get(`/v1/store-assets?${query}`)
      .then((response) => {
        commit('SET_STORE_THEMES', get(response, 'data.data'));
      }).catch(() => {
    }).finally(() => {
      commit('SET_NOTES_LOADING', { subject: 'notes', loading: false });
    });
  },
  getStoreTheme({ commit }, uuid) {
    commit('SET_NOTES_LOADING', { subject: 'notes', loading: true });

    axios.get(`/v1/store-assets/${uuid}`)
      .then((response) => {
        commit('SET_STORE_THEME_DATA', get(response, 'data.data'));
      }).catch(() => {
    }).finally(() => {
      commit('SET_NOTES_LOADING', { subject: 'notes', loading: false });
    });
  },
  updateStoreTheme({ commit }, theme) {
    commit('SET_NOTES_LOADING', { subject: 'notes', loading: true });
    axios.put(`/v1/store-assets/${theme.uuid}`, theme)
      .then(() => {
        EventBus.$emit('update-theme-settings-success');
      }).catch(() => {
    }).finally(() => {
      commit('SET_NOTES_LOADING', { subject: 'notes', loading: false });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
