<template>
  <v-app class="d-block relative">
    <div class="flex flex-column h-screen">
      <transition name="fade-transform" mode="out-in">
        <keep-alive>
          <slot>
            <router-view />
          </slot>
        </keep-alive>
      </transition>
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheDocumentsDashboardV2',

  layout: 'dashboard',

  middleware: 'auth',

  data() {
    return {
      layout: null,
      currentRole: 'adminDashboard',
      admins: [
        ['Management', 'mdi-people-outline'],
        ['Settings', 'mdi-cog'],
      ],
      cruds: [
        ['Create', 'mdi-add'],
        ['Read', 'mdi-insert_drive_file'],
        ['Update', 'mdi-update'],
        ['Delete', 'mdi-delete'],
      ],
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        { text: 'Created', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  watch: {
    $route() {
      if (this.device === 'mobile' && this.secondaryNavViews.listingImportNav) {
        this.toggleSecondaryImportNav();
      }
    },
  },

  computed: {
    ...mapGetters({
      sidebar: 'app/sidebar',
      device: 'app/device',
      secondaryNavViews: 'app/secondaryNavViews',
    }),

    classObj() {
      return {
        'sidebar-closed': !this.sidebar.opened,
        'sidebar-opened': this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile',
        'show-import-secondary-menu': this.secondaryNavViews.listingImportNav,
      };
    },
  },
  methods: {
    ...mapActions({
      toggleSecondaryImportNav: 'app/toggleSecondaryImportNav',
    }),
  },

};
</script>

<style lang="scss">
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
  /* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
