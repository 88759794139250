import axios from 'axios';
import {getToken, removeAllUserCookies} from '@/utils/auth';
import {EventBus} from '@/plugins/eventBus';
import {store} from '@/store';
import router from '@/router';

axios.defaults.baseURL = '/api';

// Request interceptor
axios.interceptors.request.use((request) => {
    const token = getToken();

    if (token) {
        request.headers.Authorization = `bearer ${token}`;
    }

    request.headers.Accept = 'application/json';
    request.headers['Accept-Language'] = store.getters['lang/locale'];

    return request;
});

axios.interceptors.response.use((response) => response, (error) => {
    const status = error.response?.status;

    // eslint-disable-next-line
    if (status === 401) {
        // handleUnauthorizedError();
    } else if (status === 500) {
        EventBus.$emit('SystemMessage', {
            type: 'error',
            message: 'Server Error. Please try again in a few minutes.',
        });
    }

    return Promise.reject(error);
});

const handleUnauthorizedError = () => {
    const currentRouterName = router.currentRoute.value.name;
    const currentPath = window.location.pathname;
    const fullPath = window.location.href;

    console.dir('handleUnauthorizedError');
    console.dir(currentRouterName);
    console.dir(currentPath);
    console.dir(fullPath);


    const authPaths = ['/login', '/register', '/password/reset', '/password/verify', '/password/resend', '/company', '/rental', 'rental/reservations'];

    if (authPaths.some(path => currentPath.startsWith(path))) {
        console.log('Already on an auth-related page, no redirect needed.');
        return;
    }

    EventBus.$emit('SystemMessage', {
        type: 'error',
        message: 'Unauthorized',
    });

    removeAllUserCookies();
    window.location.href = `/login?redirect=${encodeURIComponent(currentPath)}`;
};