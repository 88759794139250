import {store} from '@/store';
import {DefaultMeta} from '@/types';

export default function getDefaultMeta(value: string): string | boolean | object | number | undefined {
    const meta: DefaultMeta = store.getters['company/defaultMeta'];

    return meta[value];
}


export function getMetaBooleanValue(value: string, trueIfMissing: boolean = false): boolean {
    const meta: DefaultMeta = store.getters['company/defaultMeta'];

    if (meta[value] === undefined || meta[value] === null) {
        return trueIfMissing;
    }
    
    return Boolean(meta[value]);
}