<template>
  <div>
    <v-card max-width="100%">
      <v-alert
        v-if="!includedInSubscription('coupons')"
        class="card-alert-full"
        density="compact"
        type="warning"
      >
        <div class="sm:flex block">
          <div class="flex-grow">Coupons are a standard & premium feature.</div>
          <v-btn :to="{name: 'settings.account'}" class="flex-shrink" size="x-small">Upgrade</v-btn>
        </div>
      </v-alert>

      <v-card-title style="margin-top: 5px">
        <FeatureTitle
          featurePremium
          featureStandard
          title="Coupons"
        />
      </v-card-title>

      <v-card-text>

        <div v-if="couponsExist">
          <v-text-field
            v-model="search"
            append-icon="mdi-search"
            class="mb-2"
            hide-details
            label="Search"
            variant="outlined"
          ></v-text-field>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th class="px-6 py-3" scope="col">Code</th>
                <th class="px-6 py-3" scope="col">Type</th>
                <th class="px-6 py-3" scope="col">Amount</th>
                <th class="px-6 py-3" scope="col">Active</th>
                <th class="px-6 py-3" scope="col">Listings</th>
                <th class="px-6 py-3" scope="col">Limit</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in couponsFiltered" :key="item.uuid">
                <th class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" scope="row">
                  <span style="cursor: pointer" @click="editCode(item)">{{ item.code }}</span>
                </th>
                <td class="px-6 py-4">
                  {{ item.type }}
                </td>
                <td class="px-6 py-4">
                 <span v-if="item.type === 'amount'">
                    {{ formatMoneyDisplayV2(item.amount) }}
                  </span>
                  <span v-else>{{ item.amount }}<small class="ml-1">%</small></span>
                </td>
                <td class="px-6 py-4">
                  <span v-if="item.active" class="text-green-500">Active</span>
                  <span v-else class="text-red-500">Inactive</span>
                </td>
                <td class="px-6 py-4">
                  {{
                  item.listings?.length === 0 || fleetResourceListings.length === item.listings?.length ? 'All' :
                  item.listings?.length
                  }}
                </td>
                <td class="px-6 py-4">
                <span v-if="item.limit < 0">{{ item.count || 0 }}/<v-icon
                  size="small">mdi-infinity</v-icon></span>
                  <span v-else>{{ item.count || 0 }}/{{ item.limit }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-else>No Coupons Yet</div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="!includedInSubscription('coupons')"
          :loading="couponLoading.company"
          class="ml-2"
          @click="importCoupons()"
        >
          Import
        </v-btn>
        <v-spacer/>
        <v-btn
          :disabled="!includedInSubscription('coupons')"
          :loading="couponLoading.company"
          class="mr-2"
          @click="addCoupon()"
        >
          Add Coupon
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="importCouponsDialog" :scrim="false" fullscreen transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar color="primary" theme="dark">
          <v-btn theme="dark" variant="text" @click="importCouponsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Import Coupons</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn theme="dark" variant="text" @click="importCouponsDialog = false">Okay</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list lines="three">
          <v-list-item>

            <v-list-item-title>Coming soon</v-list-item-title>
            <v-list-item-subtitle>Ability to import a large number of coupon codes.
            </v-list-item-subtitle>

          </v-list-item>
        </v-list>
        <v-divider/>
      </v-card>
    </v-dialog>

    <v-dialog v-model="couponDialog" :max-width="couponTab === 1 ? 600 : 850" :persistent="couponDialogMode === 'add'">
      <v-form v-model="couponFormValid" :disabled="couponForm.busy">
        <v-card :loading="couponForm.busy">
          <v-scroll-y-transition hide-on-leave>
            <v-card-title v-if="couponTab === 1">
              <div class="flex">
                <span class="text-h5">{{ couponDialogTitle }}</span>
                <v-spacer/>
                <div class="flex items-center">
                  <span class="text-muted mr-3">{{ couponClone.active ? 'Active' : 'Inactive' }}</span>
                  <v-switch v-model="couponClone.active" :inset="true" color="green" density="compact" hide-details></v-switch>
                </div>
              </div>
            </v-card-title>
          </v-scroll-y-transition>

          <v-window v-model="couponTab">
            <v-window-item :value="1">
              <v-card-text v-if="couponDialogMode === 'add'">
                <div class="coupon-form">
                  <v-text-field
                    v-model="couponClone.code"
                    :error="couponForm.errors.has('code')"
                    :rules="couponRules.code"
                    class="coupon-code mx-2"
                    label="Code"
                    name="code"
                    required
                    variant="outlined"
                    @keyup="store.commit('coupon/CLEAR_COUPON_FORM_ERRORS', 'code')"
                  ></v-text-field>

                  <v-btn-toggle v-model="couponClone.type" class="coupon-type-btn ml-2 h-100" mandatory>
                    <v-btn value="percent">
                      <span class="bold">%</span>
                    </v-btn>
                    <v-btn value="amount">
                      <span class="bold">{{ companyCurrencySymbol() }}</span>
                    </v-btn>
                  </v-btn-toggle>

                  <v-text-field
                    v-model="currentCouponAmount"
                    :prefix="couponClone.type === 'amount' ? companyMoneyTextInputSettings.prefix : ''"
                    :rules="couponRules.amount"
                    :suffix="couponClone.type === 'percent' ? '%' : companyMoneyTextInputSettings.suffix"
                    class="coupon-value"
                    variant="outlined"
                  />
                </div>
                <div class="limit-section mt-4">
                  <v-checkbox
                    v-model="couponLimit"
                    class="mb-2"
                    label="Limit the total number of times this coupon can be redeemed"
                  ></v-checkbox>
                  <v-text-field
                    v-show="couponLimit"
                    v-model="couponClone.limit"
                    class="mt-2"
                    hint="How many times can this coupon be used?"
                    label="Limit"
                    min="0"
                    persistent-hint
                    type="number"
                    variant="outlined"
                  ></v-text-field>
                </div>
                <span class="small italic">There will be more coupon settings once created.</span>

                <v-scroll-x-transition>
                  <v-alert v-if="couponForm.errors.any()" class="mt-3" density="compact" type="error" variant="outlined">
                    <div v-for="(error, key) in couponForm.errors.flatten()" :key="key">
                      {{ error }}
                    </div>
                  </v-alert>
                </v-scroll-x-transition>
              </v-card-text>
              <v-card-text v-else-if="couponClone">
                <h5 class="mb-2 text-3xl font-bold text-gray-900 dark:text-white">{{ couponClone.code }}</h5>
                <p>{{ couponClone.type === 'amount' ? 'Fixed amount discount' : 'Percentage discount' }}
                  ({{
                  couponClone.type === 'amount' ? formatMoneyDisplayV2(currentCouponAmount, false) :
                  `${currentCouponAmount}%`
                  }})
                </p>
                <p>{{ couponClone.limit >= 0 ? `Limited to ${couponClone.limit} uses` : 'Unlimited uses' }}</p>

                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-title>
                      <span v-if="currentCouponListings">All Listings</span>
                      <span v-else>Allow for {{ couponClone.listings.length }} Listings</span>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <div class="grid grid-cols-3 gap-2" style="min-height: 125px;">
                        <div class="">
                          <v-switch
                            v-model="currentCouponListings"
                            :inset="true"
                            density="compact"
                            hide-details
                            label="All listings"
                            @update:model-value="currentCouponListingsUpdate()"
                          />
                        </div>
                        <div class="col-span-2">
                          <v-autocomplete
                            v-show="!currentCouponListings"
                            v-model="couponClone.listings"
                            :items="fleetResourceListings"
                            chips
                            class="mx-2"
                            closable-chips
                            color="blue-grey-lighten-2"
                            hide-details
                            item-title="name"
                            item-value="l_id"
                            label="Listings"
                            multiple
                            variant="outlined"
                          >
                            <template v-slot:prepend-item>
                              <v-list-item title="Select All Listings" @click="toggle">
                                <template v-slot:prepend>
                                  <v-checkbox-btn
                                    :color="fleetResourceListings.length > 0 ? 'indigo-darken-4' : undefined"
                                    :indeterminate="wantsSomeListings && !wantsAllListings"
                                    :model-value="wantsAllListings"
                                  />
                                </template>
                              </v-list-item>
                              <v-divider class="mt-2" opacity="50"></v-divider>
                            </template>
                            <template v-slot:chip="data">
                              <v-chip
                                v-if="data.index === 0"
                                :closable="true"
                                :model-value="data.selected"
                                v-bind="data.props"
                                @click="data.select"
                                @click:close="removeListing(data.item.raw as ListingInterface)"
                              >
                                <v-avatar start>
                                  <v-img :src="listingVehicleImage(data.item.raw as ListingInterface)"></v-img>
                                </v-avatar>
                                {{ listingNameDisplay(data.item.raw as ListingInterface) }}
                              </v-chip>
                              <span
                                v-if="data.index === 1"
                                class="text-grey text-caption"
                              >
                              (+{{ couponClone.listings.length - 1 }} others)
                            </span>
                            </template>
                            <template v-slot:item="{ props, item }">
                              <v-list-item
                                :prepend-avatar="listingVehicleImage(item.raw as ListingInterface)"
                                :title="listingNameDisplay(item.raw as ListingInterface)"
                                v-bind="props"
                              />
                            </template>
                          </v-autocomplete>
                        </div>
                      </div>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-title>
                      <span v-if="currentCouponUsersOn">Limit Renter uses to {{ currentCouponUsers }}</span>
                      <span v-else>Renters can use unlimited</span>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <div class="grid grid-cols-1 gap-2">
                        <div>
                          <v-switch
                            v-model="currentCouponUsersOn"
                            :inset="true"
                            density="compact"
                            hide-details
                            label="Limit number of times this coupon can be used per customer"
                            @update:model-value="currentCouponUserUpdate()"
                          />
                        </div>
                        <div>
                          <v-text-field
                            v-show="currentCouponUsersOn"
                            v-model="currentCouponUsers"
                            class="coupon-code mx-2"
                            hide-details
                            label="Limit"
                            min="1"
                            persistent-hint
                            type="number"
                            variant="outlined"
                          ></v-text-field>
                        </div>
                      </div>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-title>
                      Reason
                      <span v-if="couponClone.reason">: {{ currentCouponReasonEllipsis }}</span>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <v-text-field
                        v-model="couponClone.reason"
                        :counter="40"
                        :error="couponForm.errors.has('reason')"
                        :persistent-hint="true"
                        :rules="couponRules.reason"
                        class="coupon-code mx-2"
                        hint="This will appear on customers' receipts and invoices."
                        label="Reason"
                        required
                        variant="outlined"
                        @keyup="couponForm.errors.clear('reason')"
                      ></v-text-field>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-alert v-if="couponForm.errors.any()" class="mt-3" density="compact" type="warning">
                  {{ couponForm.errors.flatten()[0] }}
                </v-alert>

                <v-btn class="mt-5" size="small" variant="text" @click="couponTab = 2">View Redemptions</v-btn>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="2">
              <v-card-title>Redemptions</v-card-title>
              <v-card-text>
                <div>
                  <div v-if="couponRedemptions.length === 0 && couponLoading.redemptions">Loading...</div>

                  <v-data-table
                    v-else-if="couponRedemptions.length > 0"
                    :headers="orderHeaders"
                    :items="couponRedemptions"
                    :loading="couponLoading.redemptions"
                    :mobile-breakpoint="0"
                    class="elevation-0"
                    item-key="b_id"
                  >
                    <template v-slot:body="{ items }">
                      <tbody class="overflow-hidden overflow-x-auto">
                      <tr v-for="(item, index) in items" :key="(item as VDataTableItem).name">
                        <td class="w-48">
                          <div class="d-flex align-items-start">
                            <div class="mr-2">
                              <div class="image-container image-responsive rounded" style="width: 40px">
                                <div class="image-resizer" style="padding-bottom: 100%;"></div>
                                <div :style="`background-image: url(${listingVehicleImage((item as VDataTableItem).listing)});`" class="fw-image" style="background-position: center center;"></div>
                                <div class="fw-responsive__content"></div>
                              </div>
                            </div>
                            <router-link v-if="(item as VDataTableItem).listing?.l_id" :to="{ name: 'listing.single', params: {l_id: (item as VDataTableItem).listing?.l_id } }" class="small">
                              {{ listingNameDisplay((item as VDataTableItem).listing) }}
                            </router-link>
                          </div>
                        </td>
                        <td style="min-width: 190px">
                          <CustomerPopoverMenu :customer="(item as VDataTableItem).renter" :item-index="index">
                            <template v-slot:customerName>
                              <span>{{ (item as VDataTableItem).renter?.full_name }}</span>
                            </template>
                          </CustomerPopoverMenu>
                        </td>
                        <td class="w-48">
                          <span v-if="checkPermission(['orders_financials'])">
                            {{ formatMoneyDisplayV2((item as VDataTableItem).total) }}
                          </span>
                          <span v-else v-tippy content="You need permission to view financial data"><v-icon>mdi-eye-off-outline</v-icon></span>
                        </td>
                        <td class="w-48">
                          <span v-if="checkPermission(['orders_financials'])">
                            {{ formatMoneyDisplayV2((item as VDataTableItem).discount) }}
                          </span>
                          <span v-else v-tippy content="You need permission to view financial data"><v-icon>mdi-eye-off-outline</v-icon></span>
                        </td>
                        <td style="min-width: 185px">
                          <router-link :to="{ name: 'orders.single', params: {b_id: (item as VDataTableItem).b_id} }">
                            <span v-html="(item as VDataTableItem).b_id"></span>
                          </router-link>
                        </td>
                        <td style="min-width: 190px">
                          {{ formatBookingDate((item as VDataTableItem).trip_start) }}
                        </td>
                        <td style="min-width: 190px">
                          {{ formatBookingDate((item as VDataTableItem).trip_end) }}
                        </td>
                        <td class="w-52">
                          <span :class="classObject(item as VDataTableItem)" class="badge">{{
                              (item as VDataTableItem).status
                            }}</span>
                        </td>
                        <td class="w-56">
                          {{ (item as VDataTableItem).source }}
                        </td>
                        <td style="min-width: 185px">
                          {{ formatBookingDate((item as VDataTableItem).created_at) }}
                        </td>
                      </tr>
                      </tbody>
                    </template>
                    <template v-slot:no-data>No Redemptions</template>
                  </v-data-table>
                  <div v-else>No Redemptions</div>
                </div>
                <v-btn class="mt-5" size="small" variant="text" @click="couponTab = 1">Back</v-btn>
              </v-card-text>
            </v-window-item>
          </v-window>

          <v-scroll-y-reverse-transition leave-absolute>
            <v-card-actions v-if="couponTab === 1">
              <DeleteButton
                v-if="couponDialogMode === 'edit' && !confirmCouponDelete"
                :loading="couponLoading.delete_coupon"
                @delete-confirmed="deleteCouponCommit(couponClone)"
              />
              <v-spacer/>
              <v-btn :disabled="couponForm.busy" color="grey" size="small"
                     variant="text"
                     @click="couponDialog = false; confirmCouponDelete = false" v-text="$t('close')"></v-btn>
              <v-btn v-if="couponDialogMode === 'edit'" :disabled="!couponFormValid"
                     :loading="couponLoading.update_coupon" color="primary"
                     @click="updateCouponCommit(couponClone); confirmCouponDelete = false" v-text="$t('update')"></v-btn>
              <v-btn
                v-if="couponDialogMode === 'add'"
                :disabled="!couponFormValid"
                :loading="couponLoading.save_coupon"
                color="primary"
                @click="createCouponCommit(couponClone)"
                v-text="$t('create')"
              ></v-btn>
            </v-card-actions>
          </v-scroll-y-reverse-transition>
        </v-card>
      </v-form>
    </v-dialog>

    <UpgradePlanFeatureDialog2
      :bullets="[{text: 'Offer coupons for vehicle rentals, providing renters with exclusive discounts and promotions.'},
         { text: 'Foster loyalty and referrals by rewarding renters with coupons, cultivating lasting relationships within your rental platform.',},
         {text: 'Drive loyalty and recommendations with coupon incentives, nurturing lasting connections within your rental network.',},
      ]"
      :tiers="['standard', 'premium']"
      description="Unlock extra value for your vehicle rental experience – Upgrade to Standard or Premium plans to access exclusive coupons and enhance your rentals with special discounts."
      feature="Coupons"
      right-side-message="Create coupons for your most loyal renters and reward them with special discounts."
    >
      <template v-slot:rightImage>
        <v-img alt="" src="/images/upgrade_coupons.png"/>
      </template>
    </UpgradePlanFeatureDialog2>
  </div>
</template>

<script lang="ts" setup>
import {computed, defineAsyncComponent, onBeforeMount, onBeforeUnmount, ref, watch} from 'vue';
import dayjs from 'dayjs';
import {useStore} from 'vuex';

import {useMoneyFormatter} from '@/composables/useMoneyFormatter';
import {Coupon, ListingInterface} from '@/types';
import {useSubscription} from '@/composables/useSubscription';
import checkPermission from '@/utils/permission';
import {useListing} from '@/composables/useListing';
import {useCoupons} from '@/composables/useCoupons';
import {useCouponForm} from '@/composables/useCouponForm';

interface VDataTableItem {
  name: string;
  listing: ListingInterface;
  renter: any;
  total: number;
  discount: number;
  b_id: string;
  trip_start: string;
  trip_end: string;
  status: string;
  source: string;
  created_at: string;
}

const UpgradePlanFeatureDialog2 = defineAsyncComponent(() => import('@/components/dashboard/dialogs/UpgradePlanFeatureDialog2.vue'));
const CustomerPopoverMenu = defineAsyncComponent(() => import('@/components/dashboard/customers/CustomerPopoverMenu.vue'));
const FeatureTitle = defineAsyncComponent(() => import('@/components/dashboard/FeatureTitle.vue'));
const DeleteButton = defineAsyncComponent(() => import('@/components/buttons/DeleteButton.vue'));

const store = useStore();
const {includedInSubscription} = useSubscription();
const {formatMoneyDisplayV2, companyMoneyTextInputSettings, companyCurrencySymbol} = useMoneyFormatter();
const {
  fleetResourceListings,
  listingVehicleImage,
  listingNameDisplay,
  fetchListings,
  fetchFleetResourcesListings
} = useListing();
const {
  coupons,
  couponLoading,
  couponForm,
  couponRedemptions,
  fetchCoupons,
  createCoupon,
  updateCoupon,
  deleteCoupon,
  getCouponRedemptions,
  clearCoupons,
} = useCoupons();

const {
  couponClone,
  currentCouponListings,
  currentCouponUsersOn,
  currentCouponUsers,
  couponLimit,
  couponFormValid,
  currentCouponAmount,
  couponRules,
  resetForm,
  updateListings,
  updateUserLimit
} = useCouponForm();

const couponDialog = ref(false);
const search = ref('');
const couponTab = ref(1);
const couponDialogMode = ref('add');
const couponDialogTitle = ref('Add Coupon');
const confirmCouponDelete = ref(false);
const selectedCoupons = ref([]);
const importCouponsDialog = ref(false);
const listingsClone = ref([]);
const confirmMultiCouponToggle = ref(false);

const orderHeaders = [
  {text: 'Listing', value: 'listing', width: 200},
  {text: 'Renter', value: 'renter', width: 200},
  {text: 'Total', value: 'total', width: 200},
  {text: 'Discount', value: 'discount', width: 200},
  {text: 'Order ID', value: 'b_id', width: 200},
  {text: 'Start Date', value: 'trip_start', width: 200},
  {text: 'End Date', value: 'trip_end', width: 200},
  {text: 'Status', value: 'status', width: 200},
  {text: 'Source', value: 'source', width: 200},
  {text: 'Created At', value: 'created_at', width: 200},
];

// Computed properties
const couponsFiltered = computed(() => {
  const searchTerm = search.value.toLowerCase();
  if (!coupons.value) return coupons.value;
  return coupons.value.filter(coupon =>
    coupon.code.toLowerCase().includes(searchTerm)
  );
});


const currentCouponReasonEllipsis = computed(() => {
  return (couponClone.value?.reason ?? '').length > 40
    ? `${couponClone.value.reason.substring(0, 40)}...`
    : couponClone.value.reason;
});

const couponsExist = computed(() => {
  if (coupons.value === undefined) return false;
  return coupons.value.length;
});

const wantsAllListings = computed(() =>
  (couponClone.value?.listings ?? []).length === fleetResourceListings.value.length
);

const wantsSomeListings = computed(() =>
  (couponClone.value?.listings ?? []).length > 0 && !wantsAllListings.value
);

const icon = computed(() => {
  if (wantsAllListings.value) return 'mdi-close-box';
  if (wantsSomeListings.value) return 'mdi-minus-box';
  return 'mdi-checkbox-blank-outline';
});

// Methods
const classObject = (item: VDataTableItem) => ({
  'badge--success': item.status === 'completed',
  'badge--warning': item.status === 'pending',
  'badge--error': item.status === 'cancelled',
});

const deleteCouponCommit = async (coupon: Coupon) => {
  await deleteCoupon(coupon.uuid);
  couponDialog.value = false;
};

const createCouponCommit = async (coupon: Coupon) => {
  await createCoupon(coupon);
  couponDialog.value = false;
  setTimeout(() => {
    resetForm();
  }, 300);
};

const updateCouponCommit = async (coupon: Coupon) => {
  await updateCoupon(coupon);
  couponDialog.value = false;
  setTimeout(() => {
    resetForm();
  }, 300);
};

const addCoupon = () => {
  couponDialogMode.value = 'add';
  couponDialogTitle.value = 'Add Coupon';
  couponDialog.value = true;
};

const editCode = async (code: Coupon) => {
  try {
    await fetchFleetResourcesListings();
    listingsClone.value = JSON.parse(JSON.stringify(fleetResourceListings.value));
    couponClone.value = JSON.parse(JSON.stringify(code));
    couponDialog.value = true;
    couponDialogTitle.value = 'Edit Coupon';
    couponDialogMode.value = 'edit';

    const hasNoListings = code.listings.length === 0;
    const hasAllListings = code.listings.length === fleetResourceListings.value.length;
    currentCouponListings.value = hasNoListings || hasAllListings;

    currentCouponUsersOn.value = code.user_limit > 0;
    currentCouponUsers.value = Number(code.user_limit);
  } catch (error) {
    console.error('Error while editing coupon:', error);
  }
};

const importCoupons = () => {
  importCouponsDialog.value = true;
};

const currentCouponListingsUpdate = () => {
  updateListings(fleetResourceListings.value);
};

const currentCouponUserUpdate = () => {
  updateUserLimit();
};

const toggle = () => {
  if (wantsAllListings.value) {
    couponClone.value.listings = [];
  } else {
    couponClone.value.listings = fleetResourceListings.value.slice();
  }
};

const removeListing = (item: ListingInterface) => {
  const index = couponClone.value.listings.findIndex((l: ListingInterface) => l.l_id === item.l_id);
  if (index >= 0) couponClone.value.listings.splice(index, 1);
};

const formatBookingDate = (bookingDate: string) => {
  return dayjs(bookingDate).format('ddd, MMM D @ h:mma');
};

// Watchers
watch(couponDialog, (dialog) => {
  store.commit('coupon/SET_COUPON_REDEMPTIONS', []);
  couponTab.value = 1;
  if (dialog && fleetResourceListings.value.length === 0) {
    fetchListings();
  }
  if (!dialog) {
    setTimeout(() => {
      resetForm();
    }, 300);
  }
});

watch(couponTab, (tab) => {
  if (tab === 2 && couponClone.value) {
    getCouponRedemptions(couponClone.value.uuid);
  }
});

watch(couponLimit, (limit) => {
  couponClone.value.limit = limit ? 1 : -1;
});

watch(selectedCoupons, () => {
  confirmMultiCouponToggle.value = false;
});

watch(currentCouponUsers, (users) => {
  couponClone.value.user_limit = +users;
});

// Lifecycle hooks
onBeforeMount(() => {
  fetchCoupons();
});

onBeforeUnmount(() => {
  clearCoupons();
});
</script>

<style>
.coupon-type-btn button {
  height: 56px !important;
}

.coupon-form {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: flex-start;
}

.coupon-form .coupon-code {
  width: 175px;
  flex: 1;
  min-width: 175px;
}

.coupon-form .coupon-value {
  width: 100px;
  flex: 0 0 100px;
}

.limit-section {
  width: 100%;
  max-width: 100%;
}

@media (max-width: 600px) {
  .coupon-form {
    flex-direction: column;
    width: 100%;
  }

  .coupon-form .coupon-code,
  .coupon-form .coupon-value {
    width: 100%;
    min-width: 100%;
  }

  .coupon-type-btn {
    width: 100%;
    margin: 8px 0;
  }

  .coupon-type-btn button {
    flex: 1;
  }
}

.select-all-item {
  min-height: 48px;
  padding: 0 16px;
  white-space: nowrap;
}

.select-all-item .v-list-item-action {
  margin-right: 16px;
}

.select-all-item .v-list-item-title {
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
