import axios from 'axios';
import {EventBus} from '@/plugins/eventBus';
import * as types from '../mutation-types';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {ESignerFieldData, ESignTool} from '@/types';
import {DocumentType, TemplateData} from '@/store/modules/document';


export interface TemplateInterface {
    data: null;
    documents: DocumentType;
    file_id: null;
    name: null;
    pages: number;
    version: 'v1' | 'v2';
    uuid?: string;
    updated_at?: string;
    created_at?: string;
}

export interface TemplateState {
    init: boolean;
    loading: Record<string, boolean>,
    canvasActive: boolean;
    toolsActive: boolean;
    documents: DocumentType[];
    document: DocumentType;
    templates: TemplateData[];

    template?: TemplateInterface;

    rightSidebar: {
        open: boolean;
        mode: string;
        title: string;
    };
    currentWorkingTool: ESignTool;
    selectedField: any;
    selectedFieldV2: ESignerFieldData | null;
    selectedFieldIndex: number;

    existingTemplateData: any[];
    newTemplateData: any[];

    documentFieldData: any[];

    fieldProperties: {
        fontSize: number;
        fontColor: string;
    };
    allTools: ESignTool[];
    currentSigner: string;
    signerItems: any[];

    documentImages: any[];
}


const state: TemplateState = {
    init: false,
    loading: {
        template: false,
        saving: false,
    },
    canvasActive: false,
    toolsActive: false,
    documents: [],
    document: null,
    templates: [],
    template: null,

    rightSidebar: {
        open: false,
        mode: 'tools',
        title: '',
    },
    currentWorkingTool: null,
    selectedField: {
        attributes: {
            bold: false,
            italic: false,
            required_field: false,
            strikethrough: false,
            underline: false,
            required: true,
        },
    },
    selectedFieldV2: null,
    selectedFieldIndex: null,

    existingTemplateData: [],
    newTemplateData: [],

    documentFieldData: [],

    fieldProperties: {
        fontSize: 12,
        fontColor: '#4169e1',
    },
    allTools: [
        {
            id: 'field-primary-free-text',
            name: 'Text',
            value: 'text',
            type: 'text',
            group: 'field',
            icon: 'mdi-format-text',
            category: 'Misc',
            primary: true,
        },
        {
            id: 'field-secondary-free-text',
            name: 'Text',
            value: 'text',
            type: 'text',
            group: 'field',
            icon: 'mdi-format-text',
            category: 'Misc',
            secondary: true,
        },
        {
            id: 'field-primary-checkbox',
            name: 'Checkbox',
            value: false,
            type: 'checkbox',
            group: 'field',
            icon: 'mdi-checkbox-marked-outline',
            description: 'Checkbox field for a signer to make a choice',
            category: 'Misc',
            w: 20,
            h: 20,
            resizable: false,
            fieldName: '',
            primary: true,
        },
        {
            id: 'field-secondary-checkbox',
            name: 'Checkbox',
            value: false,
            type: 'checkbox',
            group: 'field',
            icon: 'mdi-checkbox-marked-outline',
            description: 'Checkbox field for a signer to make a choice',
            category: 'Misc',
            w: 20,
            h: 20,
            resizable: false,
            fieldName: '',
            secondary: true,
        },
        {
            id: 'field-primary-full-name',
            name: 'Full Name',
            title: 'Name',
            value: 'full_name',
            type: 'field',
            resizable: true,
            icon: 'mdi-account-box-outline',
            category: 'Primary Customer',
            w: 100,
            h: 20,
            options: ['field-primary-full-name', 'field-primary-first-name', 'field-primary-last-name'],
            description: 'The full name of the primary driver',
            primary: true,
        },
        {
            id: 'field-primary-first-name',
            name: 'First Name',
            title: 'Name',
            value: 'full_name',
            type: 'field',
            resizable: true,
            icon: 'mdi-account-box',
            category: 'Primary Customer',
            w: 100,
            h: 20,
            description: 'The first name of the primary driver',
            options: ['field-primary-full-name', 'field-primary-first-name', 'field-primary-last-name'],
            primary: true,
        },
        {
            id: 'field-primary-last-name',
            name: 'Last Name',
            title: 'Name',
            value: 'full_name',
            type: 'field',
            resizable: true,
            icon: 'mdi-account-box',
            category: 'Primary Customer',
            w: 100,
            h: 20,
            description: 'The last name of the primary driver',
            options: ['field-primary-full-name', 'field-primary-first-name', 'field-primary-last-name'],
            primary: true,
        },
        {
            id: 'field-primary-email',
            name: 'Email',
            value: 'email',
            type: 'field',
            resizable: true,
            icon: 'mdi-email-outline',
            description: 'The email name of the primary driver',
            category: 'Primary Customer',
            primary: true,
        },
        {
            id: 'field-primary-cell',
            name: 'Cell Phone',
            value: 'cell',
            type: 'field',
            resizable: true,
            w: 110,
            icon: 'mdi-cellphone',
            description: 'The cell phone of the primary driver',
            category: 'Primary Customer',
            primary: true,
        },
        {
            id: 'field-primary-drivers-license',
            name: 'Drivers License #',
            value: 'dl_number',
            type: 'field',
            w: 160,
            h: 20,
            description: 'The drivers license number of the primary driver',
            category: 'Primary Customer',
            icon: 'mdi-smart-card-outline',
            primary: true,
        },
        {
            id: 'field-primary-drivers-license-state',
            name: 'Drivers License St',
            value: 'dl_state',
            type: 'field',
            w: 160,
            h: 20,
            description: 'The drivers license state of the primary driver',
            category: 'Primary Customer',
            icon: 'mdi-smart-card-outline',
            primary: true,
        },
        {
            id: 'field-primary-drivers-license-exp',
            name: 'Drivers License Exp',
            value: 'dl_exp',
            type: 'field',
            w: 160,
            h: 20,
            description: 'The drivers license expiration date of the primary driver',
            category: 'Primary Customer',
            icon: 'mdi-smart-card-outline',
            primary: true,
        },
        {
            id: 'field-primary-dob',
            name: 'DOB',
            value: 'dob',
            type: 'field',
            w: 160,
            h: 20,
            description: 'The date of birth of the primary driver',
            category: 'Primary Customer',
            icon: 'mdi-calendar-outline',
            primary: true,
        },
        {
            id: 'field-primary-signature',
            name: 'Sign',
            value: 'signature',
            type: 'signature',
            group: 'signature',
            resizable: false,
            icon: 'mdi-signature',
            category: 'Primary Customer',
            w: 50,
            h: 50,
            description: 'The signature of the primary driver',
            primary: true,
        },
        {
            id: 'field-date-signed',
            name: 'Date Signed',
            value: 'date_signed',
            type: 'field',
            resizable: true,
            icon: 'mdi-calendar-range',
            category: 'Primary Customer',
            readOnly: true,
            w: 110,
            h: 23,
            primary: true,
        },
        {
            id: 'field-attachment',
            name: 'Attach',
            value: 'attachment',
            type: 'attachment',
            disabled: false,
            resizable: false,
            icon: 'mdi-paperclip',
            category: 'Primary Customer',
            fontSize: 7,
            w: 50,
            h: 50,
            description: 'A attachment for the primary driver',
            primary: true,
        },


        {
            id: 'field-secondary-full-name',
            name: 'Full Name',
            title: 'Name',
            value: 'full_name',
            type: 'field',
            resizable: true,
            icon: 'mdi-account-box-outline',
            category: 'Secondary Customer',
            w: 100,
            h: 20,
            description: 'The full name of the secondary driver',
            options: ['field-secondary-full-name', 'field-secondary-first-name', 'field-secondary-last-name'],
            secondary: true,
        },

        {
            id: 'field-secondary-first-name',
            name: 'First Name',
            title: 'Name',
            value: 'full_name',
            type: 'field',
            resizable: true,
            icon: 'mdi-account-box',
            category: 'Secondary Customer',
            w: 100,
            h: 20,
            description: 'The first name of the secondary driver',
            options: ['field-secondary-full-name', 'field-secondary-first-name', 'field-secondary-last-name'],
            secondary: true,
        },
        {
            id: 'field-secondary-last-name',
            name: 'Last Name',
            title: 'Name',
            value: 'full_name',
            type: 'field',
            resizable: true,
            icon: 'mdi-account-box',
            category: 'Secondary Customer',
            w: 100,
            h: 20,
            description: 'The last name of the secondary driver',
            options: ['field-secondary-full-name', 'field-secondary-first-name', 'field-secondary-last-name'],
            secondary: true,
        },
        {
            id: 'field-secondary-email',
            name: 'Email',
            value: 'email',
            type: 'field',
            resizable: true,
            icon: 'mdi-email-outline',
            description: 'The email name of the secondary driver',
            category: 'Secondary Customer',
            secondary: true,
        },
        {
            id: 'field-secondary-cell',
            name: 'Cell Phone',
            value: 'cell',
            type: 'field',
            resizable: true,
            w: 110,
            icon: 'mdi-cellphone',
            description: 'The cell phone of the secondary driver',
            category: 'Secondary Customer',
            secondary: true,
        },
        {
            id: 'field-secondary-drivers-license',
            name: 'Drivers License #',
            value: 'dl_number',
            type: 'field',
            w: 160,
            h: 20,
            description: 'The drivers license number of the secondary driver',
            category: 'Secondary Customer',
            icon: 'mdi-smart-card-outline',
            secondary: true,
        },
        {
            id: 'field-secondary-drivers-license-state',
            name: 'Drivers License St',
            value: 'dl_state',
            type: 'field',
            w: 160,
            h: 20,
            description: 'The drivers license state of the secondary driver',
            category: 'Secondary Customer',
            icon: 'mdi-smart-card-outline',
            secondary: true,
        },
        {
            id: 'field-secondary-drivers-license-exp',
            name: 'Drivers License Exp',
            value: 'dl_exp',
            type: 'field',
            w: 160,
            h: 20,
            description: 'The drivers license expiration date of the secondary driver',
            category: 'Secondary Customer',
            icon: 'mdi-smart-card-outline',
            secondary: true,
        },
        {
            id: 'field-secondary-dob',
            name: 'DOB',
            value: 'dob',
            type: 'field',
            w: 50,
            h: 20,
            description: 'The date of birth of the secondary driver',
            category: 'Secondary Customer',
            bgColor: 'purple',
            icon: 'mdi-calendar-outline',
            secondary: true,
        },
        {
            id: 'field-secondary-signature',
            name: 'Sign',
            value: 'signature',
            type: 'signature',
            group: 'signature',
            resizable: false,
            icon: 'mdi-signature',
            category: 'Secondary Customer',
            w: 50,
            h: 50,
            description: 'The signature of the secondary driver',
            secondary: true,
        },
        {
            id: 'field-secondary-date-signed',
            name: 'Date Signed',
            value: 'date_signed',
            type: 'field',
            resizable: true,
            icon: 'mdi-calendar-range',
            category: 'Secondary Customer',
            readOnly: true,
            w: 110,
            h: 23,
            secondary: true,
        },
        {
            id: 'field-attachment',
            name: 'Attach',
            value: 'attachment',
            type: 'attachment',
            disabled: false,
            resizable: false,
            icon: 'mdi-paperclip',
            category: 'Secondary Customer',
            fontSize: 7,
            w: 50,
            h: 50,
            description: 'A attachment for the primary driver',
            secondary: true,
        },

        {
            id: 'field-vehicle-year-make-model',
            name: 'Year Make Model',
            value: 'vehicle_year_make_model',
            type: 'field',
            category: 'Vehicle',
            icon: 'mdi-car',
            readOnly: true,
            w: 160,
            h: 20,
        },
        {
            id: 'field-vehicle-year',
            name: 'Year',
            value: 'vehicle_year',
            type: 'field',
            category: 'Vehicle',
            readOnly: true,
            resizable: true,
            icon: 'mdi-car',
        },
        {
            id: 'field-vehicle-make',
            name: 'Make',
            value: 'vehicle_make',
            type: 'field',
            category: 'Vehicle',
            readOnly: true,
            resizable: true,
            icon: 'mdi-car',
        },
        {
            id: 'field-vehicle-model',
            name: 'Model',
            value: 'vehicle_model',
            type: 'field',
            category: 'Vehicle',
            readOnly: true,
            resizable: true,
            icon: 'mdi-car',
        },
        {
            id: 'field-vehicle-color',
            name: 'Color',
            value: 'vehicle_color',
            type: 'field',
            category: 'Vehicle',
            readOnly: true,
            resizable: true,
            icon: 'mdi-palette',
        },
        {
            id: 'field-vehicle-plate',
            name: 'Plate #',
            value: 'vehicle_plate',
            type: 'field',
            category: 'Vehicle',
            readOnly: true,
            resizable: true,
            icon: 'mdi-card-text-outline',
        },
        {
            id: 'field-vehicle-vin',
            name: 'Vin',
            value: 'vehicle_vin',
            type: 'field',
            category: 'Vehicle',
            readOnly: true,
            icon: 'mdi-card-text-outline',
        },
        {
            id: 'field-vehicle-current-odometer',
            name: 'Odometer',
            value: 'vehicle_odometer',
            type: 'field',
            category: 'Vehicle',
            readOnly: true,
            icon: 'mdi-card-text-outline',
            w: 90,
            h: 20,
            description: 'The current odometer reading of the vehicle at the time of signing. Note: This value will not update if the odometer is different at the time of pickup. Should probably only be used for agreements signed at the time of pickup.',
        },
        {
            id: 'field-vehicle-title',
            name: 'Listing title',
            value: 'vehicle_title',
            type: 'field',
            category: 'Vehicle',
            resizable: true,
            readOnly: true,
            icon: 'mdi-format-title',
            w: 160,
            h: 20,
        },
        {
            id: 'field-vehicle-unit-id',
            name: 'Unit Id',
            value: 'vehicle_unit_id',
            type: 'field',
            category: 'Vehicle',
            resizable: true,
            readOnly: true,
            icon: 'mdi-barcode',
            w: 160,
            h: 20,
        },
        {
            id: 'field-listing-mile-allowance-per-day',
            name: 'Mile Allowance',
            value: 'listing_mile_allowance',
            type: 'field',
            category: 'Listing',
            readOnly: true,
            icon: 'mdi-counter',
            w: 160,
            h: 20,
        },
        {
            id: 'field-listing-mile-overage',
            name: 'Mile Overage',
            value: 'listing_mile_overage',
            type: 'field',
            category: 'Listing',
            icon: 'mdi-cash',
            readOnly: true,
            w: 160,
            h: 20,
        },
        {
            id: 'field-listing-price',
            name: 'Listing Price',
            value: 'listing_price',
            type: 'field',
            category: 'Listing',
            readOnly: true,
            icon: 'mdi-cash',
            w: 160,
            h: 20,
        },
        {
            id: 'field-listing-id',
            name: 'Listing ID',
            value: 'listing_id',
            type: 'field',
            category: 'Listing',
            readOnly: true,
            icon: 'mdi-barcode',
            w: 160,
            h: 20,
        },

        {
            id: 'field-booking-id',
            name: 'Booking ID',
            value: 'booking_id',
            type: 'field',
            category: 'Booking',
            icon: 'mdi-numeric',
            readOnly: true,
            w: 115,
            h: 20,
        },
        {
            id: 'field-booking-end-datetime',
            name: 'End date/time',
            value: 'booking_end',
            type: 'field',
            category: 'Booking',
            icon: 'mdi-calendar-range',
            readOnly: true,
            w: 160,
            h: 20,
        },
        {
            id: 'field-booking-start-datetime',
            name: 'Start date/time',
            value: 'booking_start',
            type: 'field',
            category: 'Booking',
            readOnly: true,
            icon: 'mdi-calendar-range',
            w: 160,
            h: 20,
        },
        {
            id: 'field-booking-start-date',
            name: 'Start Date',
            value: 'booking_start_date',
            type: 'field',
            category: 'Booking',
            readOnly: true,
            icon: 'mdi-calendar-range',
            w: 160,
            h: 20,
        },
        {
            id: 'field-booking-end-date',
            name: 'End Date',
            value: 'booking_end_date',
            type: 'field',
            category: 'Booking',
            readOnly: true,
            icon: 'mdi-calendar-range',
            w: 160,
            h: 20,
        },
        {
            id: 'field-booking-start-time',
            name: 'Start Time',
            value: 'booking_start_time',
            type: 'field',
            category: 'Booking',
            readOnly: true,
            icon: 'mdi-calendar-clock',
            w: 160,
            h: 20,
        },
        {
            id: 'field-booking-end-time',
            name: 'End Time',
            value: 'booking_end_time',
            type: 'field',
            category: 'Booking',
            readOnly: true,
            icon: 'mdi-calendar-clock',
            w: 160,
            h: 20,
        },
        {
            id: 'field-booking-trip-total',
            name: 'Trip Price',
            value: 'booking_trip_total',
            type: 'field',
            category: 'Booking',
            readOnly: true,
            icon: 'mdi-cash',
            w: 160,
            h: 20,
        },
        {
            id: 'field-booking-total',
            name: 'Total Price',
            value: 'booking_total',
            type: 'field',
            category: 'Booking',
            readOnly: true,
            icon: 'mdi-cash',
            w: 160,
            h: 20,
        },

        {
            id: 'field-booking-taxes',
            name: 'Total Taxes',
            value: 'booking_taxes',
            type: 'field',
            category: 'Booking',
            readOnly: true,
            icon: 'mdi-cash',
            w: 160,
            h: 20,
        },
        {
            id: 'field-booking-discount',
            name: 'Total Discount',
            value: 'booking_discount',
            type: 'field',
            category: 'Booking',
            readOnly: true,
            icon: 'mdi-cash',
            w: 160,
            h: 20,
        },
        {
            id: 'field-booking-delivery',
            name: 'Total Delivery',
            value: 'booking_delivery_total',
            type: 'field',
            category: 'Booking',
            readOnly: true,
            icon: 'mdi-cash',
            w: 160,
            h: 20,
        },
        {
            id: 'field-booking-extras',
            name: 'Extras Total',
            value: 'booking_extras_total',
            type: 'field',
            category: 'Booking',
            readOnly: true,
            icon: 'mdi-cash',
            w: 160,
            h: 20,
        },
        {
            id: 'field-booking-deposit',
            name: 'Total Deposit',
            value: 'booking_deposit',
            type: 'field',
            category: 'Booking',
            readOnly: true,
            icon: 'mdi-cash-plus',
            w: 160,
            h: 20,
        },
        {
            id: 'field-booking-days',
            name: 'Total Days',
            value: 'booking_days',
            type: 'field',
            category: 'Booking',
            readOnly: true,
            icon: 'mdi-calendar-range',
            w: 105,
            h: 20,
        },
        {
            id: 'field-booking-mile-allowance',
            name: 'Trip Mile Allowance',
            value: 'booking_days',
            type: 'field',
            category: 'Booking',
            readOnly: true,
            icon: 'mdi-calendar-range',
            w: 105,
            h: 20,
        },
        {
            id: 'field-booking-mile-extra',
            name: 'Trip Distance Overage',
            description: 'Trip distance overage is a field for storing the cost for exceeding the distance allowance. The default is the listings distance allowance setting but can be overridden on a reservation level.',
            value: 'booking_days',
            type: 'field',
            category: 'Booking',
            readOnly: true,
            icon: 'mdi-calendar-range',
            w: 220,
            h: 20,
        },
    ],
    currentSigner: 'primary',
    signerItems: [
        {
            title: 'Primary',
            value: 'primary',
            color: '#5be8ff',
        },
        {
            title: 'Secondary',
            value: 'secondary',
            color: 'purple',
        },
    ],

    documentImages: [],
};

const getters = {
    init: (state: TemplateState) => state.init,
    rightSidebar: (state: TemplateState) => state.rightSidebar,
    toolsActive: (state: TemplateState) => state.toolsActive,
    loading: (state: TemplateState) => state.loading,
    document: (state: TemplateState) => state.document,
    documents: (state: TemplateState) => state.documents,
    templates: (state: TemplateState) => state.templates,
    template: (state: TemplateState) => state.template,
    currentWorkingTool: (state: TemplateState) => state.currentWorkingTool,
    selectedField: (state: TemplateState) => state.selectedField,
    selectedFieldV2: (state: TemplateState) => state.selectedFieldV2,
    selectedFieldIndex: (state: TemplateState) => state.selectedFieldIndex,
    documentFieldData: (state: TemplateState) => state.documentFieldData,
    existingTemplateData: (state: TemplateState) => state.existingTemplateData,
    newTemplateData: (state: TemplateState) => state.newTemplateData,
    fieldProperties: (state: TemplateState) => state.fieldProperties,
    allTools: (state: TemplateState) => state.allTools,
    currentSigner: (state: TemplateState) => state.currentSigner,
    signerItems: (state: TemplateState) => state.signerItems,
    documentImages: (state: TemplateState) => state.documentImages,
};

const mutations = {
    [types.SET_TEMPLATES_LOADING](state: TemplateState, loading: { subject: string; loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_FIELD_CURRENT_SIGNER](state: TemplateState, signer: string) {
        state.currentSigner = signer;
    },
    [types.SET_TOOLS_ACTIVE](state: TemplateState, active: boolean) {
        state.toolsActive = active;
    },
    [types.SET_CANVAS_ACTIVE](state: TemplateState, active: boolean) {
        state.canvasActive = active;
    },
    [types.SET_RIGHT_SIDEBAR_OPEN](state: TemplateState, open: boolean) {
        state.rightSidebar.open = open;
    },
    [types.SET_RIGHT_SIDEBAR_MODE](state: TemplateState, mode: string) {
        state.rightSidebar.mode = mode;
    },
    [types.SET_RIGHT_SIDEBAR_TITLE](state: TemplateState, title: string) {
        state.rightSidebar.title = title;
    },
    [types.SET_CURRENT_WORKING_TOOL](state: TemplateState, tool: ESignTool) {
        state.currentWorkingTool = tool;
    },
    [types.SET_SELECTED_FIELD](state: TemplateState, field) {
        state.selectedField = field;
    },
    [types.SET_SELECTED_FIELD_V2](state: TemplateState, field: ESignerFieldData) {
        console.dir('SET_SELECTED_FIELD_V2');
        console.dir(field);
        state.selectedFieldV2 = field;
    },
    [types.REMOVE_FIELD](state: TemplateState, field: ESignerFieldData) {
        const documents = state.template?.documents;
        if (documents && documents.data) {
            documents.data = documents.data.filter((f: ESignerFieldData) => f.id !== field.id);
        }
    },
    [types.REMOVE_ALL_FIELDS](state: TemplateState) {
        const documents = state.template?.documents;
        if (documents && documents.data) {
            documents.data = [];
        }
    },
    [types.SET_SELECTED_FIELD_INDEX_V2](state: TemplateState, index: number) {
        state.selectedFieldIndex = index;
    },

    [types.UPDATE_DOCUMENT_TEMPLATE_DATA](state: TemplateState, payload: { data: ESignerFieldData, index: number }) {
        if (payload.data === null) {
            state.template.documents.data.splice(payload.index, 1); // Remove element safely
        } else {
            state.template.documents.data[payload.index] = payload.data;
        }
    },

    [types.ADD_TO_DOCUMENT_TEMPLATE_DATA](state: TemplateState, payload: ESignerFieldData) {
        state.template.documents.data.push(payload);
    },
    [types.SET_ONE_TEMPLATE](state: TemplateState, template: TemplateInterface) {
        if (template?.documents?.data === null) {
            template.documents.data = [];
        }

        state.template = template;

    },
    [types.SET_EXISTING_DATA](state: TemplateState, data) {
        state.existingTemplateData = data || [];
    },
    [types.SET_NEW_DATA](state: TemplateState, data) {
        state.newTemplateData = data;
    },
    [types.RESET_TEMPLATE_DATA](state: TemplateState) {
        state.newTemplateData = [];
        state.existingTemplateData = [];
        state.template = null;
    },
    [types.SET_DOCUMENT_IMAGES](state: TemplateState, data) {
        state.documentImages = data;
    },
};

const actions = {
    async getTemplate({commit}, uuid: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_TEMPLATES_LOADING', 'template', () => axios.get(`/v1/templates/${uuid}`));
            EventBus.$emit('get-template-success', responseData.template);
            commit(types.SET_ONE_TEMPLATE, responseData.template);
        } catch (error) {
        }
    },

    deleteSelectedElements({state, commit}) {
        ['newTemplateData', 'existingTemplateData'].forEach(dataKey => {
            const index = state[dataKey].findIndex(d => d.fieldid === state.selectedField?.fieldid);
            if (index >= 0) {
                state[dataKey][index].styleParent = 'display:none;';
                state[dataKey].splice(index, 1);
            }
        });
        commit(types.SET_SELECTED_FIELD, {});
        commit(types.SET_RIGHT_SIDEBAR_OPEN, false);
    },

    deleteSelectedElementsV2({state, commit}) {
        console.dir('deleteSelectedElementsV2');
        console.dir(state.selectedFieldIndex);
        if (state.selectedFieldIndex >= 0) {
            state.template?.documents?.data.splice(state.selectedFieldIndex, 1);
            commit(types.SET_SELECTED_FIELD_V2, null);
            commit(types.SET_SELECTED_FIELD_INDEX_V2, null);
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
