import type {RouteRecordRaw} from 'vue-router';
import {CommonRouteMeta} from "@/types";

const BaseDashboard = () => import('@/views/dashboard/BaseDashboard.vue');

const notifications: RouteRecordRaw[] = [
    {
        path: '/notifications',
        component: BaseDashboard,
        meta: {
            title: 'notifications',
            icon: 'mdi-newspaper-variant-multiple-outline',
            noCache: false,
            middleware: ['auth'],
            layout: 'Dashboard',
        } as CommonRouteMeta,
        children: [
            {
                path: '',
                components: {
                    default: () => import('@/views/dashboard/notifications/BaseNotifications.vue'),
                },
                name: 'notifications.index',
                meta: {title: 'Notifications', icon: 'mdi-account-outline', noCache: false},
            },
        ],
    }
];

export default notifications;
