<template>
  <transition mode="out-in" name="fade-transform">
    <keep-alive :include="cachedViews">
      <router-view :key="key"/>
    </keep-alive>
  </transition>
</template>

<script lang="ts">

export default {
  name: 'LayoutCompanyForCheckoutIFrame',

  middleware: ['company'],

  layout: 'companyIframe',

  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.fullPath;
    },
  },
};
</script>

<style>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.options-subtitle {
  line-height: 1.2;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  margin-top: 5px;
}
</style>
