<template>
  <div>
    <v-card :disabled="true" class="mt-5">
      <div class="my-3">
        <v-img alt="Braintree Payment Provider Logo" max-height="25" src="https://s3-us-west-1.amazonaws.com/bt-partner-assets/paypal-braintree.png"/>
      </div>
      <v-card-text>
        <div v-if="!hasBraintree">
          <img alt="Connect with Braintree" class="cursor-pointer" height="44" src="https://s3-us-west-1.amazonaws.com/bt-partner-assets/connect-braintree.png" width="328" @click="braintreeOauthInit">
        </div>
        <div v-else class="media align-items-center border rounded p-3">
          <img :src="`https://ui-avatars.com/api/?name=PP`" alt="Image Description" class="h-10 w-10 rounded-full object-cover mx-1 mr-3">
          <div class="media-body">
            <h6 class="text-hover-primary mb-0">{{ braintreeEmail }}</h6>
            <small class="d-block">
              <strong>Connected: </strong>{{ formatRentalDate(braintreeCreatedAt) }}</small>
          </div>

          <v-menu class="flex-row" offset="left">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" variant="text">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="$router.push({ name: 'settings.payment-provider.single', params: { uuid: braintreeUuid }}).catch(() => {})">
                <v-list-item-title>
                  View
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="removeBraintreeConfirmDialog = true">
                <v-list-item-title>
                  Disconnect
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="small text-gray-600">
          Braintree provider is currently on closed beta. Contact us if you are interested.
        </div>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="removeBraintreeConfirmDialog" max-width="350">
      <v-card>
        <v-card-title class="text-h5">Delete PayPal Connection</v-card-title>
        <v-card-text>
          Are you sure you want to delete your PayPal/Braintree connection?
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="grey" size="small" variant="text" @click="removeBraintreeConfirmDialog = false">
            Nevermind
          </v-btn>
          <v-btn color="primary" @click="deletePaymentProfile(braintreeProvider.id)">
            I'm Sure
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import {mapActions, mapGetters} from 'vuex';
import {get} from 'lodash';
import {EventBus} from '@/plugins/eventBus';
import {useDateFormatter} from "@/composables/useDate";


export default {
  name: 'BraintreeProviderCard',

  setup() {
    const {formatRentalDate} = useDateFormatter();
    return {
      formatRentalDate,
    };
  },

  data() {
    return {
      braintreeOauthWindow: null,
      braintreeCheckInterval: null,
      removeBraintreeConfirmDialog: false,
    };
  },

  created() {
    EventBus.$on('braintree-oauth-url-create-success', (data) => {
      this.openOauthUrl(data.url);
    });
    EventBus.$on('delete-payment-profile-success', () => {
      this.removeBraintreeConfirmDialog = false;
      this.getPaymentProfiles();
    });
  },

  mounted() {
    EventBus.$on('braintree-oauth-success', (data) => {
      if (get(data, 'braintree')) {
        this.braintreeOauthWindow.close();
        clearInterval(this.braintreeCheckInterval);
      }
    });
  },

  computed: {
    ...mapGetters({
      paymentProviders: 'providers/paymentProviders',
    }),
    braintreeProvider() {
      return this.paymentProviders.find(provider => provider.provider === 'braintree');
    },
    hasBraintree() {
      return this.braintreeProvider !== undefined;
    },
    braintreeEmail() {
      return get(this.braintreeProvider, 'email');
    },
    braintreeUuid() {
      return get(this.braintreeProvider, 'uuid');
    },
    braintreeCreatedAt() {
      return get(this.braintreeProvider, 'created_at');
    },
  },

  methods: {
    ...mapActions({
      getBraintreeOauthUrl: 'braintree/getBraintreeOauthUrl',
      getBraintreeProvider: 'braintree/getBraintreeProvider',
      deletePaymentProfile: 'providers/deletePaymentProfile',
      getPaymentProfiles: 'providers/getPaymentProfiles',
    }),
    braintreeOauthInit() {
      this.getBraintreeOauthUrl();
    },
    openOauthUrl(url) {
      const vm = this;

      this.braintreeOauthWindow = window.open(
        url,
        'Fleetwire.io Braintree Oauth',
        'scrollbars,status,width=750,height=700',
      );

      this.braintreeCheckInterval = setInterval(() => {
        if (this.braintreeOauthWindow.closed) {
          clearInterval(vm.braintreeCheckInterval);
          this.authLoading.stripe_oauth = false;
        }
        vm.getBraintreeProvider();
      }, 1000);
    },
  },

  beforeUnmount() {
    EventBus.$off('braintree-oauth-url-create-success');
    EventBus.$off('braintree-oauth-success');
    EventBus.$off('delete-payment-profile-success');
  },
};
</script>
