import {EventBus} from '@/plugins/eventBus';
import {get} from 'lodash';
import {Form} from '@/plugins/FWForms/index';
import axios from 'axios';
import * as types from '@/store/mutation-types';
import {push} from 'notivue'

export interface CustomFieldsState {
    loading: Record<string, boolean>;
    customFields: any[];
    form: Form<object>;
}

// state
const state: CustomFieldsState = {
    loading: {
        customFields: false,
        updateCustomFields: false,
    },
    customFields: [],
    form: new Form(),
};

const getters = {
    loading: (state: CustomFieldsState) => state.loading,
    form: (state: CustomFieldsState) => state.form,
    customFields: (state: CustomFieldsState) => state.customFields,
};

const mutations = {
    [types.SET_CUSTOM_FIELDS_LOADING](state: CustomFieldsState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_CUSTOM_FIELDS](state: CustomFieldsState, fields) {
        state.customFields = fields;
    },
    [types.SET_CUSTOM_FIELDS_FORM_BUSY](state: CustomFieldsState, busy) {
        state.form.busy = busy;
    },
};

const actions = {

    fetchCustomFields({commit}) {
        commit('SET_CUSTOM_FIELDS_LOADING', {subject: 'customFields', loading: true});
        commit('SET_CUSTOM_FIELDS_FORM_BUSY', true);

        axios.get('/v1/custom-fields')
            .then((response) => {
                commit('SET_CUSTOM_FIELDS', get(response, 'data.customFields'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_CUSTOM_FIELDS_FORM_BUSY', false);

            commit('SET_CUSTOM_FIELDS_LOADING', {subject: 'customFields', loading: false});
        });
    },

    createCustomFields({commit, state}, field) {
        commit('SET_CUSTOM_FIELDS_LOADING', {subject: 'updateCustomFields', loading: true});
        commit('SET_CUSTOM_FIELDS_FORM_BUSY', true);

        axios.post('/v1/custom-fields', field)
            .then((response) => {
                EventBus.$emit('create-custom-fields-success', get(response, 'data.customField', {}));
                push.success({
                    message: 'Custom Field created successfully!',
                });
            }).catch((e) => {
            state.form.errors.set(get(e, 'response.data.errors'));
        }).finally(() => {
            commit('SET_CUSTOM_FIELDS_FORM_BUSY', false);
            commit('SET_CUSTOM_FIELDS_LOADING', {subject: 'updateCustomFields', loading: false});
        });
    },

    updateCustomFields({commit}, field) {
        commit('SET_CUSTOM_FIELDS_LOADING', {subject: 'updateCustomFields', loading: true});
        commit('SET_CUSTOM_FIELDS_FORM_BUSY', true);

        axios.put(`/v1/custom-fields/${field.uuid}`, field)
            .then((response) => {
                EventBus.$emit('update-custom-fields-success', get(response, 'data.customField', {}));
                push.success({
                    message: 'Custom Field updated successfully!',
                });
            }).catch((e) => {
            EventBus.$emit('update-custom-fields-error', {field, error: get(e, 'response.data.errors', {})});
        }).finally(() => {
            commit('SET_CUSTOM_FIELDS_FORM_BUSY', false);
            commit('SET_CUSTOM_FIELDS_LOADING', {subject: 'updateCustomFields', loading: false});
        });
    },

    updateCustomFieldsPositions({commit}, fields) {
        commit('SET_CUSTOM_FIELDS_LOADING', {subject: 'updateCustomFields', loading: true});
        commit('SET_CUSTOM_FIELDS_FORM_BUSY', true);

        axios.put('/v1/custom-fields/positions', {fields})
            .then((response) => {
                EventBus.$emit('update-custom-fields-positions-success', get(response, 'data.customFields', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_CUSTOM_FIELDS_FORM_BUSY', false);
            commit('SET_CUSTOM_FIELDS_LOADING', {subject: 'updateCustomFields', loading: false});
        });
    },

    deleteCustomField({commit}, field) {
        commit('SET_CUSTOM_FIELDS_LOADING', {subject: 'updateCustomFields', loading: true});
        commit('SET_CUSTOM_FIELDS_FORM_BUSY', true);

        axios.delete(`/v1/custom-fields/${field.uuid}`)
            .then((response) => {
                commit('SET_CUSTOM_FIELDS', get(response, 'data.customFields'));
                push.success({
                    message: 'Custom Field deleted successfully!',
                });
            }).catch((e) => {
            EventBus.$emit('delete-custom-fields-failed', get(e, 'response.data.message', {}));
        }).finally(() => {
            commit('SET_CUSTOM_FIELDS_FORM_BUSY', false);
            commit('SET_CUSTOM_FIELDS_LOADING', {subject: 'updateCustomFields', loading: false});
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
