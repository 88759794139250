import axios from 'axios';
import {EventBus} from '@/plugins/eventBus';
import router from '@/router';
import * as types from '../mutation-types';
import {createUrlQueryString} from '@/utils/urls';
import {get} from 'lodash';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {CompanyInterface, PermissionsArray} from '@/types';

export interface SuperUserState {
    loading: Record<string, boolean>,
    users: Array<any>,
    companies: CompanyInterface[],
    companiesMeta: {
        current_page: number,
        from: number,
        last_page: number,
        per_page: number,
        to: number,
        total: number,
    },
    currentWorkingCompany: any,
    currentWorkingUser: any,
    roles: Array<any>,
    permissions: PermissionsArray,
    media: Array<any>,
    mediaDirectories: Array<any>,
    mediaPath: string,
    allListingImages: Array<any>,
    serverDetails: any,
    basicStats: {
        active_users: Array<any>,
        subscribers: any,
        users: number | null,
        trials: number | null,
        listings: number | null,
        automations: number | null,
        bookings: number | null,
        connections: {
            tesla_accounts: number | null,
            turo_accounts: number | null,
            getaround_accounts: number | null,
        },
    },
    featureWaitlist: Array<any>,
    featuresStatus: Array<any>,
    cache: Array<any>,
}

const state: SuperUserState = {
    loading: {
        users: null,
        roles: null,
        permissions: null,
        automations: null,
        bookings: null,
        connections: null,
        listings: null,
        subscribers: null,
        activeUsers: null,
        turoTrip: null,
    },
    users: [],
    companies: [],
    companiesMeta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 1,
        to: 1,
        total: 1,
    },
    currentWorkingCompany: {},
    currentWorkingUser: {},
    roles: [],
    permissions: [],
    media: [],
    mediaDirectories: [],
    mediaPath: '',
    allListingImages: [],
    serverDetails: {},
    basicStats: {
        active_users: [],
        subscribers: {},
        users: null,
        trials: null,
        listings: null,
        automations: null,
        bookings: null,
        connections: {
            tesla_accounts: null,
            turo_accounts: null,
            getaround_accounts: null,
        },
    },

    featureWaitlist: [],
    featuresStatus: [],
    cache: [],
};

const getters = {
    loading: (state: SuperUserState) => state.loading,
    allUsers: (state: SuperUserState) => state.users,
    allCompanies: (state: SuperUserState) => state.companies,
    companiesMeta: (state: SuperUserState) => state.companiesMeta,
    currentWorkingUser: (state: SuperUserState) => state.currentWorkingUser,
    currentWorkingCompany: (state: SuperUserState) => state.currentWorkingCompany,
    roles: (state: SuperUserState) => state.roles,
    permissions: (state: SuperUserState) => state.permissions,
    mediaDirectories: (state: SuperUserState) => state.mediaDirectories,
    media: (state: SuperUserState) => state.media,
    mediaPath: (state: SuperUserState) => state.mediaPath,
    allListingImages: (state: SuperUserState) => state.allListingImages,
    basicStats: (state: SuperUserState) => state.basicStats,
    serverDetails: (state: SuperUserState) => state.serverDetails,
    featureWaitlist: (state: SuperUserState) => state.featureWaitlist,
    featuresStatus: (state: SuperUserState) => state.featuresStatus,
    cache: (state: SuperUserState) => state.cache,
};

const mutations = {
    [types.SET_SU_LOADING](state: SuperUserState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_ALL_USERS](state: SuperUserState, users) {
        state.users = users;
    },
    [types.SET_ALL_COMPANIES](state: SuperUserState, companies) {
        if (companies.current_page === 1) {
            state.companies = companies.data;
        } else {
            // state.companies = state.companies.concat(companies.data);
        }

        state.companiesMeta = {
            current_page: companies.current_page,
            from: companies.from,
            last_page: companies.last_page,
            per_page: companies.per_page,
            to: companies.to,
            total: companies.total,
        };
    },
    [types.SET_CURRENT_WORKING_COMPANY](state: SuperUserState, company) {
        state.currentWorkingCompany = company;
    },
    [types.SET_CURRENT_WORKING_USER](state: SuperUserState, user) {
        state.currentWorkingUser = user;
    },
    [types.SET_ALL_ROLES](state: SuperUserState, roles) {
        state.roles = roles;
    },
    [types.SET_ALL_PERMISSIONS](state: SuperUserState, permissions) {
        state.permissions = permissions;
    },
    [types.SET_ALL_MEDIA_DIRECTORIES](state: SuperUserState, mediaDirectories) {
        state.mediaDirectories = mediaDirectories;
    },
    [types.SET_ALL_MEDIA_IN_DIRECTORY](state: SuperUserState, media) {
        state.media = media;
    },
    [types.SET_MEDIA_BASE_PATH](state: SuperUserState, path) {
        state.mediaPath = path;
    },
    [types.SET_ALL_LISTING_IMAGES](state: SuperUserState, images) {
        state.allListingImages = images;
    },
    [types.SET_ALL_SERVER_DETAILS](state: SuperUserState, details) {
        state.serverDetails = details;
    },
    [types.SET_SA_FEATURE_WAITLIST](state: SuperUserState, waitlist) {
        state.featureWaitlist = waitlist;
    },
    [types.SET_SA_FEATURES_STATUS](state: SuperUserState, status) {
        state.featuresStatus = status;
    },
    [types.SET_SA_CACHE](state: SuperUserState, cache) {
        state.cache = cache;
    },
    [types.SET_CURRENT_WORKING_SU_BASIC_STATS](state: SuperUserState, stats) {
        state.basicStats[Object.keys(stats)[0]] = stats[Object.keys(stats)[0]];
    },
};

const actions = {
    async fetchAllUsers({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_SU_LOADING', 'users', () => axios.get('/v1/su/users'));
            commit('SET_ALL_USERS', responseData.users);
        } catch (error) {
            console.error(error)
        }
    },

    async fetchOneUsers({commit}, uuid) {
        try {
            const responseData = await performApiRequest(commit, 'SET_SU_LOADING', 'users', () => axios.get(`/v1/su/users/${uuid}`));
            commit('SET_CURRENT_WORKING_USER', responseData.user);
        } catch (error) {
            console.error(error)
        }
    },
    async purgeFeatures({commit}) {
        try {
            await performApiRequest(commit, 'SET_SU_LOADING', 'users', () => axios.post('/v1/su/features/purge'));
        } catch (error) {
            console.error(error)
        }
    },
    async getFeaturesWaitlist({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_SU_LOADING', 'users', () => axios.get('/v1/su/features-waitlist'));
            commit('SET_SA_FEATURE_WAITLIST', responseData.waitlist);
        } catch (error) {
            console.error(error)
        }
    },
    setAllowFeature({commit}, feature) {
        axios.post('/v1/su/features/allow', feature)
            .then(() => {
                EventBus.$emit('sa-set-allow-feature-success');
            }).catch(() => {
        }).finally(() => {
        });
    },
    setRemoveFeature({commit}, feature) {
        axios.post('/v1/su/features/remove', feature)
            .then(() => {
                EventBus.$emit('sa-set-allow-feature-success');
            }).catch(() => {
        }).finally(() => {
        });
    },
    setAllowFeatureFromWishlist({commit}, feature) {
        axios.post(`/v1/su/features-waitlist/allow/${feature}`)
            .then(() => {
                // commit('SET_SA_FEATURE_WAITLIST', response.data.waitlist);
            }).catch(() => {
        }).finally(() => {
        });
    },

    getFeatures({commit}) {
        axios.get('/v1/su/features')
            .then((response) => {
                commit('SET_SA_FEATURES_STATUS', response.data.featuresStatus);
            }).catch(() => {
        }).finally(() => {
        });
    },

    getCache({commit}) {
        axios.get('/v1/su/cache')
            .then((response) => {
                commit('SET_SA_CACHE', response.data.cache);
            }).catch(() => {
        }).finally(() => {
        });
    },

    clearCache(data = null) {
        axios.delete('/v1/su/cache', {data})
            .catch(() => {
            }).finally(() => {
        });
    },

    async getFleetwirePartners({commit}) {
        return await performApiRequest(commit, 'SET_SU_LOADING', 'getFleetwirePartners', () => axios.get('/v1/su/partners'));
    },

    getFleetwirePartner({commit}, getFleetwirePartner) {
        axios.get(`/v1/su/partners/${getFleetwirePartner}`)
            .then((response) => {
                EventBus.$emit('get-fleetwire-partner-success', response.data);
            }).catch(() => {
        }).finally(() => {
        });
    },

    async updateUser({commit}, user) {
        try {
            const responseData = await performApiRequest(commit, 'SET_SU_LOADING', 'users', () => axios.put(`/v1/su/users/${user.uuid}`, user));
            commit('SET_CURRENT_WORKING_USER', responseData.user);
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'User Saved',
            });
        } catch (error) {
            console.error(error)
        }
    },

    fetchAllCompanies({commit}, data) {
        const page = get(data, 'page', 1) || 1;

        axios.get(`/v1/su/company?page=${page}`)
            .then((response) => {
                commit('SET_ALL_COMPANIES', response.data);
            }).catch(() => {
        }).finally(() => {
        });
    },

    async fetchAllMedia({commit}) {
        const responseData = await performApiRequest(commit, 'SET_SU_LOADING', 'users', () => axios.get('/v1/su/media'));
        commit(types.SET_ALL_MEDIA_DIRECTORIES, responseData.directories);
        return responseData;
    },
    async fetchDirectories({commit}, payload: { path: string }) {
        console.dir('fetchDirectories');
        console.dir(payload);
        const urlParams = new URLSearchParams();
        urlParams.append('path', payload.path);
        const query = urlParams.toString();
        return await performApiRequest(commit, 'SET_SU_LOADING', 'directory', () => axios.get(`/v1/su/directory?${query}`));
    },

    fetchServerDetails({commit}) {
        axios.get('/v1/su/server-details')
            .then((response) => {
                commit('SET_ALL_SERVER_DETAILS', response.data.details);
            }).catch(() => {
        }).finally(() => {
        });
    },

    async fetchAllMediaInDirectory({commit}, directory) {
        const responseData = await performApiRequest(commit, 'SET_SU_LOADING', 'users', () => axios.get(`/v1/su/media/directory?directory=${directory}`));
        commit(types.SET_ALL_MEDIA_IN_DIRECTORY, responseData.media);
        commit(types.SET_MEDIA_BASE_PATH, responseData.path);
        return responseData;
    },

    fetchAllListingImages({commit}) {
        axios.get('/v1/su/listing-images')
            .then((response) => {
                commit('SET_ALL_LISTING_IMAGES', response.data.images);
            }).catch(() => {
        }).finally(() => {
        });
    },

    resizeImage({commit}, path) {
        axios.put('/v1/su/resize-image', {path})
            .then(() => {
            }).catch(() => {
        }).finally(() => {
        });
    },

    fetchOneCompany({commit}, uuid) {
        axios.get(`/v1/su/company/${uuid}`)
            .then((response) => {
                commit('SET_CURRENT_WORKING_COMPANY', response.data.company);
            }).catch(() => {
        }).finally(() => {
        });
    },

    updateCompany({commit, state}) {
        axios.put(`/v1/su/company/${state.currentWorkingCompany.uuid}`, state.currentWorkingCompany)
            .then((response) => {
                commit('SET_CURRENT_WORKING_COMPANY', response.data.company);
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Company Updated',
                });
            }).catch(() => {
        }).finally(() => {
        });
    },

    deleteCompany({commit, state}) {
        axios.delete(`/v1/su/company/${state.currentWorkingCompany.uuid}`)
            .then((response) => {
                commit('SET_CURRENT_WORKING_USER', response.data.user);
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'User Saved',
                });
            }).catch(() => {
        }).finally(() => {
        });
    },

    getBasicStats({commit}) {
        axios.get('/v1/su/stats')
            .then((response) => {
                commit('SET_CURRENT_WORKING_SU_BASIC_STATS', response.data.stats);
            }).catch(() => {
        }).finally(() => {
        });
    },

    getActiveUsersStats({commit}) {
        commit('SET_SU_LOADING', {subject: 'activeUsers', loading: true});

        axios.get('/v1/su/stats/users')
            .then((response) => {
                commit('SET_CURRENT_WORKING_SU_BASIC_STATS', {active_users: response.data.stats});
            }).catch(() => {
        }).finally(() => {
            commit('SET_SU_LOADING', {subject: 'activeUsers', loading: false});
        });
    },

    testUserAPN({commit}) {
        commit('SET_SU_LOADING', {subject: 'activeUsers', loading: true});

        axios.get('/v1/su/pusher-test-user-notification')
            .then(() => {
            }).catch(() => {
        }).finally(() => {
            commit('SET_SU_LOADING', {subject: 'activeUsers', loading: false});
        });
    },

    getSubscribersStats({commit}) {
        commit('SET_SU_LOADING', {subject: 'subscribers', loading: true});

        axios.get('/v1/su/stats/subscribers')
            .then((response) => {
                commit('SET_CURRENT_WORKING_SU_BASIC_STATS', {subscribers: response.data.stats});
            }).catch(() => {
        }).finally(() => {
            commit('SET_SU_LOADING', {subject: 'subscribers', loading: false});
        });
    },

    getListingStats({commit}) {
        commit('SET_SU_LOADING', {subject: 'listings', loading: true});

        axios.get('/v1/su/stats/listingStats')
            .then((response) => {
                commit('SET_CURRENT_WORKING_SU_BASIC_STATS', {listings: response.data.stats});
            }).catch(() => {
        }).finally(() => {
            commit('SET_SU_LOADING', {subject: 'listings', loading: false});
        });
    },

    getConnectionsStats({commit}) {
        commit('SET_SU_LOADING', {subject: 'connections', loading: true});

        axios.get('/v1/su/stats/connectionsStats')
            .then((response) => {
                commit('SET_CURRENT_WORKING_SU_BASIC_STATS', {connections: response.data.stats});
            }).catch(() => {
        }).finally(() => {
            commit('SET_SU_LOADING', {subject: 'connections', loading: false});
        });
    },

    getAutomations({commit}, data) {
        commit('SET_SU_LOADING', {subject: 'automations', loading: true});

        const query = createUrlQueryString(data);

        axios.get(`/v1/su/stats/automations${query}`)
            .then((response) => {
                EventBus.$emit('get-automations-success', response.data);
            }).catch(() => {
        }).finally(() => {
            commit('SET_SU_LOADING', {subject: 'automations', loading: false});
        });
    },
    getAutomationsStats({commit}) {
        commit('SET_SU_LOADING', {subject: 'automations', loading: true});

        axios.get('/v1/su/stats/automationsStats')
            .then((response) => {
                commit('SET_CURRENT_WORKING_SU_BASIC_STATS', {automations: response.data.stats});
            }).catch(() => {
        }).finally(() => {
            commit('SET_SU_LOADING', {subject: 'automations', loading: false});
        });
    },
    getAutomationsStatsData({commit}, data) {
        commit('SET_SU_LOADING', {subject: 'automations', loading: true});

        const query = createUrlQueryString(data);

        axios.get(`/v1/su/stats/automationsStatsData${query}`)
            .then((response) => {
                EventBus.$emit('get-automation-stats-data-success', response.data);
            }).catch(() => {
        }).finally(() => {
            commit('SET_SU_LOADING', {subject: 'automations', loading: false});
        });
    },
    getTuroTrip({commit}, data) {
        commit('SET_SU_LOADING', {subject: 'turoTrip', loading: true});

        const query = createUrlQueryString(data);

        axios.get(`/v1/su/stats/getTuroTrip${query}`)
            .then((response) => {
                EventBus.$emit('get-turo-trip-success', response.data);
            }).catch(() => {
        }).finally(() => {
            commit('SET_SU_LOADING', {subject: 'turoTrip', loading: false});
        });
    },
    getBookingsStats({commit}) {
        commit('SET_SU_LOADING', {subject: 'bookings', loading: true});

        axios.get('/v1/su/stats/bookingStats')
            .then((response) => {
                commit('SET_CURRENT_WORKING_SU_BASIC_STATS', {bookings: response.data.stats});
            }).catch(() => {
        }).finally(() => {
            commit('SET_SU_LOADING', {subject: 'bookings', loading: false});
        });
    },

    async fetchAllRoles({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_SU_LOADING', 'roles', () => axios.get('/v1/roles'));
            commit('SET_ALL_ROLES', responseData.roles);
        } catch (error) {
            console.error(error)
        }
    },

    async fetchAllPermissions({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_SU_LOADING', 'permissions', () => axios.get('/v1/permissions'));
            commit('SET_ALL_PERMISSIONS', responseData.permissions);
        } catch (error) {
            console.error(error)
        }
    },

    createPermission({commit}, data) {
        axios.post('/v1/permissions', data)
            .then((response) => {
                commit('SET_ALL_PERMISSIONS', response.data.permissions);
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Permission Created',
                });
                router.push({name: 'super.admin.permissions'});
            }).catch(() => {
        }).finally(() => {
        });
    },

    updatePermission({commit}, data) {
        axios.put(`/v1/permissions/${data.id}`, data)
            .then((response) => {
                commit('SET_ALL_PERMISSIONS', response.data.permissions);
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Permission Updated',
                });
            }).catch(() => {
        }).finally(() => {
        });
    },

    deletePermission({commit}, data) {
        axios.delete(`/v1/permissions/${data.id}`)
            .then((response) => {
                commit('SET_ALL_PERMISSIONS', response.data.permissions);
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Permission Deleted',
                });
                router.push({name: 'super.admin.permissions'});
            }).catch(() => {
        }).finally(() => {
        });
    },

    masqueradeInit({commit, rootGetters}, user) {
        axios.get(`/v1/users/${user.uuid}/masquerade`)
            .then((response) => {
                commit('app/SET_MASQUERADE', {
                    user: response.data.user,
                    token: response.data.token,
                    admin: {
                        email: rootGetters['auth/user'].email,
                        uuid: rootGetters['auth/user'].uuid,
                    },
                }, {root: true});
            }).catch(() => {
        }).finally(() => {
        });
    },

    encryptText({commit}, string) {
        axios.post('/v1/su/encrypt-text', {text: string})
            .then((response) => {
                EventBus.$emit('su-encrypt-text-success', response.data.result);
            }).catch((e) => {
            EventBus.$emit('su-encrypt-text-failed', e.response.data.message);
        }).finally(() => {
        });
    },

    decryptText({commit}, string) {
        axios.post('/v1/su/decrypt-text', {text: string})
            .then((response) => {
                EventBus.$emit('su-decrypt-text-success', response.data.result);
            }).catch((e) => {
            EventBus.$emit('su-decrypt-text-failed', e.response.data.message);
        }).finally(() => {
        });
    },
    uploadMedia({commit}, file) {
        axios.post('/v1/su/upload-media', file)
            .then((response) => {
                EventBus.$emit('su-upload-media-success', response.data.path);
            }).catch((e) => {
            EventBus.$emit('su-decrypt-text-failed', e.response.data.message);
        }).finally(() => {
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
