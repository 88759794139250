<template>
<div class="justify-content-center align-items-center flex">
  <div>
    <div class="text-center">
      <div class="h3">Checking Invite Code</div>
      {{ company.name }} <br>
      {{ managed_id }} <br>

      <v-progress-circular
          v-if="custodianLoading.join"
          :size="50"
          color="primary"
          indeterminate
      ></v-progress-circular>

      <v-alert type="warning" v-if="errorMessage">
        {{errorMessage}}
      </v-alert>
    </div>
  </div>

  <v-dialog
      v-model="successDialog"
      persistent
      width="300"
  >
    <v-card color="primary" dark>
      <v-card-text>
        Success. Please wait.
        <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { EventBus } from '@/plugins/eventBus';


export default {
  name: 'ListingManagerConnect',

  data() {
    return {
      managed_id: this.$route.params.managed_id,
      errorMessage: null,
      successDialog: false,
    };
  },

  created() {
    EventBus.$on('custodian-join-success', () => {
      this.successRedirectDialogInit();
    });
    EventBus.$on('custodian-join-failure', (message) => {
      this.errorMessage = message;
    });
  },

  mounted() {
    if(this.$route.params.managed_id) {
      this.fetchCustodian(this.$route.params.managed_id);
    }
  },

  computed: {
    ...mapGetters({
      company: 'company/company',
      custodianLoading: 'custodian/loading',
    }),
  },

  methods: {
    ...mapActions({
      fetchCustodian: 'custodian/join',
    }),
    successRedirectDialogInit() {
      this.successDialog = true;

      // Set a timeout for 3 seconds and redirect to the listings page
      setTimeout(() => {
        this.$router.push({ name: 'listing.index' }).catch(() => {});
      }, 2000);
    },
  },
};
</script>
