import type {RouteRecordRaw} from 'vue-router';
import {CommonRouteMeta} from "@/types";

const BaseDashboard = () => import('@/views/dashboard/BaseDashboard.vue');

const superAdminRoutes: RouteRecordRaw = {
    path: '/super-admin',
    component: BaseDashboard,
    meta: {
        title: 'Super Admin',
        icon: 'mdi-login',
        noCache: false,
        superAdmin: true,
        hidden: true,
        middleware: ['auth'],
        layout: 'Dashboard',
        roles: ['super-admin']
    } as CommonRouteMeta,
    children: [
        {
            path: '',
            name: 'super.admin.general',
            component: () => import('@/views/dashboard/superAdmin/General.vue'),
        },
        {
            path: 'roles',
            component: () => import('@/views/dashboard/superAdmin/rolesPermissions/RolesBase.vue'),
            children: [
                {
                    path: '',
                    name: 'super.admin.roles',
                    component: () => import('@/views/dashboard/superAdmin/rolesPermissions/Roles.vue'),
                },
                {
                    path: 'new',
                    name: 'super.admin.roles.new',
                    component: () => import('@/views/dashboard/superAdmin/rolesPermissions/RolesNew.vue'),
                },
                {
                    path: ':role_id',
                    name: 'super.admin.roles.edit',
                    component: () => import('@/views/dashboard/superAdmin/rolesPermissions/RolesEdit.vue'),
                },
            ],
        },
        {
            path: 'permissions',
            component: () => import('@/views/dashboard/superAdmin/rolesPermissions/PermissionsBase.vue'),
            children: [
                {
                    path: '',
                    name: 'super.admin.permissions',
                    component: () => import('@/views/dashboard/superAdmin/rolesPermissions/Permissions.vue'),
                },
                {
                    path: 'new',
                    name: 'super.admin.permissions.new',
                    component: () => import('@/views/dashboard/superAdmin/rolesPermissions/PermissionsNew.vue'),
                },
                {
                    path: ':permission_id',
                    name: 'super.admin.permissions.edit',
                    component: () => import('@/views/dashboard/superAdmin/rolesPermissions/PermissionsEdit.vue'),
                },
            ],
        },
        {
            path: 'media',
            name: 'super.admin.media',
            component: () => import('@/views/dashboard/superAdmin/media/Media.vue'),
        },
        {
            path: 'media/upload',
            name: 'super.admin.media.upload',
            component: () => import('@/views/dashboard/superAdmin/media/SUUploadMedia.vue'),
        },
        {
            path: 'users',
            component: () => import('@/views/dashboard/superAdmin/users/UsersBase.vue'),
            children: [
                {
                    path: '',
                    name: 'super.admin.users',
                    component: () => import('@/views/dashboard/superAdmin/users/Users.vue'),
                },
                {
                    path: ':user_id',
                    name: 'super.admin.users.edit',
                    component: () => import('@/views/dashboard/superAdmin/users/UsersEdit.vue'),
                },
            ],
        },
        {
            path: 'masquerade',
            name: 'super.admin.users.masquerade',
            component: () => import('@/views/dashboard/superAdmin/users/UserMasquerade.vue'),
        },
        {
            path: 'companies',
            component: () => import('@/views/dashboard/superAdmin/companies/CompaniesBase.vue'),
            children: [
                {
                    path: '',
                    name: 'super.admin.companies',
                    component: () => import('@/views/dashboard/superAdmin/companies/Companies.vue'),
                },
                {
                    path: ':company_id',
                    name: 'super.admin.company.edit',
                    component: () => import('@/views/dashboard/superAdmin/companies/CompanyEdit.vue'),
                },
            ],
        },
        {
            path: 'stats',
            component: () => import('@/views/dashboard/superAdmin/stats/StatsBase.vue'),
            children: [
                {
                    path: '',
                    name: 'super.admin.stats',
                    component: () => import('@/views/dashboard/superAdmin/stats/Stats.vue'),
                },
                {
                    path: 'users',
                    name: 'super.admin.stats.users',
                    component: () => import('@/views/dashboard/superAdmin/stats/UserStats.vue'),
                },
                {
                    path: 'subscribers',
                    name: 'super.admin.stats.subscribers',
                    component: () => import('@/views/dashboard/superAdmin/stats/SubscriberStats.vue'),
                },
            ],
        },
        {
            path: 'automations',
            component: () => import('@/views/dashboard/superAdmin/Automations/AutomationsBase.vue'),
            children: [
                {
                    path: '',
                    name: 'super.admin.automations',
                    component: () => import('@/views/dashboard/superAdmin/Automations/Automations.vue'),
                },
                {
                    path: 'trip/:trip_id',
                    name: 'super.admin.automations.trip',
                    component: () => import('@/views/dashboard/superAdmin/Automations/AutomationsTrip.vue'),
                },
            ],
        },
        {
            path: 'whats-new',
            component: () => import('@/views/dashboard/superAdmin/stats/StatsBase.vue'),
            children: [
                {
                    path: '',
                    name: 'super.admin.whats-new',
                    component: () => import('@/views/dashboard/superAdmin/whats-new/WhatsNew.vue'),
                },
            ],
        },
        {
            path: 'server',
            component: () => import('@/views/dashboard/superAdmin/server/ServerBase.vue'),
            children: [
                {
                    path: '',
                    name: 'super.admin.server',
                    component: () => import('@/views/dashboard/superAdmin/whats-new/WhatsNew.vue'),
                },
            ],
        },
        {
            path: 'utils',
            component: () => import('@/views/dashboard/superAdmin/companies/CompaniesBase.vue'),
            children: [
                {
                    path: '',
                    name: 'super.admin.utils',
                    component: () => import('@/views/dashboard/superAdmin/utils/UtilsBase.vue'),
                },
            ],
        },
        {
            path: 'push-notifications',
            component: () => import('@/views/dashboard/superAdmin/companies/CompaniesBase.vue'),
            children: [
                {
                    path: '',
                    name: 'super.admin.push-notifications',
                    component: () => import('@/views/dashboard/superAdmin/companies/PushNotifications.vue'),
                },
            ],
        },
        {
            path: 'features',
            component: () => import('@/views/dashboard/superAdmin/companies/CompaniesBase.vue'),
            children: [
                {
                    path: '',
                    name: 'super.admin.features',
                    component: () => import('@/views/dashboard/superAdmin/companies/Features.vue'),
                },
            ],
        },
        {
            path: 'affiliate',
            component: () => import('@/views/dashboard/superAdmin/companies/CompaniesBase.vue'),
            children: [
                {
                    path: '',
                    name: 'super.admin.affiliate',
                    component: () => import('@/views/dashboard/superAdmin/companies/Affiliate.vue'),
                },
            ],
        },
        {
            path: 'cache',
            component: () => import('@/views/dashboard/superAdmin/companies/CompaniesBase.vue'),
            children: [
                {
                    path: '',
                    name: 'super.admin.cache',
                    component: () => import('@/views/dashboard/superAdmin/companies/Cache.vue'),
                },
            ],
        },
        {
            path: 'affiliate/:uuid',
            component: () => import('@/views/dashboard/superAdmin/companies/CompaniesBase.vue'),
            children: [
                {
                    path: '',
                    name: 'super.admin.affiliateSingle',
                    component: () => import('@/views/dashboard/superAdmin/companies/AffiliateSingle.vue'),
                },
            ],
        },
    ],
};

export default superAdminRoutes;
