import {EventBus} from '@/plugins/eventBus';
import {Form} from '@/plugins/FWForms/index';
import axios from 'axios';
import * as types from '@/store/mutation-types';
import dayjs from 'dayjs';
import {performApiRequest} from "@/store/helpers/vuex-helpers";
import {SubscriptionPlan, SubscriptionState} from "@/types";


const subscriptionNameMap: Record<string, string> = {
    'sub_basic_monthly': 'Basic Monthly',
    'plan_IyarH890IaZoHd': 'Basic Monthly', // Production
    'sub_standard_monthly': 'Standard Monthly',
    'plan_IyarRkXlliDZhB': 'Standard Monthly', // Production
    'sub_premium_monthly': 'Premium Monthly',
    'plan_IyarEVETwSHGj7': 'Premium Monthly', // Production
    'sub_basic_annual': 'Basic Annual',
    'plan_IyarKGjJctzvXy': 'Basic Annual', // Production
    'sub_standard_annual': 'Standard Annual',
    'plan_IyarWX4xbYuDHu': 'Standard Annual', // Production
    'sub_premium_annual': 'Premium Annual',
    'plan_Iyar2EOrTQfZ6l': 'Premium Annual' // Production
};


// state
const state: SubscriptionState = {
    loading: {
        subscription: false,
        subscribing: false,
        cancelSubscription: false,
        resumeSubscription: false,
        endTrial: false,
    },

    enforceSubscription: false,

    newSubscription: {
        billingPeriod: null,
        plan: null,
    },

    subscriptionForm: new Form(),

    plans: [
        {
            name: 'basic',
            pricing: {
                monthly: {
                    id: 'sub_basic_monthly',
                    price: 34,
                },
                yearly: {
                    id: 'sub_basic_annual',
                    price: 24,
                },
            },
            description: {
                bullets: [
                    'Orders',
                    'Up to 5 vehicles',
                    'Includes 1 branch',
                    '2 staff accounts',
                    'Unlimited Customers',
                    '1 car-share connections',
                    'Online Store',
                    'Stripe Integration',
                    'And More*',
                ],
            },
        },
        {
            name: 'standard',
            pricing: {
                monthly: {
                    id: 'sub_standard_monthly',
                    price: 89,
                },
                yearly: {
                    id: 'sub_standard_annual',
                    price: 69,
                },
            },
            description: {
                bullets: [
                    'All of Basic',
                    'Up to 50 vehicles',
                    '5 staff accounts',
                    'Includes 2 branches',
                    'eSign Rental Agreements',
                    'Coupons',
                    'QR & Barcodes',
                    'Reports',
                    'Import/Export',
                    'Hide powered by Fleetwire',
                    'And More*',
                ],
            },
        },
        {
            name: 'premium',
            pricing: {
                monthly: {
                    id: 'sub_premium_monthly',
                    price: 279,
                },
                yearly: {
                    id: 'sub_premium_annual',
                    price: 239,
                },
            },
            description: {
                bullets: [
                    'All of Standard',
                    'Up to 150 vehicles',
                    '20 staff accounts',
                    'Includes 2 branches',
                    'Custom Domain',
                    'Custom Email Domain',
                    'Additional Scripts',
                    '10 Car-share connections',
                    'Zapier integration',
                    'API',
                    'TFA (Two factor authentication)',
                    'And More*',
                ],
            },
        },
    ] as SubscriptionPlan[],

    subscription: {
        stripe_status: null,
        ends_at: null,
        items: [],
        name: null,
        quantity: null,
        stripe_id: null,
        stripe_price: null,
        trial_ends_at: null,
        updated_at: null,
    },

    subscriptionMaster: null,
    extraListingsAddonCount: 0,

    subscriptionStep: 0,
    planSelection: 0,

    updatedSubscription: {
        plan: 'basic',
        interval: 'monthly',
    }
};

const getters = {
    loading: (state: SubscriptionState) => state.loading,
    enforceSubscription: (state: SubscriptionState) => state.enforceSubscription,
    plans: (state: SubscriptionState) => state.plans,
    subscription: (state: SubscriptionState) => state.subscription,
    newSubscription: (state: SubscriptionState) => state.newSubscription,
    subscriptionForm: (state: SubscriptionState) => state.subscriptionForm,
    subscriptionMaster: (state: SubscriptionState) => state.subscriptionMaster,
    extraListingsAddonCount: (state: SubscriptionState) => state.extraListingsAddonCount,
    subscriptionFullName: (state: SubscriptionState) => {
        const subscriptionName = subscriptionNameMap[state.subscriptionMaster];
        return subscriptionName ? subscriptionName : 'Premium Monthly';
    },
    subscriptionPlan: (state: SubscriptionState) => {
        switch (state.subscriptionMaster) {
            case 'sub_basic_monthly':
            case 'sub_basic_annual':
            case 'plan_IyarH890IaZoHd': // Production
            case 'plan_IyarKGjJctzvXy': // Production
            case 'price_1KfoLECZJTjTZE0Qs6poe7wG':
            case 'price_1KfoLECZJTjTZE0QmWH5yynX':
                return 'basic';
            case 'sub_standard_monthly':
            case 'sub_standard_annual':
            case 'plan_IyarRkXlliDZhB': // Production
            case 'plan_IyarWX4xbYuDHu': // Production
            case 'price_1Kfp67CZJTjTZE0Q0SW8L1Fk':
                return 'standard';
            case 'sub_premium_monthly':
            case 'sub_premium_annual':
            case 'plan_IyarEVETwSHGj7': // Production
            case 'plan_Iyar2EOrTQfZ6l': // Production
                return 'premium';
            default:
                return null;
        }
    },
    subscriptionDuration: (state: SubscriptionState) => {
        switch (state.subscriptionMaster) {
            case 'sub_basic_monthly':
            case 'sub_standard_monthly':
            case 'sub_premium_monthly':
            case 'plan_IyarH890IaZoHd':
            case 'plan_IyarRkXlliDZhB':
            case 'plan_IyarEVETwSHGj7':
                return 'monthly';
            case 'sub_standard_annual':
            case 'sub_basic_annual':
            case 'sub_premium_annual':
            case 'plan_IyarKGjJctzvXy':
            case 'plan_IyarWX4xbYuDHu':
            case 'plan_Iyar2EOrTQfZ6l':
                return 'yearly';
            default:
                return 'monthly';
        }
    },
    hasActiveSubscription: (state: SubscriptionState) => state.subscription?.stripe_status === 'active',
    hasPastDueSubscription: (state: SubscriptionState) => state.subscription?.stripe_status === 'past_due',
    hasPausedSubscription: (state: SubscriptionState) => state.subscription?.stripe_status === 'paused',
    subscriptionEndDate: (state: SubscriptionState) => {
        const subEndDate = state.subscription?.ends_at;
        if (!subEndDate) return false;
        return dayjs(subEndDate).format('MMM D, YYYY');
    },
    subscriptionStep: (state: SubscriptionState) => state.subscriptionStep,
    planSelection: (state: SubscriptionState) => state.planSelection,
    updatedSubscription: (state: SubscriptionState) => state.updatedSubscription,
};

const mutations = {
    [types.SET_SUBSCRIPTION_LOADING](state: SubscriptionState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_ENFORCE_SUBSCRIPTION](state: SubscriptionState, enforce) {
        state.enforceSubscription = enforce;
    },
    [types.SET_SUBSCRIPTION_STEP](state: SubscriptionState, step: number) {
        state.subscriptionStep = step;
    },
    [types.SET_SUBSCRIPTION_PLAN_AND_INTERVAL](state: SubscriptionState, updatedSubscription: {
        plan: string,
        interval: string
    }) {
        state.updatedSubscription = updatedSubscription;
    },
    [types.SET_PLAN_SELECTION](state: SubscriptionState, selection: number) {
        state.planSelection = selection;
    },
    [types.SET_SUBSCRIPTION](state: SubscriptionState, subscription) {
        state.subscription = subscription;
    },

    /**
     * Update the new subscription object
     */
    [types.UPDATE_NEW_SUBSCRIPTION](state: SubscriptionState, newSubscription) {
        if (newSubscription.billingPeriod) {
            state.newSubscription.billingPeriod = newSubscription.billingPeriod;
        }
        if (newSubscription.plan) {
            state.newSubscription.plan = newSubscription.plan;
        }
    },
    [types.SET_SUBSCRIPTION_MASTER](state: SubscriptionState, subscription) {
        console.dir('SET_SUBSCRIPTION_MASTER');
        console.dir(subscription);
        state.subscriptionMaster = subscription;
    },
    [types.SET_EXTRA_LISTINGS_ADDON](state: SubscriptionState, count) {
        state.extraListingsAddonCount = count;
    },
    [types.CLEAR_SUBSCRIPTION_FORM_ERRORS](state: SubscriptionState, form_field: string | null = null) {
        if (form_field === null || form_field === undefined) {
            state.subscriptionForm.errors.clear();
        } else {
            state.subscriptionForm.errors.clear(form_field);
        }
    },
    [types.SET_SUBSCRIPTION_FORM_ERROR](state: SubscriptionState, error: string | Record<string, string[]>) {
        state.subscriptionForm.errors.set(error);
    },
};

const actions = {

    async subscribeToPlan({commit}, sub) {
        try {
            const responseData = await performApiRequest(commit, 'SET_SUBSCRIPTION_LOADING', 'subscribing', () => axios.post('/v1/subscription', {id: sub}));
            commit(types.SET_SUBSCRIPTION, responseData.subscription);
            commit('company/SET_COMPANY', responseData.company, {root: true});

            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'subscription successful',
            });
            EventBus.$emit('subscribe-to-plan-success');
        } catch (error) {
            EventBus.$emit('SystemMessage', {
                type: 'error',
                message: error.response?.data?.message || error,
            });
        }
    },

    async switchPlan({commit}, sub: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_SUBSCRIPTION_LOADING', 'subscribing', () => axios.post('/v1/subscription/swap', {id: sub}));
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'subscription swap successful',
            });
            EventBus.$emit('swap-plan-success');

            return responseData;
        } catch (error) {
            EventBus.$emit('SystemMessage', {
                type: 'error',
                message: error.response?.data?.message || "There was an error swapping your subscription.",
            });
        }
    },

    addAddon({commit}, data) {
        commit('SET_SUBSCRIPTION_LOADING', {subject: 'subscribing', loading: true});

        axios.post('/v1/subscription/addon', data)
            .then((response) => {
                commit('SET_SUBSCRIPTION', response.data.subscription);
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'subscription swap successful',
                });
                EventBus.$emit('swap-plan-success');
            }).catch((e) => {
            console.dir(e);
            EventBus.$emit('SystemMessage', {
                type: 'error',
                message: e.response.data.message,
            });
        }).finally(() => {
            commit('SET_SUBSCRIPTION_LOADING', {subject: 'subscribing', loading: false});
        });
    },

    removeAddon({commit}, id: string) {
        commit('SET_SUBSCRIPTION_LOADING', {subject: 'subscribing', loading: true});

        axios.delete(`/v1/subscription/addon/${id}`)
            .then((response) => {
                commit('SET_SUBSCRIPTION', response.data.subscription);
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'subscription swap successful',
                });
                EventBus.$emit('swap-plan-success');
            }).catch((e) => {
            console.dir(e);
            EventBus.$emit('SystemMessage', {
                type: 'error',
                message: e.response.data.message,
            });
        }).finally(() => {
            commit('SET_SUBSCRIPTION_LOADING', {subject: 'subscribing', loading: false});
        });
    },

    cancelSubscription({commit}) {
        commit('SET_SUBSCRIPTION_LOADING', {subject: 'cancelSubscription', loading: true});
        commit('CLEAR_SUBSCRIPTION_FORM_ERRORS');

        axios.delete('/v1/subscription')
            .then((response) => {
                commit('SET_SUBSCRIPTION', response.data.subscription);
                EventBus.$emit('cancel-subscription-success');
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Cancel subscription successful',
                });
            }).catch((e) => {
            commit(types.SET_SUBSCRIPTION_FORM_ERROR, e?.response?.data?.message || 'There was an error cancelling your subscription.');
        }).finally(() => {
            commit('SET_SUBSCRIPTION_LOADING', {subject: 'cancelSubscription', loading: false});
        });
    },

    async cancelSubscriptionStripePortalUrl({commit}) {
        try {
            return await performApiRequest(commit, 'SET_SUBSCRIPTION_LOADING', 'cancelSubscription', () => axios.get('/v1/subscription/cancel-portal'));
        } catch (error) {
        }
    },

    async resumeSubscription({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_SUBSCRIPTION_LOADING', 'resumeSubscription', () => axios.put('/v1/subscription'));
            commit('SET_SUBSCRIPTION', responseData.subscription);
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Resume subscription successful',
            });
            return responseData;
        } catch (error) {
            EventBus.$emit('SystemMessage', {
                type: 'error',
                message: error.response?.data?.message || "There was an error resuming your subscription.",
            });
        }
    },

    /**
     * End trial early and start subscription
     */
    async endTrial({commit}) {

        try {
            const responseData = await performApiRequest(commit, 'SET_SUBSCRIPTION_LOADING', 'endTrial', () => axios.post('/v1/end-trial'));
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Ended trial successful',
            });

            return responseData;
        } catch (error) {
            EventBus.$emit('SystemMessage', {
                type: 'error',
                message: error.response?.data?.message || "There was an error ending your trial.",
            });
            throw error;
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
