import type {RouteRecordRaw} from 'vue-router';
import {CommonRouteMeta} from '@/types';
import BaseCompanyIFrame from '@/views/company/checkoutIFrame.vue';


const Company = () => import('@/views/company/CompanyCatalog.vue');
const SingleTenantProduct2 = () => import('@/views/tenantStore2/SingleTenantProduct2.vue');
const TenantStoreBase2 = () => import('@/views/tenantStore2/TenantStoreBase.vue');

export const companyRoutes: RouteRecordRaw[] = [
    {
        path: '/rental',
        component: TenantStoreBase2,
        meta: {
            middleware: ['companyStore'],
            layout: 'CompanyStore',
            noCache: true,
        } as CommonRouteMeta,
        children: [
            {
                path: '',
                component: Company,
                name: 'company',
                meta: {title: 'agencyReservation', icon: 'domain', noCache: true, showFilter: true, calendarOn: true},
            },
            {
                path: 'reservations',
                components: {
                    default: SingleTenantProduct2,
                    navBarItem: () => import('@/components/dashboard/secondaryMenus/Settings.vue'),
                },
                name: 'companyReservation',
                meta: {title: 'companyReservation', icon: 'domain', noCache: true},
            },
            {
                path: 'reservations/finalize',
                component: () => import(/* webpackChunkName: "checkout" */ '@/views/company/Finalize.vue'),
                name: 'reservations.finalize',
                meta: {title: 'Finalize', icon: 'domain', noCache: true},
            },
            {
                path: 'reservations/complete',
                component: () => import('@/views/company/Complete.vue'),
                name: 'reservations.complete',
                meta: {title: 'Booked', icon: 'domain', noCache: true},
            },
            {
                path: 'reservations/:b_id/trip-photos',
                component: () => import('@/views/company/SingleReservationTripPhotos.vue'),
                name: 'reservations.trip_photos',
                meta: {title: 'Booked', icon: 'domain', noCache: true},
            },
            {
                path: 'reservations/:b_id/inspection',
                component: () => import(/* webpackChunkName: "checkin" */ '@/views/guest/DamageReport.vue'),
                name: 'reservations.inspection',
                meta: {title: 'Checkin', icon: 'domain', noCache: true},
            },
            {
                path: 'reservations/checkin',
                component: () => import(/* webpackChunkName: "checkin" */ '@/views/company/Checkin.vue'),
                name: 'reservations.checkin',
                meta: {title: 'Checkin', icon: 'domain', noCache: true},
            },
        ],
    },
];


export const companyRoutes2: RouteRecordRaw[] = [
    {
        path: '/public',
        component: BaseCompanyIFrame,
        meta: {
            layout: 'CompanyIframeLayout',
            noCache: true,
            hidden: true,
        } as CommonRouteMeta,
        children: [
            {
                path: '/',
                component: () => import('@/views/company/CompanyCatalog.vue'),
                name: 'company.iframe',
                meta: {title: 'agencyReservation', icon: 'domain', noCache: true, showFilter: true, calendarOn: true},
            },
            {
                path: 'reservations',
                components: {
                    default: SingleTenantProduct2,
                    navBarItem: () => import(/* webpackChunkName: "checkout" */ '@/components/dashboard/secondaryMenus/Settings.vue'),
                },
                name: 'company.iframe.reservations',
                meta: {title: 'companyReservation', icon: 'domain', noCache: true},
            },
            {
                path: 'reservations/finalize',
                component: () => import(/* webpackChunkName: "checkout" */ '@/views/company/Finalize.vue'),
                name: 'reservations.iframe.finalize',
                meta: {title: 'Finalize', icon: 'domain', noCache: true},
            },
            {
                path: 'reservations/complete',
                component: () => import(/* webpackChunkName: "checkout-complete" */ '@/views/company/Complete.vue'),
                name: 'reservations.iframe.complete',
                meta: {title: 'Booked', icon: 'domain', noCache: true},
            },
            {
                path: 'reservations/:b_id/trip-photos',
                component: () => import(/* webpackChunkName: "checkout-complete" */ '@/views/company/SingleReservationTripPhotos.vue'),
                name: 'reservations.iframe.trip_photos',
                meta: {title: 'Booked', icon: 'domain', noCache: true},
            },
            {
                path: 'reservations/:b_id/inspection',
                component: () => import(/* webpackChunkName: "checkin" */ '@/views/guest/DamageReport.vue'),
                name: 'reservations.iframe.inspection',
                meta: {title: 'Checkin', icon: 'domain', noCache: true},
            },
            {
                path: 'reservations/checkin',
                component: () => import(/* webpackChunkName: "checkin" */ '@/views/company/Checkin.vue'),
                name: 'reservations.iframe.checkin',
                meta: {title: 'Checkin', icon: 'domain', noCache: true},
            },
        ]
    },
];
