<template>
  <div class="theme-layout">
    <ThemeTopNav />
    <LeftSideNav />
    <ThemeMainArea />
  </div>
</template>

<script>

import ThemeTopNav from '@/components/themes/ThemeTopNav.vue';
import LeftSideNav from '@/components/themes/LeftSideNav.vue';
import ThemeMainArea from '@/components/themes/ThemeMainArea.vue';

export default {
  name: 'ThemesBase',
  components: {
    LeftSideNav,
    ThemeTopNav,
    ThemeMainArea,
  },

  middleware: [],

  layout: 'themes',

  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.fullPath;
    },
  },
};
</script>

<style>
.theme-layout {
  background: rgb(246, 248, 248);
  display: flex;
  flex-direction: row;
  -webkit-box-align: stretch;
  align-items: stretch;
  width: 100%;
  padding-top: 62px;
  box-sizing: border-box;
}

</style>
