import { CartService } from '@/services/cart.service';

/** The company base url */
// const COMPANY_URL = 'http://af769718-cc33-47e5-8164-b07bbdb39e71.fw.test';
const COMPANY_URL = window.fleetwireOptions?.apiURL;

class ApiService {

  static apiVersion = '/api/v1';

  static async call(url = '', method = 'GET', data = {}) {

    const options = {
      method,
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        Accept: "application/json",
        // 'Current-Url': window.location.href,
      },
      referrerPolicy: 'no-referrer',
    };

    if (method === 'POST') {
      options.headers['Content-Type'] = 'application/json';
      options.body = JSON.stringify(data);
    }

    data.cart_id = CartService.getCartUuid();

    const response = await fetch(`${COMPANY_URL}${ApiService.apiVersion}${url}`, options);
    return response.json();
  }


  /**
   * Make the API calls
   *
   * @private
   * @param {string} [url] The url.
   * @param {Object} [data] The data to use for the call.
   * @returns {JSON} Returns `json`.
   */
  static async postData(url = '', data = {}) {
    data.cart_id = CartService.getCartUuid();
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data)
    });
    return response.json();
  }

  /**
   * @param {string} url
   */
  static async getData(url = '') {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        "Accept": "application/json",
      },
      referrerPolicy: 'no-referrer',
    });
    return response.json();
  }


}

export default ApiService;