import {get, isNull, map, set} from 'lodash';
import axios from 'axios';
import * as types from '@/store/mutation-types';
import {EventBus} from '@/plugins/eventBus';
import {Form} from '@/plugins/FWForms/index';
import {CartService} from '@/services/cart.service';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import getDefaultMeta from '@/utils/meta';

export interface CheckoutState {
    loading: Record<string, boolean>;
    cart: any;
    stripe: any;
    checkoutForm: Form<any>;
    _orderRequest: any;
    initialized: boolean;
    availability: any;
    coupon: any;
    checkoutRenterAuthenticatedData: object;
}

export interface ListingsAvailabilityPayload {
    dates: {
        start: string,
        end: string,
    },
    l_id?: string,
    ignoreUnavailable?: boolean,
}

// state
const state: CheckoutState = {
    loading: {
        checkout: false,
        checkAvailability: false,
        paymentIntent: false,
        checkDrivingDistance: false,
    },
    cart: {
        uuid: CartService.getCartUuid(),
        l_id: null,
        trip_start: null,
        trip_end: null,
    },

    stripe: null,

    checkoutForm: new Form(),
    checkoutRenterAuthenticatedData: null,
    _orderRequest: {
        l_id: '',
        dates: {
            start: null,
            end: null,
        },
        costs: {
            vehicle: 0,
            extras: 0,
            requiredExtras: 0,
            delivery: 0,
            taxes: 0,
            discount: 0,
            subtotal: 0,
            grandTotal: 0,
            dueToday: 0,
            securityDepositCredit: 0,
            securityDepositDebit: 0,
        },
        discounts: {
            discounted: false,
            amount: 0,
            value: 0,
            code: null,
            reason: null,
            type: null,
            durationDiscountReason: null,
            durationDiscountAmount: null,
            durationDiscountValue: null,
        },
        duration: {
            minutes: 0,
            hours: 0,
            days: 0,
            weeks: 0,
            months: 0,
            years: 0,
            units: 1,
        },
        customers: [
            {
                isPrimary: true,
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                dob: '',
                age: '',
                signaturePng: '',
                signFullName: '',
                signInitials: '',
                agreeAdoptSignature: false,
                agreeToS: false,
            },
        ],
        extras: [],
        extrasV2: [],
        requiredExtras: [],
        requiredExtrasV2: [],
        delivery: {},
        delivery_address: null,
        customFields: [],
        cartId: CartService.getCartUuid(),
    },


    initialized: false,
    availability: {
        available: true,
        booking: '',
        endDate: '',
        endTime: '',
        message: '',
        nextAvailableEndTime: '',
        nextAvailableStartTime: '',
        part: '',
        startDate: '',
        startTime: '',
    },
    coupon: {
        code: '',
        amount: 0,
        reason: '',
        type: '',
        value: 0,
    },
};

const getters = {
    loading: (state: CheckoutState) => state.loading,
    coupon: (state: CheckoutState) => state.coupon,
    availability: (state: CheckoutState) => state.availability,
    checkoutForm: (state: CheckoutState) => state.checkoutForm,
    checkoutRenterAuthenticatedData: (state: CheckoutState) => state.checkoutRenterAuthenticatedData,
    checkoutInitialized: (state: CheckoutState) => state.initialized,
    _orderRequest: (state: CheckoutState) => state._orderRequest,
};

const mutations = {
    [types.SET_CHECKOUT_LOADING](state: CheckoutState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.CLEAR_CHECKOUT_FORM_ERRORS](state: CheckoutState, form_field: string | null | undefined) {
        if (form_field === null || form_field === undefined) {
            state.checkoutForm.errors.clear();
        } else {
            state.checkoutForm.errors.clear(form_field);
        }
    },
    [types.SET_CHECKOUT_DISCOUNT](state: CheckoutState, coupon) {
        state.coupon = coupon;

        // state.order.discounts = {
        //   discounted: true,
        //   code:   get(coupon, 'code'),
        //   amount: get(coupon, 'amount'),
        //   reason: get(coupon, 'reason'),
        //   type:   get(coupon, 'type'),
        //   durationDiscountReason: null,
        //   durationDiscountAmount: null,
        //   durationDiscountValue: null,
        // };
        state._orderRequest.discounts = {
            discounted: true,
            code: get(coupon, 'code'),
            amount: get(coupon, 'amount'),
            reason: get(coupon, 'reason'),
            type: get(coupon, 'type'),
            durationDiscountReason: null,
            durationDiscountAmount: null,
            durationDiscountValue: null,
        };
    },
    [types.RESET_CHECKOUT_DISCOUNT](state: CheckoutState) {
        state.checkoutForm.errors.clear('coupon');
        state._orderRequest.discounts = {
            discounted: false,
            code: '',
            amount: 0,
            reason: '',
            type: '',
            value: 0,
            durationDiscountReason: null,
            durationDiscountAmount: null,
            durationDiscountValue: null,
        };
    },
    [types.SET_ORDER_DELIVERY](state: CheckoutState, location) {
        state._orderRequest.delivery = location;
    },
    [types.SET_CHECKOUT_INITIALIZED](state: CheckoutState, initialized: boolean) {
        console.dir('SET_CHECKOUT_INITIALIZED');
        state.initialized = initialized;
    },
    [types.SET_CHECKOUT_STRIPE](state: CheckoutState, stripe) {
        state.stripe = stripe;
    },
    [types.SET_CHECKOUT_AVAILABILITY](state: CheckoutState, availability) {
        state.availability = availability;
    },
    [types.SET_RESERVATION_REQUEST_LISTING_ID](state: CheckoutState, l_id) {
        console.dir('SET_RESERVATION_REQUEST_LISTING_ID');
        state._orderRequest.l_id = l_id;
    },
    [types.SET_RESERVATION_REQUEST_DATES](state: CheckoutState, dates) {
        console.log('%c SET_RESERVATION_REQUEST_DATES', 'color: blue;background: yellow; padding: 2px');

        console.dir(dates);
        set(state._orderRequest, 'dates', dates);
    },
    [types.SET_RESERVATION_REQUEST_DURATIONS](state: CheckoutState, duration) {
        state._orderRequest.duration = duration;
    },
    [types.SET_RESERVATION_REQUEST_EXTRAS](state: CheckoutState, extras) {
        state._orderRequest.extras = extras;
    },
    [types.SET_RESERVATION_REQUEST_EXTRAS_V2](state: CheckoutState, extras) {
        console.dir('SET_RESERVATION_REQUEST_EXTRAS_V2');
        state._orderRequest.extrasV2 = extras;
    },
    [types.SET_RESERVATION_REQUEST_REQUIRED_EXTRAS_V2](state: CheckoutState, extras) {
        state._orderRequest.requiredExtrasV2 = extras;
    },
    [types.SET_RESERVATION_REQUEST_REQUIRED_EXTRAS_V2_AT_INDEX](state: CheckoutState, data) {
        set(state._orderRequest.requiredExtrasV2, data.index, data.extra);
    },
    /**
     * Set the pricing data for the reservation request
     */
    [types.SET_RESERVATION_REQUEST_PRICING](state: CheckoutState, data) {
        const dataKeys = Object.keys(data);
        const costKeys = Object.keys(state._orderRequest.costs);

        dataKeys.forEach((key) => {
            if (costKeys.includes(key)) {
                state._orderRequest.costs[key] = data[key];
            }
        });
    },
    [types.SET_CHECKOUT_ORDER_DATES](state: CheckoutState, dates) {
        state._orderRequest.dates = dates;
    },
    [types.SET_CHECKOUT_ORDER_CUSTOMER](state: CheckoutState, customers) {
        set(state._orderRequest, 'customers', customers);

    },
    [types.SET_CHECKOUT_ORDER_CUSTOMER_BY_INDEX](state: CheckoutState, data) {
        set(state._orderRequest.customers, data.index, data.customer);
    },
    [types.SET_CHECKOUT_ORDER_CUSTOMER_AGREE_TOS](state: CheckoutState, data) {
        state._orderRequest.customers[data.index].agreeToS = data.agreeToS;
    },
    [types.SET_CHECKOUT_ORDER_CUSTOM_FIELDS](state: CheckoutState, fields) {
        state._orderRequest.customFields = fields;
    },
    [types.SET_CHECKOUT_ORDER_DELIVERY_ADDRESS](state: CheckoutState, address) {
        state._orderRequest.delivery_address = address;
    },
    [types.SET_CHECKOUT_RENTER_AUTHENTICATED](state: CheckoutState, data) {
        console.dir('SET_CHECKOUT_RENTER_AUTHENTICATED');
        console.dir(data);
        state.checkoutRenterAuthenticatedData = data;
    },
    [types.SET_CHECKOUT_ORDER_REQUIRED_EXTRAS](state: CheckoutState, data) {
        if (data.requiredExtras) {
            state._orderRequest.requiredExtras = map(data.requiredExtras, e => e.extras[0]);
        }

        if (data.requiredExtrasV2) {
            state._orderRequest.requiredExtrasV2 = map(data.requiredExtrasV2, e => {
                const parentUuid = e.uuid;
                return {
                    parentUuid,
                    choice: e.options[0],
                };
            });
        }
    },
};

const actions = {

    checkout({state, commit, rootGetters}) {
        commit('SET_CHECKOUT_LOADING', {subject: 'checkout', loading: true});
        const paymentStrategy = getDefaultMeta('settings.payment.strategy');
        const paymentProvider = get(rootGetters['company/company'], 'payment_profile.provider');
        const usesStripe = paymentProvider === 'stripe';
        const usesSquare = paymentProvider === 'square';
        const hasBalance = get(state._orderRequest, 'costs.grandTotal', 0) <= 0;

        state.checkoutForm.startProcessing();

        axios.post('/v1/checkout', state._orderRequest)
            .then((response) => {
                if (usesSquare) {
                    window.location.href = get(response, 'data.redirect');
                }

                if ((!usesSquare && !usesStripe) || isNull(paymentStrategy) || hasBalance) {
                    EventBus.$emit('checkout-successful', response.data);
                } else if (get(response, 'data.redirect')) {
                    window.location.href = get(response, 'data.redirect');
                } else {
                    const sessionId = get(response, 'data.StripeSession.id');
                    const customer = get(response, 'data.customer');
                    const stripePaymentMethod = 'payment_intent';
                    commit('booking/SET_REMEMBER_CUSTOMER', {
                        email: get(customer, 'email'),
                        stripe_id: get(customer, 'stripe_id'),
                    }, {root: true});


                    if (stripePaymentMethod === 'payment_intent') {
                        EventBus.$emit('set-booking-id', get(response, 'data.b_id'));
                        EventBus.$emit('set-listing-id', get(response, 'data.l_id'));
                        EventBus.$emit('open-checkout-payment-dialog', get(response, 'data.StripePaymentIntent'));
                    } else {
                        state.stripe.redirectToCheckout({
                            sessionId,
                        }).then(() => {
                            // If `redirectToCheckout` fails due to a browser or network
                            // error, display the localized error message to your customer
                            // using `result.error.message`.
                        });
                    }
                }
                state.checkoutForm.finishProcessing(true);
            })
            .catch((e) => {
                console.dir(e);
                state.checkoutForm.errors.set(get(e.response, 'data.errors', []));

                if (get(e.response, 'data.errors')) {
                    EventBus.$emit('checkout-message', {
                        type: 'error',
                        message: 'There was an error',
                    });
                } else {
                    EventBus.$emit('checkout-message', {
                        type: 'error',
                        message: 'There was an error - not available',
                    });
                }
                state.checkoutForm.finishProcessing(false);
            }).finally(() => {
            commit('SET_CHECKOUT_LOADING', {subject: 'checkout', loading: false});
        });
    },

    /**
     * Start an otp authentication
     */
    async renterAuthenticationStart({commit}, data: { email: string }) {
        try {
            const responseData = await performApiRequest(commit, 'SET_CHECKOUT_LOADING', 'checkAvailability', () => axios.post('/v1/checkout/renter-authentication/start', data));
            EventBus.$emit('renter-authenticated-start-success', responseData);

            EventBus.$emit('open-renter-otp-auth', {
                focusId: 'renter-email-input',
                email: responseData.email,
                phone_number: responseData.phone_number,
            });
        } catch (error) {
        }
    },

    /**
     * Complete an otp authentication
     */
    async renterAuthenticationComplete({commit}, data) {
        try {
            const responseData = await performApiRequest(commit, 'SET_CHECKOUT_LOADING', 'checkAvailability', () => axios.post('/v1/checkout/renter-authentication/complete', data));
            commit(types.SET_CHECKOUT_RENTER_AUTHENTICATED, responseData.renter);
            // EventBus.$emit('renter-authenticated-complete-success', responseData);
        } catch (error) {
            EventBus.$emit('renter-authenticated-complete-failed');
        }
    },


    /**
     * Verify Stripe Payment with Intent
     */
    verifyPayment({commit, rootGetters}, paymentIntentId) {
        commit('SET_CHECKOUT_LOADING', {subject: 'checkAvailability', loading: true});
        axios.get(`/v1/company/${rootGetters['company/company'].uuid}/verify-payment-intent/${paymentIntentId}`)
            .then(() => {
                EventBus.$emit('payment-intent-verified');
            }).catch(() => {
        }).finally(() => {
            commit('SET_CHECKOUT_LOADING', {subject: 'checkAvailability', loading: false});
        });
    },


    /**
     * Check the available listings for a company
     */
    async checkListingsAvailability({commit, rootGetters}, data: ListingsAvailabilityPayload) {
        const params = new URLSearchParams({
            start: data.dates.start,
            end: data.dates.end,
        });

        if (data.l_id) params.append('l_id', data.l_id);
        if (data.ignoreUnavailable) params.append('ignoreUnavailable', 'true');


        try {
            console.log(params.toString());
            const companyUuid = rootGetters['company/company']?.uuid;

            const url = `/v1/company/${companyUuid}/listings/availability?${params.toString()}`;

            const responseData = await performApiRequest(
                commit,
                'SET_CHECKOUT_LOADING',
                'checkAvailability',
                () => axios.get(url)
            );

            commit('listing/SET_ALL_LISTINGS', responseData?.listings ?? [], {root: true});
        } catch (error) {
            console.error('Error fetching listings availability:', error);
        }
    },

    checkOneListingsAvailability({commit, state, rootGetters}, {l_id, dates}) {
        commit('SET_CHECKOUT_LOADING', {subject: 'checkAvailability', loading: true});

        axios.get(`/v1/company/${rootGetters['company/company'].uuid}/listings/${l_id}/availability?start=${dates.start}&end=${dates.end}`)
            .then((response) => {
                commit('listing/SET_ALL_LISTINGS', get(response.data, 'listings', []), {root: true});
            }).catch(() => {
        }).finally(() => {
            commit('SET_CHECKOUT_LOADING', {subject: 'checkAvailability', loading: false});
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
