<template>
  <div>
    <h2 class="mt-8 mb-4 font-bold text-2xl">
      <FeatureTitle
        featurePremium
        featureStandard
        title="Activity Log"
      />
    </h2>

    <v-card>
      <v-card-text>
        <div v-if="activity.length > 0" class="space-y-4">
          <div v-for="(item, key) in activity" :key="key"
               class="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4 hover:bg-gray-50 dark:hover:bg-gray-600">
            <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2">
              <div class="font-medium text-gray-900 dark:text-white">
                {{ item.description }}
              </div>
              <div class="text-sm text-gray-500 dark:text-gray-400">
                {{ formatRentalDate(item.created_at) }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="activity.length > 0">
          <div class="grid grid-cols-1 gap-2">
            <v-pagination
              v-model="page"
              :length="pages"
              class="my-4"
            />
          </div>
        </div>

        <div v-else class="text-center py-8 text-gray-500 dark:text-gray-400">No Activity</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts" setup>
import {defineAsyncComponent, onMounted, ref, watch} from 'vue';
import {useDateFormatter} from '@/composables/useDate';
import {useCompany} from '@/composables/useCompany';

const FeatureTitle = defineAsyncComponent(() => import('@/components/dashboard/FeatureTitle.vue'));

const {formatRentalDate} = useDateFormatter();
const {getActivityLog} = useCompany();

const activity = ref([]);
const page = ref(1);
const pages = ref(1);

watch(page, () => {
  console.dir(page.value);
  getActivityLog({page: page.value}).then((response) => {
    activity.value = response?.data || [];
  });
});

onMounted(() => {
  getActivityLogInit();
});

const getActivityLogInit = () => {
  getActivityLog({page: 1})
    .then((response) => {
      page.value = response?.current_page || 1;
      pages.value = response?.last_page || 1;
      activity.value = response?.data || [];
    });
};
</script>
