<template>
  <div class="p-4">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div>
        <div class="setting-section-description">
          <h3 class="text-lg font-medium text-gray-900 dark:text-gray-100">Email Templates</h3>

          <div class="feature-description">
            Create and customize Email templates to type less and send faster.
            <TheHelpLink url="https://help.fleetwire.io/en/article/create-your-own-email-templates-in-fleetwire-1apedin/?bust=1649702346456"></TheHelpLink>
          </div>
        </div>
      </div>

      <div class="col-span-1 md:col-span-2 space-y-6">
        <v-card>
          <v-card-title class="text-base sm:text-lg">Automatic Emails</v-card-title>
          <v-card-subtitle class="text-sm">These emails are sent automatically, their contents can't be changed before
            sending.
          </v-card-subtitle>
          <v-card-text>
            <div class="rounded-lg overflow-hidden border border-gray-200 dark:border-gray-700">
              <div class="p-3 bg-gray-50 dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 font-semibold">
                Template
              </div>

              <div v-for="item in automaticEmailTemplates" :key="item.uuid" class="template-item py-5">
                <router-link
                  :to="{ name: 'settings.account.email.email_template_single', params: {uuid: item.uuid} }"
                  class="block p-3 hover:bg-gray-50 dark:hover:bg-gray-800 text-blue-500">
                  <span class="font-semibold">{{ item.name }}</span>
                </router-link>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-title class="text-base sm:text-lg">Manual Emails</v-card-title>
          <v-card-subtitle class="text-sm">These emails are available when viewing orders, customers, or documents. You
            can edit their
            contents before sending.
          </v-card-subtitle>
          <v-card-text>
            <div class="rounded-lg overflow-hidden border border-gray-200 dark:border-gray-700">
              <div class="p-3 bg-gray-50 dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 font-semibold">
                Template
              </div>

              <div v-for="item in manualEmailTemplates" :key="item.uuid" class="template-item py-5">
                <router-link
                  :to="{ name: 'settings.account.email.email_template_single', params: {uuid: item.uuid} }"
                  class="block p-3 hover:bg-gray-50 dark:hover:bg-gray-800 text-blue-500">
                  <span class="font-semibold">{{ item.name }}</span>
                </router-link>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="p-4">
            <v-btn disabled size="small" variant="outlined">New Email Template</v-btn>
            <span class="text-sm text-gray-500 ml-2">Coming soon</span>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, onMounted} from 'vue';
import {useStore} from 'vuex';

const store = useStore();
const emailTemplates = computed(() => store.getters['email/emailTemplates']);
const automaticEmailTemplates = computed(() => emailTemplates.value.filter((t) => t.is_automatic));
const manualEmailTemplates = computed(() => emailTemplates.value.filter((t) => !t.is_automatic));

const getEmailTemplates = () => store.dispatch('email/getEmailTemplates');

onMounted(() => {
  getEmailTemplates();
});
</script>

<style scoped>
.template-item:not(:last-child) {
  border-bottom: 1px solid rgb(214, 217, 219);
}

@media (max-width: 640px) {
  .v-card-title {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .v-card-subtitle {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
</style>