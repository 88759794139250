import * as types from '@/store/mutation-types';
import {Form} from '@/plugins/FWForms/index';
import axios from 'axios';
import {EventBus} from '@/plugins/eventBus';
import {StorageService} from '@/services/storage.service';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {Invoice, StripeSubscriptionObject} from '@/types/Stripe';
import {SubscriptionPlan} from '@/types';
import {push} from 'notivue';

export interface StripeState {
    loading: Record<string, boolean>,
    currentWorkingInvoice: any,
    currentWorkingPaymentIntent: any,
    billing_session_url: string | null,
    stripeIdentities: Array<string>,
    stripeForm: Form<any>,
    charge: any,
    account: any,
    invoices: Invoice[],
    stripeSubscriptionObject: StripeSubscriptionObject
    stripePrices: SubscriptionPlan[]
}

const state: StripeState = {
    loading: {
        account: false,
        charge: false,
        fetchInvoice: false,
        fetchPortalUrl: false,
        updateDefaultPaymentMethod: false,
        createStripeVerification: false,
        bookingInitStripeVerification: false,
        cancelStripeVerification: false,
        refreshDocumentImageFiles: false,
        freshStripeVerification: false,
        fetchSetupUrl: false,
        invoices: false,
    },
    currentWorkingInvoice: {},
    currentWorkingPaymentIntent: {},
    billing_session_url: null,
    stripeIdentities: [],
    stripeForm: new Form(),
    charge: null,
    account: null,
    invoices: [],
    stripeSubscriptionObject: null,
    stripePrices: [
        {
            name: 'basic',
            pricing: {
                monthly: {
                    id: 'sub_basic_monthly',
                    price_id: 'sub_basic_monthly',
                    price: 34,
                },
                yearly: {
                    id: 'sub_basic_annual',
                    price_id: 'sub_basic_annual',
                    price: 24,
                },
            },
            description: {
                bullets: [
                    'Orders',
                    'Up to 5 vehicles',
                    'Includes 1 branch',
                    '2 staff accounts',
                    'Unlimited Customers',
                    '1 car-share connections',
                    'Online Store',
                    'Stripe Integration',
                    'And More*',
                ],
            },
        },
        {
            name: 'standard',
            pricing: {
                monthly: {
                    id: 'sub_standard_monthly',
                    price_id: 'sub_standard_monthly',
                    price: 89,
                },
                yearly: {
                    id: 'sub_standard_annual',
                    price_id: 'sub_standard_annual',
                    price: 69,
                },
            },
            description: {
                bullets: [
                    'All of Basic',
                    'Up to 50 vehicles',
                    '5 staff accounts',
                    'Includes 2 branches',
                    'eSign Rental Agreements',
                    'Coupons',
                    'QR & Barcodes',
                    'Reports',
                    'Import/Export',
                    'Hide powered by Fleetwire',
                    'And More*',
                ],
            },
        },
        {
            name: 'premium',
            pricing: {
                monthly: {
                    id: 'sub_premium_monthly',
                    price_id: 'sub_premium_monthly',
                    price: 279,
                },
                yearly: {
                    id: 'sub_premium_annual',
                    price_id: 'sub_premium_annual',
                    price: 239,
                },
            },
            description: {
                bullets: [
                    'All of Standard',
                    'Up to 150 vehicles',
                    '20 staff accounts',
                    'Includes 2 branches',
                    'Custom Domain',
                    'Custom Email Domain',
                    'Additional Scripts',
                    '10 Car-share connections',
                    'Zapier integration',
                    'API',
                    'TFA (Two factor authentication)',
                    'And More*',
                ],
            },
        },
    ],
};

const getters = {
    loading: (state: StripeState) => state.loading,
    currentWorkingInvoice: (state: StripeState) => state.currentWorkingInvoice,
    currentWorkingPaymentIntent: (state: StripeState) => state.currentWorkingPaymentIntent,
    billingSessionUrl: (state: StripeState) => state.billing_session_url,
    stripeIdentities: (state: StripeState) => state.stripeIdentities,
    stripeForm: (state: StripeState) => state.stripeForm,
    charge: (state: StripeState) => state.charge,
    account: (state: StripeState) => state.account,
    stripeSubscriptionObject: (state: StripeState) => state.stripeSubscriptionObject,
    invoices: (state: StripeState) => state.invoices,
    stripePrices: (state: StripeState) => state.stripePrices,
};

const mutations = {
    [types.SET_STRIPE_LOADING](state: StripeState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_CURRENT_WORKING_INVOICE](state: StripeState, invoice) {
        state.currentWorkingInvoice = invoice;
    },
    [types.SET_CURRENT_WORKING_PAYMENT_INTENT](state: StripeState, intent) {
        state.currentWorkingPaymentIntent = intent;
    },
    [types.SET_BILLING_PORTAL_SESSION](state: StripeState, url: string) {
        state.billing_session_url = url;
    },
    [types.SET_STRIPE_PRICE_DATA](state: StripeState, prices: object) {
        //     sub_basic_annual
        //         :
        //         "price_1KfoLECZJTjTZE0Qs6poe7wG"
        //     sub_basic_monthly
        //         :
        //         "price_1KfoLECZJTjTZE0QmWH5yynX"
        //     sub_premium_annual
        //         :
        //         "sub_premium_annual"
        //     sub_premium_monthly
        //         :
        //         "sub_premium_monthly"
        //     sub_standard_annual
        //         :
        //         "sub_standard_annual"
        //     sub_standard_monthly
        //         :
        //         "sub_standard_monthly"
        //             [[Prototype]]
        //

        console.dir(prices);
        console.dir(state.stripePrices);
        state.stripePrices.forEach((plan) => {
            plan.pricing.monthly.price_id = prices[plan.pricing.monthly.id];
            plan.pricing.yearly.price_id = prices[plan.pricing.yearly.id];
        });
    },
    [types.SET_STRIPE_IDENTITIES](state: StripeState, ids) {
        state.stripeIdentities = ids;
    },
    [types.SET_SUBSCRIPTION_DETAILS](state: StripeState, details) {
        state.stripeSubscriptionObject = details?.subscription || details;
    },
    [types.SET_STRIPE_ACCOUNT](state: StripeState, data) {
        state.account = data;
    },
    [types.SET_CURRENT_STRIPE_CHARGE](state: StripeState, charge) {
        state.charge = charge;
    },
    [types.SET_INVOICES](state: StripeState, invoices: Invoice[]) {
        state.invoices = invoices;
    },
};

const actions = {
    async fetchInvoices({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_STRIPE_LOADING', 'invoices', () => axios.get('/v1/stripe/invoices'));
            commit('SET_INVOICES', responseData.invoices);
        } catch (error) {
            console.error(error)
        }
    },

    fetchInvoice({commit, rootGetters}, invoice_id) {
        commit('SET_STRIPE_LOADING', {subject: 'fetchInvoice', loading: true});
        axios.get(`/v1/company/${rootGetters['company/company'].uuid}/stripe/invoices/${invoice_id}`)
            .then((response) => {
                commit('SET_CURRENT_WORKING_INVOICE', response.data.invoice);
            }).catch(() => {
            push.error({
                message: 'Settings update error. Try again later.',
            });
        }).finally(() => {
            commit('SET_STRIPE_LOADING', {subject: 'fetchInvoice', loading: false});
        });
    },

    fetchCharge({commit}, charge_id: string) {
        commit('SET_STRIPE_LOADING', {subject: 'charge', loading: true});

        axios.get(`/v1/stripe/charge/${charge_id}`)
            .then((response) => {
                commit(types.SET_CURRENT_STRIPE_CHARGE, response.data.charge);
            }).catch(() => {
            push.error({
                message: 'Settings update error. Try again later.',
            });
        }).finally(() => {
            commit('SET_STRIPE_LOADING', {subject: 'charge', loading: false});
        });
    },

    async fetchStripeAccount({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_STRIPE_LOADING', 'account', () => axios.get('/v1/stripe/account'));
            commit(types.SET_STRIPE_ACCOUNT, responseData.account);
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'Fetching Stripe Account error. Try again later.',
            });
        }
    },


    /**
     * Attach a new payment method to company
     */
    attachPaymentMethod({commit}, paymentMethod) {
        commit('SET_PAYMENTS_LOADING', {subject: 'addPaymentMethod', loading: true});

        axios.post('/v1/stripe/attach-payment-method', paymentMethod)
            .then(() => {
                EventBus.$emit('add-payment-method-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_PAYMENTS_LOADING', {subject: 'addPaymentMethod', loading: false});
        });
    },

    fetchPaymentIntent({commit}, intent_id: string) {
        commit('SET_STRIPE_LOADING', {subject: 'notifications', loading: true});
        axios.get(`/v1/stripe/payment-intent/${intent_id}`)
            .then((response) => {
                commit(types.SET_CURRENT_WORKING_PAYMENT_INTENT, response.data.intent);
            }).catch(() => {
            push.error({
                message: error?.response?.data?.message || 'Settings update error. Try again later.',
            });
        }).finally(() => {
            commit('SET_STRIPE_LOADING', {subject: 'notifications', loading: false});
        });
    },

    /**
     * Create a Stripe billing postal link and supply
     *
     * @param commit
     */
    fetchBillingPortalSession({commit}) {
        commit('SET_STRIPE_LOADING', {subject: 'fetchPortalUrl', loading: true});

        axios.get('/v1/stripe/billing-portal')
            .then((response) => {
                commit(types.SET_BILLING_PORTAL_SESSION, response.data.url);
            }).catch((error) => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_STRIPE_LOADING', {subject: 'fetchPortalUrl', loading: false});
        });
    },

    async fetchCheckoutUrl({commit}, data: { price: string }) {
        try {
            const responseData = await performApiRequest(commit, 'SET_STRIPE_LOADING', 'fetchPortalUrl', () => axios.post('/v1/stripe/checkout-url', data));

            commit(types.SET_BILLING_PORTAL_SESSION, responseData.url);

            return responseData.url;
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
            throw error;
        }
    },

    async fetchStripePriceData({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_STRIPE_LOADING', 'fetchPortalUrl', () => axios.get('/v1/stripe/prices'));

            commit(types.SET_STRIPE_PRICE_DATA, responseData.prices);

            return responseData.url;
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
            throw error;
        }
    },

    async fetchSetupUrl({commit}, data) {
        try {
            return await performApiRequest(commit, 'SET_STRIPE_LOADING', 'fetchSetupUrl', () => axios.post('/v1/stripe/setup-url', data));
        } catch (error) {
            console.error(error)
        }
    },

    async fetchSubscriptionDetails({commit}) {

        try {
            const responseData = await performApiRequest(commit, 'SET_STRIPE_LOADING', 'fetchSubscriptionDetails', () => axios.get('/v1/stripe/subscription-details'));
            commit('SET_SUBSCRIPTION_DETAILS', responseData);
        } catch (error) {
            console.error(error)
        }
    },

    /**
     * Update default payment method
     */
    updateDefaultPaymentMethod({commit}, payment_method) {
        commit('SET_STRIPE_LOADING', {subject: 'updateDefaultPaymentMethod', loading: true});

        axios.post('/v1/stripe/update-default-payment', {payment_method})
            .then((response) => {
                commit('payments/SET_PAYMENT_METHODS', response?.data?.payment_methods || [], {root: true});
                commit('company/SET_COMPANY', response.data.company, {root: true});
            }).catch(() => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_STRIPE_LOADING', {subject: 'updateDefaultPaymentMethod', loading: false});
        });
    },

    testStripeIdentity({commit, rootGetters}) {
        commit('SET_STRIPE_LOADING', {subject: 'updateDefaultPaymentMethod', loading: true});
        axios.post(`/v1/company/${rootGetters['company/company'].uuid}/stripe/test-identity`)
            .then((response) => {
                commit('payments/SET_PAYMENT_METHODS', response?.data?.payment_methods || [], {root: true});
                commit('company/SET_COMPANY', response.data.company, {root: true});
            }).catch(() => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_STRIPE_LOADING', {subject: 'updateDefaultPaymentMethod', loading: false});
        });
    },

    /**
     * Create a Stripe Identity Verification link and supply to customer
     */
    bookingInitStripeVerification({state, commit}, data) {
        commit('SET_STRIPE_LOADING', {subject: 'bookingInitStripeVerification', loading: true});
        axios.post(`/v1/bookings/${data.b_id}/stripe/identity/start`, data)
            .then((response) => {
                EventBus.$emit('stripe-create-verification-successful');
                commit('booking/SET_CUSTOMER_BOOKING', response.data.booking || [], {root: true});
            }).catch((error) => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
            state.stripeForm.errors.set(error?.response?.data?.errors);
        }).finally(() => {
            commit('SET_STRIPE_LOADING', {subject: 'bookingInitStripeVerification', loading: false});
        });
    },


    /**
     * Create a Stripe Identity link and send
     */
    createStripeVerification({commit}, data) {
        commit('SET_STRIPE_LOADING', {subject: 'createStripeVerification', loading: true});
        axios.post('/v1/stripe/identity', data)
            .then((response) => {
                commit('SET_STRIPE_IDENTITIES', response.data.ids);
                EventBus.$emit('stripe-create-verification-successful', response.data.ids);
            }).catch((error) => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_STRIPE_LOADING', {subject: 'createStripeVerification', loading: false});
        });
    },

    /**
     * Create a Stripe Identity link and send
     *
     * @param commit
     * @param {String} vsId
     */
    cancelStripeVerification({commit}, vsId: string) {
        commit('SET_STRIPE_LOADING', {subject: 'cancelStripeVerification', loading: vsId});

        axios.delete(`/v1/stripe/identity/${vsId}`)
            .then((response) => {
                EventBus.$emit('stripe-cancel-verification-successful', response.data.identity);
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Canceled',
                });
            }).catch((error) => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_STRIPE_LOADING', {subject: 'cancelStripeVerification', loading: false});
        });
    },

    fetchStripeVerification({commit}, vsId: string) {
        commit('SET_STRIPE_LOADING', {subject: 'freshStripeVerification', loading: true});

        axios.get(`/v1/stripe/identity/${vsId}/fresh`)
            .then((response) => {
                EventBus.$emit('stripe-fresh-verification-successful', response.data.session);
            }).catch((error) => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_STRIPE_LOADING', {subject: 'freshStripeVerification', loading: false});
        });
    },

    refreshDocumentImageFiles({commit}, vsId: string) {
        commit('SET_STRIPE_LOADING', {subject: 'refreshDocumentImageFiles', loading: true});

        axios.post(`/v1/stripe/identity/${vsId}/refresh-files`)
            .then((response) => {
                EventBus.$emit('stripe-refresh-files-successful', response.data.files);
            }).catch((e) => {
            EventBus.$emit('stripe-refresh-files-failure', e?.response?.data?.message || 'There was an error');
        }).finally(() => {
            commit('SET_STRIPE_LOADING', {subject: 'refreshDocumentImageFiles', loading: false});
        });
    },

    getStripeIdentities({commit}) {
        commit('SET_STRIPE_LOADING', {subject: 'updateDefaultPaymentMethod', loading: true});
        axios.get(`/v1/company/${StorageService.getCompanyUuid()}/stripe/identity`)
            .then((response) => {
                commit('SET_STRIPE_IDENTITIES', response.data.ids);
            }).catch((error) => {
            push.error({
                message: error?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit('SET_STRIPE_LOADING', {subject: 'updateDefaultPaymentMethod', loading: false});
        });
    },

    async fetchStripeCapabilities({commit}) {
        const responseData = await performApiRequest(commit, 'SET_STRIPE_LOADING', 'updateDefaultPaymentMethod', () => axios.get('/v1/stripe/capabilities'));

        commit(types.SET_STRIPE_ACCOUNT, responseData);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
