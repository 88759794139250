import parsePhoneNumber from 'libphonenumber-js'

/**
 * Formats a number with commas as thousands separators.
 * @param x - The number to format.
 * @returns The formatted number as a string with commas, or '0' if the input is not a valid number.
 */
export const formatNumberWithCommas = (x: number | null | undefined): string => {
    if (x === null || x === undefined || typeof x !== 'number' || isNaN(x)) {
        return '0';
    }

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};


/**
 * Formats a phone number based on its length.
 * @param number - The phone number as a string.
 * @returns The formatted phone number.
 */
export const formatPhoneNumber = (number: string): string => {
    if (!number) return '';

    try {
        const phone = parsePhoneNumber(number);

        // Ensure the number is valid before formatting
        if (!phone?.isValid()) {
            return number.replace(/[^0-9+]/g, ''); // Keep only numbers & leading '+'
        }

        if (phone.country === 'US' && phone.nationalNumber.length === 10) {
            return `+1 (${phone.nationalNumber.slice(0, 3)}) ${phone.nationalNumber.slice(3, 6)}-${phone.nationalNumber.slice(6)}`;
        }

        return phone.formatInternational();
    } catch (error) {
        console.warn('Invalid phone number:', number, error);
        return number.replace(/[^0-9+]/g, ''); // Remove all non-numeric characters except '+'
    }
}