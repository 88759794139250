<template>
  <v-dialog v-model="isOpen" :scrollable="true" width="600">
    <v-card>
      <v-card-title>SIPP</v-card-title>

      <v-card-text>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
          Standard Interline Passenger Procedure (SIPP) codes are four-letter abbreviations that identify the major
          features of a rental car. For example, a compact, two-door car with air conditioning would have a SIPP code
          of CBMR or CBAR depending on whether it’s a manual or an automatic.
        </p>
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tbody>
          <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
            <td style="text-align: center;">
              <strong>First Letter</strong> <br/>Size of vehicle
            </td>
            <td>
              <strong>C</strong> - Compact<br> <strong>D</strong> - Compact Elite<br>
              <strong>E</strong> - Economy<br> <strong>F</strong> - Fullsize<br>
              <strong>G</strong> - Fullsize Elite<br> <strong>H</strong> - Economy Elite<br>
              <strong>I</strong> - Intermediate<br> <strong>J</strong> - Intermediate Elite<br>
              <strong>L</strong> - Luxury<br> <strong>M</strong> - Mini<br> <strong>N</strong> - Mini Elite<br>
              <strong>O</strong> - Oversize<br> <strong>P</strong> - Premium<br>
              <strong>R</strong> - Standard Elite<br> <strong>S</strong> - Standard<br>
              <strong>U</strong> - Premium Elite<br> <strong>W</strong> - Luxury Elite<br>
              <strong>X</strong> - Special<br></td>
          </tr>
          <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
            <td style="text-align: center;"><strong>Second Letter<br></strong>Number of doors</td>
            <td><strong>B</strong> - 2/3 Door<br> <strong>C</strong> - 2 or 4 Door<br>
              <strong>D</strong> - 4/5 Door<br> <strong>E</strong> - Coupe<br> <strong>F</strong> - SUV<br>
              <strong>G</strong> - Crossover<br> <strong>H</strong> - Motorhome<br>
              <strong>J</strong> - Open-Air All Terrain<br> <strong>K</strong> - Commercial Van / Truck<br>
              <strong>L</strong> - Limousine<br> <strong>M</strong> - Monospace<br> <strong>N</strong> - Roadster<br>
              <strong>P</strong> - Pickup Regular Cab<br> <strong>Q</strong> - Pickup Extended Cab<br>
              <strong>R</strong> - Recreational<br> <strong>S</strong> - Sport<br>
              <strong>T</strong> - Convertible<br> <strong>V</strong> - Passenger Van<br>
              <strong>W</strong> - Wagon / Estate<br> <strong>X</strong> - Special<br>
              <strong>Y</strong> - Two-Wheel Drive<br> <strong>Z</strong> - Special Offer Car<br></td>
          </tr>
          <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
            <td style="text-align: center;"><strong>Third Letter</strong> <br>Transmission and drive</td>
            <td><strong>A</strong> - Auto Drive<br> <strong>B</strong> - Auto Four-Wheel Drive<br>
              <strong>C</strong> - Manual All-Wheel Drive<br> <strong>D</strong> - Auto All-Wheel Drive<br>
              <strong>M</strong> - Manual Drive<br> <strong>N</strong> - Manual Four-Wheel Drive
            </td>
          </tr>
          <tr class=" bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
            <td style="text-align: center;"><strong>Fourth Letter<br></strong>Fuel and A/C<br></td>
            <td><strong>N</strong> - Unspecified Fuel, No A/C<br> <strong>R</strong> - Unspecified Fuel, A/C<br>
              <strong>V</strong> - Petrol, A/C<br> <strong>Z</strong> - Petrol, No A/C<br>
              <strong>D</strong> - Diesel, A/C<br> <strong>Q</strong> - Diesel, No A/C<br>
              <strong>E</strong> - Electric, A/C<br> <strong>C</strong> - Electric, No A/C<br>
              <strong>H</strong> - Hybrid, A/C<br> <strong>I</strong> - Hybrid, No A/C<br>
              <strong>L</strong> - LPG/Gas, A/C<br> <strong>S</strong> - LPG/Gas, No A/C<br>
              <strong>M</strong> - Multi Fuel, A/C<br> <strong>F</strong> - Multi Fuel, No A/C<br>
              <strong>A</strong> - Hydrogen, A/C<br> <strong>B</strong> - Hydrogen, No A/C<br>
              <strong>U</strong> - Ethanol, A/C<br> <strong>X</strong> - Ethanol, No A/C
            </td>
          </tr>
          </tbody>
        </table>
      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" variant="text" @click="closeDialog">Okay</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import {computed} from 'vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});

// Emit Event
const emit = defineEmits(['update:modelValue']);

// Close Dialog
const closeDialog = () => {
  isOpen.value = false;
};

// Computed binding for the dialog
const isOpen = computed({
  get: () => props.modelValue,
  set: (value: boolean) => emit('update:modelValue', value),
});
</script>
