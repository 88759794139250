import UtilService from "@/tenant/Services/util.service";

const CART_UUID = 'fw_CartId';

const CartService = {

    /**
     * Saves a cart uuid
     */
    saveCartUuid(uuid: string): void {
        UtilService.log(`✅ Saving cart uuid: ${uuid}`);
        localStorage.setItem(CART_UUID, uuid);
    },

    /**
     * Gets a cart uuid
     *
     */
    getCartUuid() {
        return localStorage.getItem(CART_UUID);
    },

    /**
     * Removes a cart uuid from localstorage
     *
     */
    removeCartUuid(): void {
        localStorage.removeItem(CART_UUID);
    },


    async createCart(url: string) {
        const payload = {
            current_url: window.location.href,
        };
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(payload),
        });
        return response.json();
    },

    async fetchCart(url: string) {
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                Accept: 'application/json',
            },
            referrerPolicy: 'no-referrer',
        });
        return response.json();
    },

    async updateCart(url: string, payload: { start: string, end: string, current_url: string }) {
        payload.current_url = window.location.href;

        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(payload),
        });
        return response.json();
    },
};

export {CartService};
