import axios from 'axios';
import * as types from '@/store/mutation-types';
import {Form} from '@/plugins/FWForms/index';
import {BookingInterface, Coupon, StoreLoaders} from '@/types';
import {RootState} from '@/types/RootState';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {Module} from 'vuex';
import {push} from 'notivue'

export interface CouponState {
    loading: StoreLoaders;
    couponForm: Form<any>;
    coupons: Coupon[];
    redemptions: BookingInterface[];
}

// state
const state: CouponState = {
    loading: {
        save_coupon: false,
        update_coupon: false,
        fetch_coupon: false,
        delete_coupon: false,
        redemptions: false,
    },
    couponForm: new Form(),
    coupons: [],
    redemptions: [],
};


const mutations = {
    [types.SET_COUPON_LOADING](state: CouponState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_COUPONS](state: CouponState, coupons: Coupon[]) {
        state.coupons = coupons;
    },
    [types.SET_COUPON_REDEMPTIONS](state: CouponState, redemptions: BookingInterface[]) {
        state.redemptions = redemptions;
    },
    [types.UPDATE_COUPON](state: CouponState, coupon: Coupon) {
        const index = state.coupons.findIndex(c => c.uuid === coupon.uuid);

        if (index >= 0) {
            const updatedCoupons = [...state.coupons];

            updatedCoupons[index] = coupon;

            state.coupons = updatedCoupons;
        }
    },
    [types.CLEAR_COUPON_FORM_ERRORS](state: CouponState, form_field: string | null = null) {
        if (form_field === null || form_field === undefined) {
            state.couponForm.errors.clear();
        } else {
            state.couponForm.errors.clear(form_field);
        }
    },
    [types.SET_COUPON_FORM_ERROR](state: CouponState, error: string | Record<string, string[]>) {
        state.couponForm.errors.set(error);
    },
    [types.SET_COUPON_FORM_BUSY](state: CouponState, busy: boolean) {
        state.couponForm.busy = busy;
    },

};

const actions = {

    /**
     * Check a coupon
     */
    async checkCoupon({commit, rootGetters}, {coupon, l_id, email}) {
        commit(types.CLEAR_COUPON_FORM_ERRORS);

        let queryString = `?coupon=${coupon}`;
        if (l_id) {
            queryString += `&l_id=${l_id}`;
        }
        if (email) {
            email = encodeURIComponent(email);
            queryString += `&email=${email}`;
        }

        try {
            commit('SET_COUPON_FORM_BUSY', true);

            const responseData = await performApiRequest(commit, 'SET_COUPON_LOADING', 'fetch_coupon', () => axios.get(`/v1/company/${rootGetters['company/company'].uuid}/coupons/check${queryString}`));
            commit('tenantCheckout/SET_TENANT_CHECKOUT_DISCOUNT', responseData?.coupon, {root: true});
        } catch (error) {
            commit('SET_COUPON_FORM_ERROR', error.response.data?.errors || 'There was an error');
            commit('checkout/RESET_CHECKOUT_DISCOUNT', null, {root: true});
        } finally {
            commit(types.SET_COUPON_FORM_BUSY, false);
        }
    },

    async fetchCoupons({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COUPON_LOADING', 'fetch_coupon', () => axios.get('/v1/coupons'));
            commit(types.SET_COUPONS, responseData.coupons);
        } catch (error) {
        }
    },

    async createCoupon({commit}, coupon: Coupon) {
        commit(types.CLEAR_COUPON_FORM_ERRORS);
        try {
            const responseData = await performApiRequest(commit, 'SET_COUPON_LOADING', 'save_coupon', () => axios.post('/v1/coupons', coupon));
            commit(types.SET_COUPONS, responseData.coupons);

            push.success({
                message: 'Coupon created successfully',
            });
        } catch (error) {
            commit(types.SET_COUPON_FORM_ERROR, error.response.data?.errors || 'There was an error');
        }
    },

    async toggleCouponActive({commit}, couponUuid: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COUPON_LOADING', 'save_coupon', () => axios.put(`/v1/coupons/${couponUuid}/toggle`));
            commit(types.SET_COUPONS, responseData.coupons);

            push.success({
                message: 'Coupon status updated successfully',
            });
        } catch (error) {
        }
    },

    async multiCouponToggle({commit}, coupons: typeof Array<Coupon['uuid']>) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COUPON_LOADING', 'save_coupon', () => axios.put('/v1/coupons/', coupons));
            commit(types.SET_COUPONS, responseData.coupons);
        } catch (error) {
        }
    },

    async multiCouponDelete({commit}, coupons: typeof Array<Coupon['uuid']>) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COUPON_LOADING', 'save_coupon', () => axios.post('/v1/coupons/delete', coupons));
            commit(types.SET_COUPONS, responseData.coupons);
        } catch (error) {
        }
    },

    /**
     * Update a coupon
     */
    async updateCoupon({commit}, coupon: Coupon) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COUPON_LOADING', 'update_coupon', () => axios.put(`/v1/coupons/${coupon.uuid}`, coupon));
            commit(types.UPDATE_COUPON, responseData?.coupon);

            push.success({
                message: 'Coupon updated successfully',
            });
        } catch (error) {
            commit(types.SET_COUPON_FORM_ERROR, error.response.data?.errors || 'There was an error');
            throw error;
        }
    },

    async deleteCoupon({commit}, couponUuid: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COUPON_LOADING', 'delete_coupon', () => axios.delete(`/v1/coupons/${couponUuid}`));
            commit(types.SET_COUPONS, responseData.coupons);

            push.success({
                message: 'Coupon deleted successfully',
            });
        } catch (error) {
            commit(types.SET_COUPON_FORM_ERROR, error.response.data?.errors || 'There was an error');
        }
    },

    /**
     * Check a coupons redemptions
     */
    async redemptions({commit}, coupon: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COUPON_LOADING', 'redemptions', () => axios.get(`/v1/coupons/${coupon}/redemptions`));
            commit(types.SET_COUPON_REDEMPTIONS, responseData.orders);
        } catch (error) {
            commit(types.SET_COUPON_FORM_ERROR, error.response.data?.errors || 'There was an error');
        }
    },
};

const couponModule: Module<CouponState, RootState> = {
    namespaced: true,

    state,

    getters: {
        loading: (state: CouponState) => state.loading,
        coupons: (state: CouponState) => state.coupons,
        form: (state: CouponState) => state.couponForm,
        redemptions: (state: CouponState) => state.redemptions,
    },

    mutations,

    actions,
}

export default couponModule;
