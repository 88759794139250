import * as types from '../mutation-types';
import axios from 'axios';
import { createUrlQueryString } from '@/utils/urls';
import { performApiRequest } from "@/store/helpers/vuex-helpers";


type FilePayload = {
    objectType: string,
    objectId: string,
    page?: number,
}

type File = {
  created_at: string,
  disk: string,
  extension: string,
  hash: string,
  mime_type: string,
  name: string,
  object_id: string,
  object_type: string,
  original_name: string,
  path: string,
  size: string,
  updated_at: string,
  uuid: string,
};

type Pagination = {
  current_page: number,
  first_page_url: string,
  from: number,
  last_page: number,
  last_page_url: string,
  next_page_url: string,
  path: string,
  per_page: number,
  prev_page_url: string,
  to: number,
  total: number,
}

export interface FilesState {
  loading: Record<string, boolean>,
  files: File[],
  pagination: Pagination | null,
}

// state
const state: FilesState = {
  loading: {
    getFiles: false,
    deleteFiles: false,
  },
  files: [],
  pagination: null,
};

// getters
const getters = {
  loading: (state: FilesState) => state.loading,
  files: (state: FilesState) => state.files,
  pagination: (state: FilesState) => state.pagination,
};

// mutations
const mutations = {
  [types.SET_FILES_LOADING](state: FilesState, loading: { subject: string, loading: boolean }) {
    state.loading[loading.subject] = loading.loading;
  },
  [types.SET_FILES](state: FilesState, files: File[]) {
    state.files = files;
  },
  [types.SET_FILES_PAGINATION](state: FilesState, pagination) {
    // Cloning pagination object
    const newPagination = { ...pagination };
    delete newPagination.data;
    delete newPagination.links;

    state.pagination = newPagination;
  },

  [types.MERGE_FILES](state: FilesState, files: File[]): void {
    // merge unique files and order by created_at
    const uniqueFiles = new Set<string>();
    const mergedFiles = [...state.files, ...files].filter(file => {
      const isUnique = !uniqueFiles.has(file.uuid);
      uniqueFiles.add(file.uuid);
      return isUnique;
    });

    mergedFiles.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
    state.files = mergedFiles;
  },
};

// actions
const actions = {

  /**
   * Get company files
   */
  async getFiles({ commit }, payload: FilePayload) {
    try {
      const queryString = createUrlQueryString(payload);

      const responseData = await performApiRequest(commit, 'SET_FILES_LOADING','getFiles', () => axios.get(`/v1/company-files${queryString}`));
      commit('MERGE_FILES', responseData.data);
      commit('SET_FILES_PAGINATION', responseData);
    } catch (error) {
    }
  },

  /**
   * Delete a company file
   */
  async deleteFile({ commit }, uuid: string) {
    try {
      await performApiRequest(commit, 'SET_FILES_LOADING','deleteFiles', () => axios.delete(`/v1/company-files/${uuid}`));
      commit('SET_FILES', state.files.filter((file) => file.uuid !== uuid));
    } catch (error) {
    }
  },
};


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
