<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 my-5">
      <div class="px-4 md:px-0">
        <div class="setting-section-description">
          <h3 class="text-lg font-medium text-gray-900 dark:text-gray-100">Edit email template</h3>

          <div class="feature-description">
            Customize your email templates using dynamic variables to personalize your messages. Insert customer
            details, order information, and more using our
            <a class="text-blue-500" href="https://help.fleetwire.io/en/article/email-variables-1i0tn10/?bust=1649486067702" target="_blank">email
              variables</a>.
            Click the link to see the full list of available variables and how to use them.
            <div v-if="debugDrawerOpen">
              <v-btn size="small" @click="sendTestTemplateEmail(emailTemplate)">Send Test Email</v-btn>
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-1 md:col-span-2 px-4 md:px-0">
        <v-card :disabled="emailLoading.email_templates" class="mb-5" variant="elevated">
          <v-card-text>
            <v-form>
              <v-text-field
                v-if="emailTemplate"
                v-model="emailTemplate.subject"
                class="mb-4"
                label="Subject"
                variant="outlined"
              ></v-text-field>
              <div id="editorjs" class="rounded min-h-[200px]" style="border: #9b9b9b 1px solid;"></div>
            </v-form>
          </v-card-text>
          <v-card-actions class="flex flex-wrap gap-2 justify-end px-4 py-3">
            <v-btn
              v-if="!emailTemplate || !emailTemplate.default"
              class="order-1 md:order-none"
              color="red"
              size="small"
              variant="text"
              @click="resetEmailTemplateInit()"
            >Reset
            </v-btn>
            <v-spacer class="hidden md:block"/>
            <v-btn :to="{name: 'settings.account.email', hash: '#email'}" class="w-full md:w-auto" size="small" variant="text">
              Back
            </v-btn>
            <v-btn
              :loading="emailLoading.email_templates"
              class="w-full md:w-auto"
              color="primary"
              @click="updateEmailTemplateInit()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {nextTick, onBeforeUnmount, onMounted} from 'vue';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Paragraph from 'editorjs-paragraph-with-alignment';
import {useEmail} from '@/composables/useEmail';
import {useAppSettings} from '@/composables/useAppSettings';
import {useRoute, useRouter} from 'vue-router';


const route = useRoute();
const router = useRouter();
const {
  emailTemplate,
  emailLoading,
  getEmailTemplate,
  updateEmailTemplate,
  sendTestTemplateEmail,
  resetEmailTemplate
} = useEmail();

const {debugDrawerOpen} = useAppSettings();


let editorjs: EditorJS | null = null;

const resetEmailTemplateInit = () => {
  resetEmailTemplate(emailTemplate.value).then((template) => {
    if (template.uuid !== route.params.uuid) {
      router.replace({
        name: 'settings.account.email.email_template_single',
        params: {uuid: template.uuid}
      }).catch(() => {
      });
    }
  });
};

const updateEmailTemplateInit = () => {
  editorjs
    .save()
    .then((outputData) => {
      const localEmailTemplate = {...emailTemplate.value};

      localEmailTemplate.email_body_blocks = outputData;

      updateEmailTemplate(localEmailTemplate).then((template) => {
        console.dir('THEN updateEmailTemplate');
        console.dir(template);
        if (template.uuid !== route.params.uuid) {
          router.replace({
            name: 'settings.account.email.email_template_single',
            params: {uuid: template.uuid}
          }).catch(() => {
          });
        }
      });
    }).catch((error) => {
    console.log('Saving failed: ', error);
  });
};

onMounted(() => {
  getEmailTemplate(route.params.uuid as string).then(() => {
    nextTick(() => {
      console.dir(emailTemplate.value.email_body_blocks);
      if (editorjs && typeof editorjs.destroy === 'function') {
        editorjs.destroy();
      }

      editorjs = new EditorJS({
        autofocus: true,
        holder: 'editorjs',
        defaultBlock: 'paragraph',
        tools: {
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
          },
          header: Header,
          list: List,
        },
        data: emailTemplate.value.email_body_blocks,
        minHeight: 200,
      });
    });
  }).catch(() => {
    router.replace({name: 'settings.account.email', hash: '#email'}).catch(() => {
    });
  });
});

onBeforeUnmount(() => {
  if (editorjs && typeof editorjs.destroy === 'function') {
    editorjs.destroy();
  }
});

</script>

<style scoped>
@media (max-width: 768px) {
  :deep(.codex-editor) {
    padding: 0.5rem !important;
  }

  :deep(.ce-toolbar__plus) {
    left: -10px !important;
  }

  :deep(.ce-toolbar__actions) {
    right: -10px !important;
  }
}
</style>

