import axios from 'axios';
import {findIndex, get} from 'lodash';
import {EventBus} from '@/plugins/eventBus';
import {Form} from '@/plugins/FWForms/index';
import {StorageService} from '@/services/storage.service';
import * as types from '../mutation-types';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {Connection} from '@/types';
import {push} from 'notivue';


export interface TeslaConnection {
    active: boolean;
    connected: boolean;
    email: string;
    expires: string;
    loading: boolean;
    photo_url: string;
    session_cookie_valid: boolean;
    session_cookies_exist: boolean;
    vehicles: any[];
    uuid?: string;
}

export interface TeslaState {
    init: boolean;
    loading: Record<string, boolean>;
    credentials: {
        email: string;
        password: string;
    };
    connections: TeslaConnection[];
    vehicles: any[];
    currentWorkingTeslaVehicle: any;
    currentWorkingTeslaVehicleDrivers: any[];
    vehicleInvitations: any[];
    chargingHistory: any[];
    chargingHistoryPaginate: any;
    chargingInvoices: any[];
    invoices: any[];
    invoices_meta: any;
    invoices_pdf: string;
    currentWorkingTeslaVehicleAppAccess: any[];
    currentWorkingTeslaVehicleState: any;
    currentWorkingTeslaVehicleData: any;
    currentWorkingTeslaVehicleAsleep: boolean;
    form: Form<object>;
}

const state: TeslaState = {
    init: false,

    loading: {
        login: false,
        delete: false,
        toggle: false,
        refresh_invoice: false,
        invoices: false,
        invoicePDF: false,
        refreshVehicles: false,
        appAccess: false,
        vehicleState: false,
        vehicleData: false,
        wakeVehicle: false,
        chargingHistory: false,
        invitations: false,
    },

    credentials: {
        email: '',
        password: '',
    },

    connections: [],
    vehicles: [],
    currentWorkingTeslaVehicle: {},
    currentWorkingTeslaVehicleDrivers: [],
    vehicleInvitations: [],
    chargingHistory: [],
    chargingHistoryPaginate: null,
    chargingInvoices: [],
    invoices: [],
    invoices_meta: {},
    invoices_pdf: '',
    currentWorkingTeslaVehicleAppAccess: [],
    currentWorkingTeslaVehicleState: {},
    currentWorkingTeslaVehicleData: {},
    currentWorkingTeslaVehicleAsleep: false,
    form: new Form(),
};

const getters = {
    loading: (state: TeslaState) => state.loading,
    credentials: (state: TeslaState) => state.credentials,
    connections: (state: TeslaState) => state.connections,
    vehicles: (state: TeslaState) => state.vehicles,
    currentWorkingTeslaVehicle: (state: TeslaState) => state.currentWorkingTeslaVehicle,
    currentWorkingTeslaVehicleDrivers: (state: TeslaState) => state.currentWorkingTeslaVehicleDrivers,
    vehicleInvitations: (state: TeslaState) => state.vehicleInvitations,
    chargingHistory: (state: TeslaState) => state.chargingHistory,
    chargingHistoryPaginate: (state: TeslaState) => state.chargingHistoryPaginate,
    currentWorkingTeslaVehicleAppAccess: (state: TeslaState) => state.currentWorkingTeslaVehicleAppAccess,
    currentWorkingTeslaVehicleState: (state: TeslaState) => state.currentWorkingTeslaVehicleState,
    currentWorkingTeslaVehicleData: (state: TeslaState) => state.currentWorkingTeslaVehicleData,
    currentWorkingTeslaVehicleAsleep: (state: TeslaState) => state.currentWorkingTeslaVehicleAsleep,
    chargingInvoices: (state: TeslaState) => state.chargingInvoices,
    invoices: (state: TeslaState) => state.invoices,
    invoicesMeta: (state: TeslaState) => state.invoices_meta,
    invoicesPDF: (state: TeslaState) => state.invoices_pdf,
    form: (state: TeslaState) => state.form,
};

const mutations = {
    [types.SET_TESLA_INIT](state: TeslaState, init: boolean) {
        state.init = init;
    },
    [types.CLEAR_TESLA_FORM_ERRORS](state: TeslaState, form_field) {
        if (form_field === null || form_field === undefined) {
            state.form.errors.clear();
        } else {
            state.form.errors.clear(form_field);
        }
    },
    [types.SET_TESLA_LOADING](state: TeslaState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_TESLA_CREDENTIALS](state: TeslaState, init) {
        state.init = init;
    },
    [types.SET_TESLA_CONNECTIONS](state: TeslaState, connections) {
        state.connections = connections;
    },
    [types.UPDATE_TESLA_CONNECTION](state: TeslaState, tesla) {
        if (tesla) {
            const connIndex = findIndex(state.connections, (c) => tesla.uuid === c.uuid);
            if (connIndex >= 0) {
                state.connections[connIndex] = tesla;
                if (!get(tesla, 'active', false)) {
                    state.connections[connIndex].connected = false;
                }
            } else {
                state.connections.push(tesla);
            }
        }
    },
    [types.SET_TESLA_VEHICLES](state: TeslaState, vehicles) {
        state.vehicles = vehicles;
    },
    [types.SET_CURRENT_WORKING_TESLA_VEHICLE](state: TeslaState, vehicle) {
        state.currentWorkingTeslaVehicle = vehicle;
    },
    [types.SET_CURRENT_WORKING_TESLA_VEHICLE_DRIVERS](state: TeslaState, drivers) {
        state.currentWorkingTeslaVehicleDrivers = drivers;
    },
    [types.SET_CURRENT_WORKING_TESLA_VEHICLE_HISTORY](state: TeslaState, chargingHistory) {
        state.chargingHistory = chargingHistory;
    },
    [types.SET_CURRENT_WORKING_TESLA_VEHICLE_HISTORY_PAGINATE](state: TeslaState, paginate) {
        state.chargingHistoryPaginate = paginate;
    },
    [types.SET_TESLA_VEHICLE_INVITATIONS](state: TeslaState, invites) {
        console.dir('SET_TESLA_VEHICLE_INVITATIONS');
        console.dir(invites);
        state.vehicleInvitations = invites;
    },
    [types.SET_TESLA_CHARGING_INVOICES](state: TeslaState, invoices) {
        if (invoices && invoices.length > 0) {
            state.chargingInvoices = invoices;
        }
    },
    [types.SET_TESLA_INVOICES](state: TeslaState, invoices) {
        state.invoices = invoices;
    },
    [types.SET_TESLA_INVOICES_META](state: TeslaState, meta) {
        state.invoices_meta = meta;
    },
    [types.SET_TESLA_INVOICE_PDF](state: TeslaState, pdf) {
        state.invoices_pdf = pdf;
    },
    [types.SET_CURRENT_WORKING_TESLA_ASLEEP](state: TeslaState, asleep) {
        state.currentWorkingTeslaVehicleAsleep = asleep;
    },
    [types.SET_CURRENT_WORKING_TESLA_VEHICLE_APP_ACCESS](state: TeslaState, emails) {
        state.currentWorkingTeslaVehicleAppAccess = emails;
    },
    [types.SET_CURRENT_WORKING_TESLA_VEHICLE_STATE](state: TeslaState, vehicleState) {
        state.currentWorkingTeslaVehicleState = vehicleState;
    },
    [types.SET_CURRENT_WORKING_TESLA_VEHICLE_DATA](state: TeslaState, vehicleData) {
        state.currentWorkingTeslaVehicleData = vehicleData;
    },
};

const actions = {

    /**
     * Toggle active a Tesla account
     */
    toggleActive({commit}, tesla: TeslaConnection) {
        commit(types.SET_TESLA_LOADING, {subject: 'toggle', loading: true});

        axios.put(`/v1/connections/tesla/${tesla.uuid}/toggle`)
            .then((response) => {
                commit('UPDATE_TESLA_CONNECTION', get(response.data, 'tesla'));
                EventBus.$emit('tesla-toggle-success', get(response.data, 'tesla', []));
            }).catch(() => {
        }).finally(() => {
            commit(types.SET_TESLA_LOADING, {subject: 'toggle', loading: false});
        });
    },

    refreshToken({commit}, tesla: TeslaConnection) {
        commit(types.SET_TESLA_LOADING, {subject: 'toggle', loading: true});
        axios.post(`/v1/connections/tesla/${tesla.uuid}/refresh-token`)
            .then(() => {
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Tesla token refreshed successful!',
                });
                EventBus.$emit('tesla-refresh-token-success');
            }).catch(() => {
        }).finally(() => {
            commit(types.SET_TESLA_LOADING, {subject: 'toggle', loading: false});
        });
    },

    deleteConnection({commit}, tesla: TeslaConnection) {
        commit(types.SET_TESLA_LOADING, {subject: 'delete', loading: true});
        axios.delete(`/v1/connections/tesla/${tesla.uuid}`)
            .then(() => {
                EventBus.$emit('delete-tesla-connection-success');
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Tesla connection removed.',
                });
            }).catch(() => {
        }).finally(() => {
            commit(types.SET_TESLA_LOADING, {subject: 'delete', loading: false});
        });
    },

    fetchUserInfo({commit}, tesla: TeslaConnection) {
        commit(types.SET_TESLA_LOADING, {subject: 'delete', loading: true});
        axios.get(`/v1/connections/tesla/${tesla.uuid}/user-info`)
            .then(() => {
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Tesla connection success.',
                });
            }).catch(() => {
        }).finally(() => {
            commit(types.SET_TESLA_LOADING, {subject: 'delete', loading: false});
        });
    },

    getVehicles({state, commit}, tesla: TeslaConnection) {
        commit(types.SET_TESLA_LOADING, {subject: 'login', loading: true});

        const connIndex = state.connections.findIndex((c: Connection) => tesla.uuid === c.uuid);
        state.connections[connIndex].loading = true;
        state.connections[connIndex].connected = false;

        axios.get(`/v1/connections/tesla/${tesla.uuid}/vehicles`)
            .then((response) => {
                commit('SET_TESLA_CONNECTIONS', get(response, 'data.vehicles'), []);
                state.connections[connIndex].connected = true;
            }).catch(() => {
        }).finally(() => {
            commit(types.SET_TESLA_LOADING, {subject: 'login', loading: false});
            state.connections[connIndex].loading = false;
        });
    },

    getVehicle({commit}, uuid: string) {
        commit(types.SET_TESLA_LOADING, {subject: 'login', loading: true});

        axios.get(`/v1/connections/tesla/vehicle/${uuid}`)
            .then((response) => {
                commit('SET_CURRENT_WORKING_TESLA_VEHICLE', get(response, 'data.vehicle'), []);
            }).catch(() => {
        }).finally(() => {
            commit(types.SET_TESLA_LOADING, {subject: 'login', loading: false});
        });
    },

    fetchVehicleDrivers({commit}, uuid: string) {
        commit(types.SET_TESLA_LOADING, {subject: 'login', loading: true});

        axios.get(`/v1/connections/tesla/vehicle/${uuid}/drivers`)
            .then((response) => {
                commit('SET_CURRENT_WORKING_TESLA_VEHICLE_DRIVERS', get(response, 'data.drivers'), []);
            }).catch(() => {
        }).finally(() => {
            commit(types.SET_TESLA_LOADING, {subject: 'login', loading: false});
        });
    },

    createVehicleInvitation({commit}, uuid: string) {
        commit(types.SET_TESLA_LOADING, {subject: 'login', loading: true});

        axios.post(`/v1/connections/tesla/vehicle/${uuid}/invitations`)
            .then((response) => {
                EventBus.$emit('create-tesla-vehicle-invitation-success', get(response, 'data.invitation'));
            }).catch(() => {
        }).finally(() => {
            commit(types.SET_TESLA_LOADING, {subject: 'login', loading: false});
        });
    },

    async fetchVehicleChargingHistory({commit}, data) {
        try {
            const responseData = await performApiRequest(commit, 'SET_TESLA_LOADING', 'chargingHistory', () => axios.get(`/v1/connections/tesla/vehicle/${data.tesla_uuid}/charging-history?page=${data.page}`));

            commit('SET_CURRENT_WORKING_TESLA_VEHICLE_HISTORY_PAGINATE', responseData.paginate, []);
            commit('SET_CURRENT_WORKING_TESLA_VEHICLE_HISTORY', responseData.data, []);
        } catch (error) {
        }
    },
    fetchTeslaInvoice({commit}, data) {
        commit(types.SET_TESLA_LOADING, {subject: 'login', loading: true});
        const ids = data.invoiceIds.join(',');

        axios.get(`/v1/connections/tesla/vehicle/${data.tesla_uuid}/charging-history-invoice?contentId=${ids}`)
            .then((response) => {
                EventBus.$emit('fetch-tesla-charging-invoice-success', response.data);
            }).catch(() => {
        }).finally(() => {
            commit(types.SET_TESLA_LOADING, {subject: 'login', loading: false});
        });
    },
    async fetchVehicleInvitations({commit}, uuid: string) {
        try {
            const responseData = await performApiRequest(commit, 'SET_TESLA_LOADING', 'invitations', () => axios.get(`/v1/connections/tesla/vehicle/${uuid}/invitations`));

            commit('SET_TESLA_VEHICLE_INVITATIONS', responseData.invitations, []);
        } catch (error) {
        }
    },

    /**
     * Cancel vehicle invitation
     *
     */
    async cancelVehicleInvitation({commit}, data: { tesla_uuid: string, invite_id: string }) {
        try {
            await performApiRequest(commit, 'SET_TESLA_LOADING', 'invitations', () => axios.delete(`/v1/connections/tesla/vehicle/${data.tesla_uuid}/invitations/${data.invite_id}`));
        } catch (error) {
        }
    },

    refreshVehicles({state, commit}, tesla: TeslaConnection) {
        commit(types.SET_TESLA_LOADING, {subject: 'refreshVehicles', loading: true});

        const connIndex = state.connections.findIndex((c: Connection) => tesla.uuid === c.uuid);
        state.connections[connIndex].loading = true;
        state.connections[connIndex].connected = false;

        axios.post(`/v1/connections/tesla/${tesla.uuid}/refresh-vehicles`)
            .then((response) => {
                commit('SET_TESLA_CONNECTIONS', get(response, 'data.vehicles'), []);
                state.connections[connIndex].connected = true;
            }).catch(() => {
        }).finally(() => {
            commit(types.SET_TESLA_LOADING, {subject: 'refreshVehicles', loading: false});
            state.connections[connIndex].loading = false;
        });
    },

    wakeVehicle({commit}, tesla: TeslaConnection) {
        commit(types.SET_TESLA_LOADING, {subject: 'wakeVehicle', loading: true});
        commit('SET_CURRENT_WORKING_TESLA_VEHICLE', tesla);

        axios.post(`/v1/connections/tesla/vehicles/${tesla.uuid}/wake`)
            .then((response) => {
                if (get(response, 'data.wake.state') === 'asleep') {
                    EventBus.$emit('waking-tesla', get(response, 'data.wake'));
                    EventBus.$emit('SystemMessage', {
                        type: 'success',
                        message: 'Tesla is waking. It may take a few seconds.',
                    });
                } else {
                    commit('SET_CURRENT_WORKING_TESLA_ASLEEP', false);
                    EventBus.$emit('woke-tesla', tesla);
                }
            }).catch((e) => {
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit(types.SET_TESLA_LOADING, {subject: 'wakeVehicle', loading: false});
        });
    },

    getVehicleState({commit}, tesla: TeslaConnection) {
        commit(types.SET_TESLA_LOADING, {subject: 'vehicleState', loading: true});
        axios.get(`/v1/connections/tesla/vehicles/${tesla.uuid}/state`)
            .then((response) => {
                commit('SET_CURRENT_WORKING_TESLA_VEHICLE_STATE', get(response, 'data.state'), []);
            }).catch((e) => {
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit(types.SET_TESLA_LOADING, {subject: 'vehicleState', loading: false});
        });
    },

    getVehicleData({commit}, tesla: TeslaConnection) {
        commit(types.SET_TESLA_LOADING, {subject: 'vehicleData', loading: true});

        axios.get(`/v1/connections/tesla/vehicles/${tesla.uuid}/data`)
            .then((response) => {
                EventBus.$emit('get-tesla-vehicle-data-success', get(response, 'data.data'));
                commit('SET_CURRENT_WORKING_TESLA_ASLEEP', false);
                commit('SET_CURRENT_WORKING_TESLA_VEHICLE_DATA', get(response, 'data.data'), []);
            }).catch((e) => {
            console.dir(e);
            if (e.response.status === 406) {
                EventBus.$emit('get-tesla-vehicle-data-asleep', tesla);
                commit('SET_CURRENT_WORKING_TESLA_ASLEEP', true);
            }
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit(types.SET_TESLA_LOADING, {subject: 'vehicleData', loading: false});
        });
    },

    checkInvoices({commit}, tesla: TeslaConnection) {
        commit(types.SET_TESLA_LOADING, {subject: 'refresh_invoice', loading: true});

        axios.post(`/v1/company/${StorageService.getCompanyUuid()}/connections/tesla/${tesla.uuid}/payment-history`)
            .then((response) => {
                commit('SET_TESLA_INVOICES', get(response, 'data.invoices'));
                commit('SET_TESLA_INVOICES_META', get(response, 'data.invoices_meta'));
            }).catch((e) => {
            console.dir(e);
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit(types.SET_TESLA_LOADING, {subject: 'refresh_invoice', loading: false});
        });
    },

    chargingHistory({commit}, tesla: TeslaConnection) {
        commit(types.SET_TESLA_LOADING, {subject: 'refresh_invoice', loading: true});

        axios.post(`/v1/connections/tesla/${tesla.uuid}/charging-history`)
            .then(() => {
            }).catch((e) => {
            console.dir(e);
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit(types.SET_TESLA_LOADING, {subject: 'refresh_invoice', loading: false});
        });
    },

    getRnIds({commit}, tesla: TeslaConnection) {
        commit(types.SET_TESLA_LOADING, {subject: 'refresh_invoice', loading: true});

        axios.get(`/v1/company/${StorageService.getCompanyUuid()}/connections/tesla/${tesla.uuid}/rd-ids`)
            .then(() => {
            }).catch((e) => {
            console.dir(e);
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit(types.SET_TESLA_LOADING, {subject: 'refresh_invoice', loading: false});
        });
    },


    getChargingInvoiceHistory({commit}, tesla: TeslaConnection) {
        commit(types.SET_TESLA_LOADING, {subject: 'invoices', loading: true});

        axios.get(`/v1/connections/tesla/${tesla.uuid}/charging-invoice-history`)
            .then((response) => {
                commit('SET_TESLA_CHARGING_INVOICES', get(response, 'data.charging'));
            }).catch((e) => {
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit(types.SET_TESLA_LOADING, {subject: 'invoices', loading: false});
        });
    },

    getInvoices({commit}, tesla: TeslaConnection) {
        commit(types.SET_TESLA_LOADING, {subject: 'invoices', loading: true});

        axios.get(`/v1/company/${StorageService.getCompanyUuid()}/connections/tesla/${tesla.uuid}/payment-history`)
            .then((response) => {
                commit('SET_TESLA_INVOICES', get(response, 'data.invoices'));
                commit('SET_TESLA_INVOICES_META', get(response, 'data.invoices_meta'));
            }).catch((e) => {
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit(types.SET_TESLA_LOADING, {subject: 'invoices', loading: false});
        });
    },


    /**
     * Get invoice pdfs
     */
    getInvoicePDF({commit}, tesla) {
        commit(types.SET_TESLA_LOADING, {subject: 'invoicePDF', loading: true});
        axios.get(`/v1/company/${StorageService.getCompanyUuid()}/connections/tesla/${tesla.tesla_uuid}/payment-history/${tesla.chargeSessionId}/invoice-pdf`)
            .then((response) => {
                commit('SET_TESLA_INVOICE_PDF', get(response, 'data.pdf'));
            }).catch((e) => {
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit(types.SET_TESLA_LOADING, {subject: 'invoicePDF', loading: false});
        });
    },

    importTeslaVehiclesAsListings({commit}, {vehicle_uuids, tesla_uuid}) {
        commit(types.SET_TESLA_LOADING, {subject: 'invoicePDF', loading: true});

        axios.post(`/v1/company/${StorageService.getCompanyUuid()}/connections/tesla/${tesla_uuid}/import`, {vehicle_uuids})
            .then((response) => {
                commit('listing/SET_ALL_LISTINGS', get(response, 'data.listings'), {root: true});
                EventBus.$emit('SystemMessage', {
                    type: 'success',
                    message: 'Tesla imported as listing successfully',
                });
                EventBus.$emit('import-tesla-vehicles-Success');
            }).catch((e) => {
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }).finally(() => {
            commit(types.SET_TESLA_LOADING, {subject: 'invoicePDF', loading: false});
        });
    },

};


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
