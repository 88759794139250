<template>
  <section class="bg-gray-50 dark:bg-gray-900">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white" href="/">
        <img alt="logo" class="w-8 h-8 mr-2" src="https://tenant.fleetwire.io/images/logo/fleetwire-logo-new.png">
        Fleetwire
      </a>
      <div v-show="!formSubmitSuccess" class="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
        <h1 class="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
          {{ $t('reset_password') }}
        </h1>
        <p class="font-light text-gray-500 dark:text-gray-400">{{ $t('reset_password_p') }}</p>
        <v-form :disabled="form.busy" class="mt-4 space-y-4 lg:mt-5 md:space-y-5" @keydown="form.onKeydown($event)" @submit.prevent="reset">
          <div>
            <v-text-field
              v-model="form.password"
              :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :class="{ 'is-invalid': form.errors.has('password') }"
              :rules="[registerFormRules.required, registerFormRules.min]"
              :type="show1 ? 'text' : 'password'"
              hint=""
              label="New Password"
              minlength="8"
              variant="outlined"
              @click:append-inner="show1 = !show1"
            />
          </div>
          <v-btn :disabled="submitButtonDisabled" :loading="form.busy" block size="large" type="submit">
            {{ $t('reset') }}
          </v-btn>

          <div>
            <v-alert v-if="errorMessage.length > 0" density="compact" role="alert" type="error" variant="outlined">
              {{ errorMessage }}
            </v-alert>
          </div>
        </v-form>

        <p class="mt-10 text-sm font-light text-gray-500 dark:text-gray-400">
          Remember Now?
          <router-link :to="{ name: 'login', query: { email: form.email } }" class="font-medium text-primary-600 hover:underline dark:text-primary-500" data-testid=register-link>
            <b>{{ $t('login') }}</b>
          </router-link>
        </p>
      </div>

      <div v-show="formSubmitSuccess" class="mt-5">
        <v-alert density="compact" role="alert" type="success" variant="outlined">
          Password reset successfully. Redirecting to your dashboard...
        </v-alert>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import Form from 'vform';
import {useStore} from 'vuex';
import {computed, onBeforeMount, onBeforeUnmount, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';

const route = useRoute();
const router = useRouter();
const submitButtonDisabled = ref(false);
const errorMessage = ref('');
const show1 = ref(false);
const {dispatch, getters} = useStore();
const formSubmitSuccess = ref(false);

const registerFormRules = computed(() => getters['company/registerFormRules']);

const form = ref(new Form({
  token: '',
  email: '',
  password: '',
  password_confirmation: '',
}));

const saveToken = async (payload) => {
  await dispatch('auth/saveToken', payload);
};


const reset = async () => {
  if (!form.value.password) {
    errorMessage.value = 'Please enter your password.';
    return;
  }

  // wait 1 second and set formSubmitSuccess for testing
  // setTimeout(() => {
  //   formSubmitSuccess.value = true;
  // }, 1000);

  try {
    form.value.password_confirmation = form.value.password;

    const {data} = await form.value.post('/v1/password/reset');

    formSubmitSuccess.value = true;

    // Save the token.
    await saveToken({
      token: data.token,
      remember: true,
    });

    // Fetch the user.
    await dispatch('auth/fetchUser');

    // Redirect home.
    router.push({name: 'dashboard'}).catch(() => {
    });
  } catch (e) {
    console.dir(e);
  }
};


onBeforeMount(() => {
  form.value.email = route.query.email;
  form.value.token = route.params.token;
});

onBeforeUnmount(() => {
  form.value.reset();
});
</script>
