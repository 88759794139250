<template>
  <div>
    <TheTaxes/>
  </div>
</template>

<script lang="ts" setup>
import {defineAsyncComponent, onMounted} from 'vue';
import {useTaxAndFee} from '@/composables/useTaxAndFee';

const TheTaxes = defineAsyncComponent(() => import('@/components/dashboard/settings/pricing/Taxes.vue'));

const {getTaxCategories} = useTaxAndFee();

onMounted(() => {
  getTaxCategories();
});
</script>
