<template>
  <div>
    <v-alert
      class="mb-5"
      color="info"
      density="compact"
      icon="mdi-fire"
      variant="outlined"
    >
      <div class="flex">
        <div class="grow col">
          Coming soon - This is an experimental feature and is in active development & in beta.
        </div>
        <div class="shrink col">
          <v-btn v-if="!hasWaitlistRequest('CommissionedPartners')" size="small" @click="addFeatureWaitlist({feature: 'CommissionedPartners'})">
            Notify Me
          </v-btn>
          <v-btn v-else disabled size="small">Will notify</v-btn>
        </div>
      </div>
    </v-alert>

    <v-card class="mb-5">
      <div class="beta-ribbon ribbon-top-left">
        <span>Beta</span>
      </div>
      <v-card-text>
        <v-switch
          v-model="showOnMainMenu"
          density="compact"
          hint="Show the commission icon/link on the main menu"
          inset
          label="Show on main menu"
          persistent-hint
          @update:model-value="toggleShowCommissionPartnersOnMainMenu(showOnMainMenu)"
        ></v-switch>
      </v-card-text>
    </v-card>
    <v-card>
      <div class="beta-ribbon ribbon-top-left">
        <span>Beta</span>
      </div>
      <v-card-title>
        <div class="flex">
          <FeatureTitle
            featurePremium
            title="Commission Partners"
          />
          <v-btn class="ml-2" variant="text" @click="openDialog()">
            <v-icon size="small">mdi-help-circle</v-icon>
          </v-btn>
          <v-spacer/>
          <v-btn variant="text" @click="viewCommissionPartnerDialog = true;">
            <v-icon>mdi-plus</v-icon>
            Add Partner
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="mt-5">
          <v-data-table
            v-if="commissionPartners.length > 0"
            :headers="headers"
            :items="commissionPartners"
            :items-per-page="10"
          >
            <template v-slot:item.name="{ item }">
              <router-link :to="{ name: 'commission.partner.single', params: { partner_uuid: item.uuid } }" class="text-dark ml-1">
                {{ item.name }}
              </router-link>
            </template>
            <template v-slot:item.commission_type="{ item }">
              <span v-if="item.commission_type === 'percentage'">Percentage</span>
              <span v-if="item.commission_type === 'amount'">Amount</span>
            </template>
            <template v-slot:item.commission_amount="{ item }">
              <span v-if="item.commission_type === 'percentage'">{{ item.commission_amount }} %</span>
              <span v-if="item.commission_type === 'amount'">{{ formatMoneyDisplayV2(item.commission_amount) }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                class="mr-2"
                size="small"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon size="small" @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
          <div v-else>No commission partners</div>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="viewCommissionPartnerDialog" width="400">
      <v-card :disabled="partnersLoading.create">
        <v-card-title>New commission partner</v-card-title>
        <v-divider opacity="50"/>
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="commissionPartner.name"
              label="Name"
              name="name"
              variant="outlined"
            ></v-text-field>
            <v-text-field
              v-model="commissionPartner.phone"
              label="Phone"
              name="phone"
              variant="outlined"
            ></v-text-field>
            <v-text-field
              v-model="commissionPartner.email"
              label="Email"
              variant="outlined"
            ></v-text-field>
            <div>
              <div>
                <v-text-field
                  v-model="commissionAmount"
                  :append-icon="commissionPartner.commission_type === 'percentage' ? 'mdi-percent':''"
                  :label="commissionPartner.commission_type === 'percentage' ? 'Percentage':'Amount'"
                  :prefix="commissionPartner.commission_type === 'amount' ? companyMoneyTextInputSettings.prefix: ''"
                  :suffix="commissionPartner.commission_type === 'amount' ? companyMoneyTextInputSettings.suffix: ''"
                  type="number"
                  variant="outlined"
                ></v-text-field>
              </div>
              <div>
                <v-radio-group v-model="commissionPartner.commission_type" class="mt-0" density="compact" hide-details @update:model-value="updateAmountFormat()">
                  <v-radio label="Percentage" value="percentage"></v-radio>
                  <v-radio label="Amount" value="amount"></v-radio>
                </v-radio-group>
              </div>
            </div>

          </v-form>
        </v-card-text>
        <v-divider opacity="50"/>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="grey" size="small" variant="text" @click="viewCommissionPartnerDialog = false">
            Nevermind
          </v-btn>
          <v-btn v-if="commissionPartner.mode === 'edit'" :loading="partnersLoading.update" color="primary" @click="updateCommissionPartner(commissionPartner)">
            Update
          </v-btn>
          <v-btn v-else :loading="partnersLoading.create" color="primary" @click="createCommissionPartner(commissionPartner)">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteCommissionPartnerDialog" max-width="350">
      <v-card :disabled="partnersLoading.delete">
        <v-card-title class="text-h5">Delete Commission Partner</v-card-title>
        <v-divider opacity="50"/>
        <v-card-text>
          Are you sure you want to delete this commission partner? <br>
          <strong>{{ commissionPartner.name }}</strong>
        </v-card-text>
        <v-divider opacity="50"/>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="grey" size="small" variant="text" @click="deleteCommissionPartnerDialog = false">
            Nevermind
          </v-btn>
          <v-btn :loading="partnersLoading.delete" color="red text-white" @click="deleteCommissionPartner(commissionPartner)">
            I'm Sure
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <WhatAreCommissionsDialog
      :visible="isDialogVisible"
      @update:visible="isDialogVisible = $event"
    />
  </div>
</template>

<script lang="ts" setup>
import {computed, defineAsyncComponent, onBeforeMount, ref, watch} from 'vue';
import {useStore} from 'vuex';
import getDefaultMeta from '@/utils/meta';
import {useMoneyFormatter} from '@/composables/useMoneyFormatter';
import {useAppFeatures} from '@/composables/useAppFeatures';
import {useAuth} from '@/composables/useAuth';
import {useCompany} from '@/composables/useCompany';
import type {CommissionPartner} from '@/store/modules/commission-partners';

const FeatureTitle = defineAsyncComponent(() => import('@/components/dashboard/FeatureTitle.vue'));
const WhatAreCommissionsDialog = defineAsyncComponent(() => import('@/components/dashboard/dialogs/WhatAreCommissionsDialog.vue'));

const store = useStore();
const {hasWaitlistRequest, addFeatureWaitlist} = useAppFeatures();
const {formatMoneyDisplayV2, companyMoneyTextInputSettings} = useMoneyFormatter();
const {fetchSession} = useAuth();
const {defaultMeta} = useCompany()

const viewCommissionPartnerDialog = ref(false);
const deleteCommissionPartnerDialog = ref(false);
const editedIndex = ref(null);
const showOnMainMenu = ref(false);
const commissionPartner = ref<CommissionPartner>({
  active: true,
  name: '',
  phone: '',
  email: '',
  commission_amount: 0,
  commission_type: 'percentage',
  mode: 'create',
});

const headers = [
  {title: 'Name', key: 'name'},
  {title: 'Phone', key: 'phone'},
  {title: 'Email', key: 'email'},
  {title: 'Type', key: 'commission_type'},
  {title: 'Amount', key: 'commission_amount'},
  {title: 'Actions', key: 'actions', sortable: false},
];

const commissionPartners = computed<CommissionPartner[]>(() => store.getters['commissionPartners/commissionPartners']);
const partnersLoading = computed(() => store.getters['commissionPartners/loading']);

const toggleShowCommissionPartnersOnMainMenu = (showOnMainMenu: boolean) => {
  store.dispatch('commissionPartners/toggleShowOnMainMenu', showOnMainMenu).then(() => {
    fetchSession();
  });
};
const getFeatureWaitlist = () => {
  store.dispatch('app/getFeatureWaitlist', {feature: 'CommissionedPartners'});
};

const getCommissionPartners = () => {
  store.dispatch('commissionPartners/getCommissionPartners');
};
const updateCommissionPartner = (commissionPartner: CommissionPartner) => {
  store.dispatch('commissionPartners/updateCommissionPartner', commissionPartner).then(() => {
    getCommissionPartners();
    viewCommissionPartnerDialog.value = false;
    resetCommissionPartner();
  });
};
const deleteCommissionPartner = (commissionPartner: CommissionPartner) => {
  store.dispatch('commissionPartners/deleteCommissionPartner', commissionPartner).then(() => {
    getCommissionPartners();
    deleteCommissionPartnerDialog.value = false;
    resetCommissionPartner();
  });
};

const createCommissionPartner = (commissionPartner: CommissionPartner) => {
  store.dispatch('commissionPartners/createCommissionPartner', commissionPartner).then(() => {
    getCommissionPartners();
    viewCommissionPartnerDialog.value = false;
    resetCommissionPartner();
  });
};

const resetCommissionPartner = () => {
  commissionPartner.value = {
    active: true,
    name: '',
    phone: '',
    email: '',
    commission_amount: 0,
    commission_type: 'percentage',
    mode: 'create',
  };
};


const isDialogVisible = ref(false);

const openDialog = () => {
  isDialogVisible.value = true;
};

const updateAmountFormat = () => {
  if (commissionPartner.value.commission_type === 'amount') {
    commissionPartner.value.commission_amount *= 100;
  } else if (commissionPartner.value.commission_type === 'percentage') {
    commissionPartner.value.commission_amount /= 100;
  }
};

const editItem = (item: CommissionPartner) => {
  editedIndex.value = store.getters['commissionPartners/commissionPartners'].indexOf(item);
  commissionPartner.value = Object.assign({}, item);
  commissionPartner.value.mode = 'edit';
  viewCommissionPartnerDialog.value = true;
};

const deleteItem = (item: CommissionPartner) => {
  editedIndex.value = store.getters['commissionPartners/commissionPartners'].indexOf(item);
  commissionPartner.value = Object.assign({}, item);
  deleteCommissionPartnerDialog.value = true;
};

const commissionAmount = computed({
  get() {
    const amount = +commissionPartner.value.commission_amount;
    if (amount === 0) return 0;
    if (commissionPartner.value.commission_type === 'amount') {
      return amount * 0.01;
    }
    return amount;
  },
  set(amount: number) {
    if (commissionPartner.value.commission_type === 'amount') {
      commissionPartner.value.commission_amount = amount * 100;
    } else {
      commissionPartner.value.commission_amount = amount;
    }
  },
});

watch(defaultMeta, () => {
  const showOnMainMenuData = getDefaultMeta('show_commission_main_menu');
  showOnMainMenu.value = showOnMainMenuData === 1 || showOnMainMenuData === '1';
});

onBeforeMount(() => {
  getFeatureWaitlist();

  const showOnMainMenuData = getDefaultMeta('show_commission_main_menu');

  showOnMainMenu.value = showOnMainMenuData === 1 || showOnMainMenuData === '1';
  getCommissionPartners();
});
</script>
