<template>
  <div>
    <v-card class="mobile-friendly-card" max-width="100%">
      <v-img alt="Stripe Payment Provider Logo" max-height="75" src="/images/logo/Stripelogo.png"/>
      <v-card-text v-if="!hasStripe">
        <a class="stripe-connect slate" @click="StripeOauth">
          <span class="pr-5">Connect with</span>
        </a>
      </v-card-text>

      <v-card-text v-if="hasStripe">
        <div class="flex flex-column flex-sm-row items-center border rounded p-3">
          <div class="flex-shrink-0 mb-2 mb-sm-0">
            <img :src="`https://ui-avatars.com/api/?name=ST`" alt="Avatar" class="w-8 h-8 rounded-full">
          </div>
          <div class="flex-1 min-w-0 ms-0 ms-sm-4 text-center text-sm-start">
            <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
              {{ stripeProvider.uid }}
            </p>
            <p class="text-sm text-gray-500 truncate dark:text-gray-400">
              <strong>Connected: </strong>{{ formatRentalDate(stripeCreatedAt) }}
            </p>
          </div>
          <div class="mt-2 mt-sm-0">
            <v-menu class="flex-row2">
              <template v-slot:activator="{ props }">
                <v-btn size="small" v-bind="props" variant="text">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="stripeAccountDialog = true">
                  <v-list-item-title>
                    Account Data
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="removeStripeConfirm = true">
                  <v-list-item-title>
                    Disconnect
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-card-text>

      <v-progress-linear
        :active="authLoading.stripe_oauth"
        class="mb-0"
        indeterminate
      ></v-progress-linear>
    </v-card>

    <v-dialog v-model="removeStripeConfirm" max-width="350">
      <v-card>
        <v-card-title class="text-h5">Delete Stripe Connection</v-card-title>
        <v-card-text>
          Are you sure you want to delete your Stripe connection?
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="grey" size="small" variant="text" @click="removeStripeConfirm = false">
            Nevermind
          </v-btn>
          <v-btn color="primary" @click="deletePaymentProfileCommit(stripeUuid)">
            I'm Sure
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="stripeAccountDialog" class="mobile-dialog" max-width="350">
      <v-card>
        <v-card-title class="text-h6 text-sm-h5">Stripe Account Data</v-card-title>
        <v-card-text>
          <div class="border-b">
            <div class="flex flex-column flex-sm-row py-2">
              <div class="flex-grow">Charges Enabled:</div>
              <div class="mt-1 mt-sm-0">
                <span v-if="stripeAccountChargesEnabled" class="badge-pill text-green-500 bg-green-50">Yes</span>
                <span v-else class="badge-pill text-red-500 bg-red-50">No</span>
              </div>
            </div>
            <div class="flex flex-column flex-sm-row py-2 border-b">
              <div class="flex-grow">Payouts Enabled:</div>
              <div class="mt-1 mt-sm-0">
                <span v-if="stripeAccountPayoutsEnabled" class="badge-pill text-green-500 bg-green-50">Yes</span>
                <span v-else class="badge-pill text-red-500 bg-red-50">No</span>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div class="font-extrabold">Capabilities</div>
            <div v-for="(item, i) in stripeAccountCapabilities" :key="i" class="py-2">
              <div class="flex flex-column flex-sm-row">
                <div class="flex-grow">
                  {{ i }}:
                </div>
                <div class="mt-1 mt-sm-0">
                  <span v-if="item === 'active'" class="badge-pill text-green-500 bg-green-50">Active</span>
                  <span v-else-if="item === 'inactive'" class="badge-pill text-red-500 bg-red-50">Inactive</span>
                  <span v-else-if="item === 'pending'" class="badge-pill text-yellow-500 bg-yellow-50">pending</span>
                  <span v-else class="">{{ item }}</span>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" @click="stripeAccountDialog = false">
            Okay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref, watch} from 'vue';
import {useDateFormatter} from '@/composables/useDate';
import {useAuth} from '@/composables/useAuth';
import {useProviders} from '@/composables/useProviders';
import {useStripe} from '@/composables/useStripe';
import {Provider} from '@/store/modules/providers';

const {formatRentalDate} = useDateFormatter();

const {authLoading, checkStripe, setAuthLoading} = useAuth();
const {
  paymentProviders,
  deletePaymentProfile,
  getPaymentProfiles
} = useProviders();
const {
  stripeAccounts,
  fetchStripeCapabilities
} = useStripe();

const StripeOauthWindow = ref(null);
const stripeInterval = ref(null);
const removeStripeConfirm = ref(false);
const stripeAccountDialog = ref(false);

const stripeProvider = computed(() => {
  return paymentProviders.value.find((provider: Provider) => provider.provider === 'stripe');
});
const hasStripe = computed(() => {
  return stripeProvider.value !== undefined;
});
const stripeEmail = computed(() => {
  return stripeProvider.value?.email;
});
const stripeUuid = computed(() => {
  return stripeProvider.value?.uuid;
});
const stripeCreatedAt = computed(() => {
  return stripeProvider.value?.created_at;
});
const stripeAccountCapabilities = computed(() => {
  return stripeAccounts.value?.capabilities;
});
const stripeAccountChargesEnabled = computed(() => {
  return stripeAccounts.value?.charges_enabled;
});
const stripeAccountPayoutsEnabled = computed(() => {
  return stripeAccounts.value?.payouts_enabled;
});

const deletePaymentProfileCommit = (uuid: string) => {

  deletePaymentProfile(uuid).then(() => {
    removeStripeConfirm.value = false;
    getPaymentProfiles();
  });
}

const StripeOauth = () => {
  setAuthLoading({
    subject: 'stripe_oauth',
    loading: true,
  });

  const url = window.location.href;

  let clientId = 'ca_Gf8zoSeAOjUHi6Op0cj8DdNG00QcPIaa';
  if (url.includes('fleetwire.test') || url.includes('localhost')) {
    clientId = 'ca_Gf8zkVN3YcOedDmpefmhsmIB21bpAk21';
  }

  StripeOauthWindow.value = window.open(
    `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write_and_identity_write`,
    'Fleetwire.io Stripe Oauth',
    'scrollbars,status,width=750,height=650',
  );

  stripeInterval.value = setInterval(() => {
    if (StripeOauthWindow.value.closed) {
      clearInterval(stripeInterval.value);
      setAuthLoading({
        subject: 'stripe_oauth',
        loading: false,
      });
    }
    checkStripe().then((results) => {
      console.dir(results);
      if (results) {
        getPaymentProfiles();
        StripeOauthWindow.value.close();
        clearInterval(stripeInterval.value);
      }
    });
  }, 2000);
};

watch(hasStripe, (val) => {
  if (val) {
    fetchStripeCapabilities();
  }
}, {immediate: true});
</script>

<style lang="scss" scoped>
.stripe-connect a {
  text-decoration: none;
}

.stripe-connect {
  background: #635bff;
  display: inline-block;
  height: 38px;
  text-decoration: none;
  width: 180px;
  max-width: 100%;
  min-width: 160px;

  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  -webkit-font-smoothing: antialiased;
}

.stripe-connect span {
  color: #ffffff;
  display: block;
  font-family: sohne-var, "Helvetica Neue", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  padding: 11px 0 0 24px;
  position: relative;
  text-align: left;
}

.stripe-connect:hover {
  background: #7a73ff;
}

.stripe-connect.slate {
  background: #0a2540;
}

.stripe-connect.slate:hover {
  background: #425466;
}

.stripe-connect.white {
  background: #ffffff;
}

.stripe-connect.white span {
  color: #0a2540;
}

.stripe-connect.white:hover {
  background: #f6f9fc;
}

.stripe-connect span::after {
  background-repeat: no-repeat;
  background-size: 49.58px;
  content: "";
  height: 20px;
  left: 65%;
  position: absolute;
  top: 29%;
  width: 50px;
}

/* Logos */
.stripe-connect span::after {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.4, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
}

.stripe-connect.white span::after {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%230A2540;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
}

.mobile-friendly-card {
  @media (max-width: 600px) {
    border-radius: 0;
  }
}

.mobile-dialog {
  @media (max-width: 600px) {
    margin: 0;
    height: 100%;
    max-height: 100%;
  }
}
</style>
