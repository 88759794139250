import {createRouter, createWebHistory, RouteLocationNormalized} from 'vue-router'

import {Store} from 'vuex';
import {store} from '@/store';
import {constantRoutes} from '@/router/routes';
import {RootState} from '@/types/RootState';
import companyStore from '@/middleware/companyStore';
import locale from '@/middleware/locale';
import checkAuth from '@/middleware/check-auth';
import auth from '@/middleware/auth';
import guest from '@/middleware/guest';

const globalMiddleware: Array<string | Function> = [];

const router = createRouter({
    history: createWebHistory(),
    routes: constantRoutes,
})


router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    console.log('%c       START BEFORE EACH IN ROUTER   \\/  ', 'color: black;background: yellow; padding: 2px;border-top: 1px solid red;');
    console.dir(to);

    if (store.state.app.update_available) {
        window.location.reload();
    }

    const context: { store: Store<RootState>; to: RouteLocationNormalized; from: RouteLocationNormalized } = {
        to,
        from,
        store
    };


    // ✅ Ensure checkAuth runs first
    await checkAuth(context).then(() => {
        console.log('%c checkAuth Completed ✅', 'color: green;');
    });

    // ✅ Extract middleware for the route
    const middleware = getMiddleware(to.matched);


    if (!middleware.length) {
        console.log('%c NO MIDDLEWARE ❌', 'color: red;');
        return; // No middleware, continue navigation
    }


    const middlewareNames = middleware.map((middleware) => {
        return typeof middleware === 'function' ? middleware.name : middleware
    });

    console.log('%c Middleware Found ✅', 'color: white;background: pink; padding: 2px; font-weight: bold; padding: 5px;');
    console.table(middlewareNames);

    // ✅ Ensure locale middleware always runs first
    await locale();

    // COMPANY STORE MIDDLEWARE
    if (middlewareNames.includes('companyStore')) {

        await companyStore(context);

    }


    // GUEST MIDDLEWARE
    if (middlewareNames.includes('guest')) {

        if (!(await guest(context))) {
            console.log('%c REDIRECT TO DASH', 'color: white;background: pink; padding: 2px; font-weight: bold; padding: 5px;');
            return {name: 'dashboard'}
        }
    }

    // AUTH MIDDLEWARE
    if (middlewareNames.includes('auth')) {
        console.dir('middlewareNames.includes(\'auth\')');

        const isAuth = await auth(context);

        if (
            !isAuth &&
            // ❗️ Avoid an infinite redirect
            to.name !== 'login'
        ) {
            // redirect the user to the login page
            return {name: 'login', query: {redirect: to.fullPath}}
        }
    }

    console.log('%c       END BEFORE EACH IN ROUTER   /\\  ', 'color: black;background: yellow; padding: 2px;border-bottom: 1px solid red');
});


export default router;


/**
 * Merge the global middleware with the component's middleware.
 */
function getMiddleware(matchedRoutes: RouteLocationNormalized['matched']): Array<string | Function> {
    const middleware: Array<string | Function> = [...globalMiddleware];

    matchedRoutes.forEach((route) => {
        if (route.meta?.middleware) {
            if (Array.isArray(route.meta.middleware)) {
                middleware.push(...route.meta.middleware as Array<string | Function>);
            } else {
                middleware.push(route.meta.middleware as string | Function);
            }
        }
    });

    // remove duplicates
    return [...new Set(middleware)];
}