import {useStore} from 'vuex';
import {computed} from 'vue';
import {useRouter} from 'vue-router';
import {useSubscription} from "@/composables/useSubscription";
import {usePayments} from "@/composables/usePayments";
import {useStripe} from "@/composables/useStripe";
import {useAuth} from "@/composables/useAuth";


export function useSubscriptionManager() {

    const router = useRouter();
    const store = useStore();
    const {switchPlan, subscription} = useSubscription();
    const {fetchStripeCheckoutUrl} = usePayments();
    const {fetchSession} = useAuth();
    const {stripePrices} = useStripe();

    const planSelectionName = computed(() => plansOrdered.value[planSelection.value].name);
    const planSelectionObject = computed(() => plansOrdered.value[planSelection.value]);
    const planSelectionNameUpper = computed(() => planSelectionName.value.charAt(0).toUpperCase() + planSelectionName.value.slice(1));
    const planSelectionPrice = computed(() => planPricing[updatedSubscription.value.interval][planSelectionName.value]);

    // const subscriptionStatus = subscription.value?.stripe_status;
    const subscriptionStatus = computed(() => subscription.value?.stripe_status);
    const subscriptionMaster = computed(() => store.getters['subscriptions/subscriptionMaster']);

    const subscriptionStep = computed({
        get: () => store.getters['subscriptions/subscriptionStep'],
        set: (value) => store.commit('subscriptions/SET_SUBSCRIPTION_STEP', value),
    });

    const planSelection = computed({
        get: () => store.getters['subscriptions/planSelection'],
        set: (value) => store.commit('subscriptions/SET_PLAN_SELECTION', value),
    });

    const updatedSubscription = computed({
        get: () => store.getters['subscriptions/updatedSubscription'],
        set: (value) => store.commit('subscriptions/SET_SUBSCRIPTION_PLAN_AND_INTERVAL', value),
    });

    const plansOrdered = computed(() =>
        stripePrices.value.sort((a, b) =>
            ['premium', 'standard', 'basic'].indexOf(a.name) -
            ['premium', 'standard', 'basic'].indexOf(b.name)
        )
    );

    const openStripeCheckoutUrl = () => {

        fetchStripeCheckoutUrl({
            price: stripePrices.value[planSelection.value].pricing[updatedSubscription.value.interval].price_id,
        }).then((url) => {
            console.dir('Stripe checkout URL fetched');

            window.open(url, '_blank');
        });
    };

    const switchPlanInit = async () => {
        await switchPlan(stripePrices.value[planSelection.value].pricing[updatedSubscription.value.interval].price_id).then(() => {
            // pause for 2 seconds
            setTimeout(() => {
                fetchSession().then(() => {
                    router.push({name: 'settings.account'}).catch();
                });
            }, 1000);
        });
    };

    const planPricing = {
        monthly: {
            basic: 34,
            standard: 89,
            premium: 279,
        },
        yearly: {
            basic: 24,
            standard: 69,
            premium: 239,
        },
    };

    return {
        subscriptionStatus,
        planSelection,
        updatedSubscription,
        plansOrdered,
        subscriptionStep,
        planPricing,
        planSelectionName,
        subscriptionMaster,
        planSelectionObject,
        planSelectionNameUpper,
        planSelectionPrice,
        openStripeCheckoutUrl,
        switchPlanInit,
    }
}