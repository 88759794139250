<template>
  <div>
    <Connections/>

    <v-divider class="my-10" opacity="50"/>

    <Integrations/>
  </div>
</template>

<script lang="ts" setup>
import {defineAsyncComponent} from 'vue';

const Integrations = defineAsyncComponent(() => import('@/components/dashboard/settings/providers/Integrations.vue'));
const Connections = defineAsyncComponent(() => import('@/components/dashboard/settings/providers/Connections.vue'));
</script>
