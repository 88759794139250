import type {RouteMeta, RouteRecordRaw} from 'vue-router';
import type {CommonRouteMeta} from '@/types';

const DashboardLayout = () => import('@/views/dashboard/BaseDashboard.vue');

/** Views */
const BaseListings = () => import('@/views/dashboard/listings/BaseListings.vue');
const NewListings = () => import('@/views/dashboard/listings/New.vue');
const SingleListing = () => import('@/views/dashboard/listings/ListingSingle.vue');
const ListingCalendar = () => import('@/views/dashboard/listings/FWCalendarVue.vue');
const ListingAnalytics = () => import('@/views/dashboard/analytics/BaseAnalytics.vue');

/** Nav Actions */
const AddNewListingNavAction = () => import('@/components/dashboard/navActions/AddNewListing.vue');
const SaveListingNavAction = () => import('@/components/dashboard/navActions/SaveCurrentListing.vue');
const AddListingSaveNavAction = () => import('@/components/dashboard/navActions/AddListingSave.vue');


// Extend the CommonRouteMeta interface
interface ExtendedRouteMeta extends RouteMeta, CommonRouteMeta {
    isBase?: boolean;
    permissions?: string[];
    resource?: string;
    showInMenu?: boolean;
    middleware?: string[];
    layout?: string;
}

const listingRoutes: RouteRecordRaw[] = [
    {
        path: '/listings',
        component: DashboardLayout,
        redirect: _to => {
            return {name: 'listing.index'};
        },
        meta: {
            title: 'listings',
            icon: 'mdi-car-convertible',
            noCache: false,
            isBase: true,
            permissions: ['listings_view'],
            showInMenu: true,
            middleware: ['auth'],
            layout: 'Dashboard',
        } as CommonRouteMeta,
        children: [
            {
                path: 'all',
                components: {
                    default: BaseListings,
                    navRightActions: AddNewListingNavAction,
                },
                name: 'listing.index',
                meta: {title: 'Listings', icon: 'mdi-car-convertible', noCache: false} as ExtendedRouteMeta,
            },
            {
                path: 'new',
                components: {
                    default: NewListings,
                    navRightActions: AddListingSaveNavAction,
                },
                name: 'listing.new',
                meta: {title: 'New Listing', icon: 'mdi-car-convertible', noCache: false} as ExtendedRouteMeta,
            },
            {
                path: 'calendar',
                components: {
                    default: ListingCalendar,
                },
                name: 'listing.calendar',
                meta: {
                    title: 'Single Listing',
                    icon: 'mdi-car-convertible',
                    noCache: false,
                    resource: 'l_id'
                } as ExtendedRouteMeta,
            },
            {
                path: ':l_id',
                components: {
                    default: SingleListing,
                    navRightActions: SaveListingNavAction,
                },
                name: 'listing.single',
                meta: {
                    title: 'Single Listing',
                    icon: 'mdi-car-convertible',
                    noCache: false,
                    resource: 'l_id',
                    isBase: false
                } as ExtendedRouteMeta,
            },
            {
                path: ':l_id/analytics',
                components: {
                    default: ListingAnalytics,
                },
                name: 'listing.single.analytics',
                meta: {
                    title: 'Listing Analytics',
                    icon: 'mdi-car-convertible',
                    noCache: false,
                    resource: 'l_id'
                } as ExtendedRouteMeta,
            },
        ],
    }
];

export default listingRoutes;
