import axios from 'axios';
import * as types from '@/store/mutation-types';
import {RootState} from '@/types/RootState';
import {performApiRequest} from "@/store/helpers/vuex-helpers";
import {Module} from 'vuex';

export interface TagState {
    loading: Record<string, boolean>;
    tags: Record<string, number>;
}

// state
const state: TagState = {
    loading: {
        fetch_tags: false,
        create_tag: false,
        remove_tag: false,
    },
    tags: {},
};


const mutations = {
    [types.SET_TAGS_LOADING](state: TagState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_TAGS](state: TagState, tags) {
        state.tags = tags;
    },
};

const actions = {

    async fetchTags({commit}, {forType}) {
        const query = forType ? `?for=${forType}` : '';

        try {
            const responseData = await performApiRequest(commit, 'SET_TAGS_LOADING', 'fetch_tags', () => axios.get(`/v1/tags${query}`));
            commit(types.SET_TAGS, responseData.tags);
        } catch (error) {
        }
    },

    async createTag({commit}, data: { name: string, forType: string, forId: string }) {
        try {
            await performApiRequest(commit, 'SET_TAGS_LOADING', 'create_tag', () => axios.post('/v1/tags', data));
        } catch (error) {
            commit(types.SET_COUPON_FORM_ERROR, error.response.data?.errors || 'There was an error');
        }
    },

    async removeTag({commit}, data: { name: string, customer_id: string }) {
        try {
            await performApiRequest(commit, 'SET_TAGS_LOADING', 'remove_tag', () => axios.post('/v1/tags/remove', data));
        } catch (error) {
            commit(types.SET_COUPON_FORM_ERROR, error.response.data?.errors || 'There was an error');
        }
    },
};

const tagsModule: Module<TagState, RootState> = {
    namespaced: true,

    state,

    getters: {
        loading: (state: TagState) => state.loading,
        tags: (state: TagState) => state.tags,
    },

    mutations,

    actions,
}

export default tagsModule;
