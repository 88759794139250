<template>
  <div>
    <h2 class="mt-8 mb-4 font-bold text-2xl">Add New Employee</h2>

    <v-alert v-if="seatLimitReached" class="my-2" type="warning">
      <span v-html="seatLimitReachedMessage"></span>
    </v-alert>

    <div class="grid grid-cols-2 gap-1">
      <v-card :disabled="seatLimitReached">
        <v-card-title class="mb-2">
          Account information
        </v-card-title>

        <v-card-subtitle>
          Invite a team member
        </v-card-subtitle>
        <v-container>
          <v-form v-model="newTeamMemberFormValid" :disabled="seatLimitReached">
            <div class=" align-center">
              <div class="">
                <div class="">
                  <div class="">
                    <v-text-field
                      v-model="form.first_name"
                      :disabled="seatLimitReached"
                      :error-messages="form.errors.get('first_name')"
                      :label="$t('first_name')"
                      autofocus="autofocus"
                      large
                      name="first_name"
                      variant="outlined"
                      @keyup="form.errors.clear('first_name')"
                    />
                  </div>
                  <div class="">
                    <v-text-field
                      v-model="form.last_name"
                      :disabled="seatLimitReached"
                      :error-messages="form.errors.get('last_name')"
                      :label="$t('last_name')"
                      name="last_name"
                      variant="outlined"
                      @keyup="form.errors.clear('last_name')"
                    />
                  </div>
                </div>
                <div class="">
                  <div class="">
                    <v-text-field
                      v-model="form.email"
                      :disabled="seatLimitReached"
                      :error-messages="form.errors.get('email')"
                      :label="$t('email')"
                      :rules="registerFormRules.email"
                      name="email"
                      required
                      type="email"
                      variant="outlined"
                      @keyup="form.errors.clear('email')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </v-form>
        </v-container>
      </v-card>
    </div>

    <div class="grid grid-cols-2 my-5">
      <v-card :disabled="seatLimitReached || form.busy">
        <v-card-title>
          Permissions
        </v-card-title>
        <v-card-subtitle>
          Choose a set of permissions that fits this new team member's role.
        </v-card-subtitle>

        <v-card-text>
          <div class="flex">
            <v-spacer/>
            <v-checkbox
              v-model="selectAllPermissions"
              :indeterminate="form.permissions.length !== 0 && form.permissions.length < possiblePermissions.length"
              color="accent-4"
            >
              <template v-slot:prepend>
                <small style="min-width: 65px; margin-top: 5px; color: #5a5e66">Select All</small>
              </template>
            </v-checkbox>
          </div>

          <div>
            <div v-for="(item, category) in filteredPermissions" :key="`title-${category}`">
              <div class="font-bold text-xl">{{ formatTitles(category) }}</div>

              <v-list-item v-for="(perm, index) in item" :key="`item${category}-${index}`" :value="perm.name">
                <template v-slot:prepend="{ isActive }">
                  <v-list-item-action :start="true">
                    <v-checkbox-btn
                      v-model="form.permissions"
                      :input-value="isActive"
                      :true-value="perm.name"
                      :value="perm.name"

                      color="accent-4"
                      @change="form.errors.clear('permissions')"
                    ></v-checkbox-btn>
                  </v-list-item-action>

                  <v-list-item-title v-text="formatTitles(perm.name)"></v-list-item-title>
                </template>
              </v-list-item>
              <v-divider/>
            </div>
          </div>
        </v-card-text>

        <v-alert v-if="form.errors.any()" class="card-alert-full" density="compact" type="error">
          {{ form.errors.flatten()[0] }}
        </v-alert>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            :disabled="!newTeamMemberFormValid || form.errors.any() || form.busy"
            :loading="form.busy"
            class="mt-3"
            color="primary"
            @click="inviteNewTeamMember()"
          >
            Send Invite
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import {mapGetters} from 'vuex';
import Form from 'vform';
import {EventBus} from '@/plugins/eventBus';
import {each, get, map, remove} from 'lodash';
import {useSubscription} from '@/composables/useSubscription';


export default {
  name: 'SettingsEmployeeNew',

  setup() {
    const {seatLimitReached, seatLimitReachedMessage} = useSubscription()
    return {
      seatLimitReached,
      seatLimitReachedMessage,
    };
  },

  data() {
    return {
      newTeamMemberFormValid: false,
      selectAllPermissions: true,
      form: new Form({
        first_name: '',
        last_name: '',
        email: '',
        permissions: [],
      }),
    };
  },

  computed: {
    ...mapGetters({
      possiblePermissions: 'app/possiblePermissions',
      employee: 'company/employee',
      registerFormRules: 'company/registerFormRules',
    }),

    filteredPermissions() {
      const perms = {
        customers: [],
        users: [],
        listings: [],
        orders: [],
        settings: [],
        turo: [],
      };

      const allPermissions = [...this.possiblePermissions];

      each(perms, (p, i) => {
        perms[i] = remove(allPermissions, (p) => p.name.includes(i));
      });
      perms.misc = allPermissions;

      return perms;
    },

    employeePermissions: {
      get() {
        const employee = this.$store.getters['company/employee'];
        return employee?.permissions || [];
      },
      set(value) {
        this.$store.commit('company/SET_EMPLOYEE_PERMISSIONS', value);
      },
    },
  },

  watch: {
    possiblePermissions() {
      this.form.permissions = map(this.possiblePermissions, (p) => p.name);
    },

    selectAllPermissions: {
      immediate: true,
      handler(newData) {
        if (newData) {
          this.form.permissions = map(this.possiblePermissions, (p) => p.name);
        } else {
          this.form.permissions = [];
        }
      },
    },
  },

  methods: {
    formatTitles(word) {
      if (!word) return '';
      const title = word.split('_');
      return title.map((word) => word[0].toUpperCase() + word.substring(1)).join(' ');
    },
    async inviteNewTeamMember() {
      try {
        const response = await this.form.post('/v1/employees');
        if (response.status === 200) {
          if (get(response, 'data.company')) {
            this.$store.commit('company/SET_COMPANY', get(response, 'data.company'));
          }

          EventBus.$emit('SystemMessage', {
            type: 'success',
            message: 'Employee invited',
          });
          this.$router.push({name: 'settings.account'}).catch(() => {
          });
        }
      } catch (er) {
        console.dir(er);
      }
    },
  },
};
</script>
