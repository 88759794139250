import axios from 'axios';
import {EventBus} from '@/plugins/eventBus';
import {Form} from '@/plugins/FWForms/index';
import * as types from '../mutation-types';
import {performApiRequest} from "@/store/helpers/vuex-helpers";

export interface OutdoorsyState {
    loading: Record<string, boolean>;
    form: Form<any>;
    connections: any[];
    listings: any[];
    bookings: any[];
}

const state: OutdoorsyState = {
    loading: {
        login: false,
        matching: false,
        toggle: false,
        listings: false,
    },
    form: new Form(),
    connections: [],
    listings: [],
    bookings: [],
};

const getters = {
    form: (state: OutdoorsyState) => state.form,
    connections: (state: OutdoorsyState) => state.connections,
    loading: (state: OutdoorsyState) => state.loading,
    listings: (state: OutdoorsyState) => state.listings,
    bookings: (state: OutdoorsyState) => state.bookings,
};

const mutations = {
    [types.SET_OUTDOORSY_LOADING](state: OutdoorsyState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_OUTDOORSY_CONNECTIONS](state: OutdoorsyState, connections) {
        state.connections = connections;
    },
    [types.SET_OUTDOORSY_LISTINGS](state: OutdoorsyState, listings) {
        state.listings = listings;
    },
    [types.SET_OUTDOORSY_BOOKINGS](state: OutdoorsyState, bookings) {
        state.bookings = bookings;
    },
};

const actions = {


    /**
     * Login to Outdoorsy
     *
     * @param commit
     * @param {Object} credentials
     */
    async login({commit}, credentials) {
        try {
            const responseData = await performApiRequest(commit, 'SET_OUTDOORSY_LOADING', 'login', () => axios.post(`/v1/connections/outdoorsy`, credentials));
            EventBus.$emit('outdoorsy-connection-successful', responseData?.providers);
            commit('SET_OUTDOORSY_CONNECTIONS', responseData?.providers);
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Outdoorsy connection connected successfully!',
            });
        } catch (error) {
            EventBus.$emit('SystemMessage', {
                type: 'error',
                message: 'Outdoorsy connection connection error.',
            });
        }
    },


    /**
     * Delete Outdoorsy
     *
     * @param commit
     * @param {Object} outdoorsy
     */
    async delete({commit}, outdoorsy) {
        try {
            const responseData = await performApiRequest(commit, 'SET_OUTDOORSY_LOADING', 'delete', () => axios.delete(`/v1/connections/outdoorsy/${outdoorsy.uuid}`));
            EventBus.$emit('delete-outdoorsy-connection-success');
            commit('SET_OUTDOORSY_CONNECTIONS', responseData.providers);
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Outdoorsy connection deleted successfully!',
            });
        } catch (error) {
        }
    },


    /**
     * Toggle Outdoorsy active
     *
     * @param commit
     * @param {Object} outdoorsy
     */
    async toggleActive({commit}, outdoorsy) {
        commit('SET_OUTDOORSY_LOADING', {subject: 'toggle', loading: true});

        try {
            const responseData = await performApiRequest(commit, 'SET_OUTDOORSY_LOADING', 'toggle', () => axios.put(`/v1/connections/outdoorsy/${outdoorsy.uuid}/active`));
            commit('SET_OUTDOORSY_LISTINGS', responseData?.listings);
            EventBus.$emit('toggle-outdoorsy-listings-success');
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Outdoorsy connection toggled successfully!',
            });
        } catch (error) {
        }
    },


    /**
     * Fetch Outdoorsy listings
     *
     * @param commit
     * @param {String} outdoorsy_uuid
     */
    async fetchOutdoorsyListings({commit}, outdoorsy_uuid) {
        try {
            const responseData = await performApiRequest(commit, 'SET_OUTDOORSY_LOADING', 'listings', () => axios.get(`/v1/connections/outdoorsy/${outdoorsy_uuid}/vehicles`));
            commit('SET_OUTDOORSY_LISTINGS', responseData?.listings);
            EventBus.$emit('fetch-outdoorsy-listings-success');
        } catch (error) {
        }
    },


    /**
     * Fetch Outdoorsy bookings
     *
     * @param commit
     * @param {String} outdoorsy_uuid
     */
    async fetchBookings({commit}, outdoorsy_uuid) {
        try {
            const responseData = await performApiRequest(commit, 'SET_OUTDOORSY_LOADING', 'listings', () => axios.get(`/v1/connections/outdoorsy/${outdoorsy_uuid}/vehicles`));
            commit('SET_OUTDOORSY_BOOKINGS', responseData?.listings);
            EventBus.$emit('fetch-outdoorsy-listings-success');
        } catch (error) {
        }
    },

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
