import type {RouteRecordRaw} from 'vue-router';
import {CommonRouteMeta} from "@/types";

const BaseDashboard = () => import('@/views/dashboard/BaseDashboard.vue');


const connectionsRoutes: RouteRecordRaw[] = [
    {
        path: '/connections',
        component: BaseDashboard,
        meta: {
            title: 'connections',
            icon: 'mdi-toy-brick',
            noCache: true,
            middleware: ['auth'],
            layout: 'Dashboard',
            showInMenu: true,
        } as CommonRouteMeta,
        children: [
            {
                path: '',
                components: {
                    default: () => import(/* webpackChunkName: "connections" */ '@/views/connections/index.vue'),
                },
                name: 'connections',
                meta: {title: 'connections', icon: 'mdi-toy-brick', noCache: true},
            },
            {
                path: 'axle',
                components: {
                    default: () => import(/* webpackChunkName: "axle" */ '@/views/connections/axle/Axle.vue'),
                },
                meta: {title: 'Axle', icon: 'mdi-toy-brick', noCache: false},
                children: [
                    {
                        path: '',
                        components: {
                            default: () => import(/* webpackChunkName: "axle" */ '@/views/connections/axle/BaseAxle.vue'),
                        },
                        name: 'connections.axle',
                    },
                ],
            },
            {
                path: 'turo',
                components: {
                    default: () => import(/* webpackChunkName: "turo" */ '@/views/connections/turo/Turo.vue'),
                },
                meta: {title: 'Turo', icon: 'mdi-toy-brick', noCache: false},
                children: [
                    {
                        path: '',
                        components: {
                            default: () => import(/* webpackChunkName: "turo" */ '@/views/connections/turo/BaseTuro.vue'),
                        },
                        name: 'connections.turo',
                    },
                    {
                        path: 'all/bookings',
                        components: {
                            default: () => import(/* webpackChunkName: "turo" */ '@/views/connections/turo/Bookings.vue'),
                        },
                        name: 'connections.turo.bookings',
                        meta: {title: 'bookings'},
                    },
                    {
                        path: ':turo_uuid/bookings/:b_id',
                        components: {
                            default: () => import(/* webpackChunkName: "turo" */ '@/views/connections/turo/BookingSingle.vue'),
                        },
                        name: 'connections.turo.bookings.single',
                        meta: {title: 'booking single'},
                    },
                    {
                        path: 'all/vehicles',
                        components: {
                            default: () => import(/* webpackChunkName: "turo" */ '@/views/connections/turo/Vehicles.vue'),
                        },
                        name: 'connections.turo.vehicles',
                        meta: {title: 'vehicles'},
                    },
                    {
                        path: 'all/messages',
                        components: {
                            default: () => import(/* webpackChunkName: "turo" */ '@/views/connections/turo/Messages.vue'),
                        },
                        name: 'connections.turo.messages',
                        meta: {title: 'messages'},
                    },
                    {
                        path: 'all/messages2',
                        components: {
                            default: () => import(/* webpackChunkName: "turo" */ '@/views/connections/turo/TuroMessages.vue'),
                        },
                        name: 'connections.turo.messages2',
                        meta: {title: 'messages'},
                    },
                    {
                        path: 'all/emails',
                        components: {
                            default: () => import(/* webpackChunkName: "turo" */ '@/views/connections/turo/Emails.vue'),
                        },
                        name: 'connections.turo.emails',
                        meta: {title: 'emails'},
                    },
                    {
                        path: 'all/earnings',
                        components: {
                            default: () => import(/* webpackChunkName: "turo" */ '@/views/connections/turo/Earnings.vue'),
                        },
                        name: 'connections.turo.earnings',
                        meta: {
                            title: 'earnings',
                            permissions: ['orders_financials'],
                        },
                    },
                    {
                        path: 'all/reimbursements',
                        components: {
                            default: () => import(/* webpackChunkName: "turo" */ '@/views/connections/turo/Reimbursements.vue'),
                        },
                        name: 'connections.turo.reimbursements',
                        meta: {
                            title: 'earnings',
                            permissions: ['orders_financials'],
                        },
                    },
                    {
                        path: 'change-request',
                        components: {
                            default: () => import(/* webpackChunkName: "turo" */ '@/views/connections/turo/ChangeRequestSettings.vue'),
                        },
                        name: 'connections.turo.changeRequest',
                        meta: {title: 'Change Request'},
                    },
                    {
                        path: 'feedback',
                        components: {
                            default: () => import(/* webpackChunkName: "turo" */ '@/views/connections/turo/Feedback.vue'),
                        },
                        name: 'connections.turo.feedback',
                        meta: {title: 'Feedback'},
                    },
                    {
                        path: ':turo_uuid',
                        components: {
                            default: () => import(/* webpackChunkName: "turo" */ '@/views/connections/turo/SingleTuro.vue'),
                        },
                        name: 'connections.turo.single',
                        meta: {title: 'Turo home'},
                    },
                    {
                        path: ':turo_uuid/vehicles',
                        components: {
                            default: () => import(/* webpackChunkName: "turo" */ '@/views/connections/turo/SingleTuroVehicles.vue'),
                        },
                        name: 'connections.turo.single.vehicles',
                        meta: {title: 'Turo Vehicles'},
                    },
                    {
                        path: ':turo_uuid/research',
                        components: {
                            default: () => import(/* webpackChunkName: "turo" */ '@/views/connections/turo/Research.vue'),
                        },
                        name: 'connections.turo.single.research',
                        meta: {title: 'Turo Vehicles'},
                    },
                ],
            },
            {
                path: 'getaround',
                components: {
                    default: () => import(/* webpackChunkName: "getaround" */ '@/views/connections/getaround/Getaround.vue'),
                },
                children: [
                    {
                        path: '',
                        components: {
                            default: () => import(/* webpackChunkName: "getaround" */ '@/views/connections/getaround/BaseGetaround.vue'),
                        },
                        name: 'connections.getaround',
                    },
                    {
                        path: ':getaround_uuid',
                        components: {
                            default: () => import(/* webpackChunkName: "getaround" */ '@/views/connections/getaround/SingleGetaround.vue'),
                        },
                        name: 'connections.getaround.single',
                    },
                    {
                        path: ':getaround_uuid/vehicles',
                        components: {
                            default: () => import(/* webpackChunkName: "getaround" */ '@/views/connections/getaround/SingleGetaroundVehicles.vue'),
                        },
                        name: 'connections.getaround.single.vehicles',
                    },
                    {
                        path: ':getaround_uuid/bookings',
                        components: {
                            default: () => import(/* webpackChunkName: "getaround" */ '@/views/connections/getaround/SingleGetaroundBookings.vue'),
                        },
                        name: 'connections.getaround.single.bookings',
                    },
                    {
                        path: ':getaround_uuid/bookings/:reservation_id',
                        components: {
                            default: () => import(/* webpackChunkName: "getaround" */ '@/views/connections/getaround/SingleGetaroundOneReservation.vue'),
                        },
                        name: 'connections.getaround.single.booking',
                    },
                    {
                        path: 'bookings',
                        components: {
                            default: () => import(/* webpackChunkName: "getaround" */ '@/views/connections/getaround/Bookings.vue'),
                        },
                        name: 'connections.getaround.bookings',
                        meta: {title: 'bookings'},
                    },
                ],
            },
            {
                path: 'tesla',
                components: {
                    default: () => import(/* webpackChunkName: "tesla" */ '@/views/connections/tesla/Tesla.vue'),
                },
                meta: {title: 'Tesla', icon: 'mdi-toy-brick', noCache: true},
                children: [
                    {
                        path: '',
                        components: {
                            default: () => import(/* webpackChunkName: "tesla" */ '@/views/connections/tesla/BaseTesla.vue'),
                        },
                        name: 'connections.tesla',
                    },
                    {
                        path: 'invoices',
                        components: {
                            default: () => import(/* webpackChunkName: "tesla" */ '@/views/connections/tesla/Invoices.vue'),
                        },
                        name: 'connections.tesla.invoices',
                    },
                    {
                        path: 'vehicles',
                        components: {
                            default: () => import(/* webpackChunkName: "tesla" */ '@/views/connections/tesla/Vehicles.vue'),
                        },
                        name: 'connections.tesla.vehicles',
                    },
                    {
                        path: ':tesla_uuid',
                        components: {
                            default: () => import(/* webpackChunkName: "tesla" */ '@/views/connections/tesla/TeslaSingle.vue'),
                        },
                        name: 'connections.tesla.single',
                    },
                    {
                        path: 'vehicles/:tesla_uuid',
                        components: {
                            default: () => import(/* webpackChunkName: "tesla" */ '@/views/connections/tesla/VehicleSingle.vue'),
                        },
                        name: 'connections.tesla.vehicles.single',
                    },
                ],
            },
            {
                path: 'outdoorsy',
                components: {
                    default: () => import(/* webpackChunkName: "outdoorsy" */ '@/views/connections/outdoorsy/Outdoorsy.vue'),
                },
                meta: {title: 'Outdoorsy', icon: 'mdi-toy-brick', noCache: true},
                children: [
                    {
                        path: '',
                        components: {
                            default: () => import(/* webpackChunkName: "outdoorsy" */ '@/views/connections/outdoorsy/BaseOutdoorsy.vue'),
                        },
                        name: 'connections.outdoorsy',
                    },
                    {
                        path: ':provider_uuid',
                        components: {
                            default: () => import(/* webpackChunkName: "outdoorsy" */ '@/views/connections/outdoorsy/OutdoorsySingle.vue'),
                        },
                        name: 'connections.outdoorsy.single',
                    },
                    {
                        path: ':provider_uuid/listings',
                        components: {
                            default: () => import(/* webpackChunkName: "outdoorsy" */ '@/views/connections/outdoorsy/SingleOutdoorsyListings.vue'),
                        },
                        name: 'connections.outdoorsy.single.listings',
                        meta: {title: 'Outdoorsy Vehicles'},
                    },
                    {
                        path: ':provider_uuid/bookings',
                        components: {
                            default: () => import(/* webpackChunkName: "outdoorsy" */ '@/views/connections/outdoorsy/SingleOutdoorsyBookings.vue'),
                        },
                        name: 'connections.outdoorsy.single.bookings',
                        meta: {title: 'Outdoorsy Vehicles'},
                    },
                    {
                        path: 'invoices',
                        components: {
                            default: () => import(/* webpackChunkName: "outdoorsy" */ '@/views/connections/tesla/Invoices.vue'),
                        },
                        name: 'connections.outdoorsy.invoices',
                    },
                    {
                        path: 'vehicles',
                        components: {
                            default: () => import(/* webpackChunkName: "outdoorsy" */ '@/views/connections/tesla/Vehicles.vue'),
                        },
                        name: 'connections.outdoorsy.vehicles',
                    },
                ],
            },
            {
                path: 'hq-rentals',
                components: {
                    default: () => import(/* webpackChunkName: "hq-rentals" */ '@/views/connections/hq-rentals/HQRentals.vue'),
                },
                meta: {title: 'HQ Rentals', icon: 'mdi-toy-brick', noCache: true},
                children: [
                    {
                        path: '',
                        components: {
                            default: () => import(/* webpackChunkName: "hq-rentals" */ '@/views/connections/hq-rentals/BaseHQRentals.vue'),
                        },
                        name: 'connections.hq-rentals',
                    },
                    {
                        path: ':provider_uuid',
                        components: {
                            default: () => import(/* webpackChunkName: "hq-rentals" */ '@/views/connections/hq-rentals/HQRentalsSingle.vue'),
                        },
                        name: 'connections.hq-rentals.single',
                    },
                    {
                        path: ':provider_uuid/listings',
                        components: {
                            default: () => import(/* webpackChunkName: "hq-rentals" */ '@/views/connections/hq-rentals/SingleHQRentalsListings.vue'),
                        },
                        name: 'connections.hq-rentals.single.listings',
                        meta: {title: 'HQ Rentals Vehicles'},
                    },
                    {
                        path: ':provider_uuid/bookings',
                        components: {
                            default: () => import(/* webpackChunkName: "hq-rentals" */ '@/views/connections/hq-rentals/SingleHQRentalsBookings.vue'),
                        },
                        name: 'connections.hq-rentals.single.bookings',
                        meta: {title: 'HQ Rentals Vehicles'},
                    },
                    {
                        path: ':provider_uuid/contacts',
                        components: {
                            default: () => import(/* webpackChunkName: "hq-rentals" */ '@/views/connections/hq-rentals/SingleHQRentalsContacts.vue'),
                        },
                        name: 'connections.hq-rentals.single.contacts',
                        meta: {title: 'HQ Rentals Contacts'},
                    },
                ],
            },
            {
                path: 'bouncie',
                components: {
                    default: () => import(/* webpackChunkName: "bouncie" */ '@/views/connections/bouncie/Bouncie.vue'),
                },
                meta: {title: 'Bouncie', icon: 'mdi-toy-brick', noCache: true},
                children: [
                    {
                        path: '',
                        components: {
                            default: () => import(/* webpackChunkName: "bouncie" */ '@/views/connections/bouncie/BaseBouncie.vue'),
                        },
                        name: 'connections.bouncie',
                    },
                    {
                        path: 'vehicles',
                        components: {
                            default: () => import(/* webpackChunkName: "bouncie" */ '@/views/connections/bouncie/Vehicles.vue'),
                        },
                        name: 'connections.bouncie.vehicles',
                    },
                    {
                        path: 'vehicles/:bouncie_uuid',
                        components: {
                            default: () => import(/* webpackChunkName: "bouncie" */ '@/views/connections/bouncie/VehicleSingle.vue'),
                        },
                        name: 'connections.bouncie.vehicles.single',
                    },
                ],
            },
            {
                path: 'stripe',
                components: {
                    default: () => import('@/views/connections/stripe/Stripe.vue'),
                },
                meta: {title: 'Stripe', icon: 'mdi-toy-brick', noCache: true},
                children: [
                    {
                        path: '',
                        components: {
                            default: () => import('@/views/connections/stripe/StripeBase.vue'),
                        },
                        name: 'connections.stripe',
                        meta: {title: 'Stripe', icon: 'mdi-toy-brick', noCache: true},
                    },
                    {
                        path: 'identities',
                        components: {
                            default: () => import('@/views/connections/stripe/StripeIdentities.vue'),
                        },
                        name: 'connections.stripe.identities',
                    },
                ],
            },
            {
                path: 'lula',
                components: {
                    default: () => import('@/views/connections/lula/Lula.vue'),
                },
                meta: {title: 'Lula', icon: 'mdi-toy-brick', noCache: true},
                children: [
                    {
                        path: '',
                        components: {
                            default: () => import('@/views/connections/lula/LulaBase.vue'),
                        },
                        name: 'connections.lula',
                        meta: {title: 'Lula', icon: 'mdi-toy-brick', noCache: true},
                    },
                    {
                        path: ':lula_uuid',
                        components: {
                            default: () => import(/* webpackChunkName: "lula" */ '@/views/connections/lula/LulaAccountSingle.vue'),
                        },
                        name: 'connections.lula.account',
                        meta: {title: 'Lula'},
                    },
                    {
                        path: ':lula_uuid/vehicles',
                        components: {
                            default: () => import(/* webpackChunkName: "lula" */ '@/views/connections/lula/LulaVehicles.vue'),
                        },
                        name: 'connections.lula.vehicles',
                        meta: {title: 'Lula Vehicles'},
                    },
                ],
            },
            {
                path: 'tolls',
                components: {
                    default: () => import(/* webpackChunkName: "tolls" */ '@/views/connections/tolls/Tolls.vue'),
                },
                meta: {title: 'Tolls', icon: 'mdi-toy-brick', noCache: true},
                children: [
                    {
                        path: '',
                        components: {
                            default: () => import(/* webpackChunkName: "tolls" */ '@/views/connections/tolls/TollsBase.vue'),
                        },
                        name: 'connections.tolls',
                    },
                    {
                        path: 'transactions',
                        components: {
                            default: () => import(/* webpackChunkName: "tolls" */ '@/views/connections/tolls/TollsTransactions.vue'),
                        },
                        name: 'connections.tolls.transactions',
                    },
                ],
            },
        ],
    }
];

export default connectionsRoutes;
