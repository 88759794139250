<template>
  <div>
    <div class="">
      <div class="">
        <div class="setting-section-description">
          <h2>New template structure</h2>
          <div class="feature-description">
            Create price tiers to set prices for different rental periods. You could use it to make the price lower when
            customers rent for a longer period of time.

            <TheHelpLink url="https://help.fleetwire.io/en/article/setting-up-taxes-ey9pyo/?bust=1675453082095"></TheHelpLink>
          </div>
        </div>
      </div>
      <div class="">
        <v-card variant="outlined">
          <v-card-text class="mt-3">
            <div>
              <div>
                <label class="font-bold">Template Name</label>
                <v-text-field
                  ref="template_name"
                  v-model="currentPricingStructure.name"
                  density="compact"
                  hide-details
                  name="template_name"
                  variant="outlined"
                ></v-text-field>
                <div class="mt-2">This is not visible to customers.</div>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <v-card class="mt-5" variant="outlined">
          <v-card-text>
            <div class="mt-10">
              <h5 class="h5">Price tiers</h5>

              <div class="font-bold">
                Example price
              </div>
              <p>The example price is replaced by the base price of the listing that use this template.</p>

              <v-text-field
                v-model="currentPricingStructure.price_example"
                :prefix="companyMoneyTextInputSettings.prefix"
                :suffix="companyMoneyTextInputSettings.suffix"
                class="pricing-structure-example-price"
                density="compact"
                type="number"
                variant="outlined"
                @blur="updateResultingPriceParent()"
              ></v-text-field>

              <div>
                <div>
                  <div v-for="(tier,i) in currentPricingStructure.price_tiers" :key="i" class="Row-eCkaBm evTrKC">
                    <div class="Div-bMiTSW Col-iaLJLx InputField-iwpqyF xfmMQ" data-tid="Row 1: Label">
                      <div v-if="i === 0" class="LabelContainer-fLmGyQ kXPMMB">
                        <label class="Label-eJXSuD Label-iekCsg gZKasB eYnYJY" for="clidjimoz00273b7saj4fna9w">
                          Price tier label
                        </label>
                      </div>
                      <span class="InputContainer-hJWSEI bsHxqg">
                          <input
                            v-model="tier.name"
                            :name="`price_tiles_attributes[${i}].name`"
                            autocomplete="off"
                            class="Input-bXxEzP Input-icnXNI cCETsh blXyKC"
                            type="text"
                          ></span>
                    </div>
                    <div class="Div-bMiTSW Col-iaLJLx InputField-iwpqyF xfmMQ" data-tid="Row 1: Duration">
                      <div v-if="i === 0" class="LabelContainer-fLmGyQ kXPMMB">
                        <label class="Label-eJXSuD Label-iekCsg gZKasB eYnYJY" for="clidjimp000283b7surusxx4f">Duration</label>
                      </div>
                      <span class="InputContainer-hJWSEI bsHxqg">
                          <input
                            v-model="tier.quantity"
                            :name="`price_tiles_attributes[${i}].quantity`"
                            autocomplete="off"
                            class="Input-bXxEzP Input-icnXNI cCETsh blXyKC"
                            min="1"
                            type="number"
                          ></span>
                    </div>
                    <div class="Div-bMiTSW Col-iaLJLx InputField-iwpqyF xfmMQ" data-tid="Row 1: Period">
                      <div v-if="i === 0" class="LabelContainer-fLmGyQ kXPMMB"></div>
                      <div class="Container-boBWlZ erHrFZ">
                        <v-select
                          v-model="tier.period"
                          :items="durationUnits"
                          class=""
                          density="compact"
                          hide-details
                          item-title="text"
                          item-value="value"
                          style=""
                          variant="outlined"
                        ></v-select>
                      </div>
                    </div>

                    <div class="Div-bMiTSW Col-iaLJLx InputField-iwpqyF xfmMQ" data-tid="Row 1: Multiplier">
                      <div v-if="i === 0" class="LabelContainer-fLmGyQ kXPMMB">
                        <label class="Label-eJXSuD Label-iekCsg gZKasB eYnYJY" for="clidjimp8002a3b7sz3j23aa9">
                          Multiply base price by
                        </label>
                      </div>
                      <span class="InputContainer-hJWSEI bsHxqg">
                          <input
                            v-model="tier.multiplier"
                            :name="`price_tiles_attributes[${i}].multiplier`"
                            autocomplete="off"
                            class="cCETsh"
                            type="text"
                            @blur="updateResultingPrice(i)"
                          ></span>
                    </div>
                    <div class="ONqJD">* base price =</div>
                    <div class="" data-tid="Row 1: Example price">
                      <div v-if="i === 0" class="LabelContainer-fLmGyQ kXPMMB">
                        <label class="Label-eJXSuD Label-iekCsg gZKasB eYnYJY" for="clidjimp9002b3b7sb1ldvxal">
                          Resulting price
                        </label>
                      </div>
                      <span class="InputContainer-hJWSEI bsHxqg">
                          <i class="I-gmFDEA eaYeOY">$</i>
                          <input
                            v-model="tier.example_price"
                            :name="`price_tiles_attributes[${i}].example_price`"
                            autocomplete="off"
                            class="Input-bXxEzP Input-icnXNI cCETsh blXyKC"
                            label="Resulting price"
                            type="number"
                            @blur="updateMultiplier(i)"
                          >
                        </span>
                    </div>
                    <v-btn class="fJfBtJ mt-4" size="small" variant="text" @click="removePricingTier(i)">
                      <v-icon size="small">mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </div>

                <div class="mt-5">
                  <v-btn variant="outlined" @click="addPricingTier()">Add Price Tier</v-btn>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <v-card class="mt-5" variant="outlined">
          <v-card-title>Automatic increments</v-card-title>
          <v-card-subtitle>
            Set an amount that is added for each period following the price tier with the longest period.
          </v-card-subtitle>
          <v-card-text>
            <div>
              <div class="Row-eCkaBm esebmt" data-tid="Each extra block">
                <div class="EachExtra-hFYHww ipTNEC">
                  <div class="Div-bMiTSW Col-iaLJLx InputField-iwpqyF xfmMQ" data-tid="Each extra">
                    <div class="LabelContainer-fLmGyQ kXPMMB">
                      <label class="Label-eJXSuD Label-iekCsg gZKasB eYnYJY" for="clidly6i3002c3b7s5y2eovx6">
                        Each extra
                      </label>
                    </div>
                    <div class="Container-boBWlZ erHrFZ" data-tid="Each extra">
                      <div class="Container-boBWlZ erHrFZ">
                        <v-select
                          v-model="currentPricingStructure.duration_unit"
                          :items="durationUnits"
                          class=""
                          density="compact"
                          hide-details
                          item-title="text"
                          item-value="value"
                          style=""
                          variant="outlined"
                        ></v-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Div-bMiTSW Col-iaLJLx InputField-iwpqyF xfmMQ" data-tid="Each extra multiplier">
                  <div class="LabelContainer-fLmGyQ kXPMMB">
                    <label class="Label-eJXSuD Label-iekCsg gZKasB eYnYJY" for="clidly6i6002d3b7s2wg4cao0">
                      Multiply base price by
                    </label>
                  </div>
                  <span class="InputContainer-hJWSEI bsHxqg">
                    <input
                      v-model="currentPricingStructure.multiplier"
                      autocomplete="off"
                      class="Input-bXxEzP Input-icnXNI cCETsh blXyKC"
                      name="hour"
                      type="text"
                      @blur.stop="updateAddedAmount()"
                    ></span>
                </div>
                <div class="PriceText-iYCcgJ ONqJD">* base price =</div>
                <div class="Div-bMiTSW Col-iaLJLx InputField-iwpqyF xfmMQ" data-tid="Each extra price">
                  <div class="LabelContainer-fLmGyQ kXPMMB">
                    <label class="Label-eJXSuD Label-iekCsg gZKasB eYnYJY" for="clidly6i6002e3b7srupwc20u">
                      Added amount
                    </label>
                  </div>
                  <span class="InputContainer-hJWSEI bsHxqg">
                    <i class="I-gmFDEA eaYeOY">$</i>
                    <input
                      v-model="currentPricingStructure.added_amount"
                      autocomplete="off"
                      class="Input-bXxEzP Input-icnXNI cCETsh blXyKC"
                      name="example_price"
                      @blur.stop="updateAddedAmountMultiple()"
                    >
                  </span>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <div class="flex mt-5">
          <v-spacer/>
          <v-btn variant="text" @click="goBack">Cancel</v-btn>
          <v-btn :disabled="saveButtonReady" class="ml-2" color="primary" variant="flat" @click="savePriceStructureInit()">
            Save
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {mapActions, mapGetters} from 'vuex';
import {get} from 'lodash';
import {EventBus} from '@/plugins/eventBus';
import {useMoneyFormatter} from '@/composables/useMoneyFormatter';
import {useRouter} from 'vue-router';

export default {
  name: 'PricingTemplateNew',

  setup() {
    const router = useRouter();
    const {companyMoneyTextInputSettings} = useMoneyFormatter();

    const goBack = () => {
      router.go(-1);
    };

    return {
      goBack,
      companyMoneyTextInputSettings,
    };
  },


  data() {
    return {
      durationUnits: [
        {
          text: 'Hours',
          value: 'hours',
        },
        {
          text: 'Days',
          value: 'days',
        },
        {
          text: 'Weeks',
          value: 'weeks',
        },
        {
          text: 'Months',
          value: 'months',
        },
        {
          text: 'Years',
          value: 'years',
        },
      ],
      currentPricingStructure: {
        uuid: null,
        name: '',
        price_example: 100,
        added_amount: 100,
        duration_unit: 'days',
        multiplier: 1,
        price_tiers: [
          {
            name: '1 day',
            quantity: 1,
            period: 'days',
            multiplier: 1,
            example_price: 100,
          },
        ],
      },
    };
  },

  created() {
    this.getPricingStructures();

    EventBus.$on('create-pricing-structure-success', () => {
      this.$router.push({name: 'settings.pricing.template'}).catch(() => {
      });
    });
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.template_name.focus();
    });
  },

  computed: {
    ...mapGetters({
      appFeatures: 'app/appFeatures',
      featureWaitlist: 'app/featureWaitlist',
      pricingStructures: 'pricing/pricingStructures',
      pricingStructure: 'pricing/pricingStructure',
      currentWorkingPricingStructure: 'pricing/currentWorkingPricingStructure',
    }),
    automaticAmount: {
      get() {
        return this.currentPricingStructure.example_price;
      },
      set(value) {
        this.currentPricingStructure.example_price = value;
      },
    },
    saveButtonReady() {
      return !this.currentPricingStructure.name || !this.currentPricingStructure.price_example;
    },
  },

  methods: {
    savePriceStructureInit() {
      this.savePriceStructure(this.currentPricingStructure);
    },
    ...mapActions({
      getPricingStructure: 'pricing/getPricingStructure',
      updatePriceStructure: 'pricing/updatePriceStructure',
      deletePriceStructure: 'pricing/deletePriceStructure',
      getPricingStructures: 'pricing/getPricingStructures',
      savePriceStructure: 'pricing/savePriceStructure',
    }),
    removePricingTier(index) {
      this.currentPricingStructure.price_tiers.splice(index, 1);
    },
    updateAddedAmount() {
      this.currentPricingStructure.added_amount = this.currentPricingStructure.price_example * this.currentPricingStructure.multiplier;
    },
    updateAddedAmountMultiple() {
      this.currentPricingStructure.multiplier = this.currentPricingStructure.added_amount / this.currentPricingStructure.price_example;
    },
    addPricingTier() {
      const lastUnit = this.currentPricingStructure.price_tiers[this.currentPricingStructure.price_tiers.length - 1];
      const durationUnit = get(lastUnit, 'period', 'days');
      const quantity = get(lastUnit, 'quantity', 0) + 1;

      this.currentPricingStructure.price_tiers.push({
        name: `${quantity} ${durationUnit}`,
        quantity,
        period: durationUnit,
        multiplier: 1,
        example_price: 100,
      });
    },
    updateResultingPriceParent() {
      this.currentPricingStructure.added_amount = this.currentPricingStructure.price_example * this.currentPricingStructure.multiplier;
      this.currentPricingStructure.price_tiers.forEach((tier) => {
        const multiplier = +get(tier, 'multiplier', 1);
        const examplePrice = this.currentPricingStructure.price_example;
        let newPrice = multiplier * examplePrice;
        newPrice = Math.round(newPrice * 100) / 100;
        this.$set(tier, 'example_price', newPrice);
      });
    },
    updateResultingPrice(index) {
      const tier = this.currentPricingStructure.price_tiers[index];
      const multiplier = +get(tier, 'multiplier', 1);
      const examplePrice = this.currentPricingStructure.price_example;
      this.$set(tier, 'example_price', multiplier * examplePrice);
    },
    updateMultiplier(index) {
      const tier = this.currentPricingStructure.price_tiers[index];
      const examplePrice = this.currentPricingStructure.price_example;
      const resultingPrice = +get(tier, 'example_price', 0);

      this.$set(tier, 'multiplier', resultingPrice / examplePrice);
    },
    updatePriceStructureInit() {
      this.updatePriceStructure(this.currentPricingStructure);
    },
    deletePriceStructureInit() {
      this.deletePriceStructure(this.currentPricingStructure);
    },
    setupEditStructure() {
      this.currentPricingStructure.uuid = this.pricingStructure.uuid;
      this.currentPricingStructure.name = this.pricingStructure.name;
      this.currentPricingStructure.price_example = 100;
      this.currentPricingStructure.multiplier = this.pricingStructure.multiplier;

      let durationUnit = ['hour', 'day', 'month', 'week', 'year'].find(key => this.pricingStructure[key] > 0);
      if (durationUnit) {
        this.currentPricingStructure.multiplier = this.pricingStructure[durationUnit];

        if (durationUnit[durationUnit.length - 1] !== 's') {
          durationUnit += 's';
        }
        this.currentPricingStructure.duration_unit = durationUnit;
      }

      const tiers = this.pricingStructure?.pricing_tiles ?? [];

      if (tiers) {
        this.currentPricingStructure.price_tiers = [];
        tiers.forEach((tier) => {
          this.currentPricingStructure.price_tiers.push({
            name: tier.name,
            quantity: tier.quantity,
            period: tier.period,
            multiplier: tier.multiplier,
            example_price: tier.multiplier * this.currentPricingStructure.price_example,
          });
        });
      }
    },
  },

  beforeUnmount() {
    EventBus.$off('create-pricing-structure-success');
  },
};
</script>
<style>
.evTrKC {
  display: grid;
  grid-template-columns: auto 50px 120px 50px 100px auto 50px;
  column-gap: 6px;
  align-items: flex-end;
  margin-bottom: 12px;
}

.evTrKC > * {
  margin: 0;
}

.ONqJD {
  align-self: center;
  color: rgb(118, 127, 130);
  padding-top: 24px;
}

.fJfBtJ {
  cursor: pointer;
  align-self: center;
  font-size: 14px;
  display: inline-block;
  color: rgb(24, 24, 24);
}

.jEVlcv {
  padding-top: 19px;
}

.kXPMMB {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.evTrKC .Label-eJXSuD {
  white-space: nowrap;
}

.gZKasB {
  display: block;
  color: rgb(24, 24, 24);
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 5px;
}

.eYnYJY {
  height: 1.3em;
  width: auto;
  margin-bottom: 6px;
}

.bsHxqg.bsHxqg.bsHxqg {
  display: block;
  position: relative;
  font-size: 12px;
}

.cCETsh.cCETsh {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 38px;
  padding: 0px 12px;
  margin: 0px;
  color: rgb(24, 24, 24);
  background: rgb(255, 255, 255);
  border: 1px solid rgb(226, 230, 235);
  border-radius: 6px;
  outline: 0px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  box-sizing: border-box;
}

.erHrFZ {
  position: relative;
}

.css-2b097c-container {
  position: relative;
  box-sizing: border-box;
}

.kvYiAT .Select__control {
  padding: 0px 0px 0px 12px;
  color: rgb(24, 24, 24);
  background: rgb(255, 255, 255);
  border: 1px solid rgb(226, 230, 235);
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
  height: 38px !important;
  overflow: visible !important;
}

.css-yk16xz-control {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  outline: 0px !important;
}

.css-1hwfws3 {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex: 1 1 0%;
  flex-wrap: wrap;
  padding: 2px 8px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.kvYiAT .Select__value-container {
  padding: 0px;
  height: 38px !important;
}

.css-1wy0on6 {
  -webkit-box-align: center;
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
}

.css-1okebmr-indicatorSeparator {
  align-self: stretch;
  background-color: rgb(204, 204, 204);
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
}

.kvYiAT .Select__indicator-separator {
  display: none;
}

.css-tlfecz-indicatorContainer {
  color: rgb(204, 204, 204);
  display: flex;
  padding: 8px;
  transition: color 150ms ease 0s;
  box-sizing: border-box;
}

.css-19bqh2r {
  display: inline-block;
  fill: currentcolor;
  line-height: 1;
  stroke: currentcolor;
  stroke-width: 0;
}

.css-1uccc91-singleValue {
  color: rgb(51, 51, 51);
  margin-left: 2px;
  margin-right: 2px;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
}

.kvYiAT .Select__single-value {
  overflow: visible;
  color: rgb(24, 24, 24);
}

.css-1ogwcvc {
  margin: 0px;
  padding: 0px;
  height: 38px !important;
}

.ONqJD {
  align-self: center;
  color: rgb(118, 127, 130);
  padding-top: 24px;
}

.bsHxqg.bsHxqg.bsHxqg {
  display: block;
  position: relative;
  font-size: 12px;
}

.evTrKC .Label-eJXSuD {
  white-space: nowrap;
}

.bsHxqg.bsHxqg.bsHxqg > .I-gmFDEA {
  position: absolute;
  height: 38px;
  width: 38px;
  color: rgb(187, 192, 193);
  font-size: 1.25em;
  font-style: normal;
  line-height: 38px;
  text-align: center;
  pointer-events: none;
}

.bsHxqg.bsHxqg.bsHxqg > .I-gmFDEA + .Input-icnXNI {
  padding-left: 38px;
}

.xfmMQ .v-select.v-input--dense .v-select__selection--comma {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
}

.xfmMQ fieldset {
  color: rgb(226, 230, 235) !important;
  border: 1px solid rgb(226, 230, 235);
}

.xfmMQ input[type=text]:hover:not(:focus) {
  border-color: #CDCDCD;
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.8);
  -moz-box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.8);
  box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.8);
}

.cCETsh.cCETsh:hover {
  border-color: rgb(191, 197, 203);
}

.pricing-structure-example-price fieldset {
  color: rgb(226, 230, 235) !important;
  border: 1px solid rgb(226, 230, 235);
}

.pricing-structure-example-price:hover {
  border-color: rgb(191, 197, 203) !important;
}

.esebmt {
  display: grid;
  column-gap: 6px;
  align-items: flex-end;
  grid-template-columns: auto 65px 90px 100px;
  margin-bottom: 0px;
  margin-top: 12px;
}

.esebmt .Label-eJXSuD {
  white-space: nowrap;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
