import {computed} from 'vue';
import {useStore} from 'vuex';
import {EnvelopeAttachmentInterface, EnvelopeInterface, RemoveAttachmentPayload} from '@/store/modules/envelope';


export function useESign() {
    const store = useStore();

    const template = computed(() => store.getters['template/template']);

    const envelope = computed<EnvelopeInterface>(() => store.getters['envelope/envelope']);

    const envelopeAttachments = computed<EnvelopeAttachmentInterface[]>(() => envelope.value?.attachments);


    function getTemplate(uuid: string) {
        store.dispatch('template/getTemplate', uuid);
    }

    function updateTemplate(data: { actions: any, template: object }) {
        return store.dispatch('document/updateTemplate', data);
    }

    const removeAttachment = async (data: RemoveAttachmentPayload) => {
        return await store.dispatch('envelope/removeAttachment', data);
    }

    return {
        template,
        envelope,
        envelopeAttachments,
        removeAttachment,
        updateTemplate,
        getTemplate,
    }
}