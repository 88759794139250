import axios from 'axios';
import Form from 'vform';
import {EventBus} from '@/plugins/eventBus';
import * as types from '../mutation-types';
import {push} from 'notivue';

export interface TwilioState {
    loading: Record<string, boolean>;
    form: Form;
    verifyCode: string;
    codeSentSuccess: boolean;
    codeCheckSuccess: boolean;
}

const state: TwilioState = {
    loading: {
        start_verify: false,
        check_verify: false,
    },

    form: new Form(),
    verifyCode: '',
    codeSentSuccess: false,
    codeCheckSuccess: false,
};

const getters = {
    loading: state => state.loading,
    verifyCode: state => state.verifyCode,
    codeSentSuccess: state => state.codeSentSuccess,
    codeCheckSuccess: state => state.codeCheckSuccess,
    checkBtnReady: state => state.verifyCode.length === 6,
};

const mutations = {
    [types.SET_TWILIO_LOADING](state, loading) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_VERIFY_CODE](state, code) {
        state.verifyCode = code;
    },
};

const actions = {

    startTwilioVerify({commit, rootGetters}, stripe_id) {
        commit('SET_TWILIO_LOADING', {subject: 'start_verify', loading: true});
        axios.post(`/v1/company/${rootGetters['company/company'].uuid}/start-twilio-verify`, {
            stripe_id,
        })
            .then(() => {
                state.codeSentSuccess = true;

                push.success({
                    message: 'SMS code sent',
                });
            }).catch((e) => {
            state.form.errors.set(e.response.data.errors);
        }).finally(() => {
            commit('SET_TWILIO_LOADING', {subject: 'start_verify', loading: false});
        });
    },

    checkTwilioVerify({state, commit, rootGetters}, stripe_id) {
        commit('SET_TWILIO_LOADING', {subject: 'check_verify', loading: true});
        axios.post(`/v1/company/${rootGetters['company/company'].uuid}/check-twilio-verify`, {
            stripe_id,
            code: state.verifyCode,
        })
            .then(() => {
                state.codeCheckSuccess = true;
                EventBus.$emit('success-message-text', 'SMS Verified');
            }).catch((e) => {
            push.error({
                message: 'Code incorrect',
            });
        }).finally(() => {
            commit('SET_TWILIO_LOADING', {subject: 'check_verify', loading: false});
        });
    },

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
