import Cookies from 'js-cookie';
import {MasqueradeData} from '@/types';

const TokenKey: string = 'token';
const RegisterTokenKey: string = 'register_id';
const MasqueradeKey: string = 'masquerade';


export function getToken(): string | undefined {
    return Cookies.get(TokenKey);
}

/**
 * Sets the authentication token.
 * @param token - The token string.
 * @param remember - Whether the token should persist across sessions.
 */
export function setToken(token: string, remember: boolean = true): void {
    const options = remember ? {expires: 365} : undefined;
    Cookies.set(TokenKey, token, options);
}

export function removeToken(): void {
    Cookies.remove(TokenKey);
}


/**
 * Retrieves the register ID token from cookies.
 */
export function getRegisterId(): string | undefined {
    return Cookies.get(RegisterTokenKey);
}


export function checkMasquerade(): MasqueradeData | undefined {
    const masqueradeCookie = Cookies.get(MasqueradeKey);
    if (!masqueradeCookie) return undefined;

    try {
        return JSON.parse(masqueradeCookie) as MasqueradeData;
    } catch (error) {
        console.error('Error parsing masquerade cookie:', error);
        return undefined;
    }
}

export function setMasquerade(masquerade: MasqueradeData): Promise<void> {
    return new Promise((resolve) => {
        const masqueradeCookie = {
            on: true,
            admin: {
                email: masquerade.admin.email,
                uuid: masquerade.admin.uuid,
                token: getToken(),
            },
            user: {
                email: masquerade.user.email,
                uuid: masquerade.user.uuid,
                token: masquerade.token,
            },
        };

        Cookies.set(MasqueradeKey, JSON.stringify(masqueradeCookie), {expires: 2});

        resolve();
    });
}

export function removeMasquerade(): void {
    const currentMasquerade: MasqueradeData = checkMasquerade();
    if (currentMasquerade) {
        setToken(currentMasquerade.admin.token);
    }
    Cookies.remove(MasqueradeKey);
}

export function setRegisterToken(token: string): void {
    Cookies.set(RegisterTokenKey, token, {expires: 365});
}

export function removeAllUserCookies(): void {
    Cookies.remove(TokenKey);
    Cookies.remove(RegisterTokenKey);
    Cookies.remove('fleetwireio_session');
}
