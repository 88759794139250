import {arrayWrap} from './util';

export default class Errors {
    errors: Record<string, string[]>;

    /**
     * Create a new error bag instance.
     */
    constructor() {
        this.errors = {};
    }

    /**
     * Set the errors object or field error messages.
     *
     * @param {Object|String} field
     * @param {Array|String|undefined} messages
     */
    set(field: string | Record<string, string[]>, messages: string[] | string | undefined = undefined): void {
        if (typeof field === 'object') {
            this.errors = field;
        } else {
            this.set({...this.errors, [field]: arrayWrap(messages)});
        }
    }

    /**
     * Get all the errors.
     *
     * @return {Object}
     */
    all(): Record<string, string[]> {
        return this.errors;
    }

    /**
     * Determine if there is an error for the given field.
     *
     * @param  {String} field
     * @return {Boolean}
     */
    has(field: string): boolean {
        return this.errors.hasOwnProperty(field);
    }

    /**
     * Determine if there are any errors for the given fields.
     *
     * @param  {...String} fields
     * @return {Boolean}
     */
    hasAny(...fields: string[]): boolean {
        return fields.some(field => this.has(field));
    }

    /**
     * Determine if there are any errors.
     *
     * @return {Boolean}
     */
    any(): boolean {
        return Object.keys(this.errors).length > 0;
    }

    /**
     * Get the first error message for the given field.
     *
     * @param  {String} field
     * @return {String|undefined}
     */
    get(field: string): string | undefined {
        if (this.has(field)) {
            return this.getAll(field)[0];
        }
    }

    /**
     * Get all the error messages for the given field.
     *
     * @param  {String} field
     * @return {Array}
     */
    getAll(field: string): string[] {
        return arrayWrap(this.errors[field] || []);
    }

    /**
     * Get the error message for the given fields.
     *
     * @param  {...String} fields
     * @return {Array}
     */
    only(...fields: string[]): string[] {
        const messages = [];

        fields.forEach(field => {
            const message = this.get(field);

            if (message) {
                messages.push(message);
            }
        });

        return messages;
    }

    /**
     * Get all the errors in a flat array.
     *
     * @return {Array}
     */
    flatten(): string[] {
        return Object.values(this.errors).reduce((a, b) => a.concat(b), []);
    }

    /**
     * Clear one or all error fields.
     *
     * @param {String|undefined} field
     */
    clear(field: string | undefined = undefined): void {
        const errors: Record<string, string[]> = {};
        
        if (field) {
            Object.keys(this.errors).forEach(key => {
                if (key !== field) {
                    errors[key] = this.errors[key];
                }
            });
        }

        this.set(errors);
    }
}
