import {each, find, findIndex, get, isNull, isUndefined, lowerCase} from 'lodash';
import dayjs from 'dayjs';
import {mapGetters} from 'vuex';
import {formatNumberWithCommas} from '@/helpers';
import {SubscriptionPlan} from "@/types";

export default {

    data() {
        return {
            allowCreditCardInput: true,
            subscriptionFeatures: {
                seats: {
                    basic: 2,
                    standard: 5,
                    premium: 20,
                },
                listings: {
                    basic: 5,
                    standard: 50,
                    premium: 150,
                },
                locations: {
                    basic: 1,
                    standard: 2,
                    premium: 2,
                },
                carSharingConnections: {
                    basic: 1,
                    standard: 5,
                    premium: 10,
                },
                smartCarConnections: {
                    basic: 1,
                    standard: 5,
                    premium: 10,
                },
                orders: {
                    basic: 5,
                    standard: 50,
                    premium: 20,
                },
            },

            planIncludes: {
                premium: {
                    api: true,
                    sso: true,
                    coupons: true,
                    additional_scripts: true,
                    custom_domain: true,
                    custom_tos: true,
                    listing_exports: true,
                    listing_imports: true,
                    esign: true,
                    webhooks: true,
                    barcodes: true,
                    custom_checkout_fields: true,
                },
                standard: {
                    sso: true,
                    coupons: true,
                    custom_tos: true,
                    listing_exports: true,
                    listing_imports: true,
                    esign: true,
                    barcodes: true,
                    custom_checkout_fields: true,
                },
                basic: {
                    // esign: true,
                },
            },
        };
    },

    watch: {
        subLoading() {
            this.$store.commit('subscriptions/UPDATE_NEW_SUBSCRIPTION', {
                plan: lowerCase(this.subscriptionPlan),
                billingPeriod: lowerCase(this.subscriptionDuration),
            });
        },
    },

    computed: {
        ...mapGetters({
            user: 'auth/user',
            enforceSubscription: 'subscriptions/enforceSubscription',
            subscriptionsLoading: 'subscriptions/loading',
            plans: 'subscriptions/plans',
            subscription: 'subscriptions/subscription',
            hasActiveSubscription: 'subscriptions/hasActiveSubscription',
            subscriptionPlan: 'subscriptions/subscriptionPlan',
            subscriptionDuration: 'subscriptions/subscriptionDuration',
            subscriptionEndDate: 'subscriptions/subscriptionEndDate',
            company: 'company/company',
            allListings: 'listing/allListings',
            companySeatCount: 'company/companySeatCount',
            subscriptionForm: 'subscriptions/subscriptionForm',
            extraListingsAddonCount: 'subscriptions/extraListingsAddonCount',
            stripeSubscriptionObject: 'stripe/stripeSubscriptionObject',
        }),
        currentStripeSubscriptionObjectRenewalDate(): string | null {
            const date = this.stripeSubscriptionObject?.current_period_end;

            if (!date) return null;

            return dayjs(date * 1000).format('MMMM D, YYYY');
        },
        currentStripeSubscriptionObjectPlanAmount(): string {
            const amount = this.stripeSubscriptionObject?.plan?.amount;

            if (!amount) return '$0';

            if (amount % 100 === 0) {
                return `$${(amount * 0.01).toFixed(0)}`;
            }

            return `$${(amount * 0.01).toFixed(2)}`;
        },

        currentStripeSubscriptionObjectPlanInterval(): string | null {
            return this.stripeSubscriptionObject?.plan?.interval;
        },
        currentPlanDetails() {
            return find(this.plans, (p) => p.name === this.subscriptionPlan);
        },
        currentPlanBullets() {
            return get(this.currentPlanDetails, 'description.bullets');
        },
        subLoading() {
            return this.subscriptionsLoading.subscription;
        },
        plan() {
            return lowerCase(this.subscriptionPlan);
        },
        inTrial(): boolean {
            const trialDate = !!get(this.user, 'on_trial');

            if (trialDate) return true;

            const stripeTrialDate = get(this.subscription, 'trial_ends_at');

            if (stripeTrialDate) {
                return dayjs(stripeTrialDate).diff(dayjs()) > 0;
            }

            return false;
        },
        subscriptionStatus() {
            return this.subscription?.stripe_status;
        },
        inGracePeriod(): boolean {
            const endDate = get(this.subscription, 'ends_at');
            const status = this.subscriptionStatus

            return !(status === 'canceled' && dayjs(endDate).diff(dayjs()) <= 0);
        },
        monthlyPrice() {
            const plans: SubscriptionPlan[] = this.plans;

            const planIndex = findIndex(plans, (p) => this.newSubscription?.plan === p.name);
            if (planIndex === -1) return 0;
            if (isUndefined(planIndex)) return 0;
            return this.plans[planIndex].pricing[this.newSubscription.billingPeriod].price;
        },
        billingPricing() {
            const months = this.newSubscription?.billingPeriod === 'yearly' ? 12 : 1;
            const totalBilled = this.monthlyPrice * months;
            return formatNumberWithCommas(totalBilled);
        },
        listingLimitReached() {
            if (!this.enforceSubscription) return false;

            if (this.inTrial && !this.hasActiveSubscription) {
                return false;
            }

            if (!this.hasActiveSubscription) {
                return true;
            }
            const limit = this.subscriptionFeatures.listings[this.plan]
                + this.extraListingsAddonCount;

            return (limit !== -1)
                && this.allListings.length >= limit;
        },
        listingLimitReachedMessage() {
            if (isNull(this.subscriptionPlan)) return 'You need a subscription plan to add listings.';
            return `You've reached your max listings of <b>${this.subscriptionFeatures.listings[this.plan]}</b> for your plan.`;
        },

        planIncludesESign() {
            if (!this.enforceSubscription) return true;

            if (this.inTrial && !this.hasActiveSubscription) {
                return true;
            }

            if (!this.hasActiveSubscription) {
                return false;
            }

            return get(this.planIncludes, `${this.plan}.esign`);
        },

        planIncludesApi() {
            if (!this.enforceSubscription) return true;

            if (this.inTrial && !this.hasActiveSubscription) {
                return true;
            }

            if (!this.hasActiveSubscription) {
                return false;
            }

            return get(this.planIncludes, `${this.plan}.api`);
        },

        carShareConnectionsLimitReached() {
            if (!this.enforceSubscription) return false;

            if (this.inTrial && !this.hasActiveSubscription) {
                return false;
            }

            if (!this.hasActiveSubscription) {
                return true;
            }

            let connections = 0;
            each(get(this.company, 'connections.turo'), () => {
                connections++;
            });
            each(get(this.company, 'connections.getaround'), () => {
                connections++;
            });
            each(get(this.company, 'connections.ryde'), () => {
                connections++;
            });

            return (this.subscriptionFeatures.listings[this.plan] !== -1)
                && connections >= this.subscriptionFeatures.carSharingConnections[this.plan];
        },
        smartCarConnectionsLimitReached() {
            if (!this.enforceSubscription) return false;

            if (this.inTrial && !this.hasActiveSubscription) return false;

            if (!this.hasActiveSubscription) return true;

            let connections = 0;
            each(get(this.company, 'connections.tesla'), () => {
                connections++;
            });
            each(get(this.company, 'connections.bouncie'), () => {
                connections++;
            });

            return (this.subscriptionFeatures.smartCarConnections[this.plan] !== -1)
                && connections >= this.subscriptionFeatures.smartCarConnections[this.plan];
        },
        carShareConnectionsLimitReachedMessage() {
            if (isNull(this.subscriptionPlan)) return 'You need a subscription plan to add a connection.';
            return `You've reached your max car-sharing connection of <b>${this.subscriptionFeatures.carSharingConnections[this.plan]}</b> for your plan.`;
        },
        seatLimitReachedMessage() {
            if (isNull(this.subscriptionPlan)) return 'You need a subscription plan to add seats.';
            return `You've reached your max seats of <b>${this.subscriptionFeatures.seats[this.plan]}</b> for your plan.`;
        },
    },

    methods: {
        includedInSubscription(topic) {
            if (!this.enforceSubscription) return true;

            if (this.inTrial) return true;

            if (!this.hasActiveSubscription) return false;

            if (isUndefined(this.planIncludes[this.plan])) return false;
            if (isUndefined(this.planIncludes[this.plan][topic])) return false;
            return this.planIncludes[this.plan][topic];
        },
    },
};
