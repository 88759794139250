<template>
  <section class="bg-gray-50 dark:bg-gray-900 bg-fixed">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white" href="/">
        <img alt="logo" class="w-8 h-8 mr-2" src="https://tenant.fleetwire.io/images/logo/fleetwire-logo-new.png">
        Fleetwire
      </a>
      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div class="p-6 space-y-3 md:space-y-4 sm:p-8">
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Start a free 14-day trial
          </h1>
          <v-form :disabled="form.busy" class="space-y-2 md:space-y-3" @keydown="form.onKeydown($event)" @submit.prevent="register">
            <div>
              <v-text-field
                v-model="form.company_name"
                :autofocus=true
                :error-messages="form.errors.get('company_name')"
                :label="$t('company_name')"
                density="comfortable"
                name="company_name"
                required
                variant="outlined"
              />
            </div>
            <div>
              <v-text-field
                v-model="form.email"
                :error-messages="form.errors.get('email')"
                :label="$t('company_email')"
                :rules="registrationFormRules.email"
                density="comfortable"
                name="email"
                required
                variant="outlined"
              />
            </div>
            <div>
              <v-text-field
                v-model="form.password"
                :error-messages="form.errors.get('password')"
                :label="$t('password')"
                :rules="[registrationFormRules.required, registrationFormRules.min]"
                autocomplete="new-password"
                density="comfortable"
                name="password"
                type="password"
                variant="outlined"
              />
            </div>

            <div class="flex items-start">
              <div class="flex items-center h-5">
                <input id="fw-agree-checkbox" v-model="form.agree" :error-messages="form.errors.get('agree')" aria-describedby="terms" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" name="agree" required type="checkbox">
              </div>
              <div class="ml-3 text-sm">
                <label class="font-light text-gray-500 dark:text-gray-300" for="fw-agree-checkbox">I accept the
                  <a v-tippy class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                     content="Opens in a new window"
                     href="https://fleetwire.io/terms?utm_source=register_page&utm_medium=web&utm_campaign=signup&utm_content=terms_link"
                     target="_blank"
                     @click.stop
                  >{{ $t('terms_of_service') }}</a>
                  &
                  <a v-tippy class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                     content="Opens in a new window"
                     href="https://fleetwire.io/privacy-policy?utm_source=register_page&utm_medium=web&utm_campaign=signup&utm_content=privacy_link"
                     target="_blank"
                     @click.stop
                  >Privacy Policy</a>
                </label>
              </div>
            </div>
            <v-btn id="fw-register-button" :disabled="isButtonDisabled" :loading="form.busy" block class="mt-5" size="large" type="submit">
              {{ $t('register') }}
            </v-btn>

            <p class="mt-5 text-sm font-light text-gray-500 dark:text-gray-400">
              Already have an account?
              <router-link :to="{ name: 'login' }" class="font-medium text-primary-600 hover:underline dark:text-primary-500">
                <b>{{ $t('login') }} here</b>
              </router-link>
            </p>
          </v-form>
        </div>
      </div>

      <div class="mt-5 text-center">
        <div class="p-1 bg-gray-100 border font-medium text-xs text-gray-600 mb-14 mx-4 line rounded-md" style=" border: 1px solid rgb(224, 228, 232);background: rgb(224, 228, 232);">
          It only takes a few seconds, no credit card required!
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import {computed, onMounted, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {useAuth} from '@/composables/useAuth';
import Form from 'vform';

const route = useRoute();
const router = useRouter();
const store = useStore();
const {registrationFormRules} = useAuth();

const form = ref(new Form({
  email: '',
  password: '',
  company_name: '',
  agree: false,
}));

const isFormValid = computed(
  () => ['email', 'password', 'company_name'].every((field) => form.value[field]?.length) && form.value.agree
);

const isButtonDisabled = computed(() => form.value.busy || !isFormValid.value);

onMounted(() => {
  form.value.email = (route.query?.email as string) || '';
  form.value.company_name = (route.query?.company as string) || '';
});

const saveRegisterId = async (id: string) => {
  try {
    await store.dispatch('auth/saveRegisterId', id);
  } catch (error) {
    console.error('Failed to save registration ID:', error);
  }
};

const register = async () => {
  if (!isFormValid.value) return;

  // Register the user.
  try {
    const {data} = await form.value.post('/v1/register');
    await saveRegisterId(data.register_id);

    // this.$gtag.event('Register', { email: form.value.email });
    // Log success and redirect
    console.info('User registered successfully', data);
    router.push({name: 'company.next-step'}).catch((err) => console.error(err));
  } catch (e) {
    console.error('Registration failed:', e);
  }
};
</script>
