<template>
  <div>
    <v-card>
      <v-card-title class="py-4">
        <div class="flex items-center">
          <h1 class="text-xl font-semibold">Branches</h1>
          <v-spacer/>
          <v-btn
            :to="{name: 'settings.fleet.branches.new'}"
            class="text-decoration-none"
            color="primary"
            size="small"
          >
            Add Branch
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <p class="text-sm text-gray-600 mb-6">
          Create and manage multiple fleet locations to organize your listings, extras, and pickup/return locations.
        </p>
        <div class="overflow-x-auto rounded-lg border border-gray-200">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th class="px-6 py-4 whitespace-nowrap" scope="col">#</th>
              <th class="px-6 py-4 whitespace-nowrap" scope="col">Name</th>
              <th class="px-6 py-4 whitespace-nowrap" scope="col">Branch Location</th>
              <th class="px-6 py-4 whitespace-nowrap" scope="col">Default Pickup Location</th>
              <th class="px-6 py-4 whitespace-nowrap" scope="col">Default Return Location</th>
            </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
            <tr v-for="(branch, index) in branches" :key="branch.uuid" class="hover:bg-gray-50">
              <td class="px-6 py-4 whitespace-nowrap">{{ index + 1 }}</td>
              <th class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" scope="row">
                <router-link :to="{name: 'settings.fleet.branches.single', params: {uuid: branch.uuid}}" class="hover:underline">
                  {{ branch.name }}
                </router-link>
              </th>
              <td class="px-6 py-4 whitespace-nowrap">
                <span v-if="branchLocationName(branch)" v-tippy="branchLocationName(branch)">
                  {{ branchLocationLine1(branch) }}
                </span>
                <span v-else>
                  {{ branch.pickup_location }}
                </span>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">{{ branch.pickup_location }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ branch.return_location }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts" setup>
import {onMounted} from 'vue';
import {TenantBranch} from '@/types';
import {useBranch} from '@/composables/useBranch';

const {branches, getFleetBranches} = useBranch();

const branchLocationName = (branch: TenantBranch) => {
  return branch?.location?.name;
};

const branchLocationLine1 = (branch: TenantBranch) => {
  return branch?.location?.address_line1;
};

onMounted(() => {
  getFleetBranches();
});
</script>

