<template>
  <!-- Review & Confirm -->
  <v-card class="mt-5" style="border: 1px solid rgb(224, 220, 229);" variant="flat">
    <v-card-title :class="{'cursor-pointer' : subscriptionStep < 2}" class="py-4" @click="subscriptionStep = 2">
      <div class="flex align-center">
        <span class="text-sm md:text-base">3. Review & Confirm</span>
        <v-spacer/>
        <v-btn
          v-if="subscriptionStep !== 2"
          icon="mdi-chevron-down"
          size="small"
          variant="text"
        />
      </div>
    </v-card-title>

    <div v-show="subscriptionStep === 2">
      <v-card :disabled="stripeLoading.fetchPortalUrl" :loading="stripeLoading.fetchPortalUrl">
        <v-card-text class="pa-4">
          <p class="text-body-2 text-sm-md">
            By continuing, you agree to our
            <a
              class="underline text-primary"
              href="https://fleetwire.io/terms/?utm_source=subscription-checkout&utm_medium=subscription-page&utm_campaign=subscription-review"
              rel="noopener noreferrer"
              target="_blank"
              @click.stop
            >
              Terms of Service
            </a>
            and
            <a
              class="underline text-primary"
              href="https://fleetwire.io/privacy-policy/?utm_source=subscription-checkout&utm_medium=subscription-page&utm_campaign=subscription-review"
              rel="noopener noreferrer"
              target="_blank"
              @click.stop
            >
              Privacy Policy
            </a>.
          </p>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer/>
          <v-btn
            :disabled="paymentsLoading.cardBtnLoading"
            :to="{ name: 'settings.account' }"
            class="mr-2"
            size="small"
            variant="text"
          >
            Back
          </v-btn>
          <v-btn
            v-if="hasActiveSubscription"
            :disabled="subscriptionsLoading.subscribing"
            :loading="subscriptionsLoading.subscribing"
            color="primary"
            size="small"
            @click="switchPlanInit()"
          >
            Swap Plans
          </v-btn>
          <v-btn
            v-if="!hasActiveSubscription"
            :loading="stripeLoading.fetchPortalUrl"
            class="ml-2"
            size="small"
            variant="text"
            @click="openStripeCheckoutUrl()"
          >
            Checkout on Stripe
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-card>
</template>

<script lang="ts" setup>
import {useSubscription} from '@/composables/useSubscription';
import {usePayments} from '@/composables/usePayments';
import {useStripe} from '@/composables/useStripe';
import {useSubscriptionManager} from '@/composables/useSubscriptionManager';


const {
  hasActiveSubscription,
  subscriptionsLoading,
} = useSubscription();

const {paymentsLoading} = usePayments()

const {stripeLoading} = useStripe();
const {
  subscriptionStep,
  openStripeCheckoutUrl,
  switchPlanInit,
} = useSubscriptionManager();

</script>