<template>
  <v-card color="transparent" variant="flat">
    <div class="subscription__card--summary-card">
      <div class="subscription__card--summary-card-title">
        <div class="font-extrabold text-lg sm:text-xl">{{ planSelectionNameUpper }} Plan</div>
        <div class="subscription__card--summary-card-pricing flex items-baseline">
          <div class="text-2xl sm:text-3xl">$</div>
          <div class="text-3xl sm:text-4xl">{{ planSelectionPrice }}</div>
          <div class="text-lg sm:text-xl ml-1">/mo</div>
        </div>
      </div>

      <div class="subscription__card--summary-card-details">
        <div class="subscription__card--summary-card-details-row1 flex justify-between items-center py-2" data-test-id="plan">
          <div class="text-sm sm:text-base">
            <div class="text-gray-600">Plan Type</div>
            {{ planSelectionNameUpper }}
          </div>
          <div class="text-sm sm:text-base">
            <div class="text-right">${{ planSelectionAnnualPrice }}
              <span v-if="updatedSubscription.interval === 'monthly'">/mo</span>
              <span v-else>/yr</span>
            </div>
          </div>
        </div>
      </div>

      <div class="subscription__card--bullets space-y-3 mt-4">
        <template v-for="bullet in planSelectionObject.description.bullets" :key="bullet">
          <div v-if="bullet !== 'And More*'" class="subscription__card--bullet flex items-start">
            <div class="badge badge-rounded-circle badge-success-soft mt-1 mr-3">
              <v-icon color="success" size="x-small">mdi-check</v-icon>
            </div>
            <p class="text-sm sm:text-base">
              {{ bullet }}
            </p>
          </div>
        </template>

        <div class="subscription__card--bullet flex items-start">
          <div class="badge badge-rounded-circle badge-success-soft mt-1 mr-3">
            <v-icon color="success" size="x-small">mdi-check</v-icon>
          </div>
          <p class="text-sm sm:text-base">
            <a
              class="text-primary hover:underline"
              href="https://fleetwire.io/pricing/?utm_source=subscription-checkout&utm_medium=subscription-page&utm_campaign=subscription-review"
              rel="noopener noreferrer"
              target="_blank"
              @click.stop
            >
              And more...
            </a>
          </p>
        </div>
      </div>
    </div>

    <div class="flex bg-white border rounded p-3 mt-4 text-sm sm:text-base">
      <span class="flex-grow">Have a question?</span>
      <span><a class="text-primary hover:underline" href="https://help.fleetwire.io/en/" target="_blank">Find an answer</a></span>
    </div>

    <div v-if="hasActiveSubscription" class="text-center my-4 sm:my-7">
      <v-dialog class="mx-2" max-width="600">
        <template v-slot:activator="{ props: cancelSubscriptionDialogProps }">
          <v-btn class="text-sm sm:text-base" size="small" v-bind="cancelSubscriptionDialogProps" variant="tonal">Cancel
            Subscription
          </v-btn>
        </template>

        <template v-slot:default="{ isActive }">
          <v-card class="mx-2" title="Cancel Subscription">
            <v-divider opacity="50"/>
            <v-card-text class="px-4 sm:px-6">
              <div class="font-bold text-base sm:text-lg">Are you sure you want to cancel your {{ subscriptionPlan }}
                subscription?
              </div>

              <div class="mt-4 sm:mt-5 text-sm sm:text-base">By cancelling, you will lose access to:</div>
              <div class="ml-4 sm:ml-10 mt-3 sm:mt-5">
                <ul class="list-disc text-sm sm:text-base" v-html="subscriptionPlanFeatures"></ul>
              </div>

              <div class="mt-6 sm:mt-10 font-bold text-base sm:text-lg">We value your membership and want to make sure
                you're getting the most out of it.
              </div>

              <div class="mt-3 sm:mt-5 text-sm sm:text-base">Before you go, here are some options you might consider:
              </div>
              <div class="ml-4 sm:ml-10 mt-3 sm:mt-5">
                <ul class="list-disc text-sm sm:text-base">
                  <li><span class="font-bold">Speak with Support:</span> Let us know if there's anything we can do to
                    improve your experience.
                  </li>
                  <li><span class="font-bold">Pause Subscription:</span> If you need a break, consider pausing your
                    subscription instead of cancelling.
                  </li>
                  <li><span class="font-bold">Offer Feedback:</span> Share your reasons for cancelling so we can work on
                    making our service better.
                  </li>
                </ul>
              </div>

              <div class="mt-6 sm:mt-10 font-bold text-base sm:text-lg">Would you like to continue with the
                cancellation, or would you prefer to explore these options?
              </div>
            </v-card-text>

            <v-divider opacity="50"/>
            <v-card-actions class="px-4 sm:px-6 py-3">
              <v-btn class="text-sm sm:text-base" size="small" @click="isActive.value = false">nevermind</v-btn>
              <v-spacer/>
              <v-btn class="text-sm sm:text-base" size="small" @click="isActive.value = false; cancelSubscriptionCommit()">
                End Subscription
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </div>

    <div class="font-light text-xs sm:text-sm mb-6 sm:mb-10 my-4 sm:my-7">
      Annual subscriptions require a one-year non-cancellable commitment.
      By using Fleetwire you agree to our
      <a
        class="underline text-primary hover:text-primary-dark"
        href="https://fleetwire.io/terms/?utm_source=subscription-checkout&utm_medium=subscription-page&utm_campaign=subscription-review"
        rel="noopener noreferrer"
        target="_blank"
      >
        Terms of Service
      </a>.
    </div>
  </v-card>
</template>

<script lang="ts" setup>
import {computed} from 'vue';
import {useSubscription} from '@/composables/useSubscription';
import {useSubscriptionManager} from '@/composables/useSubscriptionManager';
import {SubscriptionPlan} from '@/types';


const {
  subscriptionPlan,
  subscriptionPlans,
  hasActiveSubscription,
  cancelSubscriptionStripePortal,
} = useSubscription();


const {planSelection, plansOrdered, planPricing, planSelectionName, updatedSubscription} = useSubscriptionManager();


const planSelectionObject = computed(() => plansOrdered.value[planSelection.value]);
const planSelectionNameUpper = computed(() => planSelectionName.value.charAt(0).toUpperCase() + planSelectionName.value.slice(1));
const planSelectionPrice = computed(() => planPricing[updatedSubscription.value.interval][planSelectionName.value]);
const planSelectionAnnualPrice = computed(() => updatedSubscription.value.interval === 'monthly' ? planSelectionPrice.value : planSelectionPrice.value * 12);
const subscriptionPlanIndex = computed(() => subscriptionPlans.value.findIndex((p: SubscriptionPlan) => p.name === subscriptionPlan.value));
const subscriptionPlanFeatures = computed(() => {
  return plansOrdered.value[subscriptionPlanIndex.value]?.description?.bullets?.map((bullet: string) => {
    return `<li>${bullet}</li>`;
  }).join('');
});

const cancelSubscriptionCommit = async () => {
  await cancelSubscriptionStripePortal().then((responseData) => {
    console.dir('Subscription cancelled');
    console.dir(responseData);
    // router.push({name: 'settings.account'}).catch();

    window.open(responseData.url, '_blank');
  });
};
</script>

