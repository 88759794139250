import axios from 'axios';
import {uniqBy} from 'lodash';
import * as types from '../mutation-types';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {Pagination, WhatsNew, WhatsNewFeedback} from '@/types';


export interface WhatsNewState {
    loading: Record<string, boolean>,
    count: number,
    nextWhatsNew: number,
    nextWhatsLast: number,
    whatsNew: WhatsNew[],
    images: any[],
    openSidebar: boolean,
}


const state: WhatsNewState = {
    loading: {
        sendFeedback: false,
        count: false,
        get: false,
        images: false,
        update: false,
        seen: false,
    },
    openSidebar: false,
    count: 0,
    nextWhatsNew: 1,
    nextWhatsLast: 1,
    whatsNew: [],
    images: [],
};

const getters = {
    loading: (state: WhatsNewState) => state.loading,
    count: (state: WhatsNewState) => state.count,
    images: (state: WhatsNewState) => state.images,
    whatsNew: (state: WhatsNewState) => state.whatsNew,
    nextWhatsNew: (state: WhatsNewState) => state.nextWhatsNew,
    nextWhatsLast: (state: WhatsNewState) => state.nextWhatsLast,
    openSidebar: (state: WhatsNewState) => state.openSidebar,
};

const mutations = {
    [types.SET_WHATS_NEW_LOADING](state: WhatsNewState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_WHATS_NEW_COUNT](state: WhatsNewState, count: number) {
        state.count = count;
    },
    [types.SET_SHOW_WHATS_NEW_SIDEBAR](state: WhatsNewState, isShown: boolean = false) {
        state.openSidebar = isShown;

        setTimeout(() => {
            state.openSidebar = false;
        }, 300);
    },
    [types.SET_WHATS_NEW_IMAGES](state: WhatsNewState, images: any[]) {
        state.images = images;
    },
    [types.SET_WHATS_NEW](state: WhatsNewState, whatsNewPaginateObject: Pagination) {
        const data: WhatsNew[] = whatsNewPaginateObject.data || [];

        state.whatsNew = uniqBy([...state.whatsNew, ...data], (w) => w.uuid);
        state.nextWhatsNew = (whatsNewPaginateObject.current_page || 0) + 1;
        state.nextWhatsLast = whatsNewPaginateObject.last_page || 1;
    },
    [types.MERGE_WHATS_NEW](state: WhatsNewState, whatsNew: WhatsNew) {
        if (whatsNew.uuid) {
            const wnIndex = state.whatsNew.findIndex(w => w.uuid === whatsNew.uuid);
            if (wnIndex >= 0) {
                state.whatsNew[wnIndex] = whatsNew;
            }
        }
    },
};

const actions = {

    /**
     * Get what's new count
     */
    async getWhatsNewCount({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_WHATS_NEW_LOADING', 'count', () => axios.get('/v1/whats-new/count'));
            commit(types.SET_WHATS_NEW_COUNT, responseData.count);
        } catch (error) {
            console.error(error)
        }
    },


    /**
     * Get what's new
     */
    async getWhatsNew({commit, state}, allPages = false) {
        const shouldFetch = allPages || state.nextWhatsNew <= state.nextWhatsLast;

        if (!shouldFetch) return;

        if (allPages) {
            state.nextWhatsNew = 1;
        }

        try {
            // Fetch data with a reusable helper function
            const responseData = await performApiRequest(
                commit,
                'SET_WHATS_NEW_LOADING',
                'get',
                () => axios.get(`/v1/whats-new`, {params: {page: state.nextWhatsNew, allPages}})
            );

            // Commit the fetched data
            commit(types.SET_WHATS_NEW, responseData.whatsNew);
        } catch (error) {
            console.error('Error fetching Whats New:', error);
        }
    },

    /**
     * Get what's new images
     */
    async getImages({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_WHATS_NEW_LOADING', 'images', () => axios.get('/v1/whats-new/images'));
            commit(types.SET_WHATS_NEW_IMAGES, responseData.files);
        } catch (error) {
            console.error(error)
        }
    },

    /**
     * Send what's new feedback
     */
    async sendFeedback({commit}, data: WhatsNewFeedback) {
        try {
            const responseData = await performApiRequest(commit, 'SET_WHATS_NEW_LOADING', 'sendFeedback', () => axios.post(`/v1/whats-new/${data.uuid}/feedback`, data));
            commit(types.MERGE_WHATS_NEW, responseData.whatsNew);
        } catch (error) {
            console.error(error)
        }
    },

    /**
     * Add what's new
     */
    async addWhatsNew({commit}, data: WhatsNew) {
        try {
            return await performApiRequest(commit, 'SET_WHATS_NEW_LOADING', 'sendFeedback', () => axios.post('/v1/whats-new', data));
        } catch (error) {
            console.error(error)
        }
    },

    /**
     * Update what's new
     */
    async updateWhatsNew({commit}, data: WhatsNew) {
        try {
            return await performApiRequest(commit, 'SET_WHATS_NEW_LOADING', 'update', () => axios.put(`/v1/whats-new/${data.uuid}`, data));
        } catch (error) {
            console.error(error)
        }
    },


    /**
     * Mark what's new as seen
     */
    async markSeen({commit}) {
        try {
            await performApiRequest(commit, 'SET_WHATS_NEW_LOADING', 'seen', () => axios.post('/v1/whats-new/seen'));
            commit(types.SET_WHATS_NEW_COUNT, 0);
        } catch (error) {
            console.error(error)
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
