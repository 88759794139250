import axios from 'axios';
import {Form} from '@/plugins/FWForms/index';
import {EventBus} from '@/plugins/eventBus';
import {get} from 'lodash';
import * as types from '../mutation-types';

export interface TollState {
    init: boolean;
    loading: Record<string, boolean>;
    tollForm: Form<any>;
    tollAgencies: any[];
    tollAccounts: any[];
    wishlist: any[];
    transactions: any[];
    transponders: any[];
}

const state: TollState = {
    init: false,
    loading: {
        connect_account: false,
        get_tolls: false,
        toggle_tolls: false,
        add_wishlist: false,
        attach_booking: false,
        attach_vehicle: false,
        detach_booking: false,
        detach_vehicle: false,
        delete_account: false,
        transaction_dismissing: false,
        manual_fetch_transactions: false,
        fetch_transponders: false,
    },
    tollForm: new Form(),
    tollAgencies: [],
    tollAccounts: [],
    wishlist: [],
    transactions: [],
    transponders: [],
};

const getters = {
    init: state => state.init,
    loading: state => state.loading,
    tollAgencies: state => state.tollAgencies,
    tollAccounts: state => state.tollAccounts,
    tollForm: state => state.tollForm,
    wishlist: state => state.wishlist,
    transactions: state => state.transactions,
    transponders: state => state.transponders,
};

const mutations = {
    [types.SET_TOLLS_LOADING](state, loading) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_TOLL_AGENCIES](state, tolls) {
        state.tollAgencies = tolls;
    },
    [types.SET_TOLL_ACCOUNTS](state, tolls) {
        state.tollAccounts = tolls;
    },
    [types.SET_TOLL_AGENCIES_WISHLIST](state, tolls) {
        state.wishlist = tolls;
    },
    [types.SET_TOLL_TRANSACTIONS](state, transactions) {
        state.transactions = transactions;
    },
    [types.SET_TOLL_TRANSPONDERS](state, transponders) {
        state.transponders = transponders;
    },
};

const actions = {
    getTollAgencies({commit}) {
        commit('SET_TOLLS_LOADING', {subject: 'get_tolls', loading: true});
        state.tollForm.errors.clear();

        axios.get('/v1/toll-agencies')
            .then((response) => {
                commit('SET_TOLL_AGENCIES', get(response, 'data.tollAgencies', []));
            }).catch((e) => {
            state.tollForm.errors.set(get(e, 'response.data.errors', []));
        }).finally(() => {
            commit('SET_TOLLS_LOADING', {subject: 'get_tolls', loading: false});
        });
    },

    getTollAgenciesWishlist({commit}) {
        commit('SET_TOLLS_LOADING', {subject: 'get_tolls', loading: true});

        axios.get('/v1/toll-agencies/wishlist')
            .then((response) => {
                commit('SET_TOLL_AGENCIES_WISHLIST', get(response, 'data.wishlist', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_TOLLS_LOADING', {subject: 'get_tolls', loading: false});
        });
    },

    connectTollAgency({commit, rootGetters}, data) {
        commit('SET_TOLLS_LOADING', {subject: 'connect_account', loading: true});

        axios.post('/v1/toll-accounts', data)
            .then((response) => {
                commit('SET_TOLL_ACCOUNTS', get(response, 'data.tollAccounts', []));
                EventBus.$emit('connect-toll-account-success');
            }).catch((e) => {
            state.tollForm.errors.set(get(e, 'response.data.errors'));
        }).finally(() => {
            commit('SET_TOLLS_LOADING', {subject: 'connect_account', loading: false});
        });
    },

    getTollAccounts({commit}) {
        commit('SET_TOLLS_LOADING', {subject: 'get_tolls', loading: true});

        axios.get('/v1/toll-accounts')
            .then((response) => {
                commit('SET_TOLL_ACCOUNTS', get(response, 'data.tollAccounts', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_TOLLS_LOADING', {subject: 'get_tolls', loading: false});
        });
    },

    toggleTollAccountActive({commit, rootGetters}, tollAccount) {
        commit('SET_TOLLS_LOADING', {subject: 'toggle_tolls', loading: true});

        axios.put(`/v1/toll-accounts/${tollAccount.uuid}/toggle-active`)
            .then((response) => {
                commit('SET_TOLL_ACCOUNTS', get(response, 'data.tollAccounts', []));
                EventBus.$emit('update-toll-account-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_TOLLS_LOADING', {subject: 'toggle_tolls', loading: false});
        });
    },

    deleteTollAccount({commit, rootGetters}, tollAccount) {
        commit('SET_TOLLS_LOADING', {subject: 'delete_account', loading: true});

        axios.delete(`/v1/toll-accounts/${tollAccount.uuid}`)
            .then((response) => {
                commit('SET_TOLL_ACCOUNTS', get(response, 'data.tollAccounts', []));
                EventBus.$emit('delete-toll-account-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_TOLLS_LOADING', {subject: 'delete_account', loading: false});
        });
    },

    testTollAccount({commit, rootGetters}, tollAccount) {
        commit('SET_TOLLS_LOADING', {subject: 'get_tolls', loading: true});

        axios.post(`/v1/toll-accounts/${tollAccount.uuid}/test`)
            .then(() => {
            }).catch(() => {
        }).finally(() => {
            commit('SET_TOLLS_LOADING', {subject: 'get_tolls', loading: false});
        });
    },

    updateAccount({commit, rootGetters}, tollAccount) {
        commit('SET_TOLLS_LOADING', {subject: 'get_tolls', loading: true});

        axios.put(`/v1/toll-accounts/${tollAccount.uuid}`, tollAccount)
            .then(() => {
                EventBus.$emit('update-toll-account-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_TOLLS_LOADING', {subject: 'get_tolls', loading: false});
        });
    },

    fetchTransactions({commit, rootGetters}, tollAccount) {
        commit('SET_TOLLS_LOADING', {subject: 'get_tolls', loading: true});

        axios.get(`/v1/toll-accounts/${tollAccount.uuid}/transactions`)
            .then(() => {
            }).catch(() => {
        }).finally(() => {
            commit('SET_TOLLS_LOADING', {subject: 'get_tolls', loading: false});
        });
    },

    manualFetchAllTransactions({commit}) {
        commit('SET_TOLLS_LOADING', {subject: 'manual_fetch_transactions', loading: true});

        axios.get('/v1/toll-accounts/transactions/manual-fetch')
            .then((response) => {
                commit('SET_TOLL_TRANSACTIONS', get(response, 'data.transactions', []));
                EventBus.$emit('manual-toll-transaction-fetch-success');
            }).catch(() => {
        }).finally(() => {
            commit('SET_TOLLS_LOADING', {subject: 'manual_fetch_transactions', loading: false});
        });
    },

    fetchVehicles({commit, rootGetters}, tollAccount) {
        commit('SET_TOLLS_LOADING', {subject: 'get_tolls', loading: true});

        axios.get(`/v1/toll-accounts/${tollAccount.uuid}/vehicles`)
            .then(() => {
            }).catch(() => {
        }).finally(() => {
            commit('SET_TOLLS_LOADING', {subject: 'get_tolls', loading: false});
        });
    },

    fetchTransponders({commit, rootGetters}, tollAccount) {
        commit('SET_TOLLS_LOADING', {subject: 'fetch_transponders', loading: true});

        axios.get(`/v1/toll-accounts/${tollAccount.uuid}/transponders`)
            .then((response) => {
                commit('SET_TOLL_TRANSPONDERS', get(response, 'data.transponders', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_TOLLS_LOADING', {subject: 'fetch_transponders', loading: false});
        });
    },

    saveTransponders({commit, rootGetters}, transponders) {
        commit('SET_TOLLS_LOADING', {subject: 'get_tolls', loading: true});

        axios.put('/v1/toll-accounts/transponders', transponders)
            .then((response) => {
                commit('SET_TOLL_TRANSPONDERS', get(response, 'data.transponders', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_TOLLS_LOADING', {subject: 'get_tolls', loading: false});
        });
    },

    getTransactions({commit}) {
        commit('SET_TOLLS_LOADING', {subject: 'get_tolls', loading: true});

        axios.get('/v1/toll-accounts/transactions')
            .then((response) => {
                commit('SET_TOLL_TRANSACTIONS', get(response, 'data.transactions', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_TOLLS_LOADING', {subject: 'get_tolls', loading: false});
        });
    },

    removeTransactionsMatchedBooking({commit, rootGetters}, transaction) {
        commit('SET_TOLLS_LOADING', {subject: 'detach_booking', loading: true});

        axios.delete(`/v1/toll-transactions/${transaction.uuid}/matched-booking`)
            .then((response) => {
                commit('SET_TOLL_TRANSACTIONS', get(response, 'data.transactions', []));
                EventBus.$emit('unmatched-toll-transaction-booking-success', get(response, 'data.transaction', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_TOLLS_LOADING', {subject: 'detach_booking', loading: false});
        });
    },

    removeTransactionsMatchedVehicle({commit, rootGetters}, transaction) {
        commit('SET_TOLLS_LOADING', {subject: 'detach_vehicle', loading: true});

        axios.delete(`/v1/toll-transactions/${transaction.uuid}/matched-vehicle`)
            .then((response) => {
                commit('SET_TOLL_TRANSACTIONS', get(response, 'data.transactions', []));
                EventBus.$emit('unmatched-toll-transaction-vehicle-success', get(response, 'data.transaction'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_TOLLS_LOADING', {subject: 'detach_vehicle', loading: false});
        });
    },

    dismissTransaction({commit, rootGetters}, transaction) {
        commit('SET_TOLLS_LOADING', {subject: 'transaction_dismissing', loading: true});

        axios.put(`/v1/toll-transactions/${transaction.uuid}/dismiss`)
            .then((response) => {
                commit('SET_TOLL_TRANSACTIONS', get(response, 'data.transactions', []));
                EventBus.$emit('dismissed-toll-transaction-success', get(response, 'data.transaction'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_TOLLS_LOADING', {subject: 'transaction_dismissing', loading: false});
        });
    },

    attachTransactionsMatchedBooking({commit, rootGetters}, data) {
        commit('SET_TOLLS_LOADING', {subject: 'attach_booking', loading: true});
        console.dir(data);
        axios.put(`/v1/toll-transactions/${data.transaction.uuid}/matched-booking`, {b_id: data.b_id})
            .then((response) => {
                commit('SET_TOLL_TRANSACTIONS', get(response, 'data.transactions', []));
                EventBus.$emit('attach-toll-transaction-booking-success', get(response, 'data.transaction', []));
            }).catch(() => {
        }).finally(() => {
            commit('SET_TOLLS_LOADING', {subject: 'attach_booking', loading: false});
        });
    },

    attachTransactionsMatchedVehicle({commit, rootGetters}, data) {
        commit('SET_TOLLS_LOADING', {subject: 'attach_vehicle', loading: true});
        console.dir(data);
        axios.put(`/v1/toll-transactions/${data.transaction.uuid}/matched-vehicle`, {l_id: data.l_id})
            .then((response) => {
                commit('SET_TOLL_TRANSACTIONS', get(response, 'data.transactions', []));
                EventBus.$emit('attach-toll-transaction-vehicle-success', get(response, 'data.transaction'));
            }).catch(() => {
        }).finally(() => {
            commit('SET_TOLLS_LOADING', {subject: 'attach_vehicle', loading: false});
        });
    },

    submitTollWishlist({commit, rootGetters}, data) {
        commit('SET_TOLLS_LOADING', {subject: 'add_wishlist', loading: true});

        axios.post('/v1/toll-agencies/wishlist', data)
            .then((response) => {
                commit('SET_TOLL_AGENCIES_WISHLIST', get(response, 'data.wishlist', []));
                EventBus.$emit('toll-wishlist-added-success');
            }).catch((e) => {
            state.tollForm.errors.set(get(e, 'response.data.errors'));
            if (get(e, 'response.data.code') === 'already_supported') {
                EventBus.$emit('toll-wishlist-already-supported', get(e, 'response.data.tollAgency'));
            }
        }).finally(() => {
            commit('SET_TOLLS_LOADING', {subject: 'add_wishlist', loading: false});
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
