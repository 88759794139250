export const SET_LOCALE = 'SET_LOCALE';

// app.js
export const SET_APP_LOADING = 'SET_APP_LOADING';
export const SET_APP_BOOTING = 'SET_APP_BOOTING';
export const TOGGLE_RIGHT_SIDEBAR = 'TOGGLE_RIGHT_SIDEBAR';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const INITIALIZE_STATE = 'INITIALIZE_STATE';
export const TOGGLE_LISTING_VIEW = 'TOGGLE_LISTING_VIEW';
export const TOGGLE_SECONDARY_IMPORT_NAV_VIEW = 'TOGGLE_SECONDARY_IMPORT_NAV_VIEW';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const TOGGLE_DEVICE = 'TOGGLE_DEVICE';
export const SET_ALL_POSSIBLE_PERMISSIONS = 'SET_ALL_POSSIBLE_PERMISSIONS';
export const SET_MASQUERADE_STATUS = 'SET_MASQUERADE_STATUS';
export const SET_MASQUERADE = 'SET_MASQUERADE';
export const END_MASQUERADE = 'END_MASQUERADE';
export const SET_FW_STRIPE_KEY = 'SET_FW_STRIPE_KEY';
export const SET_POLLED_FW_BUILD_NUMBER = 'SET_POLLED_FW_BUILD_NUMBER';
export const SET_UPDATE_AVAILABLE = 'SET_UPDATE_AVAILABLE';
export const SET_APP_FEATURES = 'SET_APP_FEATURES';
export const MARK_SETUP_ITEM_COMPLETE = 'MARK_SETUP_ITEM_COMPLETE';
export const SET_FEATURES_WAITLIST = 'SET_FEATURES_WAITLIST';
export const SET_CURRENT_CURRENCY = 'SET_CURRENT_CURRENCY';
export const TOGGLE_DEBUG_ON = 'TOGGLE_DEBUG_ON';
export const TOGGLE_DEBUG_DRAWER = 'TOGGLE_DEBUG_DRAWER';
export const SET_FW_BUILD_NUMBER = 'SET_FW_BUILD_NUMBER';
export const SET_HOVERED_TURNOVER_BOOKING_ID = 'SET_HOVERED_TURNOVER_BOOKING_ID';

// permissions.js
export const SET_ROUTES = 'SET_ROUTES';

// settings.js
export const SET_SETTINGS_LOADING = 'SET_SETTINGS_LOADING';
export const SET_NOTIFICATION_SETTINGS = 'SET_NOTIFICATION_SETTINGS';

// vehicles.js
export const SET_VEHICLES_LOADING = 'SET_VEHICLES_LOADING';
export const SET_VEHICLES = 'SET_VEHICLES';
export const SET_VEHICLE_MAKES = 'SET_VEHICLE_MAKES';
export const SET_VEHICLE_MODELS = 'SET_VEHICLE_MODELS';
export const SET_VEHICLE_CLASS_CREATED = 'SET_VEHICLE_CLASS_CREATED';
export const SET_VEHICLE_CLASSES = 'SET_VEHICLE_CLASSES';
export const SET_VEHICLE_CLASS = 'SET_VEHICLE_CLASS';
export const CLEAR_VEHICLE_FORM_ERRORS = 'CLEAR_VEHICLE_FORM_ERRORS';
export const SET_VEHICLE_FORM_ERRORS = 'SET_VEHICLE_FORM_ERRORS';


// auth.js
export const SET_USER = 'SET_USER';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const SET_TOKEN = 'SET_TOKEN';
export const SAVE_TOKEN = 'SAVE_TOKEN';
export const SAVE_REGISTER_ID = 'SAVE_REGISTER_ID';
export const SET_ROLES = 'SET_ROLES';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const LOGOUT = 'LOGOUT';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_USER_NOTIFICATIONS = 'SET_USER_NOTIFICATIONS';
export const SET_USER_API_TOKENS = 'SET_USER_API_TOKENS';


// booking.js
export const SET_BOOKING_LOADING = 'SET_BOOKING_LOADING';
export const SET_BOOKING_DIALOG = 'SET_BOOKING_DIALOG';
export const CLEAR_BOOKING_FORM_ERRORS = 'CLEAR_BOOKING_FORM_ERRORS';
export const SET_BOOKING_FORM_ERROR = 'SET_BOOKING_FORM_ERROR';
export const SET_FILTERED_MAKES = 'SET_FILTERED_MAKES';
export const PUSH_CALENDAR_ATTRIBUTES = 'PUSH_CALENDAR_ATTRIBUTES';
export const SET_CALENDAR_ATTRIBUTES = 'SET_CALENDAR_ATTRIBUTES';
export const SET_UNAVAILABLE_DATES = 'SET_UNAVAILABLE_DATES';
export const RESET_UNAVAILABLE_DATES = 'RESET_UNAVAILABLE_DATES';
export const SET_BOOKING_LISTING = 'SET_BOOKING_LISTING';
export const SET_STORED_TURO_BOOKINGS = 'SET_STORED_TURO_BOOKINGS';
export const SET_CUSTOMER_BOOKING = 'SET_CUSTOMER_BOOKING';
export const SET_REMEMBER_CUSTOMER = 'SET_REMEMBER_CUSTOMER';
export const SET_BOOKINGS = 'SET_BOOKINGS';
export const SET_BOOKINGS_META = 'SET_BOOKINGS_META';
export const SET_ABANDONED_BOOKINGS = 'SET_ABANDONED_BOOKINGS';
export const SET_PRIORITY_BOOKINGS = 'SET_PRIORITY_BOOKINGS';
export const SET_TOTAL_BOOKINGS = 'SET_TOTAL_BOOKINGS';
export const SET_CURRENT_WORKING_BOOKING = 'SET_CURRENT_WORKING_BOOKING';
export const SET_CURRENT_WORKING_BOOKING_STATUS = 'SET_CURRENT_WORKING_BOOKING_STATUS';
export const SET_BOOKING_PAYMENTS = 'SET_BOOKING_PAYMENTS';
export const MERGE_BOOKING_LINE_ITEM = 'MERGE_BOOKING_LINE_ITEM';
export const UPDATE_BOOKING_LINE_ITEM = 'UPDATE_BOOKING_LINE_ITEM';
export const REMOVE_BOOKING_LINE_ITEM = 'REMOVE_BOOKING_LINE_ITEM';
export const SET_BOOKING_CART_ID = 'SET_BOOKING_CART_ID';
export const RESET_BOOKING = 'RESET_BOOKING';
export const SET_BOOKING_AUTOMATION_EVENTS = 'SET_BOOKING_AUTOMATION_EVENTS';
export const SET_BOOKING_NOT_FOUND = 'SET_BOOKING_NOT_FOUND';
export const SET_CURRENT_STRIPE_CHARGE = 'SET_CURRENT_STRIPE_CHARGE';
export const SET_FLEET_RESOURCES = 'SET_FLEET_RESOURCES';
export const SET_CURRENT_WORKING_BOOKING_ATTRIBUTE = 'SET_CURRENT_WORKING_BOOKING_ATTRIBUTE';


// company.js
export const SET_COMPANY_LOADING = 'SET_COMPANY_LOADING';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_COMPANY_PHONE = 'SET_COMPANY_PHONE';
export const SET_COMPANY_META = 'SET_COMPANY_META';
export const SET_COMPANY_DEFAULT_META = 'SET_COMPANY_DEFAULT_META';
export const SET_COMPANY_BOOKINGS = 'SET_COMPANY_BOOKINGS';
export const SET_COMPANY_EMPLOYEES = 'SET_COMPANY_EMPLOYEES';
export const SET_COMPANY_EMPLOYEE = 'SET_COMPANY_EMPLOYEE';
export const SET_POSSIBLE_PERMISSIONS = 'SET_POSSIBLE_PERMISSIONS';
export const RESET_EMPLOYEE = 'RESET_EMPLOYEE';
export const SET_COMPANY_NOTIFICATIONS = 'SET_COMPANY_NOTIFICATIONS';
export const SET_EMPLOYEE_PERMISSIONS = 'SET_EMPLOYEE_PERMISSIONS';
export const SET_EMPLOYEE_ROLES = 'SET_EMPLOYEE_ROLES';
export const SET_EMAIL_DOMAIN_SETTINGS = 'SET_EMAIL_DOMAIN_SETTINGS';
export const SET_DELIVERY_LOCATIONS = 'SET_DELIVERY_LOCATIONS';
export const SET_FEEDBACK_REVIEWS = 'SET_FEEDBACK_REVIEWS';
export const SET_COMPANY_FORM_ERROR = 'SET_COMPANY_FORM_ERROR';
export const SET_COMPANY_META_FORM_ERROR = 'SET_COMPANY_META_FORM_ERROR';
export const CLEAR_COMPANY_META_FORM_ERRORS = 'CLEAR_COMPANY_META_FORM_ERRORS';

export const SET_OPERATING_RULES = 'SET_OPERATING_RULES';
export const SET_COMPANY_CURRENCY = 'SET_COMPANY_CURRENCY';
export const SET_COMPANY_SETTINGS = 'SET_COMPANY_SETTINGS';
export const SET_COUNTS = 'SET_COUNTS';

// affiliate.js
export const SET_AFFILIATE_LOADING = 'SET_AFFILIATE_LOADING';
export const SET_AFFILIATE = 'SET_AFFILIATE';
export const SET_AFFILIATE_PAYOUTS = 'SET_AFFILIATE_PAYOUTS';
export const SET_AFFILIATE_APPLICATION = 'SET_AFFILIATE_APPLICATION';
export const SET_AFFILIATE_FUTURE_PAYOUT = 'SET_AFFILIATE_FUTURE_PAYOUT';
export const SET_AFFILIATE_PAST_PAYOUT = 'SET_AFFILIATE_PAST_PAYOUT';


// listing.js
export const SET_LISTING_LOADING = 'SET_LISTING_LOADING';
export const SET_LISTING_SNACK = 'SET_LISTING_SNACK';
export const SET_LISTING_DIALOG = 'SET_LISTING_DIALOG';
export const CLEAR_LISTING_FILTER = 'CLEAR_LISTING_FILTER';
export const SET_LISTING_FILTER_AVAILABLE_FILTERS = 'SET_LISTING_FILTER_AVAILABLE_FILTERS';
export const SET_LISTING_FILTER_SELECTED_FILTERS = 'SET_LISTING_FILTER_SELECTED_FILTERS';
export const TOGGLE_LISTING_FILTER = 'TOGGLE_LISTING_FILTER';
export const SET_LISTING_VEHICLE = 'SET_LISTING_VEHICLE';
export const SET_LISTING_PRICE = 'SET_LISTING_PRICE';
export const SET_LISTING_PRICE_TAXABLE = 'SET_LISTING_PRICE_TAXABLE';
export const SET_LISTING_PRICE_TAX_CATEGORY = 'SET_LISTING_PRICE_TAX_CATEGORY';
export const SET_LISTING_SEC_DEP = 'SET_LISTING_SEC_DEP';
export const SET_LISTING_SEC_DEP_CREDIT = 'SET_LISTING_SEC_DEP_CREDIT';
export const SET_LISTING_SEC_DEP_DEBIT = 'SET_LISTING_SEC_DEP_DEBIT';
export const SET_LISTING_MILES_OVERAGE = 'SET_LISTING_MILES_OVERAGE';
export const RESET_CURRENT_LISTING = 'RESET_CURRENT_LISTING';
export const SET_CURRENT_NEW_LISTING = 'SET_CURRENT_NEW_LISTING';
export const SET_CURRENT_LISTING = 'SET_CURRENT_LISTING';
export const SET_LISTING = 'SET_LISTING';
export const SET_LISTING_INIT = 'SET_LISTING_INIT';
export const SET_ALL_LISTINGS = 'SET_ALL_LISTINGS';
export const SET_LISTING_ID = 'SET_LISTING_ID';
export const SET_STATUS_FILTER_ITEMS = 'SET_STATUS_FILTER_ITEMS';
export const SET_STATUS_FILTER_ITEMS_UPCOMING = 'SET_STATUS_FILTER_ITEMS_UPCOMING';
export const SET_STATUS_FILTER_TEMP_ITEMS = 'SET_STATUS_FILTER_TEMP_ITEMS';
export const SET_PAYMENT_STATUS_FILTER_ITEMS = 'SET_PAYMENT_STATUS_FILTER_ITEMS';
export const SET_ACTIVITY_FILTER_ITEMS = 'SET_ACTIVITY_FILTER_ITEMS';
export const SET_SOURCE_FILTER_ITEMS = 'SET_SOURCE_FILTER_ITEMS';
export const SET_BOOKABLE_TIMES = 'SET_BOOKABLE_TIMES';
export const SET_CURRENT_LISTING_BOOKINGS = 'SET_CURRENT_LISTING_BOOKINGS';
export const SET_CURRENT_LISTING_DISCOUNTABLE = 'SET_CURRENT_LISTING_DISCOUNTABLE';
export const SET_CURRENT_LISTING_MILE_ALLOWANCE = 'SET_CURRENT_LISTING_MILE_ALLOWANCE';
export const SET_CURRENT_LISTING_DURATION_DISCOUNT = 'SET_CURRENT_LISTING_DURATION_DISCOUNT';
export const TOGGLE_PAUSE_CURRENT_LISTING = 'TOGGLE_PAUSE_CURRENT_LISTING';
export const SET_CURRENT_LISTING_CHECKOUT_MODE = 'SET_CURRENT_LISTING_CHECKOUT_MODE';
export const SET_LISTING_PRICE_TYPE = 'SET_LISTING_PRICE_TYPE';
export const SET_LISTING_PRICE_PERIOD = 'SET_LISTING_PRICE_PERIOD';
export const SET_LISTING_PRICE_STRUCTURE_ID = 'SET_LISTING_PRICE_STRUCTURE_ID';
export const SET_VEHICLE_VIN = 'SET_VEHICLE_VIN';
export const SET_VEHICLE_YEAR = 'SET_VEHICLE_YEAR';
export const SET_VEHICLE_MAKE = 'SET_VEHICLE_MAKE';
export const SET_VEHICLE_MODEL = 'SET_VEHICLE_MODEL';
export const SET_VEHICLE_COLOR = 'SET_VEHICLE_COLOR';
export const SET_VEHICLE_SEATS = 'SET_VEHICLE_SEATS';
export const SET_VEHICLE_DOORS = 'SET_VEHICLE_DOORS';
export const SET_VEHICLE_PLATE = 'SET_VEHICLE_PLATE';
export const SET_ALL_AVAILABLE_FEATURES = 'SET_ALL_AVAILABLE_FEATURES';
export const SET_VEHICLE_FEATURES = 'SET_VEHICLE_FEATURES';
export const SET_VEHICLE_EXTRAS = 'SET_VEHICLE_EXTRAS';
export const SET_CURRENT_DECODED_VIN = 'SET_CURRENT_DECODED_VIN';
export const SET_EXISTING_LISTING_DATA = 'SET_EXISTING_LISTING_DATA';
export const SET_CURRENT_TRIP_EXTRA = 'SET_CURRENT_TRIP_EXTRA';
export const SET_CURRENT_LISTING_IMAGES = 'SET_CURRENT_LISTING_IMAGES';
export const SET_LISTING_AVAILABILITY_MESSAGE = 'SET_LISTING_AVAILABILITY_MESSAGE';
export const SET_ALL_EXTRAS = 'SET_ALL_EXTRAS';
export const SET_LISTING_FORM_ERRORS = 'SET_LISTING_FORM_ERRORS';
export const CLEAR_LISTING_FORM_ERRORS = 'CLEAR_LISTING_FORM_ERRORS';
export const SET_LISTING_DAILY_PRICES = 'SET_LISTING_DAILY_PRICES';
export const SET_FLEET_RESOURCE_LISTINGS = 'SET_FLEET_RESOURCE_LISTINGS';
export const SET_FLEET_RESOURCE_LISTINGS_DATA = 'SET_FLEET_RESOURCE_LISTINGS_DATA';
export const SET_CURRENT_LISTING_INCIDENTS = 'SET_CURRENT_LISTING_INCIDENTS';
export const SET_LISTING_FILTER_OPTIONS = 'SET_LISTING_FILTER_OPTIONS';
export const SET_CUSTODIAL_LISTING = 'SET_CUSTODIAL_LISTING';

// turo
export const SET_INIT = 'SET_INIT';
export const SET_TURO_LOADING = 'SET_TURO_LOADING';
export const SET_TRIPS = 'SET_TRIPS';
export const SET_TRIP_SINGLE = 'SET_TRIP_SINGLE';
export const SET_TURO_EMAILS = 'SET_TURO_EMAILS';
export const SET_TRIP_SINGLE_MESSAGES = 'SET_TRIP_SINGLE_MESSAGES';
export const SET_TRIP_SINGLE_IMAGES = 'SET_TRIP_SINGLE_IMAGES';
export const SET_TURO_MESSAGE_SENDING = 'SET_TURO_MESSAGE_SENDING';
export const SET_TURO_ALL_VEHICLES = 'SET_TURO_ALL_VEHICLES';
export const RESET_VEHICLES = 'RESET_VEHICLES';
export const SET_ALL_IMPORTED_TURO_VEHICLES = 'SET_ALL_IMPORTED_TURO_VEHICLES';
export const SET_TRIP_TURO_GO = 'SET_TRIP_TURO_GO';
export const SET_TURO_CONNECTIONS = 'SET_TURO_CONNECTIONS';
export const SET_TURO_REIMBURSEMENT_DETAILS = 'SET_TURO_REIMBURSEMENT_DETAILS';
export const RESET_TURO_CREDS = 'RESET_TURO_CREDS';
export const SET_TURO_CAPTCHA_TESTING = 'SET_TURO_CAPTCHA_TESTING';
export const SET_TURO_CAPTCHA_ID = 'SET_TURO_CAPTCHA_ID';
export const SET_TURO_EARNINGS = 'SET_TURO_EARNINGS';
export const SET_TRIP_HISTORY = 'SET_TRIP_HISTORY';
export const SET_TRIP_PENDING_REQUESTS = 'SET_TRIP_PENDING_REQUESTS';
export const SET_ACTIVITY_FEED = 'SET_ACTIVITY_FEED';
export const SET_TURO_CHANGE_REQUEST_SETTINGS = 'SET_TURO_CHANGE_REQUEST_SETTINGS';
export const SET_TURO_FEEDBACK = 'SET_TURO_FEEDBACK';
export const SET_TURO_FEEDBACK_PAGE = 'SET_TURO_FEEDBACK_PAGE';
export const SET_TURO_FEEDBACK_NEXT_PAGE = 'SET_TURO_FEEDBACK_NEXT_PAGE';
export const SET_TURO_FEEDBACK_TOTAL = 'SET_TURO_FEEDBACK_TOTAL';
export const SET_TURO_FEEDBACK_PER_PAGE = 'SET_TURO_FEEDBACK_PER_PAGE';
export const SET_TURO_FEEDBACK_LAST_PAGE = 'SET_TURO_FEEDBACK_LAST_PAGE';
export const SET_ALL_TURO_VEHICLES = 'SET_ALL_TURO_VEHICLES';
export const CLEAR_ALL_TURO_VEHICLES = 'CLEAR_ALL_TURO_VEHICLES';
export const SET_ALL_TURO_MESSAGES = 'SET_ALL_TURO_MESSAGES';
export const SET_ALL_TURO_MESSAGES_CURRENT_PAGINATE = 'SET_ALL_TURO_MESSAGES_CURRENT_PAGINATE';
export const SET_ALL_TURO_MESSAGES_TOTAL_PAGES = 'SET_ALL_TURO_MESSAGES_TOTAL_PAGES';
export const SET_TURO_EMAILS_PAGINATION = 'SET_TURO_EMAILS_PAGINATION';
export const SET_TRIP_SINGLE_MESSAGES_STORED = 'SET_TRIP_SINGLE_MESSAGES_STORED';

//
export const SET_TURO_PRICING_LOADING = 'SET_TURO_PRICING_LOADING';
export const SET_TURO_VEHICLE_DAILY_PRICING = 'SET_TURO_VEHICLE_DAILY_PRICING';
export const SET_TURO_PRICING_VEHICLES = 'SET_TURO_PRICING_VEHICLES';


// getaround.js
export const SET_GETAROUND_INIT = 'SET_GETAROUND_INIT';
export const SET_GETAROUND_LOADING = 'SET_GETAROUND_LOADING';
export const SET_GETAROUND_VEHICLES = 'SET_GETAROUND_VEHICLES';
export const SET_GETAROUND_CONNECTIONS = 'SET_GETAROUND_CONNECTIONS';
export const SET_GETAROUND_TRIPS = 'SET_GETAROUND_TRIPS';
export const SET_GETAROUND_RESERVATION = 'SET_GETAROUND_RESERVATION';
export const SET_ALL_IMPORTED_GETAROUND_VEHICLES = 'SET_ALL_IMPORTED_GETAROUND_VEHICLES';
export const SET_GETAROUND_RESERVATION_RECEIPT = 'SET_GETAROUND_RESERVATION_RECEIPT';


// renters.js
export const SET_RENTERS_LOADING = 'SET_RENTERS_LOADING';
export const SET_RENTER = 'SET_RENTER';
export const SET_RENTERS = 'SET_RENTERS';
export const SET_ALL_RENTERS = 'SET_ALL_RENTERS';
export const SET_RENTERS_DIALOG = 'SET_RENTERS_DIALOG';
export const ADD_RENTER = 'ADD_RENTER';
export const RESET_RENTER = 'RESET_RENTER';
export const SET_PAYMENT_SOURCES = 'SET_PAYMENT_SOURCES';
export const SET_BOOKING_PAYMENT = 'SET_BOOKING_PAYMENT';
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
export const SET_SEARCHED_RENTERS = 'SET_SEARCHED_RENTERS';
export const SET_STRIPE_CUSTOMER = 'SET_STRIPE_CUSTOMER';
export const CLEAR_RENTERS_FORM_ERROR = 'CLEAR_RENTERS_FORM_ERROR';
export const SET_RENTERS_CREDIT_CARDS = 'SET_RENTERS_CREDIT_CARDS';
export const SET_RENTER_NOTIFICATIONS = 'SET_RENTER_NOTIFICATIONS';
export const SET_CUSTOMER_QUERY = 'SET_CUSTOMER_QUERY';

// automations
export const SET_AUTOMATION_LOADING = 'SET_AUTOMATION_LOADING';
export const SET_CURRENT_AUTOMATION_EVENT = 'SET_CURRENT_AUTOMATION_EVENT';
export const SET_MESSAGE_TEMPLATES = 'SET_MESSAGE_TEMPLATES';
export const SET_AUTOMATION_EVENTS = 'SET_AUTOMATION_EVENTS';
export const SET_CURRENT_EVENT_LOGS = 'SET_CURRENT_EVENT_LOGS';

// cart.js
export const SET_CART_LOADING = 'SET_CART_LOADING';
export const SET_CART = 'SET_CART';
export const SET_CART_L_ID = 'SET_CART_L_ID';

// payments.js
export const SET_PAYMENTS_LOADING = 'SET_PAYMENTS_LOADING';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const SET_INVOICES = 'SET_INVOICES';
export const SET_PAYMENT = 'SET_PAYMENT';
export const SET_PAYMENTS = 'SET_PAYMENTS';
export const SET_CURRENT_WORKING_PAYMENT_ITEM = 'SET_CURRENT_WORKING_PAYMENT_ITEM';
export const SET_CUSTOM_PAYMENT_METHODS = 'SET_CUSTOM_PAYMENT_METHODS';
export const SET_STRIPE_INTENT = 'SET_STRIPE_INTENT';
export const RESET_NEW_PAYMENT = 'RESET_NEW_PAYMENT';

// subscription.js
export const SET_SUBSCRIPTION_LOADING = 'SET_SUBSCRIPTION_LOADING';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const UPDATE_NEW_SUBSCRIPTION = 'UPDATE_NEW_SUBSCRIPTION';
export const SET_ENFORCE_SUBSCRIPTION = 'SET_ENFORCE_SUBSCRIPTION';
export const SET_SUBSCRIPTION_MASTER = 'SET_SUBSCRIPTION_MASTER';
export const SET_EXTRA_LISTINGS_ADDON = 'SET_EXTRA_LISTINGS_ADDON';
export const CLEAR_SUBSCRIPTION_FORM_ERRORS = 'CLEAR_SUBSCRIPTION_FORM_ERRORS';
export const SET_SUBSCRIPTION_FORM_ERROR = 'SET_SUBSCRIPTION_FORM_ERROR';
export const SET_SUBSCRIPTION_STEP = 'SET_SUBSCRIPTION_STEP';
export const SET_PLAN_SELECTION = 'SET_PLAN_SELECTION';
export const SET_SUBSCRIPTION_PLAN_AND_INTERVAL = 'SET_SUBSCRIPTION_PLAN_AND_INTERVAL';

// super-admin.js
export const SET_SU_LOADING = 'SET_SU_LOADING';
export const SET_ALL_USERS = 'SET_ALL_USERS';
export const SET_CURRENT_WORKING_USER = 'SET_CURRENT_WORKING_USER';
export const SET_ALL_ROLES = 'SET_ALL_ROLES';
export const SET_ALL_PERMISSIONS = 'SET_ALL_PERMISSIONS';
export const SET_ALL_COMPANIES = 'SET_ALL_COMPANIES';
export const SET_CURRENT_WORKING_COMPANY = 'SET_CURRENT_WORKING_COMPANY';
export const SET_ALL_MEDIA_DIRECTORIES = 'SET_ALL_MEDIA_DIRECTORIES';
export const SET_ALL_MEDIA_IN_DIRECTORY = 'SET_ALL_MEDIA_IN_DIRECTORY';
export const SET_MEDIA_BASE_PATH = 'SET_MEDIA_BASE_PATH';
export const SET_ALL_LISTING_IMAGES = 'SET_ALL_LISTING_IMAGES';
export const SET_CURRENT_WORKING_SU_BASIC_STATS = 'SET_CURRENT_WORKING_SU_BASIC_STATS';
export const SET_ALL_SERVER_DETAILS = 'SET_ALL_SERVER_DETAILS';
export const SET_SA_FEATURE_WAITLIST = 'SET_SA_FEATURE_WAITLIST';
export const SET_SA_FEATURES_STATUS = 'SET_SA_FEATURES_STATUS';
export const SET_SA_CACHE = 'SET_SA_CACHE';

// twilio
export const SET_TWILIO_LOADING = 'SET_TWILIO_LOADING';
export const SET_VERIFY_CODE = 'SET_VERIFY_CODE';

// tesla.js
export const SET_TESLA_INIT = 'SET_TESLA_INIT';
export const CLEAR_TESLA_FORM_ERRORS = 'CLEAR_TESLA_FORM_ERRORS';
export const SET_TESLA_LOADING = 'SET_TESLA_LOADING';
export const SET_TESLA_CREDENTIALS = 'SET_TESLA_CREDENTIALS';
export const SET_TESLA_CONNECTIONS = 'SET_TESLA_CONNECTIONS';
export const UPDATE_TESLA_CONNECTION = 'UPDATE_TESLA_CONNECTION';
export const SET_TESLA_VEHICLES = 'SET_TESLA_VEHICLES';
export const SET_TESLA_CHARGING_INVOICES = 'SET_TESLA_CHARGING_INVOICES';
export const SET_TESLA_INVOICES = 'SET_TESLA_INVOICES';
export const SET_TESLA_INVOICES_META = 'SET_TESLA_INVOICES_META';
export const SET_TESLA_INVOICE_PDF = 'SET_TESLA_INVOICE_PDF';
export const SET_CURRENT_WORKING_TESLA_VEHICLE = 'SET_CURRENT_WORKING_TESLA_VEHICLE';
export const SET_CURRENT_WORKING_TESLA_VEHICLE_DRIVERS = 'SET_CURRENT_WORKING_TESLA_VEHICLE_DRIVERS';
export const SET_CURRENT_WORKING_TESLA_VEHICLE_HISTORY = 'SET_CURRENT_WORKING_TESLA_VEHICLE_HISTORY';
export const SET_CURRENT_WORKING_TESLA_VEHICLE_HISTORY_PAGINATE = 'SET_CURRENT_WORKING_TESLA_VEHICLE_HISTORY_PAGINATE';
export const SET_TESLA_VEHICLE_INVITATIONS = 'SET_TESLA_VEHICLE_INVITATIONS';
export const SET_CURRENT_WORKING_TESLA_VEHICLE_APP_ACCESS = 'SET_CURRENT_WORKING_TESLA_VEHICLE_APP_ACCESS';
export const SET_CURRENT_WORKING_TESLA_VEHICLE_STATE = 'SET_CURRENT_WORKING_TESLA_VEHICLE_STATE';
export const SET_CURRENT_WORKING_TESLA_VEHICLE_DATA = 'SET_CURRENT_WORKING_TESLA_VEHICLE_DATA';
export const SET_CURRENT_WORKING_TESLA_ASLEEP = 'SET_CURRENT_WORKING_TESLA_ASLEEP';

// bouncie.js
export const SET_BOUNCIE_INIT = 'SET_BOUNCIE_INIT';
export const SET_BOUNCIE_LOADING = 'SET_BOUNCIE_LOADING';
export const SET_BOUNCIE_CONNECTIONS = 'SET_BOUNCIE_CONNECTIONS';


// document.js
export const SET_TEMPLATES_LOADING = 'SET_TEMPLATES_LOADING';
export const RESET_TEMPLATE = 'RESET_TEMPLATE';
export const SET_ALL_DOCUMENTS = 'SET_ALL_DOCUMENTS';
export const SET_ONE_DOCUMENT = 'SET_ONE_DOCUMENT';
export const SET_ALL_TEMPLATES = 'SET_ALL_TEMPLATES';
export const SET_ONE_TEMPLATE = 'SET_ONE_TEMPLATE';

// envelope.js
export const SET_ENVELOPE_LOADING = 'SET_ENVELOPE_LOADING';
export const SET_ENVELOPE = 'SET_ENVELOPE';
export const SET_ALL_ENVELOPES = 'SET_ALL_ENVELOPES';
export const SET_ENVELOPE_SIGNATURE = 'SET_ENVELOPE_SIGNATURE';
export const SET_ALL_FIELDS = 'SET_ALL_FIELDS';
export const SET_ENVELOPE_DIALOG = 'SET_ENVELOPE_DIALOG';
export const SET_ENVELOPE_QUERY = 'SET_ENVELOPE_QUERY';
export const SET_ENVELOPE_CUSTOMER = 'SET_ENVELOPE_CUSTOMER';
export const UPDATE_ENVELOPE_FIELD = 'UPDATE_ENVELOPE_FIELD';
export const SET_ESIGN_SESSION_DATA = 'SET_ESIGN_SESSION_DATA';

// stripe
export const SET_STRIPE_LOADING = 'SET_STRIPE_LOADING';
export const SET_CURRENT_WORKING_INVOICE = 'SET_CURRENT_WORKING_INVOICE';
export const SET_CURRENT_WORKING_PAYMENT_INTENT = 'SET_CURRENT_WORKING_PAYMENT_INTENT';
export const SET_BILLING_PORTAL_SESSION = 'SET_BILLING_PORTAL_SESSION';
export const SET_STRIPE_PRICE_DATA = 'SET_STRIPE_PRICE_DATA';
export const SET_SUBSCRIPTION_DETAILS = 'SET_SUBSCRIPTION_DETAILS';
export const SET_STRIPE_IDENTITIES = 'SET_STRIPE_IDENTITIES';
export const SET_STRIPE_ACCOUNT = 'SET_STRIPE_ACCOUNT';

// square.ts
export const SET_SQUARE_LOADING = 'SET_SQUARE_LOADING';

// maintenance.js
export const SET_MAINTENANCE_LOADING = 'SET_MAINTENANCE_LOADING';
export const SET_CURRENT_VEHICLE_MAINTENANCE = 'SET_CURRENT_VEHICLE_MAINTENANCE';
export const SET_CURRENT_VEHICLE_RECALLS = 'SET_CURRENT_VEHICLE_RECALLS';
export const RESET_CURRENT_WORKING_MAINTENANCE_ITEM = 'RESET_CURRENT_WORKING_MAINTENANCE_ITEM';

// template.js
export const SET_RIGHT_SIDEBAR_OPEN = 'SET_RIGHT_SIDEBAR_OPEN';
export const SET_RIGHT_SIDEBAR_MODE = 'SET_RIGHT_SIDEBAR_MODE';
export const SET_RIGHT_SIDEBAR_TITLE = 'SET_RIGHT_SIDEBAR_TITLE';
export const SET_CURRENT_WORKING_TOOL = 'SET_CURRENT_WORKING_TOOL';
export const SET_SELECTED_FIELD = 'SET_SELECTED_FIELD';
export const SET_SELECTED_FIELD_V2 = 'SET_SELECTED_FIELD_V2';
export const REMOVE_FIELD = 'REMOVE_FIELD';
export const REMOVE_ALL_FIELDS = 'REMOVE_ALL_FIELDS';
export const SET_SELECTED_FIELD_INDEX_V2 = 'SET_SELECTED_FIELD_INDEX_V2';
export const SET_EXISTING_DATA = 'SET_EXISTING_DATA';
export const SET_NEW_DATA = 'SET_NEW_DATA';
export const SET_TOOLS_ACTIVE = 'SET_TOOLS_ACTIVE';
export const SET_CANVAS_ACTIVE = 'SET_CANVAS_ACTIVE';
export const RESET_TEMPLATE_DATA = 'RESET_TEMPLATE_DATA';
export const SET_FIELD_CURRENT_SIGNER = 'SET_FIELD_CURRENT_SIGNER';
export const SET_DOCUMENT_IMAGES = 'SET_DOCUMENT_IMAGES';
export const UPDATE_DOCUMENT_TEMPLATE_DATA = 'UPDATE_DOCUMENT_TEMPLATE_DATA';
export const ADD_TO_DOCUMENT_TEMPLATE_DATA = 'ADD_TO_DOCUMENT_TEMPLATE_DATA';

// taxes.js
export const SET_TAXES_LOADING = 'SET_TAXES_LOADING';
export const SET_TAX_CATEGORIES = 'SET_TAX_CATEGORIES';
export const SET_CURRENT_WORKING_TAX_CATEGORY = 'SET_CURRENT_WORKING_TAX_CATEGORY';
export const SET_TAX_CATEGORY_FORM_ERRORS = 'SET_TAX_CATEGORY_FORM_ERRORS';
export const CLEAR_TAX_CATEGORY_FORM_ERRORS = 'CLEAR_TAX_CATEGORY_FORM_ERRORS';
export const RESET_CURRENT_WORKING_TAX_CATEGORY = 'RESET_CURRENT_WORKING_TAX_CATEGORY';

// tenant-checkout.ts
export const SET_TENANT_CHECKOUT_DATES = 'SET_TENANT_CHECKOUT_DATES';
export const SET_TENANT_CHECKOUT_LOCATION = 'SET_TENANT_CHECKOUT_LOCATION';
export const SET_TENANT_CHECKOUT_DURATIONS = 'SET_TENANT_CHECKOUT_DURATIONS';
export const SET_TENANT_CHECKOUT_CUSTOM_ORDER_FIELDS = 'SET_TENANT_CHECKOUT_CUSTOM_ORDER_FIELDS';
export const SET_TENANT_CHECKOUT_BOOKING_ID = 'SET_TENANT_CHECKOUT_BOOKING_ID';
export const SET_TENANT_CHECKOUT_CUSTOMER_TOS_AGREE = 'SET_TENANT_CHECKOUT_CUSTOMER_TOS_AGREE';
export const SET_TENANT_CHECKOUT_COSTS = 'SET_TENANT_CHECKOUT_COSTS';
export const SET_TENANT_CHECKOUT_LISTING_ID = 'SET_TENANT_CHECKOUT_LISTING_ID';
export const SET_TENANT_CHECKOUT_CUSTOMERS = 'SET_TENANT_CHECKOUT_CUSTOMERS';
export const SET_TENANT_CHECKOUT_CUSTOMER_BY_INDEX = 'SET_TENANT_CHECKOUT_CUSTOMER_BY_INDEX';
export const SET_TENANT_CHECKOUT_EXTRAS_V2 = 'SET_TENANT_CHECKOUT_EXTRAS_V2';
export const SET_TENANT_CHECKOUT_REQUIRED_EXTRAS_V2 = 'SET_TENANT_CHECKOUT_REQUIRED_EXTRAS_V2';
export const SET_TENANT_CHECKOUT_REQUIRED_EXTRAS = 'SET_TENANT_CHECKOUT_REQUIRED_EXTRAS';
export const SET_TENANT_CHECKOUT_DELIVERY_ADDRESS_FORM_SHOW = 'SET_TENANT_CHECKOUT_DELIVERY_ADDRESS_FORM_SHOW';
export const SET_TENANT_CHECKOUT_STRIPE_PAYMENT_INTENT = 'SET_TENANT_CHECKOUT_STRIPE_PAYMENT_INTENT';
export const SET_TENANT_CHECKOUT_DIALOG = 'SET_TENANT_CHECKOUT_DIALOG';
export const SET_TENANT_CHECKOUT_ORDER_DELIVERY_ADDRESS = 'SET_TENANT_CHECKOUT_ORDER_DELIVERY_ADDRESS';
export const SET_TENANT_CHECKOUT_ORDER_DISTANCE_ALLOWANCE = 'SET_TENANT_CHECKOUT_ORDER_DISTANCE_ALLOWANCE';
export const RESET_TENANT_CHECKOUT_DELIVERY_RESULTS = 'RESET_TENANT_CHECKOUT_DELIVERY_RESULTS';
export const SET_TENANT_CHECKOUT_DELIVERY_RESULTS = 'SET_TENANT_CHECKOUT_DELIVERY_RESULTS';
export const SET_TENANT_CHECKOUT_DISCOUNT = 'SET_TENANT_CHECKOUT_DISCOUNT';
export const RESET_TENANT_CHECKOUT_DISCOUNT = 'RESET_TENANT_CHECKOUT_DISCOUNT';
export const SET_TENANT_CHECKOUT_FORM_ERRORS = 'SET_TENANT_CHECKOUT_FORM_ERRORS';
export const RESET_TENANT_CHECKOUT_FORM_ERRORS = 'RESET_TENANT_CHECKOUT_FORM_ERRORS';


// checkout
export const SET_CHECKOUT_LOADING = 'SET_CHECKOUT_LOADING';
export const CLEAR_CHECKOUT_FORM_ERRORS = 'CLEAR_CHECKOUT_FORM_ERRORS';
export const SET_CHECKOUT_DISCOUNT = 'SET_CHECKOUT_DISCOUNT';
export const RESET_CHECKOUT_DISCOUNT = 'RESET_CHECKOUT_DISCOUNT';
export const SET_ORDER_DELIVERY = 'SET_ORDER_DELIVERY';
export const SET_CHECKOUT_STRIPE = 'SET_CHECKOUT_STRIPE';
export const SET_CHECKOUT_AVAILABILITY = 'SET_CHECKOUT_AVAILABILITY';
export const SET_CHECKOUT_INITIALIZED = 'SET_CHECKOUT_INITIALIZED';
export const SET_CHECKOUT_ORDER_CUSTOMER = 'SET_CHECKOUT_ORDER_CUSTOMER';
export const SET_CHECKOUT_ORDER_CUSTOMER_BY_INDEX = 'SET_CHECKOUT_ORDER_CUSTOMER_BY_INDEX';
export const SET_CHECKOUT_ORDER_CUSTOMER_AGREE_TOS = 'SET_CHECKOUT_ORDER_CUSTOMER_AGREE_TOS';
export const SET_CHECKOUT_ORDER_DATES = 'SET_CHECKOUT_ORDER_DATES';
export const SET_CHECKOUT_ORDER_CUSTOM_FIELDS = 'SET_CHECKOUT_ORDER_CUSTOM_FIELDS';
export const SET_CHECKOUT_ORDER_DELIVERY_ADDRESS = 'SET_CHECKOUT_ORDER_DELIVERY_ADDRESS';
export const SET_CHECKOUT_RENTER_AUTHENTICATED = 'SET_CHECKOUT_RENTER_AUTHENTICATED';
export const SET_CHECKOUT_ORDER_REQUIRED_EXTRAS = 'SET_CHECKOUT_ORDER_REQUIRED_EXTRAS';
export const SET_RESERVATION_REQUEST_LISTING_ID = 'SET_RESERVATION_REQUEST_LISTING_ID';
export const SET_RESERVATION_REQUEST_DATES = 'SET_RESERVATION_REQUEST_DATES';
export const SET_RESERVATION_REQUEST_DURATIONS = 'SET_RESERVATION_REQUEST_DURATIONS';
export const SET_RESERVATION_REQUEST_EXTRAS = 'SET_RESERVATION_REQUEST_EXTRAS';
export const SET_RESERVATION_REQUEST_EXTRAS_V2 = 'SET_RESERVATION_REQUEST_EXTRAS_V2';
export const SET_RESERVATION_REQUEST_REQUIRED_EXTRAS_V2 = 'SET_RESERVATION_REQUEST_REQUIRED_EXTRAS_V2';
export const SET_RESERVATION_REQUEST_REQUIRED_EXTRAS_V2_AT_INDEX = 'SET_RESERVATION_REQUEST_REQUIRED_EXTRAS_V2_AT_INDEX';
export const SET_RESERVATION_REQUEST_PRICING = 'SET_RESERVATION_REQUEST_PRICING';

// coupon.js
export const SET_COUPON_LOADING = 'SET_COUPON_LOADING';
export const SET_COUPONS = 'SET_COUPONS';
export const UPDATE_COUPON = 'UPDATE_COUPON';
export const SET_COUPON_REDEMPTIONS = 'SET_COUPON_REDEMPTIONS';
export const CLEAR_COUPON_FORM_ERRORS = 'CLEAR_COUPON_FORM_ERRORS';
export const SET_COUPON_FORM_ERROR = 'SET_COUPON_FORM_ERROR';
export const SET_COUPON_FORM_BUSY = 'SET_COUPON_FORM_BUSY';

// notes.js
export const SET_NOTES_LOADING = 'SET_NOTES_LOADING';


// custom-fields.js
export const SET_CUSTOM_FIELDS_LOADING = 'SET_CUSTOM_FIELDS_LOADING';
export const SET_CUSTOM_FIELDS = 'SET_CUSTOM_FIELDS';
export const SET_CUSTOM_FIELDS_FORM_BUSY = 'SET_CUSTOM_FIELDS_FORM_BUSY';

// default-properties.js
export const SET_DEFAULT_PROPERTIES = 'SET_DEFAULT_PROPERTIES';
export const CLEAR_DEFAULT_PROPERTIES_FORM = 'CLEAR_DEFAULT_PROPERTIES_FORM';
export const SET_DEFAULT_PROPERTIES_FORM_BUSY = 'SET_DEFAULT_PROPERTIES_FORM_BUSY';
export const SET_DEFAULT_PROPERTIES_FORM_ERRORS = 'SET_DEFAULT_PROPERTIES_FORM_ERRORS';

// tolls.js
export const SET_TOLLS_LOADING = 'SET_TOLLS_LOADING';
export const SET_TOLL_AGENCIES = 'SET_TOLL_AGENCIES';
export const SET_TOLL_ACCOUNTS = 'SET_TOLL_ACCOUNTS';
export const SET_TOLL_AGENCIES_WISHLIST = 'SET_TOLL_AGENCIES_WISHLIST';
export const SET_TOLL_TRANSACTIONS = 'SET_TOLL_TRANSACTIONS';
export const SET_TOLL_TRANSPONDERS = 'SET_TOLL_TRANSPONDERS';

// whats-new.js
export const SET_WHATS_NEW_LOADING = 'SET_WHATS_NEW_LOADING';
export const SET_WHATS_NEW_COUNT = 'SET_WHATS_NEW_COUNT';
export const SET_WHATS_NEW = 'SET_WHATS_NEW';
export const MERGE_WHATS_NEW = 'MERGE_WHATS_NEW';
export const SET_WHATS_NEW_IMAGES = 'SET_WHATS_NEW_IMAGES';
export const SET_SHOW_WHATS_NEW_SIDEBAR = 'SET_SHOW_WHATS_NEW_SIDEBAR';


// fleet.js
export const SET_COMPANY_BRANCHES = 'SET_COMPANY_BRANCHES';
export const SET_COMPANY_BRANCH = 'SET_COMPANY_BRANCH';
export const SET_COMPANY_BRANCHES_LOADING = 'SET_COMPANY_BRANCHES_LOADING';
export const SET_ACTION_ITEMS = 'SET_ACTION_ITEMS';
export const SET_ACTION_ITEM = 'SET_ACTION_ITEM';
export const ACTION_ITEM_CREATED = 'ACTION_ITEM_CREATED';
export const ACTION_ITEM_DELETED = 'ACTION_ITEM_DELETED';
export const SET_FLEET_FORM_ERROR = 'SET_FLEET_FORM_ERROR';
export const CLEAR_FLEET_FORM_ERRORS = 'CLEAR_FLEET_FORM_ERRORS';

// outdoorsy.js
export const SET_OUTDOORSY_LOADING = 'SET_OUTDOORSY_LOADING';
export const SET_OUTDOORSY_CONNECTIONS = 'SET_OUTDOORSY_CONNECTIONS';
export const SET_OUTDOORSY_LISTINGS = 'SET_OUTDOORSY_LISTINGS';
export const SET_OUTDOORSY_BOOKINGS = 'SET_OUTDOORSY_BOOKINGS';

// hq-rentals.js
export const SET_HQRENTALS_LOADING = 'SET_HQRENTALS_LOADING';
export const SET_HQRENTALS_CONNECTIONS = 'SET_HQRENTALS_CONNECTIONS';
export const SET_HQRENTALS_LISTINGS = 'SET_HQRENTALS_LISTINGS';
export const SET_HQRENTALS_BOOKINGS = 'SET_HQRENTALS_BOOKINGS';
export const SET_HQRENTALS_CONTACTS = 'SET_HQRENTALS_CONTACTS';

// providers.js
export const SET_PROVIDERS_LOADING = 'SET_PROVIDERS_LOADING';
export const SET_PAYMENT_PROVIDERS = 'SET_PAYMENT_PROVIDERS';

// tur0-research.js
export const SET_TURO_RESEARCH_LOADING = 'SET_TURO_RESEARCH_LOADING';
export const SET_TURO_ALL_DELIVERY_LOCATIONS = 'SET_TURO_ALL_DELIVERY_LOCATIONS';
export const SET_TURO_ALL_VEHICLE_MAKES = 'SET_TURO_ALL_VEHICLE_MAKES';
export const SET_TURO_RESEARCH_SEARCH_LIST = 'SET_TURO_RESEARCH_SEARCH_LIST';

// commission-partner.js
export const SET_COMMISSION_PARTNER_LOADING = 'SET_COMMISSION_PARTNER_LOADING';
export const SET_COMMISSION_PARTNERS = 'SET_COMMISSION_PARTNERS';
export const SET_COMMISSION_PARTNER = 'SET_COMMISSION_PARTNER';
export const SET_COMMISSIONS = 'SET_COMMISSIONS';
export const CLEAR_COMMISSION_PARTNER_COUPON_FORM_ERRORS = 'CLEAR_COMMISSION_PARTNER_COUPON_FORM_ERRORS';

// order
export const SET_ORDERS_LOADING = 'SET_ORDERS_LOADING';
export const SET_ORDER = 'SET_ORDER';
export const UPDATE_ORDER_LINE = 'UPDATE_ORDER_LINE';
export const SET_ORDER_DOCUMENT = 'SET_ORDER_DOCUMENT';
export const SET_ORDER_DOCUMENTS = 'SET_ORDER_DOCUMENTS';
export const SET_ORDER_DOCUMENT_DOWNLOAD_STATUS = 'SET_ORDER_DOCUMENT_DOWNLOAD_STATUS';
export const SET_ORDER_AVAILABILITY = 'SET_ORDER_AVAILABILITY';
export const SET_OPEN_PRICING_WIZARD_DIALOG = 'SET_OPEN_PRICING_WIZARD_DIALOG';

// email.js
export const SET_EMAILS_LOADING = 'SET_EMAILS_LOADING';
export const SET_EMAILS = 'SET_EMAILS';
export const SET_EMAIL_HISTORY = 'SET_EMAIL_HISTORY';
export const SET_EMAIL_TEMPLATES = 'SET_EMAIL_TEMPLATES';
export const SET_EMAIL_TEMPLATE = 'SET_EMAIL_TEMPLATE';
export const SET_EMAIL_FORM_ERROR = 'SET_EMAIL_FORM_ERROR';
export const CLEAR_EMAIL_FORM_ERRORS = 'CLEAR_EMAIL_FORM_ERRORS';


// webhooks.ts
export const SET_WEBHOOKS_LOADING = 'SET_WEBHOOKS_LOADING';
export const SET_WEBHOOKS_CONVERSATIONS = 'SET_WEBHOOKS_CONVERSATIONS';
export const SET_WEBHOOK_ENDPOINTS = 'SET_WEBHOOK_ENDPOINTS';
export const SET_WEBHOOK_ENDPOINT = 'SET_WEBHOOK_ENDPOINT';
export const SET_WEBHOOK_ENDPOINT_SECRET = 'SET_WEBHOOK_ENDPOINT_SECRET';

// analytics.js
export const SET_ANALYTICS_LOADING = 'SET_ANALYTICS_LOADING';
export const SET_CONVERSION_ANALYTICS = 'SET_CONVERSION_ANALYTICS';
export const SET_VEHICLE_ANALYTICS = 'SET_VEHICLE_ANALYTICS';
export const SET_VEHICLES_ANALYTICS = 'SET_VEHICLES_ANALYTICS';

// custodial.js
export const SET_CUSTODIAN_LOADING = 'SET_CUSTODIAN_LOADING';
export const SET_CUSTODIANS = 'SET_CUSTODIANS';
export const SET_CUSTODIAN_INVITE = 'SET_CUSTODIAN_INVITE';
export const SET_CUSTODIAN_LISTINGS = 'SET_CUSTODIAN_LISTINGS';
export const SET_CUSTODIAN_BOOKINGS = 'SET_CUSTODIAN_BOOKINGS';

// pricing
export const SET_PRICING_LOADING = 'SET_PRICING_LOADING';
export const SET_PRICING_RULES = 'SET_PRICING_RULES';

export const SET_PRICING_STRUCTURE = 'SET_PRICING_STRUCTURE';
export const SET_PRICING_STRUCTURES = 'SET_PRICING_STRUCTURES';
export const RESET_CURRENT_WORKING_PRICING_STRUCTURE = 'RESET_CURRENT_WORKING_PRICING_STRUCTURE';

// lula.js
export const SET_LULA_LOADING = 'SET_LULA_LOADING';
export const SET_LULA_CONNECTIONS = 'SET_LULA_CONNECTIONS';
export const SET_LULA_VEHICLES = 'SET_LULA_VEHICLES';


// twisted-road.js

export const SET_TWISTED_ROAD_LOADING = 'SET_TWISTED_ROAD_LOADING';
export const SET_TWISTED_ROAD_CONNECTIONS = 'SET_TWISTED_ROAD_CONNECTIONS';
export const SET_TWISTED_ROAD_VEHICLES = 'SET_TWISTED_ROAD_VEHICLES';

// files.js
export const SET_FILES = 'SET_FILES';
export const MERGE_FILES = 'MERGE_FILES';
export const SET_FILES_PAGINATION = 'SET_FILES_PAGINATION';
export const SET_FILES_LOADING = 'SET_FILES_LOADING';

// barcode.js
export const SET_BARCODE_LOADING = 'SET_BARCODE_LOADING';
export const SET_BARCODE = 'SET_BARCODE';

// trip-extras.js
export const SET_EXTRAS_LOADING = 'SET_EXTRAS_LOADING';
export const SET_EXTRAS = 'SET_EXTRAS';
export const SET_TRIP_EXTRA_SETTING_DIALOG = 'SET_TRIP_EXTRA_SETTING_DIALOG';
export const SET_REQUIRED_EXTRA_ADD_EDIT_DIALOG = 'SET_REQUIRED_EXTRA_ADD_EDIT_DIALOG';
export const SET_REQUIRED_EXTRA_ADD_EDIT_DATA = 'SET_REQUIRED_EXTRA_ADD_EDIT_DATA';
export const SET_REGULAR_EXTRA_DIALOG = 'SET_REGULAR_EXTRA_DIALOG';
export const SET_EXTRA_LISTINGS_DIALOG = 'SET_EXTRA_LISTINGS_DIALOG';
export const SET_REGULAR_EXTRA_DATA = 'SET_REGULAR_EXTRA_DATA';

// axle
export const SET_AXLE_LOADING = 'SET_AXLE_LOADING';
export const SET_AXLE = 'SET_AXLE';
export const SET_AXLE_IGNITIONS = 'SET_AXLE_IGNITIONS';
export const MERGE_AXLE_IGNITION = 'MERGE_AXLE_IGNITION';
export const SET_INCOMING_AXLE_IGNITION = 'SET_INCOMING_AXLE_IGNITION';

// theme.js
export const SET_STORE_THEMES = 'SET_STORE_THEMES';
export const SET_STORE_THEME_DATA = 'SET_STORE_THEME_DATA';

// braintree.ts
export const SET_BRAINTREE_LOADING = 'SET_BRAINTREE_LOADING';

// tags
export const SET_TAGS_LOADING = 'SET_TAGS_LOADING';
export const SET_TAGS = 'SET_TAGS';