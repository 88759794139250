import { get, isNumber } from 'lodash';
import { formatNumberWithCommas } from '@/utils/numbers';
import DatepickerService from './datepicker.service';
import dayjs from 'dayjs';
import { CartService } from '@/services/cart.service';
import CardService from './card.service';


const COMPANY_URL = window.fleetwireOptions?.apiURL;

const DEBUG_ON = window.fleetwireOptions?.debug || true;

const UtilService = {

  log(data) {
    if (DEBUG_ON) {
      console.dir(data);
    }
  },

  error(message) {
    if (DEBUG_ON) {
      console.error(message);
    }
  },

  setCompany(company) {
    UtilService.updatePriceCurrency(get(company, 'currency'));
    UtilService.updateCompanyName(get(company, 'name'));
    UtilService.updateCompanyDefaultPickupTimes(get(company, 'operating_rules'));
  },

  updatePriceCurrency(currency) {
    const prices = $fw('.fw-price-currency');

    prices.each(function (index, el) {
      const cardElement = $fw(el).closest('.fleetwire-listing-card')[0]
      let price = el.innerHTML;
      price = price.replace(/\D/g, "");

      if (cardElement) {
        CardService.setPrice(cardElement, null, price * 100);
      } else {


        const symbol = get(currency, 'symbol', "**");
        el.innerHTML = `${symbol}${price}`;
      }
    })
  },

  updateCompanyName(name) {
    console.dir('updateCompanyName');
    console.dir(name);
    const names = $fw('.fw-company-name');

    console.dir(names);
    names.each(function (index, el) {
      $fw(el).text(name);
    })
  },

  /**
   * Fill in the default pickup times for the company.
   *
   * @param {Object} rules
   */
  updateCompanyDefaultPickupTimes(rules) {
    if (Object.keys(rules).length > 0) {
      const pickup = $fw('.fw-from-time');
      const until = $fw('.fw-until-time');

      const found = rules.find(element => element.data_type === 'default_pickup_time');
      const defaultTime = get(found, 'data.default_pickup_time.start', '09:00');

      pickup.each(function (index, el) {
        $fw(el).text(UtilService.formatDefaultTime(defaultTime));
      })
      until.each(function (index, el) {
        $fw(el).text(UtilService.formatDefaultTime(defaultTime));
      })
    }
  },

  formatDefaultTime(time) {
    return UtilService.getFormattedTimeFromDate(dayjs(`2022-08-08 ${time}`));
  },

  getFormattedTimeFromDate(date) {
    return dayjs(date).format('h:mm A');
  },

  /**
   * Build a FW component wrapper
   *
   * @private
   */
  buildFWComponent() {
    const pDiv = document.createElement('div');
    pDiv.classList.add('fleetwire-component');
    return pDiv;
  },

  /**
   * Collect the fleetwire components
   *
   * @private
   * @returns {Array} Returns `array`.
   */
  collectFWComponents() {
    const ids = [];
    const products = document.querySelectorAll('[class*=fleetwire-]');

    for (const product of products) {
      const id = product?.dataset?.id;
      if (id && !ids.includes(id)) {
        ids.push(id);
      }
    }

    return ids;
  },

  /**
   * Load another script
   * @param {string} url
   */
  loadScript(url) {
    const script = document.createElement('script')
    script.type = 'text/javascript';

    if (script.readyState) {  //IE
      script.onreadystatechange = function () {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
        }
      };
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  },

  /**
   * If window is resized then resize the elements
   * @param {WindowEventMap[keyof WindowEventMap]} data
   */
  setWidth(data = null) {
    const productCards = Array.from(document.getElementsByClassName('fleetwire-listing-card'))

    if (productCards.length > 0) {
      productCards.forEach(function (card) {
        const parent = card.parentNode;

        const parentWidth = parent.offsetWidth

        const o = Math.floor(parentWidth / 280);
        const width = Math.max(1, Math.min(o, 6));
        card.style.width = 100 / width + '%';
      })
    }
  },


  /**
   * Get the listings url
   *
   * @private
   * @param {String} [listingId] The Listing ID.
   * @returns {String}
   */
  companyProductUrl(listingId) {
    const times = DatepickerService.data.times
    let query = '';
    if (get(times, 'start')) {
      const startDate = dayjs(get(times, 'start')).format('MMDDYY');
      const startTime = dayjs(get(times, 'start')).format('HHmm');
      query += `&startDate=${startDate}&startTime=${startTime}`;
    }
    if (get(times, 'end')) {
      const endDate = dayjs(get(times, 'end')).format('MMDDYY');
      const endTime = dayjs(get(times, 'end')).format('HHmm');
      query += `&endDate=${endDate}&endTime=${endTime}`;
    }

    if (CartService.getCartUuid()) {
      query += `&cart=${CartService.getCartUuid()}`;
    }
    return encodeURI(`${COMPANY_URL}/rental/reservations?l_id=${listingId}&${query}`);
  },


  /**
   * Get the listings price image or default
   *
   * @private
   * @param {Number} [value] The price.
   * @returns {String}
   */
  getListingPrice(value) {
    if (isNumber(value)) {
      return value <= 0 ? 0 : formatNumberWithCommas(value * 0.01);
    }
    return value === 0 ? 0 : formatNumberWithCommas(+value * 0.01);
  },

  randomString(length = 5) {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    for (let i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  },


  /**
   * Get the listings first image or default
   *
   * @private
   * @param {Object} [listing] The listing object.
   * @returns {String}
   */
  productFirstImage(listing) {
    return get(listing, 'listing_images[0].path', 'https://fleetwire.io/images/vehicle-placeholder.jpg');
  },


  /**
   * Get the listings id
   *
   * @private
   * @param {Object} [listing] The listing object.
   * @returns {String}
   */
  getListingId(listing) {
    return get(listing, 'dataset.id');
  }

}

export default UtilService;