import {EventBus} from '@/plugins/eventBus';
import axios from 'axios';
import * as types from '@/store/mutation-types';
import {Form} from '@/plugins/FWForms';
import {RootState} from '@/types/RootState';
import {Module} from 'vuex';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {push} from 'notivue';

export interface CommissionPartnersState {
    loading: Record<string, boolean>;
    couponForm: Form<object>;
    commissionPartners: CommissionPartner[];
    commissionPartner?: CommissionPartner;
    commissions: any[];
}

export interface CommissionPartner {
    active: boolean;
    checkoutLink?: string;
    commission_amount: number;
    commission_type: string;
    company?: any;
    created_at?: string;
    email: string;
    name: string;
    notes?: string;
    phone: string;
    updated_at?: string;
    uuid?: string;
    mode?: 'create' | 'edit';
}

// state
const state: CommissionPartnersState = {
    loading: {
        get: false,
        create: false,
        update: false,
        delete: false,
        toggle: false,
        commissions: false,
    },
    couponForm: new Form(),
    commissionPartners: [],
    commissionPartner: null,
    commissions: [],
};

const mutations = {
    [types.SET_COMMISSION_PARTNER_LOADING](state: CommissionPartnersState, loading: {
        subject: string,
        loading: boolean
    }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_COMMISSION_PARTNERS](state: CommissionPartnersState, partners: CommissionPartner[]) {
        state.commissionPartners = partners;
    },
    [types.SET_COMMISSION_PARTNER](state: CommissionPartnersState, partner: CommissionPartner) {
        state.commissionPartner = partner;
    },
    [types.SET_COMMISSIONS](state: CommissionPartnersState, commissions) {
        state.commissions = commissions;
    },

    [types.CLEAR_COMMISSION_PARTNER_COUPON_FORM_ERRORS](state: CommissionPartnersState) {
        state.couponForm.errors.clear();
    },
};

const actions = {

    /**
     * Get commission partners
     */
    getCommissionPartners({commit}) {
        commit('SET_COMMISSION_PARTNER_LOADING', {subject: 'get', loading: true});

        commit(types.CLEAR_COMMISSION_PARTNER_COUPON_FORM_ERRORS);

        axios.get('/v1/commission-partners')
            .then((response) => {
                EventBus.$emit('get-commission-partners-success', response.data.partners);
                commit(types.SET_COMMISSION_PARTNERS, response.data.partners);
            }).catch(() => {
        }).finally(() => {
            commit('SET_COMMISSION_PARTNER_LOADING', {subject: 'get', loading: false});
        });
    },


    /**
     * Get a single commission partner
     */
    getCommissionPartner({commit}, partner_uuid: string) {
        commit('SET_COMMISSION_PARTNER_LOADING', {subject: 'get', loading: true});
        commit('CLEAR_COMMISSION_PARTNER_COUPON_FORM_ERRORS');

        axios.get(`/v1/commission-partners/${partner_uuid}`)
            .then((response) => {
                EventBus.$emit('get-commission-partners-success', response.data.partner);
                commit('SET_COMMISSION_PARTNER', response.data.partner);
            }).catch(() => {
        }).finally(() => {
            commit('SET_COMMISSION_PARTNER_LOADING', {subject: 'get', loading: false});
        });
    },

    /**
     * Create commission partner
     */
    async createCommissionPartner({commit}, partner: CommissionPartner) {
        try {
            const responseData = await performApiRequest(commit, 'SET_COMMISSION_PARTNER_LOADING', 'create', () => axios.post('/v1/commission-partners', partner));
            EventBus.$emit('create-commission-partner-success', responseData.partner);
        } catch (e) {
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }
    },

    /**
     * Update commission partner
     */
    async updateCommissionPartner({commit}, partner: CommissionPartner) {
        try {
            await performApiRequest(commit, 'SET_COMMISSION_PARTNER_LOADING', 'update', () => axios.put(`/v1/commission-partners/${partner.uuid}`, partner));
        } catch (e) {
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }
    },

    /**
     * Delete commission partner
     */
    async deleteCommissionPartner({commit}, partner: CommissionPartner) {
        try {
            await performApiRequest(commit, 'SET_COMMISSION_PARTNER_LOADING', 'delete', () => axios.delete(`/v1/commission-partners/${partner.uuid}`));
        } catch (e) {
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }
    },

    async toggleShowOnMainMenu({commit}, value: boolean) {
        try {
            await performApiRequest(commit, 'SET_COMMISSION_PARTNER_LOADING', 'toggle', () => axios.post('/v1/commission-partners/main-menu-toggle', {value}));
        } catch (e) {
            push.error({
                message: e?.response?.data?.message || 'There was an error',
            });
        }
    },

    /**
     * Get commissions
     */
    getCommissions({commit}) {
        commit('SET_COMMISSION_PARTNER_LOADING', {subject: 'commissions', loading: true});

        axios.get('/v1/commissions')
            .then((response) => {
                EventBus.$emit('get-commissions-success');
                commit('SET_COMMISSIONS', response.data.commissions);
            }).catch(() => {
        }).finally(() => {
            commit('SET_COMMISSION_PARTNER_LOADING', {subject: 'commissions', loading: false});
        });
    },

};

const commissionPartnerModule: Module<CommissionPartnersState, RootState> = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters: {
        loading: state => state.loading,
        commissions: state => state.commissions,
        commissionPartners: state => state.commissionPartners,
        commissionPartner: state => state.commissionPartner,
    },
};

export default commissionPartnerModule;
