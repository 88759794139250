<template>
  <div>
    <v-card :disabled="!canUseSquareIntegration" class="mt-5">
      <div class="my-3">
        <v-img alt="Braintree Payment Provider Logo" max-height="50" src="/images/logo/Square_LogoLockup_Black.png"/>
      </div>
      <v-card-text>
        <div v-if="!hasSquare">
          <a class="square-connect slate" @click="squareOauthInit">
            <span>Connect with</span>
          </a>
        </div>
        <div v-else class="flex items-center border rounded p-3">
          <div class="flex-shrink-0">
            <img :src="`https://ui-avatars.com/api/?name=SQ`" alt="Image Description" class="h-10 w-10 rounded-full object-cover mx-1 mr-3">
          </div>
          <div class="flex-1 min-w-0 ms-4">
            <h6 class="text-hover-primary mb-0">{{ squareEmail }}</h6>
            <small class="d-block">
              <strong>Connected: </strong>{{ formatRentalDate(squareCreatedAt) }}</small>
          </div>

          <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="text">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <!--              <v-list-item @click="$router.push({ name: 'settings.payment-provider.single', params: { uuid: squareUuid }}).catch(() => {})">-->
                <!--                <v-list-item-title>-->
                <!--                  View-->
                <!--                </v-list-item-title>-->
                <!--              </v-list-item>-->
                <v-list-item @click="removeSquareConfirmDialog = true">
                  <v-list-item-title>
                    Disconnect
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="removeSquareConfirmDialog" max-width="350">
      <v-card>
        <v-card-title class="text-h5">Delete Square Connection</v-card-title>
        <v-card-text>
          Are you sure you want to delete your Square connection?
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="grey" size="small" variant="text" @click="removeSquareConfirmDialog = false">
            Nevermind
          </v-btn>
          <v-btn color="primary" @click="deletePaymentProfileCommit(squareUuid)">
            I'm Sure
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue';
import {EventBus} from '@/plugins/eventBus';
import {useDateFormatter} from '@/composables/useDate';
import {useAppFeatures} from '@/composables/useAppFeatures';
import {Provider} from '@/store/modules/providers';
import {useProviders} from '@/composables/useProviders';
import {useSquare} from '@/composables/useSquare';


const {paymentProviders, deletePaymentProfile, getPaymentProfiles} = useProviders();
const {formatRentalDate} = useDateFormatter();
const {canUseSquareIntegration} = useAppFeatures();
const {
  getSquareOauthUrl,
  getSquareProvider,
  setSquareLoading,
} = useSquare();

const squareOauthWindow = ref(null);
const squareCheckInterval = ref(null);
const removeSquareConfirmDialog = ref(false);


const squareProvider = computed(() => {
  return paymentProviders.value.find((provider: Provider) => provider.provider === 'square');
});
const hasSquare = computed(() => {
  return squareProvider.value !== undefined;
});

EventBus.$on('square-oauth-url-create-success', (data) => {
  openOauthUrl(data.url);
});
EventBus.$on('delete-payment-profile-success', () => {
  removeSquareConfirmDialog.value = false;
  getPaymentProfiles();
});

const squareEmail = computed(() => {
  return squareProvider.value?.email;
});

const squareUuid = computed(() => {
  return squareProvider.value?.uuid;
});
const squareCreatedAt = computed(() => {
  return squareProvider.value?.created_at;
});

const squareOauthInit = () => {
  getSquareOauthUrl().then((response) => {
    if (response.data.url) {
      openOauthUrl(response.data.url);
    }
  });
};
const openOauthUrl = (url) => {

  squareOauthWindow.value = window.open(
    url,
    'Fleetwire.io Square Oauth',
    'scrollbars,status,width=750,height=700',
  );

  squareCheckInterval.value = setInterval(() => {
    if (squareOauthWindow.value.closed) {
      clearInterval(squareCheckInterval.value);
      setSquareLoading({
        subject: 'square_oauth',
        loading: false,
      })

    }
    getSquareProvider().then((response) => {
      if (response.data.square) {
        squareOauthWindow.value.close();
        clearInterval(squareCheckInterval.value);
        getPaymentProfiles();
      }
    });
  }, 1000);
};

const deletePaymentProfileCommit = (uuid: string) => {
  deletePaymentProfile(uuid).then(() => {
    removeSquareConfirmDialog.value = false;
    getPaymentProfiles();
  });
};
</script>

<style scoped>
.square-connect a {
  text-decoration: none;
}

.square-connect {
  background: #635bff;
  display: inline-block;
  height: 38px;
  text-decoration: none;
  width: 180px;

  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  -webkit-font-smoothing: antialiased;
}

.square-connect span {
  color: #ffffff;
  display: block;
  font-family: sohne-var, "Helvetica Neue", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  padding: 11px 0 0 24px;
  position: relative;
  text-align: left;
}

.square-connect:hover {
  background: #7a73ff;
}

.square-connect.slate {
  background: #000000;
}

.square-connect.slate:hover {
  background: #425466;
}

.square-connect.white {
  background: #ffffff;
}

.square-connect.white span {
  color: #0a2540;
}

.square-connect.white:hover {
  background: #f6f9fc;
}

.square-connect span::after {
  background-repeat: no-repeat;
  background-size: 70px;
  content: "";
  height: 30px;
  left: 63%;
  position: absolute;
  top: 20%;
  width: 75px;
}

/* Logos */
.square-connect span::after {
  background-image: url(https://tenant.fleetwire.io/images/logo/Square_LogoLockup_White.png);
}


</style>
