<template>
  <div>
    <v-btn
      v-if="orderBookingId && device !== 'mobile'"
      :to="{ name: 'orders.single', params: {b_id: orderBookingId} }"
      class="mx-3"
      size="small"
      variant="text"
    >
      Order {{ orderBookingId }}
    </v-btn>
    <v-btn v-if="device !== 'mobile'" class="mr-3" color="red" variant="outlined" @click="deleteOrderDocumentInit()">
      <v-icon class="mr-2" size="small">mdi-delete-outline</v-icon>
      Delete
    </v-btn>
    <div v-if="device !== 'mobile'" class="v-btn-group">
      <v-btn variant="flat" @click="printElem()">
        <v-icon class="mr-2" size="small">mdi-printer</v-icon>
        Print
      </v-btn>
      <v-btn variant="flat" @click="downloadOrderDocumentInit()">
        <v-icon class="mr-2" size="small">mdi-download</v-icon>
        Download
      </v-btn>
      <v-btn
        v-if="documentCanBeFinalized"
        :loading="orderLoading.update"
        color="blue"
        variant="flat"
        @click="finalizeDocumentInit()"
      >
        <v-icon class="mr-2" size="small">mdi-check</v-icon>
        Finalize
      </v-btn>
      <!--      <v-btn depressed theme="dark" color="blue">-->
      <!--        <v-icon size="small" class="mr-2">mdi-check</v-icon> Confirm-->
      <!--      </v-btn>-->
      <!--      <v-btn depressed theme="dark" color="green">-->
      <!--        <v-icon size="small" class="mr-2">mdi-email</v-icon> Send-->
      <!--      </v-btn>-->
    </div>
    <v-menu v-if="device === 'mobile'">
      <template v-slot:activator="{ props }">
        <v-btn
          class="ml-1"
          rounded
          v-bind="props"
          variant="outlined"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-if="device === 'mobile'">

          <v-btn
            v-if="orderBookingId"
            :to="{ name: 'orders.single', params: {b_id: orderBookingId} }"
            variant="outlined"
          >
            Order {{ orderBookingId }}
          </v-btn>

        </v-list-item>
        <v-list-item v-if="device === 'mobile'">

          <v-btn color="red" variant="outlined" @click="deleteOrderDocumentInit()">
            <v-icon class="mr-2" size="small">mdi-delete-outline</v-icon>
            Delete
          </v-btn>

        </v-list-item>
        <v-list-item>

          <v-btn variant="flat" @click="printElem()">
            <v-icon class="mr-2" size="small">mdi-printer</v-icon>
            Print
          </v-btn>

        </v-list-item>
        <v-list-item>

          <v-btn variant="flat" @click="downloadOrderDocumentInit()">
            <v-icon class="mr-2" size="small">mdi-download</v-icon>
            Download
          </v-btn>

        </v-list-item>
        <v-list-item>

          <v-btn
            v-if="documentCanBeFinalized"
            :loading="orderLoading.update"
            color="blue"
            variant="flat"
            @click="finalizeDocumentInit()"
          >
            <v-icon class="mr-2" size="small">mdi-check</v-icon>
            Finalize
          </v-btn>

        </v-list-item>
      </v-list>
    </v-menu>
    <StartOrderDocumentDownloadDialog/>

    <v-dialog v-model="deleteOrderDocumentConfirmDialog" width="350">
      <v-card :disabled="orderLoading.deleteOrderDocument">
        <v-card-title>
          Delete Document
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete this document?
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="grey" size="small" variant="text" @click="deleteOrderDocumentConfirmDialog = false;">Nevermind
          </v-btn>
          <v-btn :loading="orderLoading.deleteOrderDocument" color="red text-white" size="small" @click="deleteOrderDocumentCommit($route.params.uuid);">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts" setup>
import {computed, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {EventBus} from '@/plugins/eventBus';
import {useOrders} from '@/composables/useOrders';
import {useAppSettings} from '@/composables/useAppSettings';
import StartOrderDocumentDownloadDialog from '@/components/dashboard/dialogs/StartOrderDocumentDownloadDialog.vue';
import {OrderDocumentType} from '@/types';

const route = useRoute();
const router = useRouter();
const {orderDocument, orderLoading, updateOrderDocument, deleteOrderDocument} = useOrders();
const {device} = useAppSettings();

// Reactive state
const deleteOrderDocumentConfirmDialog = ref(false);

// Computed properties
const orderBookingId = computed<string>(() => orderDocument.value?.order?.b_id);
const documentType = computed<OrderDocumentType['type']>(() => orderDocument.value?.type);
const documentCanBeFinalized = computed(() =>
  documentType.value === 'Document::Invoice' && !orderDocument.value?.finalized
);

// Methods
const finalizeDocumentInit = () => {
  updateOrderDocument({
    documentUuid: orderDocument.value?.uuid,
    attributes: {
      finalize: true,
    },
  });
};

const deleteOrderDocumentInit = () => {
  deleteOrderDocumentConfirmDialog.value = true;
};

const deleteOrderDocumentCommit = async (uuid: string) => {
  console.log('deleteOrderDocumentCommit');
  try {
    await deleteOrderDocument(uuid);
    router.push('/documents');
  } catch (error) {
    console.error('Failed to delete document:', error);
  }
};

const printElem = () => {
  window.print();
};

const downloadOrderDocumentInit = () => {
  console.log('downloadOrderDocumentInit');
  EventBus.$emit('open-download-order-document-dialog', route.params.uuid);
};
</script>


<style lang="scss">
.v-btn-group {
  vertical-align: middle;
  border-radius: 2px;
  display: inline-flex;

  .v-btn {
    border-radius: 0;
    justify-content: center;
    margin: 0;
    min-width: auto;
    padding: 0 8px;
    width: auto;

    &:not(:last-child) {
      border-right: 1px solid transparent;
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
}
</style>
