<template>
  <div id="wrapper" class="print-area">
    <div id="page" section="documents">
      <div id="content">
        <div id="document" class="document-quote">
          <div class="block">
            <div id="top" class="">
              <div v-if="documentPaid" id="stamp">
                <img id="paid" alt="Stamp" src="https://fleetwire.io/images/stamps/stamp-paid.png">
              </div>
              <div v-if="company.logo" id="logo-wrapper">
                <img id="logo" :src="company.logo" alt="Company Logo">
              </div>

              <div class="lefter">
                <div id="user-info" :class="editUser ? 'editing' :''">
                  <strong class="customer-name">
                    {{ customer.first_name }} {{ customer.last_name }}
                  </strong>
                  <v-btn
                    v-if="!editUser"
                    class="absolute no-print"
                    size="small"
                    style="top: -5px;"
                    variant="text"
                    @click="editUser = true;"
                  >
                    <v-icon size="x-small">mdi-pencil</v-icon>
                  </v-btn>
                  <form action="#">
                    <div class="show-user flex">
                      <pre class="pre-address" v-html="customerAddress"></pre>
                    </div>
                    <div class="edit-user">
                      <v-textarea
                        v-model="customerAddress"
                        hide-details
                        label="Address"
                        name="input-7-4"
                        rows="2"
                        variant="outlined"
                      ></v-textarea>
                      <div class="float-right mt-2">
                        <v-btn class="mr-2" size="small" variant="text" @click="editUser = false;">
                          <v-icon size="small">mdi-close</v-icon>
                        </v-btn>
                        <v-btn size="small" variant="text" @click="updateCustomerAddress(); editUser = false;">Save
                        </v-btn>
                      </div>
                    </div>
                    <button style="display: none;" type="submit"></button>
                  </form>
                  <div class="customer-email">{{ customer.email }}</div>
                  <div class="additional-information"></div>
                </div>
              </div>

              <div class="righter">
                <div id="company-info">
                  <strong class="company-name">{{ company.name }}</strong><br>
                  <div v-if="companyAddress.address_1">
                    {{ companyAddress.address_1 }}<br>
                    <span v-if="companyAddress.address_2" class="block">{{ companyAddress.address_2 }}</span>
                    <span v-if="companyAddress.city">{{ companyAddress.city }}, </span>
                    <span v-if="companyAddress.state">{{ companyAddress.state }} </span>
                    <span v-if="companyAddress.postal_code">{{ companyAddress.postal_code }} </span>
                  </div>
                  <span class="company-phone">{{ company.phone_fmt || company.phone }}</span><br>
                  <span class="company-email">{{ company.email }}</span><br><br>
                  <span class="financial-line-1"></span><br>
                  <span class="financial-line-2"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="name-dates">
            <div class="grid grid-cols-2">
              <div class="">
                <h2 v-if="!editDocumentNumber" class="table-form-title">
                  <span>{{ documentTitle }}</span>
                  <v-btn v-if="!editDocumentNumber" class="no-print" size="small" variant="text" @click="editDocumentNumber = true;">
                    <v-icon size="small">mdi-pencil</v-icon>
                  </v-btn>
                </h2>
                <div v-if="editDocumentNumber" class="flex">
                  <h2 class="table-form-title mr-2">{{ documentType }} #</h2>
                  <v-text-field
                    v-model="documentNumber"
                    append-icon="mdi-content-save"
                    density="compact"
                    hide-details
                    style="max-width: 100px;"
                    variant="outlined"
                    @keyup.enter="updateOrderDocumentNumber(); editDocumentNumber = !editDocumentNumber"
                    @click:append="updateOrderDocumentNumber(); editDocumentNumber = !editDocumentNumber"
                  ></v-text-field>
                </div>
              </div>

              <div class="dates">
                <form action="#">
                  <div class="document-date">
                    <b>Date</b>
                    <span>{{ formatRentalDate(orderDocument?.date, 'MMM DD, YYYY') }}</span>
                    <v-dialog
                      v-if="orderDocument?.date"
                      ref="dialog"
                      v-model="editDateDialog"
                      v-model:return-value="orderDocument.date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ props }">
                        <v-btn size="small" v-bind="props" variant="text">
                          <v-icon size="x-small">mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <v-date-picker
                        v-model="documentDate"
                        scrollable
                      >
                        <template v-slot:actions>
                          <v-btn
                            color="gray"
                            variant="text"
                            @click="editDateDialog = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="primary"
                            variant="text"
                            @click="updateOrderDocumentDate()"
                          >
                            OK
                          </v-btn>
                        </template>
                        <v-spacer/>

                      </v-date-picker>
                    </v-dialog>
                  </div>
                </form>
                <div class="starts-at">
                  <b>Pickup</b>
                  <span v-if="orderDocument?.starts_at">{{ formatRentalDate(orderDocument?.starts_at) }}</span>
                </div>
                <div class="stops-at">
                  <b>Return</b>
                  <span v-if="orderDocument?.stops_at">{{ formatRentalDate(orderDocument?.stops_at) }}</span>
                </div>
                <div class="stops-at">
                  <b>Booking ID</b>
                  <span v-if="documentBookingId">{{ documentBookingId }}</span>
                </div>
              </div>
            </div>
          </div>


          <div id="products-table">
            <div>
              <div class="">
                <form action="#">
                  <table class="table">
                    <tbody>
                    <tr>
                      <th class="auto-width"></th>
                      <th class="table-form-title"></th>
                      <th class="auto-width charge"></th>
                      <th class="text-right auto-width price">Price</th>
                      <th class="text-right vat">Tax</th>
                      <th class="text-right auto-width total">Total</th>
                    </tr>
                    </tbody>
                    <tbody>

                    <tr v-for="(line) in orderDocument?.lines" :key="line.uuid" class="line">
                      <td class="auto-width quantity">{{ line.quantity }}x</td>
                      <td class="table-form-title" colspan="1">
                        <div class="line-title">
                          {{ line.title }}
                        </div>
                        <div class="line-extra">{{ line.extra_information }}</div>
                        <div class="line-custom"></div>
                      </td>
                      <td class="auto-width charge">
                        {{ line.charge_units }}
                      </td>
                      <td class="text-right auto-width price">
                        {{ formatMoneyDisplayV2(line.price_each_in_cents) }}
                      </td>
                      <td class="text-right auto-width tax">
                        {{ getTaxCategoryParentName(line) }}
                      </td>
                      <td class="text-right auto-width total">
                        {{ formatMoneyDisplayV2(line.price_in_cents) }}
                      </td>
                    </tr>
                    </tbody>

                    <tbody id="totals">
                    <tr class="reference">
                      <td class="print-hide" colspan="3" rowspan="100%">
                        <span v-if="!editReference" class="printed-reference-text">
                          {{ orderReference }}
                        </span>
                        <v-btn v-if="!editReference" class="no-print" size="small" variant="text" @click="editReference = true">
                          <v-icon size="small">mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="!editReference && !orderReference"
                          class="no-print underline"
                          size="small"
                          variant="text"
                          @click="editReference = true;"
                        >
                          Insert a reference
                        </v-btn>
                        <div v-if="editReference">
                          <v-textarea
                            v-model="orderReference"
                            hide-details
                            label="Reference"
                            name="input-7-4"
                            rows="5"
                            variant="outlined"
                          />
                          <div class="float-right mt-2">
                            <v-btn :loading="orderLoading.update" class="mr-2" size="small" variant="text" @click="editReference = false;">
                              <v-icon size="small">mdi-close</v-icon>
                            </v-btn>
                            <v-btn :loading="orderLoading.update" size="small" variant="text" @click="updateOrderReference(); editReference = false;">
                              Save
                            </v-btn>
                          </div>
                        </div>
                      </td>
                      <td class="print-show" colspan="3" rowspan="100%">{{ orderReference }}</td>
                      <td class="text-right subtotal" colspan="2">Subtotal</td>
                      <td class="text-right subtotal">{{ formatMoneyDisplayV2(orderDocument?.price_in_cents) }}</td>
                    </tr>
                    <tr v-if="orderDocument?.total_discount_in_cents > 0">
                      <td class="divider" colspan="3">
                        <hr>
                      </td>
                    </tr>
                    <tr v-if="orderDocument?.discount_in_cents > 0" class="total">
                      <td class="text-right total" colspan="2">Discount</td>
                      <td class="text-right total">-{{ formatMoneyDisplayV2(orderDocument?.discount_in_cents) }}</td>
                    </tr>
                    <tr v-if="orderDocument?.coupon_discount_in_cents > 0" class="total">
                      <td class="text-right total" colspan="2">Coupon</td>
                      <td class="text-right total">-{{
                          formatMoneyDisplayV2(orderDocument?.coupon_discount_in_cents)
                        }}
                      </td>
                    </tr>
                    <tr v-if="orderDocument?.total_discount_in_cents > 0" class="total">
                      <td class="text-right total" colspan="2">Total discount</td>
                      <td class="text-right total">-{{
                          formatMoneyDisplayV2(orderDocument?.total_discount_in_cents)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="divider" colspan="3">
                        <hr>
                      </td>
                    </tr>
                    <tr class="total">
                      <td class="text-right total" colspan="2">Total excl. taxes</td>
                      <td class="text-right total">{{ formatMoneyDisplayV2(orderDocument?.grand_total_in_cents) }}</td>
                    </tr>
                    <tr v-if="orderDocument?.total_tax_in_cents > 0" class="tax">
                      <td class="text-right vat" colspan="2">Taxes</td>
                      <td class="text-right vat">{{ formatMoneyDisplayV2(orderDocument?.total_tax_in_cents) }}</td>
                    </tr>
                    <tr>
                      <td class="divider" colspan="3">
                        <hr>
                      </td>
                    </tr>
                    <tr class="grand-total">
                      <td class="text-right bold grand-total" colspan="2">Total incl. taxes</td>
                      <td class="text-right bold grand-total">
                        {{ formatMoneyDisplayV2(orderDocument?.grand_total_with_tax_in_cents) }}
                      </td>
                    </tr>
                    <tr class="deposit">
                      <td class="text-right deposit" colspan="2">Security deposit</td>
                      <td class="text-right deposit">{{ formatMoneyDisplayV2(orderDocument?.deposit_in_cents) }}</td>
                    </tr>
                    <tr>
                      <td class="divider" colspan="3">
                        <hr>
                      </td>
                    </tr>
                    <tr class="grand-total">
                      <td class="text-right bold grand-total" colspan="2">Paid</td>
                      <td class="text-right bold grand-total">{{
                          formatMoneyDisplayV2(orderDocument?.paid_in_cents)
                        }}
                      </td>
                    </tr>
                    <tr class="grand-total">
                      <td class="text-right bold grand-total bold" colspan="2">Outstanding</td>
                      <td class="text-right bold grand-total">{{
                          formatMoneyDisplayV2(orderDocument?.to_be_paid_in_cents)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="100%"><br></td>
                    </tr>
                    </tbody>
                  </table>
                  <button style="display: none;" type="submit"></button>
                </form>
              </div>

              <div id="footer" :class="footer.blocks ? '' : 'blank'">
                <div :class="addEditFooter ? 'editing' : 'view'">
                  <div v-if="!addEditFooter" class="rx-content" v-html="convertBlocksToHtml(footer.blocks)"></div>
                  <v-btn v-if="!addEditFooter" class="float-right no-print" size="x-small" variant="text" @click="addEditFooter = true;">
                    <span class="underline">Add or edit footer</span>
                  </v-btn>
                  <div class="editor text-editor">
                    <div id="editorjs"></div>
                  </div>
                  <v-btn v-if="addEditFooter" class="text-right mt-2" @click="updateFooter()">Save</v-btn>
                </div>
              </div>
              <button style="display: none;" type="submit"></button>
            </div>

            <br class="clear">
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, onBeforeMount, onBeforeUnmount, ref, watch} from 'vue';
import {EventBus} from '@/plugins/eventBus';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import {useMoneyFormatter} from '@/composables/useMoneyFormatter';
import {useDateFormatter} from '@/composables/useDate';
import {useCompany} from '@/composables/useCompany';
import dayjs from 'dayjs';
import {useRoute} from 'vue-router';
import {useOrders} from '@/composables/useOrders';
import {OrderLine} from '@/types';

const route = useRoute();
const {formatMoneyDisplayV2} = useMoneyFormatter();
const {formatRentalDate} = useDateFormatter();
const {company} = useCompany();
const {orderLoading, orderDocument, getOrderDocument, updateOrderDocument, setOrderDocument} = useOrders();

const documentUuid = route.params.uuid as string;

const documentDate = ref(null);
const selected = ref([]);
const options = ref({});
const addEditFooter = ref(false);
const editDateDialog = ref(false);
const editDocumentNumber = ref(false);
const editUser = ref(false);
const editReference = ref(false);
const customer = ref({
  first_name: null,
  last_name: null,
  email: null,
  phone: null,
});

const editorjs = ref<EditorJS | null>(null);
const footer = ref({blocks: null});
const documentDataCopy = ref(null);

const customerAddress = computed({
  get: () => orderDocument.value?.address,
  set: (value: string) => {
    if (orderDocument.value) {
      orderDocument.value.address = value;
    }
  }
});

const orderReference = computed({
  get: () => orderDocument.value?.reference,
  set: (value: string) => {
    if (orderDocument.value) {
      orderDocument.value.reference = value;
    }
  }
});

const documentNumber = computed({
  get: () => orderDocument.value?.number,
  set: (value: string) => {
    if (orderDocument.value) {
      orderDocument.value.number = value;
    }
  }
});

const updateFooter = async () => {
  if (!editorjs.value) return;

  try {
    const outputData = await editorjs.value.save();
    console.log('Saved footer data:', outputData);
    footer.value.blocks = outputData;

    await updateOrderDocument({
      documentUuid,
      attributes: {footer: footer.value.blocks}
    });
    addEditFooter.value = false;
    // Destroy the editor instance after saving
    editorjs.value.destroy();
    editorjs.value = null;
  } catch (error) {
    console.error('Footer save failed:', error);
  }
};

const documentTitle = computed(() => orderDocument.value?.title);
const documentBookingId = computed(() => orderDocument.value?.order?.b_id);
const documentStatus = computed(() => orderDocument.value?.status);
const documentPaid = computed(() =>
  ['overpaid', 'paid'].includes(documentStatus.value?.toLowerCase() || '')
);
const documentType = computed(() =>
  orderDocument.value?.type.split('::')[1] || 'Quote'
);
const companyAddress = computed(() => company.value?.address);

watch(addEditFooter, (newValue) => {
  if (newValue) {
    // Ensure any existing editor is destroyed before creating a new one
    if (editorjs.value) {
      editorjs.value.destroy();
      editorjs.value = null;
    }

    // Create new EditorJS instance with current footer data
    editorjs.value = new EditorJS({
      autofocus: true,
      holder: 'editorjs',
      tools: {
        header: Header,
      },
      data: footer.value.blocks || undefined,
      onReady: () => {
        console.log('EditorJS initialized with data:', footer.value.blocks);
      }
    });
  }
});

// Watchers
watch(orderDocument, (document) => {
  if (document) {
    documentDataCopy.value = JSON.parse(JSON.stringify(document));
    customer.value = {
      first_name: document.customer?.first_name,
      last_name: document.customer?.last_name,
      email: document.customer?.email,
      phone: document.customer?.phone
    };
    if (document.footer) {
      footer.value.blocks = document.footer;
    }
  }
}, {immediate: true});

onBeforeMount(() => {
  getOrderDocument(documentUuid);
});

// Methods
const convertBlocksToHtml = (data: any) => {
  if (!data?.blocks) return '';

  let html = '';
  data.blocks.forEach((block: any) => {
    const value = block.data?.text;
    if (block.type === 'paragraph') {
      html += `<p>${value}</p>`;
    } else if (block.type === 'header') {
      const h = block.data?.level;
      html += `<h${h}>${value}</h${h}>`;
    }
  });
  return html;
};

const getTaxCategoryParentName = (line: OrderLine) => {
  return line?.tax_category?.name;
};

const updateCustomerAddress = () => {
  updateOrderDocument({
    documentUuid,
    attributes: {address: customerAddress.value}
  });
};

const updateOrderDocumentDate = () => {
  updateOrderDocument({
    documentUuid,
    attributes: {
      date: dayjs(documentDate.value).format('YYYY-MM-DD')
    }
  }).then(() => {
    editDateDialog.value = false;
  });
};

const updateOrderDocumentNumber = () => {
  updateOrderDocument({
    documentUuid,
    attributes: {number: documentNumber.value}
  });
};

const updateOrderReference = () => {
  updateOrderDocument({
    documentUuid,
    attributes: {reference: orderReference.value}
  });
};

onBeforeUnmount(() => {
  if (editorjs.value) {
    editorjs.value.destroy();
    editorjs.value = null;
  }

  // @ts-ignore
  setOrderDocument(null);
  EventBus.$off('update-order-document-error');
});

// Event listeners
EventBus.$on('update-order-document-error', () => {
  documentNumber.value = documentDataCopy.value?.number;
});

</script>

<style>
.float-right {
  float: right !important;
}

#wrapper {
  float: left;
  width: 100%;
  height: 100%;
  min-width: 708px;
}
</style>

<style id="print-css">

.print-show {
  display: none;
}

#page {
  max-width: 1060px;
  min-height: 29.7cm;
  background: white;
  display: block;
  margin: 0 auto 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}

#document {
  margin: 0 auto;
  max-width: 1060px;
  position: relative;
  overflow: hidden;
  padding: 0 40px 40px 40px;
}

#document #top {
  float: left;
  width: 100%;
  font-weight: 300;
  padding: 40px 0 0 0;
}

#document #top #logo-wrapper {
  position: absolute;
  top: 40px;
  right: 40px;
  display: table-cell;
  max-width: 175px;
  max-height: 175px;
}

#document #top .lefter {
  float: left;
  width: 50%;
}

#document #top .righter {
  float: right;
  width: 50%;
}

#document #top #user-info {
  font-size: 13px;
  position: relative;
}

#document #top .additional-information {
  margin-top: 20px;
}

#document #top #company-info {
  font-size: 13px;
  max-width: 200px;
}

#document #products {
  float: left;
  width: 100%;
  margin-top: 40px;
}

#document #products .dates {
  font-size: 12px;
  line-height: 18px;
  float: right;
  width: 50%;
  margin-top: -20px;
}

#document #products .dates > span,
#document #products .dates .document-date {
  display: block;
}


.name-dates > div b,
.document-date b {
  width: 75px;
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
}

.name-dates > div span {
  display: inline-block;
  font-size: 12px;
  margin-left: 10px;
}

#document #products table .line-title {
  font-weight: bold;
}

#document #products h2.table-form-title {
  font-weight: 200;
  font-size: 30px;
  margin: 0 0 40px 0;
  color: #1C2A30;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}


#page table, #page .table {
  margin: 0;
  font-size: 13px;
  font-weight: 300;
  border-collapse: separate;
}

#document #products table tbody {
  background: #fff;
}

#page table tbody,
#page .table tbody {
  color: #1C2A30;
  border: 0;
}

table th, .table th {
  white-space: nowrap;
}

table th, table td, .table th, .table td {
  border-color: #DDE1E3;
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
table > tfoot > tr > th,
.table > tfoot > tr > td {
  padding: 10px 20px;
  line-height: 1.428571429;
  vertical-align: top;
  border-top: 1px solid #DDE1E3;
}

table th.auto-width,
table td.auto-width,
.table th.auto-width,
.table td.auto-width {
  width: 1px;
  white-space: nowrap;
}

#page table th,
#page table td,
#page .table th,
#page .table td {
  vertical-align: middle;
}

table th.total,
table th.price {
  width: 100px;
}

table th.total,
table th.vat {
  padding-right: 0 !important;
}

table th.vat {
  width: 120px;
}

table tbody:first-child th {
  border-top: 0;
}

#page table tbody#totals tr,
#page .table tbody#totals tr {
  padding: 0;
}

table td.tax,
table td.total,
table td.subtotal,
table td.grand-total,
table td.deposit {
  padding-right: 0 !important;
}

table td {
  vertical-align: top !important;
}

table .line-extra {
  font-size: 12px;
  margin-top: 4px;
  white-space: pre-wrap;
}


#page table tbody#totals tr td,
#page .table tbody#totals tr td {
  border: 0;
  padding: 5px 15px;
  white-space: nowrap;
}

#page table tbody#totals tr td.divider,
#page .table tbody#totals tr td.divider {
  padding: 10px 0;
}


#page table tbody#totals tr td.right,
#page .table tbody#totals tr td.right {
  padding-right: 0;
}

a.underline {
  text-decoration: underline;
}

#page table th.bold,
#page table td.bold,
#page .table th.bold,
#page .table td.bold {
  font-weight: bold;
}

table th.right,
table td.right,
.table th.right,
.table td.right {
  text-align: right;
}


#body {
  border: 0 !important;
}

#footer {
  font-size: 12px;
}

#footer,
#body {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px #DDE1E3 solid;
  float: left;
  width: 100%;
  font-weight: 400;
}


#footer a.right,
#body a.right {
  text-align: right;
  width: 100%;
  display: inline-block;
}

#footer a.underline,
#body a.underline {
  font-size: 12px;
  font-weight: 400;
}

#footer .edit,
#body .edit {
  display: none;
}


#document #top #user-info .edit-user {
  display: none;
  padding-right: 40px;
}

#document #top #user-info.editing .edit-user {
  display: block;
}

#document #top #user-info.editing .show-user {
  display: none;
}


#footer .editing .editor {
  height: 200px;
  padding: 20px;
  border: 1px #DDE1E3 solid;
  cursor: text;
}

#footer .view .editor {
  display: none;
}

#footer .editing .editor {
  float: left;
  width: 100%;
  max-height: calc(70vh - 125px);
  overflow-y: scroll;
}

#text-editor .ProseMirror {
  height: 362px !important;
}


#footer .ce-block__content,
#footer .ce-toolbar__content {
  max-width: calc(100% - 25px);
}

.pre-address {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  border: none;
}

@media print {
  body * {
    visibility: hidden;
  }

  .print-area, .print-area * {
    visibility: visible;
  }

  * {
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
  }

  *, *:before, *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  @page {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  body {
    color: #1C2A30;
    min-width: unset;
    padding: 0 !important;
    margin: 0;
  }

  #page {
    max-width: unset !important;
    min-height: unset !important;
    margin: unset !important;
    box-shadow: unset !important;
  }

  #app.bg-light {
    background-color: #FFFFFF !important;
  }

  #page {
    width: unset;
    min-height: unset;
    margin: 0 !important;
  }

  .print-hide {
    display: none;
  }

  .no-print {
    display: none;
  }

  .main-container {
    padding: 0 !important;
  }

  #document #top {
    float: left;
    width: 100%;
    font-weight: 300;
    padding: 0 0 0 0;
  }

  #page h1 {
    font-size: 2em;
  }

  #page h2 {
    font-size: 1.5em;
  }

  #page h3 {
    font-size: 1.17em;
  }

  #page h4 {
    font-size: 1.12em;
  }

  #page h5 {
    font-size: .83em;
  }

  #page h6 {
    font-size: .75em;
  }

  [section=documents] #content {
    padding: 0;
  }

  [section=documents] #document {
    border: none !important;
    padding: 0 !important;
    border-radius: unset;
    -webkit-box-shadow: unset;
    box-shadow: unset;
  }

  [section=documents] table.table tr td,
  [section=documents] table.table tr th {
    padding: 8px !important;
    border-color: #f1f1f1 !important;
  }

  [section=documents] #totals tr:first-child td {
    border-top: 1px #dde1e3 solid !important;
    padding-top: 18px !important;
  }

  [section=documents] #totals tr td {
    padding: 5px 0 !important;
  }

  [section=documents] .printed-reference-text {
    width: 100%;
    white-space: normal;
  }

  .print-show {
    display: table-cell;
  }

  .printed-reference-text {
    width: 100%;
    white-space: normal;
  }

  .main-content {
    margin: 0 !important;
    padding: 0 !important;
    height: unset !important;
  }

  .eUcdHi {
    padding: unset;
  }

  .navbar-custom {
    display: none !important;
  }

  .app-wrapper {
    margin-left: 0 !important;
    padding: 0 !important;
  }

  .dashboard-sidebar {
    display: none !important;
  }

  .mainNavBar__mobileMenuOpen {
    display: none !important;
  }

  .fw-container-fluid {
    padding: unset !important;
  }

  .v-application .my-3 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  [section=documents] #footer.blank,
  [section=documents] #body.blank {
    display: none;
  }

  [section=documents] #totals tr:first-child td {
    border-top: 1px #dde1e3 solid !important;
    padding-top: 18px !important;
  }
}
</style>


<style>
#document #stamp {
  display: inline-block;
  opacity: 0.1;
  top: 0;
  left: 0;
  position: absolute;
  width: 250px;
  height: 250px;
  pointer-events: none;
}

#document #stamp img#paid {
  height: 250px;
  top: -55px;
  left: -60px;
  position: absolute;
}
</style>
