<template>
  <div class="UpsellCard">
    <div>
      <v-card class="bg-white" variant="flat">
        <v-card-title>
          Upgrade to Premium
        </v-card-title>

        <v-card-text>
          <div class="grid grid-cols-2 justify-between">
            <div class="">More listings, more staff accounts, custom domain, additional scripts, and more. Upgrade to
              Fleetwire's Premium plan today.
            </div>
            <div class="grid col-auto grid-flow-col gap-2 justify-end">

              <v-dialog v-model="upgradeDialogIsOpen" width="950">
                <template v-slot:activator="{ props }">
                  <v-btn size="small" v-bind="props">Learn More</v-btn>
                </template>

                <template v-slot:default="{ isActive }">
                  <v-card>
                    <v-img class="app-15cj5iy e1xdmtt71" data-element="PositionTopRight" data-source-file="highlightModalContainer.tsx" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDE0IiBoZWlnaHQ9Ijg1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMCAwaDQxNHY3OC4zMDZMMjk2Ljc3NCA4NC4xNlY2OGgtMjcuMzMybC00LjE5Ny0zNC43MUw3LjUyNyAzMC4xMSAwIDB6IiBmaWxsPSIjRDlDRkU5Ii8+PHBhdGggZD0iTTIyOS44ODYgNDQuMjQ0aDI1LjA5MWwuODM2IDE1Ljg5LTI1LjkyNy0yLjE3NFY0NC4yNDR6IiBmaWxsPSIjRDlDRkU5Ii8+PC9zdmc+" width="400px"></v-img>
                    <div style="position: relative;padding: 70px 80px;overflow: hidden;">

                      <div class="try-premium-modal">
                        <div>
                          <div style="text-transform: uppercase;font-weight: bold;color: rgb(108, 95, 199);font-size: 11px;">
                            Next stop - Premium Plan
                          </div>
                          <div style="font-size: 22px;font-weight: bold;margin: 8px 0;">Try the upgrade. Stress less.
                          </div>
                        </div>
                        <div class="main-upsell">
                          <div>
                            <div class="app-1fttcpj e6gn4392">
                              <div>
                                {{ stepDescription }}
                              </div>
                              <v-img :src="stepImage" class="app-1jbawm2 e6gn4391" width="540px"></v-img>
                              <div class="app-10re2i4 e6gn4390" v-html="stepSecondDescription"/>
                            </div>
                          </div>

                          <div>
                            <div class="app-1vbqv9y e1qu3fq81">Premium Features Include</div>
                            <div v-for="(feature, index) in features" :key="index" :aria-selected="featureStep === index" :style="featureListStyle(index)" class="app-s85quu e1qu3fq80" @click="featureStep = index">
                              <a v-if="feature.ctaUrl" :href="feature.ctaUrl" target="_blank">
                                <v-icon size="small">mdi-flash-outline</v-icon>
                                {{ feature.title }}
                              </a>
                              <span v-else>
                                <v-icon size="small">mdi-flash-outline</v-icon>
                                {{ feature.title }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>


                      <v-card-actions>
                        <v-btn :to="{name: 'settings.account.plans.confirm'}" color="primary" variant="tonal">Upgrade
                        </v-btn>
                        <v-btn variant="outlined" @click="isActive.value = false">Maybe Later</v-btn>
                      </v-card-actions>
                    </div>

                    <v-img class="app-1n8nfba e1xdmtt70" data-element="PositionBottomLeft" data-source-file="highlightModalContainer.tsx" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzExIiBoZWlnaHQ9IjE0NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTI5OC4wMTMgMTQ0SC4wMDFWMGwxMDIuNzQgNy4wOTQgMi40NjcgODAuODI0IDY0LjE0MSAzLjQzMyAxLjI2NyAyNS4wMTEgMTI0Ljk0My0xLjhMMjk4LjAxMyAxNDR6IiBmaWxsPSIjRDlDRkU5Ii8+PHBhdGggZD0iTTEyMC40NTEgNjQuOTA2djE1LjYwNmwtMTEuMzE2LS43MDktLjI0OS0xNS4yNTEgMTEuNTY1LjM1NHpNMzA5LjU3OCAxMTMuMTQzbDEuNDIzIDE1LjUzNS0xMC4xNDEgMi41NTQtMS40OTUtMTcuNTIyIDEwLjIxMy0uNTY3eiIgZmlsbD0iI0Q5Q0ZFOSIvPjwvc3ZnPg==" width="200px"/>
                  </v-card>
                </template>
              </v-dialog>

              <v-btn :to="{name: 'settings.account.plans.confirm'}" color="primary" size="small">Upgrade</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref, watch} from 'vue';

const upgradeDialogIsOpen = ref(false);
const featureStep = ref(0);
const safeFeatureStep = computed(() => Math.min(featureStep.value, features.length - 1));

const features: Feature[] = [
  {
    description: 'Expand your visibility by adding and managing up to 150 vehicle listings.',
    imageUrl: '/images/up-sell/listings2.png',
    title: 'Additional Listings',
    secondDescription: "You'll need the <strong>Premium Plan</strong> to enable 150 listings. You're\n" +
      "                                currently on the Standard plan.",
  },
  {
    description: 'Coordinate your team by adding and managing up to 20 staff accounts for improved collaboration.',
    imageUrl: '/images/up-sell/team2.png',
    title: 'Additional Staff Accounts',
    secondDescription: "You'll need the <strong>Premium Plan</strong> to enable 150 listings. You're\n" +
      "                                currently on the Standard plan.",
  },
  {
    description: 'Broaden your operational presence by adding and managing up to 2 additional branch locations.',
    imageUrl: 'https://s1.sentry-cdn.com/_static/dist/sentry/assets/cross-project.bd06afab95685c8b6ad5.svg',
    title: 'Additional Branches',
    secondDescription: "You'll need the <strong>Premium Plan</strong> to enable 150 listings. You're\n" +
      "                                currently on the Standard plan.",
  },
  {
    description: 'Fortify your brand\'s digital footprint with a seamless connection to a custom domain for your Fleetwire store, inclusive of a secure SSL certificate.',
    imageUrl: 'https://s1.sentry-cdn.com/_static/dist/sentry/assets/change-alerts.53afc4cd6161b91abbc4.svg',
    title: 'Custom Domain',
    secondDescription: "You'll need the <strong>Premium Plan</strong> to enable 150 listings. You're\n" +
      "                                currently on the Standard plan.",
  },
  {
    description: 'Professionalize your communication with a custom email domain that aligns with your brand identity.',
    imageUrl: 'https://s1.sentry-cdn.com/_static/dist/sentry/assets/cross-project.bd06afab95685c8b6ad5.svg',
    title: 'Custom Email Domain',
    secondDescription: "You'll need the <strong>Premium Plan</strong> to enable 150 listings. You're\n" +
      "                                currently on the Standard plan.",
  },
  {
    description: 'Tailor the checkout experience by adding custom fields to gather unique information like insurance details, secondary drivers, etc.',
    imageUrl: 'https://s1.sentry-cdn.com/_static/dist/sentry/assets/change-alerts.53afc4cd6161b91abbc4.svg',
    title: 'Custom Checkout Fields',
    secondDescription: "You'll need the <strong>Premium Plan</strong> to enable 150 listings. You're\n" +
      "                                currently on the Standard plan.",
  },
  {
    description: 'Empower your site with enhanced analytics and tracking through customizable scripts and styles for services like Google Analytics and Facebook Pixel.',
    imageUrl: 'https://s1.sentry-cdn.com/_static/dist/sentry/assets/cross-project.bd06afab95685c8b6ad5.svg',
    title: 'Additional Scripts & Styles',
    secondDescription: "You'll need the <strong>Premium Plan</strong> to enable 150 listings. You're\n" +
      "                                currently on the Standard plan.",
  },
  {
    description: 'Unleash Unlimited Functionality: Harness the Power of our Flexible API and Webhooks to Build Your Own Custom Integrations and Expand the Capabilities of Fleetwire.',
    imageUrl: 'https://s1.sentry-cdn.com/_static/dist/sentry/assets/change-alerts.53afc4cd6161b91abbc4.svg',
    title: 'API',
    secondDescription: "You'll need the <strong>Premium Plan</strong> to enable 150 listings. You're\n" +
      "                                currently on the Standard plan.",
  },
  {
    description: 'Receive top-tier assistance with our premium email support, dedicated to resolving your queries with priority.',
    imageUrl: 'https://s1.sentry-cdn.com/_static/dist/sentry/assets/cross-project.bd06afab95685c8b6ad5.svg',
    title: 'Premium Email Support',
    secondDescription: "You'll need the <strong>Premium Plan</strong> to enable 150 listings. You're\n" +
      "                                currently on the Standard plan.",
  },
  {
    description: 'Explore the full spectrum of advanced features and exclusive benefits with our Premium Plan—visit our pricing page to discover more..',
    imageUrl: 'https://s1.sentry-cdn.com/_static/dist/sentry/assets/change-alerts.53afc4cd6161b91abbc4.svg',
    ctaUrl: 'https://front.fleetwire.io/pricing/',
    title: 'And More...',
    secondDescription: "You'll need the <strong>Premium Plan</strong> to enable 150 listings. You're\n" +
      "                                currently on the Standard plan.",
  }
];

interface Feature {
  description: string;
  imageUrl: string;
  title: string;
  secondDescription: string;
  ctaUrl?: string;
}

const stepDescription = computed(() => features[safeFeatureStep.value].description);
const stepImage = computed(() => features[safeFeatureStep.value].imageUrl);
const stepSecondDescription = computed(() => features[safeFeatureStep.value].secondDescription);

const featureListStyle = computed(() => (index: number) => {
  return {
    transform: featureStep.value === index ? 'translateX(-7px)' : 'translateX(0px)',
  };
});

watch(() => upgradeDialogIsOpen.value, (newVal) => {
  if (!newVal) {
    featureStep.value = 0;
  }
});
</script>

<style scoped>

.main-upsell {
  display: grid;
  font-size: 14px;
  margin-bottom: 16px;
}

.app-1vbqv9y {
  font-weight: bold;
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 4px;
}

.app-s85quu[aria-selected="true"] {
  color: rgb(62, 52, 70);
  font-weight: bold;
}

.app-s85quu {
  cursor: pointer;
  transition: color 300ms ease 0s;
  color: rgb(128, 112, 143);
  position: relative;
  display: grid;
  grid-template-columns: max-content auto;
  gap: 8px;
  align-items: center;
  align-content: center;
  margin-bottom: 4px;
}

.app-1fttcpj {
  display: flex;
  flex-direction: column;
}

.app-1jbawm2 {
  margin: 16px 0;
}

.app-10re2i4 {
  font-size: 11px;
  line-height: 1.5;
}

.app-15cj5iy {
  position: absolute;
  width: 400px;
  right: 0;
  top: 0;
  pointer-events: none;
  border-radius: 0 8px 0 0;
}

.app-1n8nfba {
  position: absolute;
  width: 200px;
  bottom: 0;
  left: 0;
  pointer-events: none;
  border-radius: 0 0 0 8px;
  z-index: -1;
}

@media (min-width: 800px) {
  .main-upsell {
    grid-template-columns: auto 200px;
    gap: 30px;
  }
}

</style>
