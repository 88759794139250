import type {RouteRecordRaw} from 'vue-router';
import BaseLogin from '@/views/auth/BaseLogin.vue';

import Login from '@/views/auth/Login.vue';
import Register from '@/views/auth/Register.vue';
import RegisterNextStep from '@/views/auth/RegisterNextStep.vue';
import CompanyEmployeeInvite from '@/views/auth/companyEmployeeInvite.vue';
import PasswordRequest from '@/views/auth/password/email.vue';
import PasswordReset from '@/views/auth/password/reset.vue';
import PasswordVerify from '@/views/auth/password/verify.vue';
import PasswordResend from '@/views/auth/password/resend.vue';
import guest from "@/middleware/guest";
import {CommonRouteMeta} from "@/types";

const authRoutes: RouteRecordRaw = {
    path: '/',
    component: BaseLogin,
    meta: {
        title: 'home',
        icon: 'mdi-login',
        noCache: false,
        hidden: true,
        layout: 'Default',
        requiresAuth: false,
        middleware: [guest],
    } as CommonRouteMeta,
    children: [
        {
            path: 'login',
            name: 'login',
            component: Login,
            meta: {
                requiresAuth: false,
                middleware: [guest],
            }
        },
        {
            path: 'register',
            name: 'register',
            component: Register,
        },
        {
            path: '/company',
            name: 'company.next-step',
            component: RegisterNextStep,
        },
        {
            path: '/company/employees/invitation/accept/:invite_token',
            name: 'company.employee.invite',
            component: CompanyEmployeeInvite,
        },
        {
            path: '/password/reset',
            name: 'password.request',
            component: PasswordRequest,
        },
        {
            path: '/password/reset/:token',
            name: 'password.reset',
            component: PasswordReset,
        },
        {
            path: '/email/verify/:id',
            name: 'verification.verify',
            component: PasswordVerify,
        },
        {
            path: '/email/resend',
            name: 'verification.resend',
            component: PasswordResend,
        },
    ],
};

export default authRoutes;
