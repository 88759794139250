import ApiService from './api.service';
import CardService from './card.service';
import UtilService from './util.service';

const COMPANY_URL = window.fleetwireOptions?.apiURL;

const ListService = {


  addListingList() {
    const lists = Array.from(document.getElementsByClassName('fleetwire-listing-list'))


    const listings = window.fwListings;

    ListService.updatePaginate();

    lists.forEach(list => {
      list.setAttribute('id', `fleetwire-listing-list-${UtilService.randomString()}`);

      list = ListService.addListComponent(list);

      const listWrapper = list.getElementsByClassName('fleetwire-listing-list-wrapper')[0]


      /**
       * Remove the current cards and add the new ones
       */
      if (window.fwPaginate.current_page) {
        const existingProductCards = $fw('.fleetwire-listing-list');

        if (existingProductCards.length) {
          const elements = $fw(existingProductCards[0]).find('.fleetwire-listing-list-wrapper')
          if (elements.length) {
            $fw(elements[0]).children().remove();
          }
          // $fw(existingProductCards[0]).forEach(card => {
          //   card.remove();
          // });
        }
      }


      /**
       * Add the listings to each listing list element
       */
      listings.forEach((listing) => {
        CardService.cardCount++;
        const listingUrl = UtilService.companyProductUrl(listing.l_id);
        const dataset = $fw(list).data()
        const markup = CardService.buildProductCard(listing.l_id, dataset);

        $fw(markup).click(function () {
          window.open(listingUrl, '_blank');
        })

        $fw(listWrapper).append(markup);
      });

      UtilService.setWidth();

      CardService.addListingCards(listings);
    })
  },

  /**
   * Preps the product card element to the DOM and sets loading
   *
   * @private
   */
  prepListingList(page = 1) {
    const productCards = Array.from(document.getElementsByClassName("fleetwire-listing-list"));

    if (productCards.length > 0) {
      const hasPaginate = $fw(productCards[0]).data('per');

      let qString = `?page=${page}`;

      if (hasPaginate && parseInt(hasPaginate)) {
        qString += `&limit=${+hasPaginate}`;
      }

      ApiService.getData(`${COMPANY_URL}/api/v1/products${qString}`)
        .then(data => {
          window.fwListings = data.data;
          window.fwPaginate = data.meta;

          ListService.addListingList()
        }).catch(e => {
        console.dir(e);
      });
    }
  },

  updatePaginate() {
    const productCards = $fw('.fleetwire-listing-list');

    const data = $fw(productCards[0]).data();

    if (typeof data === 'undefined') return;
    if (!data.per) return;

    ListService.buildPaginate();

    const paginate = $fw('.fleetwire-pagination .fw-pagination-inner');

    if (paginate.length) {
      const currentPage = window.fwPaginate.current_page;
      // const totalPages = window.fwPaginate.total;

      if (currentPage > 10) {
        if ($fw('.fw-pagination-previous').length === 0) {
          $fw($fw('.fw-pagination-inner')[0]).prepend(`<div class="fw-pagination-page fw-pagination-previous"><a><i class="bq-icon-arrow-left2"></i></a></div>`);
        }


      }

      paginate.children().each(function (index, element) {

        if (index + 1 === currentPage) {


          if ($fw(element).hasClass('fw-pagination-next')) {

          } else {
            $fw(element).html(currentPage);

            $fw(element).addClass('fw-pagination-current');
            $fw(element).addClass('fw-branded');
          }
        } else {
          if (currentPage > 10) {
            if (index > 0 && index < 10) {
              $fw(element).html(`<a>${currentPage - (10 - index)}</a>`);
            }
          }

          if ($fw(element).hasClass('fw-pagination-current')) {
            $fw(element).html(`<a>${index + 1}</a>`);
          }

          $fw(element).removeClass('fw-pagination-current');
          $fw(element).removeClass('fw-branded');

          $fw(element).unbind();

          $fw(element).click(function (e) {
            const pageRequest = +e.target.innerHTML

            if (pageRequest > 0) {
              ListService.prepListingList(pageRequest)
            } else {
              const button = $fw(e.target).parent();
              if (button.length) {
                if ($fw(button[0]).parents('.fw-pagination-next').length) {
                  ListService.prepListingList(index + 1)
                }
              }
            }
          });

        }
      })
    }
  },

  buildPaginate() {
    const productCards = $fw('.fleetwire-listing-list');


    if (productCards.length) {

      if ($fw('.fleetwire-pagination').length === 0) {
        $fw(productCards[0]).append('<div class="fleetwire-component"><div class="fleetwire-pagination"><div class="fw-pagination-inner"></div></div></div>');
        const addNextButton = window.fwPaginate.total > 10;

        const buttonCount = window.fwPaginate.total > 10 ? 10 : window.fwPaginate.total;
        for (let i = 0; i < buttonCount; i++) {
          $fw($fw('.fw-pagination-inner')[0]).append(`<div class="fw-pagination-page"><a>${i + 1}</a></div>`);
        }

        if (addNextButton) {
          $fw($fw('.fw-pagination-inner')[0]).append(`<div class="fw-pagination-page fw-pagination-next"><a><i class="bq-icon-arrow-right2"></i></a></div>`);
        }
      }
    }
  },

  addListComponent(list) {
    if (list) {
      $fw(list).append($fw(`<div class="fleetwire-themed"><div class="fleetwire-component"><div class="fleetwire-listing-list-wrapper"></div></div></div>`));
    }
    return list;
  },
}

export default ListService;