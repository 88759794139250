<template>
  <div>
    <FeatureTitle
      featurePremium
      title="Webhooks"
    />
    <div class="grid grid-cols-1 my-5">
      <div>
        <div v-if="webhooksLoading.webhooks">Loading...</div>
        <v-card v-else-if="webhookEndpoints.length > 0" :disabled="!includedInSubscription('webhooks')">
          <v-card-title>
            <div class="flex">
              Hosted endpoints
              <v-spacer/>
              <v-btn :to="{ name: 'settings.providers.webhooks.create' }" size="small">
                <v-icon class="mr-2" size="small">mdi-plus</v-icon>
                Add Endpoint
              </v-btn>
            </div>
          </v-card-title>

          <v-card-text>
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th class="px-6 py-3" scope="col">
                  URL
                </th>
                <th class="px-6 py-3" scope="col">
                  STATUS
                </th>
                <th class="px-6 py-3" scope="col">
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="item in webhookEndpoints"
                :key="item.uuid"
                class="border align-content-center justify-center"
              >
                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" scope="row">
                  {{ item.url }}
                </td>
                <td class="px-6 py-3 cursor-pointer align-middle">
                    <span v-if="webhookEndpointStatus(item) === 'Enabled'" class="badge badge-success">
                      Enabled
                    </span>
                  <span v-if="webhookEndpointStatus(item) === 'Disabled'" class="badge badge-danger">
                      Disabled
                    </span>
                </td>
                <td class="px-6 py-3 align-middle">
                  <v-btn size="small" variant="text" @click="editWebhook(item)">View</v-btn>
                </td>
              </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>

        <div v-else class="h-100 row align-items-center">
          <div class="col text-center">
            <div class="w-100 mb-5">
              <svg data-name="Layer 1" height="200" viewBox="0 0 888 710.80704"
                   width="100%" xmlns="http://www.w3.org/2000/svg"><title>server_cluster</title>
                <path
                  d="M600.93555,699.40352a260.06372,260.06372,0,1,1,183.89355-76.1709A258.36518,258.36518,0,0,1,600.93555,699.40352Zm0-518.12891c-142.29688,0-258.06446,115.76758-258.06446,258.06494s115.76758,258.064,258.06446,258.064S859,581.63692,859,439.33955,743.23242,181.27461,600.93555,181.27461Z"
                  fill="#ccc" transform="translate(-156 -94.59648)"/>
                <rect fill="#2f2e41" height="195" transform="translate(1051.40391 -87.19257) rotate(90)" width="262"
                      x="469" y="509.90391"/>
                <rect fill="#3f3d56" height="104" width="262" x="313" y="373.80743"/>
                <rect fill="#3f3d56" height="104" width="262" x="313" y="489.80743"/>
                <rect fill="#3f3d56" height="104" width="262" x="313" y="605.80743"/>
                <rect fill="#73b18a" height="16" width="262" x="313" y="417.90372"/>
                <rect fill="#73b18a" height="16" width="262" x="313" y="533.90372"/>
                <rect fill="#73b18a" height="16" width="262" x="313" y="649.90372"/>
                <circle cx="524" cy="388.80743" fill="#73b18a" r="6"/>
                <circle cx="545" cy="388.80743" fill="#73b18a" r="6"/>
                <circle cx="566" cy="388.80743" fill="#73b18a" r="6"/>
                <circle cx="524" cy="502.80743" fill="#73b18a" r="6"/>
                <circle cx="545" cy="502.80743" fill="#73b18a" r="6"/>
                <circle cx="566" cy="502.80743" fill="#73b18a" r="6"/>
                <circle cx="524" cy="616.80743" fill="#73b18a" r="6"/>
                <circle cx="545" cy="616.80743" fill="#73b18a" r="6"/>
                <circle cx="566" cy="616.80743" fill="#73b18a" r="6"/>
                <rect fill="#3f3d56" height="2" width="888" y="708.80704"/>
                <path
                  d="M397.8787,365.66116v185.9917a14.67525,14.67525,0,0,1-14.67227,14.67231H310.928a14.67656,14.67656,0,0,1-14.6784-14.67231V365.66116a14.67655,14.67655,0,0,1,14.6784-14.67228h8.76786a6.9778,6.9778,0,0,0,6.4551,9.60614h41.20843a6.97774,6.97774,0,0,0,6.45506-9.60614h9.392A14.67524,14.67524,0,0,1,397.8787,365.66116Z"
                  fill="#e6e6e6" transform="translate(-156 -94.59648)"/>
                <rect fill="#fff" height="128" width="92" x="145.06414" y="291.91002"/>
                <rect fill="#e6e6e6" height="2.09827" width="36.71978" x="171.18557" y="379.92719"/>
                <rect fill="#73b18a" height="2.09827" width="56.81724" x="171.14869" y="387.63152"/>
                <rect fill="#e6e6e6" height="2.09827" width="44.5883" x="171.14869" y="394.89011"/>
                <rect fill="#e6e6e6" height="2.09827" width="26.22841" x="171.14869" y="402.1487"/>
                <rect fill="#e6e6e6" height="2.09827" width="39.34262" x="171.14869" y="409.40729"/>
                <rect fill="#e6e6e6" height="4.19655" width="4.19655" x="154.53324" y="379.32379"/>
                <rect fill="#73b18a" height="4.19655" width="4.19655" x="154.53324" y="386.58238"/>
                <rect fill="#e6e6e6" height="4.19655" width="4.19655" x="154.53324" y="393.84097"/>
                <rect fill="#e6e6e6" height="4.19655" width="4.19655" x="154.53324" y="401.09956"/>
                <rect fill="#e6e6e6" height="4.19655" width="4.19655" x="154.53324" y="408.35815"/>
                <rect fill="#e6e6e6" height="36.99753" width="36.99753" x="172.75082" y="307.91002"/>
                <rect fill="#e6e6e6" height="157.47494" width="245.88383" x="321.11058" y="0.13939"/>
                <rect fill="#fff" height="77.03517" width="203.86136" x="339.64671" y="44.19534"/>
                <rect fill="#e6e6e6" height="2.79582" width="48.92684" x="376.78939" y="57.54179"/>
                <rect fill="#73b18a" height="2.79582" width="75.70547" x="376.74025" y="67.80733"/>
                <rect fill="#e6e6e6" height="2.79582" width="59.41117" x="376.74025" y="77.47895"/>
                <rect fill="#e6e6e6" height="2.79582" width="34.94775" x="376.74025" y="87.15058"/>
                <rect fill="#e6e6e6" height="2.79582" width="52.42162" x="376.74025" y="96.8222"/>
                <rect fill="#e6e6e6" height="5.59164" width="5.59164" x="354.60119" y="56.7378"/>
                <rect fill="#73b18a" height="5.59164" width="5.59164" x="354.60119" y="66.40942"/>
                <rect fill="#e6e6e6" height="5.59164" width="5.59164" x="354.60119" y="76.08104"/>
                <rect fill="#e6e6e6" height="5.59164" width="5.59164" x="354.60119" y="85.75267"/>
                <rect fill="#e6e6e6" height="5.59164" width="5.59164" x="354.60119" y="95.42429"/>
                <rect fill="#e6e6e6" height="50.05873" width="50.05873" x="483.44507" y="53.8475"/>
                <rect fill="#73b18a" height="10.44604" width="245.88383" x="321.00559"/>
                <circle cx="328.76825" cy="5.34057" fill="#fff" r="2.09686"/>
                <circle cx="336.11718" cy="5.34057" fill="#fff" r="2.09686"/>
                <circle cx="343.46611" cy="5.34057" fill="#fff" r="2.09686"/>
                <rect fill="#e6e6e6" height="31" width="60" x="671.64772" y="294.80743"/>
                <rect fill="#e6e6e6" height="31" width="60" x="671.64772" y="405.80743"/>
                <circle cx="701.64772" cy="365.80743" fill="#e6e6e6" r="50"/>
                <circle cx="701.64772" cy="365.80743" fill="#fff" r="39"/>
                <rect fill="#e2e2e2" height="1.72839" width="30.24683" x="688.87659" y="355.12175"/>
                <rect fill="#73b18a" height="1.72839" width="46.80151" x="688.84621" y="361.46796"/>
                <rect fill="#e2e2e2" height="1.72839" width="36.72829" x="688.84621" y="367.44701"/>
                <rect fill="#e2e2e2" height="1.72839" width="21.60488" x="688.84621" y="373.42605"/>
                <rect fill="#e2e2e2" height="1.72839" width="32.40732" x="688.84621" y="379.4051"/>
                <rect fill="#e2e2e2" height="3.45678" width="3.45678" x="675.15972" y="354.62472"/>
                <rect fill="#73b18a" height="3.45678" width="3.45678" x="675.15972" y="360.60376"/>
                <rect fill="#e2e2e2" height="3.45678" width="3.45678" x="675.15972" y="366.58281"/>
                <rect fill="#e2e2e2" height="3.45678" width="3.45678" x="675.15972" y="372.56186"/>
                <rect fill="#e2e2e2" height="3.45678" width="3.45678" x="675.15972" y="378.54091"/>
              </svg>
            </div>
            <h2 class="newCustomer__header">No webhooks yet.</h2>
            <p>Create webhook endpoints, so that Fleetwire can notify your integration when asynchronous events
              occur.</p>
            <v-btn :to="{ name: 'settings.providers.webhooks.create' }" size="small">
              <v-icon class="mr-2" size="small">mdi-plus</v-icon>
              Add Endpoint
            </v-btn>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, defineAsyncComponent, onBeforeMount} from 'vue';
import {useSubscription} from '@/composables/useSubscription';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';

const FeatureTitle = defineAsyncComponent(() => import('@/components/dashboard/FeatureTitle.vue'));

const router = useRouter();
const {getters, dispatch} = useStore();
const {includedInSubscription} = useSubscription();
const webhooksLoading = computed(() => getters['webhook/loading']);
const webhookEndpoints = computed(() => getters['webhook/webhookEndpoints']);

const getWebhookEndpoints = async () => {
  return await dispatch('webhook/getWebhookEndpoints');
};

const webhookEndpointStatus = (endpoint) => {
  if (!endpoint || !endpoint.status) {
    return 'Unknown';
  }
  return endpoint.status.charAt(0).toUpperCase() + endpoint.status.slice(1);
};

const editWebhook = (webhook) => {
  router.push({name: 'settings.providers.webhooks.update', params: {uuid: webhook.uuid}})
    .catch(err => {
      console.error('Failed to navigate to edit webhook:', err);
    });

};

onBeforeMount(() => {
  getWebhookEndpoints();
});
</script>
