import defaultSettings from '@/settings';
import * as types from '@/store/mutation-types';
import axios from 'axios';
import {isNull} from 'lodash';
import {EventBus} from '@/plugins/eventBus';
import {performApiRequest} from '@/store/helpers/vuex-helpers';

const {showSettings, tagsView, fixedHeader, sidebarLogo} = defaultSettings;

export interface NotificationSettings {
    fw_booking_email: boolean;
    fw_booking_sms: boolean;
    getaround_booking_email: boolean;
    getaround_booking_sms: boolean;
    getaround_communication_error_email: boolean;
    getaround_communication_error_sms: boolean;
    id: boolean;
    tesla_communication_error_email: boolean;
    tesla_communication_error_sms: boolean;
    turo_booking_email: boolean;
    turo_booking_sms: boolean;
    turo_communication_error_email: boolean;
    turo_communication_error_sms: boolean;
    vehicle_maintenance_reminder_sms: boolean;
    vehicle_maintenance_reminder_email: boolean;
    renter_invoice_paid_sms: boolean;
    renter_invoice_paid_email: boolean;
    toll_new_transaction_sms: boolean;
    toll_new_transaction_email: boolean;
    vehicle_recall_sms: boolean;
    vehicle_recall_email: boolean;
    notify_email: boolean;
    notify_sms: boolean;
    set: boolean;
}

export interface SettingsState {
    theme: any[];
    showSettings: boolean;
    tagsView: boolean;
    fixedHeader: boolean;
    sidebarLogo: boolean;
    loading: Record<string, boolean>;
    notifications: NotificationSettings;
}

const state: SettingsState = {
    theme: [],
    showSettings,
    tagsView,
    fixedHeader,
    sidebarLogo,
    loading: {
        notifications: false,
        export_commit: false,
        settings: false,
        get_settings: false,
    },
    notifications: {
        set: false,
        fw_booking_email: null,
        fw_booking_sms: null,
        getaround_booking_email: null,
        getaround_booking_sms: null,
        getaround_communication_error_email: null,
        getaround_communication_error_sms: null,
        id: null,
        tesla_communication_error_email: null,
        tesla_communication_error_sms: null,
        turo_booking_email: null,
        turo_booking_sms: null,
        turo_communication_error_email: null,
        turo_communication_error_sms: null,
        vehicle_maintenance_reminder_sms: null,
        vehicle_maintenance_reminder_email: null,
        renter_invoice_paid_sms: null,
        renter_invoice_paid_email: null,
        toll_new_transaction_sms: null,
        toll_new_transaction_email: null,
        vehicle_recall_sms: null,
        vehicle_recall_email: null,
        notify_email: null,
        notify_sms: null,
    },
};

const getters = {
    loading: (state: SettingsState) => state.loading,
    needTagsView: (state: SettingsState) => state.tagsView,
    fixedHeader: (state: SettingsState) => state.fixedHeader,
    showSettings: (state: SettingsState) => state.showSettings,
    notifications: (state: SettingsState) => state.notifications,
};

const mutations = {
    [types.SET_SETTINGS_LOADING](state: SettingsState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_NOTIFICATION_SETTINGS](state: SettingsState, settings: NotificationSettings) {
        if (!isNull(settings)) {
            state.notifications = settings;
            state.notifications.set = true;
        }
    },
};

const actions = {
    getSettings({commit}) {
        commit('SET_SETTINGS_LOADING', {subject: 'get_settings', loading: true});

        axios.get('/v1/settings')
            .then((response) => {
                EventBus.$emit('get-settings-success', response.data.settings);
                commit('company/SET_COMPANY_SETTINGS', response.data.settings, {root: true});
            }).catch(() => {
            EventBus.$emit('SystemMessage', {
                type: 'error',
                message: 'error getting settings',
            });
        }).finally(() => {
            commit('SET_SETTINGS_LOADING', {subject: 'get_settings', loading: false});
        });
    },

    async updateSettings({commit}, data: { settings: { [key: string]: string } }) {
        try {
            const responseData = await performApiRequest(commit, 'SET_SETTINGS_LOADING', 'settings', () => axios.post('/v1/settings', data));
            commit('company/SET_COMPANY_SETTINGS', responseData.settings, {root: true});
        } catch (error) {
            EventBus.$emit('SystemMessage', {
                type: 'error',
                message: 'Settings update error. Try again later.',
            });
        }
    },

    async updateNotificationSettings({commit}) {
        try {
            const responseData = await performApiRequest(commit, 'SET_SETTINGS_LOADING', 'notifications', () => axios.put('/v1/settings/notifications', state.notifications));
            EventBus.$emit('SystemMessage', {
                type: 'success',
                message: 'Settings updated successfully!',
            });
            return responseData;
        } catch (error) {
            EventBus.$emit('SystemMessage', {
                type: 'error',
                message: 'Settings update error. Try again later.',
            });
        }
    },

    resetNotificationSettings({commit}) {
        commit('SET_SETTINGS_LOADING', {subject: 'notifications', loading: true});
        axios.post('/v1/settings/notifications/reset')
            .then((response) => {
                commit('SET_NOTIFICATION_SETTINGS', response?.data?.company?.notification_settings);
            }).catch(() => {
        }).finally(() => {
            commit('SET_SETTINGS_LOADING', {subject: 'notifications', loading: false});
        });
    },


    getExportDetails({commit}) {
        commit('SET_SETTINGS_LOADING', {subject: 'export_details', loading: true});
        axios.get('/v1/export/details')
            .then((response) => {
                EventBus.$emit('export-details-success', response.data);
            }).catch(() => {
        }).finally(() => {
            commit('SET_SETTINGS_LOADING', {subject: 'export_details', loading: false});
        });
    },
    commitExport({commit}, data) {
        commit('SET_SETTINGS_LOADING', {subject: 'export_commit', loading: true});
        axios.post('/v1/export', data)
            .then((response) => {
                console.dir(response);
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'fleetwire-export.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(() => {
        }).finally(() => {
            commit('SET_SETTINGS_LOADING', {subject: 'export_commit', loading: false});
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
