import * as Sentry from '@sentry/vue';

const IS_DEBUG = import.meta.env.MODE !== 'production';

export function initSentry(app: any) {
    const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN || 'https://477434e9051c46e38a6d5052ba4129d7@sentry.io/2158517';

    if (!SENTRY_DSN) {
        console.warn('⚠️ Sentry DSN is not provided.');
        return;
    }

    Sentry.init({
        app,
        dsn: SENTRY_DSN,
        release: import.meta.env.VITE_APP_VERSION || '1.0.51',
        // debug: IS_DEBUG,
        environment: import.meta.env.MODE,

        beforeSend: (event, hint) => {
            if (IS_DEBUG) {
                console.error('[Sentry Debug] Captured Error:', hint.originalException || hint.syntheticException);
                // return null; // this drops the event and nothing will be sent to sentry
            }
            return event;
        },

        integrations: [
            Sentry.vueIntegration({
                tracingOptions: {
                    trackComponents: true,
                },
            }),
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: true,
            }),
            // The following is all you need to enable canvas recording with Replay
            Sentry.replayCanvasIntegration(),
        ],

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: IS_DEBUG ? 1.0 : 0.1,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: 1.0,
        tracePropagationTargets: ['localhost', 'fleetwire.test', 'fleetwire.io', /^\//],
    });

    console.log('✅ Sentry Initialized Successfully!');
}