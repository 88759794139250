import type {RouteRecordRaw} from 'vue-router';

// Route Modules
import authRoutes from '@/router/modules/auth';
import settingsRoutes from '@/router/modules/settings';
import commissionRoutes from './modules/commission';
import custodian from './modules/custodian';
import documentRoutes from './modules/documents';
import perksRoutes from '@/router/modules/perks';
import notificationRoutes from '@/router/modules/notifications';
import listingRoutes from './modules/listing';
import connectionsRoutes from './modules/connections';
import ordersRoutes from '@/router/modules/orders';
import customerRoutes from '@/router/modules/customers';
import tripExtrasRoutes from '@/router/modules/trip-extras';
import themeRoutes from '@/router/modules/theme';
import {companyRoutes, companyRoutes2} from './modules/company';
import {eSignerCompleteRoutes, eSignerRoutes} from './modules/e-signer';
import superAdminRoutes from './modules/super-admin';
import dashboardRoutes from './modules/dashboard';
import analyticsRoutes from './modules/analytics';
import payRoutes from './modules/pay';

// Lazy-loaded components for redirects
const StripeRedirect = () => import('@/views/auth/StripeRedirect.vue');
const BraintreeRedirect = () => import('@/views/auth/BraintreeRedirect.vue');
const SquareRedirect = () => import('@/views/auth/SquareRedirect.vue');

// Grouped Constant Routes
const redirectRoutes: Array<RouteRecordRaw> = [
    {
        path: '/stripe/redirect',
        name: 'stripe.redirect',
        component: StripeRedirect,
        meta: {hidden: true, middleware: ['auth'], layout: 'Dashboard'},
    },
    {
        path: '/braintree/redirect',
        name: 'braintree.redirect',
        component: BraintreeRedirect,
        meta: {hidden: true, middleware: ['auth'], layout: 'Dashboard'},
    },
    {
        path: '/square/redirect',
        name: 'square.redirect',
        component: SquareRedirect,
        meta: {hidden: true, middleware: ['auth'], layout: 'Dashboard'},
    },
];

const moduleRoutes: Array<RouteRecordRaw> = [
    // {
    //   path: '/',
    //   name: 'home',
    //   components: Home,
    //   meta: { title: 'home', icon: 'mdi-home', noCache: false },
    //   children: [],
    // },
    // {
    //   path: '/theme-test1',
    //   name: 'theme.test1',
    //   component: ThemeTest,
    //   meta: { hidden: true }
    // },
    // {
    //   path: '/theme-test1/:uuid',
    //   name: 'theme.test1.single',
    //   component: ThemeTest,
    //   meta: { hidden: true }
    // },
    dashboardRoutes,
    authRoutes,
    custodian,
    ...companyRoutes,
    ...companyRoutes2,
    ...listingRoutes,
    ...tripExtrasRoutes,
    ...ordersRoutes,
    ...customerRoutes,
    ...connectionsRoutes,
    ...settingsRoutes,
    ...perksRoutes,
    ...notificationRoutes,
    ...themeRoutes,
    ...commissionRoutes,
    ...payRoutes,
    ...documentRoutes,
    ...eSignerRoutes,
    superAdminRoutes,
    eSignerCompleteRoutes,
    analyticsRoutes,
];

// Consolidated Routes
const constantRoutes: Array<RouteRecordRaw> = [
    ...redirectRoutes,
    ...moduleRoutes,
];

const asyncRoutes: Array<RouteRecordRaw> = [];

export {constantRoutes, asyncRoutes}