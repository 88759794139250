export const emailRule = () => {
    return [(v) => /.+@.+\..+/.test(v) || 'E-mail must be a valid e-mail'];
};

export const minLengthRule = (minLength: number) => {
    return (v) => {
        if (!v) return true; // Optional: Skip minLength check if value is empty. Remove this if you want empty values to fail this validation.
        return v.length >= minLength || `Min of ${minLength} characters`;
    };
}

export const codeRule = (length = 4) => {
    return [(v) => v.length >= length || `Should be ${length} characters`];
};

export const requiredRule = (v: any): string | boolean => {
    if (!v) {
        return 'Required';
    }
    return true;
};