import type {RouteRecordRaw} from 'vue-router';
import {CommonRouteMeta} from '@/types';

const BaseDashboard = () => import('@/views/dashboard/BaseDashboard.vue');
const BaseCustomers = () => import('@/views/dashboard/customers/BaseCustomers.vue');
const CustomerSingle = () => import('@/views/dashboard/customers/CustomerSingle.vue');
const CustomerRightSidebar = () => import('@/components/dashboard/rightSideBars/CustomerRightSidebar.vue');
const NewCustomer = () => import('@/views/dashboard/customers/New.vue');
const ImportCustomers = () => import('@/views/dashboard/customers/ImportCustomers.vue');
const AddNewCustomer = () => import('@/components/dashboard/navActions/AddNewCustomer.vue');

const customerRoutes: RouteRecordRaw[] = [
    {
        path: '/customers',
        components: {
            default: BaseDashboard,
        },
        meta: {
            title: 'customers',
            icon: 'mdi-account-outline',
            noCache: false,
            isBase: true,
            permissions: ['customers_view'],
            showInMenu: true,
            middleware: ['auth'],
            layout: 'Dashboard',
        } as CommonRouteMeta,
        children: [
            {
                path: '',
                components: {
                    default: BaseCustomers,
                    navRightActions: AddNewCustomer,
                },
                name: 'customers.index',
                meta: {title: 'customers', icon: 'mdi-account-outline', noCache: false, isBase: true},
            },
            {
                path: 'import',
                component: ImportCustomers,
                name: 'customers.import',
                meta: {title: 'Import', icon: 'mdi-account-outline', noCache: false},
            },
            {
                path: 'new',
                component: NewCustomer,
                name: 'customers.new',
                meta: {title: 'New', icon: 'mdi-account-outline', noCache: false},
            },
            {
                path: ':r_id',
                components: {
                    default: CustomerSingle,
                    rightSidebar: CustomerRightSidebar,
                },
                name: 'customers.single',
                meta: {
                    title: 'customer',
                    icon: 'mdi-account-outline',
                    noCache: false,
                    resource: 'r_id',
                    hasRightSidebar: true,
                    isBase: false
                },
            },
        ],
    }
];

export default customerRoutes;
