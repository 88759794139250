import Cookies from 'js-cookie';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { ray } from 'node-ray/web';

window.ray = ray;
Pusher.logToConsole = false;

const pusherAppKey = import.meta.env.VITE_PUSHER_APP_KEY;

const pusher = new Pusher(pusherAppKey, {
  cluster: 'us3',
  forceTLS: true,
  authEndpoint: '/api/broadcasting/auth',
  auth: {
    headers: {
      Authorization: `Bearer ${Cookies.get('token')}`,
    },
  },
});

window.Echo = new Echo({
  broadcaster: 'pusher',
  client: pusher,
});
