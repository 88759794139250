<template>
  <div class="RenewsOneCard">
    <v-card style="border: 1px solid rgb(224, 220, 229);" variant="flat">
      <v-card-text>
        <div>
          <div class="text-gray-500 dark:text-gray-400">
            <span v-if="inGracePeriod">Ends On</span>
            <span v-else-if="inTrial">Trialing Until</span>
            <span v-else>Renews On</span>
          </div>
          <div class="mb-4 text-xl font-medium text-gray-900 dark:text-white">
            <span v-if="inGracePeriod">{{ stripeEndDate }}</span>
            <span v-else-if="inTrial">{{ trialEndDate }}</span>
            <span v-else>{{ currentStripeSubscriptionObjectRenewalDate }}</span>
          </div>
          <div>
            <router-link :to="{name: 'settings.account.receipts'}" style="color: rgb(37, 98, 212);">
              View receipts
            </router-link>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts" setup>
import {useSubscription} from '@/composables/useSubscription';

const {
  inTrial,
  trialEndDate,
  currentStripeSubscriptionObjectRenewalDate,
  stripeEndDate,
  inGracePeriod,
} = useSubscription();
</script>