import {Form} from '@/plugins/FWForms/index';
import axios from 'axios';
import * as types from '@/store/mutation-types';
import {performApiRequest} from '@/store/helpers/vuex-helpers';
import {StoreLoaders} from '@/types';
import {push} from 'notivue'

export interface DefaultProperty {
    identifier: string;
    name: string;
    owner_type: string;
    property_type: string;
    select_options: any;
    show_on: any;
    position?: number;
    editable?: boolean;
    updated_at?: string;
    created_at?: string;
    uuid?: string;
}

export interface DefaultPropertiesState {
    loading: StoreLoaders;
    defaultProperties: DefaultProperty[];
    form: Form<any>;
}

// state
const state: DefaultPropertiesState = {
    loading: {
        fetchDefaultProperties: false,
        defaultProperties: false,
        createCustomFields: false,
        updateCustomFields: false,
        deleteCustomFields: false,
    },
    defaultProperties: [],
    form: new Form(),
};

const getters = {
    loading: (state: DefaultPropertiesState) => state.loading,
    form: (state: DefaultPropertiesState) => state.form,
    defaultProperties: (state: DefaultPropertiesState) => state.defaultProperties,
};

const mutations = {
    [types.SET_CUSTOM_FIELDS_LOADING](state: DefaultPropertiesState, loading: { subject: string, loading: boolean }) {
        state.loading[loading.subject] = loading.loading;
    },
    [types.SET_DEFAULT_PROPERTIES](state: DefaultPropertiesState, defaultProperty: DefaultProperty[]) {
        state.defaultProperties = defaultProperty;
    },
    [types.CLEAR_DEFAULT_PROPERTIES_FORM](state: DefaultPropertiesState) {
        state.form.errors.clear();
    },
    [types.SET_DEFAULT_PROPERTIES_FORM_BUSY](state: DefaultPropertiesState, busy = true) {
        state.form.busy = busy;
    },
    [types.SET_DEFAULT_PROPERTIES_FORM_ERRORS](state: DefaultPropertiesState, errors: string | Record<string, string[]>) {
        state.form.errors.set(errors);
    },
};

const actions = {
    async fetchDefaultProperties({commit}) {
        commit(types.CLEAR_DEFAULT_PROPERTIES_FORM);
        commit(types.SET_DEFAULT_PROPERTIES_FORM_BUSY);

        try {
            const responseData = await performApiRequest(commit, 'SET_CUSTOM_FIELDS_LOADING', 'fetchDefaultProperties', () => axios.get('/v1/default-properties'));

            commit(types.SET_DEFAULT_PROPERTIES, responseData.defaultProperties);
            commit(types.SET_DEFAULT_PROPERTIES_FORM_BUSY, false);
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error fetching default properties.',
            });
        }
    },

    async createDefaultProperties({commit}, defaultProperty: DefaultProperty) {
        commit(types.CLEAR_DEFAULT_PROPERTIES_FORM);
        commit(types.SET_DEFAULT_PROPERTIES_FORM_BUSY);

        try {
            const responseData = await performApiRequest(commit, 'SET_CUSTOM_FIELDS_LOADING', 'createCustomFields', () => axios.post('/v1/default-properties', defaultProperty));

            commit(types.SET_DEFAULT_PROPERTIES, responseData.defaultProperties);

            push.success({
                message: 'Custom Field Created successfully!',
            });
        } catch (error) {
            commit(types.SET_DEFAULT_PROPERTIES_FORM_ERRORS, error?.response?.data?.errors);
        }

        commit(types.SET_DEFAULT_PROPERTIES_FORM_BUSY, false);
    },

    async updateDefaultProperties({commit}, defaultProperty: DefaultProperty) {
        commit(types.CLEAR_DEFAULT_PROPERTIES_FORM);
        commit(types.SET_DEFAULT_PROPERTIES_FORM_BUSY);

        try {
            await performApiRequest(commit, 'SET_CUSTOM_FIELDS_LOADING', 'updateCustomFields', () => axios.put(`/v1/default-properties/${defaultProperty.uuid}`, defaultProperty));

            push.success({
                message: 'Custom Field Updated successfully!',
            });
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error saving this default property.',
            });
            commit(types.SET_DEFAULT_PROPERTIES_FORM_ERRORS, error?.response?.data?.message || 'There was an error deleting this custom field.',);
        }

        commit(types.SET_DEFAULT_PROPERTIES_FORM_BUSY, false);
    },

    async updateDefaultPropertiesPositions({commit}, fields: DefaultProperty[]) {
        commit(types.CLEAR_DEFAULT_PROPERTIES_FORM);
        commit(types.SET_DEFAULT_PROPERTIES_FORM_BUSY);

        try {
            await performApiRequest(commit, 'SET_CUSTOM_FIELDS_LOADING', 'updateCustomFields', () => axios.put('/v1/default-properties/positions', {fields}));

            push.success({
                message: 'Custom Field positions updated successfully!',
            });
        } catch (error) {
            push.error({
                message: error?.response?.data?.message || 'There was an error saving this default property.',
            });
            commit(types.SET_DEFAULT_PROPERTIES_FORM_ERRORS, error?.response?.data?.message || 'There was an error deleting this custom field.',);
        }

        commit(types.SET_DEFAULT_PROPERTIES_FORM_BUSY, false);
    },

    async deleteDefaultProperties({commit}, defaultProperty: DefaultProperty) {
        try {
            commit(types.CLEAR_DEFAULT_PROPERTIES_FORM);
            commit(types.SET_DEFAULT_PROPERTIES_FORM_BUSY);

            const responseData = await performApiRequest(commit, 'SET_CUSTOM_FIELDS_LOADING', 'deleteCustomFields', () => axios.delete(`/v1/default-properties/${defaultProperty.uuid}`));

            commit(types.SET_DEFAULT_PROPERTIES, responseData.defaultProperties);

            push.success({
                message: 'Custom Field deleted successfully!',
            });
        } catch (error) {
            commit(types.SET_DEFAULT_PROPERTIES_FORM_BUSY, false);
            push.error({
                message: error?.response?.data?.message || 'There was an error deleting this default property.',
            });

            commit(types.SET_DEFAULT_PROPERTIES_FORM_ERRORS, error?.response?.data?.errors || 'There was an error deleting this custom field.',);

            throw error;
        }

        commit(types.SET_DEFAULT_PROPERTIES_FORM_BUSY, false);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
