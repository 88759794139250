import type {RouteRecordRaw} from 'vue-router';
import ListingManagerConnect from '@/views/dashboard/manager/ListingManagerConnect.vue';
import {CommonRouteMeta} from "@/types";

const BaseDashboard = () => import('@/views/dashboard/BaseDashboard.vue');


const listingManagerRoutes: RouteRecordRaw = {
    path: '/custodian',
    component: BaseDashboard,
    meta: {
        title: 'custodian',
        icon: 'mdi-car-convertible',
        noCache: false,
        isBase: true,
        permissions: ['listings_view'],
    } as CommonRouteMeta,
    children: [
        {
            path: ':managed_id',
            components: {
                default: ListingManagerConnect,
            },
            name: 'listing.manager.connect',
            meta: {title: 'Single Listing', icon: 'mdi-car-convertible', noCache: false, resource: 'l_id'},
        },
    ],
};

export default listingManagerRoutes;
