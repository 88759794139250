<template>
  <g class="drag-handles" v-if="handlesOn" :transform="`matrix(1,0,0,1, ${element.x + 20}, ${element.y + 20})`">
    <rect stroke="#2463d1" fill="transparent" style="pointer-events: none;" opacity="0" rx="2" ry="2" x="-1" y="-1" :width="element.w + 20" :height="element.h + 20" stroke-width="0.7621247113163973"></rect>
    <circle stroke="#2463d1" fill="white" style="cursor: nwse-resize;" opacity="1" cx="0" cy="0" r="2.6674364896073905" stroke-width="1.5242494226327945"></circle>
    <circle stroke="#2463d1" fill="white" style="cursor: nesw-resize;" opacity="1" :cx="element.w" cy="0" r="2.6674364896073905" stroke-width="1.5242494226327945"></circle>
    <circle id="fw-signing-element-rect-se" selectable stroke="#2463d1" fill="white" style="cursor: nwse-resize;" opacity="1" :cx="element.w" :cy="element.h" r="2.6674364896073905" stroke-width="1.5242494226327945"></circle>
    <circle stroke="#2463d1" fill="white" style="cursor: nesw-resize;" opacity="1" cx="0" :cy="element.h" r="2.6674364896073905" stroke-width="1.5242494226327945"></circle>
    <circle style="pointer-events: none;cursor: ew-resize;" stroke="#2463d1" fill="white" opacity="0" cx="0" cy="0" r="2.6674364896073905" stroke-width="1.5242494226327945"></circle>
    <circle style="pointer-events: none;cursor: ew-resize;" stroke="#2463d1" fill="white" opacity="0" cx="0" cy="0" r="2.6674364896073905" stroke-width="1.5242494226327945"></circle>
  </g>
</template>

<script lang="ts">

export default {
  name: 'FieldDragHandles',

  props: {
    handlesOn: {
      type: Boolean,
      default: false,
    },
    element: {
      type: Object,
    },
  },
};
</script>
