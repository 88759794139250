<template>
  <div class="PlanPriceCard">
    <v-card style="border: 1px solid rgb(224, 220, 229);" variant="flat">
      <v-card-text>
        <div>
          <div class="text-gray-500 dark:text-gray-400">
            <span v-if="inTrial">Plan Price after trial</span>
            <span v-else>Plan Price</span>
          </div>
          <div class="mb-4 text-xl font-medium text-gray-900 dark:text-white">
            <span>{{ currentStripeSubscriptionObjectPlanAmount }}</span>
          </div>
          <div>
            <router-link
              :to="{name: 'settings.account.billing'}"
              style="color: rgb(37, 98, 212);"
            >
              View billing details
            </router-link>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts" setup>
import {useSubscription} from "@/composables/useSubscription";

const {inTrial, currentStripeSubscriptionObjectPlanAmount} = useSubscription();
</script>