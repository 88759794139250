<template>
  <div class="w-full z-auto">
    <v-form>
      <div>
        <div class="input-fields map-col w-full">
          <v-text-field
            v-show="!address2"
            id="mapbox-autofill"
            :density="dense ? 'compact' : 'default'"
            autocomplete="address-line1"
            class="mb-2 relative"
            hide-details
            label="Address"
            variant="outlined"
          />

          <v-text-field
            v-if="address2"
            v-model="address2"
            :density="dense ? 'compact' : 'default'"
            autocomplete="address-line"
            class="mb-2 relative"
            hide-details
            label="Address1"
            variant="outlined"
            @focus="address2 = ''"
          />

          <div v-show="!expandForm">
            <v-btn size="small" variant="text" @click="expandForm = !expandForm">Or Enter an address manually</v-btn>
          </div>
          <div v-if="!singleLineAddress" v-show="expandForm" class="secondary-inputs">
            <v-text-field
              :density="dense ? 'compact' : 'default'"
              autocomplete="address-line2"
              class="mb-2"
              hide-details
              label="Address Line 2"
              name="address-line2"
              variant="outlined"
            ></v-text-field>
            <div class=" mt-2">
              <v-text-field
                :density="dense ? 'compact' : 'default'"
                autocomplete="address-level2"
                class="mb-2"
                hide-details
                label="City"
                name="address-level2"
                variant="outlined"
              ></v-text-field>
              <v-text-field
                :density="dense ? 'compact' : 'default'"
                autocomplete="address-level1"
                class="mb-2"
                hide-details
                label="State / Region"
                name="address-level1"
                variant="outlined"
              ></v-text-field>
              <v-text-field
                :density="dense ? 'compact' : 'default'"
                autocomplete="postal-code"
                class="mb-2"
                hide-details
                label="ZIP / Postcode"
                name="postal-code"
                variant="outlined"
              ></v-text-field>
            </div>
          </div>
        </div>

        <div class="map-col" style="width: 100%">
          <div id="minimap-container" class="relative" style="height: 240px; width: 350px"></div>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script lang="ts" setup>
import {onMounted, ref, toRefs} from 'vue';
import {get} from 'lodash';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxScriptIsReady from '@/plugins/mapBoxAddressAutofillScript.js';

const ACCESS_TOKEN = 'pk.eyJ1IjoiZmxlZXR3aXJlIiwiYSI6ImNramV3ZXpvbjA3Z3Ayc21wbHkwNGluY2IifQ.203P27NF1jbVqCAuGKeYsQ';

export interface LocationData {
  name: string,
  address_line1: string,
  address_line2: string,
  address_level1: string,
  address_level2: string,
  postcode: string,
  country: string,
  country_code: string,
  district: string,
  neighborhood: string,
  provider: string,
  provider_id: string,
  lat: number,
  lng: number,
}

const props = defineProps<{
  dense?: boolean,
  singleLineAddress?: boolean,
}>();

const emit = defineEmits(['locationData']);

const {dense, singleLineAddress} = toRefs(props);

const expandForm = ref(false);
const address2 = ref('');

const setupMapBox = () => {
  mapboxsearch.config.accessToken = ACCESS_TOKEN;

  mapboxsearch.popoverOptions = {
    placement: 'bottom-start',
  }

  const autofillCollection = mapboxsearch.autofill({
    container: document.body,
  });
  const minimap = new MapboxAddressMinimap();
  // minimap.canAdjustMarker = true;
  minimap.satelliteToggle = true;
  minimap.theme = {
    variables: {
      borderRadius: '18px',
      boxShadow: '0 2px 8px #000'
    }
  }
  minimap.onSaveMarkerLocation = (lnglat) => {
    console.log(`Marker moved to ${lnglat}`);
  };
  const minimapContainer = document.getElementById('minimap-container');
  minimapContainer.appendChild(minimap);

  autofillCollection.addEventListener(
    'retrieve',
    async (e) => {
      expandForm.value = true;
      if (minimap) {
        minimap.feature = e.detail.features[0];
      }

      const features = get(e, 'detail.features.0.properties');
      const geometry = get(e, 'detail.features.0.geometry');
      const locationData: LocationData = {
        name: get(features, 'full_address'),
        address_line1: get(features, 'address_line1'),
        address_line2: get(features, 'address_line2'),
        address_level1: get(features, 'address_level1'),
        address_level2: get(features, 'address_level2'),
        postcode: get(features, 'postcode'),
        country: get(features, 'country'),
        country_code: get(features, 'country_code'),
        district: get(features, 'district'),
        neighborhood: get(features, 'neighborhood'),
        provider: 'mapbox',
        provider_id: get(features, 'id'),
        lat: get(geometry, 'coordinates.1'),
        lng: get(geometry, 'coordinates.0'),
      };
      const singleLine = singleLineAddress.value;
      address2.value = singleLine ? get(features, 'full_address') : locationData.address_line1;

      emit('locationData', locationData);
    },
  );
};

onMounted(() => {
  mapboxScriptIsReady.then(() => {
    setupMapBox();
  });
});
</script>

<style>
.map-col {
  display: block;
  max-width: 100%;
  flex-shrink: 1;
}

.mapboxgl-ctrl-geocoder {
  z-index: 1050 !important; /* Higher than dialog */
  position: absolute !important;
}

.v-overlay__content {
  overflow: visible !important; /* Allow content to overflow dialog */
}

@media screen and (min-width: 640px) {
  .map-col {
    width: auto;
    flex: 1 1 0;
  }
}

</style>
