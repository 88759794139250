import {Module} from 'vuex'
import axios from 'axios';
import Cookies from 'js-cookie';
import {removeMasquerade, setMasquerade} from '@/utils/auth';
import * as types from '../mutation-types';
import {EventBus} from '@/plugins/eventBus';
import {AppCurrency, AppFeatures, AppState, MasqueradeData} from '@/types';
import {RootState} from '@/types/RootState';
import {performApiRequest} from '@/store/helpers/vuex-helpers';

const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
const isOpera = navigator.userAgent.toLowerCase().includes('opr');

const SIDEBAR_STORAGE_KEY = 'sidebarStatus';
const DEBUG_STORAGE_KEY = 'fw-debug';


const state: AppState = {
    loading: {
        fetchCurrency: false
    },
    build: null,
    polled_build: null,
    update_available: false,
    debugDrawerOpen: false,
    debugOn: false,
    sidebar: {
        opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
        withoutAnimation: false,
    },
    listingView: Cookies.get('listingViewStatus') ? parseInt(Cookies.get('listingViewStatus'), 10) : 0,
    device: window.matchMedia('(max-width: 768px)').matches ? 'mobile' : 'desktop',
    deviceWidth: window.innerWidth,
    size: Cookies.get('size') || 'medium',

    secondaryNavViews: {
        listingImportNav: false,
    },
    possiblePermissions: [],
    masquerade: {
        on: false,
        admin: null,
        user: null,
    },
    fwPublicStripeKey: '',
    browser: {
        chrome: isChrome,
        opera: isOpera,
        safari: isSafari,
        firefox: isFirefox,
    },
    hoveredTurnoverBooking: null,
    rightSidebar: true,
    booting: true,
    setupItems: [
        {
            title: 'Create your account',
            id: 'settings.setup_checklist.create_account',
            completed: true,
        },
        {
            title: 'Set up operating hours',
            id: 'settings.setup_checklist.operating_hours',
            completed: false,
        },
        {
            title: 'Add tax rates',
            id: 'settings.setup_checklist.tax_rates',
            completed: false,
        },
        {
            title: 'Add a listing',
            id: 'settings.setup_checklist.add_listing',
            completed: false,
        },
        {
            title: 'Create order',
            id: 'settings.setup_checklist.create_order',
            completed: false,
        },
        {
            title: 'Set up payment methods',
            id: 'settings.setup_checklist.payment_methods',
            completed: false,
        },
        {
            title: 'Set up your website',
            id: 'settings.setup_checklist.website',
            completed: false,
        },
        {
            title: 'Choose a plan',
            id: 'settings.setup_checklist.choose_plan',
            completed: false,
        },
    ],
    appFeatures: null,
    featureWaitlist: null,
    countryOptions: [
        {
            name: 'Afghanistan',
            code: 'AF',
            phone: 93,
            symbol: '؋',
            currency: 'AFN',
            continent_code: 'AS',
            alpha_3: 'AFG',
        },
        {
            name: 'Åland Islands',
            code: 'AX',
            phone: 358,
            symbol: '€',
            currency: 'EUR',
            continent_code: 'EU',
            alpha_3: 'ALA',
        },
        {name: 'Albania', code: 'AL', phone: 355, symbol: 'Lek', currency: 'ALL', continent_code: 'EU', alpha_3: 'ALB'},
        {name: 'Algeria', code: 'DZ', phone: 213, symbol: 'دج', currency: 'DZD', continent_code: 'AF', alpha_3: 'DZA'},
        {
            name: 'American Samoa',
            code: 'AS',
            phone: 1684,
            symbol: '$',
            currency: 'USD',
            continent_code: 'OC',
            alpha_3: 'ASM',
        },
        {name: 'Andorra', code: 'AD', phone: 376, symbol: '€', currency: 'EUR', continent_code: 'EU', alpha_3: 'AND'},
        {name: 'Angola', code: 'AO', phone: 244, symbol: 'Kz', currency: 'AOA', continent_code: 'AF', alpha_3: 'AGO'},
        {name: 'Anguilla', code: 'AI', phone: 1264, symbol: '$', currency: 'XCD', continent_code: 'NA', alpha_3: 'AIA'},
        {
            name: 'Antarctica',
            code: 'AQ',
            phone: 672,
            symbol: '$',
            currency: 'AAD',
            continent_code: 'AN',
            alpha_3: 'ATA',
        },
        {
            name: 'Antigua & Barbuda',
            code: 'AG',
            phone: 1268,
            symbol: '$',
            currency: 'XCD',
            continent_code: 'NA',
            alpha_3: 'ATG',
        },
        {name: 'Argentina', code: 'AR', phone: 54, symbol: '$', currency: 'ARS', continent_code: 'SA', alpha_3: 'ARG'},
        {name: 'Armenia', code: 'AM', phone: 374, symbol: '֏', currency: 'AMD', continent_code: 'AS', alpha_3: 'ARM'},
        {name: 'Aruba', code: 'AW', phone: 297, symbol: 'ƒ', currency: 'AWG', continent_code: 'NA', alpha_3: 'ABW'},
        {name: 'Australia', code: 'AU', phone: 61, symbol: '$', currency: 'AUD', continent_code: 'OC', alpha_3: 'AUS'},
        {name: 'Austria', code: 'AT', phone: 43, symbol: '€', currency: 'EUR', continent_code: 'EU', alpha_3: 'AUT'},
        {
            name: 'Azerbaijan',
            code: 'AZ',
            phone: 994,
            symbol: 'm',
            currency: 'AZN',
            continent_code: 'AS',
            alpha_3: 'AZE',
        },
        {name: 'Bahamas', code: 'BS', phone: 1242, symbol: 'B$', currency: 'BSD', continent_code: 'NA', alpha_3: 'BHS'},
        {
            name: 'Bahrain',
            code: 'BH',
            phone: 973,
            symbol: '.د.ب',
            currency: 'BHD',
            continent_code: 'AS',
            alpha_3: 'BHR',
        },
        {
            name: 'Bangladesh',
            code: 'BD',
            phone: 880,
            symbol: '৳',
            currency: 'BDT',
            continent_code: 'AS',
            alpha_3: 'BGD',
        },
        {
            name: 'Barbados',
            code: 'BB',
            phone: 1246,
            symbol: 'Bds$',
            currency: 'BBD',
            continent_code: 'NA',
            alpha_3: 'BRB',
        },
        {name: 'Belarus', code: 'BY', phone: 375, symbol: 'Br', currency: 'BYN', continent_code: 'EU', alpha_3: 'BLR'},
        {name: 'Belgium', code: 'BE', phone: 32, symbol: '€', currency: 'EUR', continent_code: 'EU', alpha_3: 'BEL'},
        {name: 'Belize', code: 'BZ', phone: 501, symbol: '$', currency: 'BZD', continent_code: 'NA', alpha_3: 'BLZ'},
        {name: 'Benin', code: 'BJ', phone: 229, symbol: 'CFA', currency: 'XOF', continent_code: 'AF', alpha_3: 'BEN'},
        {name: 'Bermuda', code: 'BM', phone: 1441, symbol: '$', currency: 'BMD', continent_code: 'NA', alpha_3: 'BMU'},
        {name: 'Bhutan', code: 'BT', phone: 975, symbol: 'Nu.', currency: 'BTN', continent_code: 'AS', alpha_3: 'BTN'},
        {name: 'Bolivia', code: 'BO', phone: 591, symbol: 'Bs.', currency: 'BOB', continent_code: 'SA', alpha_3: 'BOL'},
        {
            name: 'Caribbean Netherlands',
            code: 'BQ',
            phone: 599,
            symbol: '$',
            currency: 'USD',
            continent_code: 'NA',
            alpha_3: 'BES',
        },
        {
            name: 'Bosnia & Herzegovina',
            code: 'BA',
            phone: 387,
            symbol: 'KM',
            currency: 'BAM',
            continent_code: 'EU',
            alpha_3: 'BIH',
        },
        {name: 'Botswana', code: 'BW', phone: 267, symbol: 'P', currency: 'BWP', continent_code: 'AF', alpha_3: 'BWA'},
        {
            name: 'Bouvet Island',
            code: 'BV',
            phone: 55,
            symbol: 'kr',
            currency: 'NOK',
            continent_code: 'AN',
            alpha_3: 'BVT',
        },
        {name: 'Brazil', code: 'BR', phone: 55, symbol: 'R$', currency: 'BRL', continent_code: 'SA', alpha_3: 'BRA'},
        {
            name: 'British Indian Ocean Territory',
            code: 'IO',
            phone: 246,
            symbol: '$',
            currency: 'USD',
            continent_code: 'AS',
            alpha_3: 'IOT',
        },
        {name: 'Brunei', code: 'BN', phone: 673, symbol: 'B$', currency: 'BND', continent_code: 'AS', alpha_3: 'BRN'},
        {
            name: 'Bulgaria',
            code: 'BG',
            phone: 359,
            symbol: 'Лв.',
            currency: 'BGN',
            continent_code: 'EU',
            alpha_3: 'BGR',
        },
        {
            name: 'Burkina Faso',
            code: 'BF',
            phone: 226,
            symbol: 'CFA',
            currency: 'XOF',
            continent_code: 'AF',
            alpha_3: 'BFA',
        },
        {name: 'Burundi', code: 'BI', phone: 257, symbol: 'FBu', currency: 'BIF', continent_code: 'AF', alpha_3: 'BDI'},
        {
            name: 'Cambodia',
            code: 'KH',
            phone: 855,
            symbol: 'KHR',
            currency: 'KHR',
            continent_code: 'AS',
            alpha_3: 'KHM',
        },
        {
            name: 'Cameroon',
            code: 'CM',
            phone: 237,
            symbol: 'FCFA',
            currency: 'XAF',
            continent_code: 'AF',
            alpha_3: 'CMR',
        },
        {name: 'Canada', code: 'CA', phone: 1, symbol: '$', currency: 'CAD', continent_code: 'NA', alpha_3: 'CAN'},
        {
            name: 'Cape Verde',
            code: 'CV',
            phone: 238,
            symbol: '$',
            currency: 'CVE',
            continent_code: 'AF',
            alpha_3: 'CPV',
        },
        {
            name: 'Cayman Islands',
            code: 'KY',
            phone: 1345,
            symbol: '$',
            currency: 'KYD',
            continent_code: 'NA',
            alpha_3: 'CYM',
        },
        {
            name: 'Central African Republic',
            code: 'CF',
            phone: 236,
            symbol: 'FCFA',
            currency: 'XAF',
            continent_code: 'AF',
            alpha_3: 'CAF',
        },
        {name: 'Chad', code: 'TD', phone: 235, symbol: 'FCFA', currency: 'XAF', continent_code: 'AF', alpha_3: 'TCD'},
        {name: 'Chile', code: 'CL', phone: 56, symbol: '$', currency: 'CLP', continent_code: 'SA', alpha_3: 'CHL'},
        {name: 'China', code: 'CN', phone: 86, symbol: '¥', currency: 'CNY', continent_code: 'AS', alpha_3: 'CHN'},
        {
            name: 'Christmas Island',
            code: 'CX',
            phone: 61,
            symbol: '$',
            currency: 'AUD',
            continent_code: 'AS',
            alpha_3: 'CXR',
        },
        {
            name: 'Cocos (Keeling) Islands',
            code: 'CC',
            phone: 672,
            symbol: '$',
            currency: 'AUD',
            continent_code: 'AS',
            alpha_3: 'CCK',
        },
        {name: 'Colombia', code: 'CO', phone: 57, symbol: '$', currency: 'COP', continent_code: 'SA', alpha_3: 'COL'},
        {name: 'Comoros', code: 'KM', phone: 269, symbol: 'CF', currency: 'KMF', continent_code: 'AF', alpha_3: 'COM'},
        {
            name: 'Congo - Brazzaville',
            code: 'CG',
            phone: 242,
            symbol: 'FC',
            currency: 'XAF',
            continent_code: 'AF',
            alpha_3: 'COG',
        },
        {
            name: 'Congo - Kinshasa',
            code: 'CD',
            phone: 242,
            symbol: 'FC',
            currency: 'CDF',
            continent_code: 'AF',
            alpha_3: 'COD',
        },
        {
            name: 'Cook Islands',
            code: 'CK',
            phone: 682,
            symbol: '$',
            currency: 'NZD',
            continent_code: 'OC',
            alpha_3: 'COK',
        },
        {
            name: 'Costa Rica',
            code: 'CR',
            phone: 506,
            symbol: '₡',
            currency: 'CRC',
            continent_code: 'NA',
            alpha_3: 'CRI',
        },
        {
            name: 'Côte d’Ivoire',
            code: 'CI',
            phone: 225,
            symbol: 'CFA',
            currency: 'XOF',
            continent_code: 'AF',
            alpha_3: 'CIV',
        },
        {name: 'Croatia', code: 'HR', phone: 385, symbol: 'kn', currency: 'HRK', continent_code: 'EU', alpha_3: 'HRV'},
        {name: 'Cuba', code: 'CU', phone: 53, symbol: '$', currency: 'CUP', continent_code: 'NA', alpha_3: 'CUB'},
        {name: 'Curaçao', code: 'CW', phone: 599, symbol: 'ƒ', currency: 'ANG', continent_code: 'NA', alpha_3: 'CUW'},
        {name: 'Cyprus', code: 'CY', phone: 357, symbol: '€', currency: 'EUR', continent_code: 'AS', alpha_3: 'CYP'},
        {name: 'Czechia', code: 'CZ', phone: 420, symbol: 'Kč', currency: 'CZK', continent_code: 'EU', alpha_3: 'CZE'},
        {name: 'Denmark', code: 'DK', phone: 45, symbol: 'Kr.', currency: 'DKK', continent_code: 'EU', alpha_3: 'DNK'},
        {
            name: 'Djibouti',
            code: 'DJ',
            phone: 253,
            symbol: 'Fdj',
            currency: 'DJF',
            continent_code: 'AF',
            alpha_3: 'DJI',
        },
        {name: 'Dominica', code: 'DM', phone: 1767, symbol: '$', currency: 'XCD', continent_code: 'NA', alpha_3: 'DMA'},
        {
            name: 'Dominican Republic',
            code: 'DO',
            phone: 1809,
            symbol: '$',
            currency: 'DOP',
            continent_code: 'NA',
            alpha_3: 'DOM',
        },
        {name: 'Ecuador', code: 'EC', phone: 593, symbol: '$', currency: 'USD', continent_code: 'SA', alpha_3: 'ECU'},
        {name: 'Egypt', code: 'EG', phone: 20, symbol: 'ج.م', currency: 'EGP', continent_code: 'AF', alpha_3: 'EGY'},
        {
            name: 'El Salvador',
            code: 'SV',
            phone: 503,
            symbol: '$',
            currency: 'USD',
            continent_code: 'NA',
            alpha_3: 'SLV',
        },
        {
            name: 'Equatorial Guinea',
            code: 'GQ',
            phone: 240,
            symbol: 'FCFA',
            currency: 'XAF',
            continent_code: 'AF',
            alpha_3: 'GNQ',
        },
        {name: 'Eritrea', code: 'ER', phone: 291, symbol: 'Nfk', currency: 'ERN', continent_code: 'AF', alpha_3: 'ERI'},
        {name: 'Estonia', code: 'EE', phone: 372, symbol: '€', currency: 'EUR', continent_code: 'EU', alpha_3: 'EST'},
        {
            name: 'Ethiopia',
            code: 'ET',
            phone: 251,
            symbol: 'Nkf',
            currency: 'ETB',
            continent_code: 'AF',
            alpha_3: 'ETH',
        },
        {
            name: 'Falkland Islands (Islas Malvinas)',
            code: 'FK',
            phone: 500,
            symbol: '£',
            currency: 'FKP',
            continent_code: 'SA',
            alpha_3: 'FLK',
        },
        {
            name: 'Faroe Islands',
            code: 'FO',
            phone: 298,
            symbol: 'Kr.',
            currency: 'DKK',
            continent_code: 'EU',
            alpha_3: 'FRO',
        },
        {name: 'Fiji', code: 'FJ', phone: 679, symbol: 'FJ$', currency: 'FJD', continent_code: 'OC', alpha_3: 'FJI'},
        {name: 'Finland', code: 'FI', phone: 358, symbol: '€', currency: 'EUR', continent_code: 'EU', alpha_3: 'FIN'},
        {name: 'France', code: 'FR', phone: 33, symbol: '€', currency: 'EUR', continent_code: 'EU', alpha_3: 'FRA'},
        {
            name: 'French Guiana',
            code: 'GF',
            phone: 594,
            symbol: '€',
            currency: 'EUR',
            continent_code: 'SA',
            alpha_3: 'GUF',
        },
        {
            name: 'French Polynesia',
            code: 'PF',
            phone: 689,
            symbol: '₣',
            currency: 'XPF',
            continent_code: 'OC',
            alpha_3: 'PYF',
        },
        {
            name: 'French Southern Territories',
            code: 'TF',
            phone: 262,
            symbol: '€',
            currency: 'EUR',
            continent_code: 'AN',
            alpha_3: 'ATF',
        },
        {name: 'Gabon', code: 'GA', phone: 241, symbol: 'FCFA', currency: 'XAF', continent_code: 'AF', alpha_3: 'GAB'},
        {name: 'Gambia', code: 'GM', phone: 220, symbol: 'D', currency: 'GMD', continent_code: 'AF', alpha_3: 'GMB'},
        {name: 'Georgia', code: 'GE', phone: 995, symbol: 'ლ', currency: 'GEL', continent_code: 'AS', alpha_3: 'GEO'},
        {name: 'Germany', code: 'DE', phone: 49, symbol: '€', currency: 'EUR', continent_code: 'EU', alpha_3: 'DEU'},
        {name: 'Ghana', code: 'GH', phone: 233, symbol: 'GH₵', currency: 'GHS', continent_code: 'AF', alpha_3: 'GHA'},
        {name: 'Gibraltar', code: 'GI', phone: 350, symbol: '£', currency: 'GIP', continent_code: 'EU', alpha_3: 'GIB'},
        {name: 'Greece', code: 'GR', phone: 30, symbol: '€', currency: 'EUR', continent_code: 'EU', alpha_3: 'GRC'},
        {
            name: 'Greenland',
            code: 'GL',
            phone: 299,
            symbol: 'Kr.',
            currency: 'DKK',
            continent_code: 'NA',
            alpha_3: 'GRL',
        },
        {name: 'Grenada', code: 'GD', phone: 1473, symbol: '$', currency: 'XCD', continent_code: 'NA', alpha_3: 'GRD'},
        {
            name: 'Guadeloupe',
            code: 'GP',
            phone: 590,
            symbol: '€',
            currency: 'EUR',
            continent_code: 'NA',
            alpha_3: 'GLP',
        },
        {name: 'Guam', code: 'GU', phone: 1671, symbol: '$', currency: 'USD', continent_code: 'OC', alpha_3: 'GUM'},
        {name: 'Guatemala', code: 'GT', phone: 502, symbol: 'Q', currency: 'GTQ', continent_code: 'NA', alpha_3: 'GTM'},
        {name: 'Guernsey', code: 'GG', phone: 44, symbol: '£', currency: 'GBP', continent_code: 'EU', alpha_3: 'GGY'},
        {name: 'Guinea', code: 'GN', phone: 224, symbol: 'FG', currency: 'GNF', continent_code: 'AF', alpha_3: 'GIN'},
        {
            name: 'Guinea-Bissau',
            code: 'GW',
            phone: 245,
            symbol: 'CFA',
            currency: 'XOF',
            continent_code: 'AF',
            alpha_3: 'GNB',
        },
        {name: 'Guyana', code: 'GY', phone: 592, symbol: '$', currency: 'GYD', continent_code: 'SA', alpha_3: 'GUY'},
        {name: 'Haiti', code: 'HT', phone: 509, symbol: 'G', currency: 'HTG', continent_code: 'NA', alpha_3: 'HTI'},
        {
            name: 'Heard & McDonald Islands',
            code: 'HM',
            phone: 0,
            symbol: '$',
            currency: 'AUD',
            continent_code: 'AN',
            alpha_3: 'HMD',
        },
        {
            name: 'Vatican City',
            code: 'VA',
            phone: 39,
            symbol: '€',
            currency: 'EUR',
            continent_code: 'EU',
            alpha_3: 'VAT',
        },
        {name: 'Honduras', code: 'HN', phone: 504, symbol: 'L', currency: 'HNL', continent_code: 'NA', alpha_3: 'HND'},
        {name: 'Hong Kong', code: 'HK', phone: 852, symbol: '$', currency: 'HKD', continent_code: 'AS', alpha_3: 'HKG'},
        {name: 'Hungary', code: 'HU', phone: 36, symbol: 'Ft', currency: 'HUF', continent_code: 'EU', alpha_3: 'HUN'},
        {name: 'Iceland', code: 'IS', phone: 354, symbol: 'kr', currency: 'ISK', continent_code: 'EU', alpha_3: 'ISL'},
        {name: 'India', code: 'IN', phone: 91, symbol: '₹', currency: 'INR', continent_code: 'AS', alpha_3: 'IND'},
        {name: 'Indonesia', code: 'ID', phone: 62, symbol: 'Rp', currency: 'IDR', continent_code: 'AS', alpha_3: 'IDN'},
        {name: 'Iran', code: 'IR', phone: 98, symbol: '﷼', currency: 'IRR', continent_code: 'AS', alpha_3: 'IRN'},
        {name: 'Iraq', code: 'IQ', phone: 964, symbol: 'د.ع', currency: 'IQD', continent_code: 'AS', alpha_3: 'IRQ'},
        {name: 'Ireland', code: 'IE', phone: 353, symbol: '€', currency: 'EUR', continent_code: 'EU', alpha_3: 'IRL'},
        {
            name: 'Isle of Man',
            code: 'IM',
            phone: 44,
            symbol: '£',
            currency: 'GBP',
            continent_code: 'EU',
            alpha_3: 'IMN',
        },
        {name: 'Israel', code: 'IL', phone: 972, symbol: '₪', currency: 'ILS', continent_code: 'AS', alpha_3: 'ISR'},
        {name: 'Italy', code: 'IT', phone: 39, symbol: '€', currency: 'EUR', continent_code: 'EU', alpha_3: 'ITA'},
        {name: 'Jamaica', code: 'JM', phone: 1876, symbol: 'J$', currency: 'JMD', continent_code: 'NA', alpha_3: 'JAM'},
        {name: 'Japan', code: 'JP', phone: 81, symbol: '¥', currency: 'JPY', continent_code: 'AS', alpha_3: 'JPN'},
        {name: 'Jersey', code: 'JE', phone: 44, symbol: '£', currency: 'GBP', continent_code: 'EU', alpha_3: 'JEY'},
        {name: 'Jordan', code: 'JO', phone: 962, symbol: 'ا.د', currency: 'JOD', continent_code: 'AS', alpha_3: 'JOR'},
        {name: 'Kazakhstan', code: 'KZ', phone: 7, symbol: 'лв', currency: 'KZT', continent_code: 'AS', alpha_3: 'KAZ'},
        {name: 'Kenya', code: 'KE', phone: 254, symbol: 'KSh', currency: 'KES', continent_code: 'AF', alpha_3: 'KEN'},
        {name: 'Kiribati', code: 'KI', phone: 686, symbol: '$', currency: 'AUD', continent_code: 'OC', alpha_3: 'KIR'},
        {
            name: 'North Korea',
            code: 'KP',
            phone: 850,
            symbol: '₩',
            currency: 'KPW',
            continent_code: 'AS',
            alpha_3: 'PRK',
        },
        {
            name: 'South Korea',
            code: 'KR',
            phone: 82,
            symbol: '₩',
            currency: 'KRW',
            continent_code: 'AS',
            alpha_3: 'KOR',
        },
        {name: 'Kosovo', code: 'XK', phone: 381, symbol: '€', currency: 'EUR', continent_code: 'EU', alpha_3: 'XKX'},
        {name: 'Kuwait', code: 'KW', phone: 965, symbol: 'ك.د', currency: 'KWD', continent_code: 'AS', alpha_3: 'KWT'},
        {
            name: 'Kyrgyzstan',
            code: 'KG',
            phone: 996,
            symbol: 'лв',
            currency: 'KGS',
            continent_code: 'AS',
            alpha_3: 'KGZ',
        },
        {name: 'Laos', code: 'LA', phone: 856, symbol: '₭', currency: 'LAK', continent_code: 'AS', alpha_3: 'LAO'},
        {name: 'Latvia', code: 'LV', phone: 371, symbol: '€', currency: 'EUR', continent_code: 'EU', alpha_3: 'LVA'},
        {name: 'Lebanon', code: 'LB', phone: 961, symbol: '£', currency: 'LBP', continent_code: 'AS', alpha_3: 'LBN'},
        {name: 'Lesotho', code: 'LS', phone: 266, symbol: 'L', currency: 'LSL', continent_code: 'AF', alpha_3: 'LSO'},
        {name: 'Liberia', code: 'LR', phone: 231, symbol: '$', currency: 'LRD', continent_code: 'AF', alpha_3: 'LBR'},
        {name: 'Libya', code: 'LY', phone: 218, symbol: 'د.ل', currency: 'LYD', continent_code: 'AF', alpha_3: 'LBY'},
        {
            name: 'Liechtenstein',
            code: 'LI',
            phone: 423,
            symbol: 'CHf',
            currency: 'CHF',
            continent_code: 'EU',
            alpha_3: 'LIE',
        },
        {name: 'Lithuania', code: 'LT', phone: 370, symbol: '€', currency: 'EUR', continent_code: 'EU', alpha_3: 'LTU'},
        {
            name: 'Luxembourg',
            code: 'LU',
            phone: 352,
            symbol: '€',
            currency: 'EUR',
            continent_code: 'EU',
            alpha_3: 'LUX',
        },
        {name: 'Macao', code: 'MO', phone: 853, symbol: '$', currency: 'MOP', continent_code: 'AS', alpha_3: 'MAC'},
        {
            name: 'North Macedonia',
            code: 'MK',
            phone: 389,
            symbol: 'ден',
            currency: 'MKD',
            continent_code: 'EU',
            alpha_3: 'MKD',
        },
        {
            name: 'Madagascar',
            code: 'MG',
            phone: 261,
            symbol: 'Ar',
            currency: 'MGA',
            continent_code: 'AF',
            alpha_3: 'MDG',
        },
        {name: 'Malawi', code: 'MW', phone: 265, symbol: 'MK', currency: 'MWK', continent_code: 'AF', alpha_3: 'MWI'},
        {name: 'Malaysia', code: 'MY', phone: 60, symbol: 'RM', currency: 'MYR', continent_code: 'AS', alpha_3: 'MYS'},
        {name: 'Maldives', code: 'MV', phone: 960, symbol: 'Rf', currency: 'MVR', continent_code: 'AS', alpha_3: 'MDV'},
        {name: 'Mali', code: 'ML', phone: 223, symbol: 'CFA', currency: 'XOF', continent_code: 'AF', alpha_3: 'MLI'},
        {name: 'Malta', code: 'MT', phone: 356, symbol: '€', currency: 'EUR', continent_code: 'EU', alpha_3: 'MLT'},
        {
            name: 'Marshall Islands',
            code: 'MH',
            phone: 692,
            symbol: '$',
            currency: 'USD',
            continent_code: 'OC',
            alpha_3: 'MHL',
        },
        {
            name: 'Martinique',
            code: 'MQ',
            phone: 596,
            symbol: '€',
            currency: 'EUR',
            continent_code: 'NA',
            alpha_3: 'MTQ',
        },
        {
            name: 'Mauritania',
            code: 'MR',
            phone: 222,
            symbol: 'MRU',
            currency: 'MRO',
            continent_code: 'AF',
            alpha_3: 'MRT',
        },
        {name: 'Mauritius', code: 'MU', phone: 230, symbol: '₨', currency: 'MUR', continent_code: 'AF', alpha_3: 'MUS'},
        {name: 'Mayotte', code: 'YT', phone: 262, symbol: '€', currency: 'EUR', continent_code: 'AF', alpha_3: 'MYT'},
        {name: 'Mexico', code: 'MX', phone: 52, symbol: '$', currency: 'MXN', continent_code: 'NA', alpha_3: 'MEX'},
        {
            name: 'Micronesia',
            code: 'FM',
            phone: 691,
            symbol: '$',
            currency: 'USD',
            continent_code: 'OC',
            alpha_3: 'FSM',
        },
        {name: 'Moldova', code: 'MD', phone: 373, symbol: 'L', currency: 'MDL', continent_code: 'EU', alpha_3: 'MDA'},
        {name: 'Monaco', code: 'MC', phone: 377, symbol: '€', currency: 'EUR', continent_code: 'EU', alpha_3: 'MCO'},
        {name: 'Mongolia', code: 'MN', phone: 976, symbol: '₮', currency: 'MNT', continent_code: 'AS', alpha_3: 'MNG'},
        {
            name: 'Montenegro',
            code: 'ME',
            phone: 382,
            symbol: '€',
            currency: 'EUR',
            continent_code: 'EU',
            alpha_3: 'MNE',
        },
        {
            name: 'Montserrat',
            code: 'MS',
            phone: 1664,
            symbol: '$',
            currency: 'XCD',
            continent_code: 'NA',
            alpha_3: 'MSR',
        },
        {name: 'Morocco', code: 'MA', phone: 212, symbol: 'DH', currency: 'MAD', continent_code: 'AF', alpha_3: 'MAR'},
        {
            name: 'Mozambique',
            code: 'MZ',
            phone: 258,
            symbol: 'MT',
            currency: 'MZN',
            continent_code: 'AF',
            alpha_3: 'MOZ',
        },
        {
            name: 'Myanmar (Burma)',
            code: 'MM',
            phone: 95,
            symbol: 'K',
            currency: 'MMK',
            continent_code: 'AS',
            alpha_3: 'MMR',
        },
        {name: 'Namibia', code: 'NA', phone: 264, symbol: '$', currency: 'NAD', continent_code: 'AF', alpha_3: 'NAM'},
        {name: 'Nauru', code: 'NR', phone: 674, symbol: '$', currency: 'AUD', continent_code: 'OC', alpha_3: 'NRU'},
        {name: 'Nepal', code: 'NP', phone: 977, symbol: '₨', currency: 'NPR', continent_code: 'AS', alpha_3: 'NPL'},
        {
            name: 'Netherlands',
            code: 'NL',
            phone: 31,
            symbol: '€',
            currency: 'EUR',
            continent_code: 'EU',
            alpha_3: 'NLD',
        },
        {name: 'Curaçao', code: 'AN', phone: 599, symbol: 'NAf', currency: 'ANG', continent_code: 'NA', alpha_3: 'ANT'},
        {
            name: 'New Caledonia',
            code: 'NC',
            phone: 687,
            symbol: '₣',
            currency: 'XPF',
            continent_code: 'OC',
            alpha_3: 'NCL',
        },
        {
            name: 'New Zealand',
            code: 'NZ',
            phone: 64,
            symbol: '$',
            currency: 'NZD',
            continent_code: 'OC',
            alpha_3: 'NZL',
        },
        {
            name: 'Nicaragua',
            code: 'NI',
            phone: 505,
            symbol: 'C$',
            currency: 'NIO',
            continent_code: 'NA',
            alpha_3: 'NIC',
        },
        {name: 'Niger', code: 'NE', phone: 227, symbol: 'CFA', currency: 'XOF', continent_code: 'AF', alpha_3: 'NER'},
        {name: 'Nigeria', code: 'NG', phone: 234, symbol: '₦', currency: 'NGN', continent_code: 'AF', alpha_3: 'NGA'},
        {name: 'Niue', code: 'NU', phone: 683, symbol: '$', currency: 'NZD', continent_code: 'OC', alpha_3: 'NIU'},
        {
            name: 'Norfolk Island',
            code: 'NF',
            phone: 672,
            symbol: '$',
            currency: 'AUD',
            continent_code: 'OC',
            alpha_3: 'NFK',
        },
        {
            name: 'Northern Mariana Islands',
            code: 'MP',
            phone: 1670,
            symbol: '$',
            currency: 'USD',
            continent_code: 'OC',
            alpha_3: 'MNP',
        },
        {name: 'Norway', code: 'NO', phone: 47, symbol: 'kr', currency: 'NOK', continent_code: 'EU', alpha_3: 'NOR'},
        {name: 'Oman', code: 'OM', phone: 968, symbol: '.ع.ر', currency: 'OMR', continent_code: 'AS', alpha_3: 'OMN'},
        {name: 'Pakistan', code: 'PK', phone: 92, symbol: '₨', currency: 'PKR', continent_code: 'AS', alpha_3: 'PAK'},
        {name: 'Palau', code: 'PW', phone: 680, symbol: '$', currency: 'USD', continent_code: 'OC', alpha_3: 'PLW'},
        {name: 'Palestine', code: 'PS', phone: 970, symbol: '₪', currency: 'ILS', continent_code: 'AS', alpha_3: 'PSE'},
        {name: 'Panama', code: 'PA', phone: 507, symbol: 'B/.', currency: 'PAB', continent_code: 'NA', alpha_3: 'PAN'},
        {
            name: 'Papua New Guinea',
            code: 'PG',
            phone: 675,
            symbol: 'K',
            currency: 'PGK',
            continent_code: 'OC',
            alpha_3: 'PNG',
        },
        {name: 'Paraguay', code: 'PY', phone: 595, symbol: '₲', currency: 'PYG', continent_code: 'SA', alpha_3: 'PRY'},
        {name: 'Peru', code: 'PE', phone: 51, symbol: 'S/.', currency: 'PEN', continent_code: 'SA', alpha_3: 'PER'},
        {
            name: 'Philippines',
            code: 'PH',
            phone: 63,
            symbol: '₱',
            currency: 'PHP',
            continent_code: 'AS',
            alpha_3: 'PHL',
        },
        {
            name: 'Pitcairn Islands',
            code: 'PN',
            phone: 64,
            symbol: '$',
            currency: 'NZD',
            continent_code: 'OC',
            alpha_3: 'PCN',
        },
        {name: 'Poland', code: 'PL', phone: 48, symbol: 'zł', currency: 'PLN', continent_code: 'EU', alpha_3: 'POL'},
        {name: 'Portugal', code: 'PT', phone: 351, symbol: '€', currency: 'EUR', continent_code: 'EU', alpha_3: 'PRT'},
        {
            name: 'Puerto Rico',
            code: 'PR',
            phone: 1787,
            symbol: '$',
            currency: 'USD',
            continent_code: 'NA',
            alpha_3: 'PRI',
        },
        {name: 'Qatar', code: 'QA', phone: 974, symbol: 'ق.ر', currency: 'QAR', continent_code: 'AS', alpha_3: 'QAT'},
        {name: 'Réunion', code: 'RE', phone: 262, symbol: '€', currency: 'EUR', continent_code: 'AF', alpha_3: 'REU'},
        {name: 'Romania', code: 'RO', phone: 40, symbol: 'lei', currency: 'RON', continent_code: 'EU', alpha_3: 'ROM'},
        {name: 'Russia', code: 'RU', phone: 70, symbol: '₽', currency: 'RUB', continent_code: 'AS', alpha_3: 'RUS'},
        {name: 'Rwanda', code: 'RW', phone: 250, symbol: 'FRw', currency: 'RWF', continent_code: 'AF', alpha_3: 'RWA'},
        {
            name: 'St. Barthélemy',
            code: 'BL',
            phone: 590,
            symbol: '€',
            currency: 'EUR',
            continent_code: 'NA',
            alpha_3: 'BLM',
        },
        {
            name: 'St. Helena',
            code: 'SH',
            phone: 290,
            symbol: '£',
            currency: 'SHP',
            continent_code: 'AF',
            alpha_3: 'SHN',
        },
        {
            name: 'St. Kitts & Nevis',
            code: 'KN',
            phone: 1869,
            symbol: '$',
            currency: 'XCD',
            continent_code: 'NA',
            alpha_3: 'KNA',
        },
        {
            name: 'St. Lucia',
            code: 'LC',
            phone: 1758,
            symbol: '$',
            currency: 'XCD',
            continent_code: 'NA',
            alpha_3: 'LCA',
        },
        {
            name: 'St. Martin',
            code: 'MF',
            phone: 590,
            symbol: '€',
            currency: 'EUR',
            continent_code: 'NA',
            alpha_3: 'MAF',
        },
        {
            name: 'St. Pierre & Miquelon',
            code: 'PM',
            phone: 508,
            symbol: '€',
            currency: 'EUR',
            continent_code: 'NA',
            alpha_3: 'SPM',
        },
        {
            name: 'St. Vincent & Grenadines',
            code: 'VC',
            phone: 1784,
            symbol: '$',
            currency: 'XCD',
            continent_code: 'NA',
            alpha_3: 'VCT',
        },
        {name: 'Samoa', code: 'WS', phone: 684, symbol: 'SAT', currency: 'WST', continent_code: 'OC', alpha_3: 'WSM'},
        {
            name: 'San Marino',
            code: 'SM',
            phone: 378,
            symbol: '€',
            currency: 'EUR',
            continent_code: 'EU',
            alpha_3: 'SMR',
        },
        {
            name: 'São Tomé & Príncipe',
            code: 'ST',
            phone: 239,
            symbol: 'Db',
            currency: 'STD',
            continent_code: 'AF',
            alpha_3: 'STP',
        },
        {
            name: 'Saudi Arabia',
            code: 'SA',
            phone: 966,
            symbol: '﷼',
            currency: 'SAR',
            continent_code: 'AS',
            alpha_3: 'SAU',
        },
        {name: 'Senegal', code: 'SN', phone: 221, symbol: 'CFA', currency: 'XOF', continent_code: 'AF', alpha_3: 'SEN'},
        {name: 'Serbia', code: 'RS', phone: 381, symbol: 'din', currency: 'RSD', continent_code: 'EU', alpha_3: 'SRB'},
        {name: 'Serbia', code: 'CS', phone: 381, symbol: 'din', currency: 'RSD', continent_code: 'EU', alpha_3: 'SCG'},
        {
            name: 'Seychelles',
            code: 'SC',
            phone: 248,
            symbol: 'SRe',
            currency: 'SCR',
            continent_code: 'AF',
            alpha_3: 'SYC',
        },
        {
            name: 'Sierra Leone',
            code: 'SL',
            phone: 232,
            symbol: 'Le',
            currency: 'SLL',
            continent_code: 'AF',
            alpha_3: 'SLE',
        },
        {name: 'Singapore', code: 'SG', phone: 65, symbol: '$', currency: 'SGD', continent_code: 'AS', alpha_3: 'SGP'},
        {
            name: 'Sint Maarten',
            code: 'SX',
            phone: 1,
            symbol: 'ƒ',
            currency: 'ANG',
            continent_code: 'NA',
            alpha_3: 'SXM',
        },
        {name: 'Slovakia', code: 'SK', phone: 421, symbol: '€', currency: 'EUR', continent_code: 'EU', alpha_3: 'SVK'},
        {name: 'Slovenia', code: 'SI', phone: 386, symbol: '€', currency: 'EUR', continent_code: 'EU', alpha_3: 'SVN'},
        {
            name: 'Solomon Islands',
            code: 'SB',
            phone: 677,
            symbol: 'Si$',
            currency: 'SBD',
            continent_code: 'OC',
            alpha_3: 'SLB',
        },
        {
            name: 'Somalia',
            code: 'SO',
            phone: 252,
            symbol: 'Sh.so.',
            currency: 'SOS',
            continent_code: 'AF',
            alpha_3: 'SOM',
        },
        {
            name: 'South Africa',
            code: 'ZA',
            phone: 27,
            symbol: 'R',
            currency: 'ZAR',
            continent_code: 'AF',
            alpha_3: 'ZAF',
        },
        {
            name: 'South Georgia & South Sandwich Islands',
            code: 'GS',
            phone: 500,
            symbol: '£',
            currency: 'GBP',
            continent_code: 'AN',
            alpha_3: 'SGS',
        },
        {
            name: 'South Sudan',
            code: 'SS',
            phone: 211,
            symbol: '£',
            currency: 'SSP',
            continent_code: 'AF',
            alpha_3: 'SSD',
        },
        {name: 'Spain', code: 'ES', phone: 34, symbol: '€', currency: 'EUR', continent_code: 'EU', alpha_3: 'ESP'},
        {name: 'Sri Lanka', code: 'LK', phone: 94, symbol: 'Rs', currency: 'LKR', continent_code: 'AS', alpha_3: 'LKA'},
        {name: 'Sudan', code: 'SD', phone: 249, symbol: '.س.ج', currency: 'SDG', continent_code: 'AF', alpha_3: 'SDN'},
        {name: 'Suriname', code: 'SR', phone: 597, symbol: '$', currency: 'SRD', continent_code: 'SA', alpha_3: 'SUR'},
        {
            name: 'Svalbard & Jan Mayen',
            code: 'SJ',
            phone: 47,
            symbol: 'kr',
            currency: 'NOK',
            continent_code: 'EU',
            alpha_3: 'SJM',
        },
        {name: 'Eswatini', code: 'SZ', phone: 268, symbol: 'E', currency: 'SZL', continent_code: 'AF', alpha_3: 'SWZ'},
        {name: 'Sweden', code: 'SE', phone: 46, symbol: 'kr', currency: 'SEK', continent_code: 'EU', alpha_3: 'SWE'},
        {
            name: 'Switzerland',
            code: 'CH',
            phone: 41,
            symbol: 'CHf',
            currency: 'CHF',
            continent_code: 'EU',
            alpha_3: 'CHE',
        },
        {name: 'Syria', code: 'SY', phone: 963, symbol: 'LS', currency: 'SYP', continent_code: 'AS', alpha_3: 'SYR'},
        {name: 'Taiwan', code: 'TW', phone: 886, symbol: '$', currency: 'TWD', continent_code: 'AS', alpha_3: 'TWN'},
        {
            name: 'Tajikistan',
            code: 'TJ',
            phone: 992,
            symbol: 'SM',
            currency: 'TJS',
            continent_code: 'AS',
            alpha_3: 'TJK',
        },
        {
            name: 'Tanzania',
            code: 'TZ',
            phone: 255,
            symbol: 'TSh',
            currency: 'TZS',
            continent_code: 'AF',
            alpha_3: 'TZA',
        },
        {name: 'Thailand', code: 'TH', phone: 66, symbol: '฿', currency: 'THB', continent_code: 'AS', alpha_3: 'THA'},
        {
            name: 'Timor-Leste',
            code: 'TL',
            phone: 670,
            symbol: '$',
            currency: 'USD',
            continent_code: 'AS',
            alpha_3: 'TLS',
        },
        {name: 'Togo', code: 'TG', phone: 228, symbol: 'CFA', currency: 'XOF', continent_code: 'AF', alpha_3: 'TGO'},
        {name: 'Tokelau', code: 'TK', phone: 690, symbol: '$', currency: 'NZD', continent_code: 'OC', alpha_3: 'TKL'},
        {name: 'Tonga', code: 'TO', phone: 676, symbol: '$', currency: 'TOP', continent_code: 'OC', alpha_3: 'TON'},
        {
            name: 'Trinidad & Tobago',
            code: 'TT',
            phone: 1868,
            symbol: '$',
            currency: 'TTD',
            continent_code: 'NA',
            alpha_3: 'TTO',
        },
        {name: 'Tunisia', code: 'TN', phone: 216, symbol: 'ت.د', currency: 'TND', continent_code: 'AF', alpha_3: 'TUN'},
        {name: 'Turkey', code: 'TR', phone: 90, symbol: '₺', currency: 'TRY', continent_code: 'AS', alpha_3: 'TUR'},
        {
            name: 'Turkmenistan',
            code: 'TM',
            phone: 7370,
            symbol: 'T',
            currency: 'TMT',
            continent_code: 'AS',
            alpha_3: 'TKM',
        },
        {
            name: 'Turks & Caicos Islands',
            code: 'TC',
            phone: 1649,
            symbol: '$',
            currency: 'USD',
            continent_code: 'NA',
            alpha_3: 'TCA',
        },
        {name: 'Tuvalu', code: 'TV', phone: 688, symbol: '$', currency: 'AUD', continent_code: 'OC', alpha_3: 'TUV'},
        {name: 'Uganda', code: 'UG', phone: 256, symbol: 'USh', currency: 'UGX', continent_code: 'AF', alpha_3: 'UGA'},
        {name: 'Ukraine', code: 'UA', phone: 380, symbol: '₴', currency: 'UAH', continent_code: 'EU', alpha_3: 'UKR'},
        {
            name: 'United Arab Emirates',
            code: 'AE',
            phone: 971,
            symbol: 'إ.د',
            currency: 'AED',
            continent_code: 'AS',
            alpha_3: 'ARE',
        },
        {
            name: 'United Kingdom',
            code: 'GB',
            phone: 44,
            symbol: '£',
            currency: 'GBP',
            continent_code: 'EU',
            alpha_3: 'GBR',
        },
        {
            name: 'United States',
            code: 'US',
            phone: 1,
            symbol: '$',
            currency: 'USD',
            continent_code: 'NA',
            alpha_3: 'USA',
        },
        {
            name: 'U.S. Outlying Islands',
            code: 'UM',
            phone: 1,
            symbol: '$',
            currency: 'USD',
            continent_code: 'NA',
            alpha_3: 'UMI',
        },
        {name: 'Uruguay', code: 'UY', phone: 598, symbol: '$', currency: 'UYU', continent_code: 'SA', alpha_3: 'URY'},
        {
            name: 'Uzbekistan',
            code: 'UZ',
            phone: 998,
            symbol: 'лв',
            currency: 'UZS',
            continent_code: 'AS',
            alpha_3: 'UZB',
        },
        {name: 'Vanuatu', code: 'VU', phone: 678, symbol: 'VT', currency: 'VUV', continent_code: 'OC', alpha_3: 'VUT'},
        {name: 'Venezuela', code: 'VE', phone: 58, symbol: 'Bs', currency: 'VEF', continent_code: 'SA', alpha_3: 'VEN'},
        {name: 'Vietnam', code: 'VN', phone: 84, symbol: '₫', currency: 'VND', continent_code: 'AS', alpha_3: 'VNM'},
        {
            name: 'British Virgin Islands',
            code: 'VG',
            phone: 1284,
            symbol: '$',
            currency: 'USD',
            continent_code: 'NA',
            alpha_3: 'VGB',
        },
        {
            name: 'U.S. Virgin Islands',
            code: 'VI',
            phone: 1340,
            symbol: '$',
            currency: 'USD',
            continent_code: 'NA',
            alpha_3: 'VIR',
        },
        {
            name: 'Wallis & Futuna',
            code: 'WF',
            phone: 681,
            symbol: '₣',
            currency: 'XPF',
            continent_code: 'OC',
            alpha_3: 'WLF',
        },
        {
            name: 'Western Sahara',
            code: 'EH',
            phone: 212,
            symbol: 'MAD',
            currency: 'MAD',
            continent_code: 'AF',
            alpha_3: 'ESH',
        },
        {name: 'Yemen', code: 'YE', phone: 967, symbol: '﷼', currency: 'YER', continent_code: 'AS', alpha_3: 'YEM'},
        {name: 'Zambia', code: 'ZM', phone: 260, symbol: 'ZK', currency: 'ZMW', continent_code: 'AF', alpha_3: 'ZMB'},
        {name: 'Zimbabwe', code: 'ZW', phone: 263, symbol: '$', currency: 'ZWL', continent_code: 'AF', alpha_3: 'ZWE'},
    ],
    currentCurrency: null,
};

const appModule: Module<AppState, RootState> = {
    namespaced: true,

    state,

    getters: {
        loading: (state: AppState) => state.loading,
        booting: (state: AppState) => state.booting,
        setupItems: (state: AppState) => state.setupItems,
        sidebar: (state: AppState) => state.sidebar,
        rightSidebar: (state: AppState) => state.rightSidebar,
        browser: (state: AppState) => state.browser,
        listingView: (state: AppState) => state.listingView,
        size: (state: AppState) => state.size,
        device: (state: AppState) => state.device,
        deviceWidth: (state: AppState) => state.deviceWidth,
        secondaryNavViews: (state: AppState) => state.secondaryNavViews,
        possiblePermissions: (state: AppState) => [...state.possiblePermissions].sort((a, b) => a.name.localeCompare(b.name)),
        masquerade: (state: AppState) => state.masquerade,
        fwPublicStripeKey: (state: AppState) => state.fwPublicStripeKey,
        build: (state: AppState) => state.build,
        polled_build: (state: AppState) => state.polled_build,
        update_available: (state: AppState) => state.update_available,
        debugDrawerOpen: (state: AppState) => state.debugDrawerOpen,
        debugOn: (state: AppState) => state.debugOn,
        hoveredTurnoverBooking: (state: AppState) => state.hoveredTurnoverBooking,
        appFeatures: (state: AppState) => state.appFeatures,
        featureWaitlist: (state: AppState) => state.featureWaitlist,
        countryOptions: (state: AppState) => state.countryOptions,
        currentCurrency: (state: AppState) => state.currentCurrency,
    },

    mutations: {
        [types.SET_APP_LOADING](state: AppState, loading: { subject: string, loading: boolean }) {
            state.loading[loading.subject] = loading.loading;
        },
        [types.INITIALIZE_STATE](state: AppState) {
            state.debugDrawerOpen = [true, 'true'].includes(localStorage.getItem(DEBUG_STORAGE_KEY));
        },
        [types.SET_APP_BOOTING](state: AppState, booting: boolean) {
            state.booting = booting;
        },
        [types.TOGGLE_RIGHT_SIDEBAR](state: AppState) {
            state.rightSidebar = !state.rightSidebar;
        },
        [types.TOGGLE_SIDEBAR](state: AppState) {
            state.sidebar.opened = !state.sidebar.opened;
            state.sidebar.withoutAnimation = false;
        },
        [types.SET_HOVERED_TURNOVER_BOOKING_ID](state: AppState, id) {
            state.hoveredTurnoverBooking = id;
        },
        [types.TOGGLE_LISTING_VIEW](state: AppState, value) {
            state.listingView = value;
            Cookies.set('listingViewStatus', state.listingView);
        },
        [types.TOGGLE_SECONDARY_IMPORT_NAV_VIEW](state: AppState) {
            state.secondaryNavViews.listingImportNav = !state.secondaryNavViews.listingImportNav;
        },

        [types.CLOSE_SIDEBAR](state: AppState, withoutAnimation) {
            Cookies.set('sidebarStatus', 0);
            state.sidebar.opened = false;
            state.sidebar.withoutAnimation = withoutAnimation;
        },
        [types.TOGGLE_DEVICE](state: AppState, device) {
            state.device = device;
        },
        [types.SET_ALL_POSSIBLE_PERMISSIONS](state: AppState, permissions) {
            state.possiblePermissions = permissions;
        },
        [types.SET_MASQUERADE_STATUS](state: AppState, masquerade) {
            state.masquerade = masquerade;
        },
        [types.SET_MASQUERADE](state: AppState, masquerade: MasqueradeData) {
            if (masquerade) {
                state.masquerade = masquerade;
                state.masquerade.on = true;
                setMasquerade(state.masquerade).then(() => {
                    window.location.href = '/dashboard';
                });
            }
        },
        [types.END_MASQUERADE](state: AppState) {
            state.masquerade = {
                on: false,
                user: null,
                admin: null,
            };
            removeMasquerade();
            // eslint-disable-next-line no-restricted-globals
            location.reload();
        },
        [types.SET_FW_STRIPE_KEY](state: AppState, key: string) {
            state.fwPublicStripeKey = key;
        },
        [types.SET_FW_BUILD_NUMBER](state: AppState, build: string) {
            state.build = build;
        },
        [types.SET_POLLED_FW_BUILD_NUMBER](state: AppState, build: string) {
            state.polled_build = build;

            const existingBuild = localStorage.getItem('fw_polled_build');

            if (!existingBuild) {
                localStorage.setItem('fw_polled_build', build);
            } else if (existingBuild && existingBuild !== build) {
                state.update_available = true;

                localStorage.setItem('fw_polled_build', build);
            }
        },
        [types.SET_UPDATE_AVAILABLE](state: AppState) {
            state.update_available = true;
        },
        [types.SET_APP_FEATURES](state: AppState, features: AppFeatures) {
            state.appFeatures = features;
        },
        [types.MARK_SETUP_ITEM_COMPLETE](state: AppState, index) {
            state.setupItems[index].completed = true;
        },
        [types.SET_FEATURES_WAITLIST](state: AppState, features) {
            state.featureWaitlist = features;
        },
        [types.SET_CURRENT_CURRENCY](state: AppState, currency: AppCurrency) {
            state.currentCurrency = currency;
        },
        [types.TOGGLE_DEBUG_DRAWER](state: AppState) {
            console.dir(`Toggle Debug Drawer ${(state.debugDrawerOpen ? 'off' : 'on')}`.toUpperCase());
            localStorage.setItem('fw-debug', String(!state.debugDrawerOpen));
            state.debugDrawerOpen = !state.debugDrawerOpen;
        },
        [types.TOGGLE_DEBUG_ON](state: AppState, value: boolean = null) {
            const debugOnValue = value !== null ? value : !state.debugOn;

            console.dir(`Toggle Debug On Value: ${(debugOnValue ? 'off' : 'on')}`.toUpperCase());
            localStorage.setItem('fw-debug-on', String(debugOnValue));
            state.debugOn = debugOnValue;
        },
    },

    actions: {
        toggleSideBar({commit, state}) {
            commit(types.TOGGLE_SIDEBAR);

            Cookies.set(SIDEBAR_STORAGE_KEY, state.sidebar.opened ? '1' : '0');
        },
        masqueradeEnd({commit}) {
            commit(types.END_MASQUERADE);
        },
        toggleSecondaryImportNav({commit}) {
            commit(types.TOGGLE_SECONDARY_IMPORT_NAV_VIEW);
        },
        closeSideBar({commit}, {withoutAnimation}) {
            commit(types.CLOSE_SIDEBAR, withoutAnimation);
        },
        toggleDebugDrawer({commit}) {
            commit(types.TOGGLE_DEBUG_DRAWER);
        },

        toggleDebugOn({commit}) {
            console.dir('toggleDebugOn');
            commit(types.TOGGLE_DEBUG_ON);
        },

        checkForDebug({commit}) {
            console.dir('checkForDebug');

            const isDebugOn = localStorage.getItem('fw-debug-on')

            console.dir(isDebugOn);
            if (['on', 'true', true, 1].includes(isDebugOn)) {
                commit(types.TOGGLE_DEBUG_ON, true)
            }
        },

        async fetchBuildNumber({commit}) {
            const responseData = await performApiRequest(commit, 'SET_APP_LOADING', 'fetchBuild', () => axios.get('/v1/build'));
            commit(types.SET_POLLED_FW_BUILD_NUMBER, responseData.build);
            return responseData.build;
        },

        async fetchCurrency({commit}, code: string) {
            try {
                const responseData = await performApiRequest(commit, 'SET_APP_LOADING', 'fetchCurrency', () => axios.get(`/v1/currencies/${code}`));
                commit(types.SET_CURRENT_CURRENCY, responseData.currency);
            } catch (error) {
            }
        },

        addFeatureWaitlist({commit}, data) {
            axios.post('/v1/feature-waitlist', data)
                .then((response) => {
                    EventBus.$emit('join-feature-waitlist-success');
                    commit('SET_FEATURES_WAITLIST', response.data.data);
                }).catch(() => {
            }).finally(() => {
            });
        },

        async getFeatureWaitlist({commit}) {
            const responseData = await performApiRequest(commit, 'SET_APP_LOADING', 'fetchCurrency', () => axios.get('/v1/feature-waitlist'));
            commit('SET_FEATURES_WAITLIST', responseData.data);
            EventBus.$emit('get-feature-waitlist-success', responseData.data);
        },


        fetchHolidays({commit, state}, data) {
            axios.get(`/v1/holidays?year=${data.year}&countryCode=${data.countryCode}`)
                .then((response) => {
                    EventBus.$emit('get-holidays-success', response.data.holidays);
                }).catch((e) => {
                EventBus.$emit('SystemMessage', {
                    type: 'error',
                    message: e.response?.data?.message || 'There was an error',
                });
            }).finally(() => {
            });
        },
    },

}

export default appModule;
